export const getAllLookupPatientStatusType = {
  GETALL_LOOKUP_PATIENT_STATUS_TC_REQUEST:
    "GETALL_LOOKUP_PATIENT_STATUS_TC_REQUEST",
  GETALL_LOOKUP_PATIENT_STATUS_TC_SUCCESS:
    "GETALL_LOOKUP_PATIENT_STATUS_TC_SUCCESS",
  GETALL_LOOKUP_PATIENT_STATUS_TC_FAILURE:
    "GETALL_LOOKUP_PATIENT_STATUS_TC_FAILURE",
  GETALL_LOOKUP_PATIENT_STATUS_TC_RESET:
    "GETALL_LOOKUP_PATIENT_STATUS_TC_RESET",
};
