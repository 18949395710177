import { pcpTypes } from "../types";

const initiaLState = {
  loading: false,
  getAll: [],
  specialities: [],
};

export const pcpDMReducer = (state = initiaLState, action) => {
  switch (action.type) {
    case pcpTypes.PCP_REQUEST:
      return {
        ...initiaLState,
        loading: true,
      };
    case pcpTypes.PCP_REQUEST_SUCCESS:
      return {
        ...initiaLState,
        getAll: action.getAll,
        specialities: action.specialities,
      };
    case pcpTypes.PCP_REQUEST_FAILURE:
      return {
        ...initiaLState,
        error: action.error,
      };
    default:
      return state;
  }
};
