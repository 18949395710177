import { roleIndetityLookupDmTypes } from "../types";

export const roleIdentityLookupDmActions = {
  roleIdentityLookupDmRequest,
  roleIdentityLookupDmSuccess,
  roleIdentityLookupDmFailure,
  roleIdentityLookupDmReset,
};
function roleIdentityLookupDmRequest(request, token) {
  return {
    type: roleIndetityLookupDmTypes.ROLEIDENTITY_LOOKUP_DM_REQUEST,
    request,
    token,
  };
}
function roleIdentityLookupDmSuccess(getAll) {
  return {
    type: roleIndetityLookupDmTypes.ROLEIDENTITY_LOOKUP_DM_SUCCESS,
    getAll,
  };
}
function roleIdentityLookupDmFailure(error) {
  return {
    type: roleIndetityLookupDmTypes.ROLEIDENTITY_LOOKUP_DM_FAILURE,
    error,
  };
}
function roleIdentityLookupDmReset() {
  return {
    type: roleIndetityLookupDmTypes.ROLEIDENTITY_LOOKUP_DM_RESET,
  };
}
