import { advanceCarePlanLookupCmerTypes } from "../types";

const initialState = {
  loading: false,
  getAll: [],
  error: "",
};

export const advanceCarePlanLookupCmerReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case advanceCarePlanLookupCmerTypes.ADVANCECAREPLAN_LOOKUP_CMER_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case advanceCarePlanLookupCmerTypes.ADVANCECAREPLAN_LOOKUP_CMER_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
      };
    case advanceCarePlanLookupCmerTypes.ADVANCECAREPLAN_LOOKUP_CMER_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    default:
      return state;
  }
};
