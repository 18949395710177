import { put, takeLatest, call } from "@redux-saga/core/effects";
import { tasksDetailsActions } from "../actions/tasksDetails.action";
import { tasksDetailsType } from "../types/tasksDetails.type";
import { endpoint } from "../../../../../api/Url";
import { Api } from "../../../../../api";
import { loginActions } from "../../../../Login/actions/login.action";

export function* watchTasksDetails() {
  yield takeLatest(
    tasksDetailsType.CALL_TASKS_DETAILS_REQUEST,
    getTasksDetails
  );
}

function* getTasksDetails(action) {
  try {
    //   api call
    const data = yield call(Api, {
      endpoint: `${endpoint.GetTaskPocDataList}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    const parsedData = JSON.parse(data);

    parsedData.TaskDetailList !== undefined && parsedData.lstGroup !== undefined
      ? yield put(
          tasksDetailsActions.tasksDetailsRequestSuccess(
            parsedData.TaskDetailList,
            parsedData.lstGroup
          )
        )
      : yield put(
          tasksDetailsActions.tasksDetailsRequestFailure("No data found")
        );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(tasksDetailsActions.tasksDetailsRequestFailure(error));
  }
}
