import { put, call, debounce } from "@redux-saga/core/effects";

import {
  getReferralConsultantDataFailure,
  getReferralConsultantDataSuccess,
} from "./ReferralSlice";
import { loginActions } from "../../Login/actions";
import { Api2 } from "../../../api/Api2";
import { endpoint } from "../../../api/Url";

export function* watchReferralRole() {
  yield debounce(
    500,
    "referralConsultant/getReferralConsultantDataRequest",
    getAllReferralConsultant
  );
}
//For dynamic page request
function* getAllReferralConsultant(action) {
  try {
    //   api call

    const { pageSize, pageNumber, search, toDate, fromDate } =
      action.payload.request;

    const endpointUrl =
      `${endpoint.referralConsultant}?pageSize=${pageSize}&pageNumber=${pageNumber}&search=${search}` +
      (fromDate ? `&fromDate=${fromDate}` : "") +
      (toDate ? `&toDate=${toDate}` : "");

    const res = yield call(Api2, {
      endpoint: endpointUrl,
      method: "GET",
      jwt: "Bearer " + action.payload.request.token,
    });

    yield put(getReferralConsultantDataSuccess(res));
  } catch (error) {
    yield put(getReferralConsultantDataFailure(error));

    if (error.code === 401 && error.code === 403)
      yield put(loginActions.logoutRequest());
  }
}
