export const labTestsTypes = {
  LABTESTS_GET_REQUEST: "LABTESTS_GET_REQUEST",
  LABTESTS_GET_REQUEST_SUCCESS: "LABTESTS_GET_REQUEST_SUCCESS",
  LABTESTS_GET_REQUEST_FAILURE: "LABTESTS_GET_REQUEST_FAILURE",

  LABTESTS_OPERATION_REQUEST: "LABTESTS_OPERATION_REQUEST",
  LABTESTS_OPERATION_REQUEST_SUCCESS: "LABTESTS_OPERATION_REQUEST_SUCCESS",
  LABTESTS_OPERATION_REQUEST_FAILURE: "LABTESTS_OPERATION_REQUEST_FAILURE",
  LABTESTS_OPERATION_REQUEST_RESET: "LABTESTS_OPERATION_REQUEST_RESET",
};
