import { sentType } from "../../types";

const initialState = { sending: false, message: {} };
export const sentReducer = (state = initialState, action) => {
  switch (action.type) {
    case sentType.SENT_REQUEST:
      return { ...state, sending: true };
    case sentType.SENT_REQUEST_SUCCESS:
      return { ...initialState, message: action.message };
    case sentType.SENT_REQUEST_FAILURE:
      return { error: action.error };
    case sentType.SENT_REQUEST_RESET:
      return initialState;
    default:
      return state;
  }
};
