import React, { useEffect, useState } from "react";
import {
  DataGrid as MuiDataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import styles from "./styles/table.module.scss"; // Ensure the path is correct
import { useDispatch } from "react-redux";

// Custom Toolbar component if needed
function CustomToolbar({ columns }) {
  return (
    <GridToolbarContainer className={styles.toolbar}>
      <GridToolbarColumnsButton className={styles.toolbarItem} />
      <GridToolbarFilterButton className={styles.toolbarItem} />
      <GridToolbarExport
        className={styles.toolbarItem}
        csvOptions={{
          fields: columns
            ?.filter((val) => val.hide !== true)
            .map((v) => v.field),
        }}
      />
    </GridToolbarContainer>
  );
}

function MyDataGrid({
  columns = [],
  rows = [],
  loading = false,
  disabledRowId = null,
  getRowId,
  onSelectionModelChange,
  // onRowClick = () => window.alert("Row clicked!"), // Corrected to use lowercase 'window'
  onRowClick = undefined, // Corrected to use lowercase 'window'
  disableSelectionOnClick = true,

  rowsPerPageOptions = [25],
  initialState,
  filterModel,
  onFilterModelChange,
  sx = {},
  componentsProps = {},
  setPageState,
  pageState,
  rowCount = 25,
  density = "standard",
  activeRowId = null,
}) {
  const onPageChange = (pageNo) => {
    setPageState((prev) => ({
      ...prev,
      page: pageNo + 1,
    }));
  };

  return (
    <MuiDataGrid
      density={density}
      autoHeight
      className={styles.table}
      columns={columns}
      rows={rows}
      isRowSelectable={false}
      loading={loading}
      components={{
        Toolbar: (props) => <CustomToolbar columns={columns} {...props} />,
      }}
      getRowId={getRowId}
      onSelectionModelChange={onSelectionModelChange}
      onRowClick={onRowClick}
      disableSelectionOnClick={disableSelectionOnClick}
      // pageSize={pageSize}
      rowsPerPageOptions={rowsPerPageOptions}
      initialState={initialState}
      onFilterModelChange={onFilterModelChange}
      filterModel={filterModel ? filterModel : undefined}
      rowCount={rowCount}
      // paginationModel={paginationModel}
      paginationMode="server"
      // onPaginationModelChange={(model) => {
      //   console.log("Pagination model changed:", model);
      //   setPaginationModel(model);
      //   // fetchDataBasedOnPagination(); // Fetch data on pagination change
      // }}
      onPageChange={onPageChange}
      pageSize={pageState?.pageSize || 25}
      sx={{
        ".MuiTablePagination-root": {
          overflow: "hidden",
        },
        ".MuiTablePagination-toolbar": {
          paddingX: "1.5rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "baseline",
          "@media(max-width:992px)": {
            paddingX: "3.5rem",
          },
        },

        ...sx,
      }}
      componentsProps={{
        pagination: {
          component: "div",
          style: {
            flex: 1,
          },
        },
        panel: {
          placement: "bottom-start",
        },
        ...componentsProps,
      }}
    />
  );
}

export default MyDataGrid;
