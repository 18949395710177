import { pcpOfficeTypes } from "../types/pcpoffice.type";

export const pcpOfficeActions = {
  pcpofficeRequest,
  pcpofficeRequestSuccess,
  pcpofficeRequestFailure,
  pcpofficeSaveRequest,
  pcpofficeSaveRequestSuccess,
  pcpofficeSaveRequestFailure,
  pcpofficeEditRequest,
  pcpofficeEditRequestSuccess,
  pcpofficeEditRequestFailure,
  pcpofficeUpdateStatusRequest,
  pcpofficeUpdateStatusRequestSuccess,
  pcpofficeUpdateStatusRequestFailure,
  pcpofficeRequestReset,
};

//getAll PCP OFFICE
function pcpofficeRequest(query, token) {
  return {
    type: pcpOfficeTypes.PCP_OFFICE_REQUEST,
    query,
    token,
  };
}

function pcpofficeRequestSuccess(getAll) {
  return {
    type: pcpOfficeTypes.PCP_OFFICE_REQUEST_SUCCESS,
    getAll,
  };
}

function pcpofficeRequestFailure(error) {
  return {
    type: pcpOfficeTypes.PCP_OFFICE_REQUEST_FAILURE,
    error,
  };
}

//Save PCP Office
function pcpofficeSaveRequest(request, token) {
  return {
    type: pcpOfficeTypes.PCP_OFFICE_SAVE_REQUEST,
    request,
    token,
  };
}

function pcpofficeSaveRequestSuccess(message) {
  return {
    type: pcpOfficeTypes.PCP_OFFICE_SAVE_REQUEST_SUCCESS,
    message,
  };
}

function pcpofficeSaveRequestFailure(error) {
  return {
    type: pcpOfficeTypes.PCP_OFFICE_SAVE_REQUEST_FAILURE,
    error,
  };
}

//update PCP office
function pcpofficeEditRequest(query, payload, token) {
  return {
    type: pcpOfficeTypes.PCP_OFFICE_EDIT_REQUEST,
    query,
    payload,
    token,
  };
}

function pcpofficeEditRequestSuccess(message) {
  return {
    type: pcpOfficeTypes.PCP_OFFICE_EDIT_REQUEST_SUCCESS,

    message,
  };
}

function pcpofficeEditRequestFailure(error) {
  return {
    type: pcpOfficeTypes.PCP_OFFICE_EDIT_REQUEST_FAILURE,
    error,
  };
}

//IsActive PCP office
function pcpofficeUpdateStatusRequest(query, payload, token) {
  return {
    type: pcpOfficeTypes.PCP_OFFICE_UPDATE_STATUS_REQUEST,
    query,
    payload,
    token,
  };
}

function pcpofficeUpdateStatusRequestSuccess(id, message) {
  return {
    type: pcpOfficeTypes.PCP_OFFICE_UPDATE_STATUS_REQUEST_SUCCESS,
    id,
    message,
  };
}

function pcpofficeUpdateStatusRequestFailure(error) {
  return {
    type: pcpOfficeTypes.PCP_OFFICE_UPDATE_STATUS_REQUEST_FAILURE,
    error,
  };
}

//reset
function pcpofficeRequestReset() {
  return {
    type: pcpOfficeTypes.PCP_OFFICE_REQUEST_RESET,
  };
}
