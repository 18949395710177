import { userTypes } from "../types/user.type";

export const userActions = {
    userRequest,
    userRequestSuccess,
    userRequestFailure,
  };
  function userRequest(request, token) {
    return {
      type: userTypes.GET_USER_REQUEST,
      request,
      token,
    };
  }
  function userRequestSuccess(get) {
    return {
      type: userTypes.GET_USER_REQUEST_SUCCESS,
      get,
    };
  }
  function userRequestFailure(error) {
    return {
      type: userTypes.GET_USER_REQUEST_FAILURE,
      error,
    };
  }
  