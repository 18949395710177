import { put, takeLatest, call } from "@redux-saga/core/effects";
import { updatePhoneNoActions } from "../actions";
import { updatePhoneNoTypes } from "../types";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";

export function* watchCMDUpdatePhoneNo() {
  yield takeLatest(updatePhoneNoTypes.UPDATE_PHONE_NO_REQUEST, updatePhoneNo);
}

function* updatePhoneNo(action) {
  try {
    let message = yield call(Api, {
      method: "POST",
      endpoint: `${endpoint.updateCMDPhoneNo}`,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    const parsedMessage = JSON.parse(message);

    yield put(
      updatePhoneNoActions.updatePhoneNoRequestSuccess(parsedMessage[0])
    );
  } catch (error) {
    yield put(updatePhoneNoActions.updatePhoneNoRequestFailure(error));
  }
}
