import { updatePhoneNoTypes } from "../types";

export const updatePhoneNoActions = {
  updatePhoneNoRequest,
  updatePhoneNoRequestReset,
  updatePhoneNoRequestSuccess,
  updatePhoneNoRequestFailure,
};
function updatePhoneNoRequest(request, token) {
  return {
    type: updatePhoneNoTypes.UPDATE_PHONE_NO_REQUEST,
    request,
    token,
  };
}
function updatePhoneNoRequestReset() {
  return {
    type: updatePhoneNoTypes.UPDATE_PHONE_NO_REQUEST_RESET,
  };
}
function updatePhoneNoRequestSuccess(message) {
  return {
    type: updatePhoneNoTypes.UPDATE_PHONE_NO_REQUEST_SUCCESS,
    message,
  };
}
function updatePhoneNoRequestFailure(error) {
  return {
    type: updatePhoneNoTypes.UPDATE_PHONE_NO_REQUEST_FAILURE,
    error,
  };
}
