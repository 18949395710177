import { call, put, takeLatest } from "@redux-saga//core/effects";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { advanceDirectiveActions } from "../actions";
import { advanceDirectiveTypes } from "../types";

export function* watchAdvanceDirective() {
  yield takeLatest(
    advanceDirectiveTypes.ADVANCE_DIRECTIVE_OPERATION_REQUEST,
    getAdvanceDirectiveOperation
  );
  yield takeLatest(
    advanceDirectiveTypes.ADVANCE_DIRECTIVE_REQUEST,
    getAdvanceDirective
  );
}

//for update
function* getAdvanceDirectiveOperation(action) {
  try {
    let message = yield call(Api, {
      endpoint: endpoint.savePatientAdvanceDirectiveDetails,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    const parsedMessage = JSON.parse(message);

    yield put(
      advanceDirectiveActions.advanceDirectiveOperationRequestSuccess(
        parsedMessage[0]
      )
    );
  } catch (error) {
    console.log(error);
    yield put(
      advanceDirectiveActions.advanceDirectiveOperationRequestSuccess({
        Result: error.message,
      })
    );
    // yield put(
    //   advanceDirectiveActions.advanceDirectiveOperationRequestFailure(
    //     error.message
    //   )
    // );
  }
}

//for update
function* getAdvanceDirective(action) {
  try {
    let data = yield call(Api, {
      endpoint: `${endpoint.getAdvanceDirectiveDetails}?patientId=${action.request.patientId}`,
      method: "POST",
      jwt: "Bearer " + action.token,
    });
    const parsedData = JSON.parse(data);

    yield put(
      advanceDirectiveActions.advanceDirectiveRequestSuccess(
        parsedData.data,
        parsedData.DnrStatus
      )
    );
  } catch (error) {
    yield put(advanceDirectiveActions.advanceDirectiveRequestFailure(error));
  }
}
