import { getallModifierType } from "../types";

export const getallModifierActions = {
  getallModifierRequest,
  getallModifierRequestSuccess,
  getallModifierRequestFailure,
};
function getallModifierRequest(token, request) {
  return {
    type: getallModifierType.GETALL_MODIFIER_REQUEST,
    token,
    request,
  };
}
function getallModifierRequestSuccess(getAll) {
  return {
    type: getallModifierType.GETALL_MODIFIER_REQUEST_SUCCESS,
    getAll,
  };
}
function getallModifierRequestFailure(error) {
  return {
    type: getallModifierType.GETALL_MODIFIER_REQUEST_FAILURE,
    error,
  };
}
