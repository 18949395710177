import React from "react";
import styles from "./InputField.module.scss";

const InputField = (propsold) => {
  const {
    // minWidth = "20vw",
    // marginLeft = 0,
    // marginTop = 0,
    ...props
  } = propsold;
  return (
    <>
      <div className={styles.info_row}>
        <p className={styles.label}>{props.label}:</p>

        <input
          className={styles.value}
          style={
            {
              // minWidth: minWidth,
              // marginLeft: marginLeft,
            }
          }
          {...props}
        />
      </div>
    </>
  );
};

export { InputField };
