import { alertType } from "./AlertNew.type";
function alertSuccessRequest(message) {
  return { type: alertType.ALERT_SUCCESS_REQUEST, message };
}
function alertErrorRequest(message) {
  return { type: alertType.ALERT_ERROR_REQUEST, message };
}
function alertResetRequest(message) {
  return { type: alertType.ALERT_RESET_REQUEST };
}

export const alertActions = {
  alertSuccessRequest,
  alertErrorRequest,
  alertResetRequest,
};
