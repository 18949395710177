import { put, takeLatest, call } from "@redux-saga/core/effects";
import { endpoint } from "../../../../api/Url";
import { userOperationActions } from "../actions";
import { userOperationTypes } from "../types";
import { Api2 } from "../../../../api/Api2";
import { alertActions } from "../../../../components/AlertNew/store/AlertNew.action";

export function* watchDMUserOperation() {
  yield takeLatest(userOperationTypes.USER_OPERATION_REQUEST, userOperation);
}

function* userOperation(action) {
  let {
    flag,
    userId,
    loginId,
    password,
    userName,
    firstName,
    lastName,
    contactNo,
    emailId,
    isActivecreatedBy,
    roleId,
    roleIdentity,
    isActive,
    createdBy,
  } = action.request;
  if (flag === "Insert") {
    try {
      let data = yield call(Api2, {
        method: "POST",
        endpoint: endpoint.createUsersDmRole,
        jwt: "Bearer " + action.token,
        body: {
          loginId,
          password,
          userName,
          firstName,
          lastName,
          contactNo,
          emailId,
          isActivecreatedBy,
          roleId,
          roleIdentity,
          isActive,
          createdBy,
        },
      });
      yield put(
        userOperationActions.userOperationRequestSuccess(data?.message)
      );
      yield put(alertActions.alertSuccessRequest(data?.message));
    } catch (error) {
      yield put(userOperationActions.userOperationRequestFailure(error));
      yield put(alertActions.alertErrorRequest(error?.message));
    }
  } else {
    try {
      let data = yield call(Api2, {
        method: "PATCH",
        endpoint: endpoint.createUsersDmRole,
        jwt: "Bearer " + action.token,
        body: {
          loginId,
          password,
          userName,
          firstName,
          lastName,

          contactNo,
          emailId,
          isActivecreatedBy,
          roleId,
          roleIdentity,
          isActive,
          createdBy,
          userId,
        },
      });
      yield put(
        userOperationActions.userOperationRequestSuccess(data?.message)
      );
      yield put(alertActions.alertSuccessRequest(data?.message));
    } catch (error) {
      yield put(userOperationActions.userOperationRequestFailure(error));
      yield put(alertActions.alertErrorRequest(error?.message));
    }
  }
}
