import { put, takeLatest, call, debounce } from "@redux-saga/core/effects";

import { endpoint } from "../../../api/Url";
import { Api2 } from "../../../api/Api2";
import { loginActions } from "../../Login/actions/login.action";
import {
  receptionPatientFailure,
  receptionPatientSuccess,
} from "../slices/patient.slice";
import {
  patientDetailsUpdateReceptionFaliure,
  patientDetailsUpdateReceptionSuccess,
} from "../slices/patientDetailsUpdateReception.slice";
import { alertActions } from "../../../components/AlertNew/store/AlertNew.action";
import {
  receptionPatientNotesHistoryFailure,
  receptionPatientNotesHistorySuccess,
} from "../slices/patientNotesHistory.slice";
//For patients page request
export function* watchReceptionPatients() {
  yield debounce(700, "receptionPatient/receptionPatientRequest", getPatients);
  yield takeLatest(
    "patientDetailsUpdateReception/patientDetailsUpdateReceptionRequest",
    patientDetailsUpdateRecption
  );
  yield debounce(
    200,
    "receptionPatientNotesHistory/receptionPatientNotesHistoryRequest",
    getPatientNotesHistory
  );
}
//For patients page request
function* getPatients(action) {
  try {
    //   api call
    const res = yield call(Api2, {
      endpoint: `${endpoint.receptionPatients}?search=${action.payload.search}`,
      method: "GET",
      jwt: "Bearer " + action.payload.token,
    });

    yield put(receptionPatientSuccess(res.data));
  } catch (error) {
    yield put(receptionPatientFailure(error));

    if (error.code === 401 && error.code === 403)
      yield put(loginActions.logoutRequest());
  }
}
function* patientDetailsUpdateRecption(action) {
  try {
    //   api call
    const res = yield call(Api2, {
      endpoint: `${endpoint.patientDetailsUpdate}`,
      method: "POST",
      jwt: "Bearer " + action.payload.token,
      body: action.payload.body,
    });

    yield put(patientDetailsUpdateReceptionSuccess(res.data));
    yield put(alertActions.alertSuccessRequest(res.data?.message));
  } catch (error) {
    yield put(patientDetailsUpdateReceptionFaliure(error));
    yield put(alertActions.alertErrorRequest(error.message));

    if (error.code === 401 && error.code === 403)
      yield put(loginActions.logoutRequest());
  }
}
function* getPatientNotesHistory(action) {
  try {
    //   api call
    const res = yield call(Api2, {
      endpoint: `${endpoint.receptionistPatientNotesHistory}/${action?.payload?.id}/notes`,
      method: "GET",
      jwt: "Bearer " + action.payload.token,
    });
    console.log(res);
    yield put(receptionPatientNotesHistorySuccess(res?.data));
  } catch (error) {
    console.log(error);
    yield put(receptionPatientNotesHistoryFailure(error));
  }
}
