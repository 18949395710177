import { getSocialDeterministTypes } from "../types";

export const getSocialDeterministActions = {
  socialDeterministRequest,
  socialDeterministRequestSuccess,
  socialDeterministRequestFailure,
};
function socialDeterministRequest(request, token) {
  return {
    type: getSocialDeterministTypes.GET_SOICALDETERMINIST_REQUEST,
    request,
    token,
  };
}
function socialDeterministRequestSuccess(get) {
  return {
    type: getSocialDeterministTypes.GET_SOICALDETERMINIST_REQUEST_SUCCESS,
    get,
  };
}
function socialDeterministRequestFailure(error) {
  return {
    type: getSocialDeterministTypes.GET_SOICALDETERMINIST_REQUEST_FAILURE,
    error,
  };
}
