import { userPermissionTypes } from "../types/userpermission.type";

export const userPermissionActions = {
  userpermissionSaveRequest,
  userpermissionSaveRequestSuccess,
  userpermissionSaveRequestFailure,
  userpermissionRequestReset,
  userpermissionGetRequest,
  userpermissionGetRequestSuccess,
  userpermissionGetRequestFailure,
};

//save userpermission

function userpermissionSaveRequest(request, token) {
  return {
    type: userPermissionTypes.USER_PERMISSION_SAVE_REQUEST,
    request,
    token,
  };
}

function userpermissionSaveRequestSuccess(message) {
  return {
    type: userPermissionTypes.USER_PERMISSION_SAVE_REQUEST_SUCCESS,
    message,
  };
}

function userpermissionSaveRequestFailure(error) {
  return {
    type: userPermissionTypes.USER_PERMISSION_SAVE_REQUEST_FAILURE,
    error,
  };
}

//get userpermission

function userpermissionGetRequest(request, token) {
  return {
    type: userPermissionTypes.USER_PERMISSION_GET_REQUEST,
    request,
    token,
  };
}

function userpermissionGetRequestSuccess(get) {
  return {
    type: userPermissionTypes.USER_PERMISSION_GET_REQUEST_SUCCESS,
    get,
  };
}

function userpermissionGetRequestFailure(error) {
  return {
    type: userPermissionTypes.USER_PERMISSION_GET_REQUEST_FAILURE,
    error,
  };
}
//reset
function userpermissionRequestReset() {
  return {
    type: userPermissionTypes.USER_PERMISSION_REQUEST_RESET,
  };
}
