import { userTypes } from "../types/user.type";

const initiaLState = {
    loading: false,
    get: [],
  };

  export const userReducer = (state = initiaLState, action) => {
    switch (action.type) {
      case userTypes.GET_USER_REQUEST:
        return {
          ...initiaLState,
          loading: true,
        };
      case userTypes.GET_USER_REQUEST_SUCCESS:
        return {
          ...initiaLState,
          get: action.get,
        };
      case userTypes.GET_USER_REQUEST_FAILURE:
        return {
          ...initiaLState,
          error: action.error,
        };
      default:
        return state;
    }
  };
  