import { addActivePatientMedicationTransitionType } from "../../types";


const initialState = {
  loading: false,
  message: "",
  error: "",
};

export const addActivePatientMediationTCReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case addActivePatientMedicationTransitionType.ADD_ACTIVE_PATIENT_MEDICATION_TC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case addActivePatientMedicationTransitionType.ADD_ACTIVE_PATIENT_MEDICATION_TC_SUCCESS:
      return {
        ...state,
        message: action.message,
        loading: false,
      };
    case addActivePatientMedicationTransitionType.ADD_ACTIVE_PATIENT_MEDICATION_TC_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case addActivePatientMedicationTransitionType.ADD_ACTIVE_PATIENT_MEDICATION_TC_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
