import { bundleDisposition } from "../types";

export const bundleDispositionActions = {
  bundleDispositionRequest,
  bundleDispositionRequestSuccess,
  bundleDispositionRequestFailure,
};
function bundleDispositionRequest(request, token) {
  return {
    type: bundleDisposition.BUNDLE_DISPOSITION_REQUEST,
    request,
    token,
  };
}
function bundleDispositionRequestSuccess(getAll) {
  return {
    type: bundleDisposition.BUNDLE_DISPOSITION_REQUEST_SUCCESS,

    getAll,
  };
}
function bundleDispositionRequestFailure(error) {
  return {
    type: bundleDisposition.BUNDLE_DISPOSITION_REQUEST_FAILURE,

    error,
  };
}
