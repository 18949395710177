import { call, put, takeLatest } from "redux-saga/effects";
import { Api2 } from "../../../../api/Api2";
import { endpoint } from "../../../../api/Url";
import {
  getAllDuplicatePatientsTypes,
  mergeDuplicatePatientsTypes,
  mergePatientFacilityLookupTypes,
} from "../types";
import { getAllDuplicatePatientsActions } from "../actions/getAllDuplicatePatients.action";
import { alertActions } from "../../../../components/AlertNew/store/AlertNew.action";
import { mergeDuplicatePatientsActions } from "../actions/mergeDuplicatePatient.action";
import { mergePatientFacilityLookupActions } from "../actions";

export function* watchDuplicatePatientsMerging() {
  yield takeLatest(
    getAllDuplicatePatientsTypes.GETALL_DUPLICATE_PATIENTS_REQUEST,
    getAllDuplicatePatients
  );
  yield takeLatest(
    mergeDuplicatePatientsTypes.MERGE_DUPLICATE_PATIENTS_REQUEST,
    handleMergeDuplicatePatient
  );
  yield takeLatest(
    mergePatientFacilityLookupTypes.MERGE_PATIENT_FACILITY_LOOKUP_REQUEST,
    mergePatientFacilityLookup
  );
}

function* getAllDuplicatePatients(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.getAllDuplicatePatients}?PhyTypeId=${action?.query?.physicianId}&FacilityId=${action?.query?.facilityId}`,
      jwt: "Bearer " + action.token,
    });
    yield put(
      getAllDuplicatePatientsActions.getAllDuplicatePatientsSuccess(
        response?.patients
      )
    );
  } catch (error) {
    yield put(alertActions.alertErrorRequest("error"));
    yield put(
      getAllDuplicatePatientsActions.getAllDuplicatePatientsFailure(error)
    );
  }
}

function* handleMergeDuplicatePatient(action) {
  try {
    let response = yield call(Api2, {
      method: "POST",
      endpoint: `${endpoint.mergeDuplicatePatients}`,
      jwt: "Bearer " + action.token,
      body: action.AdmissionIds,
    });
    yield put(
      mergeDuplicatePatientsActions.mergeDuplicatePatientsSuccess(
        response?.message.result
      )
    );
    yield put(alertActions.alertSuccessRequest(response?.message.result));
  } catch (error) {
    yield put(alertActions.alertErrorRequest("error"));
    yield put(
      mergeDuplicatePatientsActions.mergeDuplicatePatientsFailure(error)
    );
  }
}

function* mergePatientFacilityLookup(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.mergePatientFacilityLookup}`,
      jwt: "Bearer " + action.token,
    });
    yield put(
      mergePatientFacilityLookupActions.mergePatientFacilityLookupSuccess(
        response?.data
      )
    );
  } catch (error) {
    yield put(alertActions.alertErrorRequest("error"));
    yield put(
      mergePatientFacilityLookupActions.mergePatientFacilityLookupFailure(error)
    );
  }
}
