import { put, call, takeLatest } from "@redux-saga/core/effects";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { familyHistoryActions } from "../actions";
import { familyHistoryTypes } from "../types";
export function* watchFamilyHistory() {
  yield takeLatest(
    familyHistoryTypes.GETALL_FAMILY_HISTORY_REQUEST,
    getAllFamilyHistory
  );
  yield takeLatest(
    familyHistoryTypes.OPERARTION_FAMILY_HISTORY_REQUEST,
    operationFamilyHistory
  );
  // yield takeLatest(familyHistoryTypes.DELETE_SURGICAL_REQUEST, deleteFamily);
}
function* getAllFamilyHistory(action) {
  try {
    let data = yield call(Api, {
      endpoint: `${endpoint.getFamilyHistoryDetails}?patientId=${action.request.patientId}`,
      method: "POST",
      jwt: "Bearer " + action.token,
    });
    let parsedFamily = JSON.parse(data);

    yield put(
      familyHistoryActions.familyHistoryGetAllRequestSuccess(
        parsedFamily._lstFamilyHistory,
        parsedFamily._dropdownRelationShipModel
      )
    );
  } catch (error) {
    yield put(familyHistoryActions.familyHistoryGetAllRequestFailure(error));
  }
}
function* operationFamilyHistory(action) {
  try {
    let message = yield call(Api, {
      endpoint: `${endpoint.saveFamilyHistoryDetails}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedMessage = JSON.parse(message);

    yield put(
      familyHistoryActions.familyHistoryOperationRequestSuccess(
        parsedMessage[0]
      )
    );
  } catch (error) {
    yield put(familyHistoryActions.familyHistoryOperationRequestFailure(error));
  }
}

// function* deleteFamily(action) {
//   try {
//     let message = yield call(Api, {
//       endpoint: `${endpoint.deletePatientFamilyHistoryDetails}`,
//       method: "POST",
//       jwt: "Bearer " + action.token,
//       body: action.request,
//     });
//     let parsedMessage = JSON.parse(message);

//     yield put(
//       familyHistoryActions.familyHistoryDeleteRequestSuccess(parsedMessage[0])
//     );
//   } catch (error) {
//     yield put(familyHistoryActions.familyHistoryDeleteRequestFailure(error));
//   }
// }
