import { problemListTypes } from "../types";

export const problemListActions = {
  //for get
  problemListGetAllRequest,
  problemListGetAllRequestSuccess,
  problemListGetAllRequestFailure,

  //for update delete insert
  problemListOperationRequest,
  problemListOperationRequestSuccess,
  problemListOperationRequestFailure,
  problemListOperationRequestReset,

  //for- delete -
  problemListDeleteRequest,
  problemListDeleteRequestSuccess,
  problemListDeleteRequestFailure,
  problemListDeleteRequestReset,
};
function problemListGetAllRequest(request, token) {
  return {
    type: problemListTypes.GETALL_PROBLEM_REQUEST,
    request,
    token,
  };
}
function problemListGetAllRequestSuccess(getAll, dropdownSourcelist) {
  return {
    type: problemListTypes.GETALL_PROBLEM_REQUEST_SUCCESS,
    getAll,
    // dropdownSourcelist,
  };
}
function problemListGetAllRequestFailure(error) {
  return {
    type: problemListTypes.GETALL_PROBLEM_REQUEST_FAILURE,
    error,
  };
}

function problemListOperationRequest(request, token) {
  return {
    type: problemListTypes.OPERARTION_PROBLEM_REQUEST,
    request,
    token,
  };
}
function problemListOperationRequestSuccess(message) {
  return {
    type: problemListTypes.OPERARTION_PROBLEM_REQUEST_SUCCESS,
    message,
  };
}
function problemListOperationRequestFailure(error) {
  return {
    type: problemListTypes.OPERARTION_PROBLEM_REQUEST_FAILURE,
    error,
  };
}
function problemListOperationRequestReset() {
  return {
    type: problemListTypes.OPERARTION_PROBLEM_REQUEST_RESET,
  };
}

function problemListDeleteRequest(request, token) {
  return {
    type: problemListTypes.DELETE_PROBLEM_REQUEST,
    request,
    token,
  };
}
function problemListDeleteRequestSuccess(message) {
  return {
    type: problemListTypes.DELETE_PROBLEM_REQUEST_SUCCESS,
    message,
  };
}
function problemListDeleteRequestFailure(error) {
  return {
    type: problemListTypes.DELETE_PROBLEM_REQUEST_FAILURE,
    error,
  };
}
function problemListDeleteRequestReset() {
  return {
    type: problemListTypes.DELETE_PROBLEM_REQUEST_RESET,
  };
}
