import { put, takeLatest, call } from "@redux-saga/core/effects";
import { addTaskDetailsActions } from "../actions";
import { addTaskDetailsType } from "../types";
import { endpoint } from "../../../../api/Url";
import { Api } from "../../../../api";
import { loginActions } from "../../../Login/actions/login.action";

export function* watchCMDAddTasksDetails() {
  yield takeLatest(addTaskDetailsType.ADD_TASK_DETAILS_REQUEST, addTaskDetails);
}

function* addTaskDetails(action) {
  try {
    //   api call
    const data = yield call(Api, {
      endpoint: `${endpoint.saveCMDAddTasksDetail}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    const result = JSON.parse(data);

    result[0].Result !== undefined && result[0].Result === "Success"
      ? yield put(addTaskDetailsActions.addTaskDetailsRequestSuccess(result[0]))
      : yield put(
          addTaskDetailsActions.addTaskDetailsRequestFailure(
            "Something went wrong."
          )
        );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(addTaskDetailsActions.addTaskDetailsRequestFailure(error));
  }
}
