//import dependencies
import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { loginActions } from "../../../../Login/actions/login.action";

//api

import { powerbiEpisodicActions } from "../actions/powerbiEpisodic.action";
import { powerbiEpisodicType } from "../types/powerbEpisodic.type";

export function* watchPowerBiEpisodicReport() {
  yield takeLatest(powerbiEpisodicType.POWERBI_REQUEST, fetchReportData);
}

function* fetchReportData(action) {
  try {
    //   api call
    const data = yield call(Api, {
      // endpoint: `${endpoint.powerbiReport}?RoleId=${action.roleId}`,
      endpoint: `${endpoint.EpisodicPowerBiReport}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    const parsedData = JSON.parse(data);
    console.log(parsedData);

    if (parsedData.EmbedToken === null) {
      yield put(
        powerbiEpisodicActions.powerbiEpisodicRequestFailure(parsedData)
      );
    } else {
      yield put(
        powerbiEpisodicActions.powerbiEpisodicRequestSuccess(parsedData)
      );
    }
  } catch (error) {
    console.log(error);
    yield put(powerbiEpisodicActions.powerbiEpisodicRequestFailure(error));

    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
  }
}
