import { getRvuReportType } from "../types/getRvuReport.type";

const initialState = {
  loading: false,
  getAll: [],
  totalPages: 0,
  totalRows: 0,
};

export const getRvuReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case getRvuReportType.GET_RVU_REPORT_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case getRvuReportType.GET_RVU_REPORT_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
        totalPages: action.totalPages,
        totalRows: action.totalRows,
        totalRVU: action.totalRVU,
      };
    case getRvuReportType.GET_RVU_REPORT_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    default:
      return state;
  }
};
