import { getActivePatientTransitionType } from "../types";

export const getActivePatientTCActions = {
  getallActivePatientRequest,
  getallActivePatientSuccess,
  getallActivePatientFailure,
  getallActivePatientReset,
};
function getallActivePatientRequest(query, token) {
  return {
    type: getActivePatientTransitionType.GETALL_ACTIVE_PATIENT_TC_REQUEST,
    query,
    token,
  };
}
function getallActivePatientSuccess(getAll, stay,otherData) {
  return {
    type: getActivePatientTransitionType.GETALL_ACTIVE_PATIENT_TC_SUCCESS,
    getAll,
    stay,
    otherData
  };
}
function getallActivePatientFailure(error) {
  return {
    type: getActivePatientTransitionType.GETALL_ACTIVE_PATIENT_TC_FAILURE,
    error,
  };
}
function getallActivePatientReset() {
  return {
    type: getActivePatientTransitionType.GETALL_ACTIVE_PATIENT_TC_RESET,
  };
}
