import { hedisProcedureTypes } from "../types/hedis.type";

const initialState = {
  loading: false,
  getAll: [],
  message: "",
  error: "",
};

export const hedisProcedureReducer = (state = initialState, action) => {
  switch (action.type) {
    case hedisProcedureTypes.GETALL_PROCEDURELIST_REQUEST:
      return { ...initialState, loading: true };

    case hedisProcedureTypes.GETALL_PROCEDURELIST_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
      };

    case hedisProcedureTypes.GETALL_PROCEDURELIST_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    case hedisProcedureTypes.UPDATE_PROCEDURELIST_REQUEST:
      return { ...initialState, loading: true };

    case hedisProcedureTypes.UPDATE_PROCEDURELIST_SUCCESS:
      return { ...initialState, message: action.message };

    case hedisProcedureTypes.UPDATE_PROCEDURELIST_FAILURE:
      return { ...initialState, error: action.error };

    case hedisProcedureTypes.RESET_PROCEDURELIST:
      return initialState;

    default:
      return state;
  }
};
