import { createSlice } from "@reduxjs/toolkit";

// Initial state for fetching nursing progress
const initialGetState = {
  loading: false,
  data: [], // Renamed getAll to data for clarity
  error: "",
};

// Slice for geting nursing progress
const getNursingProgressSlice = createSlice({
  name: "getNursingProgress",
  initialState: initialGetState,
  reducers: {
    getNursingProgressRequest: (state) => {
      state.loading = true;
    },
    getNursingProgressSuccess: (state, action) => {
      state.loading = false;
      state.error = "";
      state.data = action.payload.data;
    },
    getNursingProgressFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    getNursingProgressReset: () => initialGetState, // Simplified reset
  },
});

// Export actions and reducer for geting nursing progress
export const {
  getNursingProgressRequest,
  getNursingProgressSuccess,
  getNursingProgressFailure,
  getNursingProgressReset,
} = getNursingProgressSlice.actions;

export const getNursingProgressReducer = getNursingProgressSlice.reducer;

// Initial state for updating nursing progress
const initialUpdateState = {
  loading: false,
  message: "",
  error: "",
};

// Slice for updating nursing progress
const updateNursingProgressSlice = createSlice({
  name: "updateNursingProgress",
  initialState: initialUpdateState,
  reducers: {
    updateNursingProgressRequest: (state) => {
      console.log(state);
      state.loading = true;
    },
    updateNursingProgressSuccess: (state, action) => {
      console.log(action);
      state.loading = false;
      state.error = "";
      state.message = action.payload.message;
    },
    updateNursingProgressFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    updateNursingProgressReset: () => initialUpdateState, // Simplified reset
  },
});

// Export actions and reducer for updating nursing progress
export const {
  updateNursingProgressRequest,
  updateNursingProgressSuccess,
  updateNursingProgressFailure,
  updateNursingProgressReset,
} = updateNursingProgressSlice.actions;

export const updateNursingProgressReducer = updateNursingProgressSlice.reducer;
