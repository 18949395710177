export const replyMailTypes = {
  REPLY_MAIL_REQUEST: "REPLY_MAIL_REQUEST",
  REPLY_MAIL_REQUEST_RESET: "REPLY_MAIL_REQUEST_RESET",
  REPLY_MAIL_REQUEST_SUCCESS: "REPLY_MAIL_REQUEST_SUCCESS",
  REPLY_MAIL_REQUEST_FAILURE: "REPLY_MAIL_REQUEST_FAILURE",

  PATIENTCHAT_REPLY_MAIL_REQUEST: "PATIENTCHAT_REPLY_MAIL_REQUEST",
  PATIENTCHAT_REPLY_MAIL_REQUEST_RESET: "PATIENTCHAT_REPLY_MAIL_REQUEST_RESET",
  PATIENTCHAT_REPLY_MAIL_REQUEST_SUCCESS:
    "PATIENTCHAT_REPLY_MAIL_REQUEST_SUCCESS",
  PATIENTCHAT_REPLY_MAIL_REQUEST_FAILURE:
    "PATIENTCHAT_REPLY_MAIL_REQUEST_FAILURE",
};
