import React, { useEffect, useState } from "react";
import { Button } from "../../../../../core";
import styles from "./MessageForm.module.scss";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../../../../util";
import { patientMessageActions } from "../../patientMessage.action";
import { Alert } from "../../../../../components";
export const MessageForm = ({
  patientName,
  show,
  getShow,
  patientId,
  pcpId,
}) => {
  const dispatch = useDispatch();
  // const patientDetails = useSelector((state) => state.basicInfo);
  // const patientDetailsTc = useSelector((state) => state.tcPatientDetails);
  const userData = useSelector((state) => state.login.userData);

  const patientMessage = useSelector((state) => state.patientMessage);

  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const sendMessage = () => {
    if (!validate()) {
      if (userData.token !== undefined) {
        let request = {
          chatMessages: message,
          patientID: patientId,
          pcpID: pcpId,
        };
        dispatch(
          patientMessageActions.patientMessageSendRequest(
            request,
            userData.token
          )
        );
      }
    }
  };

  const validate = () => {
    let cancel = false;
    if (isEmpty(message.trim())) {
      cancel = true;
      setErrorMessage("*Required");
    } else {
      setErrorMessage("");
    }
    return cancel;
  };

  useEffect(() => {
    if (patientMessage.message !== undefined) {
      setTimeout(() => {
        setMessage("");
        if (
          patientMessage.message.Result === "Success" &&
          userData.token !== undefined
        )
          dispatch(patientMessageActions.patientMessageSendRequestReset());
      }, 1000);
    }
  }, [patientMessage.message]);

  return (
    <div className={show ? styles.container : styles.container_hide}>
      <MdClose
        className={styles.close}
        onClick={() => {
          setMessage("");
          setErrorMessage("");
          getShow(false);
        }}
      />
      Patient:
      <h5>{patientName}</h5>
      <textarea
        className={styles.input_message}
        placeholder="Type a message"
        rows={5}
        cols={50}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <p className={styles.error}>{errorMessage}</p>
      <Button
        label="Send"
        onClick={sendMessage}
        loading={patientMessage.loading}
      />
      {patientMessage.message !== "" && (
        <Alert
          isSuccess={patientMessage.message.Result === "Success"}
          successMessage={"Successfully sent!"}
          failureMessage="failed to send!"
        />
      )}
    </div>
  );
};
