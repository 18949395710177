import { put, call, debounce, takeLatest } from "@redux-saga/core/effects";

import { Api2 } from "../../../api/Api2";
import { endpoint } from "../../../api/Url";
import { alertActions } from "../../../components/AlertNew/store/AlertNew.action";

import {
  getActivePatientMedicationType,
  getActivePatientTransitionType,
  getAllProviderScheduleType,
  createScheduleAppoinmentsType,
  getActivePatientAppointmentsType,
  updatePatientCallStatusTransitionType,
  getAllFacilityLocationType,
  getAllRegionsType,
  getScheduleAppoinmentsType,
  getAllLookUpPatientsType,
  getAllFrequencyLookUpTransitionType,
  getAllRouteLookUpTransitionType,
  getAllDosageLookUpTransitionType,
  getAllDrugLookUpTransitionType,
  addActivePatientMedicationTransitionType,
  deleteMedicationTransitionType,
  getAllLookupPatientStatusType,
  getAllFacilityType,
  getAllProvidersType,
} from "../types";
import {
  getActivePatientMedicationActions,
  getActivePatientTCActions,
  getAllProviderSchedulesTCActions,
  createScheduleAppoinmentsTCActions,
  updatePatientCallStatusTCActions,
  getAllFacilityLocationsTCActions,
  getActivePatientAppointmentsTCActions,
  getAllDrugLookUpTCActions,
  getAllFrequencyLookUpTCActions,
  getAllDosageLookUpTCActions,
  addActivePatientMedicationTCActions,
  deleteMedicationTCActions,
  getAllRouteLookUpTCActions,
  getAllProvidersTCActions,
  getAllRegionsTCActions,
  getScheduleAppoinmentsTCActions,
  getAllLookUpPatientsTCActions,
  getAllLookupPatientStatusTCActions,
  getAllFacilityTypeAction,
} from "../actions";
import {
  refreshAppointmentFaliure,
  refreshAppointmentSuccess,
} from "../reducers/Appointment/refreshAppointments.reducer";

export function* watchActivePatientTC() {
  // to get all the active patients data
  yield debounce(
    900,
    getActivePatientTransitionType.GETALL_ACTIVE_PATIENT_TC_REQUEST,
    getallActivePatient
  );
  // to get all the current patients medications

  yield debounce(
    500,
    getActivePatientMedicationType.GET_ACTIVE_PATIENT_MEDICATION_REQUEST,
    getActivePatientMedication
  );
  yield debounce(
    500,
    getActivePatientAppointmentsType.GET_ACTIVE_PATIENT_APPOINTMENTS_REQUEST,
    getActivePatientAppoinmentMedication
  );
  yield debounce(
    700,
    getAllProviderScheduleType.GETALL_PROVIDER_SCHEDULE_TC_REQUEST,
    getAllProviderSchedules
  );
  yield debounce(
    500,
    getAllFacilityLocationType.GETALL_FACILITY_LOCATION_TC_REQUEST,
    getallFacilityLocations
  );
  yield debounce(
    500,
    // getAllFacilityLocationType.GETALL_FACILITY_LOCATION_TC_REQUEST,
    getAllProvidersType.GETALL_PROVIDERS_TC_REQUEST,
    getAllProviders
  );
  yield debounce(
    500,
    getAllRegionsType.GETALL_REGION_TC_REQUEST,
    getAllRegions
  );
  yield debounce(
    500,
    getScheduleAppoinmentsType.GET_SCHEDULE_APPOINMENTS_TC_REQUEST,
    getScheduleAppoinments
  );
  yield debounce(
    500,
    updatePatientCallStatusTransitionType.UPDATE_PATIENT_CALL_STATUS_TC_REQUEST,
    updatePatientCallStatus
  );
  yield takeLatest(
    createScheduleAppoinmentsType.CREATE_SCHEDULE_APPOINMENTS_TC_REQUEST,
    createScheduleAppoinments
  );
  yield takeLatest(
    getAllLookUpPatientsType.GETALL_LOOKUP_PATIENTS_TC_REQUEST,
    getAllLookUpPatients
  );

  // !medication lookups
  yield takeLatest(
    getAllFrequencyLookUpTransitionType.GETALL_FREQUENCY_LOOKUP_TC_REQUEST,
    getAllFrequencyLookup
  );

  yield takeLatest(
    getAllRouteLookUpTransitionType.GETALL_ROUTE_LOOKUP_TC_REQUEST,
    getallRouteLookup
  );
  yield takeLatest(
    getAllDosageLookUpTransitionType.GETALL_DOSAGE_LOOKUP_TC_REQUEST,
    getAllDosageLookup
  );
  yield takeLatest(
    getAllDrugLookUpTransitionType.GETALL_DRUG_LOOKUP_TC_REQUEST,
    getallDrugLookup
  );
  yield takeLatest(
    addActivePatientMedicationTransitionType.ADD_ACTIVE_PATIENT_MEDICATION_TC_REQUEST,
    addActivePatientMedication
  );
  // yield takeLatest(
  //   editActivePatientMedicationTransitionType.EDIT_ACTIVE_PATIENT_MEDICATION_TC_REQUEST,
  //   editActivePatient
  // );
  yield takeLatest(
    deleteMedicationTransitionType.DELETE_MEDICATION_TC_REQUEST,
    deleteActivePatientMedication
  );
  yield takeLatest(
    getAllLookupPatientStatusType.GETALL_LOOKUP_PATIENT_STATUS_TC_REQUEST,
    getAllLookupPatientStatus
  );
  yield takeLatest(
    getAllFacilityType.GETALL_FACILITY_TYPE_REQUEST,
    getAllFacility
  );
  yield debounce(
    500,
    "refreshAppointment/refreshAppointmentRequest",
    refreshAppointment
  );
}

// / get all discharge patient list
function* getallActivePatient(action) {
  try {
    let url = `${endpoint.transitionCare}?pageSize=${action.query.pageSize}&pageNumber=${action.query.pageNumber}&all=${action.query.all}&patientStatus=${action.query.patientStatus}&facilityTypeId=${action.query.facilityTypeId}`;
    if (
      action.query.search !== "" &&
      action.query.search !== undefined &&
      action.query.search !== null
    ) {
      url = url + `&search=${action.query.search}`;
    }
    let response = yield call(Api2, {
      method: "GET",
      endpoint: url,
      jwt: "Bearer " + action.token,
    });
    yield put(
      getActivePatientTCActions.getallActivePatientSuccess(
        response?.data,
        response?.stay,
        response
      )
    );
  } catch (error) {
    yield put(getActivePatientTCActions.getallActivePatientFailure(error));
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}

// / get all drug  list
function* getallDrugLookup(action) {
  try {
    let response = yield call(Api2, {
      method: "POST",
      endpoint: `${endpoint.getAllDrugLookup}?SearchStr=${action.query.query}`,
      jwt: "Bearer " + action.token,
      body: action.body,
    });

    yield put(
      getAllDrugLookUpTCActions.getAllDrugLookUpSuccess(response?.data)
    );
  } catch (error) {
    yield put(getAllDrugLookUpTCActions.getAllDrugLookUpFailure(error));
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}

// / get all frequency  list
function* getAllFrequencyLookup(action) {
  try {
    let url = `${endpoint.getAllFrequencyLookUp}`;

    let response = yield call(Api2, {
      method: "GET",
      endpoint: url,
      jwt: "Bearer " + action.token,
    });

    yield put(
      getAllFrequencyLookUpTCActions.getAllFrequencyLookUpSuccess(
        response?.data
      )
    );
  } catch (error) {
    yield put(
      getAllFrequencyLookUpTCActions.getAllFrequencyLookUpFailure(error)
    );
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}
// / get all dosage  list
function* getAllDosageLookup(action) {
  try {
    let url = `${endpoint.getAllDosageLookup}`;

    let response = yield call(Api2, {
      method: "GET",
      endpoint: url,
      jwt: "Bearer " + action.token,
    });

    yield put(
      getAllDosageLookUpTCActions.getAllDosageLookUpSuccess(response?.data)
    );
  } catch (error) {
    yield put(getAllDosageLookUpTCActions.getAllDosageLookUpFailure(error));
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}

// addActivePatientMedication  list
function* addActivePatientMedication(action) {
  const method = !action.body?.medicationId ? "POST" : "PUT";
  try {
    let url = `${endpoint.addActivePatientMedication}`;
    // method  = action.body
    let response = yield call(Api2, {
      method: method,
      endpoint: url,
      jwt: "Bearer " + action.token,
      body: action.body,
    });
    yield put(
      addActivePatientMedicationTCActions.addActivePatientMedicationSuccess(
        response?.data
      )
    );

    yield put(alertActions.alertSuccessRequest(response?.data[0]?.Result));
  } catch (error) {
    yield put(
      addActivePatientMedicationTCActions.addActivePatientMedicationFailure(
        error
      )
    );
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}
//edit patient medication
// function* editActivePatient(action) {
//   try {
//     let url = `${endpoint.addActivePatientMedication}`;

//     let response = yield call(Api2, {
//       method: "PUT",
//       endpoint: url,
//       jwt: "Bearer " + action.token,
//       body: action.body,
//     });
//     yield put(
//       editActivePatientMedicationTCActions.editActivePatientMedicationSuccess(
//         response?.data
//       )
//     );

//     yield put(alertActions.alertSuccessRequest(response?.data[0]?.result));
//   } catch (error) {
//     yield put(
//       editActivePatientMedicationTCActions.editActivePatientMedicationFailure(
//         error
//       )
//     );
//     yield put(alertActions.alertErrorRequest(error?.message));
//   }
// }
// delete medication  list
function* deleteActivePatientMedication(action) {
  try {
    const url = `${endpoint.deleteActivePatientMedication}?medicationId=${action.query.medicationId}`;

    const response = yield call(Api2, {
      method: "DELETE",
      endpoint: url,
      jwt: "Bearer " + action.token,
    });

    yield put(
      deleteMedicationTCActions.deleteMedicationTCSuccess(response[0]?.Result)
    );
    yield put(alertActions.alertSuccessRequest(response[0]?.Result));
  } catch (error) {
    yield put(deleteMedicationTCActions.deleteMedicationTCFailure(error));
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}

// / get all route  list
function* getallRouteLookup(action) {
  try {
    let url = `${endpoint.getAllRouteLookup}`;

    let response = yield call(Api2, {
      method: "GET",
      endpoint: url,
      jwt: "Bearer " + action.token,
    });

    yield put(
      getAllRouteLookUpTCActions.getAllRouteLookUpSuccess(response?.data)
    );
  } catch (error) {
    yield put(getAllRouteLookUpTCActions.getAllRouteLookUpFailure(error));
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}

// get active patient medication list
function* getActivePatientMedication(action) {
  console.log("geting");
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.transitionCare}/${action?.query?.patientId}/medication`,
      jwt: "Bearer " + action.token,
    });
    yield put(
      getActivePatientMedicationActions.getActivePatientMedicationSuccess(
        response?.data
      )
    );
  } catch (error) {
    yield put(
      getActivePatientMedicationActions.getActivePatientMedicationFailure(error)
    );
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}

// get active patient appoinment list
function* getActivePatientAppoinmentMedication(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.getActivePatientAppoinments}?patientId=${action.query?.patientId}`,
      jwt: "Bearer " + action.token,
    });
    yield put(
      getActivePatientAppointmentsTCActions.getActivePatientAppointmentsSuccess(
        response?.appointments
      )
    );
  } catch (error) {
    yield put(
      getActivePatientAppointmentsTCActions.getActivePatientAppointmentsFailure(
        error
      )
    );
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}

// get all provider schedules
function* getAllProviderSchedules(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      // endpoint: `${endpoint.getAllProviderSchedulesTc}?from=${action?.query?.from}&to=${action?.query?.to}&regionId=${action?.query?.regionId}&facilityId=${action?.query?.facilityId}&physicianId=${action?.query?.physicianId}`,
      endpoint: `${endpoint.getAllProviderSchedulesTc}?from=${action?.query?.from}&to=${action?.query?.to}&regionId=${action?.query?.regionId}&facilityId=${action?.query?.facilityId}&pcpId=${action?.query?.pcpId}&specilityId=${action.query.specilityId}`,
      jwt: "Bearer " + action.token,
    });
    yield put(
      getAllProviderSchedulesTCActions.getallProviderSchedulesSuccess(
        response?.data
      )
    );
    // yield put(alertActions.alertSuccessRequest(response?.data[0]?.result));
  } catch (error) {
    yield put(
      getAllProviderSchedulesTCActions.getallProviderSchedulesFailure(error)
    );
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}

// get all facility location schedules
function* getallFacilityLocations(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.getAllFacilityLocation}`,
      jwt: "Bearer " + action.token,
    });

    yield put(
      getAllFacilityLocationsTCActions.getAllFacilityLocationSuccess(
        response?.data
      )
    );
  } catch (error) {
    yield put(
      getAllFacilityLocationsTCActions.getAllFacilityLocationFailure(error)
    );
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}

// // get all physician schedules
function* getAllProviders(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.getAllProviders}`,
      jwt: "Bearer " + action.token,
    });

    yield put(getAllProvidersTCActions.getAllProvidersSuccess(response?.data));
  } catch (error) {
    yield put(getAllProvidersTCActions.getAllProvidersFailure(error?.message));
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}

// get all regions
function* getAllRegions(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.getAllRegions}?nationId=${1}`,
      jwt: "Bearer " + action.token,
    });
    yield put(getAllRegionsTCActions.getallRegionsSuccess(response?.data));
  } catch (error) {
    yield put(getAllRegionsTCActions.getallRegionsFailure(error));
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}

// get all regions
function* getScheduleAppoinments(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.getScheduleAppoinments}?scheduleId=${action?.query?.ScheduleId}`,
      jwt: "Bearer " + action.token,
    });
    yield put(
      getScheduleAppoinmentsTCActions.getScheduleAppoinmentsSuccess(
        response?.appointments,
        response?.schedules
      )
    );
  } catch (error) {
    yield put(
      getScheduleAppoinmentsTCActions.getScheduleAppoinmentsFailure(error)
    );
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}
// create schedule
function* createScheduleAppoinments(action) {
  try {
    let response = yield call(Api2, {
      method: "POST",
      endpoint: `${endpoint.createScheduleAppoinments}`,
      jwt: "Bearer " + action.token,
      body: action.body,
    });
    yield put(
      createScheduleAppoinmentsTCActions.createScheduleAppoinmentsSuccess(
        response?.data[0]?.result
      )
    );
    yield put(alertActions.alertSuccessRequest(response?.data[0]?.result));
  } catch (error) {
    yield put(
      createScheduleAppoinmentsTCActions.createScheduleAppoinmentsFailure(error)
    );
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}

// get all lookup patients
function* getAllLookUpPatients(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.getLookUpPatient}?search=${action?.query?.search}`,
      jwt: "Bearer " + action.token,
    });
    yield put(
      getAllLookUpPatientsTCActions.getAllLookUpPatientsSuccess(response?.data)
    );
  } catch (error) {
    yield put(getAllLookUpPatientsTCActions.getAllLookUpPatientsFailure(error));
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}

// get all lookup patients
function* updatePatientCallStatus(action) {
  try {
    let response = yield call(Api2, {
      method: "PUT",
      endpoint: `${endpoint.updatePatientsCallStatus}`,
      jwt: "Bearer " + action.token,
      body: action.body,
    });
    yield put(
      updatePatientCallStatusTCActions.updatePatientCallStatusSuccess(
        response?.message
      )
    );
    // if (response[0]?.Result !== "Notes submitted successfully!") {
    yield put(alertActions.alertSuccessRequest(response?.message));
    // }
  } catch (error) {
    yield put(
      updatePatientCallStatusTCActions.updatePatientCallStatusFailure(error)
    );
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}
// get all lookup patients status for getting role bases data
function* getAllLookupPatientStatus(action) {
  console.log(action);
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.getAllLookupPatientStatus}`,
      jwt: "Bearer " + action.token,
    });
    yield put(
      getAllLookupPatientStatusTCActions.getAllLookupPatientStatusTCSuccess(
        response?.data
      )
    );
  } catch (error) {
    yield put(
      getAllLookupPatientStatusTCActions.getAllLookupPatientStatusTCFailure(
        error
      )
    );
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}

//get ALL facility type
function* getAllFacility(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.getAllFacilityType}`,
      jwt: "Bearer " + action.token,
    });
    console.log("resposne");
    yield put(
      getAllFacilityTypeAction.getAllFacilityTypeSuccess(response?.data)
    );
  } catch (error) {
    yield put(getAllFacilityTypeAction.getAllFacilityTypeFailure(error));
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}

// refresh all appointment form athen and pact

function* refreshAppointment(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.refreshAppointmentData}`,
      jwt: "Bearer " + action.payload.token,
    });
    console.log(response, "respone");
    let state = {
      message: response.Result,
      timestamp: response?.apiHitTime,
    };
    yield put(refreshAppointmentSuccess(state));
    if (state.message.includes("not")) {
      yield put(alertActions.alertErrorRequest(state.message));
    } else {
      yield put(alertActions.alertSuccessRequest(state.message));
    }
  } catch (error) {
    yield put(refreshAppointmentFaliure(error));
    yield put(alertActions.alertErrorRequest(error.message));
  }
}
