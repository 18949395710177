import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api2 } from "../../../../../api/Api2";
import { endpoint } from "../../../../../api/Url";
import { ipaTypes } from "../types/ipa.type";
import { ipaActions } from "../actions/ipa.action";

export function* watchDMIpa() {
  yield takeLatest(ipaTypes.IPA_REQUEST, getAllIpas);
  yield takeLatest(ipaTypes.IPA_SAVE_REQUEST, createIPA);
  yield takeLatest(ipaTypes.IPA_EDIT_REQUEST, editIPA);
  yield takeLatest(ipaTypes.IPA_UPDATE_STATUS_REQUEST, updateIPAStatus);
}

//getall IPA
function* getAllIpas(action) {
  try {
    let res = yield call(Api2, {
      endpoint: `${endpoint.ipas}?nationId=${action.query.nationId}&regionId=${action.query.regionId}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });

    if (res.data !== undefined && res.data.length > 0) {
      yield put(ipaActions.ipaRequestSuccess(res.data));
    } else {
      yield put(ipaActions.ipaRequestFailure("No data found"));
    }
  } catch (error) {
    yield put(ipaActions.ipaRequestFailure(error));
  }
}

// create region
function* createIPA(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.ipas}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    yield put(ipaActions.ipaSaveRequestSuccess(response.message));
  } catch (error) {
    yield put(ipaActions.ipaSaveRequestFailure(error));
  }
}

//update/edit ipa
function* editIPA(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.ipas}/${action.query}`,
      method: "PATCH",
      jwt: "Bearer " + action.token,
      body: action.payload,
    });
    yield put(
      ipaActions.ipaEditRequestSuccess(
        action.query,
        action.payload,
        response.message
      )
    );
  } catch (error) {
    yield put(ipaActions.ipaEditRequestFailure(error));
  }
}

function* updateIPAStatus(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.ipas}/${action.query.ipaId}`,
      method: "PUT",
      jwt: "Bearer " + action.token,
      body: action.payload,
    });
    console.log("response", response);

    yield put(
      ipaActions.ipaUpdateStatusRequestSuccess(
        action.query,
        action.payload,
        response.message
      )
    );
  } catch (error) {
    yield put(ipaActions.ipaUpdateStatusRequestFailure(error));
  }
}
