import { getCMPatientDetailsType } from "../../types";

export const getCMPatientDetailsActions = {
  getCMPatientDetailsRequest,
  getCMPatientDetailsSuccess,
  getCMPatientDetailsFailure,
};
function getCMPatientDetailsRequest(query, token) {
  return {
    type: getCMPatientDetailsType.GET_CM_PATIENT_DETAILS_REQUEST,
    query,
    token,
  };
}
function getCMPatientDetailsSuccess(getAll) {
  return {
    type: getCMPatientDetailsType.GET_CM_PATIENT_DETAILS_SUCCESS,
    getAll,
  };
}
function getCMPatientDetailsFailure(error) {
  return {
    type: getCMPatientDetailsType.GET_CM_PATIENT_DETAILS_FAILURE,
    error,
  };
}
