import { dispositionTypes } from "../types";

export const dispositionActions = {
  dispositionRequest,
  dispositionRequestSuccess,
  dispositionRequestFailure,
};
function dispositionRequest(request, token) {
  return {
    type: dispositionTypes.DISPOSITION_REQUEST,
    request,
    token,
  };
}
function dispositionRequestSuccess(getAll, specialities) {
  return {
    type: dispositionTypes.DISPOSITION_REQUEST_SUCCESS,
    getAll,
    specialities,
  };
}
function dispositionRequestFailure(error) {
  return {
    type: dispositionTypes.DISPOSITION_REQUEST_FAILURE,
    error,
  };
}
