// import { getAllDosageLookUpTransitionType } from "../../types/medication/getAllDosageLookUp.type";

import { getAllDosageLookUpTransitionType } from "../../types";

const initialState = {
  loading: false,
  getAll: [],
};

export const getAllDosageLookUpTCReducer = (state = initialState, action) => {
  switch (action.type) {
    case getAllDosageLookUpTransitionType.GETALL_DOSAGE_LOOKUP_TC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case getAllDosageLookUpTransitionType.GETALL_DOSAGE_LOOKUP_TC_SUCCESS:
      return {
        ...state,
        getAll: action.getAll,
        loading: false,
      };
    case getAllDosageLookUpTransitionType.GETALL_DOSAGE_LOOKUP_TC_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    default:
      return state;
  }
};
