import {
  pcpHierarchyTypes,
  pcphierarchyTypes,
} from "../types/pcphierarchy.type";

export const pcpHierarchyActions = {
  pcphierarchyRequest,
  pcphierarchyRequestSuccess,
  pcphierarchyRequestFailure,
  pcphierarchySaveRequest,
  pcphierarchySaveRequestSuccess,
  pcphierarchySaveRequestFailure,
  pcphierarchyEditRequest,
  pcphierarchyEditRequestSuccess,
  pcphierarchyEditRequestFailure,
  pcphierarchyUpdateStatusRequest,
  pcphierarchyUpdateStatusRequestSuccess,
  pcphierarchyUpdateStatusRequestFailure,
  pcphierarchyRequestReset,
};

//getAll pcp hierarchy

function pcphierarchyRequest(query, token) {
  return {
    type: pcpHierarchyTypes.PCP_HIERARCHY_REQUEST,
    query,
    token,
  };
}

function pcphierarchyRequestSuccess(getAll) {
  return {
    type: pcpHierarchyTypes.PCP_HIERARCHY_REQUEST_SUCCESS,
    getAll,
  };
}

function pcphierarchyRequestFailure(error) {
  return {
    type: pcpHierarchyTypes.PCP_HIERARCHY_REQUEST_FAILURE,
    error,
  };
}

//SAVE pcp hierarachy

function pcphierarchySaveRequest(request, token) {
  return {
    type: pcpHierarchyTypes.PCP_HIERARCHY_SAVE_REQUEST,
    request,
    token,
  };
}

function pcphierarchySaveRequestSuccess(message) {
  return {
    type: pcpHierarchyTypes.PCP_HIERARCHY_SAVE_REQUEST_SUCCESS,
    message,
  };
}

function pcphierarchySaveRequestFailure(error) {
  return {
    type: pcpHierarchyTypes.PCP_HIERARCHY_SAVE_REQUEST_FAILURE,
    error,
  };
}

//update PCP group
function pcphierarchyEditRequest(query, payload, token) {
  return {
    type: pcpHierarchyTypes.PCP_HIERARCHY_EDIT_REQUEST,
    query,
    payload,
    token,
  };
}

function pcphierarchyEditRequestSuccess(message) {
  return {
    type: pcpHierarchyTypes.PCP_HIERARCHY_EDIT_REQUEST_SUCCESS,

    message,
  };
}

function pcphierarchyEditRequestFailure(error) {
  return {
    type: pcpHierarchyTypes.PCP_HIERARCHY_EDIT_REQUEST_FAILURE,
    error,
  };
}

//IsActive PCP GROUP
function pcphierarchyUpdateStatusRequest(query, payload, token) {
  return {
    type: pcpHierarchyTypes.PCP_HIERARCHY_UPDATE_STATUS_REQUEST,
    query,
    payload,
    token,
  };
}

function pcphierarchyUpdateStatusRequestSuccess(id, message) {
  return {
    type: pcpHierarchyTypes.PCP_HIERARCHY_UPDATE_STATUS_REQUEST_SUCCESS,
    id,
    message,
  };
}

function pcphierarchyUpdateStatusRequestFailure(error) {
  return {
    type: pcpHierarchyTypes.PCP_HIERARCHY_UPDATE_STATUS_REQUEST_FAILURE,
    error,
  };
}

//reset
function pcphierarchyRequestReset() {
  return {
    type: pcpHierarchyTypes.PCP_HIERARCHY_REQUEST_RESET,
  };
}
