export const advanceDirectiveTypes = {
  //TO get
  ADVANCE_DIRECTIVE_REQUEST: "ADVANCE_DIRECTIVE_REQUEST",
  ADVANCE_DIRECTIVE_REQUEST_SUCCESS: "ADVANCE_DIRECTIVE_REQUEST_SUCCESS",

  ADVANCE_DIRECTIVE_REQUEST_FAILURE: "ADVANCE_DIRECTIVE_REQUEST_FAILURE",

  //to  update add
  ADVANCE_DIRECTIVE_OPERATION_REQUEST: "ADVANCE_DIRECTIVE_OPERATION_REQUEST",
  ADVANCE_DIRECTIVE_OPERATION_REQUEST_SUCCESS:
    "ADVANCE_DIRECTIVE_OPERATION_REQUEST_SUCCESS",
  ADVANCE_DIRECTIVE_OPERATION_REQUEST_FAILURE:
    "ADVANCE_DIRECTIVE_OPERATION_REQUEST_FAILURE",
  ADVANCE_DIRECTIVE_OPERATION_REQUEST_RESET:
    "ADVANCE_DIRECTIVE_OPERATION_REQUEST_RESET",
};
