import { alertType } from "./AlertNew.type";
const initalState = {
  hasError: false,
  message: "",
  severity: "info",
};
export const alertReducer = (state = initalState, action) => {
  switch (action.type) {
    case alertType.ALERT_SUCCESS_REQUEST:
      return {
        ...state,
        hasError: false,
        message: action.message,
        severity: "success",
      };
    case alertType.ALERT_ERROR_REQUEST:
      return {
        ...state,
        hasError: true,
        message: action.message,
        severity: "error",
      };

    case alertType.ALERT_RESET_REQUEST:
      return { ...state, hasError: false, message: "", severity: "info" };

    default:
      return state;
  }
};
// alertReset: (state) => {
//   state.severity = AlertSeverity.info;
//   state.message = "";
//   state.hasAlert = false;
// },
