import { put, takeLatest, call } from "@redux-saga/core/effects";
import { getAssessmentActions } from "../actions";
import { getAssessmentTypes } from "../types";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";

export function* watchGetAssessment() {
  yield takeLatest(
    getAssessmentTypes.GET_ASSESSMENT_REQUEST,
    getAssessmentRole
  );
}

function* getAssessmentRole(action) {
  console.log(action.request);
  try {
    const data = yield call(Api, {
      endpoint: `${endpoint.getAssessment}?PatientId=${action.request.patientAdmissionId}&FilterId=${action.request.filterId}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    const parsedData = JSON.parse(data);
    console.log(parsedData);

    yield put(getAssessmentActions.getAssessmentRequestSuccess(parsedData));
  } catch (error) {
    yield put(getAssessmentActions.getAssessmentRequestFailure(error));
  }
}
