import { insuranceTypes } from "../types";

export const insuranceActions = {
  insuranceRequest,
  insuranceRequestSuccess,
  insuranceRequestFailure,
};
function insuranceRequest(request, token) {
  return {
    type: insuranceTypes.INSURANCE_REQUEST,
    request,
    token,
  };
}
function insuranceRequestSuccess(getAll) {
  return {
    type: insuranceTypes.INSURANCE_REQUEST_SUCCESS,
    getAll,
  };
}
function insuranceRequestFailure(error) {
  return {
    type: insuranceTypes.INSURANCE_REQUEST_FAILURE,
    error,
  };
}
