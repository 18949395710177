import { filtersListType } from "../types/filtersList.type";
const initalState = {
  listLoading: false,
};

export const filtersListReducer = (state = initalState, action) => {
  switch (action.type) {
    case filtersListType.FILTERSLIST_REQUEST:
      return { listLoading: true };
    case filtersListType.FILTERSLIST_REQUEST_SUCCESS:
      return { filtersDataList: action.filtersDataList };
    case filtersListType.FILTERSLIST_REQUEST_FAILURE:
      return { error: action.error };
    default:
      return state;
  }
};
