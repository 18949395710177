import { put, takeLatest, call } from "@redux-saga/core/effects";
import { getPatientDetailsActions } from "../actions";
import { patientDetailsType } from "../types";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
export function* watchTCPatientDetails() {
  yield takeLatest(
    patientDetailsType.GET_PATIENT_DETAILS_REQUEST,
    getTCPatientDetails
  );
}

function* getTCPatientDetails(action) {
  try {
    let details = yield call(Api, {
      endpoint: `${endpoint.getTCPatientDetails}?AdmissionID=${action.request.AdmissionID}`,
      method: "POST",
      jwt: "Bearer " + action.token,
    });
    const parsedDetails = JSON.parse(details);

    yield put(
      getPatientDetailsActions.getPatientDetailsRequestSuccess(parsedDetails)
    );
  } catch (error) {
    yield put(getPatientDetailsActions.getPatientDetailsRequestFailure(error));
  }
}
