import { comprehensiveTypes } from "../types/comprehensive.type";

//Get assessment
const initialState = {
  loading: false,
  getAll: [],
  error: "",
};

export const getComprehensiveSystemAssessmentReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case comprehensiveTypes.GET_ASSESSMENT_REQUEST:
      return { ...initialState, loading: true };
    case comprehensiveTypes.GET_ASSESSMENT_REQUEST_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
      };
    case comprehensiveTypes.GET_ASSESSMENT_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};

//Get changes assessment
const initialGetChangesState = {
  loading: false,
  getAll: [],
  error: "",
};

export const getChangesComprehensiveReducer = (
  state = initialGetChangesState,
  action
) => {
  switch (action.type) {
    case comprehensiveTypes.GET_CHANGES_ASSESSMENT_REQUEST:
      return { ...initialGetChangesState, loading: true };
    case comprehensiveTypes.GET_CHANGES_ASSESSMENT_REQUEST_SUCCESS:
      return {
        ...initialGetChangesState,
        getAll: action.getAll,
      };
    case comprehensiveTypes.GET_CHANGES_ASSESSMENT_REQUEST_FAILURE:
      return { ...initialGetChangesState, error: action.error };
    default:
      return state;
  }
};
//Get update assessment
const initialUpdateState = {
  loading: false,
  message: "",
  error: "",
};

export const updateComprehensiveSystemAssessmentReducer = (
  state = initialUpdateState,
  action
) => {
  switch (action.type) {
    case comprehensiveTypes.UPDATE_ASSESSMENT_REQUEST:
      return { ...initialUpdateState, loading: true };
    case comprehensiveTypes.UPDATE_ASSESSMENT_REQUEST_SUCCESS:
      return {
        ...initialUpdateState,
        message: action.message,
      };
    case comprehensiveTypes.UPDATE_ASSESSMENT_REQUEST_FAILURE:
      return { ...initialUpdateState, error: action.error };
    case comprehensiveTypes.UPDATE_ASSESSMENT_REQUEST_RESET:
      return { ...initialUpdateState };
    default:
      return state;
  }
};
