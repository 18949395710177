import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { assessmentsReferralsActions } from "../actions";
import { assessmentsReferralsTypes } from "../types";
export function* watchAssessmentsReferrals() {
  yield takeLatest(
    assessmentsReferralsTypes.ASSESSMENTSREFERRAL_GET_REQUEST,
    getAssessmentsReferrals
  );
  yield takeLatest(
    assessmentsReferralsTypes.ASSESSMENTSREFERRAL_OPERATION_REQUEST,
    operationAssessmentsReferrals
  );
}

function* getAssessmentsReferrals(action) {
  try {
    let data = yield call(Api, {
      endpoint: `${endpoint.getSuggestedAssessmentsorReferrals}?patientId=${action.request.patientId}`,
      method: "POST",
      jwt: "Bearer " + action.token,
    });
    let parsedData = JSON.parse(data);

    yield put(
      assessmentsReferralsActions.assessmentsReferralsGetRequestSuccess(
        parsedData
      )
    );
  } catch (error) {
    yield put(
      assessmentsReferralsActions.assessmentsReferralsGetRequestFailure(error)
    );
  }
}
function* operationAssessmentsReferrals(action) {
  try {
    let message = yield call(Api, {
      endpoint: endpoint.saveAssesmentsRefferalsDetails,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedMessage = JSON.parse(message);

    yield put(
      assessmentsReferralsActions.assessmentsReferralsOpRequestSuccess(
        parsedMessage[0]
      )
    );
  } catch (error) {
    yield put(
      assessmentsReferralsActions.assessmentsReferralsOpRequestFailure(error)
    );
  }
}
