import React from "react";
import * as BiIcons from "react-icons/bi";
//import * as MdIcons from "react-icons/md";
import styles from "./NotFound.module.scss";

const NotFound = ({ error }) => {
  return (
    <div className={styles.not_found_container}>
      {/* <BiIcons.BiError className={styles.not_found_icon} /> */}
      {/* <MdIcons.MdErrorOutline className={styles.not_found_icon} /> */}
      <h4 className={styles.error}>{error}</h4>
    </div>
  );
};

export { NotFound };
