import { comprehensiveTypes } from "../types/comprehensive.type";

export const comprehensiveActions = {
  getAssessmentRequest,
  getAssessmentRequestSuccess,
  getAssessmentRequestFailure,

  getChangesAssessmentRequest,
  getChangesAssessmentRequestSuccess,
  getChangesAssessmentRequestFailure,

  updateAssessmentRequest,
  updateAssessmentRequestSuccess,
  updateAssessmentRequestFailure,
  updateAssessmentRequestReset,
};

//Get assessment
function getAssessmentRequest(request, token) {
  return { type: comprehensiveTypes.GET_ASSESSMENT_REQUEST, request, token };
}
function getAssessmentRequestSuccess(getAll) {
  return {
    type: comprehensiveTypes.GET_ASSESSMENT_REQUEST_SUCCESS,
    getAll,
  };
}
function getAssessmentRequestFailure(error) {
  return { type: comprehensiveTypes.GET_ASSESSMENT_REQUEST_FAILURE, error };
}
//Get Changes assessment
function getChangesAssessmentRequest(request, token) {
  return {
    type: comprehensiveTypes.GET_CHANGES_ASSESSMENT_REQUEST,
    request,
    token,
  };
}
function getChangesAssessmentRequestSuccess(getAll) {
  return {
    type: comprehensiveTypes.GET_CHANGES_ASSESSMENT_REQUEST_SUCCESS,
    getAll,
  };
}
function getChangesAssessmentRequestFailure(error) {
  return { type: comprehensiveTypes.GET_ASSESSMENT_REQUEST_FAILURE, error };
}

//Update assessment
function updateAssessmentRequest(request, token) {
  return { type: comprehensiveTypes.UPDATE_ASSESSMENT_REQUEST, request, token };
}
function updateAssessmentRequestSuccess(message) {
  return {
    type: comprehensiveTypes.UPDATE_ASSESSMENT_REQUEST_SUCCESS,
    message,
  };
}
function updateAssessmentRequestFailure(error) {
  return { type: comprehensiveTypes.UPDATE_ASSESSMENT_REQUEST_FAILURE, error };
}
function updateAssessmentRequestReset() {
  return { type: comprehensiveTypes.UPDATE_ASSESSMENT_REQUEST_RESET };
}
