import { mergePatientFacilityLookupTypes } from "../types";

function mergePatientFacilityLookupRequest(token) {
  return {
    type: mergePatientFacilityLookupTypes.MERGE_PATIENT_FACILITY_LOOKUP_REQUEST,
    token,
  };
}

function mergePatientFacilityLookupSuccess(getAll) {
  return {
    type: mergePatientFacilityLookupTypes.MERGE_PATIENT_FACILITY_LOOKUP_SUCCESS,
    getAll,
  };
}

function mergePatientFacilityLookupFailure(error) {
  return {
    type: mergePatientFacilityLookupTypes.MERGE_PATIENT_FACILITY_LOOKUP_FAILURE,
    error,
  };
}
function mergePatientFacilityLookupReset() {
  return {
    type: mergePatientFacilityLookupTypes.MERGE_PATIENT_FACILITY_LOOKUP_RESET,
  };
}

export const mergePatientFacilityLookupActions = {
  mergePatientFacilityLookupRequest,
  mergePatientFacilityLookupSuccess,
  mergePatientFacilityLookupFailure,
  mergePatientFacilityLookupReset,
};
