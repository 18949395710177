import { assessmentTypes } from "../types";

export const assessmentActions = {
  assessmentRequest,
  assessmentRequestSuccess,
  assessmentRequestFailure,
};

function assessmentRequest(request, token) {
  return { type: assessmentTypes.ASSESSMENT_REQUEST, request, token };
}
function assessmentRequestSuccess(assessment) {
  return {
    type: assessmentTypes.ASSESSMENT_REQUEST_SUCCESS,
    assessment,
  };
}
function assessmentRequestFailure(error) {
  return { type: assessmentTypes.ASSESSMENT_REQUEST_FAILURE, error };
}
