import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  data: [],
  error: "",
  message: "",
};

export const patientUpdateSlice = createSlice({
  name: "patientUpdate",
  initialState: initialState,
  reducers: {
    patientUpdateRequest: (state, action) => {
      state.loading = true;
    },
    patientUpdateSuccess: (state, action) => {
      state.loading = false;
      state.error = "";
      // state.data = action.payload?.data;
      state.message = action.payload?.message;
    },
    patientUpdateFaliure: (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload.error;
      state.message = "";
    },
    patientUpdateReset: (state) => {
      state.loading = false;
      state.error = "";
      state.message = "";
    },
  },
});

export const {
  patientUpdateRequest,
  patientUpdateSuccess,
  patientUpdateFaliure,
  patientUpdateReset,
} = patientUpdateSlice.actions;
export default patientUpdateSlice.reducer;
