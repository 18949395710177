import { takeEvery, takeLatest, call, put } from "redux-saga/effects";
import { pcpGroupTypes } from "../types/pcpgroup.type";
import { endpoint } from "../../../../../api/Url";
import { pcpGroupActions } from "../actions/pcpgroup.action";
import { Api2 } from "../../../../../api/Api2";

export function* watchDMPCPGroup() {
  yield takeLatest(pcpGroupTypes.PCP_GROUP_REQUEST, getAllPCPGroups);
  yield takeLatest(pcpGroupTypes.PCP_GROUP_EDIT_REQUEST, editPCPGroup);
  yield takeLatest(pcpGroupTypes.PCP_GROUP_SAVE_REQUEST, createPCPGroup);
  yield takeLatest(
    pcpGroupTypes.PCP_GROUP_UPDATE_STATUS_REQUEST,
    updatePCPGroupStatus
  );
}

//getall pcpgroups

function* getAllPCPGroups(action) {
  try {
    console.log("action.query", action);
    let res = yield call(Api2, {
      endpoint: `${endpoint.pcpgroup}?nationId=${action.query.nationId}&regionId=${action.query.regionId}&ipaId=${action.query.ipaId}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });

    if (res.data !== undefined && res.data.length > 0) {
      yield put(pcpGroupActions.pcpgroupRequestSuccess(res.data));
    } else {
      yield put(pcpGroupActions.pcpgroupRequestFailure("No data found"));
    }
  } catch (error) {
    console.log("====================================");
    console.log(error);
    console.log("====================================");
    yield put(pcpGroupActions.pcpgroupRequestFailure(error));
  }
}

//update/edit pcpgroup
function* editPCPGroup(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.pcpgroup}/${action.query}`,
      method: "PATCH",
      jwt: "Bearer " + action.token,
      body: action.payload,
    });

    yield put(pcpGroupActions.pcpgroupEditRequestSuccess(response.message));
  } catch (error) {
    yield put(pcpGroupActions.pcpgroupEditRequestFailure(error));
  }
}

//create pcpgroup
function* createPCPGroup(action) {
  try {
    console.log("action", action);
    let response = yield call(Api2, {
      endpoint: `${endpoint.pcpgroup}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    console.log("response", response);
    yield put(pcpGroupActions.pcpgroupSaveRequestSuccess(response.message));
  } catch (error) {
    yield put(pcpGroupActions.pcpgroupSaveRequestFailure(error));
  }
}
//update status
function* updatePCPGroupStatus(action) {
  try {
    console.log(action, "action");
    let response = yield call(Api2, {
      endpoint: `${endpoint.pcpgroup}/${action.query.pcpgroupId}`,
      method: "PUT",
      jwt: "Bearer " + action.token,
      body: action.payload,
    });
    yield put(
      pcpGroupActions.pcpgroupUpdateStatusRequestSuccess(
        action.query,
        action.payload,
        response.message
      )
    );
  } catch (error) {
    console.log(error, "error");
    yield put(pcpGroupActions.pcpgroupUpdateStatusRequestFailure(error));
  }
}
