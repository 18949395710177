import { callCompletedPharmaType } from "../types";

const initialState = {
  loading: false,
  message: "",
  error: "",
};

export const callCompletedDischargePharmaReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case callCompletedPharmaType.CALL_COMPLETED_PHARMA_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case callCompletedPharmaType.CALL_COMPLETED_PHARMA_SUCCESS:
      return {
        ...initialState,
        message: action.message,
      };
    case callCompletedPharmaType.CALL_COMPLETED_PHARMA_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case callCompletedPharmaType.CALL_COMPLETED_PHARMA_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
