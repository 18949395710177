export const strings = {
  BTN_NAME: "Add Task",

  BTN_ADD: "Task",
  BTN_DELETE: "Delete Task",
  BTN_EDIT: "Edit Task",

  //TABLE COLUMNS
  TH_TASKDETAILS: "Task",
  TH_DATE: "Date",
  TH_GROUP: "Group",
  TH_SUBGROUP: "Subgroup",
  TH_USERNAME: "Username",
};
