import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { educationProvidedActions } from "../actions";
import { educationProvidedTypes } from "../types";
export function* watchEducationProvided() {
  yield takeLatest(
    educationProvidedTypes.EDUCATIONPROVIDED_GET_REQUEST,
    getEducationProvided
  );
  yield takeLatest(
    educationProvidedTypes.EDUCATIONPROVIDED_OPERATION_REQUEST,
    operationEducationProvided
  );
}

function* getEducationProvided(action) {
  try {
    let data = yield call(Api, {
      endpoint: `${endpoint.getEducationProvidedDetails}?patientId=${action.request.patientId}`,
      method: "POST",
      jwt: "Bearer " + action.token,
    });
    let parsedData = JSON.parse(data);

    yield put(
      educationProvidedActions.educationProvidedGetRequestSuccess(parsedData)
    );
  } catch (error) {
    yield put(
      educationProvidedActions.educationProvidedGetRequestFailure(error)
    );
  }
}
function* operationEducationProvided(action) {
  try {
    let message = yield call(Api, {
      endpoint: endpoint.saveEducationProvidedDetails,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedMessage = JSON.parse(message);

    yield put(
      educationProvidedActions.educationProvidedOpRequestSuccess(
        parsedMessage[0]
      )
    );
  } catch (error) {
    yield put(
      educationProvidedActions.educationProvidedOpRequestFailure(error)
    );
  }
}
