import { getActivePatientAppointmentsType } from "../../types";

function getActivePatientAppointmentsRequest(query, token) {
  return {
    type: getActivePatientAppointmentsType.GET_ACTIVE_PATIENT_APPOINTMENTS_REQUEST,
    query,
    token,
  };
}
function getActivePatientAppointmentsSuccess(getAll) {
  return {
    type: getActivePatientAppointmentsType.GET_ACTIVE_PATIENT_APPOINTMENTS_SUCCESS,
    getAll,
  };
}
function getActivePatientAppointmentsFailure(error) {
  return {
    type: getActivePatientAppointmentsType.GET_ACTIVE_PATIENT_APPOINTMENTS_FAILURE,
    error,
  };
}
function getActivePatientAppointmentsReset() {
  return {
    type: getActivePatientAppointmentsType.GET_ACTIVE_PATIENT_APPOINTMENTS_RESET,
  };
}

export const getActivePatientAppointmentsTCActions = {
  getActivePatientAppointmentsRequest,
  getActivePatientAppointmentsSuccess,
  getActivePatientAppointmentsFailure,
  getActivePatientAppointmentsReset,
};
