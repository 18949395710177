export const userPermissionTypes = {
  //save user permission

  USER_PERMISSION_SAVE_REQUEST: "DM_USER_PERMISSION_SAVE_REQUEST",
  USER_PERMISSION_SAVE_REQUEST_SUCCESS:
    "DM_USER_PERMISSSION_SAVE_REQUEST_SUCCESS",
  USER_PERMISSION_SAVE_REQUEST_FAILURE:
    "DM_USER_PERMISSION_SAVE_REQUEST_FAILURE",

  //get user permission

  USER_PERMISSION_GET_REQUEST: "DM_USER_PERMISSION_GET_REQUEST",
  USER_PERMISSION_GET_REQUEST_SUCCESS: "DM_USER_PERMISSION_GET_REQUEST_SUCCESS",
  USER_PERMISSION_GET_REQUEST_FAILURE: "DM_USER_PERMISSION_GET_REQUEST_FAILURE",

  //RESET

  USER_PERMISSION_REQUEST_RESET: "DM_USER_PERMISSION_REQUEST_RESET",
};
