import { powerbiEpisodicType } from "../types/powerbEpisodic.type";

export const powerbiEpisodicActions = {
  powerbiEpisodicRequest,
  powerbiEpisodicRequestSuccess,
  powerbiEpisodicRequestFailure,
};

function powerbiEpisodicRequest(request, token) {
  return {
    type: powerbiEpisodicType.POWERBI_REQUEST,
    request,
    token,
  };
}
function powerbiEpisodicRequestSuccess(reportData) {
  return {
    type: powerbiEpisodicType.POWERBI_REQUEST_SUCCESS,
    reportData,
  };
}
function powerbiEpisodicRequestFailure(error) {
  return {
    type: powerbiEpisodicType.POWERBI_REQUEST_FAILURE,
    error: error,
  };
}
