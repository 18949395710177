import { put, takeLatest, call } from "@redux-saga/core/effects";
import { endpoint } from "../../../../api/Url";
import { roleIdentityLookupDmActions, roleLookupDmActions } from "../actions";
import { Api2 } from "../../../../api/Api2";
import { roleIndetityLookupDmTypes, roleLookupDmTypes } from "../types";

export function* watchRoleLookupDm() {
  yield takeLatest(roleLookupDmTypes.ROLE_LOOKUP_DM_REQUEST, getRoleLookup);
  yield takeLatest(
    roleIndetityLookupDmTypes.ROLEIDENTITY_LOOKUP_DM_REQUEST,
    getRoleIdentityLookup
  );
}

function* getRoleLookup(action) {
  try {
    let data = yield call(Api2, {
      method: "GET",
      endpoint: endpoint.getDMRoleLookup,
      jwt: "Bearer " + action.token,
    });

    yield put(roleLookupDmActions.roleLookupDmSuccess(data.data));
  } catch (error) {
    yield put(roleLookupDmActions.roleLookupDmFailure(error));
  }
}

function* getRoleIdentityLookup(action) {
  try {
    let data = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.getRoleIdentityLookup}?roleId=${action.request.roleId}&search=${action.request.search}`,
      jwt: "Bearer " + action.token,
    });

    yield put(
      roleIdentityLookupDmActions.roleIdentityLookupDmSuccess(data.data)
    );
  } catch (error) {
    yield put(roleIdentityLookupDmActions.roleIdentityLookupDmFailure(error));
  }
}
