import { callHistoryOperationsCmType } from "../types/callHistory.type";
export const callHistoryOperationsCmActions = {
  callHistoryOperationsCmRequest,
  callHistoryOperationsCmSuccess,
  callHistoryOperationsCmFailure,
  callHistoryGetAllDetailsCmRequest,
  callHistoryGetAllDetailsCmSuccess,
  callHistoryGetAllDetailsCmFailure,
  callHistoryOperationsCmReset,
};

function callHistoryGetAllDetailsCmRequest(request, token) {
  return {
    type: callHistoryOperationsCmType.CALL_HISTORY_DETAILS_CM_REQUEST,
    request,
    token,
  };
}
function callHistoryGetAllDetailsCmSuccess(getAll) {
  return {
    type: callHistoryOperationsCmType.CALL_HISTORY_DETAILS_CM_SUCCESS,
    getAll,
  };
}
function callHistoryGetAllDetailsCmFailure(error) {
  return {
    type: callHistoryOperationsCmType.CALL_HISTORY_DETAILS_CM_FAILURE,
    error,
  };
}
function callHistoryOperationsCmRequest(request, token) {
  return {
    type: callHistoryOperationsCmType.CALL_HISTORY_OPERATIONS_CM_REQUEST,
    request,
    token,
  };
}
function callHistoryOperationsCmSuccess(message) {
  return {
    type: callHistoryOperationsCmType.CALL_HISTORY_OPERATIONS_CM_SUCCESS,
    message,
  };
}
function callHistoryOperationsCmFailure(error) {
  return {
    type: callHistoryOperationsCmType.CALL_HISTORY_OPERATIONS_CM_FAILURE,
    error,
  };
}

function callHistoryOperationsCmReset() {
  return {
    type: callHistoryOperationsCmType.CALL_HISTORY_OPERATIONS_CM_RESET,
  };
}
