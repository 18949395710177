import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  getAll: [],
  error: "",
};

export const referralConsultantSlice = createSlice({
  name: "referralConsultant",
  initialState,
  reducers: {
    getReferralConsultantDataRequest(state) {
      state.loading = true;
    },
    getReferralConsultantDataSuccess(state, action) {
      state.loading = false;
      state.error = "";
      state.getAll = action.payload;
    },
    getReferralConsultantDataFailure(state, action) {
      state.loading = false;
      state.getAll = [];
      state.error = action.payload.error || "An error occurred";
    },
    getReferralConsultantDataReset() {
      return initialState;
    },
  },
});

export const {
  getReferralConsultantDataRequest,
  getReferralConsultantDataSuccess,
  getReferralConsultantDataFailure,
  getReferralConsultantDataReset,
} = referralConsultantSlice.actions;

export default referralConsultantSlice.reducer;
