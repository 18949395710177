import { loginType } from "../types/login.type";

export const loginActions = {
  loginRequest,
  loginRequestSuccess,
  loginRequestFailure,
  logoutRequest,
};

function loginRequest(request) {
  return {
    type: loginType.LOGIN_REQUEST,
    request,
  };
}
function loginRequestSuccess(userData) {
  return {
    type: loginType.LOGIN_REQUEST_SUCCESS,
    userData,
  };
}
function loginRequestFailure(error) {
  return {
    type: loginType.LOGIN_REQUEST_FAILURE,
    error: error,
  };
}
function logoutRequest() {
  return {
    type: loginType.LOGOUT_REQUEST,
  };
}
