import { surgicalHistoryTypes } from "../types";

export const surgicalHistoryActions = {
  //for get
  surgicalHistoryGetAllRequest,
  surgicalHistoryGetAllRequestSuccess,
  surgicalHistoryGetAllRequestFailure,

  //for update delete insert
  surgicalHistoryOperationRequest,
  surgicalHistoryOperationRequestSuccess,
  surgicalHistoryOperationRequestFailure,
  surgicalHistoryOperationRequestReset,

  //for- delete -
  surgicalHistoryDeleteRequest,
  surgicalHistoryDeleteRequestSuccess,
  surgicalHistoryDeleteRequestFailure,
  surgicalHistoryDeleteRequestReset,
};
function surgicalHistoryGetAllRequest(request, token) {
  return {
    type: surgicalHistoryTypes.GETALL_SURGICAL_HISTORY_REQUEST,
    request,
    token,
  };
}
function surgicalHistoryGetAllRequestSuccess(
  getAll,
  dropdownSurgeryType,
  dropdownSourcelist
) {
  return {
    type: surgicalHistoryTypes.GETALL_SURGICAL_HISTORY_REQUEST_SUCCESS,
    getAll,
    dropdownSurgeryType,
    dropdownSourcelist,
  };
}
function surgicalHistoryGetAllRequestFailure(error) {
  return {
    type: surgicalHistoryTypes.GETALL_SURGICAL_HISTORY_REQUEST_FAILURE,
    error,
  };
}

function surgicalHistoryOperationRequest(request, token) {
  return {
    type: surgicalHistoryTypes.OPERARTION_SURGICAL_HISTORY_REQUEST,
    request,
    token,
  };
}
function surgicalHistoryOperationRequestSuccess(message) {
  return {
    type: surgicalHistoryTypes.OPERARTION_SURGICAL_HISTORY_REQUEST_SUCCESS,
    message,
  };
}
function surgicalHistoryOperationRequestFailure(error) {
  return {
    type: surgicalHistoryTypes.OPERARTION_SURGICAL_HISTORY_REQUEST_FAILURE,
    error,
  };
}
function surgicalHistoryOperationRequestReset() {
  return {
    type: surgicalHistoryTypes.OPERARTION_SURGICAL_HISTORY_REQUEST_RESET,
  };
}

function surgicalHistoryDeleteRequest(request, token) {
  return {
    type: surgicalHistoryTypes.DELETE_SURGICAL_REQUEST,
    request,
    token,
  };
}
function surgicalHistoryDeleteRequestSuccess(message) {
  return {
    type: surgicalHistoryTypes.DELETE_SURGICAL_REQUEST_SUCCESS,
    message,
  };
}
function surgicalHistoryDeleteRequestFailure(error) {
  return {
    type: surgicalHistoryTypes.DELETE_SURGICAL_REQUEST_FAILURE,
    error,
  };
}
function surgicalHistoryDeleteRequestReset() {
  return {
    type: surgicalHistoryTypes.DELETE_SURGICAL_REQUEST_RESET,
  };
}
