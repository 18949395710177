import React from "react";
import styles from "./Selector.module.scss";
export const Selector = (props) => {
  return (
    <div className={styles.container}>
      <p className={styles.label}>{props.label}:</p>
      <select
        className={styles.value}
        onChange={props.onChange}
        value={props.value}
        disabled={props.disabled}

        // defaultValue={"TQBvAE4ATwBqAGYAUwBlAGEAZwBvAD0A"}
      >
        <option disabled value={"default"}>
          Select
        </option>
        {props.list !== undefined &&
          props.list.map((item, index) => (
            <option value={item.RelationshipID} key={item.RelationshipID}>
              {item.Relationship}
            </option>
          ))}
      </select>
    </div>
  );
};
