import { getAllProvidersType } from "../../types";

export const getAllProvidersTCActions = {
  getAllProvidersRequest,
  getAllProvidersSuccess,
  getAllProvidersFailure,
};
function getAllProvidersRequest(token) {
  return {
    type: getAllProvidersType.GETALL_PROVIDERS_TC_REQUEST,

    token,
  };
}
function getAllProvidersSuccess(getAll) {
  return {
    type: getAllProvidersType.GETALL_PROVIDERS_TC_SUCCESS,
    getAll,
  };
}
function getAllProvidersFailure(error) {
  return {
    type: getAllProvidersType.GETALL_PROVIDERS_TC_SUCCESS,
    error,
  };
}
