import { call, put, takeLatest } from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
import { archiveMailboxActions } from "../actions/archiveMailbox.action";
import { archiveMailboxTypes } from "../types/archiveMailbox.type";
import { Api2 } from "../../../api/Api2";

export function* watchArchiveMailbox() {
  yield takeLatest(
    archiveMailboxTypes.ARCHIVE_MAILBOX_REQUEST,
    getArchiveMails
  );
}

function* getArchiveMails(action) {
  try {
    let archiveMails = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.getArchiveMailbox}/${action?.request?.UserRoleID}`,
      jwt: "Bearer " + action.token,
    });

    yield put(archiveMailboxActions.archiveRequestSuccess(archiveMails));
  } catch (error) {
    yield put(archiveMailboxActions.archiveRequestFailure(error));
  }
}
