export const strings = {
  //for table headers
  TH_S_NO: "S.No.",
  TH_ASSIGNED_PCP: "Assigned PCP Name",
  TH_FACILITY: "Facility",
  TH_ADMISSION_DATE: "Admission Date",
  TH_DISCHARGE_DATE: "Discharge Date",
  TH_MRN: "MRN",
  TH_PCP_NAME: "PCP Name",
  TH_DISPOSITION: "Disposition",
  TH_DIAGNOSIS: "Diagnosis",
  TH_DIAGNOSIS_DETAILS: "DiagnosisDetail",

  BTN_ADD: "Hospital Admissions",
  BTN_Delete: "Delete Utilization",
  BTN_Edit: "Edit Utilization",
};
