import { basicInfoTypes } from "../types";

export const basicInfoActions = {
  basicInfoRequest,
  basicInfoRequestSuccess,
  basicInfoRequestFailure,
};

function basicInfoRequest(request, token) {
  return { type: basicInfoTypes.BASIC_INFO_REQUEST, request, token };
}

function basicInfoRequestSuccess(getAll, cmUserList, LookupDisposition) {
  return {
    type: basicInfoTypes.BASIC_INFO_REQUEST_SUCCESS,
    getAll,
    cmUserList,
    LookupDisposition,
  };
}

function basicInfoRequestFailure(error) {
  return { type: basicInfoTypes.BASIC_INFO_REQUEST_FAILURE, error };
}
