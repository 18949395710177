import { put, takeLatest, call } from "@redux-saga/core/effects";
import { patientActions } from "../actions/patient.action";
import { patientType } from "../types/patient.type";
import { endpoint } from "../../../api/Url";
import { Api } from "../../../api";
import { loginActions } from "../../Login/actions/login.action";
//For patients page request
export function* watchPatients() {
  yield takeLatest(patientType.PATIENT_PAGE_REQUEST, getPatients);
  // yield takeLatest(patientType.PATIENT_TYPE_REQUEST, getTypePatients);

  // yield takeLatest(patientType.PATIENT_FLAG_REQUEST, getFlagPatients);
}
//For patients page request
function* getPatients(action) {
  try {
    //   api call
    const data = yield call(Api, {
      endpoint: endpoint.patientList,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    const parsedData = JSON.parse(data);

    if (parsedData.TotalCount > 0) {
      yield put(
        patientActions.patientRequestSuccess(
          parsedData.TotalCount,
          parsedData.patientlist
        )
      );
    } else {
      yield put(patientActions.patientRequestFailure("Not Found"));
    }
  } catch (error) {
    yield put(patientActions.patientRequestFailure(error));

    if (error.code === 401 && error.code === 403)
      yield put(loginActions.logoutRequest());
  }
}

// //For patients type request
// function* getTypePatients(action) {
//   try {
//     //   api call
//     const data = yield call(Api, {
//       endpoint: endpoint.patientList,
//       method: "POST",
//       jwt: "Bearer " + action.token,
//       body: action.request,
//     });

//     const parsedData = JSON.parse(data);
//     console.log("====================================");
//     console.log(parsedData);
//     console.log("====================================");
//     if (parsedData.TotalCount > 0) {
//       yield put(
//         patientActions.patientTypeRequestSuccess(
//           parsedData.TotalCount,
//           parsedData.patientlist
//         )
//       );
//     } else {
//       yield put(patientActions.patientTypeRequestFailure("Not Found"));
//     }
//   } catch (error) {
//     yield put(patientActions.patientTypeRequestFailure(error));

//     if (error.code === 401 || error.code === 403)
//       yield put(loginActions.logoutRequest());
//   }
// }
