import { getCmerPatientDetailsType } from "../types";

export const getCmerPatientDetailsActions = {
  getCmerPatientDetailsRequest,
  getCmerPatientDetailsSuccess,
  getCmerPatientDetailsFailure,
};
function getCmerPatientDetailsRequest(query, token) {
  return {
    type: getCmerPatientDetailsType.GET_CMER_PATIENT_DETAILS_REQUEST,
    query,
    token,
  };
}
function getCmerPatientDetailsSuccess(getAll) {
  return {
    type: getCmerPatientDetailsType.GET_CMER_PATIENT_DETAILS_SUCCESS,
    getAll,
  };
}
function getCmerPatientDetailsFailure(error) {
  return {
    type: getCmerPatientDetailsType.GET_CMER_PATIENT_DETAILS_FAILURE,
    error,
  };
}
