import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  message: "",
  timeStamp: "",
};

export const refreshAppointmentSlice = createSlice({
  name: "refreshAppointment",
  initialState: initialState,
  reducers: {
    refreshAppointmentRequest: (state, action) => {
      state.loading = true;
    },
    refreshAppointmentSuccess: (state, action) => {
      state.loading = false;
      state.error = "";
      state.message = action.payload.message;
      state.timeStamp = action.payload.timestamp;
    },
    refreshAppointmentFaliure: (state, action) => {
      return {
        ...initialState,
        loading: false,
        timeStamp: state.timeStamp,
        error: action.payload,
      };
    },
    refreshAppointmentReset: (state, action) => {
      return { ...initialState, timeStamp: state.timeStamp };
    },
  },
});

export const {
  refreshAppointmentRequest,
  refreshAppointmentSuccess,
  refreshAppointmentFaliure,
  refreshAppointmentReset,
} = refreshAppointmentSlice.actions;
export default refreshAppointmentSlice.reducer;
