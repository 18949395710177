import { facilityOperationTypes } from "../types";

export const facilityOperationActions = {
  facilityOperationRequest,
  facilityOperationRequestReset,
  facilityOperationRequestSuccess,
  facilityOperationRequestFailure,
};
function facilityOperationRequest(request, token) {
  return {
    type: facilityOperationTypes.FACILITY_OPERATION_REQUEST,
    request,
    token,
  };
}
function facilityOperationRequestReset() {
  return {
    type: facilityOperationTypes.FACILITY_OPERATION_REQUEST_RESET,
  };
}
function facilityOperationRequestSuccess(message) {
  return {
    type: facilityOperationTypes.FACILITY_OPERATION_REQUEST_SUCCESS,
    message,
  };
}
function facilityOperationRequestFailure(error) {
  return {
    type: facilityOperationTypes.FACILITY_OPERATION_REQUEST_FAILURE,
    error,
  };
}
