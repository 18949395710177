import React, { useEffect, useState } from "react";

import { useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//react-icons
import styles from "./TasksDetails.module.scss";
import { AddButton } from "../../cm/EditPatient/components/AddButton";

import { strings } from "./strings";
import { AddTask } from "./components";
import { ActionBtn } from "../../../components";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import ConfirmDialog from "../../../components/Dialog";
import { addTaskActionsCm } from "./actions/addTask.action";
import { TasksDetailsCMActions } from "./actions/tasksDetails.action";
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton className={styles.toolbar} />
      <GridToolbarFilterButton className={styles.toolbar} />
      <GridToolbarDensitySelector className={styles.toolbar} />
      <GridToolbarExport
        className={styles.toolbar}
        csvOptions={{
          fields: [
            "UserName",
            "Groupname",
            "SubGroupname",
            "TaskDate",
            "TaskDetail",
          ],
        }}
      />
    </GridToolbarContainer>
  );
}
function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}
const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};
renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.string.isRequired,
};

function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={params.value || ""}
      width={params.colDef.computedWidth}
    />
  );
}
const CMTasksDetails = () => {
  // const navigate = useNavigate();
  // For pagination
  const userData = useSelector((state) => state.login.userData);
  const tasksDetails = useSelector((state) => state.getAlltasksDetailsCM);
  console.log(tasksDetails, "tasksDetails");

  const addTask = useSelector((state) => state.addTaskReducerCM);

  const deleteTask = useSelector((state) => state.addTask);

  useEffect(() => {
    console.log(addTask, "tasktails");
    if (addTask?.message && addTask?.message !== "") {
      const request = {
        from: sDate,
        to: eDate,
        patientId: patientDetails?.PatientId,
        admissionId: patientDetails?.parentAdmissionId,
      };
      setShow(false);
      if (typeof userData.token !== "undefined")
        dispatch(
          TasksDetailsCMActions.tasksDetailsCMRequest(request, userData.token)
        );
    }
  }, [addTask]);
  const [isEditing, setEditing] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();

  const [sDate, setSDate] = useState(
    new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0).toLocaleDateString(
      "en-CA"
    )
  );
  const [eDate, setEDate] = useState(
    new Date(new Date().getFullYear() + 1, 0, 0, 0, 0, 0, 0).toLocaleDateString(
      "en-CA"
    )
  );
  const { state } = useLocation();

  const { patientId } = useParams();
  const [patientDetails, setPatientDetails] = useState(state?.patientDetails1);
  console.log(state?.patientDetails1, "patientIdpatientId");
  const [show, setShow] = useState(false);
  const [isTaskDelete, setIsTaskDelete] = useState(false);
  const [deleteTaskDetails, setDeleteTaskDetails] = useState({
    isDeleteTask: false,
    details: null,
  });
  useEffect(() => {
    console.log(selectedItem, "selectedItem");
  }, [selectedItem]);
  const columns = [
    {
      field: "edit",
      headerName: "Edit",
      flex: 0.05,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const onEditClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          console.log(params);
          setSelectedItem(params?.row);
          setShow(!show);
          // if (tasksDetails.tasksDetails !== undefined) {
          //   setSelectedItem(
          //     tasksDetails.tasksDetails.filter((item) =>
          //       item.TaskID.includes(params.id)
          //     )[0]
          //   );
          //   setShow(!show);
          setEditing(true);
          // }

          // return alert(JSON.stringify(params.id));
        };
        return (
          <>
            <ActionBtn isEdit onClick={onEditClick} />
          </>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 0.08,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setDeleteTaskDetails({
            isDeleteTask: true,
            details: params?.row,
          });
        };
        return (
          <>
            <ActionBtn onClick={onClick} />
          </>
        );
      },
    },
    {
      field: "UserName",
      headerName: strings.TH_USERNAME,
      flex: 0.15,
      headerClassName: styles.header,
      cellClassName: styles.cell,
    },
    {
      field: "TaskDate",
      flex: 0.15,

      headerName: strings.TH_DATE,
      type: "date",
    },
    {
      field: "Groupname",
      headerName: strings.TH_GROUP,
      flex: 0.15,
      renderCell: renderCellExpand,
    },
    {
      field: "SubGroupname",
      headerName: strings.TH_SUBGROUP,
      headerClassName: styles.header,
      flex: 0.15,
      renderCell: renderCellExpand,
    },
    {
      field: "TaskDetail",
      headerName: strings.TH_TASKDETAILS,
      headerClassName: styles.header,
      flex: 0.15,
      renderCell: renderCellExpand,
    },
  ];

  useEffect(() => {
    console.log(patientDetails);
    const request = {
      from: sDate,
      to: eDate,
      patientId: patientDetails?.PatientId,
      admissionId: patientDetails?.parentAdmissionId,
    };

    if (typeof userData.token !== "undefined")
      dispatch(
        TasksDetailsCMActions.tasksDetailsCMRequest(request, userData.token)
      );
  }, [patientId, sDate, eDate]);

  let handleDiscard = () => {
    setIsTaskDelete(false);
    setDeleteTaskDetails({
      isDeleteTask: false,
      details: null,
    });
  };
  let handleDialogClose = () => {
    setIsTaskDelete(false);

    let request = {
      TaskID: deleteTaskDetails?.details?.TaskID,

      Flag: "Delete",
    };

    dispatch(addTaskActionsCm.addTaskCmRequest(request, userData.token));

    setDeleteTaskDetails({
      isDeleteTask: false,
      details: null,
    });
  };
  return (
    <div className={styles.container}>
      {deleteTaskDetails?.isDeleteTask && (
        <ConfirmDialog
          open={deleteTaskDetails?.isDeleteTask}
          handleDialogClose={handleDialogClose}
          handleDiscard={handleDiscard}
        />
      )}
      {!show && (
        <div className={styles.action_container}>
          <AddButton
            // isActive={!show}
            onClick={() => {
              setShow((prev) => !prev);
              setEditing(false);
            }}
            btnEventName={strings.BTN_ADD}
          />

          <div className={styles.date_picker_container}>
            <div className={styles.picker_container}>
              From:{"   "}
              <input
                type="date"
                max="9999-12-31"
                className={styles.picker}
                value={sDate}
                onChange={(e) => setSDate(e.target.value)}
              />
            </div>
            <div className={styles.picker_container}>
              To:{"   "}
              <input
                type="date"
                max="9999-12-31"
                className={styles.picker}
                value={eDate}
                onChange={(e) => setEDate(e.target.value)}
              />
            </div>
          </div>
        </div>
      )}

      {show && (
        <AddTask
          isEditing={isEditing}
          setShow={(val) => setShow(val)}
          editValue={selectedItem}
          autoCompleteList={tasksDetails.lstGroup}
          patientDetails={patientDetails}
        />
      )}

      {!show && (
        <DataGrid
          autoHeight
          loading={tasksDetails.loading}
          className={styles.table}
          rows={tasksDetails?.getAll}
          error={tasksDetails.error}
          columns={columns}
          getRowId={(row) => row.TaskID}
          getRowClassName={styles.table_rows}
          components={{ Toolbar: CustomToolbar }}
          pageSize={10}
          rowsPerPageOptions={[10]}
          componentsProps={{
            pagination: {
              component: "div",
              style: {
                flex: 1,
              },
            },
          }}
          slotProps={{
            filterPanel: {
              // Force usage of "And" operator
              // Display columns by ascending alphabetical order
              columnsSort: "asc",
              filterFormProps: {
                // Customize inputs by passing props
                logicOperatorInputProps: {
                  variant: "outlined",
                  size: "small",
                },
                columnInputProps: {
                  variant: "outlined",
                  size: "small",
                  sx: { mt: "auto" },
                },
                operatorInputProps: {
                  variant: "outlined",
                  size: "small",
                  sx: { mt: "auto" },
                },
                valueInputProps: {
                  InputComponentProps: {
                    variant: "outlined",
                    size: "small",
                  },
                },
                deleteIconProps: {
                  sx: {
                    "& .MuiSvgIcon-root": { color: "#d32f2f" },
                  },
                },
              },
              sx: {
                // Customize inputs using css selectors
                "& .MuiDataGrid-filterForm": { p: 2 },
                "& .MuiDataGrid-filterForm:nth-child(even)": {
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark" ? "#444" : "#f5f5f5",
                },
                "& .MuiDataGrid-filterFormLogicOperatorInput": { mr: 2 },
                "& .MuiDataGrid-filterFormColumnInput": { mr: 2, width: 150 },
                "& .MuiDataGrid-filterFormOperatorInput": { mr: 2 },
                "& .MuiDataGrid-filterFormValueInput": { width: 200 },
              },
            },
          }}
          initialState={{
            filter: {
              filterModel: {
                items: [
                  {
                    id: 1,
                    field: "name",
                    operator: "contains",
                    value: "D",
                  },
                  {
                    id: 2,
                    field: "name",
                    operator: "contains",
                    value: "D",
                  },
                  {
                    id: 3,
                    field: "rating",
                    operator: ">",
                    value: "0",
                  },
                ],
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default CMTasksDetails;
