import { tasksDetailsType } from "../types/tasksDetails.type";

const initialState = {
  loading: false,
  tasksDetails: [],
  lstGroup: [],
};

export const tasksDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case tasksDetailsType.CALL_TASKS_DETAILS_REQUEST:
      return { ...initialState, loading: true, tasksDetails: [], lstGroup: [] };
    case tasksDetailsType.CALL_TASKS_DETAILS_REQUEST_SUCCESS:
      return {
        // ...initialState,
        tasksDetails: action.tasksDetails,
        lstGroup: action.lstGroup,
      };
    case tasksDetailsType.CALL_TASKS_DETAILS_REQUEST_FAILURE:
      return { error: action.error };
    //  return { ...initialState,error: action.error };
    default:
      return state;
  }
};
