import { visitDetailsType } from "../types/visitDetails.type";
export const visitDetailsActions = {
  visitDetailsRequest,
  visitDetailsRequestSuccess,
  visitDetailsRequestFailure,
};

function visitDetailsRequest(request, token) {
  return { type: visitDetailsType.VISIT_DETAILS_REQUEST, request, token };
}
function visitDetailsRequestSuccess(visits) {
  return {
    type: visitDetailsType.VISIT_DETAILS_REQUEST_SUCCESS,
    visits,
  };
}
function visitDetailsRequestFailure(error) {
  return { type: visitDetailsType.VISIT_DETAILS_REQUEST_FAILURE, error };
}
