import React, { useEffect, useState } from "react";
import PatientMedications from "./PatientMedications";
import PatientAppointments from "./PatientAppointments";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pageActions } from "../../../store/actions";
import dayjs from "dayjs";
import PatientCallStatus from "./PatientCallStatus";
import {
  getActivePatientTCActions,
  updatePatientCallStatusTCActions,
} from "../actions";
import { format } from "date-fns";
const ActivePatientDetails = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.login.userData);

  const patientDetails = state?.patientDetails;
  const navigate = useNavigate();
  const patientCallStatus = useSelector(
    (state) => state.updatePatientCallStatus
  );

  const [patientDetailsUpdate, setPatientDetailsUpdate] = useState({
    callCompleted: false,
    donotCall: false,
    reminder: false,
    note: patientDetails?.CaseManagerNotes || "",
    dcDate: patientDetails?.DCDateNew
      ? dayjs(patientDetails?.DCDateNew.split("T")[0])
      : null,
  });

  const [loadingFlag, setLoadingFlag] = useState({
    Reminder: false,
    Do_not_Call: false,
    Call_Completed: false,
    caseManagerNotes: false,
    dcDate: false,
  });
  const [body, setBody] = useState({
    parentAdmissionId: patientDetails?.parentAdmissionId,
  });
  useEffect(() => {
    if (patientCallStatus.message !== "") {
      setLoadingFlag({
        Reminder: false,
        Do_not_Call: false,
        Call_Completed: false,
        caseManagerNotes: false,
        dcDate: false,
      });
    }
  }, [patientCallStatus.message]);
  const handleSubmit = (targetButtonValue) => {
    const updatedBody = { ...body }; // Create a copy of body to avoid mutating state directly
    if (targetButtonValue === "Reminder") {
      updatedBody.reminder = 1;
      setLoadingFlag({
        Do_not_Call: false,
        Call_Completed: false,
        Reminder: true,
        caseManagerNotes: false,
      });
    } else if (targetButtonValue === "Do_not_Call") {
      updatedBody.donotCall = 1;
      setLoadingFlag({
        Reminder: false,
        Call_Completed: false,

        Do_not_Call: true,
        caseManagerNotes: false,
      });
    } else if (targetButtonValue === "Call_Completed") {
      updatedBody.callCompleted = 1;
      setLoadingFlag({
        Call_Completed: true,
        Reminder: false,

        Do_not_Call: false,
        caseManagerNotes: false,
      });
    } else if (targetButtonValue === "caseManagerNotes") {
      updatedBody.caseManagerNotes = patientDetailsUpdate?.note;
      setLoadingFlag({
        Call_Completed: false,
        Reminder: false,

        Do_not_Call: false,
        caseManagerNotes: true,
      });
    }

    updatedBody.dcDate =
      (patientDetailsUpdate?.dcDate &&
        dayjs(patientDetailsUpdate?.dcDate)?.format("YYYY-MM-DD")) ||
      null;
    dispatch(
      updatePatientCallStatusTCActions.updatePatientCallStatusRequest(
        updatedBody,
        userData.token
      )
    );
  };

  useEffect(() => {
    if (
      patientCallStatus.message !== "" &&
      patientCallStatus.message !== "Notes submitted successfully!"
    ) {
      navigate("/transitioncare");
      setTimeout(() => {
        dispatch(
          updatePatientCallStatusTCActions.updatePatientCallStatusReset()
        );
      }, 100);
      if (userData.token !== undefined) {
        // filterQuery;
        let query = {
          pageSize: 10,
          pageNumber: 1,
          all: true,
          search: "",
          patientStatus: "inpatient",
          patientType: "admission",
        };

        dispatch(
          getActivePatientTCActions.getallActivePatientRequest(
            query,
            userData.token
          )
        );
      }
    } else if (patientCallStatus.message === "Notes submitted successfully!") {
      // dispatch(alertActions.alertSuccessRequest(patientCallStatus?.message));
      setTimeout(() => {
        dispatch(
          updatePatientCallStatusTCActions.updatePatientCallStatusReset()
        );
      }, 100);
    }
  }, [patientCallStatus]);
  useEffect(() => {
    if (patientDetails === undefined || patientDetails === null) {
      navigate("/transitioncare");
    } else {
      dispatch(
        pageActions.pageRequest(
          `${patientDetails?.LastName}, ${patientDetails?.FirstName}`
        )
      );
    }
  }, [dispatch, navigate, patientDetails]);

  return (
    <>
      <PatientCallStatus
        loadingFlag={loadingFlag}
        patientDetails={patientDetails}
        setPatientDetailsUpdate={setPatientDetailsUpdate}
        patientDetailsUpdate={patientDetailsUpdate}
        handleSubmit={handleSubmit}
        onChangeDc={(newValue) => {
          let date = dayjs(newValue);

          setPatientDetailsUpdate({ ...patientDetailsUpdate, dcDate: date });
        }}
      />
      <PatientMedications
        patientId={patientDetails?.PatientId}
        patientDetails={patientDetails}
      />
      <PatientAppointments
        patientId={patientDetails?.PatientId}
        patientDetails={patientDetails}
     
      />
    </>
  );
};

export default ActivePatientDetails;
