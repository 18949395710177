import { put, takeLatest, call } from "@redux-saga/core/effects";
import { getClinicalInfoActions } from "../actions";
import { clinicalInfoTypes } from "../types";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";

export function* watchClinicalInfo() {
  yield takeLatest(
    clinicalInfoTypes.GET_CLINICAL_INFO_REQUEST,
    getAllClinicalInfo
  );
}

function* getAllClinicalInfo(action) {
  try {
    let clinicalInfo = yield call(Api, {
      method: "POST",
      endpoint: `${endpoint.patientClinicalInformation}?AdmissionID=${action.request.AdmissionID}`,
      jwt: "Bearer " + action.token,
    });
    const parsedClinicalInfo = JSON.parse(clinicalInfo);

    yield put(
      getClinicalInfoActions.getClinicalInfoRequestSuccess(parsedClinicalInfo)
    );
  } catch (error) {
    yield put(getClinicalInfoActions.getClinicalInfoRequestFailure(error));
  }
}
