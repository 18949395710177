import { fileProcessingType } from "../types/fileprocessing.type";

export const fileProcessingActions = {
  FileProcessRequest,
  FileProcessSuccess,
  FileProcessFailure,
  FileProcessReset,
};

function FileProcessRequest(query, request, token) {
  return {
    type: fileProcessingType.FILE_PROCESSING_REQUEST,
    query,
    request,
    token,
  };
}

function FileProcessSuccess(message, records) {
  return {
    type: fileProcessingType.FILE_PROCESSING_SUCCESS,
    message,
    records,
  };
}

function FileProcessFailure(error) {
  return {
    type: fileProcessingType.FILE_PROCESSING_FAILURE,
    error,
  };
}

function FileProcessReset() {
  return {
    type: fileProcessingType.FILE_PROCESSING_RESET,
  };
}
