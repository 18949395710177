import { put, takeLatest, call } from "@redux-saga/core/effects";
import { deleteMailActions } from "../actions/deleteMail.action";
import { deleteMailTypes } from "../types/deleteMail.type";
import { endpoint } from "../../../api/Url";
import { Api } from "../../../api";
import { loginActions } from "../../Login/actions/login.action";
import { Api2 } from "../../../api/Api2";

export function* watchDeleteMail() {
  yield takeLatest(deleteMailTypes.DELETE_MAIL_REQUEST, getDeleteMail);
}

function* getDeleteMail(action) {
  try {
    const deleteMail = yield call(Api2, {
      endpoint: endpoint.deleteMail,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    yield put(deleteMailActions.deleteMailRequestSuccess(deleteMail));
  } catch (error) {
    yield put(deleteMailActions.deleteMailRequestFailure(error));

    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
  }
}
