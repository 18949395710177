import { immunizationTypes } from "../types";

const initialGetState = {
  loading: false,
  getAll: [],
  error: "",
  dropDownImmunization: [],
  dropdownSourcelist: [],
};
export const immunizationGetReducer = (state = initialGetState, action) => {
  switch (action.type) {
    case immunizationTypes.GETALL_IMMUNIZATION_REQUEST:
      return {
        ...initialGetState,
        loading: true,
      };
    case immunizationTypes.GETALL_IMMUNIZATION_REQUEST_SUCCESS:
      return {
        ...initialGetState,
        getAll: action.getAll,
        dropDownImmunization: action.dropDownImmunization,
        dropdownSourcelist: action.dropdownSourcelist,
      };
    case immunizationTypes.GETALL_IMMUNIZATION_REQUEST_FAILURE:
      return {
        ...initialGetState,
        error: action.error,
      };
    default:
      return state;
  }
};

const initialOperationState = {
  loading: false,
  message: "",
  error: "",
};
export const immunizationOperationReducer = (
  state = initialOperationState,
  action
) => {
  switch (action.type) {
    case immunizationTypes.OPERARTION_IMMUNIZATION_REQUEST:
      return {
        ...initialOperationState,
        loading: true,
      };
    case immunizationTypes.OPERARTION_IMMUNIZATION_REQUEST_SUCCESS:
      return {
        ...initialOperationState,
        message: action.message,
      };
    case immunizationTypes.OPERARTION_IMMUNIZATION_REQUEST_FAILURE:
      return {
        ...initialOperationState,
        error: action.error,
      };
    case immunizationTypes.OPERARTION_IMMUNIZATION_REQUEST_RESET:
      return {
        ...initialOperationState,
      };
    default:
      return state;
  }
};

const initialDeleteState = {
  loading: false,
  message: "",
  error: "",
};
export const immunizationDeleteReducer = (
  state = initialDeleteState,
  action
) => {
  switch (action.type) {
    case immunizationTypes.DELETE_IMMUNIZATION_REQUEST:
      return {
        ...initialDeleteState,
        loading: true,
      };
    case immunizationTypes.DELETE_IMMUNIZATION_REQUEST_SUCCESS:
      return {
        ...initialDeleteState,
        message: action.message,
      };
    case immunizationTypes.DELETE_IMMUNIZATION_REQUEST_FAILURE:
      return {
        ...initialDeleteState,
        error: action.error,
      };
    case immunizationTypes.DELETE_IMMUNIZATION_REQUEST_RESET:
      return {
        ...initialDeleteState,
      };
    default:
      return state;
  }
};
