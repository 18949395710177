import { bundleDispositionTypes } from "../types";

const initiaLState = {
  loading: false,
  getAll: [],
};

export const bundleDispositionDMReducer = (state = initiaLState, action) => {
  switch (action.type) {
    case bundleDispositionTypes.BUNDLE_DISPOSITION_REQUEST:
      return {
        ...initiaLState,
        loading: true,
      };
    case bundleDispositionTypes.BUNDLE_DISPOSITION_REQUEST_SUCCESS:
      return {
        ...initiaLState,
        getAll: action.getAll,
      };
    case bundleDispositionTypes.BUNDLE_DISPOSITION_REQUEST_FAILURE:
      return {
        ...initiaLState,
        error: action.error,
      };
    default:
      return state;
  }
};
