import { addTaskType } from "../types/addTask.type";
export const addTaskActions = {
  addTaskRequest,
  addTaskRequestReset,
  addTaskRequestSuccess,
  addTaskRequestFailure,
};

function addTaskRequest(request, token) {
  return { type: addTaskType.ADD_TASK_REQUEST, request, token };
}

function addTaskRequestReset() {
  return { type: addTaskType.ADD_TASK_REQUEST_RESET };
}
function addTaskRequestSuccess(message) {
  return {
    type: addTaskType.ADD_TASK_REQUEST_SUCCESS,
    message,
  };
}
function addTaskRequestFailure(error) {
  return { type: addTaskType.ADD_TASK_REQUEST_FAILURE, error };
}
