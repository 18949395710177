import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

const RadioComp = ({ value, onRadioChange, options, defaultValue }) => {
  const radioArray = options.map((option, index) => (
    <FormControlLabel
      key={index} // Assign a unique key
      value={option.value}
      control={<Radio size="small" />}
      label={option.label}
    />
  ));

  return (
    <RadioGroup
      row
      aria-labelledby="demo-radio-buttons-group-label"
      name="radio-buttons-group"
      onChange={onRadioChange}
      value={value}
    >
      {radioArray}
    </RadioGroup>
  );
};

export default RadioComp;
