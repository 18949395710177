import { getMsoTypes } from "../types";

export const getMsoActions = {
    msoRequest,
    msoRequestSuccess,
    msoRequestFailure,
  };
  function msoRequest(request, token) {
    return {
      type: getMsoTypes.GET_MSO_REQUEST,
      request,
      token,
    };
  }
  function msoRequestSuccess(get) {
    return {
      type: getMsoTypes.GET_MSO_REQUEST_SUCCESS,
      get,
    };
  }
  function msoRequestFailure(error) {
    return {
      type: getMsoTypes.GET_MSO_REQUEST_FAILURE,
      error,
    };
  }