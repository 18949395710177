import React from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useSelector } from "react-redux";
import { colors } from "../../../themes/colors";
import dayjs from "dayjs";
import { IoArrowBack } from "react-icons/io5";

import { Progress } from "../../../components";
import { Spinner } from "../../../core";
import { useNavigate } from "react-router-dom";
// Adjust the import paths accordingly

const NursingProgress = ({
  initialValues,
  updateProgressValues,
  updateData,
  buttonLabel,
  buttonLoadingLabel,
  loading,
  disabled,
  handleDiscard,
}) => {
  const navigate = useNavigate();
  const getNursingProgress = useSelector((state) => state.getNursingProgress);
  // State management for form values

  // Handle form changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    updateProgressValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleDateChange = (name) => (date) => {
    updateProgressValues((prevValues) => ({
      ...prevValues,
      [name]: date,
    }));
  };
  const handleClearDate = (name) => (date) => {
    updateProgressValues((prevValues) => ({
      ...prevValues,
      [name]: null,
    }));
  };

  const handleProgressingChange = (name) => (event) => {
    // Make sure setProgressInitialValues is defined in your component
    updateProgressValues((prevValues) => ({
      ...prevValues,
      [name]: event.target.value,
    }));
  };

  /*// !side effects */

  return (
    <>
      <Box sx={{}}>
        {/* {getNursingProgress?.data?.history?.length > 0 && (
          <Grid container justifyContent="flex-end" columnGap={1}>
            <Grid item xs={4} lg={3}>
              <Select
                value={progressDateId || ""}
                onChange={handleProgressDateChange("progressingAsExpected")}
                sx={{
                  width: "100%",
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: "none" },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  border: `1px solid ${colors.color_light_txt}`,
                  "& .MuiSelect-select": {
                    padding: "8px 12px",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 200, // Adjust based on your needs
                      overflow: "auto",
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <em>History</em>
                </MenuItem>
                {getNursingProgress?.data?.history?.map(
                  ({ id, progressDate }) => (
                    <MenuItem key={id} value={id}>
                      {dateTimeGetter(progressDate)}
                    </MenuItem>
                  )
                )}
              </Select>
            </Grid>
            <Grid
              container
              xs={0.5}
              justifyContent="center"
              alignItems="center"
              sx={{
                "&:hover": { scale: 1.5 },
                backgroundColor: colors.color_background,
                p: 1,
              }}
            >
              <Tooltip title="Copy">
                <IoMdCopy onClick={progressCopied} style={copyIconStyles} />
              </Tooltip>
            </Grid>
          </Grid>
        )} */}
        {getNursingProgress?.loading ? (
          <Progress />
        ) : (
          <>
            {" "}
            {/* Interdisciplinary Care Plan Section */}
            <Card
              sx={{
                width: "100%",
                backgroundColor: "#F5F5F5",
                m: 2,
                p: 2,
                position: "relative",
              }}
            >
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontWeight: "bold" }}
              >
                Interdisciplinary Care Plan
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 500 }}
                    gutterBottom
                  >
                    Discharge Planning Considerations:
                  </Typography>
                  <TextField
                    size="small"
                    name="dischargePlanningConsiderations"
                    disabled={disabled}
                    value={initialValues.dischargePlanningConsiderations}
                    onChange={handleChange}
                    multiline
                    rows={6}
                    fullWidth
                    variant="outlined"
                    sx={{
                      color: colors.color_primary_txt,
                      backgroundColor: colors.color_primary,
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 0,
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Card>
            {/* 7-Day Chain Section */}
            <Card
              sx={{
                width: "100%",
                backgroundColor: "#F5F5F5",
                m: 2,
                p: 2,
                position: "relative",
              }}
            >
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ fontWeight: "bold" }}
              >
                7-Day Chain
              </Typography>
              <Grid container spacing={2}>
                {/* Date Pickers */}
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle2" sx={{ mt: 2 }} gutterBottom>
                    Most Recent Care Plan Date
                  </Typography>
                  <DatePicker
                    disabled={disabled}
                    slotProps={{
                      field: {
                        clearable: true,
                        onClear: () =>
                          handleClearDate("mostRecentCarePlanDate"),
                      },
                    }}
                    size="small"
                    value={
                      initialValues.mostRecentCarePlanDate
                        ? dayjs(initialValues.mostRecentCarePlanDate)
                        : null
                    }
                    onChange={handleDateChange("mostRecentCarePlanDate")}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    sx={{
                      boxShadow: "none",
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      background: colors.color_primary,
                      width: "100%",
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle2"
                    sx={{ mt: 2, fontWeight: 500 }}
                    gutterBottom
                  >
                    Next Care Plan Date
                  </Typography>
                  <DatePicker
                    disabled={disabled}
                    value={dayjs(initialValues.nextCarePlanDate)}
                    slotProps={{
                      field: {
                        clearable: true,
                        onClear: () => handleClearDate("nextCarePlanDate"),
                      },
                    }}
                    onChange={handleDateChange("nextCarePlanDate")}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    sx={{
                      boxShadow: "none",
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      background: colors.color_primary,
                      width: "100%",
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle2"
                    sx={{ mt: 2, fontWeight: 500 }}
                    gutterBottom
                  >
                    Anticipated Date
                  </Typography>
                  <DatePicker
                    disabled={disabled}
                    value={dayjs(initialValues.anticipatedDate)}
                    slotProps={{
                      field: {
                        clearable: true,
                        onClear: () => handleClearDate("anticipatedDate"),
                      },
                    }}
                    size="small"
                    onChange={handleDateChange("anticipatedDate")}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    sx={{
                      boxShadow: "none",
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      background: colors.color_primary,
                      width: "100%",
                    }}
                  />
                </Grid>

                {/* Text Areas for Notes and Cognitive Status */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle2"
                    sx={{ mt: 2, fontWeight: 500 }}
                    gutterBottom
                  >
                    Nursing Notes
                  </Typography>
                  <TextField
                    name="nursingNotes"
                    disabled={disabled}
                    placeholder=""
                    value={initialValues.nursingNotes}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    fullWidth
                    size="small"
                    variant="outlined"
                    sx={{
                      color: colors.color_primary_txt,
                      backgroundColor: colors.color_primary,
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 0,
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle2"
                    sx={{ mt: 2, fontWeight: 500 }}
                    gutterBottom
                  >
                    Cognitive Status
                  </Typography>
                  <TextField
                    disabled={disabled}
                    name="cognitiveStatus"
                    placeholder=""
                    value={initialValues.cognitiveStatus}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{
                      color: colors.color_primary_txt,
                      backgroundColor: colors.color_primary,
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 0,
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle2"
                    sx={{ mt: 2, fontWeight: 500 }}
                    gutterBottom
                  >
                    PT Progress
                  </Typography>
                  <TextField
                    disabled={disabled}
                    name="ptProgress"
                    placeholder=""
                    value={initialValues.ptProgress}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{
                      color: colors.color_primary_txt,
                      backgroundColor: colors.color_primary,
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 0,
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle2"
                    sx={{ mt: 2, fontWeight: 500 }}
                    gutterBottom
                  >
                    OT Progress
                  </Typography>
                  <TextField
                    disabled={disabled}
                    name="otProgress"
                    placeholder=""
                    value={initialValues.otProgress}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{
                      color: colors.color_primary_txt,
                      backgroundColor: colors.color_primary,
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 0,
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                    }}
                  />
                </Grid>
                {/* LOS Fields */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 500 }}
                    gutterBottom
                  >
                    Actual LOS
                  </Typography>
                  <TextField
                    disabled={disabled}
                    name="actualLOS"
                    value={initialValues.actualLOS}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder=""
                    sx={{
                      color: colors.color_primary_txt,
                      backgroundColor: colors.color_primary,
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 0,
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 500 }}
                    gutterBottom
                  >
                    Expected LOS
                  </Typography>
                  <TextField
                    disabled={disabled}
                    name="expectedLOS"
                    size="small"
                    value={initialValues.expectedLOS}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    placeholder=""
                    sx={{
                      color: colors.color_primary_txt,
                      backgroundColor: colors.color_primary,
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 0,
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                    }}
                  />
                </Grid>

                {/* Additional Notes and Status */}

                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 500 }}
                    gutterBottom
                  >
                    ST: Diet
                  </Typography>
                  <TextField
                    disabled={disabled}
                    name="stDiet"
                    placeholder=""
                    size="small"
                    value={initialValues.stDiet}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    fullWidth
                    variant="outlined"
                    sx={{
                      color: colors.color_primary_txt,
                      backgroundColor: colors.color_primary,
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 0,
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 500 }}
                    gutterBottom
                  >
                    Progressing As Expected
                  </Typography>
                  <Select
                    disabled={disabled}
                    placeholder=""
                    id="demo-simple-select"
                    size="small"
                    sx={{
                      width: "100%",
                      boxShadow: "none",
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },

                      background: colors.color_primary,
                    }}
                    value={initialValues?.progressingAsExpected}
                    onChange={handleProgressingChange("progressingAsExpected")}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </Grid>

                {/* Submit Button */}
              </Grid>
            </Card>
            <Grid
              container
              sx={{ textAlign: "right" }}
              justifyContent={"space-between"}
              // spacing={1}
            >
              <Button
                onClick={() => navigate("/facility")}
                variant="text"
                sx={{
                  ":hover": {
                    backgroundColor: "transparent",
                  },
                  textTransform: "capitalize",
                }}
              >
                <IoArrowBack style={{ marginRight: 5 }} />
                Back To Home
              </Button>
              {!disabled && (
                <Grid item xs={4} container spacing={1}>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      sx={{
                        color: colors?.color_primary_txt,
                        backgroundColor: colors?.color_active,
                        borderColor: colors?.color_primary_txt,

                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: colors?.color_active,
                          borderColor: colors?.color_primary_txt,
                          color: colors?.color_primary_txt,
                        },
                        fontSize: 16,
                        width: "fit-content",
                        minWidth: "100%",
                      }}
                      onClick={handleDiscard}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      sx={{
                        backgroundColor: colors?.color_primary_txt,
                        color: colors?.color_active,
                        textTransform: "capitalize",
                        "&:hover": {
                          color: colors?.color_active,
                          borderColor: colors?.color_primary_txt,
                          backgroundColor: colors?.color_primary_txt,
                        },
                        fontSize: 18,
                        width: "fit-content",
                        minWidth: "100%",
                      }}
                      onClick={updateData}
                    >
                      {loading ? (
                        <>
                          <Spinner width={18} height={18} />
                          <span style={{ marginLeft: "6px" }}>
                            {buttonLoadingLabel}
                          </span>
                        </>
                      ) : (
                        <span style={{ marginLeft: "6px" }}>{buttonLabel}</span>
                      )}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};

export default NursingProgress;
