export const hedisProcedureTypes = {
  GETALL_PROCEDURELIST_REQUEST: "GETALL_PROCEDURELIST_REQUEST",
  GETALL_PROCEDURELIST_SUCCESS: "GETALL_PROCEDURELIST_SUCCESS",
  GETALL_PROCEDURELIST_FAILURE: "GETALL_PROCEDURELIST_FAILURE",

  UPDATE_PROCEDURELIST_REQUEST: "UPDATE_PROCEDURELIST_REQUEST",
  UPDATE_PROCEDURELIST_SUCCESS: "UPDATE_PROCEDURELIST_SUCCESS",
  UPDATE_PROCEDURELIST_FAILURE: "UPDATE_PROCEDURELIST_FAILURE",

  RESET_PROCEDURELIST: "RESET_PROCEDURELIST",
};
