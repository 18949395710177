import { put, call, takeLatest } from "@redux-saga/core/effects";
import { endpoint } from "../../../../api/Url";
import { sentActions } from "../../actions";
import { sentType } from "../../types";
import { Api2 } from "../../../../api/Api2";
export function* watchSentMail() {
  yield takeLatest(sentType.SENT_REQUEST, sentMail);
}

function* sentMail(action) {
  console.log(action);
  try {
    let message = yield call(Api2, {
      method: "POST",
      endpoint: endpoint.sendMail,
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    yield put(sentActions.sentRequestSuccess(message));
  } catch (error) {
    yield put(sentActions.sentRequestFailure(error));
  }
}
