import { providerCptType } from "../types";

export const getProviderCptActions = {
  getProviderCptRequest,
  getProviderCptRequestSuccess,
  getProviderCptRequestFailure,
};
function getProviderCptRequest(token) {
  return {
    type: providerCptType.GET_PROVIDER_CPT_REQUEST,
    token,
  };
}
function getProviderCptRequestSuccess(getAll) {
  return {
    type: providerCptType.GET_PROVIDER_CPT_REQUEST_SUCCESS,
    getAll,
  };
}
function getProviderCptRequestFailure(error) {
  return {
    type: providerCptType.GET_PROVIDER_CPT_REQUEST_FAILURE,
    error,
  };
}
