import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { callActionTakenActions } from "../actions";
import { callActionTakenTypes } from "../types";
export function* watchCallActionsTaken() {
  yield takeLatest(
    callActionTakenTypes.CALL_ACTION_TAKEN_REQUEST,
    getActionsTaken
  );
}

function* getActionsTaken(action) {
  try {
    const actions = yield call(Api, {
      endpoint: endpoint.getTransitionActions,
      method: "POST",
      jwt: "Bearer " + action.token,
    });

    let parsedActions = JSON.parse(actions);

    yield put(
      callActionTakenActions.callActionTakenRequestSuccess(
        parsedActions.TransitionActionModel
      )
    );
  } catch (error) {
    yield put(callActionTakenActions.callActionTakenRequestFailure(error));
  }
}
