import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { dispositionActions } from "../actions";
import { dispositionTypes } from "../types";

export function* watchDMDisposition() {
  yield takeLatest(dispositionTypes.DISPOSITION_REQUEST, getAllDisposition);
}

function* getAllDisposition(action) {
  try {
    let data = yield call(Api, {
      method: "POST",
      endpoint: endpoint.getAllDMDispositionData,
      jwt: "Bearer " + action.token,
    });
    let parsedData = JSON.parse(data);

    yield put(
      dispositionActions.dispositionRequestSuccess(parsedData.DispositionList)
    );
  } catch (error) {
    yield put(dispositionActions.dispositionRequestFailure(error));
  }
}
