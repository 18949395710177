import { redirectType } from "../types/redirect.type";

export const redirectActions = {
  redirectRequest,
  redirectRequestSuccess,
  redirectRequestFailure,
  redirectRequestReset,
};

function redirectRequest(request) {
  return { type: redirectType.REDIRECT_REQUEST, request };
}
function redirectRequestSuccess(contactNo) {
  return { type: redirectType.REDIRECT_REQUEST_SUCCESS, contactNo };
}
function redirectRequestFailure(error) {
  return { type: redirectType.REDIRECT_REQUEST_FAILURE, error };
}
function redirectRequestReset() {
  return { type: redirectType.REDIRECT_REQUEST_RESET };
}
