import { deleteChargesTypes } from "../types/chargesDelete.type";

const initialState = {
  deleting: false,
};

export const deleteChargesReducer = (state = initialState, action) => {
  switch (action.type) {
    case deleteChargesTypes.DELETE_CHARGES_REQUEST:
      return { ...initialState, deleting: true };
    case deleteChargesTypes.DELETE_CHARGES_REQUEST_SUCCESS:
      return { ...initialState, message: action.message };
    case deleteChargesTypes.DELETE_CHARGES_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    case deleteChargesTypes.DELETE_CHARGES_REQUEST_RESET:
      return { ...initialState };
    default:
      return state;
  }
};
