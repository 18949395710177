import { put, takeLatest, call } from "@redux-saga/core/effects";
import { updatePatientDetailsActions } from "../actions";
import { patientDetailsUpdateType } from "../types";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
export function* watchTCPatientDetailsUpdate() {
  yield takeLatest(
    patientDetailsUpdateType.UPDATE_PATIENT_DETAILS_REQUEST,
    updateTCPatientDetails
  );
}

function* updateTCPatientDetails(action) {
  try {
    let message = yield call(Api, {
      endpoint: endpoint.updateTCPatientDetails,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    const parsedMessage = JSON.parse(message);

    yield put(
      updatePatientDetailsActions.updatePatientDetailsRequestSuccess(
        parsedMessage[0]
      )
    );
  } catch (error) {
    yield put(
      updatePatientDetailsActions.updatePatientDetailsRequestFailure(error)
    );
  }
}
