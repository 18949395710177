import { pushDeviceTokenType } from "../types/pushDeviceToken.type";
const initalState = {
  savePushToken: false,
};
export const devicePushTokenReducer = (state = initalState, action) => {
  switch (action.type) {
    case pushDeviceTokenType.PUSH_DEVICE_TOKEN_REQUEST:
      return {
        savePushToken: true,
        devicePushToken: action.devicePushToken,
      };
    case pushDeviceTokenType.PUSH_DEVICE_TOKEN_REQUEST_RESET:
      return initalState;
    default:
      return state;
  }
};
