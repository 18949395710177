import React from "react";

import styles from "./ButtonCancel.module.scss";

export const ButtonCancel = (props) => {
  return (
    <button className={styles.btn_container} onClick={props.onClick}>
      {props.label}
    </button>
  );
};
