import { put, takeLatest, call } from "@redux-saga/core/effects";
import { ReferralPlansActions } from "../actions/ReferralPlans.action";
import { ReferralPlansType } from "../types/ReferralPlans.type";
import { endpoint } from "../../../../api/Url";
import { Api } from "../../../../api";
import { loginActions } from "../../../Login/actions/login.action";

export function* watchReferralPlans() {
  yield takeLatest(ReferralPlansType.REFERRAL_PLANS_REQUEST, getReferralPlans);
}

function* getReferralPlans(action) {
  try {
    //   api call
    const data = yield call(Api, {
      endpoint: `${endpoint.GetReferralPlans}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    const parsedData = JSON.parse(data);

    parsedData !== null && parsedData.length > 0
      ? yield put(ReferralPlansActions.referralPlansRequestSuccess(parsedData))
      : yield put(
          ReferralPlansActions.referralPlansRequestFailure("No data found")
        );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(ReferralPlansActions.referralPlansRequestFailure(error));
  }
}
