import { assessmentTypes } from "../types";

const initialState = {
  loading: false,
  assessment: [],
};

export const AssessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case assessmentTypes.ASSESSMENT_REQUEST:
      return { ...initialState, loading: true };
    case assessmentTypes.ASSESSMENT_REQUEST_SUCCESS:
      return {
        ...initialState,
        assessment: action.assessment,
      };
    case assessmentTypes.ASSESSMENT_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};
