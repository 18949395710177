export const pcpHierarchyTypes = {
  //get PCP Hierarchy

  PCP_HIERARCHY_REQUEST: "GETALL_DM_PCP_HIERARCHY_REQUEST",
  PCP_HIERARCHY_REQUEST_SUCCESS: "GETALL_DM_PCP_HIERARCHY_REQUEST_SUCCESS",
  PCP_HIERARCHY_REQUEST_FAILURE: "GETALL_DM_PCP_HIERARCHY_REQUEST_FAILURE",

  //SAVE PCP Hierarchy

  PCP_HIERARCHY_SAVE_REQUEST: "DM_PCP_HIERARCHY_SAVE_REQUEST",
  PCP_HIERARCHY_SAVE_REQUEST_SUCCESS: "DM_PCP_HIERARCHY_SAVE_REQUEST_SUCCESS",
  PCP_HIERARCHY_SAVE_REQUEST_FAILURE: "DM_PCP_HIERARCHY_SAVE_REQUEST_FAILURE",

  //EDIT  PCP Hierarchy

  PCP_HIERARCHY_EDIT_REQUEST: "DM_PCP_HIERARCHY_REQUEST_EDIT_REQUEST",
  PCP_HIERARCHY_EDIT_REQUEST_SUCCESS: "DM_PCP_HIERARCHY_EDIT_REQUEST_SUCCESS",
  PCP_HIERARCHY_EDIT_REQUEST_FAILURE: "DM_PCP_HIERARCHY_EDIT_REQUEST_FAILURE",

  //UPDATE PCP Hierarchy

  PCP_HIERARCHY_UPDATE_STATUS_REQUEST: "DM_PCP_HIERARCHY_UPDATE_STATUS_REQUEST",
  PCP_HIERARCHY_UPDATE_STATUS_REQUEST_SUCCESS:
    "DM_PCP_HIERARCHY_UPDATE_STATUS_REQUEST_SUCCESS",
  PCP_HIERARCHY_UPDATE_STATUS_REQUEST_FAILURE:
    "DM_PCP_HIERARCHY_STATUS_REQUEST_FAILURE",

  //RESET

  PCP_HIERARCHY_REQUEST_RESET: "DM_PCP_HIERARCHY_REQUEST_RESET",
};
