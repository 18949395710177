import { put, takeLatest, call } from "@redux-saga/core/effects";
import { ehrFileUploadActions } from "../actions";
import { ehrFileUploadTypes } from "../types";

import { endpoint, Url } from "../../../../api/Url";

import axios from "axios";
export function* watchEhrFileUpload() {
  yield takeLatest(ehrFileUploadTypes.EHRFILEUPLOAD_REQUEST, uploadFile);
}
function* uploadFile(action) {
  try {
    let message = yield call(axios, {
      method: "POST",
      url: Url.baseURL + endpoint.ehrFileUpload,
      data: action.request,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + action.token,
      },
    });

    yield put(ehrFileUploadActions.ehrFileUploadRequestSuccess(message.data));
  } catch (error) {
    console.log(error.message);
    let errormsg = {
      Result: "Failed to upload!",
    };

    yield put(ehrFileUploadActions.ehrFileUploadRequestSuccess(errormsg));
  }
}
