import { addTaskDetailsType } from "../types";

export const addTaskDetailsActions = {
  addTaskDetailsRequest,
  addTaskDetailsRequestSuccess,
  addTaskDetailsRequestFailure,
  addTaskDetailsRequestReset,
};

function addTaskDetailsRequest(request, token) {
  return { type: addTaskDetailsType.ADD_TASK_DETAILS_REQUEST, request, token };
}
function addTaskDetailsRequestSuccess(message) {
  return {
    type: addTaskDetailsType.ADD_TASK_DETAILS_REQUEST_SUCCESS,
    message,
  };
}
function addTaskDetailsRequestFailure(error) {
  return { type: addTaskDetailsType.ADD_TASK_DETAILS_REQUEST_FAILURE, error };
}
function addTaskDetailsRequestReset() {
  return { type: addTaskDetailsType.ADD_TASK_DETAILS_REQUEST_RESET };
}
