import { hedisProcedureTypes } from "../types/hedis.type";

export const hedisProcedureActions = {
  getAllProcedureListRequest,
  getAllProcedureListSuccess,
  getAllProcedureListFailure,

  updateProcedureListRequest,
  updateProcedureListSuccess,
  updateProcedureListFailure,

  resetProcedureList,
};

function getAllProcedureListRequest(request, token) {
  return {
    type: hedisProcedureTypes.GETALL_PROCEDURELIST_REQUEST,
    request,
    token,
  };
}

function getAllProcedureListSuccess(getAll) {
  return {
    type: hedisProcedureTypes.GETALL_PROCEDURELIST_SUCCESS,
    getAll,
  };
}

function getAllProcedureListFailure(error) {
  return {
    type: hedisProcedureTypes.GETALL_PROCEDURELIST_FAILURE,
    error,
  };
}

function updateProcedureListRequest(request, token) {
  return {
    type: hedisProcedureTypes.UPDATE_PROCEDURELIST_REQUEST,
    request,
    token,
  };
}

function updateProcedureListSuccess(message) {
  return { type: hedisProcedureTypes.GETALL_PROCEDURELIST_SUCCESS, message };
}

function updateProcedureListFailure(error) {
  return {
    type: hedisProcedureTypes.UPDATE_PROCEDURELIST_FAILURE,
    error,
  };
}
function resetProcedureList() {
  return { type: hedisProcedureTypes.RESET_PROCEDURELIST };
}
