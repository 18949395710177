//import dependencies
import { put, takeLatest, call } from "@redux-saga/core/effects";

//api
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
import { loginActions, redirectActions } from "../actions/";
import { loginType, redirectType } from "../types";
import { Api2 } from "../../../api/Api2";

export function* watchLogin() {
  yield takeLatest(redirectType.REDIRECT_REQUEST, handleValidateLogin);
  yield takeLatest(loginType.LOGIN_REQUEST, userLogin);
}

function* handleValidateLogin(action) {
  try {
    let response = yield call(Api2, {
      method: "POST",
      endpoint: `${endpoint.validatelogin}`,
      body: action.request,
    });

    if (response.contactNo === undefined) {
      yield put(loginActions.loginRequestSuccess(response.userData));
      yield put(redirectActions.redirectRequestReset());
    } else {
      yield put(redirectActions.redirectRequestSuccess(response.contactNo));
    }
  } catch (error) {
    yield put(redirectActions.redirectRequestFailure(error.message));
  }
}

// Login after otp verify
function* userLogin(action) {
  try {
    let response = yield call(Api2, {
      method: "POST",
      endpoint: `${endpoint.loginOtpVerify}`,
      body: action.request,
    });
    yield put(loginActions.loginRequestSuccess(response.userData));
  } catch (error) {
    yield put(loginActions.loginRequestFailure(error.message));
  }
}
