export const getActivePatientAppointmentsType = {
  GET_ACTIVE_PATIENT_APPOINTMENTS_REQUEST:
    "GET_ACTIVE_PATIENT_APPOINTMENTS_REQUEST",
  GET_ACTIVE_PATIENT_APPOINTMENTS_SUCCESS:
    "GET_ACTIVE_PATIENT_APPOINTMENTS_SUCCESS",
  GET_ACTIVE_PATIENT_APPOINTMENTS_FAILURE:
    "GET_ACTIVE_PATIENT_APPOINTMENTS_FAILURE",
  GET_ACTIVE_PATIENT_APPOINTMENTS_RESET:
    "GET_ACTIVE_PATIENT_APPOINTMENTS_RESET",
};
