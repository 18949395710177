import { basicInfoTypes } from "../types";

const initialState = {
  loading: false,
  cmUserList: [],
  LookupDisposition: [],
};

export const basicInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case basicInfoTypes.BASIC_INFO_REQUEST:
      return { ...state, loading: true };
    case basicInfoTypes.BASIC_INFO_REQUEST_SUCCESS:
      return {
        ...initialState,
        details: action.getAll,
        cmUserList: action.cmUserList,
        LookupDisposition: action.LookupDisposition,
      };
    case basicInfoTypes.BASIC_INFO_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};
