import { drgTypes } from "../types";

export const drgActions = {
  getDRGRequest,
  getDRGRequestSuccess,
  getDRGRequestFailure,
};

function getDRGRequest(request, token) {
  return { type: drgTypes.GET_DRG_REQUEST, request, token };
}
function getDRGRequestSuccess(getAll) {
  return { type: drgTypes.GET_DRG_REQUEST_SUCCESS, getAll };
}
function getDRGRequestFailure(error) {
  return {
    type: drgTypes.GET_DRG_REQUEST_FAILURE,
    error,
  };
}
