import React from "react";
import styles from "./TextAvatar.module.scss";
const TextAvatar = ({ name }) => {
  // to get first chars of first and last name
  const getEachWordFirstChar = (fullName) => {
    const mataches = fullName.match(/\b(\w)/g);
    const acronym = mataches.join("");
    return acronym.slice(0, 2);
  };

  return (
    <div className={styles.col1}>
      {/* {item.avatar === "" ? ( */}
      <div className={styles.avatar_circle}>
        <h3 className={styles.avatar_char}>
          {getEachWordFirstChar(name)}
        </h3>
      </div>
      {/* ) : (
        <img
          className={styles.avatar}
          alt={item.patientName}
          src={item.avatar}
        />
      )} */}
    </div>
  );
};

export { TextAvatar };
