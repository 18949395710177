import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { getDispositionActions } from "../actions";
import { getDispositionTypes } from "../types";

export function* watchDMDispositionDetails() {
  yield takeLatest(getDispositionTypes.GET_DISPOSITION_REQUEST, getDisposition);
}

function* getDisposition(action) {
  try {
    let data = yield call(Api, {
      method: "POST",
      endpoint: endpoint.getDMDispositionDetails,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedData = JSON.parse(data);

    yield put(getDispositionActions.dispositionRequestSuccess(parsedData[0]));
  } catch (error) {
    yield put(getDispositionActions.dispositionRequestFailure(error));
  }
}
