import { contactRoleTypes } from "../types/contactRole.type";
const initialState = {
  loading: false,
};
export const contactRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case contactRoleTypes.CONTACT_ROLE_REQUEST:
      return { loading: true };
    case contactRoleTypes.CONTACT_ROLE_REQUEST_SUCCESS:
      return { getRoles: action.getRoles };
    case contactRoleTypes.CONTACT_ROLE_REQUEST_FAILURE:
      return { error: action.error };

    default:
      return state;
  }
};
