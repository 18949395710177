import { mailDetailsTypes } from "../types/mailDetails.type";

const initialState = {
  loading: false,
  getAll: [],
};

export const patientChatDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case mailDetailsTypes.PATIENTCHAT_DETAILS_REQUEST:
      return { ...initialState, loading: true };
    case mailDetailsTypes.PATIENTCHAT_DETAILS_REQUEST_SUCCESS:
      return { ...initialState, getAll: action.getAll };
    case mailDetailsTypes.PATIENTCHAT_DETAILS_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};
