export const addActivePatientMedicationTransitionType = {
  ADD_ACTIVE_PATIENT_MEDICATION_TC_REQUEST:
    "ADD_ACTIVE_PATIENT_MEDICATION_TC_REQUEST",
  ADD_ACTIVE_PATIENT_MEDICATION_TC_SUCCESS:
    "ADD_ACTIVE_PATIENT_MEDICATION_TC_SUCCESS",
  ADD_ACTIVE_PATIENT_MEDICATION_TC_FAILURE:
    "ADD_ACTIVE_PATIENT_MEDICATION_TC_FAILURE",
  ADD_ACTIVE_PATIENT_MEDICATION_TC_RESET:
    "ADD_ACTIVE_PATIENT_MEDICATION_TC_RESET",
};
