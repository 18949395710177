import {
  getClinicalInfoCmTypes,
  updateClinicalInfoCmerTypes,
  updateClinicalInfoCmTypes,
} from "../types";

const initialState = {
  loading: false,
  Consultant: [],
  DcDiagnosis: [],
  socialInfo: [],
  error: "",
};

export const getClinicalInfoCmReducer = (state = initialState, action) => {
  switch (action.type) {
    case getClinicalInfoCmTypes.GET_CLINICAL_INFO_CM_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case getClinicalInfoCmTypes.GET_CLINICAL_INFO_CM_SUCCESS:
      return {
        ...initialState,
        Consultant: action.Consultant,
        DcDiagnosis: action.DcDiagnosis,
        socialInfo: action.socialInfo,
      };
    case getClinicalInfoCmTypes.GET_CLINICAL_INFO_CM_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    default:
      return state;
  }
};
const initialStateUpdate = {
  loading: false,
  message: "",
  error: "",
};

export const updateClinicalInfoCmReducer = (
  state = initialStateUpdate,
  action
) => {
  switch (action.type) {
    case updateClinicalInfoCmTypes.UPDATE_CLINICAL_INFO_CM_REQUEST:
      return {
        ...initialStateUpdate,
        loading: true,
      };
    case updateClinicalInfoCmTypes.UPDATE_CLINICAL_INFO_CM_SUCCESS:
      return {
        ...initialStateUpdate,
        message: action.message,
      };
    case updateClinicalInfoCmTypes.UPDATE_CLINICAL_INFO_CM_FAILURE:
      return {
        ...initialStateUpdate,
        error: action.error,
      };
    case updateClinicalInfoCmTypes.UPDATE_CLINICAL_INFO_CM_RESET:
      return {
        ...initialStateUpdate,
      };

    default:
      return state;
  }
};
