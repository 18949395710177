import { getHieFileDataType } from "../type/getHieFileData.type";

const initialState = {
  loading: false,
  data: [],
  totalrecords: 0,
};

export const getHieFileDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case getHieFileDataType.GET_HIE_FILE_DATA_REQUEST:
      console.log(action);
      return {
        ...initialState,
        loading: true,
      };
    case getHieFileDataType.GET_HIE_FILE_DATA_SUCCESS:
      return {
        ...initialState,
        data: action.data,
        totalrecords: action.totalrecords,
      };
    case getHieFileDataType.GET_HIE_FILE_DATA_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case getHieFileDataType.GET_HIE_FILE_DATA_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
