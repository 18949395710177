import { advanceCarePlanLookupCmerTypes } from "../types";

export const advanceCarePlanLookupCmerActions = {
  advanceCarePlanLookupRequest,
  advanceCarePlanLookupSuccess,
  advanceCarePlanLookupFailure,
};
function advanceCarePlanLookupRequest(request, token) {
  return {
    type: advanceCarePlanLookupCmerTypes.ADVANCECAREPLAN_LOOKUP_CMER_REQUEST,
    request,
    token,
  };
}
function advanceCarePlanLookupSuccess(getAll) {
  return {
    type: advanceCarePlanLookupCmerTypes.ADVANCECAREPLAN_LOOKUP_CMER_SUCCESS,
    getAll,
  };
}
function advanceCarePlanLookupFailure(error) {
  return {
    type: advanceCarePlanLookupCmerTypes.ADVANCECAREPLAN_LOOKUP_CMER_FAILURE,
    error,
  };
}
