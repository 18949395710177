import { clinicalInfoUpdateTypes } from "../types";

export const clinicalInfoUpdateActions = {
  clinicalInfoUpdateRequest,
  clinicalInfoUpdateRequestReset,
  clinicalInfoUpdateRequestSuccess,
  clinicalInfoUpdateRequestFailure,
};
function clinicalInfoUpdateRequest(request, token) {
  return {
    type: clinicalInfoUpdateTypes.CLINICAL_INFO_UPDATE_REQUEST,
    request,
    token,
  };
}
function clinicalInfoUpdateRequestReset() {
  return {
    type: clinicalInfoUpdateTypes.CLINICAL_INFO_UPDATE_REQUEST_RESET,
  };
}
function clinicalInfoUpdateRequestSuccess(message) {
  return {
    type: clinicalInfoUpdateTypes.CLINICAL_INFO_UPDATE_REQUEST_SUCCESS,
    message,
  };
}
function clinicalInfoUpdateRequestFailure(error) {
  return {
    type: clinicalInfoUpdateTypes.CLINICAL_INFO_UPDATE_REQUEST_FAILURE,
    error,
  };
}
