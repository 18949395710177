export const carePlanTypes = {
  GETALL_CARE_PLAN_REQUEST: "GETALL_CARE_PLAN_REQUEST",
  GETALL_CARE_PLAN_REQUEST_SUCCESS: "GETALL_CARE_PLAN_REQUEST_SUCCESS",
  GETALL_CARE_PLAN_REQUEST_FAILURE: "GETALL_CARE_PLAN_REQUEST_FAILURE",

  ADD_CARE_PLAN_REQUEST: "ADD_CARE_PLAN_REQUEST",
  ADD_CARE_PLAN_REQUEST_SUCCESS: "ADD_CARE_PLAN_REQUEST_SUCCESS",
  ADD_CARE_PLAN_REQUEST_FAILURE: "ADD_CARE_PLAN_REQUEST_FAILURE",

  EDIT_CARE_PLAN_REQUEST: "EDIT_CARE_PLAN_REQUEST",
  EDIT_CARE_PLAN_REQUEST_SUCCESS: "EDIT_CARE_PLAN_REQUEST_SUCCESS",
  EDIT_CARE_PLAN_REQUEST_FAILURE: "EDIT_CARE_PLAN_REQUEST_FAILURE",

  RESET_CARE_PLAN: "RESET_CARE_PLAN",

  // Lookup
  LOOKUP_CM_DIAGNOSIS: "LOOKUP_CM_DIAGNOSIS",
  LOOKUP_CM_DIAGNOSIS_SUCCESS: "LOOKUP_CM_DIAGNOSIS_SUCCESS",
  LOOKUP_CM_DIAGNOSIS_FAILURE: "LOOKUP_CM_DIAGNOSIS_FAILURE",

  LOOKUP_CM_GOAL: "LOOKUP_CM_GOAL",
  LOOKUP_CM_GOAL_SUCCESS: "LOOKUP_CM_GOAL_SUCCESS",
  LOOKUP_CM_GOAL_FAILURE: "LOOKUP_CM_GOAL_FAILURE",

  LOOKUP_CM_PATIENT_RESPONSIBILITY: "LOOKUP_CM_PATIENT_RESPONSIBILITY",
  LOOKUP_CM_PATIENT_RESPONSIBILITY_SUCCESS:
    "LOOKUP_CM_PATIENT_RESPONSIBILITY_SUCCESS",
  LOOKUP_CM_PATIENT_RESPONSIBILITY_FAILURE:
    "LOOKUP_CM_PATIENT_RESPONSIBILITY_FAILURE",

  LOOKUP_CM_INTERVENTION: "LOOKUP_CM_INTERVENTION",
  LOOKUP_CM_INTERVENTION_SUCCESS: "LOOKUP_CM_INTERVENTION_SUCCESS",
  LOOKUP_CM_INTERVENTION_FAILURE: "LOOKUP_CM_INTERVENTION_FAILURE",

  LOOKUP_CM_FREQUENCY: "LOOKUP_CM_FREQUENCY",
  LOOKUP_CM_FREQUENCY_SUCCESS: "LOOKUP_CM_FREQUENCY_SUCCESS",
  LOOKUP_CM_FREQUENCY_FAILURE: "LOOKUP_CM_FREQUENCY_FAILURE",
};
