import { Loading } from "../../../../../components";
import { pcpOfficeTypes } from "../types/pcpoffice.type";

const initialState = {
  loading: false,
  getAll: [],
  error: "",
  message: "",
};

export const pcpOfficeDMReducer = (state = initialState, action) => {
  switch (action.type) {
    case pcpOfficeTypes.PCP_OFFICE_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case pcpOfficeTypes.PCP_OFFICE_REQUEST_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
      };
    case pcpOfficeTypes.PCP_OFFICE_REQUEST_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    //edit pcpoffice
    case pcpOfficeTypes.PCP_OFFICE_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case pcpOfficeTypes.PCP_OFFICE_EDIT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
      };
    case pcpOfficeTypes.PCP_OFFICE_EDIT_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    //create pcpoffice

    case pcpOfficeTypes.PCP_OFFICE_SAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case pcpOfficeTypes.PCP_OFFICE_SAVE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
      };
    case pcpOfficeTypes.PCP_OFFICE_SAVE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case pcpOfficeTypes.PCP_OFFICE_REQUEST_RESET:
      return { ...state, loading: false, message: "", error: "" };

    //update status
    case pcpOfficeTypes.PCP_OFFICE_UPDATE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case pcpOfficeTypes.PCP_OFFICE_UPDATE_STATUS_REQUEST_SUCCESS:
      let index1 = state.getAll.findIndex(
        (item) => item.pcpofficeId === action.id
      );
      const newArr1 = [...state.getAll];
      const pcpoffice = {
        ...newArr1[index1],
        active: !newArr1[index1].active,
      };
      newArr1[index1] = pcpoffice;
      return {
        ...state,
        loading: false,
        message: action.message,
        getAll: newArr1,
      };
    case pcpOfficeTypes.PCP_OFFICE_UPDATE_STATUS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
