import { cmerTasksDetailsType } from "../types/tasksDetails.type";

export const cmerTasksDetailsActions = {
  tasksDetailsRequest,
  tasksDetailsRequestSuccess,
  tasksDetailsRequestFailure,
};

function tasksDetailsRequest(request, token) {
  return {
    type: cmerTasksDetailsType.CALL_TASKS_DETAILS_REQUEST,
    request,
    token,
  };
}
function tasksDetailsRequestSuccess(getAll) {
  return {
    type: cmerTasksDetailsType.CALL_TASKS_DETAILS_REQUEST_SUCCESS,
    getAll,
  };
}
function tasksDetailsRequestFailure(error) {
  return {
    type: cmerTasksDetailsType.CALL_TASKS_DETAILS_REQUEST_FAILURE,
    error,
  };
}
