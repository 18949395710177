export const getClinicalInfoCmTypes = {
  GET_CLINICAL_INFO_CM_REQUEST: "GET_CLINICAL_INFO_CM_REQUEST",
  GET_CLINICAL_INFO_CM_SUCCESS: "GET_CLINICAL_INFO_CM_SUCCESS",
  GET_CLINICAL_INFO_CM_FAILURE: "GET_CLINICAL_INFO_CM_FAILURE",
};

export const updateClinicalInfoCmTypes = {
  UPDATE_CLINICAL_INFO_CM_REQUEST: "UPDATE_CLINICAL_INFO_CM_REQUEST",
  UPDATE_CLINICAL_INFO_CM_RESET: "UPDATE_CLINICAL_INFO_CM_RESET",
  UPDATE_CLINICAL_INFO_CM_SUCCESS: "UPDATE_CLINICAL_INFO_CM_SUCCESS",
  UPDATE_CLINICAL_INFO_CM_FAILURE: "UPDATE_CLINICAL_INFO_CM_FAILURE",
};
