import { getAllFrequencyLookUpTransitionType } from "../../types";

export const getAllFrequencyLookUpTCActions = {
  getAllFrequencyLookUpRequest,
  getAllFrequencyLookUpSuccess,
  getAllFrequencyLookUpFailure,
};
function getAllFrequencyLookUpRequest(token) {
  return {
    type: getAllFrequencyLookUpTransitionType.GETALL_FREQUENCY_LOOKUP_TC_REQUEST,

    token,
  };
}
function getAllFrequencyLookUpSuccess(getAll) {
  return {
    type: getAllFrequencyLookUpTransitionType.GETALL_FREQUENCY_LOOKUP_TC_SUCCESS,
    getAll,
  };
}
function getAllFrequencyLookUpFailure(error) {
  return {
    type: getAllFrequencyLookUpTransitionType.GETALL_FREQUENCY_LOOKUP_TC_FAILURE,
    error,
  };
}
