export const immunizationTypes = {
  GETALL_IMMUNIZATION_REQUEST: "GETALL_IMMUNIZATION_REQUEST",
  GETALL_IMMUNIZATION_REQUEST_SUCCESS: "GETALL_IMMUNIZATION_REQUEST_SUCCESS",
  GETALL_IMMUNIZATION_REQUEST_FAILURE: "GETALL_IMMUNIZATION_REQUEST_FAILURE",

  OPERARTION_IMMUNIZATION_REQUEST: "OPERARTION_IMMUNIZATION_REQUEST",
  OPERARTION_IMMUNIZATION_REQUEST_SUCCESS:
    "OPERARTION_IMMUNIZATION_REQUEST_SUCCESS",
  OPERARTION_IMMUNIZATION_REQUEST_FAILURE:
    "OPERARTION_IMMUNIZATION_REQUEST_FAILURE",
  OPERARTION_IMMUNIZATION_REQUEST_RESET:
    "OPERARTION_IMMUNIZATION_REQUEST_RESET",

  DELETE_IMMUNIZATION_REQUEST: "DELETE_IMMUNIZATION_REQUEST",
  DELETE_IMMUNIZATION_REQUEST_SUCCESS: "DELETE_IMMUNIZATION_REQUEST_SUCCESS",
  DELETE_IMMUNIZATION_REQUEST_FAILURE: "DELETE_IMMUNIZATION_REQUEST_FAILURE",
  DELETE_IMMUNIZATION_REQUEST_RESET: "DELETE_IMMUNIZATION_REQUEST_RESET",
};
