import { updateBillingIcdModifiersType } from "../types";

export const updateIcdModifierActions = {
  updateIcdModifierRequest,
  updateIcdModifierSuccess,
  updateIcdModifierFailure,
  updateIcdModifierReset,
};
function updateIcdModifierRequest(request, token) {
  return {
    type: updateBillingIcdModifiersType.UPDATE_ICD_MODIFIERS_REQUEST,
    request,
    token,
  };
}
function updateIcdModifierSuccess(message) {
  return {
    type: updateBillingIcdModifiersType.UPDATE_ICD_MODIFIERS_SUCCESS,
    message,
  };
}
function updateIcdModifierFailure(error) {
  return {
    type: updateBillingIcdModifiersType.UPDATE_ICD_MODIFIERS_FAILURE,
    error,
  };
}
function updateIcdModifierReset() {
  return {
    type: updateBillingIcdModifiersType.UPDATE_ICD_MODIFIERS_RESET,
  };
}
