import { put, call, debounce } from "@redux-saga/core/effects";
import {
  callActionTakenLookupCmerActions,
  callDispositionLookupCmerActions,
  callHistoryOperationsCmerActions,
} from "../actions";
import {
  callActionTakenLookupCmerTypes,
  callDispositionLookupCmerTypes,
  callHistoryOperationsCmerType,
} from "../types";
import { Api2 } from "../../../../api/Api2";
import { endpoint } from "../../../../api/Url";
import { alertActions } from "../../../../components/AlertNew/store/AlertNew.action";
export function* watchCallHistoryCmer() {
  yield debounce(
    500,
    callHistoryOperationsCmerType.CALL_HISTORY_DETAILS_CMER_REQUEST,
    getcallHistoryDetailsCmer
  );
  yield debounce(
    500,
    callHistoryOperationsCmerType.CALL_HISTORY_OPERATIONS_CMER_REQUEST,
    callHistoryActionsCmer
  );

  yield debounce(
    500,
    callActionTakenLookupCmerTypes.CALL_ACTION_TAKEN_LOOKUP_CMER_REQUEST,
    getActionsTakenLookupCmer
  );
  yield debounce(
    500,
    callDispositionLookupCmerTypes.CALL_DISPOSITION_LOOKUP_CMER_REQUEST,
    getDispositionLookupCmer
  );
}
// for getting all details
function* getcallHistoryDetailsCmer(action) {
  try {
    const response = yield call(Api2, {
      endpoint: `${endpoint.callHistoryOperationsCmer}?encounterId=${action?.request?.encounterId}&patientId=${action?.request?.patientID}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });
    yield put(
      callHistoryOperationsCmerActions.callHistoryGetAllDetailsCmerSuccess(
        response
      )
    );
  } catch (error) {
    yield put(
      callHistoryOperationsCmerActions.callHistoryOperationsCmerFailure(error)
    );
  }
}
// for getting all CRUD oprations
function* callHistoryActionsCmer(action) {
  let { callHistoryOperationsCmer } = endpoint;
  let {
    historyId,
    encounterId,
    patientID,
    actionIDs,
    dispositionID,
    callComments,
    callComplete,
    DNC,
    closeCase,
    flag,
  } = action?.request;

  try {
    console.log(action, "action");
    let method =
      flag === "Delete" ? "DELETE" : flag === "Edit" ? "PUT" : "POST";
    let endpointUrl =
      flag === "Delete" || flag === "Edit"
        ? `${callHistoryOperationsCmer}?historyId=${historyId}`
        : `${callHistoryOperationsCmer}?encounterId=${encounterId}&patientId=${patientID}`;

    const response = yield call(Api2, {
      endpoint: endpointUrl,
      method: method,
      jwt: "Bearer " + action.token,
      body: {
        actionIDs,
        dispositionID,
        callComments,
        callComplete,
        DNC,
        closeCase,
      },
    });
    yield put(
      callHistoryOperationsCmerActions.callHistoryOperationsCmerSuccess(
        response?.Result
      )
    );
    if (flag === "Delete") {
      yield put(alertActions?.alertSuccessRequest("Call history Deleted"));
    } else if (flag === "Edit") {
      yield put(alertActions?.alertSuccessRequest("Call history Updated"));
    } else {
      yield put(alertActions?.alertSuccessRequest("Call history Created"));
    }
  } catch (error) {
    yield put(
      callHistoryOperationsCmerActions.callHistoryOperationsCmerFailure(error)
    );
    yield put(alertActions?.alertErrorRequest(error));
  }
}
function* getActionsTakenLookupCmer(action) {
  try {
    const response = yield call(Api2, {
      endpoint: `${endpoint.actionTakenLookupCmer}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });
    console.log(response, "responselook");
    yield put(
      callActionTakenLookupCmerActions.callActionTakenLookupCmerSuccess(
        response?.data
      )
    );
  } catch (error) {
    yield put(
      callActionTakenLookupCmerActions.callActionTakenLookupCmerFailure(error)
    );
  }
}
function* getDispositionLookupCmer(action) {
  try {
    const response = yield call(Api2, {
      endpoint: `${endpoint.dispositionLookupCmer}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });

    yield put(
      callDispositionLookupCmerActions.callDispositionLookupCmerSuccess(
        response?.data
      )
    );
  } catch (error) {
    yield put(
      callDispositionLookupCmerActions.callDispositionLookupCmerFailure(error)
    );
  }
}
