import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api2 } from "../../../../../api/Api2";
import { endpoint } from "../../../../../api/Url";
import { nationTypes } from "../types/nation.type";
import { nationActions } from "../actions/nation.action";

export function* watchDMNation() {
  yield takeLatest(nationTypes.NATION_REQUEST, getAllNation);
  yield takeLatest(nationTypes.NATION_SAVE_REQUEST, createNation);
  yield takeLatest(nationTypes.NATION_EDIT_REQUEST, editNation);
  yield takeLatest(
    nationTypes.NATION_UPDATE_STATUS_REQUEST,
    updateNationStatus
  );
}

// Get all nation
function* getAllNation(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.nation}?nation=""`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });

    if (response.data !== undefined && response.data.length > 0) {
      yield put(nationActions.nationRequestSuccess(response.data));
    } else {
      yield put(nationActions.nationRequestFailure("No data found"));
    }
  } catch (error) {
    yield put(nationActions.nationRequestFailure(error));
  }
}
// create nation
function* createNation(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.nation}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    const value = {
      NationId: new Date().getTime(),
      nation: action.request.nation,
    };
    yield put(nationActions.nationSaveRequestSuccess(response.message, value));
  } catch (error) {
    yield put(nationActions.nationSaveRequestFailure(error));
  }
}
// update nation
function* editNation(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.nation}/${action.query}`,
      method: "PATCH",
      jwt: "Bearer " + action.token,
      body: action.payload,
    });
    const value = {
      NationId: action.query,
      nation: action.payload.nation,
    };
    yield put(nationActions.nationEditRequestSuccess(response.message, value));
  } catch (error) {
    yield put(nationActions.nationEditRequestFailure(error));
  }
}
// update status nation
function* updateNationStatus(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.nation}/${action.query}`,
      method: "PUT",
      jwt: "Bearer " + action.token,
      body: action.payload,
    });

    yield put(
      nationActions.nationUpdateStatusRequestSuccess(
        action.query,
        action.payload,
        response.message
      )
    );
  } catch (error) {
    yield put(nationActions.nationUpdateStatusRequestFailure(error));
  }
}
