import { facilityLookupCMType } from "../types";

export const facilityLookupCMActions = {
  facilityLookupCMRequest,
  facilityLookupCMSuccess,
  facilityLookupCMFailure,
};

function facilityLookupCMRequest(request, token) {
  return {
    type: facilityLookupCMType.FACILITY_LOOKUP_CM_REQUEST,
    request,
    token,
  };
}
function facilityLookupCMSuccess(getAll) {
  return {
    type: facilityLookupCMType.FACILITY_LOOKUP_CM_SUCCESS,
    getAll,
  };
}
function facilityLookupCMFailure(error) {
  return { type: facilityLookupCMType.FACILITY_LOOKUP_CM_FAILURE, error };
}
