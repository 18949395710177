import { lookupTypes } from "../types/lookup.type";

const initialState = {
  loading: false,
  getAll: [],
  error: "",
};

export const lookupRegionReducer = (state = initialState, action) => {
  switch (action.type) {
    case lookupTypes.LOOKUP_REGION_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case lookupTypes.LOOKUP_REGION_REQUEST_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
      };
    case lookupTypes.LOOKUP_REGION_REQUEST_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    default:
      return state;
  }
};
