import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { callDispositionActions } from "../actions";
import { callDispositionTypes } from "../types";
export function* watchCallDisposition() {
  yield takeLatest(
    callDispositionTypes.CALL_DISPOSITION_REQUEST,
    getCallDisposition
  );
}

function* getCallDisposition(action) {
  try {
    const dispositions = yield call(Api, {
      endpoint: endpoint.getDispositions,
      method: "POST",
      jwt: "Bearer " + action.token,
    });

    let parsedDispositions = JSON.parse(dispositions);

    yield put(
      callDispositionActions.callDispositionRequestSuccess(
        parsedDispositions.DispositionModel
      )
    );
  } catch (error) {
    yield put(callDispositionActions.callDispositionRequestFailure(error));
  }
}
