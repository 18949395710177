import { carePlanTypes } from "../types/careplan.type";

//  getall
const initialGetState = {
  loading: false,
  getAll: [],
  error: "",
};

export const getCarePlanReducer = (state = initialGetState, action) => {
  switch (action.type) {
    case carePlanTypes.GETALL_CARE_PLAN_REQUEST:
      return { ...initialGetState, loading: true };

    case carePlanTypes.GETALL_CARE_PLAN_REQUEST_SUCCESS:
      return { ...initialGetState, getAll: action.getAll };

    case carePlanTypes.GETALL_CARE_PLAN_REQUEST_FAILURE:
      return {
        ...initialGetState,
        error: action.error,
      };
    default:
      return state;
  }
};

const initialState = {
  loading: false,
  message: "",
  error: "",
};

export const carePlanReducer = (state = initialState, action) => {
  switch (action.type) {
    //add
    case carePlanTypes.ADD_CARE_PLAN_REQUEST:
      return { ...initialState, loading: true };

    case carePlanTypes.ADD_CARE_PLAN_REQUEST_SUCCESS:
      return { ...initialState, message: action.message };

    case carePlanTypes.ADD_CARE_PLAN_REQUEST_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    // edit
    // case carePlanTypes.EDIT_CARE_PLAN_REQUEST:
    //   return { ...state, loading: true };

    // case carePlanTypes.EDIT_CARE_PLAN_REQUEST_SUCCESS:
    //   // Find the care plan in the getAll array and update it with the new data
    //   const updatedGetAll = state.getAll.map((carePlan) => {
    //     if (carePlan.id === action.id) {
    //       // Return a new care plan object with updated properties
    //       return { ...carePlan, ...action.updatedCarePlanData };
    //     }
    //     return carePlan;
    //   });

    //   return { ...state, getAll: updatedGetAll, message: action.message };

    // case carePlanTypes.EDIT_CARE_PLAN_REQUEST_FAILURE:
    //   return { ...state, error: action.error };

    //reset
    case carePlanTypes.RESET_CARE_PLAN:
      return initialState;

    default:
      return state;
  }
};

//Lookup cm diagnosis
const initialCMDiagnosisState = {
  loading: false,
  getAll: [],
  error: "",
};

export const lookupCMDiagnosisReducer = (
  state = initialCMDiagnosisState,
  action
) => {
  switch (action.type) {
    case carePlanTypes.LOOKUP_CM_DIAGNOSIS:
      return { ...initialCMDiagnosisState, loading: true };

    case carePlanTypes.LOOKUP_CM_DIAGNOSIS_SUCCESS:
      return { ...initialCMDiagnosisState, getAll: action.getAll };

    case carePlanTypes.LOOKUP_CM_DIAGNOSIS_FAILURE:
      return {
        ...initialCMDiagnosisState,
        error: action.error,
      };
    default:
      return state;
  }
};

//Lookup cm goal
const initialCMGoalState = {
  loading: false,
  getAll: [],
  error: "",
};

export const lookupCMGoalReducer = (state = initialCMGoalState, action) => {
  switch (action.type) {
    case carePlanTypes.LOOKUP_CM_GOAL:
      return { ...initialCMGoalState, loading: true };

    case carePlanTypes.LOOKUP_CM_GOAL_SUCCESS:
      return { ...initialCMGoalState, getAll: action.getAll };

    case carePlanTypes.LOOKUP_CM_GOAL_FAILURE:
      return {
        ...initialCMGoalState,
        error: action.error,
      };
    default:
      return state;
  }
};

//lookup cm patient responsibility
const initialCMPatientState = {
  loading: false,
  getAll: [],
  error: "",
};

export const lookupCMPatientResonsibilityReducer = (
  state = initialCMPatientState,
  action
) => {
  switch (action.type) {
    case carePlanTypes.LOOKUP_CM_PATIENT_RESPONSIBILITY:
      return { ...initialCMPatientState, loading: true };

    case carePlanTypes.LOOKUP_CM_PATIENT_RESPONSIBILITY_SUCCESS:
      return { ...initialCMPatientState, getAll: action.getAll };

    case carePlanTypes.LOOKUP_CM_PATIENT_RESPONSIBILITY_FAILURE:
      return {
        ...initialCMPatientState,
        error: action.error,
      };
    default:
      return state;
  }
};

//lookup cm intervention
const initialCMInterventionState = {
  loading: false,
  getAll: [],
  error: "",
};

export const lookupCMInterventionReducer = (
  state = initialCMInterventionState,
  action
) => {
  switch (action.type) {
    case carePlanTypes.LOOKUP_CM_INTERVENTION:
      return { ...initialCMInterventionState, loading: true };

    case carePlanTypes.LOOKUP_CM_INTERVENTION_SUCCESS:
      return { ...initialCMInterventionState, getAll: action.getAll };

    case carePlanTypes.LOOKUP_CM_INTERVENTION_FAILURE:
      return {
        ...initialCMInterventionState,
        error: action.error,
      };
    default:
      return state;
  }
};
//lookup cm Frequency
const initialCMFrequencyState = {
  loading: false,
  getAll: [],
  error: "",
};

export const lookupCMFrequencyReducer = (
  state = initialCMFrequencyState,
  action
) => {
  switch (action.type) {
    case carePlanTypes.LOOKUP_CM_FREQUENCY:
      return { ...initialCMFrequencyState, loading: true };

    case carePlanTypes.LOOKUP_CM_FREQUENCY_SUCCESS:
      return { ...initialCMFrequencyState, getAll: action.getAll };

    case carePlanTypes.LOOKUP_CM_FREQUENCY_FAILURE:
      return {
        ...initialCMFrequencyState,
        error: action.error,
      };
    default:
      return state;
  }
};
