import { getAssessmentTypes } from "../types";

const initialState = {
  loading: false,
  getAssessment: [],
};

export const getAssessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case getAssessmentTypes.GET_ASSESSMENT_REQUEST:
      return { ...initialState, loading: true };
    case getAssessmentTypes.GET_ASSESSMENT_REQUEST_SUCCESS:
      return {
        ...initialState,
        getAssessment: action.getAssessment,
      };
    case getAssessmentTypes.GET_ASSESSMENT_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};
