import { put, call, takeLatest } from "@redux-saga/core/effects";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { hospitalizationActions } from "../actions";
import { hospitalizationTypes } from "../types";
export function* watchHospitalization() {
  yield takeLatest(
    hospitalizationTypes.GETALL_HOSPITALIZATION_REQUEST,
    getAllHospitalization
  );
  yield takeLatest(
    hospitalizationTypes.OPERARTION_HOSPITALIZATION_REQUEST,
    operationHospitalization
  );
  yield takeLatest(
    hospitalizationTypes.DELETE_HOSPITALIZATION_REQUEST,
    deleteHospitalization
  );
}
function* getAllHospitalization(action) {
  try {
    let data = yield call(Api, {
      endpoint: `${endpoint.getHospitalizationDetails}?patientId=${action.request.patientId}`,
      method: "POST",
      jwt: "Bearer " + action.token,
    });
    let parsedHospitalization = JSON.parse(data);

    yield put(
      hospitalizationActions.hospitalizationGetAllRequestSuccess(
        parsedHospitalization._lstHospitalizationDetails,
        parsedHospitalization._dropdownSourceListModel
      )
    );
  } catch (error) {
    yield put(
      hospitalizationActions.hospitalizationGetAllRequestFailure(error)
    );
  }
}
function* operationHospitalization(action) {
  try {
    let message = yield call(Api, {
      endpoint: `${endpoint.savePatientHospitalizationDetails}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedMessage = JSON.parse(message);

    yield put(
      hospitalizationActions.hospitalizationOperationRequestSuccess(
        parsedMessage[0]
      )
    );
  } catch (error) {
    yield put(
      hospitalizationActions.hospitalizationOperationRequestFailure(error)
    );
  }
}

function* deleteHospitalization(action) {
  try {
    let message = yield call(Api, {
      endpoint: `${endpoint.deletePatientHospitalizationDetails}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedMessage = JSON.parse(message);

    yield put(
      hospitalizationActions.hospitalizationDeleteRequestSuccess(
        parsedMessage[0]
      )
    );
  } catch (error) {
    yield put(
      hospitalizationActions.hospitalizationDeleteRequestFailure(error)
    );
  }
}
