import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const BillingSkeleton = ({ loading, message }) => {
  return (
    <>
      {loading && (
        <Stack
          spacing={2}
          sx={{
            position: "absolute",
            zIndex: 100000,
            width: "100vw",
            background: "#f5f5f5",
          }}
        >
          <Skeleton
            variant="rectangular"
            sx={{
              width: "100%",
              height: 100,
              borderRadius: 2,
              background: "#e6e6e6",
            }}
          />

          <Skeleton
            variant="rectangular"
            sx={{
              width: "100%",
              height: 50,
              borderRadius: 2,
              background: "#e6e6e6",
            }}
          />

          <Skeleton
            variant="rectangular"
            sx={{
              width: "100%",
              height: 50,
              borderRadius: 2,
              background: "#e6e6e6",
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              width: "100%",
              height: 100,
              borderRadius: 2,
              background: "#e6e6e6",
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              width: "100%",
              height: 200,
              borderRadius: 2,
              background: "#e6e6e6",
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              width: "100%",
              height: 200,
              borderRadius: 2,
              background: "#e6e6e6",
            }}
          />
        </Stack>
      )}
    </>
  );
};

export default BillingSkeleton;
