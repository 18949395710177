import { createSlice } from "@reduxjs/toolkit";

// Initial state for updating initial assessment
const initialState = {
  loading: false,
  getAll: [],
  data: null,
  error: "",
};

// Slice for updating initial assessment
const hospitalistPatientListSlice = createSlice({
  name: "hospitalistPatientList",
  initialState: initialState,
  reducers: {
    hospitalistPatientListRequest: (state) => {
      state.loading = true;
    },
    hospitalistPatientListSuccess: (state, action) => {
      console.log(action);
      state.loading = false;
      state.data = action?.payload?.data;
      state.getAll = action?.payload?.referral;
      state.error = "";
    },
    hospitalistPatientListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    hospitalistPatientListReset: () => initialState,
  },
});

export const {
  hospitalistPatientListRequest,
  hospitalistPatientListSuccess,
  hospitalistPatientListFailure,
  hospitalistPatientListReset,
} = hospitalistPatientListSlice.actions;

export const hospitalistPatientListReducer =
  hospitalistPatientListSlice.reducer;
