import { biometricMeasureTypes } from "../types";

export const biometricMeasurementActions = {
  biometricGetRequest,
  biometricGetRequestSuccess,
  biometricGetRequestFailure,

  biometricOpRequest,
  biometricOpRequestSuccess,
  biometricOpRequestFailure,
  biometricOpRequestReset,
};
function biometricGetRequest(request, token) {
  return {
    type: biometricMeasureTypes.BIOMETRIC_GET_REQUEST,
    request,
    token,
  };
}
function biometricGetRequestSuccess(get, generalList) {
  return {
    type: biometricMeasureTypes.BIOMETRIC_GET_REQUEST_SUCCESS,
    get,
    generalList,
  };
}
function biometricGetRequestFailure(error) {
  return {
    type: biometricMeasureTypes.BIOMETRIC_GET_REQUEST_FAILURE,
    error,
  };
}

function biometricOpRequest(request, token) {
  return {
    type: biometricMeasureTypes.BIOMETRIC_OPERATION_REQUEST,
    request,
    token,
  };
}
function biometricOpRequestSuccess(message) {
  return {
    type: biometricMeasureTypes.BIOMETRIC_OPERATION_REQUEST_SUCCESS,
    message,
  };
}
function biometricOpRequestFailure(error) {
  return {
    type: biometricMeasureTypes.BIOMETRIC_OPERATION_REQUEST_FAILURE,
    error,
  };
}
function biometricOpRequestReset() {
  return {
    type: biometricMeasureTypes.BIOMETRIC_OPERATION_REQUEST_RESET,
  };
}
