export const lookupGroupsSubgroupsType = {
  LOOKUP_GROUP_SUBGROUP_REQUEST: "LOOKUP_GROUP_SUBGROUP_REQUEST",
  LOOKUP_GROUP_SUBGROUP_SUCCESS: "LOOKUP_GROUP_SUBGROUP_SUCCESS",
  LOOKUP_GROUP_SUBGROUP_FAILURE: "LOOKUP_GROUP_SUBGROUP_FAILURE",
  LOOKUP_GROUP_SUBGROUP_RESET: "LOOKUP_GROUP_SUBGROUP_RESET",
};

export const addTaskTypeCmer = {
  ADD_TASK_CMER_REQUEST: "ADD_TASK_CMER_REQUEST",
  ADD_TASK_CMER_REQUEST_SUCCESS: "ADD_TASK_CMER_REQUEST_SUCCESS",
  ADD_TASK_CMER_REQUEST_FAILURE: "ADD_TASK_CMER_REQUEST_FAILURE",
  ADD_TASK_CMER_REQUEST_RESET: "ADD_TASK_CMER_REQUEST_RESET",
};
