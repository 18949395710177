import { put, takeLatest, call, delay } from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
import { forgetPasswordActions } from "../actions";
import { forgetPasswordTypes } from "../types";

export function* watchForgetPassword() {
  yield takeLatest(forgetPasswordTypes.FORGET_PASSWORD_OTP_REQUEST, getOTP);
  yield takeLatest(
    forgetPasswordTypes.FORGET_PASSWORD_CONFIRM_OTP_REQUEST,
    getOTPConfirm
  );
  yield takeLatest(
    forgetPasswordTypes.FORGET_PASSWORD_RESET_REQUEST,
    getPasswordReset
  );
}
function* getOTP(action) {
  try {
    let message = yield call(Api, {
      method: "POST",
      endpoint: endpoint.createForgotPasswordOtp,
      body: action.request,
    });
    let parsedMessage = JSON.parse(message);

    yield put(
      forgetPasswordActions.forgetPasswordOtpRequestSuccess(
        parsedMessage.Result
      )
    );
  } catch (error) {
    yield put(forgetPasswordActions.forgetPasswordOtpRequestFailure(error));
  }
}
function* getOTPConfirm(action) {
  try {
    let message = yield call(Api, {
      method: "POST",
      endpoint: endpoint.verifyForgotPasswordOtp,
      body: action.request,
    });
    let parsedMessage = JSON.parse(message);
    console.log("====================================");
    console.log("getPasswordReset ", parsedMessage);
    console.log("====================================");
    yield put(
      forgetPasswordActions.forgetPasswordConfirmOtpRequestSuccess(
        parsedMessage.Result,
        parsedMessage.UserID
      )
    );
  } catch (error) {
    yield put(
      forgetPasswordActions.forgetPasswordConfirmOtpRequestFailure(error)
    );
  }
}
function* getPasswordReset(action) {
  try {
    console.log("====================================");
    console.log("req ", action.request);
    console.log("====================================");
    let message = yield call(Api, {
      method: "POST",
      endpoint: endpoint.changePasswordForPatientApp,
      body: action.request,
    });
    let parsedMessage = JSON.parse(message);
    console.log("====================================");
    console.log("getPasswordReset ", parsedMessage);
    console.log("====================================");
    yield put(
      forgetPasswordActions.forgetPasswordResetRequestSuccess(
        parsedMessage.Result
      )
    );
  } catch (error) {
    yield put(forgetPasswordActions.forgetPasswordResetRequestFailure(error));
  }
}
