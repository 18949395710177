import { ActionBtn, Loading } from "../../components";
import { lookupTypes } from "../types";

const initialState = {
  loading: false,
  getAll: [],
};

export const lookupMenusReducer = (state = initialState, action) => {
  switch (action.type) {
    case lookupTypes.LOOKUP_MENU_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case lookupTypes.LOOKUP_MENU_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
      };
    case lookupTypes.LOOKUP_MENU_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    default:
      return state;
  }
};
