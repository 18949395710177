import { callActionTakenTypes } from "../types";

export const callActionTakenActions = {
  callActionTakenRequest,
  callActionTakenRequestSuccess,
  callActionTakenRequestFailure,
};
function callActionTakenRequest(request, token) {
  return {
    type: callActionTakenTypes.CALL_ACTION_TAKEN_REQUEST,
    request,
    token,
  };
}
function callActionTakenRequestSuccess(actions) {
  return {
    type: callActionTakenTypes.CALL_ACTION_TAKEN_REQUEST_SUCCESS,
    actions,
  };
}
function callActionTakenRequestFailure(error) {
  return {
    type: callActionTakenTypes.CALL_ACTION_TAKEN_REQUEST_FAILURE,
    error,
  };
}
