// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useDispatch } from "react-redux";

const firebaseConfig = {
  apiKey: "AIzaSyDqZsjHhL3B26odYl1093hWTS9nB2_ffPo",
  authDomain: "pact-dashboard.firebaseapp.com",
  projectId: "pact-dashboard",
  storageBucket: "pact-dashboard.appspot.com",
  messagingSenderId: "1075407907488",
  appId: "1:1075407907488:web:6fc10fc94f74aecdf6f7c5",
  measurementId: "G-LEMXL85M2H",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getTokenFrom = (setPushToken) => {
  return getToken(messaging, {
    vapidKey:
      "BPjU2z_0_uaptcjoo0vezcuUWvVjKZ6Fv6iwMwyPV96-OUVwFD3FbzdcGW7XVGCyLWKoR1GlPTHzn7boeAJhoWc",
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log("current token for client: ", currentToken);
        setPushToken(currentToken);
        //  setTokenFound(true);
      } else {
        // console.log(
        //   "No registration token available. Request permission to generate one."
        // );
        //  setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .then((currentToken) => currentToken)
    .catch((err) => {
      // console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
