import { callDispositionLookupCmerTypes } from "../types";

export const callDispositionLookupCmerActions = {
  callDispositionLookupCmerRequest,
  callDispositionLookupCmerSuccess,
  callDispositionLookupCmerFailure,
};
function callDispositionLookupCmerRequest(request, token) {
  return {
    type: callDispositionLookupCmerTypes.CALL_DISPOSITION_LOOKUP_CMER_REQUEST,
    request,
    token,
  };
}
function callDispositionLookupCmerSuccess(getAll) {
  return {
    type: callDispositionLookupCmerTypes.CALL_DISPOSITION_LOOKUP_CMER_SUCCESS,
    getAll,
  };
}
function callDispositionLookupCmerFailure(error) {
  return {
    type: callDispositionLookupCmerTypes.CALL_DISPOSITION_LOOKUP_CMER_FAILURE,
    error,
  };
}
