import { errorType } from "../types/error.type";
const initalState = {
  hasError: false,
  message: "",
};
export const errorReducer = (state = initalState, action) => {
  switch (action.type) {
    case errorType.ERROR_REQUEST:
      return { ...state, hasError: true, message: action.message };
    case errorType.ERROR_REQUEST_RESET:
      return { ...state, hasError: false };

    default:
      return state;
  }
};
