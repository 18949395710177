import { facesheetTypes } from "../types/facesheet.type";

export const facesheetActions = {
  getFacesheetDataRequest,
  getFacesheetDataSuccess,
  getFacesheetDataFailure,
  getFacesheetDataReset,

  processFacesheetDataRequest,
  processFacesheetDataSuccess,
  processFacesheetDataFailure,
  processFacesheetDataReset,
};

function getFacesheetDataRequest(query, request, token) {
  return {
    type: facesheetTypes.GET_FACESHEET_DATA_REQUEST,
    query,
    request,
    token,
  };
}

function getFacesheetDataSuccess(data) {
  return {
    type: facesheetTypes.GET_FACESHEET_DATA_SUCCESS,
    data,
  };
}

function getFacesheetDataFailure(error) {
  return {
    type: facesheetTypes.GET_FACESHEET_DATA_FAILURE,
    error,
  };
}

function getFacesheetDataReset() {
  return {
    type: facesheetTypes.GET_FACESHEET_DATA_FAILURE,
  };
}

function processFacesheetDataRequest(request, token) {
  return {
    type: facesheetTypes.PROCESS_FACESHEET_DATA_REQUEST,
    request,
    token,
  };
}

function processFacesheetDataSuccess(result, message) {
  return {
    type: facesheetTypes.PROCESS_FACESHEET_DATA_SUCCESS,
    result,
    message,
  };
}

function processFacesheetDataFailure(error) {
  return {
    type: facesheetTypes.PROCESS_FACESHEET_DATA_FAILURE,
    error,
  };
}

function processFacesheetDataReset() {
  return {
    type: facesheetTypes.PROCESS_FACESHEET_DATA_RESET,
  };
}
