import { getFileDataType } from "../types/getFileData.type";

export const getFileDataActions = {
  getFileDataRequest,
  getFileDataSuccess,
  getFileDataFailure,
  getFileDataReset,
};

function getFileDataRequest(query, request, token) {
  return {
    type: getFileDataType.GET_FILE_DATA_REQUEST,
    query,
    request,
    token,
  };
}

function getFileDataSuccess(data, totalrecords) {
  return {
    type: getFileDataType.GET_FILE_DATA_SUCCESS,
    data,
    totalrecords,
  };
}

function getFileDataFailure(error) {
  return {
    type: getFileDataType.GET_FILE_DATA_FAILURE,
    error,
  };
}

function getFileDataReset() {
  return {
    type: getFileDataType.GET_FILE_DATA_FAILURE,
  };
}
