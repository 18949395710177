import { getReportsDataTypes } from "../types/getReportsData.type";

export const getReportsDataActions = {
  getReportsDataRequest,
  getReportsDataRequestSuccess,
  getReportsDataRequestReset,
  getReportsDataRequestFailure,
};

function getReportsDataRequest(request, token) {
  return {
    type: getReportsDataTypes.GET_REPORTSDATA_REQUEST,
    request,
    token,
  };
}

function getReportsDataRequestSuccess(getReportsData) {
  return {
    type: getReportsDataTypes.GET_REPORTSDATA_REQUEST_SUCCESS,
    getReportsData,
  };
}

function getReportsDataRequestReset() {
  return { type: getReportsDataTypes.GET_REPORTSDATA_REQUEST_RESET };
}

function getReportsDataRequestFailure(error) {
  return { type: getReportsDataTypes.GET_REPORTSDATA_REQUEST_FAILURE, error };
}
