import { getBillingCptType } from "../types/getBiilingCpt.type";

export const getBillingCptModifierActions = {
  getBillingCptRequest,
  getBillingCptRequestSuccess,
  getBillingCptRequestFailure,
};
function getBillingCptRequest(request, token) {
  return {
    type: getBillingCptType.GET_BILLING_CPT_REQUEST,
    request,
    token,
  };
}
function getBillingCptRequestSuccess(getAll) {
  return {
    type: getBillingCptType.GET_BILLING_CPT_REQUEST_SUCCESS,
    getAll,
  };
}
function getBillingCptRequestFailure(error) {
  return {
    type: getBillingCptType.GET_BILLING_CPT_REQUEST_FAILURE,
    error,
  };
}
