import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { insuranceActions } from "../actions";
import { insuranceTypes } from "../types";

export function* watchDMInsurance() {
  yield takeLatest(insuranceTypes.INSURANCE_REQUEST, getAllInsurance);
}

function* getAllInsurance(action) {
  try {
    let data = yield call(Api, {
      method: "POST",
      endpoint: endpoint.getAllDMInsuranceData,
      jwt: "Bearer " + action.token,
    });
    let parsedData = JSON.parse(data);

    yield put(
      insuranceActions.insuranceRequestSuccess(parsedData.InsuranceList)
    );
  } catch (error) {
    yield put(insuranceActions.insuranceRequestFailure(error));
  }
}
