import { put, takeEvery } from "@redux-saga/core/effects";
import { pageActions } from "../actions/page.action";
import { pageType } from "../types/page.type";

export function* watchPages() {
  yield takeEvery(pageType.PAGE_TYPE_REQUEST, getPage);
}
function* getPage(action) {
  try {
    yield put(pageActions.pageRequestSuccess(action.page));
  } catch (error) {
    yield put(pageActions.pageRequestFailure(error));
  }
}
