import { put, takeLatest, call } from "@redux-saga/core/effects";
import { callHistorySaveActions } from "../actions";
import { callHistorySaveType } from "../types";
import { endpoint } from "../../../../../api/Url";
import { Api2 } from "../../../../../api/Api2";
import { loginActions } from "../../../../Login/actions/login.action";

export function* watchCallHistorySave() {
  yield takeLatest(
    callHistorySaveType.CALL_HISTORY_SAVE_REQUEST,
    saveCallHistory
  );
}

function* saveCallHistory(action) {
  try {
    //   api call
    const message = yield call(Api2, {
      endpoint: endpoint.saveCallHistory,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    const parsedMessage = JSON.parse(message);

    yield put(
      callHistorySaveActions.callHistorySaveRequestSuccess(parsedMessage[0])
    );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(callHistorySaveActions.callHistorySaveRequestFailure(error));
  }
}
