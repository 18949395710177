import { forgetPasswordTypes } from "../types";

const initialState = {
  sending: false,
  // message: "",
  error: "",
};
export const forgetPasswordResetReducer = (state = initialState, action) => {
  switch (action.type) {
    case forgetPasswordTypes.FORGET_PASSWORD_RESET_REQUEST:
      return {
        ...initialState,
        sending: true,
      };
    case forgetPasswordTypes.FORGET_PASSWORD_RESET_REQUEST_SUCCESS:
      return {
        ...initialState,
        message: action.message,
      };
    case forgetPasswordTypes.FORGET_PASSWORD_RESET_REQUEST_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case forgetPasswordTypes.FORGET_PASSWORD_RESET_REQUEST_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
