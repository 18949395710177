import { getActivePatientMedicationType } from "../../types";

function getActivePatientMedicationRequest(query, token) {
  return {
    type: getActivePatientMedicationType.GET_ACTIVE_PATIENT_MEDICATION_REQUEST,
    query,
    token,
  };
}
function getActivePatientMedicationSuccess(getAll) {
  return {
    type: getActivePatientMedicationType.GET_ACTIVE_PATIENT_MEDICATION_SUCCESS,
    getAll,
  };
}
function getActivePatientMedicationFailure(error) {
  return {
    type: getActivePatientMedicationType.GET_ACTIVE_PATIENT_MEDICATION_FAILURE,
    error,
  };
}

function getActivePatientMedicationReset() {
  return {
    type: getActivePatientMedicationType.GET_ACTIVE_PATIENT_MEDICATION_RESET,
  };
}
export const getActivePatientMedicationActions = {
  getActivePatientMedicationRequest,
  getActivePatientMedicationSuccess,
  getActivePatientMedicationFailure,
  getActivePatientMedicationReset,
};
