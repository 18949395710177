import { lookupTypes } from "../types/lookup.type";

export const lookupActions = {
  lookupRegionRequest,
  lookupRegionRequestSuccess,
  lookupRegionRequestFailure,
  lookupIPARequest,
  lookupIPARequestSuccess,
  lookupIPARequestFailure,
  //pcpgroup
  lookupPCPGroupRequest,
  lookupPCPGroupSuccess,
  lookupPCPGroupFailure,
  //pcpoffice
  lookupPCPOfficeRequest,
  lookupPCPOfficeSuccess,
  lookupPCPOfficeFailure,

  //hierarachy level
  lookupHierarchyLevelRequest,
  lookupHierarchyLevelSuccess,
  lookupHierarchyLevelFailure,

  //MENU LIST
  lookupMenuRequest,
  lookupMenuSuccess,
  lookupMenuFailure,

  //User

  lookupUserRequest,
  lookupUserRequestSuccess,
  lookupUserRequestFailure,

  //role
  lookupRoleRequest,
  lookupRoleRequestSuccess,
  lookupRoleRequestFailure,
};

//Lookup Region

function lookupRegionRequest(query, token) {
  return {
    type: lookupTypes.LOOKUP_REGION_REQUEST,
    query,
    token,
  };
}
function lookupRegionRequestSuccess(getAll) {
  return {
    type: lookupTypes.LOOKUP_REGION_REQUEST_SUCCESS,
    getAll,
  };
}
function lookupRegionRequestFailure(error) {
  return {
    type: lookupTypes.LOOKUP_REGION_REQUEST_FAILURE,
    error,
  };
}

//Lookup IPA

function lookupIPARequest(query, token) {
  return {
    type: lookupTypes.LOOKUP_IPA_REQUEST,
    query,
    token,
  };
}

function lookupIPARequestSuccess(getAll) {
  return {
    type: lookupTypes.LOOKUP_IPA_REQUEST_SUCCESS,
    getAll,
  };
}

function lookupIPARequestFailure(error) {
  return {
    type: lookupTypes.LOOKUP_IPA_REQUEST_FAILURE,
    error,
  };
}

//lookup   PCPGROUP

function lookupPCPGroupRequest(query, token) {
  return {
    type: lookupTypes.LOOKUP_PCP_GROUP_REQUEST,
    query,
    token,
  };
}

function lookupPCPGroupSuccess(getAll) {
  return {
    type: lookupTypes.LOOKUP_PCP_GROUP_REQUEST_SUCCESS,
    getAll,
  };
}

function lookupPCPGroupFailure(error) {
  return {
    type: lookupTypes.LOOKUP_PCP_GROUP_REQUEST_FAILURE,
    error,
  };
}

//LOOKUP PCPOFFICE

function lookupPCPOfficeRequest(query, token) {
  return {
    type: lookupTypes.LOOKUP_PCP_OFFICE_REQUEST,
    query,
    token,
  };
}

function lookupPCPOfficeSuccess(getAll) {
  return {
    type: lookupTypes.LOOKUP_PCP_OFFICE_SUCCESS,
    getAll,
  };
}

function lookupPCPOfficeFailure(error) {
  return {
    type: lookupTypes.LOOKUP_PCP_OFFICE_REQUEST_FAILURE,
    error,
  };
}

//lookup hierarchy level

function lookupHierarchyLevelRequest(query, token) {
  return {
    type: lookupTypes.LOOKUP_HIERARCHY_LEVEL_REQUEST,
    query,
    token,
  };
}

function lookupHierarchyLevelSuccess(getAll) {
  return {
    type: lookupTypes.LOOKUP_HIERARCHY_LEVEL_SUCCESS,
    getAll,
  };
}

function lookupHierarchyLevelFailure(error) {
  return {
    type: lookupTypes.LOOKUP_HIERARCHY_LEVEL_REQUEST_FAILURE,
    error,
  };
}

//lookup menus
function lookupMenuRequest(token) {
  return {
    type: lookupTypes.LOOKUP_MENU_REQUEST,
    token,
  };
}

function lookupMenuSuccess(getAll) {
  return {
    type: lookupTypes.LOOKUP_MENU_SUCCESS,
    getAll,
  };
}

function lookupMenuFailure(error) {
  return {
    type: lookupTypes.LOOKUP_MENU_FAILURE,
    error,
  };
}

//Lookup user

function lookupUserRequest(query, token) {
  return {
    type: lookupTypes.LOOKUP_USER_REQUEST,
    query,
    token,
  };
}
function lookupUserRequestSuccess(getAll) {
  return {
    type: lookupTypes.LOOKUP_USER_REQUEST_SUCCESS,
    getAll,
  };
}
function lookupUserRequestFailure(error) {
  return {
    type: lookupTypes.LOOKUP_USER_REQUEST_FAILURE,
    error,
  };
}

//lookup role

function lookupRoleRequest(token) {
  return {
    type: lookupTypes.LOOKUP_ROLE_REQUEST,
    token,
  };
}
function lookupRoleRequestSuccess(getAll) {
  return {
    type: lookupTypes.LOOKUP_ROLE_REQUEST_SUCCESS,
    getAll,
  };
}
function lookupRoleRequestFailure(error) {
  return {
    type: lookupTypes.LOOKUP_ROLE_REQUEST_FAILURE,
    error,
  };
}
