import { roleSaveTypes } from "../types";

const initiaLState = {
    loading: false,
  };
  
  export const roleSaveDMReducer = (state = initiaLState, action) => {
    switch (action.type) {
      case roleSaveTypes.ROLE_SAVE_REQUEST:
        return {
          ...initiaLState,
          loading: true,
        };
      case roleSaveTypes.ROLE_SAVE_REQUEST_RESET:
        return {
          ...initiaLState,
        };
      case roleSaveTypes.ROLE_SAVE_REQUEST_SUCCESS:
        return {
          ...initiaLState,
          message: action.message,
        };
      case roleSaveTypes.ROLE_SAVE_REQUEST_FAILURE:
        return {
          ...initiaLState,
          error: action.error,
        };
      default:
        return state;
    }
  };
  