export const problemListTypes = {
  GETALL_PROBLEM_REQUEST: "GETALL_PROBLEM_REQUEST",
  GETALL_PROBLEM_REQUEST_SUCCESS: "GETALL_PROBLEM_REQUEST_SUCCESS",
  GETALL_PROBLEM_REQUEST_FAILURE: "GETALL_PROBLEM_REQUEST_FAILURE",

  OPERARTION_PROBLEM_REQUEST: "OPERARTION_PROBLEM_REQUEST",
  OPERARTION_PROBLEM_REQUEST_SUCCESS: "OPERARTION_PROBLEM_REQUEST_SUCCESS",
  OPERARTION_PROBLEM_REQUEST_FAILURE: "OPERARTION_PROBLEM_REQUEST_FAILURE",
  OPERARTION_PROBLEM_REQUEST_RESET: "OPERARTION_PROBLEM_REQUEST_RESET",

  DELETE_PROBLEM_REQUEST: "DELETE_PROBLEM_REQUEST",
  DELETE_PROBLEM_REQUEST_SUCCESS: "DELETE_PROBLEM_REQUEST_SUCCESS",
  DELETE_PROBLEM_REQUEST_FAILURE: "DELETE_PROBLEM_REQUEST_FAILURE",
  DELETE_PROBLEM_REQUEST_RESET: "DELETE_PROBLEM_REQUEST_RESET",
};
