import { visitDetailsType } from "../types/visitDetails.type";

const initialState = {
  loading: false,
  visits: [],
};

export const visitDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case visitDetailsType.VISIT_DETAILS_REQUEST:
      return { loading: true, visits: [] };
    case visitDetailsType.VISIT_DETAILS_REQUEST_SUCCESS:
      return {
        visits: action.visits.sort((item1, item2) =>
          item1.id > item2.id ? 1 : -1
        ),
      };
    case visitDetailsType.VISIT_DETAILS_REQUEST_FAILURE:
      return { error: action.error };
    default:
      return state;
  }
};
