import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api2 } from "../../../../api/Api2";
import { endpoint } from "../../../../api/Url";
import { userActions } from "../actions";
import { userTypes } from "../types/user.type";

export function* watchUserDetails() {
  yield takeLatest(userTypes.GET_USER_REQUEST, getUser);
}

function* getUser(action) {
  try {
    let data = yield call(Api2, {
      method: "GET",
       endpoint: `${endpoint.createUsersDmRole}?roleId=${action.request.RoleId}`,
      jwt: "Bearer " + action.token,

    });

    yield put(userActions.userRequestSuccess(data.data));
  } catch (error) {
    yield put(userActions.userRequestFailure(error));
  }
}
