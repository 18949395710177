import { addCPTCommentType } from "../../types/CPTComments/addCptComment.type";

export const addCptCommentActions = {
  addBillingPatientRequest,
  addBillingPatientSuccess,
  addBillingPatientFailure,
  addBillingPatientReset,
};
function addBillingPatientRequest(request, token) {
  return {
    type: addCPTCommentType.ADD_CPT_COMMENT_REQUEST,
    request,
    token,
  };
}
function addBillingPatientSuccess(message) {
  return {
    type: addCPTCommentType.ADD_CPT_COMMENT_SUCCESS,
    message,
  };
}
function addBillingPatientFailure(error) {
  return {
    type: addCPTCommentType.ADD_CPT_COMMENT_FAILURE,
    error,
  };
}
function addBillingPatientReset() {
  return {
    type: addCPTCommentType.ADD_CPT_COMMENT_RESET,
  };
}
