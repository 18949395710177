import { put, call, debounce } from "@redux-saga/core/effects";
import {
  callActionTakenLookupCmerActions,
  callDispositionLookupCmerActions,
  callHistoryOperationsCmActions,
} from "../actions";
import {
  callActionTakenLookupCmerTypes,
  callDispositionLookupCmerTypes,
  callHistoryOperationsCmType,
} from "../types";
import { Api2 } from "../../../../api/Api2";
import { endpoint } from "../../../../api/Url";
import { alertActions } from "../../../../components/AlertNew/store/AlertNew.action";
export function* watchCallHistoryCmRole() {
  yield debounce(
    500,
    callHistoryOperationsCmType.CALL_HISTORY_OPERATIONS_CM_REQUEST,
    callHistoryActionsCm
  );
  yield debounce(
    500,
    callHistoryOperationsCmType.CALL_HISTORY_DETAILS_CM_REQUEST,
    getcallHistoryDetailsCm
  );

  yield debounce(
    500,
    callActionTakenLookupCmerTypes.CALL_ACTION_TAKEN_LOOKUP_CMER_REQUEST,
    getActionsTakenLookupCmer
  );
  yield debounce(
    500,
    callDispositionLookupCmerTypes.CALL_DISPOSITION_LOOKUP_CMER_REQUEST,
    getDispositionLookupCmer
  );
}
// for getting all details
function* getcallHistoryDetailsCm(action) {
  try {
    const response = yield call(Api2, {
      endpoint: `${endpoint.callHistoryOperationsCm}?admissionId=${action?.request?.parentAdmissionId}&patientId=${action?.request?.patientID}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });
    yield put(
      callHistoryOperationsCmActions.callHistoryGetAllDetailsCmSuccess(response)
    );
  } catch (error) {
    yield put(
      callHistoryOperationsCmActions.callHistoryOperationsCmFailure(error)
    );
  }
}
// for getting all CRUD oprations
function* callHistoryActionsCm(action) {
  let { callHistoryOperationsCm } = endpoint;
  let {
    historyId,
    parentAdmissionId,
    patientID,
    actionIDs,
    dispositionID,
    callComments,
    callComplete,
    DNC,
    closeCase,
    flag,
  } = action?.request;

  try {
    console.log(action, "actionCMROLE");
    let method =
      flag === "Delete" ? "DELETE" : flag === "Edit" ? "PUT" : "POST";
    let endpointUrl =
      flag === "Delete" || flag === "Edit"
        ? `${callHistoryOperationsCm}?historyId=${historyId}`
        : `${callHistoryOperationsCm}?admissionId=${parentAdmissionId}&patientId=${patientID}`;

    const response = yield call(Api2, {
      endpoint: endpointUrl,
      method: method,
      jwt: "Bearer " + action.token,
      body: {
        actionIDs,
        dispositionID,
        callComments,
        callComplete,
        DNC,
        closeCase,
      },
    });
    yield put(
      callHistoryOperationsCmActions.callHistoryOperationsCmSuccess(
        response?.Result
      )
    );
    if (flag === "Delete") {
      yield put(alertActions?.alertSuccessRequest("Call history Deleted"));
    } else if (flag === "Edit") {
      yield put(alertActions?.alertSuccessRequest("Call history Updated"));
    } else {
      yield put(alertActions?.alertSuccessRequest("Call history Created"));
    }
  } catch (error) {
    yield put(
      callHistoryOperationsCmActions.callHistoryOperationsCmFailure(error)
    );
    yield put(alertActions?.alertErrorRequest(error));
  }
}
function* getActionsTakenLookupCmer(action) {
  try {
    const response = yield call(Api2, {
      endpoint: `${endpoint.actionTakenLookupCmer}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });
    console.log(response, "responselook");
    yield put(
      callActionTakenLookupCmerActions.callActionTakenLookupCmerSuccess(
        response?.data
      )
    );
  } catch (error) {
    yield put(
      callActionTakenLookupCmerActions.callActionTakenLookupCmerFailure(error)
    );
  }
}
function* getDispositionLookupCmer(action) {
  try {
    const response = yield call(Api2, {
      endpoint: `${endpoint.dispositionLookupCmer}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });

    yield put(
      callDispositionLookupCmerActions.callDispositionLookupCmerSuccess(
        response?.data
      )
    );
  } catch (error) {
    yield put(
      callDispositionLookupCmerActions.callDispositionLookupCmerFailure(error)
    );
  }
}
