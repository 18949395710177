import { saveDynamicGaitTypes } from "../types";

export const saveDynamicGaitActions = {
  saveDynamicGaitRequest,
  saveDynamicGaitRequestSuccess,
  saveDynamicGaitRequestFailure,
  saveDynamicGaitRequestReset,
};

function saveDynamicGaitRequest(request, token) {
  return {
    type: saveDynamicGaitTypes.SAVE_DYNAMIC_GAIT_REQUEST,
    request,
    token,
  };
}

function saveDynamicGaitRequestSuccess(message) {
  return {
    type: saveDynamicGaitTypes.SAVE_DYNAMIC_GAIT_REQUEST_SUCCESS,
    message,
  };
}

function saveDynamicGaitRequestFailure(error) {
  return {
    type: saveDynamicGaitTypes.SAVE_DYNAMIC_GAIT_REQUEST_FAILURE,
    error,
  };
}

function saveDynamicGaitRequestReset() {
  return {
    type: saveDynamicGaitTypes.SAVE_DYNAMIC_GAIT_REQUEST_RESET,
  };
}
