import React from "react";
import { Progress } from "../../components";
import { Spinner } from "../../core";
import styles from "./Splash.module.scss";
const Splash = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>PACT</h1>
      <h4 className={styles.subheading}>powered by HITS</h4>

      <Spinner width={34} height={34} />
    </div>
  );
};

export default Splash;
