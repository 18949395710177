import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

import React, { lazy } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import { BrowserRouter } from "react-router-dom";
//import: redux
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
// import { ContextProvider } from "./Context/SocketContext";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ErrorBoundary } from "react-error-boundary";
// import Loadable from "./components/Loadable";
import ErrorBoundaryComp from "./components/ErrorBoundry";
import { Url } from "./api/Url";

import "moment-timezone";
import moment from "moment/moment";

// moment.tz.setDefault("America/Chicago");
const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (graphqlErrors) {
    graphqlErrors.map(({ message, location, path }) => {
      alert(`Graphql error ${message}`);
    });
  }
});
// creating  a link
const link = from([errorLink, new HttpLink({ uri: `${Url.baseGraphql}` })]);

// create a client
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
});
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
// const FallBackComp = lazy(() => import("./components/ErrorBoundry"));

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <PersistGate loading={null} persistor={persistor}>
          <React.StrictMode>
            {/* <ContextProvider> */}
            <BrowserRouter basename={baseUrl}>
              <ErrorBoundary FallbackComponent={ErrorBoundaryComp}>
                <App />
              </ErrorBoundary>
            </BrowserRouter>
            {/* </ContextProvider> */}
          </React.StrictMode>
        </PersistGate>
      </LocalizationProvider>
    </Provider>
  </ApolloProvider>,
  rootElement
);
