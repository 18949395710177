import { diagnosisLookupCmerTypes } from "../types";

const initialState = {
  loading: false,
  getAll: [],
  error: "",
};

export const diagnosisLookupCmerReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case diagnosisLookupCmerTypes.DIAGNOSIS_LOOKUP_CMER_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case diagnosisLookupCmerTypes.DIAGNOSIS_LOOKUP_CMER_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
      };
    case diagnosisLookupCmerTypes.DIAGNOSIS_LOOKUP_CMER_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    default:
      return state;
  }
};
