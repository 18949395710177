import { getAllFacilityLocationType } from "../../types";

export const getAllFacilityLocationsTCActions = {
  getAllFacilityLocationRequest,
  getAllFacilityLocationSuccess,
  getAllFacilityLocationFailure,
};
function getAllFacilityLocationRequest(query, token) {
  
  return {
    type: getAllFacilityLocationType.GETALL_FACILITY_LOCATION_TC_REQUEST,
    query,
    token,
  };
}
function getAllFacilityLocationSuccess(getAll) {
  return {
    type: getAllFacilityLocationType.GETALL_FACILITY_LOCATION_TC_SUCCESS,
    getAll,
  };
}
function getAllFacilityLocationFailure(error) {
  return {
    type: getAllFacilityLocationType.GETALL_FACILITY_LOCATION_TC_SUCCESS,
    error,
  };
}
