export const regionTypes = {
  //get REGION
  REGION_REQUEST: "GETALL_DM_REGION_REQUEST",
  REGION_REQUEST_SUCCESS: "GETALL_DM_REGION_REQUEST_SUCCESS",
  REGION_REQUEST_FAILURE: "GETALL_DM_REGION_REQUEST_FAILURE",

  //save REGION
  REGION_SAVE_REQUEST: "DM_REGION_SAVE_REQUEST",
  REGION_SAVE_REQUEST_SUCCESS: "DM_REGION_SAVE_REQUEST_SUCCESS",
  REGION_SAVE_REQUEST_FAILURE: "DM_REGION_SAVE_REQUEST_FAILURE",

  //edit REGION
  REGION_EDIT_REQUEST: "DM_REGION_EDIT_REQUEST",
  REGION_EDIT_REQUEST_SUCCESS: "DM_REGION_EDIT_REQUEST_SUCCESS",
  REGION_EDIT_REQUEST_FAILURE: "DM_REGION_EDIT_REQUEST_FAILURE",

  //update REGION
  REGION_UPDATE_STATUS_REQUEST: "DM_REGION_UPDATE_STATUS_REQUEST",
  REGION_UPDATE_STATUS_REQUEST_SUCCESS:
    "DM_REGION_UPDATE_STATUS_REQUEST_SUCCESS",
  REGION_UPDATE_STATUS_REQUEST_FAILURE:
    "DM_REGION_UPDATE_STATUS_REQUEST_FAILURE",

  //reset
  REGION_REQUEST_RESET: "DM_REGION_REQUEST_RESET",
};
