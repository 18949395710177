import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { screenPreventExamActions } from "../actions";
import { screenPreventTypes } from "../types";
export function* watchScreenPrevent() {
  yield takeLatest(
    screenPreventTypes.SCREENPREVENT_GET_REQUEST,
    getScreenPrevent
  );
  yield takeLatest(
    screenPreventTypes.SCREENPREVENT_OPERATION_REQUEST,
    operationScreenPrevent
  );
}

function* getScreenPrevent(action) {
  try {
    let data = yield call(Api, {
      endpoint: `${endpoint.getScreeningOrPreventiveExamsDetails}?patientId=${action.request.patientId}`,
      method: "POST",
      jwt: "Bearer " + action.token,
    });
    let parsedData = JSON.parse(data);

    yield put(
      screenPreventExamActions.screenPreventGetRequestSuccess(parsedData)
    );
  } catch (error) {
    yield put(screenPreventExamActions.screenPreventGetRequestFailure(error));
  }
}
function* operationScreenPrevent(action) {
  try {
    let message = yield call(Api, {
      endpoint: endpoint.saveScreeningorPreventiveExamsDetails,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedMessage = JSON.parse(message);

    yield put(
      screenPreventExamActions.screenPreventOpRequestSuccess(parsedMessage[0])
    );
  } catch (error) {
    yield put(screenPreventExamActions.screenPreventOpRequestFailure(error));
  }
}
