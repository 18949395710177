import { deleteAppointmentTypes } from "../types/deleteAppointment.type";

export const deleteAppointmentActions = {
  deleteAppointmentRequest,
  deleteAppointmentRequestSuccess,
  deleteAppointmentRequestFailure,
  deleteAppointmentRequestReset,
};

function deleteAppointmentRequest(request, token) {
  return {
    type: deleteAppointmentTypes.DELETE_APPOINTMENT_REQUEST,
    request,
    token,
  };
}

function deleteAppointmentRequestSuccess(message) {
  return {
    type: deleteAppointmentTypes.DELETE_APPOINTMENT_REQUEST_SUCCESS,
    message,
  };
}

function deleteAppointmentRequestFailure(error) {
  return {
    type: deleteAppointmentTypes.DELETE_APPOINTMENT_REQUEST,
    error,
  };
}

function deleteAppointmentRequestReset() {
  return {
    type: deleteAppointmentTypes.DELETE_APPOINTMENT_REQUEST_RESET,
  };
}
