import React from "react";
import { colors } from "../../../themes/colors";
import TextFieldSearch from "../../searchNew/TextFieldSearch";
import { DatePicker } from "@mui/x-date-pickers";

const DatePickerField = ({
  error,
  onChange,
  value = null,
  placeholder,
  size = "small",
  isError,
}) => {
  return (
    <DatePicker
      label=""
      placeholder="placeholder"
      // defaultValue={dayjs()}
      size={size}
      disableFuture
      sx={{
        boxShadow: "none",
        width: "100%",
        ".MuiOutlinedInput-notchedOutline": { border: "none" },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        ".MuiOutlinedInput-input": {
          p: 0.7,
        },

        background: colors.color_primary,
        border: isError ? `1.5px solid ${colors?.color_error_txt}` : "none",
      }}
      value={value}
      onChange={onChange}
      slotProps={{ textField: { size: "small" } }}
      renderInput={(params) => (
        <TextFieldSearch
          {...params}
          sx={{
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: "none" },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            background: colors.color_primary,
          }}
          size="small"
        />
      )}
    />
  );
};

export default DatePickerField;
