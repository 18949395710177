import { pcpTypes } from "../types";

export const pcpActions = {
  getPCPRequest,
  getPCPRequestSuccess,
  getPCPRequestFailure,
};

function getPCPRequest(query, token) {
  return { type: pcpTypes.GET_PCP_REQUEST, query, token };
}
function getPCPRequestSuccess(getAll) {
  return { type: pcpTypes.GET_PCP_REQUEST_SUCCESS, getAll };
}
function getPCPRequestFailure(error) {
  return {
    type: pcpTypes.GET_PCP_REQUEST_FAILURE,
    error,
  };
}
