import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  getAll: [],
  error: "",
};

export const dyncamicLookupSlice = createSlice({
  name: "dynamicLookup",
  initialState: initialState,
  reducers: {
    dynamicLookupRequest: (state, action) => {
      state.loading = true;
    },
    dynamicLookupSuccess: (state, action) => {
      state.loading = false;
      state.error = "";
      state.getAll = action.payload;
    },
    dynamicLookupFaliure: (state, action) => {
      state.loading = false;
      state.getAll = [];
      state.error = action.payload.error;
    },
    dynamicLookupReset: (state, action) => {
      state.loading = false;
      state.error = "";
      state.getAll = [];
    },
  },
});

export const {
  dynamicLookupRequest,
  dynamicLookupSuccess,
  dynamicLookupFaliure,
  dynamicLookupReset,
} = dyncamicLookupSlice.actions;
export default dyncamicLookupSlice.reducer;
