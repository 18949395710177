import { getInsuranceTypes } from "../types";

export const getInsuranceActions = {
  insuranceRequest,
  insuranceRequestSuccess,
  insuranceRequestFailure,
};
function insuranceRequest(request, token) {
  return {
    type: getInsuranceTypes.GET_INSURANCE_REQUEST,
    request,
    token,
  };
}
function insuranceRequestSuccess(get) {
  return {
    type: getInsuranceTypes.GET_INSURANCE_REQUEST_SUCCESS,
    get,
  };
}
function insuranceRequestFailure(error) {
  return {
    type: getInsuranceTypes.GET_INSURANCE_REQUEST_FAILURE,
    error,
  };
}
