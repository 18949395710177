import { cmUsersType } from "../types/cmUsers.type";

const initialState = {
  loading: false,
  cmUsers: [],
};

export const cmUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case cmUsersType.CM_USERS_REQUEST:
      return { ...initialState, loading: true, cmUsers: [] };
    case cmUsersType.CM_USERS_REQUEST_SUCCESS:
      return {
        // ...initialState,
        cmUsers: action.cmUsers,
      };
    case cmUsersType.CM_USERS_REQUEST_FAILURE:
      return { error: action.error };
    //  return { ...initialState,error: action.error };
    default:
      return state;
  }
};
