import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { dispositionOperationActions } from "../actions";
import { dispositionOperationTypes } from "../types";

export function* watchDMDispositionOperation() {
  yield takeLatest(
    dispositionOperationTypes.DISPOSITION_OPERATION_REQUEST,
    dispositionOperation
  );
}

function* dispositionOperation(action) {
  try {
    let data = yield call(Api, {
      method: "POST",
      endpoint: endpoint.operationDMDisposition,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedData = JSON.parse(data);

    if (parsedData.length > 0) {
      yield put(
        dispositionOperationActions.dispositionOperationRequestSuccess(
          parsedData[0]
        )
      );
    } else {
      yield put(
        dispositionOperationActions.dispositionOperationRequestSuccess({
          Result: "Error",
        })
      );
    }
  } catch (error) {
    yield put(
      dispositionOperationActions.dispositionOperationRequestFailure(error)
    );
  }
}
