import { callHistoryType } from "../types/callHistory.type";

const initialState = {
  loading: false,
  callHistory: [],
};

export const callHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case callHistoryType.CALL_HISTORY_REQUEST:
      return { ...initialState, loading: true };
    case callHistoryType.CALL_HISTORY_REQUEST_SUCCESS:
      return {
        ...initialState,
        callHistory: action.callHistory,
      };
    case callHistoryType.CALL_HISTORY_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};
