import React from "react";
import styles from "./Alert.module.scss";
export const Alert = ({ isSuccess, successMessage, failureMessage }) => {
  return (
    <div className={styles.alert_container}>
      {isSuccess ? (
        <p className={styles.success}>{successMessage}</p>
      ) : (
        <p className={styles.failure}>{failureMessage}</p>
      )}
    </div>
  );
};
