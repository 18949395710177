import {
  visitDetailsCMType,
  visitDetailsType,
} from "../types/visitDetails.type";

const initialState = {
  loading: false,
  visits: [],
};

export const visitDetailsCMReducer = (state = initialState, action) => {
  switch (action.type) {
    case visitDetailsCMType.VISIT_DETAILS_CM_REQUEST:
      return { loading: true, visits: [] };
    case visitDetailsCMType.VISIT_DETAILS_CM_SUCCESS:
      return {
        visits: action.visits.sort((item1, item2) =>
          item1.id > item2.id ? 1 : -1
        ),
      };
    case visitDetailsCMType.VISIT_DETAILS_CM_FAILURE:
      return { error: action.error };
    default:
      return state;
  }
};
