import { put, takeLatest, call } from "@redux-saga/core/effects";
import { physicianActions } from "../actions";
import { endpoint } from "../../../../../api/Url";
import { Api } from "../../../../../api";
import { loginActions } from "../../../../Login/actions/login.action";
import { physicianTypes } from "../types";

export function* watchPhysician() {
  yield takeLatest(physicianTypes.PHYSICIAN_REQUEST, getPhysicianList);
}

function* getPhysicianList(action) {
  try {
    const physicianList = yield call(Api, {
      endpoint: `${endpoint.getPhysician}?SearchStr=${action.request.searchStr}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      //   body: action.request,
    });

    const parsedPhysicianList = JSON.parse(physicianList);

    yield put(
      physicianActions.physicianRequestSuccess(
        parsedPhysicianList.PhysicianModel
      )
    );
  } catch (error) {
    yield put(physicianActions.physicianRequestFailure(error));

    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
  }
}
