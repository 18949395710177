import { takeLatest, put, call } from "@redux-saga/core/effects";
import { endpoint } from "../../../../api/Url";
import { addUpdateUtilizationCMTypes } from "../types";
import { addUpdateUtilizationActions } from "../actions";
import { Api2 } from "../../../../api/Api2";
import { alertActions } from "../../../../components/AlertNew/store/AlertNew.action";

export function* watchAddUpdateUtilizationCM() {
  yield takeLatest(
    addUpdateUtilizationCMTypes.ADDUPDATE_UTILIZATION_CM_REQUEST,
    addUpdateUtilizationCM
  );
}

function* addUpdateUtilizationCM(action) {
  const { PatientID, AdmissionID, ...requestBody } = action.request;
  try {
    let response = yield call(Api2, {
      // method: !action.request.AdmissionID ? "POST" : "PUT",
      method: requestBody?.Flag === "Insert" ? "POST" : "PUT",
      endpoint: `${endpoint.updateHospitalAdmissionPatientDetails}?PatientID=${action.request.PatientID}&AdmissionID=${action.request.AdmissionID}`,
      jwt: "Bearer " + action.token,
      body: requestBody,
    });

    yield put(
      addUpdateUtilizationActions.addUpdateUtilizationRequestSuccess(
        response?.message
      )
    );
    yield put(alertActions.alertSuccessRequest(response?.message));
  } catch (error) {
    yield put(
      addUpdateUtilizationActions.addUpdateUtilizationRequestFailure(error)
    );
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}
