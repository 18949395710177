import { insurancePlanTypes } from "../types";

export const insurancePlanActions = {
  insurancePlanRequest,
  insurancePlanRequestSuccess,
  insurancePlanRequestFailure,
};
function insurancePlanRequest(request, token) {
  return {
    type: insurancePlanTypes.INSURANCE_PLAN_REQUEST,
    request,
    token,
  };
}
function insurancePlanRequestSuccess(getAll) {
  return {
    type: insurancePlanTypes.INSURANCE_PLAN_REQUEST_SUCCESS,
    getAll,
  };
}
function insurancePlanRequestFailure(error) {
  return {
    type: insurancePlanTypes.INSURANCE_PLAN_REQUEST_FAILURE,
    error,
  };
}
