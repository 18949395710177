export const strings = {
  DIAGNOSIS: "Diagnosis",
  DISCHARGE_DIAGNOSIS: "Discharge Diagnosis",
  CONSULTANT: "Consultant",
  BTN_UPDATE: "Update",
  SOCIAL_HISTORY: "Social history",
  SMOKING: "Smoking",
  DRINKING: "AlcoholUse",
  ILLEGAL_DRUGS: "Drugs",

  ADVANCE_CARE_PLAN: "Advance care planning",
  LIVING_WILL: "Living Will",
  HCS: "HCS",
  DNR: "DNR",
  POA: "POA",
  HOSPICE: "Hospice",

  //UPDATE ALERT
  ALERT_UPDATE_SUCCESS: "Successfully updated!",
  ALERT_UPDATE_FAILURE: "Failed to update!",
};
