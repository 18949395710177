export const screenPreventTypes = {
  SCREENPREVENT_GET_REQUEST: "SCREENPREVENT_GET_REQUEST",
  SCREENPREVENT_GET_REQUEST_SUCCESS: "SCREENPREVENT_GET_REQUEST_SUCCESS",
  SCREENPREVENT_GET_REQUEST_FAILURE: "SCREENPREVENT_GET_REQUEST_FAILURE",

  SCREENPREVENT_OPERATION_REQUEST: "SCREENPREVENT_OPERATION_REQUEST",
  SCREENPREVENT_OPERATION_REQUEST_SUCCESS:
    "SCREENPREVENT_OPERATION_REQUEST_SUCCESS",
  SCREENPREVENT_OPERATION_REQUEST_FAILURE:
    "SCREENPREVENT_OPERATION_REQUEST_FAILURE",
  SCREENPREVENT_OPERATION_REQUEST_RESET:
    "SCREENPREVENT_OPERATION_REQUEST_RESET",
};
