import { Close } from "@mui/icons-material";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import React, { memo, useEffect, useState } from "react";
import { colors } from "../../../../themes/colors";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { getAllLookUpPatientsTCActions } from "../../actions";
import { Spinner } from "../../../../core";
import { stringCapatilizeConverter } from "../../../../util";

const AppointmentModal = ({
  open,
  handleClose,
  medicationData,
  medicationId,
  appointmentDetails,
  setAppointmentDetails,
  addAppointment,
  patientDetails,
  errors,
  appointmentErrors,
}) => {
  const userData = useSelector((state) => state.login.userData);
  const getAllPatientLookup = useSelector((state) => state.getAllPatientLookup);
  const createScheduleAppoinments = useSelector(
    (state) => state.createScheduleAppoinments
  );
  const [patientOptions, setPatientOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    let query = {
      search: searchQuery,
    };
    if (searchQuery?.length > 3) {
      dispatch(
        getAllLookUpPatientsTCActions?.getAllLookUpPatientsRequest(
          query,
          userData?.token
        )
      );
    }
  }, [searchQuery]);

  useEffect(() => {
    setPatientOptions(getAllPatientLookup?.getAll);
  }, [getAllPatientLookup?.getAll]);

  return (
    <Modal open={open} onClose={() => {}} keepMounted>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{
            minWidth: 25,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: colors?.color_background,
            borderRadius: 3,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid
            container
            item
            sx={{
              backgroundColor: colors?.color_primary_txt,
              color: colors?.color_active,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              p: 2,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
            alignItems={"center"}
          >
            <Grid container item xs={6}>
              <Typography variant="h5" component="">
                Schedule Appointment
              </Typography>
            </Grid>
            <Grid container item xs={6} justifyContent="flex-end">
              <Close
                color={"#0077B6"}
                onClick={handleClose}
                sx={{ cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <Grid container rowGap={1} sx={{ p: 2 }}>
            <Grid container item alignItems={"center"}>
              <Grid
                item
                alignItems={"baseline"}
                xs={2}
                lg={3}
                xl={3}
                alignContent={"baseline"}
              >
                <Typography
                  sx={{
                    typography: {
                      sm: "body1",
                      xs: "body2",
                      lg: "subtitle1",
                      xl: "h6",
                    },
                  }}
                >
                  Patient Name
                </Typography>
              </Grid>
              <Grid item xs={10} lg={9} xl={9}>
                <TextField
                  value={`${
                    patientDetails?.LastName?.charAt(0).toUpperCase() +
                    patientDetails?.LastName?.slice(1).toLowerCase()
                  }, ${
                    patientDetails?.FirstName?.charAt(0).toUpperCase() +
                    patientDetails?.FirstName?.slice(1).toLowerCase()
                  } `}
                  disabled
                  label=""
                  size="small"
                  fullWidth
                  sx={{
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: "none" },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    background: colors.color_primary,
                  }}
                  labelBlo
                  autoComplete="off"
                  autoCorrect="off"
                  autoFocus={true}
                />
              </Grid>
            </Grid>

            <Grid container item alignItems={"center"}>
              <Grid
                item
                alignItems={"baseline"}
                xs={2}
                lg={3}
                xl={3}
                alignContent={"baseline"}
              >
                <Typography
                  sx={{
                    typography: {
                      sm: "body1",
                      xs: "body2",
                      lg: "subtitle1",
                      xl: "h6",
                    },
                  }}
                >
                  {/* Provider Name */}
                  Provider Name
                  <Tooltip title="PCP Required">
                    <sup style={{ color: "red" }}>*</sup>
                  </Tooltip>
                </Typography>
              </Grid>

              <Grid item xs={10} lg={9} xl={9}>
                <Autocomplete
                  size="small"
                  sx={{
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: "none" },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },

                    background: colors.color_primary,
                    border: appointmentErrors?.provider
                      ? `1.5px solid ${colors?.color_error_txt}`
                      : "none",
                  }}
                  onChange={(e, v) => {
                    setAppointmentDetails({
                      ...appointmentDetails,
                      provider: v,
                    });
                  }}
                  // value={
                  //   appointmentDetails?.provider !== ""
                  //     ? appointmentDetails?.provider
                  //     : null
                  // }

                  value={
                    appointmentDetails?.provider !== ""
                      ? {
                          ...appointmentDetails?.provider,
                          Pcp:
                            typeof appointmentDetails?.provider?.Pcp ===
                            "string"
                              ? stringCapatilizeConverter(
                                  appointmentDetails.provider.Pcp
                                )
                              : "",
                        }
                      : null
                  }
                  stringCapatilizeConverter
                  getOptionDisabled={(option) => {
                    return !(option && option.isOpen);
                  }}
                  id="combo-box-hint-demo"
                  options={appointmentDetails?.childrens || []}
                  getOptionLabel={(option) => {
                    return option?.Pcp || "";
                  }}
                  getOptionValue={(option) => option?.ScheduleId || ""}
                  renderOption={(props, option, { inputValue }) => {
                    const matches = match(option.Pcp, inputValue, {
                      insideWords: true,
                    });
                    const parts = parse(option.Pcp, matches);
                    return (
                      <li {...props}>
                        <Tooltip title={"Available"}>
                          <div>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight ? 700 : 400,
                                }}
                              >
                                {/* {part.text} */}

                                {part.text
                                  .split(" ")
                                  .map(
                                    (textValue) =>
                                      `${
                                        textValue?.slice(0, 1)?.toUpperCase() +
                                        textValue?.slice(1)?.toLowerCase()
                                      }`
                                  )
                                  .join(" ")}
                              </span>
                            ))}
                          </div>
                        </Tooltip>
                      </li>
                    );
                  }}
                  disableClearable
                  renderInput={(params) => {
                    return <TextField {...params} label="" />;
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item justifyContent={"flex-end"}>
              <Grid
                item
                xs={10}
                lg={9}
                xl={9}
                sx={{ color: colors?.color_error, fontSize: 14 }}
              >
                {errors?.pcp ? errors?.pcp : ""}
              </Grid>
            </Grid>
            <Grid container item alignItems={"center"}>
              <Grid
                item
                alignItems={"baseline"}
                xs={2}
                lg={3}
                xl={3}
                alignContent={"baseline"}
              >
                <Typography
                  sx={{
                    typography: {
                      sm: "body1",
                      xs: "body2",
                      lg: "subtitle1",
                      xl: "h6",
                    },
                  }}
                  component="h2"
                >
                  Date
                </Typography>
              </Grid>
              <Grid item xs={10} lg={9} xl={9}>
                <DatePicker
                  label=""
                  disabled
                  size="small"
                  // format="YYYY-MM-DD"
                  format="MM/DD/YYYY"
                  value={dayjs(appointmentDetails?.date)}
                  sx={{
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: "none" },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    background: colors.color_primary,
                    width: "100%",
                  }}
                />
              </Grid>
            </Grid>

            <Grid container item alignItems={"center"}>
              <Grid
                item
                alignItems={"baseline"}
                xs={2}
                lg={3}
                xl={3}
                alignContent={"baseline"}
              >
                <Typography
                  sx={{
                    typography: {
                      sm: "body1",
                      xs: "body2",
                      lg: "subtitle1",
                      xl: "h6",
                    },
                  }}
                  component="h2"
                >
                  Start Time
                </Typography>
              </Grid>
              <Grid item xs={10} lg={9} xl={9}>
                {" "}
                <TimePicker
                  // minutesStep={'1'}
                  disabled
                  label=""
                  size="small"
                  sx={{
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: "none" },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    background: colors.color_primary,
                    width: "100%",
                  }}
                  value={dayjs(appointmentDetails?.startTime)}
                  onChange={(time) => {
                    setAppointmentDetails({
                      ...appointmentDetails,
                      startTime: time?.$d,
                    });
                  }}
                />
                {/* <TextField
                  disabled
                  label=""
                  size="small"
                  sx={{
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: "none" },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    background: colors.color_primary,
                    width: "100%",
                  }}
                  value={appointmentDetails?.startT}
                /> */}
              </Grid>
            </Grid>

            <Grid container item alignItems={"center"}>
              <Grid
                item
                alignItems={"baseline"}
                xs={2}
                lg={3}
                xl={3}
                alignContent={"baseline"}
              >
                <Typography
                  sx={{
                    typography: {
                      sm: "body1",
                      xs: "body2",
                      lg: "subtitle1",
                      xl: "h6",
                    },
                  }}
                  component="h2"
                >
                  End Time
                </Typography>
              </Grid>
              <Grid item xs={10} lg={9} xl={9}>
                {" "}
                <TimePicker
                  // minutesStep={1}
                  disabled
                  label=""
                  sx={{
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: "none" },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                      "& MuiInputBase-input-MuiOutlinedInput-input": {
                        padding: 0,
                        margin: 0,
                      },
                    },
                    background: colors.color_primary,
                    width: "100%",
                    padding: 0, // Set padding to 0
                    margin: 0, // Set margin to 0
                  }}
                  value={dayjs(appointmentDetails?.endTime)}
                  onChange={(time) => {
                    setAppointmentDetails({
                      ...appointmentDetails,
                      endTime: time?.$d,
                    });
                  }}
                />
                {/* <TextField
                  disabled
                  label=""
                  size="small"
                  sx={{
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: "none" },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    background: colors.color_primary,
                    width: "100%",
                  }}
                  value={appointmentDetails?.endT}
                /> */}
              </Grid>
            </Grid>

            <Grid container item alignItems={""}>
              <Divider />
            </Grid>
            <Grid container item alignItems={""}>
              <Grid
                item
                alignItems={"baseline"}
                xs={2}
                lg={3}
                xl={3}
                alignContent={"baseline"}
              >
                <Typography
                  sx={{
                    typography: {
                      sm: "body1",
                      xs: "body2",
                      lg: "subtitle1",
                      xl: "h6",
                    },
                  }}
                  component="h2"
                >
                  Reason
                  <Tooltip title="Reason Required">
                    <sup style={{ color: "red" }}>*</sup>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={10} lg={9} xl={9}>
                <TextField
                  fullWidth
                  type="text"
                  sx={{
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: "none" },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    background: colors.color_primary,
                    border: appointmentErrors?.reason
                      ? `1.5px solid ${colors?.color_error_txt}`
                      : "none",
                  }}
                  multiline
                  rows={3}
                  autoComplete="off"
                  autoCorrect="off"
                  autoFocus={true}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: colors.color_primary_txt, fontSize: 14 },
                  }}
                  InputProps={{
                    style: { color: colors.color_primary_txt, fontSize: 14 },
                  }}
                  value={appointmentDetails?.reason || ""}
                  onChange={(event) => {
                    setAppointmentDetails({
                      ...appointmentDetails,
                      reason: event?.target?.value,
                    });
                  }}
                  //   value={addPatient.dob}
                  placeholder="Enter Reason of Appointment"
                  inputProps={{
                    max: new Date().toISOString().split("T")[0], // Set max date to today's date
                  }}
                />
              </Grid>
            </Grid>

            <Grid container item alignItems={""}>
              {" "}
              <Divider />{" "}
            </Grid>

            <Grid
              container
              item
              justifyContent="center"
              alignItems={"center"}
              spacing={2}
            >
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  sx={{
                    color: colors?.color_primary_txt,
                    borderColor: colors?.color_primary_txt,
                    "&:hover": {
                      color: colors?.color_primary_txt,
                      borderColor: colors?.color_primary_txt,
                    },
                    textTransform: "none",
                    fontSize: 16,
                    width: "100%",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  disabled={createScheduleAppoinments.loading}
                  sx={{
                    backgroundColor: colors?.color_primary_txt,
                    color: colors?.color_active,
                    textTransform: "none",
                    "&:hover": {
                      color: colors?.color_active,
                      borderColor: colors?.color_primary_txt,
                      backgroundColor: colors?.color_primary_txt,
                    },
                    ":disabled": {
                      backgroundColor: colors?.color_primary_txt,
                      color: colors?.color_active,
                      opacity: 0.8,
                    },

                    fontSize: 16,
                    width: "100%",
                  }}
                  onClick={addAppointment}
                >
                  {createScheduleAppoinments.loading ? (
                    <>
                      <Spinner width={18} height={18} />
                      <span style={{ marginLeft: "6px" }}>Scheduling</span>
                    </>
                  ) : (
                    <span style={{ marginLeft: "6px" }}>Schedule</span>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>
    </Modal>
  );
};

export default memo(AppointmentModal);
