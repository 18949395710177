import {
  getClinicalInfoCmerTypes,
  updateClinicalInfoCmerTypes,
} from "../types";

const initialState = {
  loading: false,
  Consultant: [],
  DcDiagnosis: [],
  socialInfo: [],
  error: "",
};

export const getClinicalInfoCmerReducer = (state = initialState, action) => {
  switch (action.type) {
    case getClinicalInfoCmerTypes.GET_CLINICAL_INFO_CMER_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case getClinicalInfoCmerTypes.GET_CLINICAL_INFO_CMER_SUCCESS:
      return {
        ...initialState,
        Consultant: action.Consultant,
        DcDiagnosis: action.DcDiagnosis,
        socialInfo: action.socialInfo,
      };
    case getClinicalInfoCmerTypes.GET_CLINICAL_INFO_CMER_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    default:
      return state;
  }
};
const initialStateUpdate = {
  loading: false,
  message: "",
  error: "",
};

export const updateClinicalInfoCmerReducer = (
  state = initialStateUpdate,
  action
) => {
  switch (action.type) {
    case updateClinicalInfoCmerTypes.UPDATE_CLINICAL_INFO_CMER_REQUEST:
      return {
        ...initialStateUpdate,
        loading: true,
      };
    case updateClinicalInfoCmerTypes.UPDATE_CLINICAL_INFO_CMER_SUCCESS:
      return {
        ...initialStateUpdate,
        message: action.message,
      };
    case updateClinicalInfoCmerTypes.UPDATE_CLINICAL_INFO_CMER_FAILURE:
      return {
        ...initialStateUpdate,
        error: action.error,
      };
    case updateClinicalInfoCmerTypes.UPDATE_CLINICAL_INFO_CMER_RESET:
      return {
        ...initialStateUpdate,
      };

    default:
      return state;
  }
};
