export const pcpOfficeTypes = {
  //get PCP OFFICE

  PCP_OFFICE_REQUEST: "GETALL_DM_PCP_OFFICE_REQUEST",
  PCP_OFFICE_REQUEST_SUCCESS: "GETALL_DM_PCP_OFFICE_REQUEST_SUCCESS",
  PCP_OFFICE_REQUEST_FAILURE: "GETALL_DM_PCP_OFFICE_REQUEST_FAILURE",

  //SAVE PCP OFFICE

  PCP_OFFICE_SAVE_REQUEST: "DM_PCP_OFFICE_SAVE_REQUEST",
  PCP_OFFICE_SAVE_REQUEST_SUCCESS: "DM_PCP_OFFICE_SAVE_REQUEST_SUCCESS",
  PCP_OFFICE_SAVE_REQUEST_FAILURE: "DM_PCP_OFFICE_SAVE_REQUEST_FAILURE",

  //EDIT  PCP OFFICE

  PCP_OFFICE_EDIT_REQUEST: "DM_PCP_OFFICE_REQUEST_EDIT_REQUEST",
  PCP_OFFICE_EDIT_REQUEST_SUCCESS: "DM_PCP_OFFICE_EDIT_REQUEST_SUCCESS",
  PCP_OFFICE_EDIT_REQUEST_FAILURE: "DM_PCP_OFFICE_EDIT_REQUEST_FAILURE",

  //UPDATE PCP OFFICE

  PCP_OFFICE_UPDATE_STATUS_REQUEST: "DM_PCP_OFFICE_UPDATE_STATUS_REQUEST",
  PCP_OFFICE_UPDATE_STATUS_REQUEST_SUCCESS:
    "DM_PCP_OFFICE_UPDATE_STATUS_REQUEST_SUCCESS",
  PCP_OFFICE_UPDATE_STATUS_REQUEST_FAILURE:
    "DM_PCP_OFFICE_STATUS_REQUEST_FAILURE",

  //RESET

  PCP_OFFICE_REQUEST_RESET: "DM_PCP_OFFICE_REQUEST_RESET",
};
