import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { facilityActions } from "../actions";
import { facilityTypes } from "../types";

export function* watchDMFacility() {
  yield takeLatest(facilityTypes.FACILITY_REQUEST, getAllFacility);
}

function* getAllFacility(action) {
  try {
    let data = yield call(Api, {
      method: "POST",
      endpoint: endpoint.getDMFacilityData,
      jwt: "Bearer " + action.token,
    });
    let parsedData = JSON.parse(data);

    if (
      parsedData.FacilityList !== undefined &&
      parsedData.FacilityList.length > 0
    ) {
      yield put(
        facilityActions.facilityRequestSuccess(parsedData.FacilityList)
      );
    } else {
      yield put(facilityActions.facilityRequestFailure("No data found"));
    }
  } catch (error) {
    yield put(facilityActions.facilityRequestFailure(error));
  }
}
