import { put, call, takeLatest } from "@redux-saga/core/effects";
import { endpoint } from "../../../../api/Url";
import { allergyActions } from "../actions";
import { allergiesTypes } from "../types";
import { Api2 } from "../../../../api/Api2";
import { alertActions } from "../../../../components/AlertNew/store/AlertNew.action";
export function* watchAllergy() {
  yield takeLatest(allergiesTypes.GETALL_ALLERGY_REQUEST, getAllAllergies);
  yield takeLatest(
    allergiesTypes.OPERARTION_ALLERGY_REQUEST,
    operationAllergies
  );
}
function* getAllAllergies(action) {
  try {
    let data = yield call(Api2, {
      endpoint: `${endpoint.awvGetAllergiesDetails}?patientId=${action.request.patientId}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });

    yield put(allergyActions.allergyGetAllRequestSuccess(data?.allergy));
  } catch (error) {
    yield put(allergyActions.allergyGetAllRequestFailure(error));
  }
}
function* operationAllergies(action) {
  try {
    let message = yield call(Api2, {
      endpoint: `${endpoint.awvGetAllergiesDetails}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    yield put(allergyActions.allergyOperationRequestSuccess(message?.Message));
    yield put(alertActions.alertSuccessRequest(message?.Message));
  } catch (error) {
    yield put(allergyActions.allergyOperationRequestFailure(error));
  }
}
