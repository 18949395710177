import { pushNotificationType } from "../types/pushNotification.type";

export const pushNotificationReducer = (state = {}, action) => {
  switch (action.type) {
    case pushNotificationType.PUSH_NOTIFICATION_REQUEST:
      return { loading: true };
    case pushNotificationType.PUSH_NOTIFICATION_REQUEST_SUCCESS:
      return { message: action.message };
    case pushNotificationType.PUSH_NOTIFICATION_REQUEST_FAILURE:
      return { error: action.error };
    default:
      return state;
  }
};
