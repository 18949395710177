import React from "react";
import { default as ReactSelect } from "react-select";
import makeAnimated from "react-select/animated";

import styles from "./AutoComplete.module.scss";
import { colors } from "../../themes/colors";
export const AutoComplete = (props) => {
  const animatedComponents = makeAnimated();
  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: props.isDisabled
        ? colors.color_background
        : colors.color_primary,
      borderColor: colors.color_border,

      borderRadius: "0rem",
      maxWidth: "40vw",
      minWidth: "20vw",
      // height: "35px",
      margin: "0rem",
      padding: "0rem",
      // height: "3.4vh",

      outline: "none",
    }),
    menu: (provided, state) => ({
      ...provided,

      color: colors.color_primary_txt,

      background: colors.color_primary,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? colors.color_active
          : isFocused
          ? colors.color_active
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? colors.color_primary_txt
          : colors.color_primary_txt,
      };
    },
  };

  return (
    <div>
      <div className={styles.info_row}>
        {props.label && <p className={styles.label}>{props.label}:</p>}
        <div className={styles.value}>
          <ReactSelect
            components={animatedComponents}
            styles={customStyles}
            {...props}
          />
        </div>
      </div>
      {props.error && <p className={styles.error}>Required*</p>}
    </div>
  );
};
