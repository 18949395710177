import React from "react";
import {MdEdit,MdAdd} from "react-icons/md";
import { strings } from "./strings";
import styles from "./AddButton.module.scss";

const AddButton = ({ onClick, isActive, btnEventName, isEdit }) => {
  return (
    <button className={styles.btn_compose} onClick={onClick}>
      {isEdit ? (
        <MdEdit className={styles.btn_icon} />
      ) : (
        <MdAdd className={styles.btn_icon} />
      )}

      <h6 className={styles.btn_label}>
        {btnEventName}
      </h6>
    </button>
  );
};

export { AddButton };
