import { callHistoryTypes } from "../types";

export const callHistoryActions = {
  callHistoryRequest,
  callHistoryRequestSuccess,
  callHistoryRequestFailure,
};

function callHistoryRequest(request, token) {
  return {
    type: callHistoryTypes.CALL_HISTORY_REQUEST,
    request,
    token,
  };
}

function callHistoryRequestSuccess(callHistory) {
  return {
    type: callHistoryTypes.CALL_HISTORY_REQUEST_SUCCESS,
    callHistory,
  };
}

function callHistoryRequestFailure(error) {
  return {
    type: callHistoryTypes.CALL_HISTORY_REQUEST_FAILURE,
    error,
  };
}
