import { userReportsListTypes } from "../types/getUserReportsList.type";

const initialState = {
  loading: false,
  getReports: [],
};

export const userReportsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case userReportsListTypes.USER_REPORTSLIST_REQUEST:
      return { ...initialState, loading: true };
    case userReportsListTypes.USER_REPORTSLIST_REQUEST_SUCCESS:
      return { ...initialState, getReports: action.getReports };
    case userReportsListTypes.USER_REPORTSLIST_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};
