import React, { lazy, Suspense } from "react";
import { Global, css } from "@emotion/react";
import "react-big-calendar/lib/css/react-big-calendar.css";

import "./App.css";
import { useState, useEffect } from "react";

import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";

//pages
import Splash from "./pages/Splash";
//patients tab

import Error from "./pages/Error";

import NavContext from "./Context/NavContext";

import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line
import { onMessageListener } from "./firebase";

import { SkeletonLoading } from "./core";
import Loadable from "./components/Loadable";
import { PatientNav } from "./pages/Dynamic/PatientNav";
import { AlertBox } from "./components/AlertNew";
import BillingSkeleton from "./pages/attending/Billing/components/skeleton.jsx";
import ActivePatientDetails from "./pages/transitionCare/components/ActivePatientDetails.jsx";
import AppointmentCalendar from "./pages/transitionCare/components/Calender/index.jsx";
import { startIdleTimer } from "./util/activityTracking.js";
import { loginActions } from "./pages/Login/actions/login.action.js";
import { Alert, Snackbar } from "@mui/material";
import { colors } from "./themes/colors.js";
import { RiErrorWarningLine } from "react-icons/ri";

import Nursing from "./pages/nursing/Nursing.js";
import { useErrorBoundary } from "react-error-boundary";
import PatientUpdate from "./pages/adt/patient-update/PatientUpdate.jsx";
import moment from "moment";
import { timeZoneSet } from "./store/slice/TimeZone.slice.js";

const globalStyles = css`
  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }
  .MuiDataGrid-root .MuiDataGrid-cell {
    cursor: pointer;
  }
`;
const Login = Loadable(lazy(() => import("./pages/Login")));
// const ForgetPassword = Loadable(lazy(() => import("./pages/ForgetPassword")));
const ForgetPassword = Loadable(lazy(() => import("./pages/ForgetPassword")));
const ForgetPass = Loadable(lazy(() => import("./pages/ForgetPasswordNew")));

//for all role
const Container = Loadable(
  lazy(() => import("./components/Container/Container"))
);
const RightNavbar = Loadable(
  lazy(() => import("./components/RightNavbar/RightNavbar"))
);

const Dashboard = Loadable(lazy(() => import("./pages/Dashboard")));
// const Dashboardv2 = Loadable(lazy(() => import("./pages/Dashboardv2")));

// const Dashboardv2 = Loadable(lazy(() => import("./pages/Dashboardv2")));

// hospitalist role
const Hospitalist = Loadable(
  lazy(() => import("./pages/Hospitalist/Hospitalist.jsx"))
);
const AppointmentCalendarHospital = Loadable(
  lazy(() => import("./pages/Hospitalist/Calender/index.jsx"))
);
const Patients = Loadable(lazy(() => import("./pages/Patients")));
const Inbox = Loadable(lazy(() => import("./pages/Inbox")));
const Reports = Loadable(lazy(() => import("./pages/Reports")));

//for cm role
// const EditPatient = Loadable(lazy(() => import("./pages/cm/EditPatient")));

// const EditPatient = Loadable(lazy(() => import("./pages/cm/EditPatient")));
// const PatientChat = Loadable(lazy(() => import("./pages/PatientChat")));
//for tc role
// const TC = Loadable(lazy(() => import("./pages/tc")));

// const TCDashboard = Loadable(
//   lazy(() => import("./pages/Dashboardv2/TCDashboard/index"))
// );
//for cmd role
const CMD = Loadable(lazy(() => import("./pages/cmd")));
const EHRFiles = Loadable(lazy(() => import("./pages/cmd/EHRFiles")));

//for maw role
const Maw = Loadable(lazy(() => import("./pages/maw")));
//for pcp, mso, insurance role
const Patient = Loadable(lazy(() => import("./pages/Patient")));
//for dm role
const Lookup = Loadable(lazy(() => import("./pages/dm/Lookup")));
const Users = Loadable(lazy(() => import("./pages/dm/Users")));
const UserPermission = Loadable(
  lazy(() => import("./pages/dm/Userpermission"))
);

const Facility = Loadable(lazy(() => import("./pages/dm/Lookup/Facility")));
const PCP = Loadable(lazy(() => import("./pages/dm/Lookup/PCP")));
const Insurance = Loadable(lazy(() => import("./pages/dm/Lookup/Insurance")));
const MSO = Loadable(lazy(() => import("./pages/dm/Lookup/MSO")));
const Role = Loadable(lazy(() => import("./pages/dm/Lookup/Role")));
const CMPatientNav = Loadable(lazy(() => import("./pages/cm_New/PatientNav")));

// const PCPDashboard = Loadable(
//   lazy(() => import("./pages/Dashboardv2/PCPDashboard"))
// );
const InsurancePlan = Loadable(
  lazy(() => import("./pages/dm/Lookup/InsurancePlan"))
);
const Disposition = Loadable(
  lazy(() => import("./pages/dm/Lookup/Disposition"))
);
const SocialDeterminist = Loadable(
  lazy(() => import("./pages/dm/Lookup/SocialDeterminist"))
);
const BundleDisposition = Loadable(
  lazy(() => import("./pages/dm/Lookup/BundleDisposition"))
);
const ICD = Loadable(lazy(() => import("./pages/dm/Lookup/ICD")));

const RoutePermission = Loadable(lazy(() => import("./pages/dm/Lookup/Route")));
const Nation = Loadable(lazy(() => import("./pages/dm/Lookup/Nation")));
const Region = Loadable(lazy(() => import("./pages/dm/Lookup/Region")));
const ScreenPremission = Loadable(
  lazy(() => import("./pages/dm/Lookup/ScreenPermission"))
);
const IPA = Loadable(lazy(() => import("./pages/dm/Lookup/IPA")));

const PCPGroup = Loadable(lazy(() => import("./pages/dm/Lookup/PCPGroup")));

const PCPOffice = Loadable(lazy(() => import("./pages/dm/Lookup/PCPOffice")));

const PCPHierarchy = Loadable(
  lazy(() => import("./pages/dm/Lookup/PCPHierarchy"))
);
//hedis role
const Hedis = Loadable(lazy(() => import("./pages/hedis")));
//Receptionist role
const Receptionist = Loadable(lazy(() => import("./pages/receptionist")));
//coder role
const Coder = Loadable(lazy(() => import("./pages/coder")));
//Pharmacist role
// const VCPCP = Loadable(lazy(() => import("./pages/Pharmacist/VCPCP")));
const Pharmacist = Loadable(
  lazy(() => import("./pages/Pharmacist/pharma/components/Pharma.jsx"))
);

//Attending role
const Attending = Loadable(lazy(() => import("./pages/attending")));
const RvuMobile = Loadable(
  lazy(() => import("./pages/attendingNew/components/RVU.jsx"))
);
const RvuWeb = Loadable(
  lazy(() => import("./pages/attending/Billing/components/RvuReport.jsx"))
);

const AttendingNew = Loadable(lazy(() => import("./pages/attendingNew")));
// const ADT = Loadable(lazy(() => import("./pages/adt")));
const Charges = Loadable(lazy(() => import("./pages/adt")));
const HcaFaceSheets = Loadable(lazy(() => import("./pages/adt/HcaFaceSheets")));
const Duplicate = Loadable(
  lazy(() => import("./pages/adt/DuplicatePatientMerging"))
);
const TCTransitonCare = Loadable(
  lazy(() => import("./pages/transitionCare/components/TransitionCare.jsx"))
);
const CMER = Loadable(lazy(() => import("./pages/cmer/index.js")));
const TcAdmin = Loadable(lazy(() => import("./pages/TCAdmin")));

// ! Roles
const Referral = Loadable(lazy(() => import("./pages/referral/Referral.jsx")));

// const TcAdmin = Loadable(lazy(() => import("./pages/TCAdmin")));

const Signup = Loadable(lazy(() => import("./pages/signup")));

/* App component */
function App() {
  const navigate = useNavigate();
  const pathName = useLocation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.login.userData);
  const errorBoundryState = useSelector((state) => state.errorBoundryState);
  const { showBoundary } = useErrorBoundary();

  const patientBillingSkeleton = useSelector(
    (state) => state.patientBillingSkeleton
  );
  const timeZoneCurrent = useSelector((state) => state.timeZoneCurrent);

  const [nav, setNav] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    console.log(errorBoundryState);
    if (errorBoundryState.error) {
      showBoundary({ status: 500, message: "error" });
    }
  }, [errorBoundryState]);
  const value = { nav, setNav };
  // eslint-disable-next-line
  const [isTokenFound, setTokenFound] = useState(false);
  // eslint-disable-next-line
  const [show, setShow] = useState(false);
  // eslint-disable-next-line
  const [notification, setNotification] = useState({ title: "", body: "" });
  //getTokenFrom(setTokenFound);
  onMessageListener()
    .then((payload) => {
      setShow(true);
      if (window.Notification.permission === "granted") {
        navigator.serviceWorker.getRegistration().then(function (reg) {
          const notificationTitle = payload.notification.title;
          const notificationOptions = {
            body: payload.notification.body,
          };

          reg.showNotification(notificationTitle, notificationOptions);
          // console.log("Message received. ", payload);
        });
      }
    })
    .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    if (userData?.token !== undefined) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userData]);

  // useEffect to start the idle timer only if isLoggedIn is true
  useEffect(() => {
    if (isLoggedIn) {
      const stopIdleTimer = startIdleTimer(() => {
        if (userData?.token !== undefined) {
          dispatch(loginActions.logoutRequest());
          setIsSessionExpired(true);
        }
      });

      return () => {
        stopIdleTimer();
      };
    }
  }, [isLoggedIn, userData, dispatch]);
  const handleClose = () => {
    setIsSessionExpired(false);
    setIsLoggedIn(false);
  };
  // useEffect(() => {
  //   console.log(userData);
  //   if (
  //     userData !== undefined &&
  //     Object.entries(userData).length !== 0 &&
  //     (!userData?.IsMobileVerified ||
  //       !userData?.HasQuestions ||
  //       !userData?.IsEmailVerified)
  //   ) {
  //     // navigate(
  //     //   `/registration-complete/${userData?.UserName}/${userData?.Uuid}`
  //     // );
  //   } else if (
  //     Object.entries(userData).length === 0 &&
  //     !pathName.pathname.includes("registration-complete")
  //   ) {logoutre
  //     navigate("/");
  //   }
  // }, [userData]);

  useEffect(() => {
    console.log(userData);
    if (
      userData?.token === undefined &&
      !pathName.pathname.includes("registration-complete")
    ) {
      navigate("/");
    }
    // moment.tz.setDefault(timeZoneCurrent?.timeZone);

    let request = {
      zone: "America/Chicago",
    };
    dispatch(timeZoneSet(request));
  }, [userData]);
  return (
    <div className="App">
      {/* global level componentws for alert and session expired message  start*/}
      <AlertBox />
      <Snackbar
        open={isSessionExpired}
        // autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          width: { xs: "90%", md: 425, lg: 575, xxl: 625 },
          margin: "auto",
        }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{
            width: "100%",
            color: {
              xs: colors?.color_active,
              md: colors?.color_primary_txt,
            },
            backgroundColor: {
              xs: colors?.color_primary_txt,
              md: colors?.color_active,
            },
            p: { xs: 1, xl: 2 },
            m: { xs: 1, xl: 2 },
            fontSize: { xs: 12, md: 16 },
            textAlign: "center",
            boxShadow: "2px 5px 5px rgba(0, 0, 0, 0.2)",
            opacity: 0.3,
          }}
          icon={<RiErrorWarningLine fontSize="inherit" />}
        >
          Your session has expired. Please login again.{" "}
        </Alert>
      </Snackbar>

      {/* global level componentws for alert and session expired message  end*/}

      <Global styles={globalStyles} />
      {window.innerWidth <= 620 ? (
        <BillingSkeleton
          loading={patientBillingSkeleton.loading}
          message={patientBillingSkeleton.message}
        />
      ) : (
        ""
      )}
      <NavContext.Provider value={value}>
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<></>}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path={`/registration-complete/:loginId/:uuid`}
            element={
              <Suspense fallback={<SkeletonLoading />}>
                <Signup />
              </Suspense>
            }
          />
          <Route
            path="/forgetpassword"
            element={
              <Suspense fallback={<></>}>
                {/* <ForgetPassword /> */}
                <ForgetPass />
              </Suspense>
            }
          />
          {userData !== undefined &&
            userData?.IsMobileVerified &&
            userData?.HasQuestions &&
            userData?.IsEmailVerified && (
              <Route
                element={
                  <Suspense fallback={<Splash />}>
                    <Container stickyNav={<RightNavbar />} />
                  </Suspense>
                }
              >
                {userData !== undefined &&
                  (userData.RoleID === 4 || userData.RoleID === 2) && (
                    <Route path="/dashboard" element={<Dashboard />} />
                  )}
                {/* {userData !== undefined && userData.RoleID === 6 && (
              <>
                <Route
                  path="/dashboard"
                  element={
                    <Suspense fallback={<SkeletonLoading />}>
                      <TCDashboard />
                    </Suspense>
                  }
                />
              </>
            )} */}
                {userData !== undefined && userData.RoleID === 2 && (
                  <Route
                    path="/dashboard"
                    element={
                      <Suspense fallback={<SkeletonLoading />}>
                        <></>
                      </Suspense>
                    }
                  />
                )}

                {userData !== undefined &&
                  userData.RoleID !== 9 &&
                  userData.RoleID !== 58 &&
                  userData.RoleID !== 61 && (
                    <>
                      {/* <Route path="/dashboard" element={<Dashboard />} /> */}
                      {userData.RoleID !== 56 &&
                        userData.RoleID !== 57 &&
                        userData.RoleID !== 60 &&
                        userData.RoleID !== 6 && (
                          <>
                            {/* {userData.RoleID !== 5 && userData.RoleID !== 4 && (
                          <Route path="/dashboard" element={<Dashboard />} />
                        )} */}
                            <Route path="/report" element={<Reports />} />
                          </>
                        )}

                      <Route path="/inbox" element={<Inbox />} />

                      <Route path="/patients" element={<Patients />} />
                      {/* <Route path="/patients/:patientId" element={<Patient />} /> */}

                      {/* patientChat for pcp and cm only */}
                      {/* {userData !== undefined && userData.RoleID === 1 && (
                        <Route
                          path="/patientchats"
                          element={
                            <Suspense fallback={<SkeletonLoading />}>
                              <PatientChat />
                            </Suspense>
                          }
                        />
                      )} */}
                      {userData !== undefined && userData.RoleID === 68 && (
                        <>
                          <Route
                            path="/facility"
                            element={<TCTransitonCare />}
                          />
                          <Route
                            path="/facility/:patientId"
                            element={<Nursing />}
                          />
                          <Route
                            path="/facility/:patientId/schedule"
                            element={<AppointmentCalendar />}
                            // element={<AppointmentCalendarHospital />}
                          />
                          <Route
                            path="/facility/schedule"
                            // element={<AppointmentCalendar />}
                            element={<AppointmentCalendarHospital />}
                          />
                        </>
                      )}
                      {userData !== undefined && userData.RoleID === 69 && (
                        <>
                          <Route path="/referral" element={<Referral />} />
                          <Route path="/inbox" element={<Inbox />} />
                        </>
                      )}
                      {/* cm role */}
                      {userData !== undefined && userData.RoleID === 1 ? (
                        <>
                          {/* <Route
                            path="/patients/:patientId"
                            element={
                              <Suspense fallback={<SkeletonLoading />}>
                                <PatientNav />
                              </Suspense>
                            }
                          /> */}
                        </>
                      ) : // ) : userData !== undefined && userData.RoleID === 6 ? (
                      //   <Route
                      //     path="/patients/:patientId"
                      //     element={
                      //       <Suspense fallback={<SkeletonLoading />}>
                      //         <TC />
                      //       </Suspense>
                      //     }
                      //   />
                      userData !== undefined && userData.RoleID === 55 ? (
                        <Route
                          path="/patients/:patientId"
                          element={
                            <Suspense fallback={<SkeletonLoading />}>
                              <Maw />
                            </Suspense>
                          }
                        />
                      ) : userData !== undefined && userData.RoleID === 3 ? (
                        <>
                          <Route
                            path="/patients/:patientId"
                            element={
                              <Suspense fallback={<SkeletonLoading />}>
                                <CMD />
                              </Suspense>
                            }
                          />
                          <Route path="/ehrfiles" element={<EHRFiles />} />
                        </>
                      ) : userData !== undefined && userData.RoleID === 57 ? (
                        <Route
                          path="/patients/:patientId"
                          element={
                            <Suspense fallback={<SkeletonLoading />}>
                              <Coder />
                            </Suspense>
                          }
                        />
                      ) : userData !== undefined && userData.RoleID === 56 ? (
                        <>
                          <Route
                            path="/patients/:patientId/hedis"
                            element={
                              <Suspense fallback={<SkeletonLoading />}>
                                <Hedis index={0} />
                              </Suspense>
                            }
                          />
                          <Route
                            path="/patients/:patientId/questionnaire"
                            element={
                              <Suspense fallback={<SkeletonLoading />}>
                                <Hedis index={1} />
                              </Suspense>
                            }
                          />
                        </>
                      ) : (
                        <Route
                          path="/patients/:patientId"
                          element={
                            <Suspense fallback={<SkeletonLoading />}>
                              <Patient />
                            </Suspense>
                          }
                        />
                      )}

                      {/* <Redirect from="*" to="/error" /> */}
                    </>
                  )}
                {
                  //Pharmacist role
                  userData !== undefined && userData.RoleID === 58 && (
                    <>
                      {/* <Route path="/vc-pcp" element={<Pharmacist />} /> */}
                      <Route path="/pharmacist" element={<Pharmacist />} />
                      <Route path="/inbox" element={<Inbox />} />
                    </>
                  )
                }
                {userData !== undefined &&
                  (userData?.RoleID === 70 || userData?.RoleID === 71) && (
                    <>
                      <Route path="/hospital" element={<Hospitalist />} />
                      <Route
                        // path="/transitioncare/appointment"
                        // path="/appointment"
                        path="/hospital/:patientId/schedule"
                        element={<AppointmentCalendar />}
                      />
                      <Route
                        // path="/transitioncare/appointment"
                        // path="/appointment"
                        path="/hospital/schedule"
                        // element={<AppointmentCalendar />}
                        element={<AppointmentCalendarHospital />}
                      />
                    </>
                  )}
                {userData !== undefined && userData?.RoleID === 71 && (
                  <>
                    <Route path="/home-health" element={<Hospitalist />} />
                    <Route
                      // path="/transitioncare/appointment"
                      // path="/appointment"
                      path="/home-health/:patientId/schedule"
                      element={<AppointmentCalendar />}
                    />
                    <Route
                      // path="/transitioncare/appointment"
                      // path="/appointment"
                      path="/home-health/schedule"
                      // element={<AppointmentCalendar />}
                      element={<AppointmentCalendarHospital />}
                    />
                  </>
                )}
                {
                  //new tc role
                  userData !== undefined && userData.RoleID === 6 && (
                    <>
                      {/* <Route path="/vc-pcp" element={<Pharmacist />} /> */}
                      <Route
                        path="/transitioncare"
                        element={<TCTransitonCare />}
                      />
                      <Route
                        // path="/transitioncare/appointment"
                        // path="/appointment"
                        path="/transitioncare/:patientId/schedule"
                        element={<AppointmentCalendar />}
                      />
                      <Route
                        path="/transitioncare/:patientId"
                        element={<ActivePatientDetails />}
                      />

                      <Route path="/inbox" element={<Inbox />} />
                    </>
                  )
                }
                {/* Case Manage Role */}
                {userData !== undefined && userData.RoleID === 1 && (
                  <>
                    <Route path="/casemanager" element={<TCTransitonCare />} />

                    <Route
                      path="/casemanager/:patientId"
                      element={<CMPatientNav />}
                    />
                  </>
                )}
                {
                  //new tc role
                  userData !== undefined && userData.RoleID === 62 && (
                    <>
                      <Route path="/cmer" element={<CMER />} />
                      <Route
                        path="/cmer/:patientId"
                        element={
                          <Suspense fallback={<SkeletonLoading />}>
                            {/* <EditPatient /> */}
                            <PatientNav />
                          </Suspense>
                        }
                      />
                      <Route path="/inbox" element={<Inbox />} />
                    </>
                  )
                }
                {/* {
              //new tc admin role
              userData !== undefined && userData.RoleID === 63 && (
                <>
                  <Route path="/admin/dashboard" element={<Dashboard />} />

                  <Route path="/inbox" element={<Inbox />} />
                </>
              )
            } */}

                {userData.RoleID === 63 && (
                  <Route path="/admin/dashboard" element={<Dashboard />} />
                )}
                {/* dm role */}
                {userData !== undefined && userData.RoleID === 9 && (
                  <>
                    <Route
                      path="/lookup"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <Lookup />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/facility"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <Facility />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/pcp"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <PCP />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/insurance"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <Insurance />
                        </Suspense>
                      }
                    />

                    <Route
                      path="/lookup/mso"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <MSO />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/role"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <Role />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/insuranceplan"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <InsurancePlan />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/disposition"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <Disposition />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/socialdeterminants"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <SocialDeterminist />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/bundledisposition"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <BundleDisposition />
                        </Suspense>
                      }
                    />

                    <Route
                      path="/lookup/icd"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <ICD />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/route"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <RoutePermission />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/nation"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <Nation />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/region"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <Region />
                        </Suspense>
                      }
                    />

                    <Route
                      path="/lookup/user-screen-permission"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <ScreenPremission />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/ipa"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <IPA />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/pcpgroup"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <PCPGroup />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/pcpoffice"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <PCPOffice />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/pcphierarchy"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <PCPHierarchy />
                        </Suspense>
                      }
                    />

                    <Route path="/inbox" element={<Inbox />} />
                  </>
                )}
                {
                  //new tc role
                  userData !== undefined && userData.RoleID === 62 && (
                    <>
                      <Route path="/cmer" element={<CMER />} />
                      <Route
                        path="/cmer/:patientId"
                        element={
                          <Suspense fallback={<SkeletonLoading />}>
                            {/* <EditPatient /> */}
                            <PatientNav />
                          </Suspense>
                        }
                      />
                      <Route path="/inbox" element={<Inbox />} />
                    </>
                  )
                }
                {
                  //new tc role
                  userData !== undefined &&
                    (userData.RoleID === 65 || userData.RoleID === 67) && (
                      <>
                        <Route path="/reception" element={<Receptionist />} />
                        {/* <Route
                        path="/receptionist/:patientId"
                        element={
                          <Suspense fallback={<SkeletonLoading />}>
                            <PatientNav />
                          </Suspense>
                        }
                      />
                      <Route path="/inbox" element={<Inbox />} /> */}
                      </>
                    )
                }
                {/* {
              //new tc admin role
              userData !== undefined && userData.RoleID === 63 && (
                <>
                  <Route path="/admin/dashboard" element={<Dashboard />} />

                  <Route path="/inbox" element={<Inbox />} />
                </>
              )
            } */}

                {userData.RoleID === 63 ||
                  (userData.RoleID === 67 && (
                    <Route path="/admin/dashboard" element={<Dashboard />} />
                  ))}
                {/* dm role */}
                {userData !== undefined && userData.RoleID === 9 && (
                  <>
                    <Route
                      path="/lookup"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <Lookup />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/facility"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <Facility />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/pcp"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <PCP />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/insurance"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <Insurance />
                        </Suspense>
                      }
                    />

                    <Route
                      path="/lookup/mso"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <MSO />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/role"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <Role />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/insuranceplan"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <InsurancePlan />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/disposition"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <Disposition />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/socialdeterminants"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <SocialDeterminist />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/bundledisposition"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <BundleDisposition />
                        </Suspense>
                      }
                    />

                    <Route
                      path="/lookup/icd"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <ICD />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/route"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <RoutePermission />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/nation"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <Nation />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/region"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <Region />
                        </Suspense>
                      }
                    />

                    <Route
                      path="/lookup/user-screen-permission"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <ScreenPremission />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/ipa"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <IPA />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/pcpgroup"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <PCPGroup />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/pcpoffice"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <PCPOffice />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/lookup/pcphierarchy"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <PCPHierarchy />
                        </Suspense>
                      }
                    />

                    {/* <Route
                    {/* <Route
                    path="/lookup/pcpoffice"
                    element={
                      <Suspense fallback={<SkeletonLoading />}>
                        <PCPOffice />
                      </Suspense>
                    }
                  /> */}

                    <Route
                      path="/users"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <Users />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/userpermission"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <UserPermission />
                        </Suspense>
                      }
                    />
                  </>
                )}
                {/* for attending role */}
                {userData !== undefined && userData.RoleID === 60 && (
                  <>
                    <Route
                      path="/charges"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          {windowWidth > 620 ? <Attending /> : <AttendingNew />}
                        </Suspense>
                      }
                    />
                    <Route
                      path="/charges/rvu"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          {windowWidth > 620 ? <RvuWeb /> : <RvuMobile />}
                        </Suspense>
                      }
                    />
                  </>
                )}

                {userData !== undefined && userData.RoleID === 61 && (
                  <>
                    <Route
                      path="/fileprocess/charges"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <Charges />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/fileprocess/hie"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <Charges />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/patient-merge"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <Duplicate />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/hca-facesheets"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <HcaFaceSheets />
                        </Suspense>
                      }
                    />

                    <Route
                      path="/patient-update"
                      element={
                        <Suspense fallback={<SkeletonLoading />}>
                          <PatientUpdate />
                        </Suspense>
                      }
                    />
                  </>
                )}
                <Route path="*" element={<Navigate replace to={"/"} />} />
              </Route>
            )}

          <Route path="/error" element={<Error />} />
        </Routes>
      </NavContext.Provider>
    </div>
  );
}

export default App;
