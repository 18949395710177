import { getActivePatientMedicationType } from "../../types";

const initialState = {
  loading: false,
  getAll: [],
  error:''
};

export const getActivePatientMedicationReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getActivePatientMedicationType.GET_ACTIVE_PATIENT_MEDICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case getActivePatientMedicationType.GET_ACTIVE_PATIENT_MEDICATION_SUCCESS:
      
      return {
        ...state,
        getAll: action.getAll,
        loading: false,
      };
    case getActivePatientMedicationType.GET_ACTIVE_PATIENT_MEDICATION_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,

      };
    case getActivePatientMedicationType.GET_ACTIVE_PATIENT_MEDICATION_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
