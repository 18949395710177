import { getActivePatientTransitionType } from "../types";

const initialState = {
  loading: false,
  getAll: [],
  stay: [],
  otherData: null,
};

export const getActivePatientTCReducer = (state = initialState, action) => {
  switch (action.type) {
    case getActivePatientTransitionType.GETALL_ACTIVE_PATIENT_TC_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case getActivePatientTransitionType.GETALL_ACTIVE_PATIENT_TC_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
        stay: action.stay,
        otherData: action.otherData,
      };
    case getActivePatientTransitionType.GETALL_ACTIVE_PATIENT_TC_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case getActivePatientTransitionType.GETALL_ACTIVE_PATIENT_TC_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
