import { getAllRouteLookUpTransitionType } from "../../types";

export const getAllRouteLookUpTCActions = {
  getAllRouteLookUpRequest,
  getAllRouteLookUpSuccess,
  getAllRouteLookUpFailure,
};
function getAllRouteLookUpRequest( token) {
  return {
    type: getAllRouteLookUpTransitionType.GETALL_ROUTE_LOOKUP_TC_REQUEST,
    token,
  };
}
function getAllRouteLookUpSuccess(getAll) {
  return {
    type: getAllRouteLookUpTransitionType.GETALL_ROUTE_LOOKUP_TC_SUCCESS,
    getAll,
  };
}
function getAllRouteLookUpFailure(error) {
  return {
    type: getAllRouteLookUpTransitionType.GETALL_ROUTE_LOOKUP_TC_FAILURE,
    error,
  };
}
