export const strings = {
  //labels
  PATIENT_ID: "Patient ID",
  NAME: "Name",
  DOB: "DOB",
  ADDRESS: "Address",
  CONTACT: "Contact",
  ADMIT_FACILITY: "Facility",
  ADMIT_FACILITY_ID: "Facility ID",
  ADMIT_DATE: "Admission Date",
  PROVIDER: "Provider",
  PROVIDER_ID: "Provider ID",

  MRN: "MRN",
  DISCHARGE_DATE: "Discharge Date",
  DIPOSITION: "Diposition",
  ICD_10: "ICD-10 Codes",
  COMMENTS: "Comments",
  CM_NOTES: "Notes: ",
  DISPOSITION: "Disposition",
  CAREGIVER_DETAILS: "Caregiver details:",
  CAREGIVER_FNAME: "First Name",
  CAREGIVER_LNAME: "Last Name",
  CAREGIVER_CNUMBER: "Contact No.",
  RELATIONSHIP: "Relationship",

  INSURANCE_DETAILS: "Insurance details:",
  COMPANYNAME: "Company",
  PLAN: "Plan",

  PCP_DETAILS: "PCP details:",
  PCP: "Name",
  PCP_ADDRESS: "Address",
  PCP_PHONE: "Phone",
  EFFACTIVE_DATE: "Effective Date",
  HCC_SCORE: "HCC Score",
  FUNDING: "Funding",
  MLR: "MLR(%)",
  RISK_LVL: "Risk Level",

  PCP_ID: "PCP ID",

  CM_DETAILS: "CM details:",
  CM_ASSIGNED: "CMAssigned",
  CM_NAME: "CM Name",

  //UPDATE ALERT
  ALERT_UPDATE_SUCCESS: "Successfully updated!",
  ALERT_UPDATE_FAILURE: "Failed to update!",
};
