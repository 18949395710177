import { socialDeterministTypes } from "../types";

export const socialDeterministActions = {
  socialDeterministRequest,
  socialDeterministRequestSuccess,
  socialDeterministRequestFailure,
};
function socialDeterministRequest(request, token) {
  return {
    type: socialDeterministTypes.SOCIALDETERMINIST_REQUEST,
    request,
    token,
  };
}
function socialDeterministRequestSuccess(getAll) {
  return {
    type: socialDeterministTypes.SOCIALDETERMINIST_REQUEST_SUCCESS,
    getAll,
  };
}
function socialDeterministRequestFailure(error) {
  return {
    type: socialDeterministTypes.SOCIALDETERMINIST_REQUEST_FAILURE,
    error,
  };
}
