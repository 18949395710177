import { appointmentDetailsType } from "../types/appointmentDetails.type"; 

export const appointmentDetailsActions = {
  appointmentDetailsRequest,
  appointmentDetailsRequestSuccess,
  appointmentDetailsRequestFailure,
};

function appointmentDetailsRequest(request, token) {
  return { type: appointmentDetailsType.APPOINTMENT_DETAILS_REQUEST, request, token };
}

function appointmentDetailsRequestSuccess(appointments) {
  return { type: appointmentDetailsType.APPOINTMENT_DETAILS_REQUEST_SUCCESS, appointments };
}

function appointmentDetailsRequestFailure(error) {
  return { type: appointmentDetailsType.APPOINTMENT_DETAILS_REQUEST_FAILURE, error };
}
