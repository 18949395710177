import { regionTypes } from "../types/region.type";

const initialState = {
  loading: false,
  getAll: [],
  error: "",
  message: "",
};

export const regionDMReducer = (state = initialState, action) => {
  switch (action.type) {
    // getall regions
    case regionTypes.REGION_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case regionTypes.REGION_REQUEST_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
      };
    case regionTypes.REGION_REQUEST_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    //create regions
    case regionTypes.REGION_SAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case regionTypes.REGION_SAVE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        getAll: [...state.getAll],
      };
    case regionTypes.REGION_SAVE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    //edit region
    case regionTypes.REGION_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case regionTypes.REGION_EDIT_REQUEST_SUCCESS:
      let index = state.getAll.findIndex((item) => (item.RegionId = action.id));
      const newArr = [...state.getAll];
      const updatedRegion = { ...newArr[index], ...action.data };
      newArr[index] = updatedRegion;
      return {
        ...state,
        loading: false,
        message: action.message,
        getAll: newArr,
      };
    case regionTypes.REGION_EDIT_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    //Update status
    case regionTypes.REGION_UPDATE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case regionTypes.REGION_UPDATE_STATUS_REQUEST_SUCCESS:
      let index1 = state.getAll.findIndex(
        (item) => item.RegionId === action.id
      );
      const newArr1 = [...state.getAll]; //create  new array to store list
      const region = {
        ...newArr1[index1],
        active: !newArr1[index1].active,
      };
      newArr1[index1] = region;
      return {
        ...state,
        loading: false,
        message: action.message,
        getAll: newArr1,
      };
    case regionTypes.REGION_UPDATE_STATUS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case regionTypes.REGION_REQUEST_RESET:
      return { ...state, loading: false, message: " ", error: "" };
    default:
      return state;
  }
};
