import { takeLatest, put, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { updateInfoActions } from "../actions";
import { updateInfoTypes } from "../types";

export function* watchUpdateInfo() {
  yield takeLatest(updateInfoTypes.UPDATE_INFO_REQUEST, updateInfo);
}

function* updateInfo(action) {
  try {
    let message = yield call(Api, {
      endpoint: endpoint.updatePatientDetails,
      method: "POST",
      jwt: "bearer " + action.token,
      body: action.request,
    });

    let parsedMessage = JSON.parse(message);

    yield put(updateInfoActions.updateInfoRequestSuccess(parsedMessage[0]));
  } catch (error) {
    yield put(updateInfoActions.updateInfoRequestFailure(error));
  }
}
