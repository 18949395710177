import { patientChatMailboxTypes } from "../types/patientChatMailbox.type";

export const patientChatMailboxActions = {
  patientChatRequest,
  patientChatRequestSuccess,
  patientChatRequestFailure,
};

function patientChatRequest(token) {
  return { type: patientChatMailboxTypes.PATIENT_CHAT_MAILBOX_REQUEST, token };
}
function patientChatRequestSuccess(getAll) {
  return {
    type: patientChatMailboxTypes.PATIENT_CHAT_MAILBOX_REQUEST_SUCCESS,
    getAll,
  };
}
function patientChatRequestFailure(error) {
  return {
    type: patientChatMailboxTypes.PATIENT_CHAT_MAILBOX_REQUEST_FAILURE,
    error,
  };
}
