import { notificationsListType } from "../types/notificationsList.type";

export const notificationListReducer = (state = {}, action) => {
  switch (action.type) {
    case notificationsListType.NOTIFICATIONS_LIST_REQUEST:
      return { loading: true };
    case notificationsListType.NOTIFICATIONS_LIST_REQUEST_SUCCESS:
      return { notificationList: action.notificationList };
    case notificationsListType.NOTIFICATIONS_LIST_REQUEST_FAILURE:
      return { error: action.error };
    default:
      return state;
  }
};
