import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { bundleSaveTypes } from "../types";
import {bundleSaveActions} from "../actions";

export function* watchDMBundleDispositionOperation() {
    yield takeLatest(bundleSaveTypes.BUNDLE_SAVE_REQUEST, bundleSave);
}

function* bundleSave(action) {
    try {
        let data = yield call(Api, {
          method: "POST",
          endpoint: endpoint.saveDMBundleData,
          jwt: "Bearer " + action.token,
          body: action.request,
        });
        let parsedData = JSON.parse(data);

        if (parsedData.length > 0)
          yield put(bundleSaveActions.bundleSaveRequestSuccess(parsedData[0]));
        else {
          yield put(bundleSaveActions.bundleSaveRequestSuccess({ Result: "Error" }));
        }
      } catch (error) {
        yield put(bundleSaveActions.bundleSaveRequestFailure(error));
      }
}