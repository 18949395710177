import { chargesType } from "../types/charges.type";
export const chargesActions = {
  chargesRequest,
  chargesRequestSuccess,
  chargesRequestFailure,
};

function chargesRequest(request, token) {
  return { type: chargesType.CHARGES_REQUEST, request, token };
}
function chargesRequestSuccess(charges) {
  return {
    type: chargesType.CHARGES_REQUEST_SUCCESS,
    charges,
  };
}
function chargesRequestFailure(error) {
  return { type: chargesType.CHARGES_REQUEST_FAILURE, error };
}
