import { put, takeLatest, call } from "@redux-saga/core/effects";
import { assessmentActions } from "../actions";
import { assessmentTypes } from "../types";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";

export function* watchAssessment() {
  yield takeLatest(assessmentTypes.ASSESSMENT_REQUEST, getAssessment);
}

function* getAssessment(action) {
  try {
    const data = yield call(Api, {
      endpoint: `${endpoint.functionalAssessment}?PatientId=${action.request.patientId}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    const parsedData = JSON.parse(data);
    yield put(assessmentActions.assessmentRequestSuccess(parsedData));
  } catch (error) {
    yield put(assessmentActions.assessmentRequestFailure(error));
  }
}
