import { getallBillingType } from "../types";

export const getallBillingActions = {
  getallBillingRequest,
  getallBillingRequestSuccess,
  getallBillingRequestFailure,
};
function getallBillingRequest(request, token) {
  return {
    type: getallBillingType.GETALL_BILLING_REQUEST,
    request,
    token,
  };
}
function getallBillingRequestSuccess(getAll, phyData) {
  return {
    type: getallBillingType.GETALL_BILLING_REQUEST_SUCCESS,
    getAll,
    phyData,
  };
}
function getallBillingRequestFailure(error) {
  return {
    type: getallBillingType.GETALL_BILLING_REQUEST_FAILURE,
    error,
  };
}
