import { addUpdateUtilizationCMTypes } from "../types";

const initialState = {
  loading: false,
  message: "",
};

export const addUpdateUtilizationCMReducer = (state = initialState, action) => {
  switch (action.type) {
    case addUpdateUtilizationCMTypes.ADDUPDATE_UTILIZATION_CM_REQUEST:
      return {
        ...initialState,
        loading: true,
      };

    case addUpdateUtilizationCMTypes.ADDUPDATE_UTILIZATION_CM_SUCCESS:
      return {
        ...initialState,
        message: action.message,
      };
    case addUpdateUtilizationCMTypes.ADDUPDATE_UTILIZATION_CM_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case addUpdateUtilizationCMTypes.ADDUPDATE_UTILIZATION_CM_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
