import { powerbiEpisodicType } from "../types/powerbEpisodic.type";
const initalState = {
  reportLoading: false,
};

export const powerbiEpisodicReducer = (state = initalState, action) => {
  switch (action.type) {
    case powerbiEpisodicType.POWERBI_REQUEST:
      return { reportLoading: true };
    case powerbiEpisodicType.POWERBI_REQUEST_SUCCESS:
      return { reportData: action.reportData };
    case powerbiEpisodicType.POWERBI_REQUEST_FAILURE:
      return { error: action.error };
    default:
      return state;
  }
};
