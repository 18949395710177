import { replyMailTypes } from "../types/replyMail.type";

const initialState = {
  replying: false,
  message: "",
};

export const replyMailReducer = (state = initialState, action) => {
  switch (action.type) {
    case replyMailTypes.REPLY_MAIL_REQUEST:
      return { ...initialState, replying: true };
    case replyMailTypes.REPLY_MAIL_REQUEST_RESET:
      return { ...initialState };
    case replyMailTypes.REPLY_MAIL_REQUEST_SUCCESS:
      return { ...initialState, message: action.message };
    case replyMailTypes.REPLY_MAIL_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};
