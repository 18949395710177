import { carePlanTypes } from "../types/careplan.type";

export const carePlanActions = {
  getallCarePlanRequest,
  getallCarePlanRequestSuccess,
  getallCarePlanRequestFailure,
  addCarePlanRequest,
  addCarePlanRequestSuccess,
  addCarePlanRequestFailure,
  editCarePlanRequest,
  editCarePlanRequestSuccess,
  editCarePlanRequestFailure,
  resetCarePlanRequest,
  lookupCMDiagnosisRequest,
  lookupCMDiagnosisRequestSuccess,
  lookupCMDiagnosisRequestFailure,
  lookupCMGoalRequest,
  lookupCMGoalRequestSuccess,
  lookupCMGoalRequestFailure,
  lookupCMPatientResponsibilityRequest,
  lookupCMPatientResponsibilityRequestSuccess,
  lookupCMPatientResponsibilityRequestFailure,
  lookupCMInterventionRequest,
  lookupCMInterventionRequestSuccess,
  lookupCMInterventionRequestFailure,
  lookupCMFrequencyRequest,
  lookupCMFrequencyRequestSuccess,
  lookupCMFrequencyRequestFailure,
};

//get all care plan
function getallCarePlanRequest(request, token) {
  return { type: carePlanTypes.GETALL_CARE_PLAN_REQUEST, request, token };
}

function getallCarePlanRequestSuccess(getAll) {
  return {
    type: carePlanTypes.GETALL_CARE_PLAN_REQUEST_SUCCESS,
    getAll,
  };
}
function getallCarePlanRequestFailure(error) {
  return { type: carePlanTypes.GETALL_CARE_PLAN_REQUEST_FAILURE, error };
}

//add care plan
function addCarePlanRequest(params, request, token) {
  return { type: carePlanTypes.ADD_CARE_PLAN_REQUEST, params, request, token };
}

function addCarePlanRequestSuccess(message) {
  return {
    type: carePlanTypes.ADD_CARE_PLAN_REQUEST_SUCCESS,
    message,
  };
}
function addCarePlanRequestFailure(error) {
  return { type: carePlanTypes.ADD_CARE_PLAN_REQUEST_FAILURE, error };
}
//edit care plan
function editCarePlanRequest(id, request, token) {
  return { type: carePlanTypes.EDIT_CARE_PLAN_REQUEST, id, request, token };
}

function editCarePlanRequestSuccess(id, message) {
  return {
    type: carePlanTypes.EDIT_CARE_PLAN_REQUEST_SUCCESS,
    id,
    message,
  };
}
function editCarePlanRequestFailure(error) {
  return { type: carePlanTypes.EDIT_CARE_PLAN_REQUEST_FAILURE, error };
}
function resetCarePlanRequest() {
  return { type: carePlanTypes.RESET_CARE_PLAN };
}

//getall lookup diagnosis
function lookupCMDiagnosisRequest(request, token) {
  return { type: carePlanTypes.LOOKUP_CM_DIAGNOSIS, request, token };
}

function lookupCMDiagnosisRequestSuccess(getAll) {
  return {
    type: carePlanTypes.LOOKUP_CM_DIAGNOSIS_SUCCESS,
    getAll,
  };
}
function lookupCMDiagnosisRequestFailure(error) {
  return { type: carePlanTypes.LOOKUP_CM_DIAGNOSIS_FAILURE, error };
}

//getall lookup goals
function lookupCMGoalRequest(request, token) {
  return { type: carePlanTypes.LOOKUP_CM_GOAL, request, token };
}

function lookupCMGoalRequestSuccess(getAll) {
  return {
    type: carePlanTypes.LOOKUP_CM_GOAL_SUCCESS,
    getAll,
  };
}
function lookupCMGoalRequestFailure(error) {
  return { type: carePlanTypes.LOOKUP_CM_GOAL_FAILURE, error };
}
//getAll lookup patient responsibility
function lookupCMPatientResponsibilityRequest(request, token) {
  return {
    type: carePlanTypes.LOOKUP_CM_PATIENT_RESPONSIBILITY,
    request,
    token,
  };
}

function lookupCMPatientResponsibilityRequestSuccess(getAll) {
  return {
    type: carePlanTypes.LOOKUP_CM_PATIENT_RESPONSIBILITY_SUCCESS,
    getAll,
  };
}
function lookupCMPatientResponsibilityRequestFailure(error) {
  return {
    type: carePlanTypes.LOOKUP_CM_PATIENT_RESPONSIBILITY_FAILURE,
    error,
  };
}
//getAll lookup intervention
function lookupCMInterventionRequest(request, token) {
  return {
    type: carePlanTypes.LOOKUP_CM_INTERVENTION,
    request,
    token,
  };
}

function lookupCMInterventionRequestSuccess(getAll) {
  return {
    type: carePlanTypes.LOOKUP_CM_INTERVENTION_SUCCESS,
    getAll,
  };
}
function lookupCMInterventionRequestFailure(error) {
  return {
    type: carePlanTypes.LOOKUP_CM_INTERVENTION_FAILURE,
    error,
  };
}
//getAll lookup frequency
function lookupCMFrequencyRequest(token) {
  return {
    type: carePlanTypes.LOOKUP_CM_FREQUENCY,
    token,
  };
}

function lookupCMFrequencyRequestSuccess(getAll) {
  return {
    type: carePlanTypes.LOOKUP_CM_FREQUENCY_SUCCESS,
    getAll,
  };
}
function lookupCMFrequencyRequestFailure(error) {
  return {
    type: carePlanTypes.LOOKUP_CM_FREQUENCY_FAILURE,
    error,
  };
}
