//import:dependencies
import { fork, all } from "@redux-saga/core/effects";
//watchers
import { watchLogin } from "../pages/Login/sagas/login.saga";
import { watchPatients } from "../pages/Patients/sagas/patient.saga";
import { watchPages } from "./sagas/page.saga";
import { watchPowerBiReport } from "../pages/Dashboard/sagas/powerbiDashboard.saga";
import { watchPowerBiEpisodicReport } from "../pages/cm/EditPatient/EpisodicCost/sagas/powerbiEpisodic.saga";

import { watchFiltersList } from "../pages/Patients/sagas/filtersList.saga";
// import { watchPatientDetails } from "../pages/Patient/BasicInfo/sagas/patientDetails.saga";
import { watchPatientVisits } from "../pages/Patient/VisitDetails/sagas/visitDetails.saga";
// import { watchRedirect } from "../pages/Login/sagas/redirect.saga";

import { watchCharges } from "../pages/cm/EditPatient/Charges/sagas/charges.saga";
import {
  watchTasksDetails,
  watchAddTask,
} from "../pages/cm/EditPatient/TasksDetails/sagas";
import { watchAppointmentDetails } from "../pages/tc/Appointments/sagas/appointmentDetails.saga";
import {
  watchArchiveMail,
  watchSentMail,
  watchDeleteMail,
  watchContact,
  watchContactRole,
  watchInboxMailbox,
  watchArchiveMailbox,
  watchSentMailbox,
  watchPatientChatMailbox,
  watchReplyMail,
  watchMailDetails,
  watchPatientChatDetails,
  watchReplyPatientChatMail,
} from "../pages/Inbox/sagas";
import {
  watchClinicalInfo,
  watchClinicalInfoUpdate,
  watchAdvanceCarePlan,
} from "../pages/cm/EditPatient/Diagnosis/sagas";

//for cm role
import {
  watchBasicInfo,
  watchPhysician,
  watchFacility,
  watchRelationship,
  watchUpdateInfo,
  watchPcpsDetails,
} from "../pages/cm/EditPatient/BasicInfo/sagas";

import {
  watchCallActionsTaken,
  watchCallHistory,
  watchCallDisposition,
  watchCallHistorySave,
} from "../pages/cm/EditPatient/CallHistory/sagas";
import {
  watchBundleDisposition,
  watchChargesInput,
  watchChargesSave,
  watchDRGs,
  watchDeleteCharges,
} from "../pages/cm/EditPatient/Charges/sagas";

import { watchAddUpdateUtilization } from "../pages/cm/EditPatient/VisitDetails/sagas/addUpdateUtilization.saga";
import { watchPCPs } from "./sagas/pcp.saga";
import { watchCarePlan } from "../pages/cm/EditPatient/CarePlan/sagas/careplan.saga";
import { watchCronicCareManager } from "../pages/cm/EditPatient/CronicCareManager/sagas/cronicCareManager.saga";

//tc role
import { watchMedication } from "../pages/tc/Medication/sagas/medication.saga";
import { watchMedicationSave } from "../pages/tc/Medication/sagas/medicationSave.saga";
import { watchSetAppointment } from "../pages/tc/Appointments/sagas/setAppointment.saga";
import { watchDosageNRoute } from "../pages/tc/Medication/sagas/dosageNRoute.saga";
// import { watchDrug } from "../pages/tc/Medication/sagas/drug.saga";
import { watchFrequency } from "../pages/tc/Medication/sagas/frequency.saga";

import {
  watchTCPatientDetails,
  watchTCPatientDetailsUpdate,
} from "../pages/tc/PatientDetails/sagas";
import {
  watchDiagnosis,
  watchDiagnosisDetails,
  watchUpdateDiagnosis,
} from "../pages/tc/Diagnosis/sagas";

import {
  watchTCTasksDetails,
  watchReferralPlans,
  watchTCAddTask,
  watchCMUsers,
} from "../pages/tc/TasksDetails/sagas";

//for cmd
import {
  watchCMDPatientDetails,
  watchCMDUpdatePhoneNo,
} from "../pages/cmd/PatientDetails/sagas";
import // watchCMDCallHistory,
// watchCMDCallHistorySave,
"../pages/cmd/CallHistory/sagas";
import { watchCMDTasksDetails } from "../pages/cmd/TaskDetails/sagas/cmdTasksDetails.saga";
import { watchCMDAddTasksDetails } from "../pages/cmd/TaskDetails/sagas/cmdAddTasksDetails.saga";
import { watchCMDCMUsers } from "../pages/cmd/TaskDetails/sagas/cmdCmUsers.saga";
import { watchDeleteMedication } from "../pages/tc/Medication/sagas/deleteMedication.saga";
import { watchDeleteAppointment } from "../pages/tc/Appointments/sagas/deleteAppointment.saga";

//dm role
import {
  watchDMFacility,
  watchDMFacilityOperation,
} from "../pages/dm/Lookup/Facility/sagas";
import {
  watchDMInsurance,
  watchDMInsuranceDetails,
  watchDMInsuranceOperation,
} from "../pages/dm/Lookup/Insurance/sagas";
import {
  watchDMInsurancePlan,
  watchDMInsurancePlanDetails,
  watchDMInsurancePlanOperation,
} from "../pages/dm/Lookup/InsurancePlan/sagas";
import { watchDMMSO } from "../pages/dm/Lookup/MSO/sagas";
import {
  watchDMMsoDetails,
  watchDMMsoOperation,
} from "../pages/dm/Lookup/MSO/sagas";

import {
  watchDMPCP,
  watchDMPCPDetails,
  watchDMPCPOperation,
} from "../pages/dm/Lookup/PCP/sagas";
import { watchDMInsurancePlanCompney } from "../pages/dm/Lookup/InsurancePlan/sagas/insurancePlanCompney.saga";

import {
  watchDMROLE,
  watchDMRoleOperation,
} from "../pages/dm/Lookup/Role/sagas";
import {
  watchDMDisposition,
  watchDMDispositionDetails,
  watchDMDispositionOperation,
} from "../pages/dm/Lookup/Disposition/sagas";

import {
  watchDMBundleDisposition,
  watchDMBundleDispositionOperation,
} from "../pages/dm/Lookup/BundleDisposition/sagas";

import {
  watchDMSocialDeterminist,
  watchDMSocialDeterministDetails,
  watchDMSocialDeterministOperation,
} from "../pages/dm/Lookup/SocialDeterminist/sagas";
import { watchDMICD, watchDMIcdOperation } from "../pages/dm/Lookup/ICD/sagas";

import { watchDMRoute } from "../pages/dm/Lookup/Route/sagas/route.saga";
import { watchDMRegion } from "../pages/dm/Lookup/Region/sagas/region.saga";

//lookpup
import { watchLookupNation } from "../pages/dm/Lookup/Region/sagas/lookupNation.saga";

//User Manger
import {
  watchUserDetails,
  watchDMUserRole,
  watchDMUserOperation,
} from "../pages/dm/Users/sagas";

//notifications
import { watchPushNotificationDeviceSave } from "./sagas/pushNotification.sagas";
import { watchGetNotificationsList } from "../components/RightNavbar/Submenus/sagas/notificationsList.sagas";
//Screening assessment
import {
  watchAssessment,
  watchGetAssessment,
  watchSaveDynamicGait,
} from "../pages/cm/EditPatient/Assessments/sagas";

//Reports
import {
  watchUserReportsList,
  watchGetReportsData,
} from "../pages/Reports/sagas";
import { watchPatientMessage } from "../pages/cm/EditPatient/patientMessage.saga";

import { watchPatientMessageList } from "../pages/PatientChat/sagas/messageList.saga";
import { watchPatientConversationList } from "../pages/PatientChat/sagas/converastionList.saga";
import { watchPatientSendMessage } from "../pages/PatientChat/sagas/sendMessage.saga";
import { watchForgetPassword } from "../pages/ForgetPassword/sagas";
import { watchEhrFileUpload } from "../pages/cmd/EHRFiles/sagas/ehrFileUpload.saga";
import { watchAdvanceDirective } from "../pages/maw/AdvanceDirective/sagas/advanceDirective.saga";
import { watchAllergy } from "../pages/maw/Allergies/sagas";
import { watchImmunization } from "../pages/maw/Immunization/sagas";
import { watchSurgicalHistory } from "../pages/maw/SurgicalHistory/sagas/surgicalHistory.saga";
import { watchHospitalization } from "../pages/maw/Hospitalization/sagas";
import { watchBiometricMeasure } from "../pages/maw/BiometricMeasurments/sagas/biometricMeasure.saga";
import { watchProblemList } from "../pages/maw/ProblemList/sagas";
import { watchScreenPrevent } from "../pages/maw/ScreeningPreventiveExam/sagas";
import { watchLabTests } from "../pages/maw/LabTests/sagas";
import { watchEducationProvided } from "../pages/maw/EducationProvided/sagas";
import { watchAssessmentsReferrals } from "../pages/maw/AssessmentsReferrals/sagas";
import { watchRecommendations } from "../pages/maw/Recommendations/sagas";
import { watchFamilyHistory } from "../pages/maw/FamilyHistory/sagas";
import { watchPrint } from "../pages/maw/PrintReport/sagas";
import { watchDashboardReport } from "../pages/Dashboardv2/sagas";
import { watchHedisProcedure } from "../pages/hedis/HedisProcedure/sagas/hedis.saga";

//maw
import { watchComprehensiveAssessment } from "../pages/maw/ComprehensiveSystemAssessment/sagas/comprehensive.saga";
import { watchDMNation } from "../pages/dm/Lookup/Nation/sagas/nation.saga";
import { watchLookups } from "./sagas/lookup.saga";
import { watchDMIpa } from "../pages/dm/Lookup/IPA/sagas/ipa.saga";
import { watchDMPCPGroup } from "../pages/dm/Lookup/PCPGroup/sagas/pcpgroup.saga";
import { watchDMPCPOffice } from "../pages/dm/Lookup/PCPOffice/sagas/pcpoffice.saga";
import { watchDMPCPHierarchy } from "../pages/dm/Lookup/PCPHierarchy/sagas/pcphierarchy.saga";
import { watchDMUserPermission } from "../pages/dm/Userpermission/sagas/userpermission.saga";
import { watchPermissions } from "./sagas/permissions.saga";

//attending
import { watchPatientBilling } from "../pages/attending/Billing/sagas/billing.saga";

//adt
import { watchFileProcessing } from "../pages/adt/FileProcessing/store/sagas/adtFileProcessing.saga";
//duplicate patient merging
import { watchDuplicatePatientsMerging } from "../pages/adt/DuplicatePatientMerging/sagas/duplicatePatients.saga";

//pharma
import { watchDischargePatientPharma } from "../pages/Pharmacist/pharma/saga/pharma.saga";

//transition care
import { watchActivePatientTC } from "../pages/transitionCare/saga/transitionCare.saga";
import { watchHieFileProcessing } from "../pages/adt/HieFileProcessing/store/sagas/HieFileProcessing.saga";

//CMER;
import { watchCmerPatient } from "../pages/cmer/sagas/cmer.saga";
import {
  watchCmerAddTask,
  watchCmerTasksDetails,
} from "../pages/cmer/TaskDetails/sagas";
import { watchCallHistoryCmer } from "../pages/cmer/CallHistoryCmer/sagas";
import { watchCmerClinicalInformation } from "../pages/cmer/ClinicalInformation/sagas/clinicalInformation.saga";
import { watchRoleLookupDm } from "../pages/dm/Users/sagas/roleLookup.saga";
import { watchReceptionPatients } from "../pages/receptionist/sagas/patient.saga";
// import { watchCallHistoryCmer } from "../pages/cmer/CallHistoryCmer/sagas/callActionTakenLookup.saga";
// import { watchBillingNew } from "../pages/attendingNew/sagas/billing.saga";
import { watchDynamicLookupData } from "./sagas/dynamicLookup.saga";

//facesheet
import { watchFacesheet } from "../pages/adt/HcaFaceSheets/store/sagas/facesheet.saga";
import { watchCallHistoryCmRole } from "../pages/cm_New/CallHistoryCmRole/sagas";
import { watchCmAddTask } from "../pages/cm_New/TaskDetails/sagas/addTask.saga";
import { watchCmTasksDetails } from "../pages/cm_New/TaskDetails/sagas/tasksDetails.saga";
import { watchClinicalInformationCM } from "../pages/cm_New/ClinicalInformationCM/sagas/clinicalInformation.saga";
import { watchCMPatient } from "../pages/cm_New/store/sagas/cmer.saga";
import { watchHospitalAdmissionPatientVisitsCM } from "../pages/cm_New/HospitalAdmissions/sagas/visitDetails.saga";
import { watchAddUpdateUtilizationCM } from "../pages/cm_New/HospitalAdmissions/sagas/addUpdateUtilization.saga";
import { watchFacilityLookupCM } from "../pages/cm_New/HospitalAdmissions/sagas/facilityLookup.saga";
import { watchNursingInitialAssessment } from "../pages/nursing/store/saga/initialAssessment.saga";
import { watchNursingProgress } from "../pages/nursing/store/saga/progress.saga";
import { watchReferralRole } from "../pages/referral/Referralstore/Referral.saga";
import { watchHospitalist } from "../pages/Hospitalist/store/saga/hospitalist.saga";
import { watchPatientUpdate } from "../pages/adt/patient-update/patientUpdate.store/saga/patientUpdate.saga";

//export sagas: Root Saga

export function* rootSaga() {
  //yield all([fork(watcher)]);
  yield all([fork(watchLogin), fork(watchPermissions)]);
  yield all([fork(watchPatients)]);
  yield all([fork(watchPages)]);
  yield all([fork(watchPowerBiReport)]);
  yield all([fork(watchDashboardReport)]);
  yield all([fork(watchPowerBiEpisodicReport)]);
  yield all([fork(watchAddUpdateUtilization)]);

  yield all([fork(watchFiltersList)]);
  // yield all([fork(watchPatientDetails)]);
  yield all([fork(watchPatientVisits)]);

  // yield all([fork(watchRedirect)]);

  yield all([fork(watchCharges)]);
  //inbox
  yield all([fork(watchContact)]);
  yield all([fork(watchContactRole)]);
  yield all([fork(watchSentMail)]);
  yield all([fork(watchReplyMail)]);
  yield all([fork(watchArchiveMail)]);
  yield all([fork(watchDeleteMail)]);
  yield all([fork(watchSentMailbox)]);
  yield all([fork(watchInboxMailbox)]);
  yield all([fork(watchArchiveMailbox)]);
  yield all([fork(watchMailDetails)]);

  yield all([fork(watchPatientChatMailbox)]);
  yield all([fork(watchPatientChatDetails)]);

  //for cm
  yield all([fork(watchBasicInfo)]);
  yield all([fork(watchPhysician)]);
  yield all([fork(watchFacility)]);
  yield all([fork(watchRelationship)]);
  yield all([fork(watchTasksDetails)]);
  yield all([fork(watchCallHistory)]);
  yield all([fork(watchCallActionsTaken)]);
  yield all([fork(watchCallDisposition)]);
  yield all([fork(watchCallHistorySave)]);
  yield all([fork(watchUpdateInfo)]);
  yield all([fork(watchAddTask)]);
  yield all([fork(watchBundleDisposition)]);
  yield all([fork(watchDeleteCharges)]);
  yield all([fork(watchDeleteMedication)]);
  yield all([fork(watchPcpsDetails)]);

  yield all([fork(watchClinicalInfoUpdate)]);

  yield all([fork(watchPatientMessage)]);

  yield all([fork(watchCarePlan)]);

  yield all([fork(watchCronicCareManager)]);
  // for tc
  yield all([fork(watchMedication)]);
  yield all([fork(watchClinicalInfo)]);
  yield all([fork(watchMedicationSave)]);
  yield all([fork(watchSetAppointment)]);
  yield all([fork(watchDosageNRoute)]);
  // yield all([fork(watchDrug)]);
  yield all([fork(watchFrequency)]);
  yield all([fork(watchTCPatientDetails)]);
  yield all([fork(watchTCPatientDetailsUpdate)]);
  yield all([fork(watchUpdateDiagnosis)]);

  yield all([fork(watchChargesInput)]);
  yield all([fork(watchChargesSave)]);
  yield all([fork(watchAppointmentDetails)]);
  yield all([fork(watchDiagnosisDetails)]);

  //lookuo
  yield all([fork(watchPCPs)]);
  yield all([fork(watchDiagnosis)]);
  yield all([fork(watchDRGs)]);
  yield all([fork(watchAdvanceCarePlan)]);
  //task details for tc
  yield all([fork(watchTCTasksDetails)]);
  yield all([fork(watchReferralPlans)]);
  yield all([fork(watchTCAddTask)]);
  yield all([fork(watchCMUsers)]);

  //cmd role
  yield all([fork(watchCMDPatientDetails)]);
  // yield all([fork(watchCMDCallHistory)]);
  yield all([fork(watchCMDUpdatePhoneNo)]);
  // yield all([fork(watchCMDCallHistorySave)]);
  yield all([fork(watchCMDTasksDetails)]);
  yield all([fork(watchCMDAddTasksDetails)]);
  yield all([fork(watchCMDCMUsers)]);
  yield all([fork(watchDeleteAppointment)]);

  //dm role
  yield all([fork(watchDMFacility)]);
  yield all([fork(watchDMFacilityOperation)]);

  yield all([fork(watchDMInsurance)]);
  yield all([fork(watchDMInsuranceDetails)]);
  yield all([fork(watchDMInsuranceOperation)]);

  yield all([fork(watchDMInsurancePlan)]);
  yield all([fork(watchDMInsurancePlanCompney)]);
  yield all([fork(watchDMInsurancePlanDetails)]);
  yield all([fork(watchDMInsurancePlanOperation)]);

  yield all([fork(watchDMPCP)]);
  yield all([fork(watchDMPCPDetails)]);
  yield all([fork(watchDMPCPOperation)]);

  yield all([fork(watchDMMSO)]);
  yield all([fork(watchDMMsoDetails)]);
  yield all([fork(watchDMMsoOperation)]);
  yield all([fork(watchDMROLE)]);
  yield all([fork(watchDMRoleOperation)]);
  yield all([fork(watchDMBundleDisposition)]);

  yield all([fork(watchDMDisposition)]);
  yield all([fork(watchDMDispositionDetails)]);
  yield all([fork(watchDMDispositionOperation)]);
  yield all([fork(watchDMBundleDispositionOperation)]);
  yield all([fork(watchDMICD)]);
  yield all([fork(watchDMIcdOperation)]);

  yield all([fork(watchDMSocialDeterminist)]);
  yield all([fork(watchDMSocialDeterministDetails)]);
  yield all([fork(watchDMSocialDeterministOperation)]);
  //dm role pcp hierachy
  yield all([fork(watchDMRoute)]);
  yield all([fork(watchDMNation)]);
  yield all([fork(watchDMRegion)]);
  yield all([
    fork(watchLookupNation),
    fork(watchLookups),
    fork(watchDMIpa),
    fork(watchDMPCPGroup),
    fork(watchDMPCPOffice),
    fork(watchDMPCPHierarchy),
    fork(watchDMUserPermission),
  ]);

  //User Manager
  yield all([fork(watchUserDetails)]);
  yield all([fork(watchRoleLookupDm)]);
  yield all([fork(watchDMUserRole)]);
  yield all([fork(watchDMUserOperation)]);

  //notification
  yield all([fork(watchPushNotificationDeviceSave)]);
  yield all([fork(watchGetNotificationsList)]);

  //screening assessment
  yield all([fork(watchAssessment)]);
  yield all([fork(watchGetAssessment)]);
  yield all([fork(watchSaveDynamicGait)]);
  // yield all([fork(watchSaveBergBalanceScale)]);
  // yield all([fork(watchSaveDepressionAssessment)]);
  // yield all([fork(watchSaveNutritionalAssessment)]);
  // yield all([fork(watchSaveADL)]);
  // yield all([fork(watchSaveIADL)]);
  yield all([fork(watchUserReportsList)]);
  yield all([fork(watchGetReportsData)]);

  //Patient Chats
  yield all([fork(watchPatientMessageList)]);
  yield all([fork(watchPatientConversationList)]);
  yield all([fork(watchPatientSendMessage)]);
  yield all([fork(watchReplyPatientChatMail)]);

  //forget password
  yield all([fork(watchForgetPassword)]);

  yield all([fork(watchEhrFileUpload)]);

  yield all([fork(watchEhrFileUpload)]);

  yield all([fork(watchAdvanceDirective)]);
  yield all([fork(watchAllergy)]);
  yield all([fork(watchImmunization)]);
  yield all([fork(watchSurgicalHistory)]);
  yield all([fork(watchHospitalization)]);
  yield all([fork(watchBiometricMeasure)]);
  yield all([fork(watchProblemList)]);
  yield all([fork(watchScreenPrevent)]);
  yield all([fork(watchLabTests)]);
  yield all([fork(watchEducationProvided)]);
  yield all([fork(watchAssessmentsReferrals)]);
  yield all([fork(watchRecommendations)]);
  yield all([fork(watchFamilyHistory)]);
  yield all([fork(watchPrint)]);

  //hedis role
  yield all([fork(watchHedisProcedure)]);

  //maw
  yield all([fork(watchComprehensiveAssessment)]);

  //lookups
  yield all([fork(watchPatientBilling)]);
  // yield all([fork(watchBilling), fork(watchBillingNew)]);

  //ADT
  yield all([fork(watchFileProcessing)]); //get file data
  yield all([fork(watchHieFileProcessing)]); //get file data
  yield all([fork(watchFacesheet)]); //facesheet

  // Patient Update In BPO
  yield all([fork(watchPatientUpdate)]);

  // Duplicate Patients
  yield all([fork(watchDuplicatePatientsMerging)]);
  // Pharmacist
  yield all([fork(watchDischargePatientPharma)]);

  //transition
  yield all([fork(watchActivePatientTC)]);

  //CMER
  yield all([fork(watchCmerPatient)]);
  yield all([fork(watchCmerAddTask)]);
  yield all([fork(watchCmerTasksDetails)]);
  yield all([fork(watchCmerClinicalInformation)]);
  yield all([fork(watchAddUpdateUtilizationCM)]);

  // CMROle
  yield all([fork(watchCallHistoryCmRole)]);
  yield all([fork(watchCmAddTask)]);
  yield all([fork(watchClinicalInformationCM)]);
  //! CM ROle patient Details
  yield all([fork(watchCMPatient)]);
  yield all([fork(watchCallHistoryCmer)]);
  yield all([fork(watchHospitalAdmissionPatientVisitsCM)]);
  yield all([fork(watchFacilityLookupCM)]);
  //! CMROle call histor
  yield all([fork(watchCmTasksDetails)]);
  // reception patients
  yield all([fork(watchReceptionPatients)]);
  yield all([fork(watchDynamicLookupData)]);

  // Nursing/Facility Role
  yield all([fork(watchNursingInitialAssessment)]);
  yield all([fork(watchNursingProgress)]);

  // Referral Role
  yield all([fork(watchReferralRole)]);

  // Hospitalist Role
  yield all([fork(watchHospitalist)]);
}
