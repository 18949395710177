import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { socialDeterministActions } from "../actions";
import { socialDeterministTypes } from "../types";

export function* watchDMSocialDeterminist() {
  yield takeLatest(
    socialDeterministTypes.SOCIALDETERMINIST_REQUEST,
    getAllSocialDeterminist
  );
}

function* getAllSocialDeterminist(action) {
  try {
    let data = yield call(Api, {
      method: "POST",
      endpoint: endpoint.getAllDMSocialDeterminantsData,
      jwt: "Bearer " + action.token,
    });
    let parsedData = JSON.parse(data);

    yield put(
      socialDeterministActions.socialDeterministRequestSuccess(
        parsedData.SocialDeterminantsList
      )
    );
  } catch (error) {
    yield put(socialDeterministActions.socialDeterministRequestFailure(error));
  }
}
