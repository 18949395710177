import { labTestsTypes } from "../types";

const initialGetState = {
  loading: false,
  get: [],
  error: "",
};
export const labTestsGetReducer = (state = initialGetState, action) => {
  switch (action.type) {
    case labTestsTypes.LABTESTS_GET_REQUEST:
      return { ...initialGetState, loading: true };
    case labTestsTypes.LABTESTS_GET_REQUEST_SUCCESS:
      return {
        ...initialGetState,
        get: action.get,
      };
    case labTestsTypes.LABTESTS_GET_REQUEST_FAILURE:
      return { ...initialGetState, error: action.error };
    default:
      return state;
  }
};

const initialOpState = {
  loading: false,
  message: "",
  error: "",
};
export const labTestsOperationReducer = (state = initialOpState, action) => {
  switch (action.type) {
    case labTestsTypes.LABTESTS_OPERATION_REQUEST:
      return { ...initialOpState, loading: true };
    case labTestsTypes.LABTESTS_OPERATION_REQUEST_SUCCESS:
      return { ...initialOpState, message: action.message };
    case labTestsTypes.LABTESTS_OPERATION_REQUEST_FAILURE:
      return { ...initialOpState, error: action.error };
    case labTestsTypes.LABTESTS_OPERATION_REQUEST_RESET:
      return { ...initialOpState };
    default:
      return state;
  }
};
