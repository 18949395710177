import { getHieFileDataType } from "../type/getHieFileData.type";

export const getHieFileDataActions = {
  getHieFileDataRequest,
  getHieFileDataSuccess,
  getHieFileDataFailure,
  getHieFileDataReset,
};

function getHieFileDataRequest(query, request, token) {
  return {
    type: getHieFileDataType.GET_HIE_FILE_DATA_REQUEST,
    query,
    request,
    token,
  };
}

function getHieFileDataSuccess(data, totalrecords) {
  return {
    type: getHieFileDataType.GET_HIE_FILE_DATA_SUCCESS,
    data,
    totalrecords,
  };
}

function getHieFileDataFailure(error) {
  return {
    type: getHieFileDataType.GET_HIE_FILE_DATA_FAILURE,
    error,
  };
}

function getHieFileDataReset() {
  return {
    type: getHieFileDataType.GET_HIE_FILE_DATA_REQUEST,
  };
}
