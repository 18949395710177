import { call, debounce, put, takeLatest } from "redux-saga/effects";
import {
  getPatientListForUpdateFailure,
  getPatientListForUpdateSuccess,
} from "../slice/getPatientList.slice";
import { endpoint } from "../../../../../api/Url";
import { Api2 } from "../../../../../api/Api2";
import {
  patientUpdateFaliure,
  patientUpdateSuccess,
} from "../slice/patientUpdate.slice";

export function* watchPatientUpdate() {
  yield debounce(
    500,
    "getPatientListForUpdate/getPatientListForUpdateRequest",
    getAllPatients
  );
  yield takeLatest("patientUpdate/patientUpdateRequest", updatePatient);
}
function* getAllPatients(action) {
  console.log(action, "actionaction");
  try {
    const response = yield call(Api2, {
      endpoint: `${endpoint.getPatientListForUpdate}?dos=${
        action.payload.dos
      }&patientId=${""}`,
      method: "GET",
      jwt: `Bearer ${action.payload.token}`,
    });

    yield put(getPatientListForUpdateSuccess(response));
  } catch (error) {
    yield put(getPatientListForUpdateFailure(error));
  }
}

function* updatePatient(action) {
  let { token, ...body } = action.payload;
  try {
    const response = yield call(Api2, {
      endpoint: `${endpoint.getPatientListForUpdate}`,
      method: "POST",
      jwt: `Bearer ${token}`,
      body: body,
    });

    console.log(response);
    yield put(patientUpdateSuccess(response));
  } catch (error) {
    yield put(patientUpdateFaliure(error));
  }
}
