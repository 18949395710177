import { roleLookupDmTypes } from "../types";

export const roleLookupDmActions = {
  roleLookupDmRequest,
  roleLookupDmSuccess,
  roleLookupDmFailure,
};
function roleLookupDmRequest(token) {
  return {
    type: roleLookupDmTypes.ROLE_LOOKUP_DM_REQUEST,
    token,
  };
}
function roleLookupDmSuccess(getAll) {
  return {
    type: roleLookupDmTypes.ROLE_LOOKUP_DM_SUCCESS,
    getAll,
  };
}
function roleLookupDmFailure(error) {
  return {
    type: roleLookupDmTypes.ROLE_LOOKUP_DM_FAILURE,
    error,
  };
}
