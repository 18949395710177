import { getAllRegionsType } from "../../types";

export const getAllRegionsTCActions = {
  getallRegionsRequest,
  getallRegionsSuccess,
  getallRegionsFailure,
};
function getallRegionsRequest(query, token) {
  return {
    type: getAllRegionsType.GETALL_REGION_TC_REQUEST,
    query,
    token,
  };
}
function getallRegionsSuccess(getAll) {
  return {
    type: getAllRegionsType.GETALL_REGION_TC_SUCCESS,
    getAll,
  };
}
function getallRegionsFailure(error) {
  return {
    type: getAllRegionsType.GETALL_REGION_TC_FAILURE,
    error,
  };
}
