import { setAppointmentType } from "../types/setAppointment.type";

const initialState = {
  saving: false,
};

export const setAppointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case setAppointmentType.SET_APPOINTMENT_REQUEST:
      return { ...initialState, saving: true };
    case setAppointmentType.SET_APPOINTMENT_REQUEST_SUCCESS:
      return { ...initialState, message: action.message };
    case setAppointmentType.SET_APPOINTMENT_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    case setAppointmentType.SET_APPOINTMENT_REQUEST_RESET:
      return initialState;
    default:
      return state;
  }
};
