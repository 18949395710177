import { cronicCareManagerTypes } from "../types/croniccaremanager.type";

//lookup notes
const initialNotesState = {
  loading: false,
  getAll: [],
  error: "",
};

export const lookupNotesReducer = (state = initialNotesState, action) => {
  switch (action.type) {
    case cronicCareManagerTypes.LOOKUP_NOTE_REQUEST:
      return { ...initialNotesState, loading: true };
    case cronicCareManagerTypes.LOOKUP_NOTE_REQUEST_SUCCESS:
      return { ...initialNotesState, getAll: action.getAll };
    case cronicCareManagerTypes.LOOKUP_NOTE_REQUEST_FAILURE:
      return { ...initialNotesState, error: action.error };
    default:
      return state;
  }
};

//lookup invention
const initialInventionState = {
  loading: false,
  getAll: [],
  error: "",
};

export const lookupInventionReducer = (
  state = initialInventionState,
  action
) => {
  switch (action.type) {
    case cronicCareManagerTypes.LOOKUP_INVENTION_REQUEST:
      return { ...initialInventionState, loading: true };
    case cronicCareManagerTypes.LOOKUP_INVENTION_REQUEST_SUCCESS:
      return { ...initialInventionState, getAll: action.getAll };
    case cronicCareManagerTypes.LOOKUP_INVENTION_REQUEST_FAILURE:
      return { ...initialInventionState, error: action.error };
    default:
      return state;
  }
};

//save ccm
const initialSaveState = {
  loading: false,
  message: "",
  error: "",
};

export const saveCCMReducer = (state = initialSaveState, action) => {
  switch (action.type) {
    case cronicCareManagerTypes.SAVE_CCM_TASK_REQUEST:
      return { ...initialSaveState, loading: true };
    case cronicCareManagerTypes.SAVE_CCM_TASK_REQUEST_SUCCESS:
      return { ...initialSaveState, message: action.message };
    case cronicCareManagerTypes.SAVE_CCM_TASK_REQUEST_FAILURE:
      return { ...initialSaveState, error: action.error };
    case cronicCareManagerTypes.SAVE_CCM_TASK_REQUEST_RESET:
      return { ...initialSaveState };
    default:
      return state;
  }
};

//Getall CCM
const initialGetState = {
  loading: false,
  getAll: [],
  error: "",
};

export const getCCMReducer = (state = initialGetState, action) => {
  switch (action.type) {
    case cronicCareManagerTypes.GETALL_CCM_TASK_REQUEST:
      return { ...initialGetState, loading: true };
    case cronicCareManagerTypes.GETALL_CCM_TASK_REQUEST_SUCCESS:
      return { ...initialGetState, getAll: action.getAll };
    case cronicCareManagerTypes.GETALL_CCM_TASK_REQUEST_FAILURE:
      return { ...initialGetState, error: action.error };
    default:
      return state;
  }
};
