import { userRoleTypes } from "../types";

const initiaLState = {
    loading: false,
    getAll: [],
  };
  
  export const userRoleDMReducer = (state = initiaLState, action) => {
    switch (action.type) {
      case userRoleTypes.USER_ROLE_REQUEST:
        return {
          ...initiaLState,
          loading: true,
        };
      case userRoleTypes.USER_ROLE_REQUEST_SUCCESS:
        return {
          ...initiaLState,
          getAll: action.getAll,
        };
      case userRoleTypes.USER_ROLE_REQUEST_FAILURE:
        return {
          ...initiaLState,
          error: action.error,
        };
      default:
        return state;
    }
  };
  