import { updatePatientCallStatusTransitionType } from "../types";

export const updatePatientCallStatusTCActions = {
  updatePatientCallStatusRequest,
  updatePatientCallStatusSuccess,
  updatePatientCallStatusFailure,
  updatePatientCallStatusReset,
};
function updatePatientCallStatusRequest(body, token) {
  return {
    type: updatePatientCallStatusTransitionType.UPDATE_PATIENT_CALL_STATUS_TC_REQUEST,
    body,
    token,
  };
}
function updatePatientCallStatusSuccess(message) {
  return {
    type: updatePatientCallStatusTransitionType.UPDATE_PATIENT_CALL_STATUS_TC_SUCCESS,
    message,
  };
}
function updatePatientCallStatusFailure(error) {
  return {
    type: updatePatientCallStatusTransitionType.UPDATE_PATIENT_CALL_STATUS_TC_FAILURE,
    error,
  };
}
function updatePatientCallStatusReset() {
  return {
    type: updatePatientCallStatusTransitionType.UPDATE_PATIENT_CALL_STATUS_TC_RESET,
  };
}
