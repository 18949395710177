import { put, takeEvery, call } from "@redux-saga/core/effects";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { userRoleActions } from "../actions";
import { userRoleTypes } from "../types";

export function* watchDMUserRole() {
  yield takeEvery(userRoleTypes.USER_ROLE_REQUEST, getUserRole);
}

function* getUserRole(action) {
  try {
    let data = yield call(Api, {
      method: "POST",
      endpoint: endpoint.getDMRoleIdentityData,
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    let parsedData = JSON.parse(data);

    yield put(
      userRoleActions.userRoleRequestSuccess(parsedData.RoleIdentityList)
    );
  } catch (error) {
    yield put(userRoleActions.userRoleRequestFailure(error));
  }
}
