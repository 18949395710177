import { getClinicalInfoCmTypes, updateClinicalInfoCmTypes } from "../types";

export const getClinicalInformationCmActions = {
  getClinicalInfoCmRequest,
  getClinicalInfoCmSuccess,
  getClinicalInfoCmFailure,
};

function getClinicalInfoCmRequest(request, token) {
  return {
    type: getClinicalInfoCmTypes.GET_CLINICAL_INFO_CM_REQUEST,
    request,
    token,
  };
}

function getClinicalInfoCmSuccess(Consultant, DcDiagnosis, socialInfo) {
  return {
    type: getClinicalInfoCmTypes.GET_CLINICAL_INFO_CM_SUCCESS,
    Consultant,
    DcDiagnosis,
    socialInfo,
  };
}
function getClinicalInfoCmFailure(error) {
  return {
    type: getClinicalInfoCmTypes.GET_CLINICAL_INFO_CM_FAILURE,
  };
}

export const updateClinicalInformationCmActions = {
  updateClinicalInfoCmRequest,
  updateClinicalInfoCmSuccess,
  updateClinicalInfoCmFailure,
  updateClinicalInfoCmReset,
};
function updateClinicalInfoCmRequest(request, body, token) {
  return {
    type: updateClinicalInfoCmTypes.UPDATE_CLINICAL_INFO_CM_REQUEST,
    request,
    body,
    token,
  };
}

function updateClinicalInfoCmSuccess(message) {
  return {
    type: updateClinicalInfoCmTypes.UPDATE_CLINICAL_INFO_CM_SUCCESS,
    message,
  };
}
function updateClinicalInfoCmFailure() {
  return {
    type: updateClinicalInfoCmTypes.UPDATE_CLINICAL_INFO_CM_FAILURE,
  };
}
function updateClinicalInfoCmReset(error) {
  return {
    type: updateClinicalInfoCmTypes.UPDATE_CLINICAL_INFO_CM_RESET,
  };
}
