import {roleTypes} from "../types";


const initiaLState = {
    loading: false,
  };
  
  export const roleDMReducer = (state = initiaLState, action) => {
    switch (action.type) {
      case roleTypes.ROLE_REQUEST:
        return {
          ...initiaLState,
          loading: true,
        };
      case roleTypes.ROLE_REQUEST_SUCCESS:
        return {
          ...initiaLState,
          getAll: action.getAll,
        };
      case roleTypes.ROLE_REQUEST_FAILURE:
        return {
          ...initiaLState,
          error: action.error,
        };
      default:
        return state;
    }
  };
  