import { put, takeLatest, call } from "@redux-saga/core/effects";
import { addTaskActions } from "../actions/addTask.action";
import { addTaskType } from "../types/addTask.type";
import { endpoint } from "../../../../../api/Url";
import { Api } from "../../../../../api";
import { loginActions } from "../../../../Login/actions/login.action";

export function* watchAddTask() {
  yield takeLatest(addTaskType.ADD_TASK_REQUEST, addTask);
}

function* addTask(action) {
  try {
    //   api call
    const data = yield call(Api, {
      endpoint: `${endpoint.savePocTaskDetail}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    const result = JSON.parse(data);

    yield put(addTaskActions.addTaskRequestSuccess(result[0]));
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(addTaskActions.addTaskRequestFailure(error));
  }
}
