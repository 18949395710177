import { put, takeLatest, call } from "@redux-saga/core/effects";
import { endpoint } from "../../../../../api/Url";
import { Api } from "../../../../../api";
import { icdTypes } from "../types";
import { icdActions } from "../actions";

export function* watchDMICD() {
  yield takeLatest(icdTypes.ICD_REQUEST, getIcd);
}

function* getIcd(action) {
  try {
    let data = yield call(Api, {
      endpoint: endpoint.getDMICDData,
      method: "POST",
      jwt: "Bearer " + action.token,
    });

    let parsedData = JSON.parse(data);

    yield put(icdActions.icdRequestSuccess(parsedData.ICDList));
  } catch (error) {
    yield put(icdActions.icdRequestFailure(error));
  }
}
