import {
  addTaskTypeCm,
  lookupGroupsSubgroupsType,
} from "../types/addTask.type";

/*Lookup group & subgroup */
export const lookupGroupsSubgroupsActions = {
  lookupGroupsSubgroupsRequest,
  lookupGroupsSubgroupsRequestSuccess,
  lookupGroupsSubgroupsRequestFailure,
  lookupGroupsSubgroupsRequestReset,
};

function lookupGroupsSubgroupsRequest(token) {
  return {
    type: lookupGroupsSubgroupsType.LOOKUP_GROUP_SUBGROUP_REQUEST,
    token,
  };
}
function lookupGroupsSubgroupsRequestSuccess(getAll) {
  return {
    type: lookupGroupsSubgroupsType.LOOKUP_GROUP_SUBGROUP_SUCCESS,
    getAll,
  };
}
function lookupGroupsSubgroupsRequestFailure(error) {
  return {
    type: lookupGroupsSubgroupsType.LOOKUP_GROUP_SUBGROUP_FAILURE,
    error,
  };
}

function lookupGroupsSubgroupsRequestReset() {
  return { type: lookupGroupsSubgroupsType.LOOKUP_GROUP_SUBGROUP_RESET };
}

/** Add task */
export const addTaskActionsCm = {
  addTaskCmRequest,
  addTaskCmRequestSuccess,
  addTaskCmRequestFailure,
  addTaskCmRequestReset,
};

function addTaskCmRequest(request, token) {
  return { type: addTaskTypeCm.ADD_TASK_CM_REQUEST, request, token };
}
function addTaskCmRequestSuccess(message) {
  return {
    type: addTaskTypeCm.ADD_TASK_CM_REQUEST_SUCCESS,
    message,
  };
}
function addTaskCmRequestFailure(error) {
  return { type: addTaskTypeCm.ADD_TASK_CM_REQUEST_FAILURE, error };
}

function addTaskCmRequestReset() {
  return { type: addTaskTypeCm.ADD_TASK_CM_REQUEST_RESET };
}
