import { patientType } from "../types/patient.type";

const initialState = {
  loading: false,
  patients: [],
  totalCount: 0,
};

export const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    //For patients page request
    case patientType.PATIENT_PAGE_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case patientType.PATIENT_PAGE_REQUEST_SUCCESS:
      return {
        ...initialState,
        totalCount: action.totalCount,
        patients: [
          // ...state.patients,
          ...action.patients.sort((item1, item2) =>
            item1.patientName.toUpperCase() > item2.patientName.toUpperCase()
              ? 1
              : -1
          ),
        ],
      };
    case patientType.PATIENT_PAGE_REQUEST_FAILURE:
      return { ...initialState, error: action.error };

    // For patients type request
    // case patientType.PATIENT_TYPE_REQUEST:
    //   return { ...initialState, loading: true };
    // case patientType.PATIENT_TYPE_REQUEST_SUCCESS:
    //   return {
    //     ...initialState,
    //     totalCount: action.totalCount,
    //     patients: action.patients.sort((item1, item2) =>
    //       item1.patientName.toUpperCase() > item2.patientName.toUpperCase()
    //         ? 1
    //         : -1
    //     ),
    //   };
    // case patientType.PATIENT_TYPE_REQUEST_FAILURE:
    //   return { ...initialState, error: action.error };

    // //For patients flag request
    // case patientType.PATIENT_FLAG_REQUEST:
    //   return { ...initialState, loading: true };
    // case patientType.PATIENT_FLAG_REQUEST_SUCCESS:
    //   return {
    //     ...initialState,
    //     totalCount: action.totalCount,
    //     patients: action.patients.sort((item1, item2) =>
    //       item1.patientName.toUpperCase() > item2.patientName.toUpperCase()
    //         ? 1
    //         : -1
    //     ),
    //   };
    // case patientType.PATIENT_FLAG_REQUEST_FAILURE:
    //   return { ...initialState, error: action.error };

    default:
      return state;
  }
};
/*
switch (action.type) {
    case patientType.PATIENT_SEARCH_REQUEST:
      return { loading: true };
    case patientType.PATIENT_SEARCH_REQUEST_SUCCESS:
      return {
        totalCount: action.totalCount,
        patients: action.patients.sort((item1, item2) =>
          item1.patientName.toUpperCase() > item2.patientName.toUpperCase()
            ? 1
            : -1
        ),
      };
    case patientType.PATIENT_SEARCH_REQUEST_FAILURE:
      return { error: action.error };
    default:
      return state;
  }
*/

/** switch (action.type) {
    case patientType.PATIENT_SEARCH_REQUEST:
      return { loading: true, ...state };
    case patientType.PATIENT_SEARCH_REQUEST_SUCCESS:
      return {
        ...state,
        totalCount: action.totalCount,
        patients: [
          ...state.patients,
          ...action.patients.sort((item1, item2) =>
            item1.patientName.toUpperCase() > item2.patientName.toUpperCase()
              ? 1
              : -1
          ),
        ],
      };
    case patientType.PATIENT_SEARCH_REQUEST_FAILURE:
      return { error: action.error };
    default:
      return state;
  } */
