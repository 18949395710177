import { addActivePatientMedicationTransitionType } from "../../types";

export const addActivePatientMedicationTCActions = {
  addActivePatientMedicationRequest,
  addActivePatientMedicationSuccess,
  addActivePatientMedicationFailure,
  addActivePatientMedicationReset,
};
function addActivePatientMedicationRequest(body, token) {
  return {
    type: addActivePatientMedicationTransitionType.ADD_ACTIVE_PATIENT_MEDICATION_TC_REQUEST,
    body,
    token,
  };
}
function addActivePatientMedicationSuccess(message) {
  return {
    type: addActivePatientMedicationTransitionType.ADD_ACTIVE_PATIENT_MEDICATION_TC_SUCCESS,
    message,
  };
}
function addActivePatientMedicationFailure(error) {
  return {
    type: addActivePatientMedicationTransitionType.ADD_ACTIVE_PATIENT_MEDICATION_TC_FAILURE,
    error,
  };
}
function addActivePatientMedicationReset() {
  return {
    type: addActivePatientMedicationTransitionType.ADD_ACTIVE_PATIENT_MEDICATION_TC_RESET,
  };
}
