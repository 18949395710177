import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api2 } from "../../../../../api/Api2";
import { endpoint } from "../../../../../api/Url";
import { routeTypes } from "../types/route.type";
import { routeActions } from "../actions/route.action";

export function* watchDMRoute() {
  yield takeLatest(routeTypes.ROUTE_REQUEST, getAllRoute);
  yield takeLatest(routeTypes.ROUTE_SAVE_REQUEST, saveRoute);
  yield takeLatest(routeTypes.ROUTE_UPDATE_REQUEST, updateRoute);
  yield takeLatest(routeTypes.ROUTE_ISACTIVE_REQUEST, updateRouteStatus);
}

// Get all route
function* getAllRoute(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.dm}?search=""`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });

    if (response.data !== undefined && response.data.length > 0) {
      yield put(routeActions.routeRequestSuccess(response.data));
    } else {
      yield put(routeActions.routeRequestFailure("No data found"));
    }
  } catch (error) {
    yield put(routeActions.routeRequestFailure(error));
  }
}

//save route
function* saveRoute(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.dm}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    yield put(routeActions.routeSaveRequestSuccess(response.message));
  } catch (error) {
    yield put(routeActions.routeSaveRequestFailure(error));
  }
}
//update route
function* updateRoute(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.dm}/${action.query}`,
      method: "PATCH",
      jwt: "Bearer " + action.token,
      body: action.payload,
    });

    yield put(
      routeActions.routeUpdateRequestSuccess(
        action.query,
        action.payload,
        response.message
      )
    );
  } catch (error) {
    yield put(routeActions.routeUpdateRequestFailure(error));
  }
}
//update route status
function* updateRouteStatus(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.dm}/${action.query}`,
      method: "PUT",
      jwt: "Bearer " + action.token,
      body: action.payload,
    });

    yield put(
      routeActions.routeIsActiveRequestSuccess(action.query, response.message)
    );
  } catch (error) {
    yield put(routeActions.routeIsActiveRequestFailure(error));
  }
}
