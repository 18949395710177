export const facesheetTypes = {
  GET_FACESHEET_DATA_REQUEST: "GET_FACESHEET_DATA_REQUEST",
  GET_FACESHEET_DATA_SUCCESS: "GET_FACESHEET_DATA_SUCCESS",
  GET_FACESHEET_DATA_FAILURE: "GET_FACESHEET_DATA_FAILURE",
  GET_FACESHEET_DATA_RESET: "GET_FACESHEET_DATA_RESET",

  PROCESS_FACESHEET_DATA_REQUEST: "PROCESS_FACESHEET_DATA_REQUEST_REQUEST",
  PROCESS_FACESHEET_DATA_SUCCESS: "PROCESS_FACESHEET_DATA_REQUEST_SUCCESS",
  PROCESS_FACESHEET_DATA_FAILURE: "PROCESS_FACESHEET_DATA_REQUEST_FAILURE",
  PROCESS_FACESHEET_DATA_RESET: "PROCESS_FACESHEET_DATA_REQUEST_RESET",
};
