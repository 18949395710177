export const routeTypes = {
  //get route
  ROUTE_REQUEST: "GETALL_DM_ROUTE_REQUEST",
  ROUTE_REQUEST_SUCCESS: "GETALL_DM_ROUTE_REQUEST_SUCCESS",
  ROUTE_REQUEST_FAILURE: "GETALL_DM_ROUTE_REQUEST_FAILURE",

  //save route
  ROUTE_SAVE_REQUEST: "DM_ROUTE_SAVE_REQUEST",
  ROUTE_SAVE_REQUEST_SUCCESS: "DM_ROUTE_SAVE_REQUEST_SUCCESS",
  ROUTE_SAVE_REQUEST_FAILURE: "DM_ROUTE_SAVE_REQUEST_FAILURE",
  // ROUTE_SAVE_REQUEST_RESET: "DM_ROUTE_SAVE_REQUEST_RESET",
  //UPDATE route
  ROUTE_UPDATE_REQUEST: "DM_ROUTE_UPDATE_REQUEST",
  ROUTE_UPDATE_REQUEST_SUCCESS: "DM_ROUTE_UPDATE_REQUEST_SUCCESS",
  ROUTE_UPDATE_REQUEST_FAILURE: "DM_ROUTE_UPDATE_REQUEST_FAILURE",
  // ROUTE_UPDATE_REQUEST_RESET: "DM_ROUTE_UPDATE_REQUEST_RESET",

  //Actve/IsActive
  ROUTE_ISACTIVE_REQUEST: "DM_ROUTE_ISACTIVE_REQUEST",
  ROUTE_ISACTIVE_REQUEST_SUCCESS: "DM_ROUTE_ISACTIVE_REQUEST_SUCCESS",
  ROUTE_ISACTIVE_REQUEST_FAILURE: "DM_ROUTE_ISACTIVE_REQUEST_FAILURE",
  ROUTE_REQUEST_RESET: "DM_ROUTE_REQUEST_RESET",
};
