import { updatePatientAssignSeenType } from "../types";

export const updatePatientAssignSeenActions = {
  updatePatientAssignSeenRequest,
  updatePatientAssignSeenSuccess,
  updatePatientAssignSeenFailure,
  updatePatientAssignSeenReset,
};
function updatePatientAssignSeenRequest(request, token) {
  return {
    type: updatePatientAssignSeenType.UPDATE_PATIENT_ASSIGN_SEEN_REQUEST,
    request,
    token,
  };
}
function updatePatientAssignSeenSuccess(message) {
  return {
    type: updatePatientAssignSeenType.UPDATE_PATIENT_ASSIGN_SEEN_SUCCESS,
    message,
  };
}
function updatePatientAssignSeenFailure(error) {
  return {
    type: updatePatientAssignSeenType.UPDATE_PATIENT_ASSIGN_SEEN_FAILURE,
    error,
  };
}
function updatePatientAssignSeenReset(error) {
  return {
    type: updatePatientAssignSeenType.UPDATE_PATIENT_ASSIGN_SEEN_RESET,
    error,
  };
}
