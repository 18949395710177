import { put, takeLatest, call } from "@redux-saga/core/effects";
import { diagnosisDetailsActions } from "../actions";
import { diagnosisDetailsType } from "../types";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";

export function* watchDiagnosisDetails() {
  yield takeLatest(
    diagnosisDetailsType.DIAGNOSIS_DETAILS_REQUEST,
    getAllDiagnosisDetails
  );
}

function* getAllDiagnosisDetails(action) {
  try {
    let diagnosisList = yield call(Api, {
      method: "POST",
      endpoint: `${endpoint.getTCPatientDiagnosisDetails}?AdmissionID=${action.request.AdmissionID}`,
      jwt: "Bearer " + action.token,
    });
    const parsedDiagnosisList = JSON.parse(diagnosisList);

    yield put(
      diagnosisDetailsActions.diagnosisDetailsRequestSuccess(
        parsedDiagnosisList
      )
    );
  } catch (error) {
    yield put(diagnosisDetailsActions.diagnosisDetailsRequestFailure(error));
  }
}
