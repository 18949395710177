import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { pcpActions } from "../actions";
import { pcpTypes } from "../types";

export function* watchDMPCP() {
  yield takeLatest(pcpTypes.PCP_REQUEST, getAllPCP);
}

function* getAllPCP(action) {
  try {
    let data = yield call(Api, {
      method: "POST",
      endpoint: endpoint.getAllDMPCPData,
      jwt: "Bearer " + action.token,
    });
    let parsedData = JSON.parse(data);

    yield put(
      pcpActions.pcpRequestSuccess(
        parsedData.PCPList,
        parsedData.SpecialityList
      )
    );
  } catch (error) {
    yield put(pcpActions.pcpRequestFailure(error));
  }
}
