import { put, call, delay, takeLatest } from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
import { patientMessageActions } from "./patientMessage.action";
import { patientMessageTypes } from "./patientMessage.type";

export function* watchPatientMessage() {
  yield takeLatest(
    patientMessageTypes.PATIENT_MESSAGE_SEND_REQUEST,
    sendMessage
  );
}

function* sendMessage(action) {
  try {
    let message = yield call(Api, {
      endpoint: endpoint.sendPatientChat,
      method: "POST",
      jwt: "bearer " + action.token,
      body: action.request,
    });

    let parsedMessage = JSON.parse(message);

    yield put(
      patientMessageActions.patientMessageSendRequestSuccess(parsedMessage[0])
    );
    yield delay(2000);
    yield put(patientMessageActions.patientMessageSendRequestReset());
  } catch (error) {
    yield put(patientMessageActions.patientMessageSendRequestFailure(error));
  }
}
