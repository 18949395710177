import { deleteMedicationTransitionType } from "../../types";

function deleteMedicationTCRequest(query, token) {
  return {
    type: deleteMedicationTransitionType.DELETE_MEDICATION_TC_REQUEST,
    query,
    token,
  };
}
function deleteMedicationTCSuccess(message) {
  return {
    type: deleteMedicationTransitionType.DELETE_MEDICATION_TC_SUCCESS,
    message,
  };
}
function deleteMedicationTCFailure(error) {
  return {
    type: deleteMedicationTransitionType.DELETE_MEDICATION_TC_SUCCESS,
    error,
  };
}
function deleteActivePatientMedicationReset() {
  return {
    type: deleteMedicationTransitionType.DELETE_MEDICATION_TC_RESET,
  };
}
export const deleteMedicationTCActions = {
  deleteMedicationTCRequest,
  deleteMedicationTCSuccess,
  deleteMedicationTCFailure,
  deleteActivePatientMedicationReset,
};
