import React from "react";
import { getEachWordFirstChar } from "../../util";
import styles from "./PatientInfo.module.scss";
import { SiLivechat } from "react-icons/si";
import { BiVideo, BiChat } from "react-icons/bi";
import Select from "react-select";
import { colors } from "../../themes/colors";

const PatientInfo = ({
  pName,
  pDob,
  onChat,
  hideChat = true,
  hideVC = true,
  menuOptions,
  onChange,
  value,
  ishedis = false,

  onVC = () => {},
}) => {
  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: colors.color_primary,
      borderColor: colors.color_border,

      borderRadius: "0rem",
      // maxWidth: "40vw",
      // minWidth: "20vw",
      width: "20vw",
      // height: "35px",
      margin: "0rem",
      padding: "0rem",
      // height: "3.4vh",

      outline: "none",
      "@media screen and (max-width: 570px)": {
        margin: ".8rem",
        width: "75vw",
      },
    }),
    menu: (provided, state) => ({
      ...provided,

      color: colors.color_primary_txt,

      background: colors.color_primary,
    }),
    // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //   return {
    //     ...styles,
    //     backgroundColor: isDisabled
    //       ? undefined
    //       : isSelected
    //       ? colors.color_accent
    //       : isFocused
    //       ? colors.color_accent
    //       : undefined,
    //     color: isDisabled
    //       ? "#ccc"
    //       : isSelected
    //       ? colors.color_primary_txt
    //       : colors.color_primary_txt,
    //   };
    // },
  };

  return (
    <div className={styles.row}>
      <div className={styles.col1}>
        <div className={styles.avatar_circle}>
          <h3 className={styles.avatar_char}>{getEachWordFirstChar(pName)}</h3>
        </div>
      </div>
      <div className={styles.col1}>
        <h3 className={styles.pname}>{pName}</h3>
        <h6 className={styles.pdob}>{pDob}</h6>
      </div>

      <div className={styles.col1}>
        {!hideChat && (
          <div className={styles.chat_compose} onClick={onChat}>
            <BiChat title="Send a message" />
          </div>
        )}
      </div>
      <div className={styles.col1}>
        {!hideVC && (
          <div className={styles.chat_compose} onClick={onVC}>
            <BiVideo title="Video Call" />
          </div>
        )}
      </div>
      {ishedis ? (
        <></>
      ) : (
        <div className={styles.col}>
          <Select
            options={menuOptions}
            styles={customStyles}
            placeholder="Quick Menu"
            onChange={onChange}
            value={value}
          />
        </div>
      )}
    </div>
  );
};

export { PatientInfo };
