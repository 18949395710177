import { updateInfoTypes } from "../types";

export const updateInfoActions = {
  updateInfoRequest,
  updateInfoRequestReset,
  updateInfoRequestSuccess,
  updateInfoRequestFailure,
};

function updateInfoRequest(request, token) {
  return { type: updateInfoTypes.UPDATE_INFO_REQUEST, request, token };
}

function updateInfoRequestReset() {
  return { type: updateInfoTypes.UPDATE_INFO_REQUEST_RESET };
}
function updateInfoRequestSuccess(message) {
  return { type: updateInfoTypes.UPDATE_INFO_REQUEST_SUCCESS, message };
}
function updateInfoRequestFailure(error) {
  return { type: updateInfoTypes.UPDATE_INFO_REQUEST_FAILURE, error };
}
