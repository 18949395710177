import { call, takeLatest, put } from "@redux-saga/core/effects";
import { dashboardReportActions } from "../actions";
import { dashboardReportTypes } from "../types";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
export function* watchDashboardReport() {
  yield takeLatest(dashboardReportTypes.DASHBOARD_REPORT_REQUEST, getReport);
}

function* getReport(action) {
  try {
    const data = yield call(Api, {
      // endpoint: `${endpoint.powerbiReport}?RoleId=${action.roleId}`,
      endpoint: `${endpoint.dashboardReport}`,
      method: "POST",
      jwt: "Bearer " + action.token,
    });
    const parsedData = JSON.parse(data);

    if (parsedData.reportsDetails !== undefined)
      yield put(
        dashboardReportActions.dashboardReportRequestSuccess(
          parsedData.reportsDetails
        )
      );
  } catch (error) {
    yield put(dashboardReportActions.dashboardReportRequestFailure(error));
  }
}
