import { patientDetailsTypes } from "../types";

const initialState = {
  loading: false,
};

export const patientDetailsCMDReducer = (state = initialState, action) => {
  switch (action.type) {
    case patientDetailsTypes.PATIENT_DETAILS_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case patientDetailsTypes.PATIENT_DETAILS_REQUEST_SUCCESS:
      return {
        ...initialState,
        details: action.details,
      };
    case patientDetailsTypes.PATIENT_DETAILS_REQUEST_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    default:
      return state;
  }
};
