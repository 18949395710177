import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { getSocialDeterministActions } from "../actions";
import { getSocialDeterministTypes } from "../types";

export function* watchDMSocialDeterministDetails() {
  yield takeLatest(
    getSocialDeterministTypes.GET_SOICALDETERMINIST_REQUEST,
    getSocialDeterminist
  );
}

function* getSocialDeterminist(action) {
  try {
    let data = yield call(Api, {
      method: "POST",
      endpoint: endpoint.getDMSocialDeterministDetails,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedData = JSON.parse(data);

    yield put(
      getSocialDeterministActions.socialDeterministRequestSuccess(parsedData[0])
    );
  } catch (error) {
    yield put(
      getSocialDeterministActions.socialDeterministRequestFailure(error)
    );
  }
}
