import { advanceDirectiveTypes } from "../types";

const initialGetState = {
  loading: false,
  get: "",
  dnsStatusList: [],
  error: "",
};

export const advanceDirectiveReducer = (state = initialGetState, action) => {
  switch (action.type) {
    case advanceDirectiveTypes.ADVANCE_DIRECTIVE_REQUEST:
      return {
        ...initialGetState,
        loading: true,
      };
    case advanceDirectiveTypes.ADVANCE_DIRECTIVE_REQUEST_SUCCESS:
      return {
        ...initialGetState,
        get: action.get,
        dnsStatusList: action.dnsStatusList,
      };
    case advanceDirectiveTypes.ADVANCE_DIRECTIVE_REQUEST_FAILURE:
      return {
        ...initialGetState,

        error: action.error,
      };
    default:
      return state;
  }
};
// for update
const initialUpdateState = {
  loading: false,
  message: "",
  error: "",
};

export const advanceDirectiveOperationReducer = (
  state = initialUpdateState,
  action
) => {
  switch (action.type) {
    case advanceDirectiveTypes.ADVANCE_DIRECTIVE_OPERATION_REQUEST:
      return {
        ...initialUpdateState,
        loading: true,
      };
    case advanceDirectiveTypes.ADVANCE_DIRECTIVE_OPERATION_REQUEST_SUCCESS:
      return {
        ...initialUpdateState,
        message: action.message,
        error: action.error,
      };
    case advanceDirectiveTypes.ADVANCE_DIRECTIVE_OPERATION_REQUEST_FAILURE:
      return {
        ...initialUpdateState,
        error: action.error,
      };
    case advanceDirectiveTypes.ADVANCE_DIRECTIVE_OPERATION_REQUEST_RESET:
      return {
        ...initialUpdateState,
      };
    default:
      return state;
  }
};
