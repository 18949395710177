import { familyHistoryTypes } from "../types";

export const familyHistoryActions = {
  //for get
  familyHistoryGetAllRequest,
  familyHistoryGetAllRequestSuccess,
  familyHistoryGetAllRequestFailure,

  //for update delete insert
  familyHistoryOperationRequest,
  familyHistoryOperationRequestSuccess,
  familyHistoryOperationRequestFailure,
  familyHistoryOperationRequestReset,

  //for- delete -
  familyHistoryDeleteRequest,
  familyHistoryDeleteRequestSuccess,
  familyHistoryDeleteRequestFailure,
  familyHistoryDeleteRequestReset,
};
function familyHistoryGetAllRequest(request, token) {
  return {
    type: familyHistoryTypes.GETALL_FAMILY_HISTORY_REQUEST,
    request,
    token,
  };
}
function familyHistoryGetAllRequestSuccess(getAll, dropdownRelationship) {
  return {
    type: familyHistoryTypes.GETALL_FAMILY_HISTORY_REQUEST_SUCCESS,
    getAll,
    dropdownRelationship,
  };
}
function familyHistoryGetAllRequestFailure(error) {
  return {
    type: familyHistoryTypes.GETALL_FAMILY_HISTORY_REQUEST_FAILURE,
    error,
  };
}

function familyHistoryOperationRequest(request, token) {
  return {
    type: familyHistoryTypes.OPERARTION_FAMILY_HISTORY_REQUEST,
    request,
    token,
  };
}
function familyHistoryOperationRequestSuccess(message) {
  return {
    type: familyHistoryTypes.OPERARTION_FAMILY_HISTORY_REQUEST_SUCCESS,
    message,
  };
}
function familyHistoryOperationRequestFailure(error) {
  return {
    type: familyHistoryTypes.OPERARTION_FAMILY_HISTORY_REQUEST_FAILURE,
    error,
  };
}
function familyHistoryOperationRequestReset() {
  return {
    type: familyHistoryTypes.OPERARTION_FAMILY_HISTORY_REQUEST_RESET,
  };
}

function familyHistoryDeleteRequest(request, token) {
  return {
    type: familyHistoryTypes.DELETE_FAMILY_REQUEST,
    request,
    token,
  };
}
function familyHistoryDeleteRequestSuccess(message) {
  return {
    type: familyHistoryTypes.DELETE_FAMILY_REQUEST_SUCCESS,
    message,
  };
}
function familyHistoryDeleteRequestFailure(error) {
  return {
    type: familyHistoryTypes.DELETE_FAMILY_REQUEST_FAILURE,
    error,
  };
}
function familyHistoryDeleteRequestReset() {
  return {
    type: familyHistoryTypes.DELETE_FAMILY_REQUEST_RESET,
  };
}
