export const getActivePatientMedicationType = {
  GET_ACTIVE_PATIENT_MEDICATION_REQUEST:
    "GET_ACTIVE_PATIENT_MEDICATION_REQUEST",
  GET_ACTIVE_PATIENT_MEDICATION_SUCCESS:
    "GET_ACTIVE_PATIENT_MEDICATION_SUCCESS",
  GET_ACTIVE_PATIENT_MEDICATION_FAILURE:
    "GET_ACTIVE_PATIENT_MEDICATION_FAILURE",
  GET_ACTIVE_PATIENT_MEDICATION_RESET:
    "GET_ACTIVE_PATIENT_MEDICATION_RESET",
};
