import { tasksDetailsCMType } from "../types/tasksDetails.type";

export const TasksDetailsCMActions = {
  tasksDetailsCMRequest,
  tasksDetailsCMSuccess,
  tasksDetailsCMFailure,
};

function tasksDetailsCMRequest(request, token) {
  return {
    type: tasksDetailsCMType.CALL_TASKS_DETAILS_CM_REQUEST,
    request,
    token,
  };
}
function tasksDetailsCMSuccess(getAll) {
  return {
    type: tasksDetailsCMType.CALL_TASKS_DETAILS_CM_SUCCESS,
    getAll,
  };
}
function tasksDetailsCMFailure(error) {
  return {
    type: tasksDetailsCMType.CALL_TASKS_DETAILS_CM_FAILURE,
    error,
  };
}
