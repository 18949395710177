import React from "react";
import * as MdIcons from "react-icons/md";
import styles from "./ActionBtn.module.scss";
export const BtnDeleteIcon = ({ onClick }) => {
  return (
    <button className={styles.btn_dlt} onClick={onClick} title="Delete">
      <MdIcons.MdDelete className={styles.dlt_icon} />
    </button>
  );
};
