import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api2 } from "../../../../../api/Api2";
import { endpoint } from "../../../../../api/Url";
import { regionTypes } from "../types/region.type";
import { regionActions } from "../actions/region.action";

export function* watchDMRegion() {
  yield takeLatest(regionTypes.REGION_REQUEST, getAllRegion);
  yield takeLatest(regionTypes.REGION_SAVE_REQUEST, createRegion);
  yield takeLatest(regionTypes.REGION_EDIT_REQUEST, editRegion);
  yield takeLatest(
    regionTypes.REGION_UPDATE_STATUS_REQUEST,
    updateRegionStatus
  );
}

// Getall region
function* getAllRegion(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.region}?nationId=${action.nationId}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });
    if (response.data !== undefined && response.data.length > 0) {
      yield put(regionActions.regionRequestSuccess(response.data));
    } else {
      yield put(regionActions.regionRequestFailure("No data found"));
    }
  } catch (error) {
    yield put(regionActions.regionRequestFailure(error));
  }
}

// create region
function* createRegion(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.region}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    yield put(regionActions.regionSaveRequestSuccess(response.message));
  } catch (error) {
    yield put(regionActions.regionSaveRequestFailure(error));
  }
}

//update/edit region
function* editRegion(action) {
  try {
    console.log("ACTION", action);
    let response = yield call(Api2, {
      endpoint: `${endpoint.region}/${action.query}`,
      method: "PATCH",
      jwt: "Bearer " + action.token,
      body: action.payload,
    });
    console.log(response);
    yield put(
      regionActions.regionEditRequestSuccess(
        action.query,
        action.payload,
        response.message
      )
    );
  } catch (error) {
    yield put(regionActions.regionEditRequestFailure(error));
  }
}

function* updateRegionStatus(action) {
  try {
    console.log("action", action);
    let response = yield call(Api2, {
      endpoint: `${endpoint.region}/${action.query}`,
      method: "PUT",
      jwt: "Bearer " + action.token,
      body: action.payload,
    });

    yield put(
      regionActions.regionUpdateStatusRequestSuccess(
        action.query,
        action.payload,
        response.message
      )
    );
  } catch (error) {
    yield put(regionActions.regionUpdateStatusRequestFailure(error));
  }
}
