import { archiveType } from "../types";

const initialState = { archiving: false };
export const archiveReducer = (state = initialState, action) => {
  switch (action.type) {
    case archiveType.ARCHIVE_MESSAGE_REQUEST:
      return { ...initialState, archiving: true };
    case archiveType.ARCHIVE_MESSAGE_REQUEST_SUCCESS:
      return {
        ...initialState,
        message: action.message,
      };
    case archiveType.ARCHIVE_MESSAGE_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};
