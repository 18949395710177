import { saveDynamicGaitTypes } from "../types";

const initialState = {
  loading: false,
  message: [],
};

export const saveDynamicGaitReducer = (state = initialState, action) => {
  switch (action.type) {
    case saveDynamicGaitTypes.SAVE_DYNAMIC_GAIT_REQUEST:
      return { ...initialState, loading: true };
    case saveDynamicGaitTypes.SAVE_DYNAMIC_GAIT_REQUEST_SUCCESS:
      return {
        ...initialState,
        message: action.message,
      };
    case saveDynamicGaitTypes.SAVE_DYNAMIC_GAIT_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    case saveDynamicGaitTypes.SAVE_DYNAMIC_GAIT_REQUEST_RESET:
      return initialState;
    default:
      return state;
  }
};
