import { deleteChargesTypes } from "../types/chargesDelete.type";

export const deleteChargesActions = {
  deleteChargesRequest,
  deleteChargesRequestSuccess,
  deleteChargesRequestFailure,
  deleteChargesRequestReset,
};

function deleteChargesRequest(request, token) {
  return { type: deleteChargesTypes.DELETE_CHARGES_REQUEST, request, token };
}

function deleteChargesRequestSuccess(message) {
  return { type: deleteChargesTypes.DELETE_CHARGES_REQUEST_SUCCESS, message };
}

function deleteChargesRequestFailure(error) {
  return { type: deleteChargesTypes.DELETE_CHARGES_REQUEST_FAILURE, error };
}

function deleteChargesRequestReset() {
  return { type: deleteChargesTypes.DELETE_CHARGES_REQUEST_RESET };
}
