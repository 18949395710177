export const assessmentsReferralsTypes = {
  ASSESSMENTSREFERRAL_GET_REQUEST: "ASSESSMENTSREFERRAL_GET_REQUEST",
  ASSESSMENTSREFERRAL_GET_REQUEST_SUCCESS:
    "ASSESSMENTSREFERRAL_GET_REQUEST_SUCCESS",
  ASSESSMENTSREFERRAL_GET_REQUEST_FAILURE:
    "ASSESSMENTSREFERRAL_GET_REQUEST_FAILURE",

  ASSESSMENTSREFERRAL_OPERATION_REQUEST:
    "ASSESSMENTSREFERRAL_OPERATION_REQUEST",
  ASSESSMENTSREFERRAL_OPERATION_REQUEST_SUCCESS:
    "ASSESSMENTSREFERRAL_OPERATION_REQUEST_SUCCESS",
  ASSESSMENTSREFERRAL_OPERATION_REQUEST_FAILURE:
    "ASSESSMENTSREFERRAL_OPERATION_REQUEST_FAILURE",
  ASSESSMENTSREFERRAL_OPERATION_REQUEST_RESET:
    "ASSESSMENTSREFERRAL_OPERATION_REQUEST_RESET",
};
