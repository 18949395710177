import { addCPTCommentType } from "../../types/CPTComments/addCptComment.type";

const initialState = {
  loading: false,
  message: "",
  error: "",
};

export const addCptCommentReducer = (state = initialState, action) => {
  switch (action.type) {
    case addCPTCommentType.ADD_CPT_COMMENT_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case addCPTCommentType.ADD_CPT_COMMENT_SUCCESS:
      return {
        ...initialState,
        message: action.message,
      };
    case addCPTCommentType.ADD_CPT_COMMENT_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case addCPTCommentType.ADD_CPT_COMMENT_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
