import { clinicalInfoTypes } from "../types";

export const getClinicalInfoActions = {
  getClinicalInfoRequest,
  getClinicalInfoRequestSuccess,
  getClinicalInfoRequestFailure,
};
function getClinicalInfoRequest(request, token) {
  return {
    type: clinicalInfoTypes.GET_CLINICAL_INFO_REQUEST,
    request,
    token,
  };
}
function getClinicalInfoRequestSuccess(getAll) {
  return {
    type: clinicalInfoTypes.GET_CLINICAL_INFO_REQUEST_SUCCESS,
    getAll,
  };
}
function getClinicalInfoRequestFailure(error) {
  return {
    type: clinicalInfoTypes.GET_CLINICAL_INFO_REQUEST_FAILURE,
    error,
  };
}
