import React from "react";

import styles from "./BtnDownload.module.scss";
import { Spinner } from "../../core";

export const GetReports = (props) => {
  return (
    <button className={styles.btn_container} onClick={props.onClick}>
      {props.loading && (
        <div className={styles.spinner_container}>
          <Spinner height={20} width={20} />
        </div>
      )}
      {"  "}
      {props.label}
    </button>
  );
};
