import { regionTypes } from "../types/region.type";

export const regionActions = {
  regionRequest,
  regionRequestSuccess,
  regionRequestFailure,
  regionSaveRequest,
  regionSaveRequestSuccess,
  regionSaveRequestFailure,
  regionEditRequest,
  regionEditRequestSuccess,
  regionEditRequestFailure,
  regionUpdateStatusRequest,
  regionUpdateStatusRequestSuccess,
  regionUpdateStatusRequestFailure,
  regionRequestReset,
};

//Getall region
function regionRequest(nationId, token) {
  return {
    type: regionTypes.REGION_REQUEST,
    nationId,
    token,
  };
}
function regionRequestSuccess(getAll) {
  return {
    type: regionTypes.REGION_REQUEST_SUCCESS,
    getAll,
  };
}
function regionRequestFailure(error) {
  return {
    type: regionTypes.REGION_REQUEST_FAILURE,
    error,
  };
}

//Save region
function regionSaveRequest(request, token) {
  return {
    type: regionTypes.REGION_SAVE_REQUEST,
    request,
    token,
  };
}
function regionSaveRequestSuccess(message) {
  return {
    type: regionTypes.REGION_SAVE_REQUEST_SUCCESS,
    message,
  };
}
function regionSaveRequestFailure(error) {
  return {
    type: regionTypes.REGION_SAVE_REQUEST_FAILURE,
    error,
  };
}

//update region
function regionEditRequest(query, payload, token) {
  return {
    type: regionTypes.REGION_EDIT_REQUEST,
    query,
    payload,
    token,
  };
}
function regionEditRequestSuccess(id, data, message) {
  return {
    type: regionTypes.REGION_EDIT_REQUEST_SUCCESS,
    id,
    data,
    message,
  };
}
function regionEditRequestFailure(error) {
  return {
    type: regionTypes.REGION_EDIT_REQUEST_FAILURE,
    error,
  };
}

//IsActive region
function regionUpdateStatusRequest(query, payload, token) {
  return {
    type: regionTypes.REGION_UPDATE_STATUS_REQUEST,
    query,
    payload,
    token,
  };
}
function regionUpdateStatusRequestSuccess(id, message) {
  return {
    type: regionTypes.REGION_UPDATE_STATUS_REQUEST_SUCCESS,
    id,
    message,
  };
}
function regionUpdateStatusRequestFailure(error) {
  return {
    type: regionTypes.REGION_UPDATE_STATUS_REQUEST_FAILURE,
    error,
  };
}
//reset
function regionRequestReset() {
  return {
    type: regionTypes.REGION_REQUEST_RESET,
  };
}
