import { getallCmerPatientType } from "../types";

export const getallCmerPatientActions = {
  getallCmerPatientRequest,
  getallCmerPatientSuccess,
  getallCmerPatientFailure,
};
function getallCmerPatientRequest(query, token) {
  return {
    type: getallCmerPatientType.GETALL_CMER_PATIENT_REQUEST,
    query,
    token,
  };
}
function getallCmerPatientSuccess(getAll) {
  return {
    type: getallCmerPatientType.GETALL_CMER_PATIENT_SUCCESS,
    getAll,
  };
}
function getallCmerPatientFailure(error) {
  return {
    type: getallCmerPatientType.GETALL_CMER_PATIENT_FAILURE,
    error,
  };
}
