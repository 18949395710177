import React from "react";
import styles from "./RadioGroup.module.scss";
export const RadioGroup = ({
  hasLabel,
  label,
  rLabel1,
  rLabel2,
  getValue,
  value,
  disabled,
}) => {
  return (
    <div className={styles.radio_container}>
      {hasLabel && <label className={styles.label}>{label}:</label>}
      <label className={styles.rlabel}>{rLabel1} </label>

      <input
        disabled={disabled}
        type={"radio"}
        name={label}
        className={styles.radio}
        value={rLabel1}
        onChange={(val) => getValue(val.target.value)}
        checked={value === rLabel1}
      />
      <label className={styles.rlabel}>{rLabel2}</label>
      <input
        disabled={disabled}
        type={"radio"}
        name={label}
        className={styles.radio}
        value={rLabel2}
        checked={value === rLabel2}
        onChange={(val) => getValue(val.target.value)}
      />
    </div>
  );
};
