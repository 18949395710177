import { ReferralPlansType } from "../types/ReferralPlans.type";
export const ReferralPlansActions = {
  referralPlansRequest,
  referralPlansRequestSuccess,
  referralPlansRequestFailure,
};

function referralPlansRequest(request, token) {
  return { type: ReferralPlansType.REFERRAL_PLANS_REQUEST, request, token };
}
function referralPlansRequestSuccess(referralPlans) {
  return {
    type: ReferralPlansType.REFERRAL_PLANS_REQUEST_SUCCESS,
    referralPlans,
  };
}
function referralPlansRequestFailure(error) {
  return { type: ReferralPlansType.REFERRAL_PLANS_REQUEST_FAILURE, error };
}
