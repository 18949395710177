import { tasksDetailsCMType } from "../types/tasksDetails.type";

const initialState = {
  loading: false,
  getAll: [],
};

export const getAlltasksDetailsCMReducer = (state = initialState, action) => {
  switch (action.type) {
    case tasksDetailsCMType.CALL_TASKS_DETAILS_CM_REQUEST:
      return { ...initialState, loading: true };
    case tasksDetailsCMType.CALL_TASKS_DETAILS_CM_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
      };
    case tasksDetailsCMType.CALL_TASKS_DETAILS_CM_FAILURE:
      // return { error: action.error };
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};
