export const educationProvidedTypes = {
  EDUCATIONPROVIDED_GET_REQUEST: "EDUCATIONPROVIDED_GET_REQUEST",
  EDUCATIONPROVIDED_GET_REQUEST_SUCCESS:
    "EDUCATIONPROVIDED_GET_REQUEST_SUCCESS",
  EDUCATIONPROVIDED_GET_REQUEST_FAILURE:
    "EDUCATIONPROVIDED_GET_REQUEST_FAILURE",

  EDUCATIONPROVIDED_OPERATION_REQUEST: "EDUCATIONPROVIDED_OPERATION_REQUEST",
  EDUCATIONPROVIDED_OPERATION_REQUEST_SUCCESS:
    "EDUCATIONPROVIDED_OPERATION_REQUEST_SUCCESS",
  EDUCATIONPROVIDED_OPERATION_REQUEST_FAILURE:
    "EDUCATIONPROVIDED_OPERATION_REQUEST_FAILURE",
  EDUCATIONPROVIDED_OPERATION_REQUEST_RESET:
    "EDUCATIONPROVIDED_OPERATION_REQUEST_RESET",
};
