import { msoOperationTypes } from "../types";

export const msoOperationActions = {
    msoOperationRequest,
    msoOperationRequestReset,
    msoOperationRequestSuccess,
    msoOperationRequestFailure,
  };
  function msoOperationRequest(request, token) {
    return {
      type: msoOperationTypes.MSO_OPERATION_REQUEST,
      request,
      token,
    };
  }
  function msoOperationRequestReset() {
    return {
      type: msoOperationTypes.MSO_OPERATION_REQUEST_RESET,
    };
  }
  function msoOperationRequestSuccess(message) {
    return {
      type: msoOperationTypes.MSO_OPERATION_REQUEST_SUCCESS,
      message,
    };
  }
  function msoOperationRequestFailure(error) {
    return {
      type: msoOperationTypes.MSO_OPERATION_REQUEST_FAILURE,
      error,
    };
  }
  