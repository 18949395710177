import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { colors } from "../../../themes/colors";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { getAllFrequencyLookUpTCActions } from "../actions/medication/getAllFrequencyLookUp.action";
import { getAllRouteLookUpTCActions } from "../actions/medication/getAllRouteLookup.action";
import { getAllDosageLookUpTCActions } from "../actions/medication/getAllDosageLookUp.action";
import {
  addActivePatientMedicationTCActions,
  getActivePatientMedicationActions,
} from "../actions";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { deleteMedicationTCActions } from "../actions/medication/deleteMedication.action";
import { alertActions } from "../../../components/AlertNew/store/AlertNew.action";
import Loadable from "../../../components/Loadable";
import { lazy } from "react";

const ConfirmDialog = Loadable(
  lazy(() => import("../../../components/Dialog"))
);
const MedicationModal = Loadable(lazy(() => import("./AddMedicationModal")));

const PatientMedications = ({ patientId, patientDetails }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [showDialogBox, setShowDialogBox] = useState(false);

  const userData = useSelector((state) => state.login.userData);
  const medicationData = useSelector(
    (state) => state.getActivePatientMedication
  );
  const addPatientMediation = useSelector(
    (state) => state.addActivePatientMediation
  );
  const deleteMedicationTC = useSelector((state) => state.deleteMedicationTC);

  const [showMedicationModal, setShowMedicationModal] = useState(false);
  const [handleDeleteMedicationData, sethandleDeleteMedicationData] =
    useState(null);

  const columns = useMemo(
    () => [
      {
        field: "drugname",
        headerName: "Drug",
        width: 400,
        flex: 0.08,
        renderCell: (params) => {
          const content = params.value || "--"; // Use empty string if params.value is null or undefined
          const titleCaseContent = content
            .split(" ")
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ");

          return <div>{titleCaseContent}</div>;
        },
      },
      { field: "Frequency", headerName: "Frequency", width: 200, flex: 0.08 },
      { field: "Dosage", headerName: "Dosage", width: 200, flex: 0.08 },
      { field: "Route", headerName: "Route", width: 200, flex: 0.08 },
      {
        field: "Edit",
        headerName: "Edit",
        width: 10,
        flex: 0.08,

        renderCell: (params) => {
          return (
            <div>
              <Tooltip title={"Edit"}>
                <ModeEditIcon
                  style={{ color: colors?.color_accent, cursor: "pointer" }}
                  onClick={() => handleEditMedication(params)}
                />
              </Tooltip>
            </div>
          );
        },
      },
      {
        field: "delete",
        headerName: "Delete",
        width: 20,
        flex: 0.08,
        renderCell: (params) => {
          return (
            <Tooltip title={"Delete"}>
              <div
                onClick={() => {
                  // handleDeleteMedication(params);
                  sethandleDeleteMedicationData(params);
                  setShowDialogBox(true);
                }}
                style={{
                  cursor: "pointer",
                  color: colors?.color_light_txt,
                }}
              >
                <DeleteIcon />
              </div>
            </Tooltip>
          );
        },
      },
    ],
    []
  );

  const [medicationRows, setMedicationRows] = useState(null);
  useEffect(() => {
    if (patientDetails === undefined) {
      navigate("/transitioncare");
    }
  }, [navigate, patientDetails]);
  useEffect(() => {
    setMedicationRows(medicationData?.getAll);
  }, [medicationData]);

  const [patientmedicationId, setPatientmedicationId] = useState("");
  const [medicationDetails, setMedicationDetails] = useState({
    drug: null,
    frequency: null,
    dosage: null,
    route: null,
  });
  const medicationSchema = Yup.object().shape({
    drug: Yup.mixed().nullable().required("Drug is required"),
    frequency: Yup.mixed().nullable().required("Frequency is required"),
    dosage: Yup.mixed().nullable().required("Dosage is required"),
    route: Yup.mixed().nullable().required("Route is required"),
  });

  const [errors, setErrors] = useState({});

  const handleClose = useCallback(() => {
    setShowMedicationModal(false);
    setMedicationDetails({
      drug: null,
      frequency: null,
      dosage: null,
      route: null,
    });
    setPatientmedicationId("");
    setErrors({});
  }, []);

  const handleAddMedication = async () => {
    try {
      // Validate the medicationDetails object against the schema
      await medicationSchema.validate(medicationDetails, {
        abortEarly: false,
      });

      // Construct the query object
      let query = {
        routeId: medicationDetails?.route?.Routeid || "",
        frequencyId: medicationDetails?.frequency?.FrequencyId || "",
        admissionID: patientDetails?.parentAdmissionId || "",
        drugId: medicationDetails?.drug?.DrugID || "",
        dosageId: medicationDetails?.dosage?.Dosageid || "",
      };

      // Add medicationId to the query if patientmedicationId is not empty
      if (patientmedicationId !== "") {
        query.medicationId = patientmedicationId;
      }

      dispatch(
        addActivePatientMedicationTCActions.addActivePatientMedicationRequest(
          query,
          userData.token
        )
      );
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    }
  };

  //! when edit is clicked

  useEffect(() => {
    if (
      addPatientMediation.message !== undefined &&
      addPatientMediation.message !== ""
    ) {
      setShowMedicationModal(false);
      setPatientmedicationId("");
      setMedicationDetails({
        drug: null,
        frequency: null,
        dosage: null,
        route: null,
      });
    }
  }, [addPatientMediation.message]);
  const handleEditMedication = ({ row }) => {
    const editMedicationData = row;
    setShowMedicationModal(true);
    setPatientmedicationId(editMedicationData?.PatientMedicationId);
    // ? setting default value for edit in modal
    setMedicationDetails({
      drug: {
        DrugID: editMedicationData?.DrugID,
        DrugName: editMedicationData?.drugname,
      },
      route: {
        Route: editMedicationData?.Route,
        Routeid: editMedicationData?.RouteID,
      },
      dosage: {
        Dosage: editMedicationData?.Dosage,
        Dosageid: editMedicationData?.DosageID,
      },
      frequency: {
        Frequency: editMedicationData?.Frequency,
        frequencyid: editMedicationData?.frequencyiD,
      },
    });
  };
  const handleDeleteMedication = () => {
    const deleteData = handleDeleteMedicationData?.row;
    if (userData?.token !== undefined && deleteData) {
      let query = { medicationId: deleteData?.PatientMedicationId };
      try {
        dispatch(
          deleteMedicationTCActions.deleteMedicationTCRequest(
            query,
            userData.token
          )
        );
      } catch (error) {
        dispatch(alertActions.alertErrorRequest(error?.message));
      }
    }
  };

  useEffect(() => {
    if (patientDetails?.parentAdmissionId) {
      let query = {
        patientId: patientDetails?.parentAdmissionId,
      };
      dispatch(
        getActivePatientMedicationActions.getActivePatientMedicationRequest(
          query,
          userData.token
        )
      );
    }
  }, [dispatch, patientDetails?.parentAdmissionId, patientId, userData.token]);

  useEffect(() => {
    if (
      (addPatientMediation?.message !== undefined &&
        addPatientMediation?.message !== "") ||
      (deleteMedicationTC.message !== undefined &&
        deleteMedicationTC?.message !== "")
    ) {
      let query = {
        patientId: patientDetails?.parentAdmissionId,
      };

      setTimeout(() => {
        dispatch(
          getActivePatientMedicationActions.getActivePatientMedicationRequest(
            query,
            userData.token
          )
        );
        dispatch(
          deleteMedicationTCActions.deleteActivePatientMedicationReset()
        );
        dispatch(
          addActivePatientMedicationTCActions.addActivePatientMedicationReset()
        );
      }, 1000);
    }
  }, [
    addPatientMediation?.message,
    deleteMedicationTC.message,
    dispatch,
    patientDetails?.parentAdmissionId,
    userData.token,
  ]);

  useEffect(() => {
    dispatch(
      getAllFrequencyLookUpTCActions.getAllFrequencyLookUpRequest(
        userData.token
      )
    );
    dispatch(
      getAllRouteLookUpTCActions.getAllRouteLookUpRequest(userData.token)
    );
    dispatch(
      getAllDosageLookUpTCActions.getAllDosageLookUpRequest(userData.token)
    );
  }, [dispatch, userData.token]);
  let handleDiscard = () => {
    setShowDialogBox(false);
  };
  let handleDialogClose = () => {
    setShowDialogBox(false);
    handleDeleteMedication();
  };


  return (
    <Box sx={{ pl: 3, py: 1 }}>
      {/*Confirmdialog box start dialog box start */}
      {showDialogBox && (
        <ConfirmDialog
          open={showDialogBox}
          handleDialogClose={handleDialogClose}
          handleDiscard={handleDiscard}
        />
      )}
      {/*Confirmdialog box start dialog box end */}
      {/* add or update modal start */}
      <MedicationModal
        open={showMedicationModal}
        handleClose={handleClose}
        medicationDetails={medicationDetails}
        setMedicationDetails={setMedicationDetails}
        handleAddMedication={handleAddMedication}
        patientmedicationId={patientmedicationId}
        errors={errors}
      />
      {/* add or update modal end
      {/* medication details start */}{" "}
      <Grid
        container
        sx={{
          p: 1,

          backgroundColor: colors?.color_background,
          borderRadius: 2,
        }}
      >
        <Grid
          container
          item
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid
            item
            sx={{ pb: 0.5, backgroundColor: colors?.color_background }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Medications
            </Typography>
          </Grid>
          <Grid
            item
            sx={{ pb: 0.5, pl: 0.5, backgroundColor: colors?.color_background }}
          >
            <Tooltip title="Click to add medication">
              <Button
                variant="contained"
                sx={{
                  background: colors.color_primary_txt,
                  "&:hover": {
                    background: colors.color_primary_txt,
                  },
                  mb: 0.5,
                }}
                onClick={() => {
                  setShowMedicationModal(true);
                }}
              >
                <AddIcon
                  sx={{ color: colors.color_primary, fontSize: "20px" }}
                />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container>
          <DataGrid
            sx={{
              backgroundColor: "#FFFFFF",
              height: "35vh",
              overflow: "scroll",
              "&::-webkit-scrollbar": {
                width: "5px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555",
              },
            }}
            rows={medicationRows || []}
            columns={columns}
            loading={medicationData?.loading || false}
            getRowId={(row) => row?.PatientMedicationId || ""}
            disableSelectionOnClick
            disableColumnSelector
            disableRowSelectionOnClick
            pageSize={100}
            hideFooter
            density="compact"
            pagination
          />
        </Grid>
      </Grid>
      {/* medication details end */}
    </Box>
  );
};

export default memo(PatientMedications);
