export const biometricMeasureTypes = {
  BIOMETRIC_GET_REQUEST: "BIOMETRIC_GET_REQUEST",
  BIOMETRIC_GET_REQUEST_SUCCESS: "BIOMETRIC_GET_REQUEST_SUCCESS",
  BIOMETRIC_GET_REQUEST_FAILURE: "BIOMETRIC_GET_REQUEST_FAILURE",

  BIOMETRIC_OPERATION_REQUEST: "BIOMETRIC_OPERATION_REQUEST",
  BIOMETRIC_OPERATION_REQUEST_SUCCESS: "BIOMETRIC_OPERATION_REQUEST_SUCCESS",
  BIOMETRIC_OPERATION_REQUEST_FAILURE: "BIOMETRIC_OPERATION_REQUEST_FAILURE",
  BIOMETRIC_OPERATION_REQUEST_RESET: "BIOMETRIC_OPERATION_REQUEST_RESET",
};
