import { filtersListType } from "../types/filtersList.type";

export const filtersListActions = {
  filtersListRequest,
  filtersListRequestSuccess,
  filtersListRequestFailure,
};

function filtersListRequest(token) {
  return {
    type: filtersListType.FILTERSLIST_REQUEST,
    token,
  };
}
function filtersListRequestSuccess(filtersDataList) {
  return {
    type: filtersListType.FILTERSLIST_REQUEST_SUCCESS,
    filtersDataList,
  };
}
function filtersListRequestFailure(error) {
  return {
    type: filtersListType.FILTERSLIST_REQUEST_FAILURE,
    error: error,
  };
}
