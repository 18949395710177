import { Alert, AlertTitle, Snackbar, Stack, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { alertActions } from "./store/AlertNew.action";

const AlertBox = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);
  const alert = useSelector((store) => store.alert);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(alertActions.alertResetRequest());
    setOpen(false);
  };
  useEffect(() => {
    setOpen(true);
  }, [alert.message]);

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
  return (
    <Box>
      {alert.severity !== "info" && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            action={action}
          >
            <Alert
              sx={{ width: "100%" }}
              severity={alert.severity}
              onClose={handleClose}
            >
              {/* <AlertTitle>{"alert.severity"}</AlertTitle> */}
              {/* <strong>{alert.message}</strong> */}
              <AlertTitle>{alert.message}</AlertTitle>
            </Alert>
          </Snackbar>
        </Stack>
      )}
    </Box>
  );
};

export { AlertBox };
