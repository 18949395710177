import { call, put, takeLatest } from "@redux-saga/core/effects";
import { messageListTypes } from "../types";
import { messageListActions } from "../actions";
import { endpoint } from "../../../api/Url";
import { Api } from "../../../api";

export function* watchPatientMessageList() {
  yield takeLatest(messageListTypes.MESSAGE_LIST_REQUEST, getMessageList);
}

function* getMessageList(action) {
  try {
    let messagesList = yield call(Api, {
      endpoint: endpoint.getNursechatDetails,
      method: "POST",
      jwt: "bearer " + action.token,
      body: action.request,
    });

    let parsedMessageList = JSON.parse(messagesList);

    yield put(messageListActions.messageListRequestSuccess(parsedMessageList));
  } catch (error) {
    yield put(messageListActions.messageListRequestFailure(error));
  }
}
