import { endpoint } from "../../../../api/Url";
import { put, call, debounce } from "@redux-saga/core/effects";
import {
  advanceCarePlanLookupCmerTypes,
  diagnosisLookupCmerTypes,
  getClinicalInfoCmerTypes,
  pcpLookupCmerTypes,
  updateClinicalInfoCmerTypes,
} from "../types";
import { Api2 } from "../../../../api/Api2";
import {
  advanceCarePlanLookupCmerActions,
  diagnosisLookupCmerActions,
  getClinicalInformationCmerActions,
  pcpLookupCmerActions,
  updateClinicalInformationCmerActions,
} from "../actions";
import { alertActions } from "../../../../components/AlertNew/store/AlertNew.action";

export function* watchCmerClinicalInformation() {
  yield debounce(
    300,
    advanceCarePlanLookupCmerTypes.ADVANCECAREPLAN_LOOKUP_CMER_REQUEST,
    advanceCarePlanLookUpCmer
  );
  yield debounce(
    300,
    diagnosisLookupCmerTypes.DIAGNOSIS_LOOKUP_CMER_REQUEST,
    diagnosisLookupCmer
  );
  yield debounce(
    300,
    pcpLookupCmerTypes.PCP_LOOKUP_CMER_REQUEST,
    pcpLookupCmer
  );
  yield debounce(
    300,
    getClinicalInfoCmerTypes.GET_CLINICAL_INFO_CMER_REQUEST,
    getPatientsClinicalInformationCmer
  );
  yield debounce(
    300,
    updateClinicalInfoCmerTypes.UPDATE_CLINICAL_INFO_CMER_REQUEST,
    updatePatientsClinicalInformationCmer
  );
}

function* advanceCarePlanLookUpCmer(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.advanceCarePlanLookupCmer}`,
      jwt: "Bearer " + action.token,
    });

    yield put(
      advanceCarePlanLookupCmerActions.advanceCarePlanLookupSuccess(
        response?.data
      )
    );
  } catch (error) {
    yield put(
      advanceCarePlanLookupCmerActions.advanceCarePlanLookupFailure(error)
    );
    yield put(alertActions?.alertErrorRequest(error?.message));
  }
}
function* diagnosisLookupCmer(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.diagnosisLookupCmer}?search=${action?.request?.searchStr}`,
      jwt: "Bearer " + action.token,
    });

    yield put(diagnosisLookupCmerActions.diagnosisLookupSuccess(response));
  } catch (error) {
    yield put(
      diagnosisLookupCmerActions.diagnosisLookupFailure(error?.message)
    );
    yield put(alertActions?.alertErrorRequest(error?.message));
  }
}

function* pcpLookupCmer(action) {
  try {
    console.log(action, "action");
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.pcpLookupCmer}?search=${action?.request?.search}`,
      jwt: "Bearer " + action.token,
    });

    yield put(pcpLookupCmerActions.pcpLookupSuccess(response));
  } catch (error) {
    yield put(pcpLookupCmerActions.pcpLookupFailure(error?.message));
    yield put(alertActions?.alertErrorRequest(error?.message));
  }
}
function* getPatientsClinicalInformationCmer(action) {
  try {
    console.log(action, "action");
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.patientClinicalInfoCmer}?encounterId=${action?.request?.encounterId}&patientId=${action?.request?.patientId}`,
      jwt: "Bearer " + action.token,
    });
    let { Consultant, DcDiagnosis, socialInfo } = response;
    console.log(Consultant, DcDiagnosis, socialInfo, "imhere");
    yield put(
      getClinicalInformationCmerActions.getClinicalInfoCmerSuccess(
        Consultant,
        DcDiagnosis,
        socialInfo
      )
    );
  } catch (error) {
    yield put(
      getClinicalInformationCmerActions.getClinicalInfoCmerFailure(
        error?.message
      )
    );
    yield put(alertActions?.alertErrorRequest(error?.message));
  }
}
function* updatePatientsClinicalInformationCmer(action) {
  try {
    console.log(action, "action");
    let response = yield call(Api2, {
      method: "POST",
      endpoint: `${endpoint.patientClinicalInfoCmer}?encounterId=${action?.request?.encounterId}&patientId=${action?.request?.patientId}`,
      jwt: "Bearer " + action.token,
      body: action.body,
    });
    let { message } = response;
    console.log(message, "imhere");
    yield put(
      updateClinicalInformationCmerActions.updateClinicalInfoCmerSuccess(
        message
      )
    );
    yield put(alertActions.alertSuccessRequest(message));
  } catch (error) {
    yield put(
      updateClinicalInformationCmerActions.updateClinicalInfoCmerFailure(
        error?.message
      )
    );
    yield put(alertActions?.alertErrorRequest(error?.message));
  }
}
