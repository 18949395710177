export const lookupGroupsSubgroupsType = {
  LOOKUP_GROUP_SUBGROUP_REQUEST: "LOOKUP_GROUP_SUBGROUP_REQUEST",
  LOOKUP_GROUP_SUBGROUP_SUCCESS: "LOOKUP_GROUP_SUBGROUP_SUCCESS",
  LOOKUP_GROUP_SUBGROUP_FAILURE: "LOOKUP_GROUP_SUBGROUP_FAILURE",
  LOOKUP_GROUP_SUBGROUP_RESET: "LOOKUP_GROUP_SUBGROUP_RESET",
};

export const addTaskTypeCm = {
  ADD_TASK_CM_REQUEST: "ADD_TASK_CM_REQUEST",
  ADD_TASK_CM_REQUEST_SUCCESS: "ADD_TASK_CM_REQUEST_SUCCESS",
  ADD_TASK_CM_REQUEST_FAILURE: "ADD_TASK_CM_REQUEST_FAILURE",
  ADD_TASK_CM_REQUEST_RESET: "ADD_TASK_CM_REQUEST_RESET",
};
