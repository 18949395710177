import { facilityTypes } from "../types";

export const facilityActions = {
  facilityRequest,
  facilityRequestSuccess,
  facilityRequestFailure,
};

function facilityRequest(request, token) {
  return { type: facilityTypes.FACILITY_REQUEST, request, token };
}

function facilityRequestSuccess(getAll) {
  return { type: facilityTypes.FACILITY_REQUEST_SUCCESS, getAll };
}

function facilityRequestFailure(error) {
  return { type: facilityTypes.FACILITY_REQUEST_FAILURE, error };
}
