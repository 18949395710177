import React from "react";
import styles from "./Spinner.module.scss";

const Spinner = ({ height, width }) => {
  return (
    <div className={[styles.loader]} style={{ width: width, height: height }} />
  );
};

export { Spinner };
