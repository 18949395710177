import { patientBillingSkeletonTypes } from "../types";

const initialState = {
  loading: false,
  message: "",
};

export const patientBillingSkeletonReducer = (state = initialState, action) => {
  switch (action.type) {
    case patientBillingSkeletonTypes.PATIENT_BILLING_SKELETON_REQUEST:
      return { loading: true, message: action.message };
    case patientBillingSkeletonTypes.PATIENT_BILLING_SKELETON_RESET:
      return { ...initialState };
    default:
      return state;
  }
};
