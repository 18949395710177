import { call, put, takeLatest } from "@redux-saga/core/effects";
import { endpoint } from "../../../../api/Url";
import { inboxMailboxActions } from "../../actions/inboxMailbox.action";
import { inboxMailboxTypes } from "../../types/inboxMailbox.type";
import { Api2 } from "../../../../api/Api2";

export function* watchInboxMailbox() {
  yield takeLatest(inboxMailboxTypes.INBOX_MAILBOX_REQUEST, getInboxMails);
}

function* getInboxMails(action) {
  console.log(action);
  try {
    let inboxMails = yield call(Api2, {
      // method: "POST",
      method: "GET",
      endpoint: `${endpoint.inboxMailbox}/${action?.request?.UserRoleID}`,
      jwt: "Bearer " + action.token,
    });

    yield put(inboxMailboxActions.inboxRequestSuccess(inboxMails));
  } catch (error) {
    yield put(inboxMailboxActions.inboxRequestFailure(error));
  }
}
