import {
  insuranceCompanyListLookupCmerType,
  insurancePlanLookupCmerType,
} from "../types";

const initialState = {
  loading: false,
  getAll: [],
};

export const insuranceCompanylistLookupCmerReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case insuranceCompanyListLookupCmerType.INSURANCE_COMPANY_LIST_LOOKUP_CMER_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case insuranceCompanyListLookupCmerType.INSURANCE_COMPANY_LIST_LOOKUP_CMER_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
        loading: false,
      };
    case insuranceCompanyListLookupCmerType.INSURANCE_COMPANY_LIST_LOOKUP_CMER_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    default:
      return state;
  }
};
const initialStatePlan = {
  loading: false,
  getAll: [],
};
export const insurancePlanLookupCmerReducer = (
  state = initialStatePlan,
  action
) => {
  switch (action.type) {
    case insurancePlanLookupCmerType.INSURANCE_PLAN_LOOKUP_CMER_REQUEST:
      return {
        ...initialStatePlan,
        loading: true,
      };
    case insurancePlanLookupCmerType.INSURANCE_PLAN_LOOKUP_CMER_SUCCESS:
      return {
        ...initialStatePlan,
        getAll: action.getAll,
        loading: false,
      };
    case insurancePlanLookupCmerType.INSURANCE_PLAN_LOOKUP_CMER_FAILURE:
      return {
        ...initialStatePlan,
        error: action.error,
      };

    default:
      return state;
  }
};
