import { put, takeLatest, call } from "@redux-saga/core/effects";
import { facilityActions } from "../actions";
import { endpoint } from "../../../../../api/Url";
import { Api } from "../../../../../api";
import { loginActions } from "../../../../Login/actions/login.action";
import { facilityTypes } from "../types";

export function* watchFacility() {
  yield takeLatest(facilityTypes.FACILITY_REQUEST, getFacilityList);
}

function* getFacilityList(action) {
  try {
    const facilityList = yield call(Api, {
      endpoint: `${endpoint.getFacility}?t?Searchstr=${action.request.searchStr}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      //   body: action.request,
    });

    const parsedFacilityList = JSON.parse(facilityList);

    yield put(
      facilityActions.facilityRequestSuccess(parsedFacilityList.FacilityModel)
    );
  } catch (error) {
    yield put(facilityActions.facilityRequestFailure(error));
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
  }
}
