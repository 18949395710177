import { createSlice } from "@reduxjs/toolkit";

/**Initial state
 * it can be empty */
const initialState = {
  loading: false,
  getAll: [],
  error: "",
};
//creating a slice for test
export const receptionPatientNotesHistorySlice = createSlice({
  name: "receptionPatientNotesHistory",
  initialState: initialState,
  reducers: {
    receptionPatientNotesHistoryRequest: (state) => {
      state.loading = true;
    },
    receptionPatientNotesHistorySuccess: (state, action) => {
      state.loading = false;
      state.error = "";
      state.getAll = action.payload;
    },
    receptionPatientNotesHistoryFailure: (state, action) => {
      state.loading = false;
      state.getAll = [];
      state.error = action.payload.error;
    },
    receptionPatientNotesHistoryReset: (state) => {
      state.loading = false;
      state.error = "";
      state.getAll = [];
    },
  },
});
//getting all actions
export const {
  receptionPatientNotesHistoryRequest,
  receptionPatientNotesHistoryFailure,
  receptionPatientNotesHistorySuccess,
  receptionPatientNotesHistoryReset,
} = receptionPatientNotesHistorySlice.actions;
//exporting slice as a reducer
export default receptionPatientNotesHistorySlice.reducer;
