import { frequencyType } from "../types/frequency.type";

const initialState = {
  loading: false,
  getFrequency: [],
};

export const frequencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case frequencyType.FREQUENCY_REQUEST:
      return { loading: true };
    case frequencyType.FREQUENCY_REQUEST_SUCCESS:
      return { getFrequency: action.getFrequency };
    case frequencyType.FREQUENCY_REQUEST_FAILURE:
      return { error: action.error };
    default:
      return state;
  }
};
