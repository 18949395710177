import { getReportsDataTypes } from "../types/getReportsData.type";

const initialState = {
  loading: false,
  // getReportsData: [],
};
export const getReportsDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case getReportsDataTypes.GET_REPORTSDATA_REQUEST:
      return { ...initialState, loading: true };
    case getReportsDataTypes.GET_REPORTSDATA_REQUEST_SUCCESS:
      return { ...initialState, getReportsData: action.getReportsData };
    case getReportsDataTypes.GET_REPORTSDATA_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    case getReportsDataTypes.GET_REPORTSDATA_REQUEST_RESET:
      return initialState;
    default:
      return state;
  }
};
