import { call, put, takeLatest } from "redux-saga/effects";
import { endpoint } from "../../../../../api/Url";
import { Api2 } from "../../../../../api/Api2";
import { loginActions } from "../../../../Login/actions/login.action";
import { cronicCareManagerActions } from "../actions/croniccaremanager.action";
import { cronicCareManagerTypes } from "../types/croniccaremanager.type";

export function* watchCronicCareManager() {
  //lookup notes
  yield takeLatest(cronicCareManagerTypes.LOOKUP_NOTE_REQUEST, lookupNotes);
  yield takeLatest(
    cronicCareManagerTypes.LOOKUP_INVENTION_REQUEST,
    lookupInvention
  );
  yield takeLatest(cronicCareManagerTypes.SAVE_CCM_TASK_REQUEST, saveCCM);
  yield takeLatest(cronicCareManagerTypes.GETALL_CCM_TASK_REQUEST, getCCM);
}

// lookup notes
function* lookupNotes(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.ccm}/${endpoint.lookups}/${endpoint.notes}`,
      jwt: "Bearer " + action.token,
    });
    response.data !== undefined && response.data.length > 0
      ? yield put(
          cronicCareManagerActions.lookupNotesRequestSuccess(response.data)
        )
      : yield put(
          cronicCareManagerActions.lookupNotesRequestSuccess("No data found")
        );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(cronicCareManagerActions.lookupNotesRequestFailure(error));
  }
}
// lookup intervention
function* lookupInvention(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.ccm}/${action.AdmissionID}/${endpoint.careplans}/${action.carePlanId}/${endpoint.interventions}`,
      jwt: "Bearer " + action.token,
    });
    response.data !== undefined && response.data.length > 0
      ? yield put(
          cronicCareManagerActions.lookupInventionRequestSuccess(response.data)
        )
      : yield put(
          cronicCareManagerActions.lookupInventionRequestSuccess(
            "No data found"
          )
        );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(cronicCareManagerActions.lookupInventionRequestFailure(error));
  }
}

//Add CCM
function* saveCCM(action) {
  try {
    const response = yield call(Api2, {
      endpoint: `${endpoint.ccm}/${action.params.PatientId}/${endpoint.ccmtasks}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    yield put(cronicCareManagerActions.saveCCMRequestSuccess(response.message));
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(cronicCareManagerActions.saveCCMRequestSuccess(error));
  }
}

//Getall CCM
function* getCCM(action) {
  try {
    let data = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.ccm}/${action.request.AdmissionID}/${endpoint.ccmtasks}?from=${action.request.from}&to=${action.request.to}`,
      jwt: "Bearer " + action.token,
    });

    yield put(cronicCareManagerActions.getallCCMRequestSuccess(data.data));
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(cronicCareManagerActions.getallCCMRequestFailure(error));
  }
}
