import { addPatientBillingType } from "../types";

export const addPatientBillingActions = {
  addBillingPatientRequest,
  addBillingPatientSuccess,
  addBillingPatientFailure,
  addBillingPatientReset,
};
function addBillingPatientRequest(request, token) {
  return {
    type: addPatientBillingType.ADD_PATIENT_BILLING_REQUEST,
    request,
    token,
  };
}
function addBillingPatientSuccess(message) {
  return {
    type: addPatientBillingType.ADD_PATIENT_BILLING_SUCCESS,
    message,
  };
}
function addBillingPatientFailure(error) {
  return {
    type: addPatientBillingType.ADD_PATIENT_BILLING_FAILURE,
    error,
  };
}
function addBillingPatientReset() {
  return {
    type: addPatientBillingType.ADD_PATIENT_BILLING_RESET,
  };
}
