import { put, takeLatest, call } from "@redux-saga/core/effects";
import { msoActions } from "../actions";
import { msoTypes } from "../types";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";

export function* watchDMMSO() {
  yield takeLatest(msoTypes.MSO_REQUEST, getAllMSO);
}

function* getAllMSO(action) {
  try {
    let data = yield call(Api, {
      endpoint: endpoint.getDMMsoData,
      method: "POST",
      jwt: "Bearer " + action.token,
    });

    let parsedData = JSON.parse(data);

    if (parsedData.MsoList !== undefined && parsedData.MsoList.length > 0) {
      yield put(msoActions.msoRequestSuccess(parsedData.MsoList));
    } else {
      yield put(msoActions.msoRequestFailure("No data found"));
    }
  } catch (error) {
    yield put(msoActions.msoRequestFailure(error));
  }
}
