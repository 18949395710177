import { getPCPTypes } from "../types";

export const getPCPActions = {
  pcpRequest,
  pcpRequestSuccess,
  pcpRequestFailure,
};
function pcpRequest(request, token) {
  return {
    type: getPCPTypes.GET_PCP_REQUEST,
    request,
    token,
  };
}
function pcpRequestSuccess(get) {
  return {
    type: getPCPTypes.GET_PCP_REQUEST_SUCCESS,
    get,
  };
}
function pcpRequestFailure(error) {
  return {
    type: getPCPTypes.GET_PCP_REQUEST_FAILURE,
    error,
  };
}
