import { createSlice } from "@reduxjs/toolkit";
import { act } from "react";

const initialState = {
  loading: false,
  getAll: [], // Consider 'data' or 'patients' instead of 'getAll'
  error: "",
};

export const getPatientListForUpdateSlice = createSlice({
  name: "getPatientListForUpdate", // Updated the slice name to reflect the specific purpose
  initialState,
  reducers: {
    getPatientListForUpdateRequest: (state, action) => {
      state.loading = true;
    },
    getPatientListForUpdateSuccess: (state, action) => {
      console.log(action, "action");
      state.loading = false;
      state.error = "";
      state.getAll = action.payload.patients; // Store the list of patients
    },
    getPatientListForUpdateFailure: (state, action) => {
      state.loading = false;
      state.getAll = []; // Clear data if there's an error
      state.error = action.payload.error; // Capture the error message
    },
    resetPatientListForUpdate: (state) => {
      state.loading = false;
      state.error = "";
      state.getAll = []; // Reset data to initial state
    },
  },
});

export const {
  getPatientListForUpdateRequest,
  getPatientListForUpdateSuccess,
  getPatientListForUpdateFailure, // Corrected spelling to 'Failure'
  resetPatientListForUpdate,
} = getPatientListForUpdateSlice.actions;

export default getPatientListForUpdateSlice.reducer;
