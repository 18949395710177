import { facilityOperationTypes } from "../types";

const initiaLState = {
  loading: false,
};

export const facilityOperationDMReducer = (state = initiaLState, action) => {
  switch (action.type) {
    case facilityOperationTypes.FACILITY_OPERATION_REQUEST:
      return {
        ...initiaLState,
        loading: true,
      };
    case facilityOperationTypes.FACILITY_OPERATION_REQUEST_RESET:
      return {
        ...initiaLState,
      };
    case facilityOperationTypes.FACILITY_OPERATION_REQUEST_SUCCESS:
      return {
        ...initiaLState,
        message: action.message,
      };
    case facilityOperationTypes.FACILITY_OPERATION_REQUEST_FAILURE:
      return {
        ...initiaLState,
        error: action.error,
      };
    default:
      return state;
  }
};
