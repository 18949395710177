import { diagnosisLookupCmerTypes } from "../types";

export const diagnosisLookupCmerActions = {
  diagnosisLookupRequest,
  diagnosisLookupSuccess,
  diagnosisLookupFailure,
};
function diagnosisLookupRequest(request, token) {
  return {
    type: diagnosisLookupCmerTypes.DIAGNOSIS_LOOKUP_CMER_REQUEST,
    request,
    token,
  };
}
function diagnosisLookupSuccess(getAll) {
  return {
    type: diagnosisLookupCmerTypes.DIAGNOSIS_LOOKUP_CMER_SUCCESS,
    getAll,
  };
}
function diagnosisLookupFailure(error) {
  return {
    type: diagnosisLookupCmerTypes.DIAGNOSIS_LOOKUP_CMER_FAILURE,
    error,
  };
}
