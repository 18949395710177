import { addUpdateUtilizationTypes } from "../types";

export const addUpdateUtilizationActions = {
  addUpdateUtilizationRequest,
  addUpdateUtilizationRequestSuccess,
  addUpdateUtilizationRequestFailure,
  addUpdateUtilizationRequestReset,
};
function addUpdateUtilizationRequest(request, token) {
  return {
    type: addUpdateUtilizationTypes.ADDUPDATE_UTILIZATION_REQUEST,
    request,
    token,
  };
}
function addUpdateUtilizationRequestSuccess(message) {
  return {
    type: addUpdateUtilizationTypes.ADDUPDATE_UTILIZATION_REQUEST_SUCCESS,
    message,
  };
}
function addUpdateUtilizationRequestFailure(error) {
  return {
    type: addUpdateUtilizationTypes.ADDUPDATE_UTILIZATION_REQUEST_FAILURE,
    error,
  };
}
function addUpdateUtilizationRequestReset() {
  return {
    type: addUpdateUtilizationTypes.ADDUPDATE_UTILIZATION_REQUEST_RESET,
  };
}
