import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
import { mailDetailsActions } from "../actions";
import { mailDetailsTypes } from "../types/mailDetails.type";
import { Api2 } from "../../../api/Api2";
export function* watchMailDetails() {
  yield takeLatest(mailDetailsTypes.MAIL_DETAILS_REQUEST, mailDetails);
}

function* mailDetails(action) {
  try {
    let message = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.mailDetails}?userRoleId=${action?.request?.userRoleId}&composeId=${action?.request?.composeId}&createdDate=${action?.request?.createdDate}`,
      jwt: "Bearer " + action.token,
    });

    yield put(mailDetailsActions.mailDetailsRequestSuccess(message));
  } catch (error) {
    yield put(mailDetailsActions.mailDetailsRequestFailure(error));
  }
}
