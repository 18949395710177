import { put, call, debounce, takeLatest } from "@redux-saga/core/effects";

import { Api2 } from "../../../../api/Api2";
import { endpoint } from "../../../../api/Url";
import {
  getDischargePatientDetailsActions,
  getallDischargePatientPharmaActions,
  callAttemptedPatientDischargePharmaActions,
  callCompletedPatientDischargePharmaActions,
} from "../actions";
import {
  getDischargePatientDetailsType,
  getDischargePatientPharmaType,
  callAttemptedPharmaType,
  callCompletedPharmaType,
} from "../types";
import { alertActions } from "../../../../components/AlertNew/store/AlertNew.action";

export function* watchDischargePatientPharma() {
  yield debounce(
    500,
    getDischargePatientPharmaType.GETALL_DISCHARGE_PATIENT_PHARMA_REQUEST,
    getallDischargePatient
  );
  yield debounce(
    500,
    getDischargePatientDetailsType.GET_DISCHARGE_PATIENT_DETAILS_PHARMA_REQUEST,
    getDischargePatientDetails
  );
  yield takeLatest(
    callAttemptedPharmaType.CALL_ATTENMPTED_PHARMA_REQUEST,
    handleCallAttempted
  );
  yield takeLatest(
    callCompletedPharmaType.CALL_COMPLETED_PHARMA_REQUEST,
    handleCallCompleted
  );
}
// get all discharge patient list
function* getallDischargePatient(action) {
  try {
    let url = `${endpoint.DischargePatientPharma}?pageSize=${action.query.pageSize}&pageNumber=${action.query.pageNumber}&all=${action.query.all}`;
    if (action.query.search !== "") {
      url = url + `&search=${action.query.search}`;
    }
    let response = yield call(Api2, {
      method: "GET",
      endpoint: url,
      jwt: "Bearer " + action.token,
    });

    yield put(
      getallDischargePatientPharmaActions.getallDischargePatientSuccess(
        response?.data,
        response?.totalPages,
        response?.totalRows
      )
    );
  } catch (error) {
    yield put(
      getallDischargePatientPharmaActions.getallDischargePatientFailure(error)
    );
    yield put(yield put(alertActions.alertErrorRequest(error.message)));
  }
}

// get discharge patient detail
function* getDischargePatientDetails(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.DischargePatientPharma}/${action.request.AdmissionID}`,
      jwt: "Bearer " + action.token,
    });
    yield put(
      getDischargePatientDetailsActions.getDischargePatientDetailsSuccess(
        response.patientDetails[0],
        response.medicationDetails,
        response.callHistory
      )
    );
  } catch (error) {
    yield put(
      getDischargePatientDetailsActions.getDischargePatientDetailsFailure(error)
    );
        yield put(yield put(alertActions.alertErrorRequest(error.message)));

  }
}

function* handleCallAttempted(action) {
  try {
    let response = yield call(Api2, {
      method: "PUT",
      endpoint: `${endpoint.DischargePatientPharma}/${action.request.AdmissionID}/calls`,
      jwt: "Bearer " + action.token,
    });
    yield put(
      callAttemptedPatientDischargePharmaActions.callAttemptedPatientDischargeSuccess(
        response
      )
    );
    if (
      response.message ===
      "Call didn't update. Because Call Attempts cannot be more than No. of Calls"
    ) {
      yield put(alertActions.alertErrorRequest("Call attempt limit reached"));
    } else {
      yield put(alertActions.alertSuccessRequest(response.message));
    }
  } catch (error) {
    yield put(
      callAttemptedPatientDischargePharmaActions.callAttemptedPatientDischargeFailure(
        error
      )
    );
    yield put(alertActions.alertErrorRequest(error.message));
  }
}
function* handleCallCompleted(action) {
  try {
    let response = yield call(Api2, {
      method: "PUT",
      endpoint: `${endpoint.DischargePatientPharma}/${action.query.AdmissionID}/comments`,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    yield put(
      callCompletedPatientDischargePharmaActions.callCompletedPatientDischargeSuccess(
        response
      )
    );
    yield put(alertActions.alertSuccessRequest("Call completed successfully"));
  } catch (error) {
    yield put(
      callCompletedPatientDischargePharmaActions.callCompletedPatientDischargeFailure(
        error
      )
    );
    yield put(alertActions.alertErrorRequest(error.message));
  }
}
