import { put, takeEvery, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { insurancePlanActions } from "../actions";
import { insurancePlanTypes } from "../types";

export function* watchDMInsurancePlan() {
  yield takeEvery(
    insurancePlanTypes.INSURANCE_PLAN_REQUEST,
    getAllInsurancePlan
  );
}

function* getAllInsurancePlan(action) {
  try {
    let data = yield call(Api, {
      method: "POST",
      endpoint: endpoint.getAllDMInsurancePlanData,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedData = JSON.parse(data);

    yield put(
      insurancePlanActions.insurancePlanRequestSuccess(
        parsedData.InsurancePlanList
      )
    );
  } catch (error) {
    yield put(insurancePlanActions.insurancePlanRequestFailure(error));
  }
}
