import { socialDeterministTypes } from "../types";

const initiaLState = {
  loading: false,
  getAll: [],
};

export const socialDeterministDMReducer = (state = initiaLState, action) => {
  switch (action.type) {
    case socialDeterministTypes.SOCIALDETERMINIST_REQUEST:
      return {
        ...initiaLState,
        loading: true,
      };
    case socialDeterministTypes.SOCIALDETERMINIST_REQUEST_SUCCESS:
      return {
        ...initiaLState,
        getAll: action.getAll,
      };
    case socialDeterministTypes.SOCIALDETERMINIST_REQUEST_FAILURE:
      return {
        ...initiaLState,
        error: action.error,
      };
    default:
      return state;
  }
};
