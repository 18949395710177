import {
  insuranceCompanyListLookupCmerType,
  insurancePlanLookupCmerType,
} from "../types";

export const insuranceCompanyListLookupCmerActions = {
  insuranceListLookupCmerRequest,
  insuranceListLookupCmerSuccess,
  insuranceListLookupCmerFailure,
};
function insuranceListLookupCmerRequest(token) {
  return {
    type: insuranceCompanyListLookupCmerType.INSURANCE_COMPANY_LIST_LOOKUP_CMER_REQUEST,
    token,
  };
}
function insuranceListLookupCmerSuccess(getAll) {
  return {
    type: insuranceCompanyListLookupCmerType.INSURANCE_COMPANY_LIST_LOOKUP_CMER_SUCCESS,
    getAll,
  };
}
function insuranceListLookupCmerFailure(error) {
  return {
    type: insuranceCompanyListLookupCmerType.INSURANCE_COMPANY_LIST_LOOKUP_CMER_FAILURE,
    error,
  };
}
export const insurancePlanLookupCmerActions = {
  insurancePlanLookupCmerRequest,
  insurancePlanLookupCmerSuccess,
  insurancePlanLookupCmerFailure,
};
function insurancePlanLookupCmerRequest(token) {
  return {
    type: insurancePlanLookupCmerType.INSURANCE_PLAN_LOOKUP_CMER_REQUEST,
    token,
  };
}
function insurancePlanLookupCmerSuccess(getAll) {
  return {
    type: insurancePlanLookupCmerType.INSURANCE_PLAN_LOOKUP_CMER_SUCCESS,
    getAll,
  };
}
function insurancePlanLookupCmerFailure(error) {
  return {
    type: insurancePlanLookupCmerType.INSURANCE_PLAN_LOOKUP_CMER_FAILURE,
    error,
  };
}
