import { getRvuReportType } from "../types/getRvuReport.type";

export const getRvuReportActions = {
  getRvuReportRequest,
  getRvuReportSuccess,
  getRvuReportFailure,
};
function getRvuReportRequest(query, token) {
  return {
    type: getRvuReportType.GET_RVU_REPORT_REQUEST,
    query,
    token,
  };
}
function getRvuReportSuccess(getAll, totalPages, totalRows, totalRVU) {
  return {
    type: getRvuReportType.GET_RVU_REPORT_SUCCESS,
    getAll,
    totalPages,
    totalRows,
    totalRVU,
  };
}
function getRvuReportFailure(error) {
  return {
    type: getRvuReportType.GET_RVU_REPORT_FAILURE,
    error,
  };
}
