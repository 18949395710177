import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotFound, Progress } from "../../../components";

import { strings } from "./strings";
import styles from "./BasicInfo.module.scss";

import {
  InputTypeField,
  Selector,
  AutoComplete,
  InputField,
} from "../../cm/EditPatient/components";
import { useLocation, useNavigate } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { MdExpandMore } from "react-icons/md";
import { getCMPatientDetailsActions } from "../store/actions";
import {
  insuranceCompanyListLookupCmerActions,
  insurancePlanLookupCmerActions,
  relationshipListLookupCmerActions,
} from "../../cmer/actions";
import { Button } from "../../../core";
import { updateCMPatientDetailsActions } from "../store/actions/patientDetailsAction/updateCMPatientDetails.action";
import { pcpLookupCmerActions } from "../../cmer/ClinicalInformation/actions";
import { isEmptyOrNull } from "../../../util";
import { dynamicLookupRequest } from "../../../store/reducers/dynamicLookup.slice";

const PatientBasicInfoCM = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { patientDetails1 } = useLocation().state;

  const userData = useSelector((state) => state.login.userData);
  const getCMPatientDetails = useSelector((state) => state.getCMPatientDetails);
  const patientDetailsCM = getCMPatientDetails?.getAll[0];

  const insuranceCompanylistLookupCmer = useSelector(
    (state) => state.insuranceCompanylistLookupCmer
  );
  const updateCMPatientDetails = useSelector(
    (state) => state.updateCMPatientDetails
  );

  const pcpLookupCmer = useSelector((state) => state.pcpLookupCmer);

  const relationshiplistLookupCmer = useSelector(
    (state) => state.relationshiplistLookupCmer
  );
  const dynamicLookupData = useSelector((state) => state.dynamicLookupData);

  const [patientDetails, setPatientDetails] = useState({
    AdmissionID: "",
    FacilityID: "",
    DOB: "",
    PCPName: "",
    PCPAddress: "",
    PCPPhone: "",
    PCPEffectiveDate: "",
    Address: "",
    AdmissionDate: "",
    CaregiverFName: "",
    CaregiverLName: "",
    CaregiverPhoneNo: "",
    CaregiverRelationship: "",
    Cellphone: "",
    HccScore: "",
    Funding: "",
    CmAssigned: "",
    CmName: "",
    // Cmid: "",
    Mlr: "",
    RiskLevel: "",
    CaseManagerNotes: "",
    Provider: "",
  });
  useEffect(() => {
    if (!getCMPatientDetails?.loading && !relationshiplistLookupCmer?.loading) {
      setPatientDetails({
        // basic
        PatientName: patientDetailsCM?.PatientName,
        Address: patientDetailsCM?.Address,
        DOB: patientDetailsCM?.DOB,
        CellPhone: patientDetailsCM?.CellPhone,

        // last admission details

        HccScore: patientDetailsCM?.HccScore,
        Funding: patientDetailsCM?.Funding,
        Mlr: patientDetailsCM?.Mlr,
        RiskLevel: patientDetailsCM?.RISK || null,

        Provider: {
          ProviderId: patientDetailsCM?.ProviderId,
          ProviderName: patientDetailsCM?.ProviderName,
        },
        ICDCodes: patientDetailsCM?.PrimaryDx,
        // caregiver

        CaregiverFName: patientDetailsCM?.CaregiverFName,
        CaregiverLName: patientDetailsCM?.CaregiverLName,
        CaregiverPhoneNo: patientDetailsCM?.CaregiverPhoneNo,

        CaregiverRelationship:
          relationshiplistLookupCmer?.getAll?.find(
            (item) =>
              item.RelationshipId === patientDetailsCM?.CaregiverRelationshipID
          ) || null,

        // case manager

        CmAssigned: patientDetailsCM?.CmAssigned || false,
        // insurance
        // InsuranceCompany: insuranceCompanylistLookupCmer?.getAll?.find(
        //   (insurance) =>
        //     insurance.InsuranceCompanyId ===
        //     patientDetailsCM?.InsuranceCompanyId
        // ),
        InsuranceCompany: {
          CompanyName: patientDetailsCM?.CompanyName,
          InsuranceCompanyId: patientDetailsCM?.InsuranceCompanyId,
        },
        InsurancePlan: {
          Plan: patientDetailsCM?.PlanName,
          PlanId: patientDetailsCM?.PlanId,
        },

        // ),
        // PCP details

        AdmissionDate: patientDetailsCM?.AdmissionDate,

        Facility: patientDetailsCM?.Facility,

        PCPEffectiveDate: patientDetailsCM?.PCPEffectiveDate,

        TCDenied: patientDetailsCM?.TCDenied,
        PCPName: {
          PCPID: patientDetailsCM?.PCPID,
          PCP: patientDetailsCM?.PCP,
        },
        // PCPName: {
        //   PCPID: patientDetailsCM?.ProviderID,
        //   PCP: patientDetailsCM?.ProviderName,
        // },
        CaseManagerNotes: patientDetailsCM?.CaseManagerNotes,
      });
    }
  }, [
    getCMPatientDetails,
    insuranceCompanylistLookupCmer,
    relationshiplistLookupCmer,
  ]);
  const [expandAccordian, setExpandAccordian] = useState({
    basicDetails: true,
    lastAdmissionDetails: true,
    careGiver: false,
    insuranceDetails: false,
    pcpDetails: false,
    cmDetails: false,
    notes: false,
  });

  function expandAccordianModel(type) {
    if (type === "basic_details") {
      setExpandAccordian({
        ...expandAccordian,
        basicDetails: !expandAccordian?.basicDetails,
      });
    } else if (type === "last_admission_details") {
      setExpandAccordian({
        ...expandAccordian,
        lastAdmissionDetails: !expandAccordian?.lastAdmissionDetails,
      });
    } else if (type === "caregiver_details") {
      setExpandAccordian({
        ...expandAccordian,
        careGiver: !expandAccordian?.careGiver,
      });
    } else if (type === "insurance_details") {
      setExpandAccordian({
        ...expandAccordian,
        insuranceDetails: !expandAccordian?.insuranceDetails,
      });
    } else if (type === "pcp_details") {
      setExpandAccordian({
        ...expandAccordian,
        pcpDetails: !expandAccordian?.pcpDetails,
      });
    } else if (type === "cm_details") {
      setExpandAccordian({
        ...expandAccordian,
        cmDetails: !expandAccordian?.cmDetails,
      });
    } else if (type === "cm_notes") {
      setExpandAccordian({
        ...expandAccordian,
        notes: !expandAccordian?.notes,
      });
    }
  }
  const [address, setAddress] = useState("");
  const [testNotes, setTestNotes] = useState("");
  const [caregiverFName, setCaregiverFName] = useState("");
  const [caregiverLName, setCaregiverLName] = useState("");
  const [caregiverPhoneNo, setCaregiverPhoneNo] = useState("");
  const [cellphoneNo, setCellphoneNo] = useState("");

  const [funding, setFunding] = useState("");
  const [mlr, setMLR] = useState("");
  const [riskLvl, setRiskLvl] = useState("");
  const [cmAssgined, setCmAssigned] = useState(false);

  const [errorPhoneNo, setErrorPhoneNo] = useState(false);
  const [errorCaregiverPhoneNo, setErrorCaregiverPhoneNo] = useState(false);
  const [errorDOB, setErrorDOB] = useState(false);

  const [expandCareDetails, setExpandCare] = useState(false);
  const [expandBasicDetails, setExpandBasic] = useState(true);

  const [searchString, setSearchString] = useState("");

  const [errorProviderPCPID, setErrorProviderPCPID] = useState(false);
  const [errorHccScore, setErrorHccScore] = useState(false);
  const [errorFunding, setErrorFunding] = useState(false);
  const [errorRiskLevel, setErrorRiskLevel] = useState(false);
  const [errorMlr, setErrorMlr] = useState(false);
  const [errorCaregiverFName, setErrorCaregiverFName] = useState(false);
  const [errorCaregiverLName, setErrorCaregiverLName] = useState(false);
  const [errorCaregiverRelationshipID, setErrorCaregiverRelationshipID] =
    useState(false);
  const [errorPCPNameID, setErrorPCPNameID] = useState(false);
  const [errorCaseManagerNotes, setErrorCaseManagerNotes] = useState(false);

  const validate = () => {
    let hasError = false;

    // Validate Provider PCPID
    if (isEmptyOrNull(patientDetails.Provider?.PCP)) {
      setErrorProviderPCPID(true);
      hasError = true;
    } else {
      setErrorProviderPCPID(false);
    }
    if (isEmptyOrNull(patientDetails.PCPName?.PCP)) {
      setErrorPCPNameID(true);
      hasError = true;
    } else {
      setErrorPCPNameID(false);
    }

    hasError &&
      setExpandAccordian({
        careGiver: true,
        basicDetails: true,
        lastAdmissionDetails: true,
        insuranceDetails: true,
        pcpDetails: true,
        cmDetails: true,
        notes: true,
      });

    return hasError;
  };

  const perfromUpdateInfo = () => {
    if (!validate()) {
      if (userData.token !== undefined) {
        if (patientDetails1 !== undefined) {
          let request = {
            CreatedBy: `${userData?.UserID}`,
            AdmissionID: patientDetails1.parentAdmissionId,

            PhoneNo: patientDetails?.CellPhone || "",

            // last admission details
            ProviderID: patientDetails?.Provider?.ProviderId,
            HccScore: patientDetails?.HccScore,
            Funding: patientDetails?.Funding,
            RiskLevel: patientDetails?.RiskLevel || null,
            Mlr: patientDetails?.Mlr || null,

            // care giver
            CaregiverFName: patientDetails?.CaregiverFName,
            CaregiverLName: patientDetails?.CaregiverLName,
            CaregiverPhoneNo: patientDetails?.CaregiverPhoneNo,
            RelationshipID:
              patientDetails?.CaregiverRelationship?.RelationshipId || null,

            InsuranceCompanyId:
              patientDetails?.InsuranceCompany?.InsuranceCompanyId || null,
            PlanId: patientDetails?.InsurancePlan?.PlanId || null,

            // PCP Details
            PCPID: patientDetails?.PCPName?.PCPID || null,

            // Case Managr details
            CmAssigned: patientDetails?.CmAssigned,
            CaseManagerNotes: patientDetails?.CaseManagerNotes || "",
          };

          dispatch(
            updateCMPatientDetailsActions.updateCMPatientDetailsRequest(
              request,
              userData.token
            )
          );
        }
      }
    }
  };

  useEffect(() => {
    let query = {
      patientId: patientDetails1.PatientId,
      admissionId: patientDetails1.parentAdmissionId,
      userId: userData?.UserID,
      roleId: userData?.RoleID,
    };
    let queryParams = {
      token: userData.token,
      tableName: "Provider",
    };
    dispatch(dynamicLookupRequest(queryParams));

    dispatch(
      getCMPatientDetailsActions.getCMPatientDetailsRequest(
        query,
        userData.token
      )
    );
  }, [updateCMPatientDetails]);
  useEffect(() => {
    if (
      userData.token !== undefined &&
      patientDetails1.PatientId !== undefined &&
      patientDetails1.parentAdmissionId
    ) {
      let query = {
        patientId: patientDetails1.PatientId,
        admissionId: patientDetails1.parentAdmissionId,
        userId: userData?.UserID,
        roleId: userData?.RoleID,
      };

      dispatch(
        getCMPatientDetailsActions.getCMPatientDetailsRequest(
          query,
          userData.token
        )
      );
      dispatch(
        relationshipListLookupCmerActions?.relationshipListLookupCmerRequest(
          userData?.token
        )
      );
      dispatch(
        insuranceCompanyListLookupCmerActions?.insuranceListLookupCmerRequest(
          userData?.token
        )
      );
    } else {
      navigate("casemanager");
    }
  }, [userData?.token]);

  useEffect(() => {
    if (userData?.token && searchString?.length > 0) {
      let request = {
        search: searchString || "a",
      };
      dispatch(pcpLookupCmerActions.pcpLookupRequest(request, userData.token));
    }
  }, [searchString]);
  useEffect(() => {
    if (patientDetails?.InsuranceCompany?.InsuranceCompanyId) {
      let request = {
        companyId: patientDetails?.InsuranceCompany?.InsuranceCompanyId,
      };
      dispatch(
        insurancePlanLookupCmerActions.insurancePlanLookupCmerRequest(
          request,
          userData?.token
        )
      );
    }
  }, [patientDetails.InsuranceCompany]);

  return (
    <>
      {getCMPatientDetails.loading === true ? (
        <Progress />
      ) : getCMPatientDetails.error ? (
        <NotFound error={getCMPatientDetails.error} />
      ) : (
        getCMPatientDetails.getAll !== undefined && (
          <div className={styles.container}>
            <div className={styles.col1}>
              <div className={styles.avatar_circle}></div>
            </div>

            <div className={styles.main_container1}>
              {/* <div className={styles.main}> */}
              {/* <div className={styles.col1}> */}
              <Accordion
                className={styles.accordion}
                defaultExpanded
                // expanded={expandBasicDetails}
                expanded={expandAccordian?.basicDetails}
              >
                <AccordionSummary
                  className={styles.accordion_summary}
                  expandIcon={<MdExpandMore className={styles.expandMore} />}
                  aria-controls="panel1a-content"
                  id="basic_details"
                  onClick={() => {
                    setExpandBasic(!expandBasicDetails);
                    expandAccordianModel("basic_details");
                  }}
                >
                  <h6 className={styles.note_label}>{"Basic details:"}</h6>
                </AccordionSummary>
                <AccordionDetails className={styles.accordion_details}>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <InputField
                        disabled={true}
                        label={strings.NAME}
                        // value={getCMPatientDetails?.getAll[0]?.PatientName}
                        value={patientDetails.PatientName}
                      />
                      <>
                        <InputField
                          value={patientDetails.DOB}
                          max="9999-12-31"
                          disabled
                          label={strings.DOB}
                        />
                        {errorDOB && (
                          <p className={styles.error}>{"required*"}</p>
                        )}
                      </>
                    </div>
                    <div className={styles.col}>
                      <InputField
                        label={strings.ADDRESS}
                        // value={getCMPatientDetails?.getAll[0]?.Address}
                        value={patientDetails.Address}
                        onChange={(evt) => setAddress(evt.target.value)}
                        disabled
                      />
                      <>
                        <InputField
                          value={patientDetails?.CellPhone || ""}
                          onChange={(evt) => {
                            setCellphoneNo(
                              evt.target.value.replace(/[^0-9]/g, "")
                            );
                            setPatientDetails({
                              ...patientDetails,
                              CellPhone: evt.target.value,
                            });
                          }}
                          // disabled
                          type="tel"
                          label={strings.CONTACT}
                        />
                        {errorPhoneNo && (
                          <p className={styles.error}>
                            {"10 digits required*"}
                          </p>
                        )}
                      </>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={styles.accordion}
                defaultExpanded
                expanded={expandAccordian?.lastAdmissionDetails}
              >
                <AccordionSummary
                  className={styles.accordion_summary}
                  expandIcon={<MdExpandMore className={styles.expandMore} />}
                  aria-controls="panel1a-content"
                  id="last_admission_details"
                  onClick={() => {
                    // setExpandAdmit(!expandAdmitDetails);
                    expandAccordianModel("last_admission_details");
                  }}
                >
                  <h6 className={styles.note_label}>
                    {"Last Admission details:"}
                  </h6>
                </AccordionSummary>
                <AccordionDetails className={styles.accordion_details}>
                  <div className={styles.main}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div className={styles.col1}>
                        <InputField
                          label={strings.HCC_SCORE}
                          type="number"
                          value={patientDetails?.HccScore || ""}
                          onChange={(evt) =>
                            setPatientDetails({
                              ...patientDetails,
                              HccScore: evt.target.value,
                            })
                          }
                        />
                        {errorHccScore && (
                          <p className={styles.error}>{"Required HCC*"}</p>
                        )}
                        <InputField
                          label={strings.MLR}
                          type="number"
                          // value={mlr || ""}
                          value={patientDetails?.Mlr || ""}
                          // onChange={(evt) => setMLR(evt.target.value)}
                          onChange={(evt) =>
                            setPatientDetails({
                              ...patientDetails,
                              Mlr: evt.target.value,
                            })
                          }
                          // disabled
                        />
                        {errorMlr && (
                          <p className={styles.error}>{"Required Mlr*"}</p>
                        )}
                        <AutoComplete
                          label={strings.PROVIDER}
                          getOptionLabel={(option) => option.ProviderName}
                          getOptionValue={(option) => option.ProviderId}
                          options={dynamicLookupData.getAll}
                          isLoading={dynamicLookupData.loading}
                          onInputChange={(val) => setSearchString(val)}
                          onChange={(val) => {
                            setPatientDetails({
                              ...patientDetails,
                              Provider: val,
                            });
                          }}
                          value={patientDetails?.Provider}
                        />
                        {errorProviderPCPID && (
                          <p className={styles.error}>{"Required PCP*"}</p>
                        )}
                      </div>
                      <div className={styles.col}>
                        <InputField
                          label={strings.FUNDING}
                          value={patientDetails?.Funding || ""}
                          onChange={(evt) => {
                            setFunding(evt.target.value);
                            setPatientDetails({
                              ...patientDetails,
                              Funding: evt.target.value,
                            });
                          }}
                          // disabled
                        />{" "}
                        {errorFunding && (
                          <p className={styles.error}>{"Required*"}</p>
                        )}
                        <InputField
                          label={strings.RISK_LVL}
                          type="number"
                          // value={riskLvl || ""}
                          value={patientDetails?.RiskLevel || ""}
                          onChange={(evt) => {
                            setRiskLvl(evt.target.value);

                            setPatientDetails({
                              ...patientDetails,
                              RiskLevel: evt.target.value,
                            });
                          }}
                          // disabled
                        />
                        {errorRiskLevel && (
                          <p className={styles.error}>{"Required*"}</p>
                        )}
                        <InputTypeField
                          disabled
                          minWidth={"70%"}
                          label={strings.ICD_10}
                          value={patientDetails?.ICDCodes}
                          // value={patientDetails.details.ICDCodes}
                        />
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={styles.accordion}
                defaultExpanded
                // expanded={expandCareDetails}
                expanded={expandAccordian?.careGiver}
              >
                <AccordionSummary
                  className={styles.accordion_summary}
                  expandIcon={<MdExpandMore className={styles.expandMore} />}
                  aria-controls="panel1a-content"
                  id="caregiver_details"
                  onClick={() => {
                    setExpandCare(!expandCareDetails);
                    expandAccordianModel("caregiver_details");
                  }}
                >
                  <h6 className={styles.note_label}>
                    {strings.CAREGIVER_DETAILS}
                  </h6>
                </AccordionSummary>
                <AccordionDetails className={styles.accordion_details}>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <InputField
                        label={strings.CAREGIVER_FNAME}
                        // value={getCMPatientDetails?.getAll[0]?.CaregiverFName}
                        value={patientDetails?.CaregiverFName}
                        onChange={(evt) => {
                          setCaregiverFName(evt.target.value);
                          setPatientDetails({
                            ...patientDetails,
                            CaregiverFName: evt.target.value,
                          });
                        }}
                        // disabled
                      />
                      {errorCaregiverFName && (
                        <p className={styles.error}>{"Required*"}</p>
                      )}
                      <InputField
                        label={strings.CAREGIVER_LNAME}
                        // value={getCMPatientDetails?.getAll[0]?.CaregiverLName}
                        value={patientDetails?.CaregiverLName}
                        onChange={(evt) => {
                          setCaregiverLName(evt.target.value);
                          setPatientDetails({
                            ...patientDetails,
                            CaregiverLName: evt.target.value,
                          });
                        }}
                        // disabled
                      />
                      {errorCaregiverLName && (
                        <p className={styles.error}>{"Required*"}</p>
                      )}
                    </div>
                    <div className={styles.col}>
                      <>
                        <InputField
                          type="tel"
                          label={strings.CAREGIVER_CNUMBER}
                          value={patientDetails?.CaregiverPhoneNo}
                          onChange={(evt) => {
                            setCaregiverPhoneNo(
                              evt.target.value.replace(/[^0-9]/g, "")
                            );
                            setPatientDetails({
                              ...patientDetails,
                              CaregiverPhoneNo: evt.target.value,
                            });
                          }}
                          // disabled
                        />

                        {errorCaregiverPhoneNo && (
                          <p className={styles.error}>
                            {"10 digits required*"}
                          </p>
                        )}
                      </>

                      <Selector
                        label={strings.RELATIONSHIP}
                        list={relationshiplistLookupCmer.getAll}
                        onChange={(evt) => {
                          setPatientDetails({
                            ...patientDetails,
                            // CaregiverRelationship: evt.target.value,
                            CaregiverRelationship:
                              relationshiplistLookupCmer?.getAll?.find(
                                (item) => item.Relationship === evt.target.value
                              ),
                          });
                        }}
                        // value={relationshipId}
                        value={
                          patientDetails?.CaregiverRelationship?.Relationship
                        }
                      />
                      {errorCaregiverRelationshipID && (
                        <p className={styles.error}>{"Required*"}</p>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              {/* <Accordion
                className={styles.accordion}
                expanded={expandAccordian?.insuranceDetails}
              >
                <AccordionSummary
                  className={styles.accordion_summary}
                  expandIcon={<MdExpandMore className={styles.expandMore} />}
                  aria-controls="panel1a-content"
                  id="insurance_details"
                  onClick={() => {
                    expandAccordianModel("insurance_details");
                  }}
                >
                  <h6 className={styles.note_label}>
                    {strings.INSURANCE_DETAILS}
                  </h6>
                </AccordionSummary>
                <AccordionDetails className={styles.accordion_details}>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <AutoComplete
                        options={insuranceCompanylistLookupCmer?.getAll || []}
                        getOptionLabel={(option) => option.CompanyName}
                        getOptionValue={(option) => option.InsuranceCompanyId}
                        isLoading={insuranceCompanylistLookupCmer?.loading}
                        onChange={(val) => {
                          setInsuranceCompney(val);
                          setPatientDetails((prev) => ({
                            ...prev,
                            InsuranceCompany: val,
                          }));
                          // insurancePlans(val);
                        }}
                        label={strings.COMPANYNAME}
                        // value={insuranceCompney}
                        value={patientDetails?.InsuranceCompany}
                      />
                      {errorInsuranceCompanyID && (
                        <p className={styles.error}>{"Required*"}</p>
                      )}
                    </div>
                    <div className={styles.col}>
                      <AutoComplete
                        options={insurancePlanLookupCmer?.getAll || []}
                        getOptionLabel={(option) => option.Plan}
                        getOptionValue={(option) => option.PlanId}
                        isLoading={insurancePlanLookupCmer?.loading}
                        onChange={(val) => {
                          setInsurancePlanVal(val);
                          setPatientDetails((prev) => ({
                            ...prev,
                            InsurancePlan: val,
                          }));
                        }}
                        label={strings.PLAN}
                        // value={insurancePlanVal}
                        value={patientDetails?.InsurancePlan}
                      />
                      {errorInsurancePlanID && (
                        <p className={styles.error}>{"Required*"}</p>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion> */}
              <Accordion
                className={styles.accordion}
                expanded={expandAccordian?.pcpDetails}
              >
                <AccordionSummary
                  className={styles.accordion_summary}
                  expandIcon={<MdExpandMore className={styles.expandMore} />}
                  aria-controls="panel1a-content"
                  id="pcp_details"
                  expanded={expandAccordian?.pcpDetails}
                  onClick={() => {
                    expandAccordianModel("pcp_details");
                  }}
                >
                  <h6 className={styles.note_label}>{"PCP details:"}</h6>
                </AccordionSummary>
                <AccordionDetails className={styles.accordion_details}>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <AutoComplete
                        label={strings.PCP}
                        getOptionLabel={(option) => option.PCP}
                        getOptionValue={(option) => option.PCPID}
                        options={pcpLookupCmer.getAll}
                        isLoading={pcpLookupCmer.loading}
                        value={patientDetails?.PCPName}
                        onInputChange={(val) => {
                          setSearchString(val);
                        }}
                        onChange={(val) => {
                          setPatientDetails({
                            ...patientDetails,
                            PCPName: val,
                          });
                        }}
                      />
                      {errorPCPNameID && (
                        <p className={styles.error}>{"Required Note*"}</p>
                      )}
                      <InputField
                        disabled={true}
                        label={strings.PCP_ADDRESS}
                        value={patientDetails?.PCPAddress}
                      />{" "}
                    </div>
                    <div className={styles.col}>
                      <InputField
                        disabled={true}
                        label={strings.PCP_PHONE}
                        value={patientDetails?.PCPPhone}
                      />
                      <InputField
                        disabled={true}
                        type="date"
                        label={strings.EFFACTIVE_DATE}
                        max="9999-12-31"
                        value={patientDetails?.PCPEffectiveDate}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                className={styles.accordion}
                expanded={expandAccordian?.cmDetails}
              >
                <AccordionSummary
                  className={styles.accordion_summary}
                  expandIcon={<MdExpandMore className={styles.expandMore} />}
                  aria-controls="panel1a-content"
                  id="cm_details"
                  onClick={() => {
                    expandAccordianModel("cm_details");
                  }}
                >
                  <h6 className={styles.note_label}>{strings.CM_DETAILS}</h6>
                </AccordionSummary>
                <AccordionDetails className={styles.accordion_details}>
                  <div className={styles.row}>
                    <div className={styles.col}>
                      <div className={styles.checkboxCointainer}>
                        <label>{strings.CM_ASSIGNED}:</label>
                        <div className={styles.checkbox}>
                          <input
                            type={"checkbox"}
                            // checked={cmAssgined}
                            checked={patientDetails?.CmAssigned}
                            onChange={(evt) => {
                              setPatientDetails({
                                ...patientDetails,
                                CmAssigned: evt.target.checked,
                              });
                              setCmAssigned(evt.target.checked);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={styles.accordion}
                expanded={expandAccordian?.notes}
              >
                <AccordionSummary
                  expandIcon={<MdExpandMore className={styles.expandMore} />}
                  aria-controls="panel1a-content"
                  id="cm_notes"
                  onClick={() => {
                    expandAccordianModel("cm_notes");
                  }}
                >
                  <h6 className={styles.note_label}>{strings.CM_NOTES}</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <textarea
                    rows="2"
                    placeholder="Type a note"
                    className={styles.notes}
                    value={patientDetails?.CaseManagerNotes}
                    onChange={(evt) => {
                      setTestNotes(evt.target.value);
                      setPatientDetails({
                        ...patientDetails,
                        CaseManagerNotes: evt.target.value,
                      });
                    }}
                  />{" "}
                  {errorCaseManagerNotes && (
                    <p className={styles.error}>{"Required Note*"}</p>
                  )}
                </AccordionDetails>
              </Accordion>

              <Button
                label={"Update"}
                loading={updateCMPatientDetails.updating}
                onClick={perfromUpdateInfo}
              />
            </div>
          </div>
        )
      )}
    </>
  );
};

export default PatientBasicInfoCM;
