import { put, takeLatest, call } from "@redux-saga/core/effects";
import { visitDetailsActions } from "../actions/visitDetails.action";
import { visitDetailsType } from "../types/visitDetails.type";
import { endpoint } from "../../../../api/Url";
import { Api } from "../../../../api";
import { loginActions } from "../../../Login/actions/login.action";
export function* watchPatientVisits() {
  yield takeLatest(visitDetailsType.VISIT_DETAILS_REQUEST, getVisits);
}

function* getVisits(action) {
  try {
    //   api call
    const data = yield call(Api, {
      endpoint: endpoint.visitDetails,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    const parsedData = JSON.parse(data);
    yield put(visitDetailsActions.visitDetailsRequestSuccess(parsedData));
    // if (parsedData.length > 0) {
    //   yield put(visitDetailsActions.visitDetailsRequestSuccess(parsedData));
    // } else {
    //   yield put(
    //     visitDetailsActions.visitDetailsRequestFailure("No data found")
    //   );
    // }
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(visitDetailsActions.visitDetailsRequestFailure(error));
  }
}
