import { tasksDetailsType } from "../types/tasksDetails.type";

export const tasksDetailsActions = {
  tasksDetailsRequest,
  tasksDetailsRequestSuccess,
  tasksDetailsRequestFailure,
};

function tasksDetailsRequest(request, token) {
  return { type: tasksDetailsType.CALL_TASKS_DETAILS_REQUEST, request, token };
}
function tasksDetailsRequestSuccess(tasksDetails, lstGroup) {
  return {
    type: tasksDetailsType.CALL_TASKS_DETAILS_REQUEST_SUCCESS,
    tasksDetails,
    lstGroup,
  };
}
function tasksDetailsRequestFailure(error) {
  return { type: tasksDetailsType.CALL_TASKS_DETAILS_REQUEST_FAILURE, error };
}
