import { getAllDuplicatePatientsTypes } from "../types";

function getAllDuplicatePatientsRequest(query, token) {
  return {
    type: getAllDuplicatePatientsTypes.GETALL_DUPLICATE_PATIENTS_REQUEST,
    query,
    token,
  };
}

function getAllDuplicatePatientsSuccess(patients) {
  return {
    type: getAllDuplicatePatientsTypes.GETALL_DUPLICATE_PATIENTS_SUCCESS,
    patients,
  };
}

function getAllDuplicatePatientsFailure(error) {
  return {
    type: getAllDuplicatePatientsTypes.GETALL_DUPLICATE_PATIENTS_FAILURE,
    error,
  };
}

export const getAllDuplicatePatientsActions = {
  getAllDuplicatePatientsRequest,
  getAllDuplicatePatientsSuccess,
  getAllDuplicatePatientsFailure,
};
