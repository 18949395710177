import { updateBillingCptModifiersType } from "../types";

export const updateCptModifierActions = {
  updateCptModifierRequest,
  updateCptModifierSuccess,
  updateCptModifierFailure,
  updateCptModifierReset,
};
function updateCptModifierRequest(request, token) {
  return {
    type: updateBillingCptModifiersType.UPDATE_CPT_MODIFIERS_REQUEST,
    request,
    token,
  };
}
function updateCptModifierSuccess(message) {
  return {
    type: updateBillingCptModifiersType.UPDATE_CPT_MODIFIERS_SUCCESS,
    message,
  };
}
function updateCptModifierFailure(error) {
  return {
    type: updateBillingCptModifiersType.UPDATE_CPT_MODIFIERS_FAILURE,
    error,
  };
}
function updateCptModifierReset() {
  return {
    type: updateBillingCptModifiersType.UPDATE_CPT_MODIFIERS_RESET,
  };
}
