export const powerbiDashboardType = {
  //for POWERBI
  POWERBI_REQUEST: "POWERBI_REQUEST",
  POWERBI_REQUEST_RESET: "POWERBI_REQUEST_RESET",
  POWERBI_REQUEST_SUCCESS: "POWERBI_REQUEST_SUCCESS",
  POWERBI_REQUEST_FAILURE: "POWERBI_REQUEST_FAILURE",
};
export const powerbiDashboardByIdType = {
  //for POWERBI
  POWERBI_REPORT_BY_ID_REQUEST: "POWERBI_REPORT_BY_ID_REQUEST",
  POWERBI_REPORT_BY_ID_RESET: "POWERBI_REPORT_BY_ID_RESET",
  POWERBI_REPORT_BY_ID_SUCCESS: "POWERBI_REPORT_BY_ID_SUCCESS",
  POWERBI_REPORT_BY_ID_FAILURE: "POWERBI_REPORT_BY_ID_FAILURE",
};

export const powerbiDashboardTabsType = {
  POWERBI_DASHBOARD_TABS_REQUEST: "POWERBI_DASHBOARD_TABS_REQUEST",
  POWERBI_DASHBOARD_TABS_SUCCESS: "POWERBI_DASHBOARD_TABS_SUCCESS",
  POWERBI_DASHBOARD_TABS_FAILURE: "POWERBI_DASHBOARD_TABS_FAILURE",
  POWERBI_DASHBOARD_TABS_RESET: "POWERBI_DASHBOARD_TABS_RESET",
};
