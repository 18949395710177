import { takeEvery, takeLatest, call, put } from "redux-saga/effects";
import { pcpHierarchyTypes } from "../types/pcphierarchy.type";
import { endpoint } from "../../../../../api/Url";
import { pcpHierarchyActions } from "../actions/pcphierarchy.action";
import { Api2 } from "../../../../../api/Api2";

export function* watchDMPCPHierarchy() {
  yield takeLatest(pcpHierarchyTypes.PCP_HIERARCHY_REQUEST, getAllPCPHierarchy);
  yield takeLatest(
    pcpHierarchyTypes.PCP_HIERARCHY_EDIT_REQUEST,
    editPCPHierarchy
  );
  yield takeLatest(
    pcpHierarchyTypes.PCP_HIERARCHY_SAVE_REQUEST,
    createPCPHierarchy
  );
  yield takeLatest(
    pcpHierarchyTypes.PCP_HIERARCHY_UPDATE_STATUS_REQUEST,
    updatePCPHierarchyStatus
  );
}

//getall pcpHierarchyActions

function* getAllPCPHierarchy(action) {
  try {
    console.log("getaction", action);
    let res = yield call(Api2, {
      endpoint: `${endpoint.pcphierarchy}?nationId=${action.query.nationId}&regionId=${action.query.regionId}&ipaId=${action.query.ipaId}&pcpGroupId=${action.query.pcpgroupId}&pcpOfficeId=${action.query.PCPOfficeId}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });
    console.log("result", res.data);
    if (res.data !== undefined && res.data.length > 0) {
      yield put(pcpHierarchyActions.pcphierarchyRequestSuccess(res.data));
    } else {
      yield put(
        pcpHierarchyActions.pcphierarchyRequestFailure("No data found")
      );
    }
  } catch (error) {
    yield put(pcpHierarchyActions.pcphierarchyRequestFailure(error));
  }
}

//update/edit pcpHierarchyActions
function* editPCPHierarchy(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.pcphierarchy}/${action.query}`,
      method: "PATCH",
      jwt: "Bearer " + action.token,
      body: action.payload,
    });

    yield put(
      pcpHierarchyActions.pcphierarchyEditRequestSuccess(response.message)
    );
  } catch (error) {
    yield put(pcpHierarchyActions.pcphierarchyEditRequestFailure(error));
  }
}

//create pcpHierarchyActions
function* createPCPHierarchy(action) {
  try {
    console.log("actionn", action);
    let response = yield call(Api2, {
      endpoint: `${endpoint.pcphierarchy}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    console.log("response", response);
    yield put(
      pcpHierarchyActions.pcphierarchySaveRequestSuccess(response.message)
    );
  } catch (error) {
    yield put(pcpHierarchyActions.pcphierarchySaveRequestFailure(error));
  }
}
//update status
function* updatePCPHierarchyStatus(action) {
  console.log("action", action);
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.pcphierarchy}/${action.query.pcphierarchyId}`,
      method: "PUT",
      jwt: "Bearer " + action.token,
      body: action.payload,
    });
    console.log("response", response);
    yield put(
      pcpHierarchyActions.pcphierarchyUpdateStatusRequestSuccess(
        action.query,
        action.payload,
        response.message
      )
    );
  } catch (error) {
    yield put(
      pcpHierarchyActions.pcphierarchyUpdateStatusRequestFailure(error)
    );
  }
}
