import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";

import { Url, endpoint } from "../../../../../api/Url";
import { getFileDataActions } from "../actions/getFileData.action";
import { getFileDataType } from "../types/getFileData.type";

import { fileProcessingActions } from "../actions/fileProcessing.action";
import { fileProcessingType } from "../types/fileprocessing.type";
import { alertActions } from "../../../../../components/AlertNew/store/AlertNew.action";

export function* watchFileProcessing() {
  yield takeLatest(getFileDataType.GET_FILE_DATA_REQUEST, getFileData);
  yield takeLatest(
    fileProcessingType.FILE_PROCESSING_REQUEST,
    fileProcessingData
  );
}

function* getFileData(action) {
  let urlEndpoint =
    action.query.fileType !== 5
      ? `${Url.baseURL2}${endpoint.bpoPatientAssignment}?read=${action.query.read}&write=${action.query.write}&fileType=${action.query.fileType}`
      : `${Url.baseURL2}${endpoint.azureNomcPdf}?read=${action.query.read}&write=${action.query.write}`;

  try {
    let response = yield call(axios, {
      method: "POST",
      url: urlEndpoint,
      data: action.request,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + action.token,
      },
      timeout: 1800000, // 30 minutes
    });

    if (action.query.fileType !== 5) {
      yield put(
        getFileDataActions.getFileDataSuccess(
          response.data.data,
          response.data.totalrecords
        )
      );
    } else {
      if (
        response.data !== undefined &&
        response?.data?.message !== undefined
      ) {
        let res = response?.data?.message || [];

        let filteredRes = res.filter((element) => element !== undefined);

        yield put(
          getFileDataActions.getFileDataSuccess(filteredRes, filteredRes.length)
        );
      }
    }
  } catch (error) {
    console.log("nomc", error);
    yield put(getFileDataActions.getFileDataFailure(error));
  }
}

function* fileProcessingData(action) {
  let urlEndpoint =
    action.query.fileType !== 5
      ? `${Url.baseURL2}${endpoint.bpoPatientAssignment}?read=${action.query.read}&write=${action.query.write}&fileType=${action.query.fileType}`
      : `${Url.baseURL2}${endpoint.storepatientNomc}`;
  try {
    let response = yield call(axios, {
      method: "POST",
      url: urlEndpoint,
      data: action.request,
      headers: {
        "Content-Type":
          action.query.fileType === 5
            ? "application/json"
            : "multipart/form-data",
        Authorization: "Bearer " + action.token,
      },
      timeout: 1800000, // for 30 minutes
    });
    yield put(
      fileProcessingActions.FileProcessSuccess(
        response.data.message,
        response.data.records
      )
    );
    yield put(alertActions.alertSuccessRequest(response.data.message));
  } catch (error) {
    console.log(error);
    yield put(alertActions.alertErrorRequest(error.message));
    yield put(fileProcessingActions.FileProcessFailure(error));
  }
}
