import React from "react";
import TextFieldSearch from "../../searchNew/TextFieldSearch";
import { colors } from "../../../themes/colors";
import { TextField } from "@mui/material";

const StringTextField = ({
  errors,
  size = "small",
  placeholder = "",
  type = "text",
  value = "",
  inputProps,
  onChange = null,
  sx = null,
  isError,
  ...props
}) => {
  return (
    <TextField
      fullWidth
      type={type}
      value={value}
      onChange={onChange}
      sx={{
        width: "100%",
        boxShadow: "none",
        ".MuiOutlinedInput-notchedOutline": { border: "none" },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        background: colors.color_primary,
        border: isError ? `1.5px solid ${colors?.color_error_txt}` : "none",
        ".MuiOutlinedInput-input": {
          p: 0.7,
        },
        ...sx,
      }}
      autoComplete="off"
      autoCorrect="off"
      // autoFocus={true}
      size={size}
      InputLabelProps={{
        shrink: true,
        style: { color: colors.color_primary_txt, fontSize: 14 },
      }}
      InputProps={{
        style: { color: colors.color_primary_txt, fontSize: 14 },
      }}
      label=""
      variant="outlined"
      placeholder={placeholder}
      {...inputProps}
      {...props}
    />
  );
};

export default StringTextField;
