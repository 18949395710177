import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { colors } from "../../../themes/colors";
import { dateTimeGetter } from "../../../util";
import { MdOutlineEditNote } from "react-icons/md";
import { MdOutlineEditOff } from "react-icons/md";

const ProgressHeader = ({
  handleProgressDateChange,
  value,
  data,
  handleCopyProgressUpdate,
  handleEdit,
  isEditable,
  handleCancelEdit,
}) => {
  return (
    <Grid container justifyContent="flex-end" columnGap={1}>
      {" "}
      <Grid item xs={4} lg={3}>
        <Select
          value={value || ""}
          onChange={handleProgressDateChange("progressingAsExpected")}
          sx={{
            width: "100%",
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline": { border: "none" },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            border: `1px solid ${colors.color_light_txt}`,
            "& .MuiSelect-select": {
              padding: "8px 12px",
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 200, // Adjust based on your needs
                overflow: "auto",
              },
            },
          }}
        >
          <MenuItem value="" disabled>
            {Array.isArray(data) && data?.length > 0 ? (
              <em>History</em>
            ) : (
              <em>No History</em>
            )}{" "}
          </MenuItem>
          {data?.map(({ id, progressDate }) => (
            <MenuItem key={id} value={id}>
              {progressDate}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {/* <Grid
        container
        xs={0.5}
        justifyContent="center"
        alignItems="center"
        sx={{
          "&:hover": { scale: 1.5 },
          backgroundColor: colors.color_background,
          p: 1,
        }}
      >
        <IoMdCopy
          onClick={handleCopyProgressUpdate}
          style={{ fontSize: 24, "&:hover": { scale: 1.5 }, cursor: "copy" }}
        />
      </Grid> */}
      <Grid
        container
        xs={0.5}
        justifyContent="center"
        alignItems="center"
        sx={{
          color: isEditable
            ? colors.color_background
            : colors.color_primary_txt,
          backgroundColor: isEditable
            ? colors.color_primary_txt
            : colors.color_background,
          borderRadius: 2,
          opacity: 0.8,

          //   p: 1,
        }}
      >
        {!isEditable ? (
          <MdOutlineEditNote
            onClick={handleEdit}
            style={{
              fontSize: 28,
              cursor: "pointer",
            }}
          />
        ) : (
          <MdOutlineEditOff
            onClick={handleCancelEdit}
            style={{
              fontSize: 24,
              cursor: "pointer",
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ProgressHeader;
