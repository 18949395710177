import { put, takeLatest, call } from "@redux-saga/core/effects";
import { endpoint } from "../../../../../api/Url";
import { roleTypes } from "../types";
import { roleActions } from "../actions";
import { Api2 } from "../../../../../api/Api2";
import { Api } from "../../../../../api";

export function* watchDMROLE() {
  yield takeLatest(roleTypes.ROLE_REQUEST, getAllROLE);
}

// function* getAllROLE(action) {
//   try {
//     let response = yield call(Api2, {
//       endpoint: endpoint.getDMRoleData,
//       method: "GET",
//       jwt: "Bearer " + action.token,
//     });
//     console.log(response);
//     yield put(roleActions.roleRequestSuccess(response.data));
//   } catch (error) {
//     yield put(roleActions.roleRequestFailure(error));
//   }
// }

function* getAllROLE(action) {
  try {
    let data = yield call(Api, {
      endpoint: endpoint.getDMRoleData,
      method: "POST",
      jwt: "Bearer " + action.token,
    });

    let parsedData = JSON.parse(data);

    if (parsedData.RoleList.length > 0) {
      yield put(roleActions.roleRequestSuccess(parsedData.RoleList));
    } else {
      yield put(roleActions.roleRequestFailure("No data found"));
    }
  } catch (error) {
    yield put(roleActions.roleRequestFailure(error));
  }
}
