import { setAppointmentType } from "../types/setAppointment.type";

export const setAppointmentActions = {
  setAppointmentRequest,
  setAppointmentRequestSuccess,
  setAppointmentRequestReset,
  setAppointmentRequestFailure,
};

function setAppointmentRequest(request, token) {
  return { type: setAppointmentType.SET_APPOINTMENT_REQUEST, request, token };
}

function setAppointmentRequestSuccess(message) {
  return { type: setAppointmentType.SET_APPOINTMENT_REQUEST_SUCCESS, message };
}

function setAppointmentRequestReset() {
  return { type: setAppointmentType.SET_APPOINTMENT_REQUEST_RESET };
}

function setAppointmentRequestFailure(error) {
  return { type: setAppointmentType.SET_APPOINTMENT_REQUEST_FAILURE, error };
}
