import { callHistoryType } from "../types/callHistory.type";
export const callHistoryActions = {
  callHistoryRequest,
  callHistoryRequestSuccess,
  callHistoryRequestFailure,
};

function callHistoryRequest(request, token) {
  return { type: callHistoryType.CALL_HISTORY_REQUEST, request, token };
}
function callHistoryRequestSuccess(callHistory) {
  return {
    type: callHistoryType.CALL_HISTORY_REQUEST_SUCCESS,
    callHistory,
  };
}
function callHistoryRequestFailure(error) {
  return { type: callHistoryType.CALL_HISTORY_REQUEST_FAILURE, error };
}
