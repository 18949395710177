import { dashboardReportTypes } from "../types";

const initialState = {
  loading: false,
  getAll: [],
  error: "",
};

export const dashboardReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case dashboardReportTypes.DASHBOARD_REPORT_REQUEST:
      return { ...initialState, loading: true };
    case dashboardReportTypes.DASHBOARD_REPORT_REQUEST_SUCCESS:
      return { ...initialState, getAll: action.getAll };
    case dashboardReportTypes.DASHBOARD_REPORT_REQUEST_FAILURE:
      return { ...initialState, error: action.error };

    default:
      return state;
  }
};
