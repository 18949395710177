import { medicationType } from "../types/medication.type";

export const medicationActions = {
  medicationRequest,
  medicationRequestSuccess,
  medicationRequestFailure,
};

function medicationRequest(request, token) {
  return { type: medicationType.MEDICATION_REQUEST, request, token };
}

function medicationRequestSuccess(medication) {
  return { type: medicationType.MEDICATION_REQUEST_SUCCESS, medication };
}

function medicationRequestFailure(error) {
  return { type: medicationType.MEDICATION_REQUEST_FAILURE, error };
}
