import { ResetTvRounded } from "@mui/icons-material";
import { ipaTypes } from "../types/ipa.type";
const initialState = {
  loading: false,
  getAll: [],
  error: "",
  message: "",
};

export const ipaDMReducer = (state = initialState, action) => {
  switch (action.type) {
    case ipaTypes.IPA_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case ipaTypes.IPA_REQUEST_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
      };
    case ipaTypes.IPA_REQUEST_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    //create IPA
    case ipaTypes.IPA_SAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ipaTypes.IPA_SAVE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
      };
    case ipaTypes.IPA_SAVE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ipaTypes.IPA_REQUEST_RESET:
      return { ...state, loading: false, message: "", error: "" };

    //edit IPA
    case ipaTypes.IPA_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ipaTypes.IPA_EDIT_REQUEST_SUCCESS:
      let index = state.getAll.findIndex((item) => (item.ipaId = action.id));
      const newArr = [...state.getAll];
      const updateIPA = { ...newArr[index], ...action.data };
      newArr[index] = updateIPA;
      return {
        ...state,
        loading: false,
        message: action.message,
        getAll: newArr,
      };
    case ipaTypes.IPA_EDIT_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    //update status
    case ipaTypes.IPA_UPDATE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ipaTypes.IPA_UPDATE_STATUS_REQUEST_SUCCESS:
      let index1 = state.getAll.findIndex((item) => item.ipaId === action.id);
      const newArr1 = [...state.getAll];
      const ipa = {
        ...newArr1[index1],
        active: !newArr1[index1].active,
      };
      newArr1[index1] = ipa;
      return {
        ...state,
        loading: false,
        message: action.message,
        getAll: newArr1,
      };
    case ipaTypes.IPA_UPDATE_STATUS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};
