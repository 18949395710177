import {roleTypes} from "../types";

export const roleActions = {
    roleRequest,
    roleRequestSuccess,
    roleRequestFailure,
   };
   function roleRequest(request, token) {
     return {
       type: roleTypes.ROLE_REQUEST,
       request,
       token,
     };
   }
   function roleRequestSuccess(getAll) {
     return {
       type: roleTypes.ROLE_REQUEST_SUCCESS,
       getAll,
     };
   }
   function roleRequestFailure(error) {
     return {
       type: roleTypes.ROLE_REQUEST_FAILURE,
       error,
     };
   }
   