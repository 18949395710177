import { chargesSaveType } from "../types";

const initialState = {
  loading: false,
};

export const chargesSaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case chargesSaveType.CHARGES_SAVE_REQUEST:
      return { ...initialState, loading: true };
    case chargesSaveType.CHARGES_SAVE_REQUEST_SUCCESS:
      return {
        ...initialState,
        message: action.message,
      };
    case chargesSaveType.CHARGES_SAVE_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    case chargesSaveType.CHARGES_SAVE_REQUEST_RESET:
      return { ...initialState };
    default:
      return state;
  }
};
