import { put, call, takeLatest } from "@redux-saga/core/effects";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { problemListActions } from "../actions";
import { problemListTypes } from "../types";
import { Api2 } from "../../../../api/Api2";
export function* watchProblemList() {
  yield takeLatest(problemListTypes.GETALL_PROBLEM_REQUEST, getAllProblemList);
  yield takeLatest(
    problemListTypes.OPERARTION_PROBLEM_REQUEST,
    operationProblemList
  );
  yield takeLatest(problemListTypes.DELETE_PROBLEM_REQUEST, deleteProblemList);
}
function* getAllProblemList(action) {
  try {
    let data = yield call(Api2, {
      endpoint: `${endpoint.awvGetProblemList}?patientId=${action.request.patientId}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });

    yield put(
      problemListActions.problemListGetAllRequestSuccess(data?.problemlist)
    );
  } catch (error) {
    yield put(problemListActions.problemListGetAllRequestFailure(error));
  }
}
function* operationProblemList(action) {
  try {
    let message = yield call(Api, {
      endpoint: `${endpoint.saveProblemListDetails}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedMessage = JSON.parse(message);

    yield put(
      problemListActions.problemListOperationRequestSuccess(parsedMessage[0])
    );
  } catch (error) {
    yield put(problemListActions.problemListOperationRequestFailure(error));
  }
}

function* deleteProblemList(action) {
  try {
    let message = yield call(Api, {
      endpoint: `${endpoint.deletePatientProblemListDetails}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedMessage = JSON.parse(message);

    yield put(
      problemListActions.problemListDeleteRequestSuccess(parsedMessage[0])
    );
  } catch (error) {
    yield put(problemListActions.problemListDeleteRequestFailure(error));
  }
}
