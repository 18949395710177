import { put, call, takeLatest } from "@redux-saga/core/effects";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { surgicalHistoryActions } from "../actions";
import { surgicalHistoryTypes } from "../types";
export function* watchSurgicalHistory() {
  yield takeLatest(
    surgicalHistoryTypes.GETALL_SURGICAL_HISTORY_REQUEST,
    getAllSurgicalHistory
  );
  yield takeLatest(
    surgicalHistoryTypes.OPERARTION_SURGICAL_HISTORY_REQUEST,
    operationSurgicalHistory
  );
  yield takeLatest(
    surgicalHistoryTypes.DELETE_SURGICAL_REQUEST,
    deleteSurgical
  );
}
function* getAllSurgicalHistory(action) {
  try {
    let data = yield call(Api, {
      endpoint: `${endpoint.getSurgicalHistoryDetails}?patientId=${action.request.patientId}`,
      method: "POST",
      jwt: "Bearer " + action.token,
    });
    let parsedImmunization = JSON.parse(data);

    yield put(
      surgicalHistoryActions.surgicalHistoryGetAllRequestSuccess(
        parsedImmunization._lstSurgicalHistory,
        parsedImmunization._dropdownSurgeryType,
        parsedImmunization._dropdownSourceListModel
      )
    );
  } catch (error) {
    yield put(
      surgicalHistoryActions.surgicalHistoryGetAllRequestFailure(error)
    );
  }
}
function* operationSurgicalHistory(action) {
  try {
    let message = yield call(Api, {
      endpoint: `${endpoint.savePatientSurgicalHistoryDetails}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedMessage = JSON.parse(message);

    yield put(
      surgicalHistoryActions.surgicalHistoryOperationRequestSuccess(
        parsedMessage[0]
      )
    );
  } catch (error) {
    yield put(
      surgicalHistoryActions.surgicalHistoryOperationRequestFailure(error)
    );
  }
}

function* deleteSurgical(action) {
  try {
    let message = yield call(Api, {
      endpoint: `${endpoint.deletePatientSurgicalHistoryDetails}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedMessage = JSON.parse(message);

    yield put(
      surgicalHistoryActions.surgicalHistoryDeleteRequestSuccess(
        parsedMessage[0]
      )
    );
  } catch (error) {
    yield put(
      surgicalHistoryActions.surgicalHistoryDeleteRequestFailure(error)
    );
  }
}
