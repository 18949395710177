import { call, put, debounce } from "redux-saga/effects";
import { Api2 } from "../../../../api/Api2";
import { endpoint } from "../../../../api/Url";
import {
  getNursingProgressFailure,
  getNursingProgressSuccess,
  updateNursingProgressFailure,
  updateNursingProgressSuccess,
} from "../Reducer/progress.slice";
import { alertActions } from "../../../../components/AlertNew/store/AlertNew.action";

// Watcher saga
export function* watchNursingProgress() {
  yield debounce(
    500,
    "getNursingProgress/getNursingProgressRequest",
    getNursingProgress
  );
  yield debounce(
    500,
    "updateNursingProgress/updateNursingProgressRequest",
    updateNursingProgress
  );
}

// Worker saga for fetching nursing progress
function* getNursingProgress(action) {
  console.log(action, "progress");
  const { patientId, admissionId, progressId } = action.payload;

  let url = `${endpoint.nursingProgressUpdates}?patientId=${patientId}&admissionId=${admissionId}`;
  if (progressId !== null && progressId !== undefined) {
    url += `&progressId=${progressId}`;
  }

  try {
    const response = yield call(Api2, {
      endpoint: url, // Ensure the endpoint is correct
      method: "GET",
      jwt: `Bearer ${action.payload.token}`,
    });

    yield put(getNursingProgressSuccess(response));
  } catch (error) {
    yield put(getNursingProgressFailure(error));
  }
}

// Worker saga for updating nursing progress
function* updateNursingProgress(action) {
  console.log(action);

  try {
    const response = yield call(Api2, {
      endpoint: `${endpoint.nursingProgressUpdates}`, // Ensure the endpoint is correct
      method: "POST",
      jwt: `Bearer ${action.payload.token}`,
      body: action.payload.body, // Assuming you need to send some data for POST request
    });
    console.log(response);

    yield put(updateNursingProgressSuccess(response));
    yield put(alertActions.alertSuccessRequest(response?.message));
  } catch (error) {
    yield put(updateNursingProgressFailure(error));
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}
