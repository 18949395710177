import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lookupsData: {},
};

const lookupsState = {
  loading: false,
  error: null,
  data: [],
};

export const lookupDynamicSlice = createSlice({
  name: "lookupDynamic",
  initialState,
  reducers: {
    lookupDynamicRequest: (state, action) => {
      const { tableName } = action.payload;
      state.lookupsData[tableName] = { ...lookupsState, loading: true };
    },
    lookupDynamicSuccess: (state, action) => {
      console.log(action);
      const { key, data } = action.payload;
      state.lookupsData[key] = { ...lookupsState, loading: false, data };
    },
    lookupDynamicFailure: (state, action) => {
      const { key, error } = action.payload;
      state.lookupsData[key] = { ...lookupsState, loading: false, error };
    },
    lookupDynamicReset: (state, action) => {
      const { key } = action.payload;
      state.lookupsData[key] = { ...lookupsState };
    },
  },
});

export const {
  lookupDynamicRequest,
  lookupDynamicSuccess,
  lookupDynamicFailure,
  lookupDynamicReset,
} = lookupDynamicSlice.actions;

export default lookupDynamicSlice.reducer;
