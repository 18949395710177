import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ButtonRow } from "../../../../../components";
import { AutoComplete } from "../AutoComplete";
import styles from "./AddCall.module.scss";

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextareaAutosize,
} from "@mui/material";
import { callHistoryOperationsCmActions } from "../../actions";
export const AddCall = ({
  show,
  setShow,
  selectedItem,
  isEditing,
  formDataErrors,
  setFormData,
  formData,
  setFormDataErrors,
  formDataKeys,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  console.log(state, "state");
  const userData = useSelector((state) => state.login.userData);
  const callActionTakenLookupCmer = useSelector(
    (state) => state.callActionTakenLookupCmer
  );
  const callDispositionLookupCmer = useSelector(
    (state) => state.callDispositionLookupCmer
  );
  const callHistoryOperationsCm = useSelector(
    (state) => state.callHistoryOperationsCm
  );
  //inputs

  const [errorCheckbox, setErrorCheckbox] = useState(false);

  const saveCallHistory = async () => {
    if (!validate()) {
      // setLoading(true);
      if (userData.token !== undefined) {
        let request;
        if (isEditing) {
          request = {
            actionIDs: `${formData?.actions?.map((val) => val.ActionID)}`,
            dispositionID: `${formData?.disposition?.DispositionID}`,
            callComments: formData?.callComments?.trim(),
            callComplete: formData?.callComplete || false,
            DNC: formData?.DNC || false,
            closeCase: formData?.closeCase || false,
            historyId: formData?.historyId,
            patientID: state?.patientId,
            parentAdmissionId: state?.patientDetails1?.parentAdmissionId,
            flag: "Edit",
          };
          console.log(request, "request", formData);
        } else {
          request = {
            actionIDs: `${formData?.actions?.map((val) => val.ActionID)}`,
            dispositionID: `${formData?.disposition?.DispositionID}`,
            callComments: formData?.callComments?.trim(),
            callComplete: formData?.callComplete || false,
            DNC: formData?.DNC || false,
            closeCase: formData?.closeCase || false,
            historyId: "",
            patientID: state?.patientId,
            parentAdmissionId: state?.patientDetails1?.parentAdmissionId,
          };
          console.log(request, "request", formData);
        }
        dispatch(
          callHistoryOperationsCmActions?.callHistoryOperationsCmRequest(
            request,
            userData?.token
          )
        );
        // dispatch(
        //   callHistoryOperationsCmerActions?.callHistoryOperationsCmerRequest(
        //     request,
        //     userData?.token
        //   )
        // );
      }
    }
  };

  const validate = () => {
    let cancel = false;
    const errors = {};

    // Check each field's validity and update errors object accordingly
    if (
      formData?.actions === null ||
      formData?.actions === undefined ||
      formData?.actions?.length === 0
    ) {
      errors.actions = true;
      cancel = true;
    }

    if (
      formData?.disposition === null ||
      formData?.disposition === undefined ||
      formData?.disposition?.length === 0
    ) {
      errors.disposition = true;
      cancel = true;
    }

    if (
      formData?.callComments === null ||
      formData?.callComments?.trim() === "" ||
      formData?.callComments === ""
    ) {
      errors.callComments = true;
      cancel = true;
    }

    // Check if at least one checkbox is checked
    if (!formData?.DNC && !formData?.callComplete && !formData?.closeCase) {
      errors.checkBox = true;
      cancel = true;
    }

    // Update formDataErrors state with the errors object
    setFormDataErrors(errors);

    return cancel;
  };
  useEffect(() => {
    if (formDataErrors && Object?.keys(formDataErrors)?.length > 0) {
      validate();
    }
    setLoading(false);
  }, [formData]);

  //filter patient by the flags

  useEffect(() => {
    console.log(formData, formDataErrors, "forms");
  }, [formData, formDataErrors]);
  console.log(callActionTakenLookupCmer, "callActionTakenLookupCmer");
  return (
    <div className={styles.add_call_container} style={{ width: "80%" }}>
      {/* <h4>Add Call</h4> */}
      <div className={styles.row1}>
        <Grid container item spacing={4}>
          <Grid container item xs={5.9} sx={{}} direction={"column"}>
            <Grid item>
              <AutoComplete
                error={formDataErrors?.actions}
                isMulti
                isLoading={callActionTakenLookupCmer?.loading}
                label="Actions Taken"
                options={callActionTakenLookupCmer?.getAll || []}
                getOptionLabel={(option) => option.Action}
                getOptionValue={(option) => option.ActionID}
                value={formData?.actions}
                onChange={(selected) => {
                  // getSelectedActions(selected);
                  // setActions(selected);
                  console.log(selected);
                  setFormData({ ...formData, actions: selected });
                }}
              />
            </Grid>
            {/* <Grid item>
            {formDataErrors?.actions && (
              <p className={styles.error}>Actions is required</p>
            )}
          </Grid> */}
          </Grid>
          <Grid container item xs={5.9} direction={"column"}>
            <Grid item>
              <AutoComplete
                error={formDataErrors?.disposition}
                err
                isLoading={callDispositionLookupCmer?.loading}
                label="Disposition"
                options={callDispositionLookupCmer?.getAll}
                // value={dispositionId}
                getOptionLabel={(option) => option.DispositionDesc}
                getOptionValue={(option) => option.DispositionID}
                // onChange={(selected) => setDispositionId(selected)}
                value={formData?.disposition}
                onChange={(selected) => {
                  console.log(selected);
                  setFormData({ ...formData, disposition: selected });
                }}
              />{" "}
            </Grid>
            {/* <Grid item>
            {formDataErrors.disposition && (
              <p className={styles.error}>Actions is required</p>
            )}
          </Grid> */}
          </Grid>
        </Grid>
      </div>
      <div className={styles.checkboxContainer}>
        <Grid container direction={"column"}>
          <Grid item>
            <FormGroup className={styles.checkboxContainer}>
              <FormControlLabel
                control={<Checkbox checked={formData?.DNC} />}
                label="DNC"
                onChange={(event, checked) =>
                  setFormData({ ...formData, DNC: checked })
                }
              />
              <FormControlLabel
                control={<Checkbox checked={formData?.closeCase} />}
                label="Close This Case"
                onChange={(event, checked) =>
                  setFormData({ ...formData, closeCase: checked })
                }
              />
              <FormControlLabel
                control={<Checkbox checked={formData?.callComplete} />}
                label="Call Complete"
                onChange={(event, checked) =>
                  setFormData({ ...formData, callComplete: checked })
                }
              />
            </FormGroup>
          </Grid>
          <Grid item>
            {formDataErrors?.checkBox && (
              <p className={styles.error}>Check any of above</p>
            )}
          </Grid>
        </Grid>
      </div>
      {errorCheckbox && (
        <p className={styles.error}>Required check atleast one field*</p>
      )}
      <div className={styles.info_row}>
        <p className={styles.label}>{"Comments"}:</p>
        <TextareaAutosize
          className={styles.value}
          aria-label="empty textarea"
          placeholder="Enter comments"
          value={formData?.callComments}
          onChange={(evt) =>
            setFormData({ ...formData, callComments: evt.target.value })
          }
        />
      </div>
      {formDataErrors?.callComments && (
        <p className={styles.error}>Call comments are required</p>
      )}
      <ButtonRow
        isEditing={isEditing}
        loading={callHistoryOperationsCm.loading}
        onSubmit={saveCallHistory}
        onCancel={() => {
          setShow(false);
          setFormData(formDataKeys);
          setFormDataErrors(null);
        }}
      />
    </div>
  );
};
