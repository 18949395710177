import { callHistoryTypes } from "../types";

const initialState = {
  loading: false,
  callHistory: [],
};

export const callHistoryCMDReducer = (state = initialState, action) => {
  switch (action.type) {
    case callHistoryTypes.CALL_HISTORY_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case callHistoryTypes.CALL_HISTORY_REQUEST_SUCCESS:
      return {
        ...initialState,
        callHistory: action.callHistory,
      };
    case callHistoryTypes.CALL_HISTORY_REQUEST_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    default:
      return state;
  }
};
