import { updateBillingCptModifiersType } from "../types";

const initialState = {
  loading: false,
  message: "",
  error: "",
};

export const updateCptReducer = (state = initialState, action) => {
  switch (action.type) {
    case updateBillingCptModifiersType.UPDATE_CPT_MODIFIERS_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case updateBillingCptModifiersType.UPDATE_CPT_MODIFIERS_SUCCESS:
      return {
        ...initialState,
        message: action.message,
      };
    case updateBillingCptModifiersType.UPDATE_CPT_MODIFIERS_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case updateBillingCptModifiersType.UPDATE_CPT_MODIFIERS_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
