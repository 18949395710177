import { put, takeLatest } from "@redux-saga/core/effects";
import { cmUsersActions } from "../actions";
import { cmUsersType } from "../types";
import { loginActions } from "../../../Login/actions/login.action";
import cmdCaseManager from "../cmdCaseManager.json";

export function* watchCMDCMUsers() {
  yield takeLatest(cmUsersType.CM_USERS_REQUEST, getCaseManagers);
}

function* getCaseManagers(action) {
  try {
    //   api call
    // const data = yield call(Api, {
    //   endpoint: `${endpoint.getCMDCMUsers}`,
    //   method: "POST",
    //   jwt: "Bearer " + action.token,
    //   body: action.request,
    // });

    // const parsedData = JSON.parse(data);
    const parsedData = cmdCaseManager;

    parsedData !== null && parsedData.length > 0
      ? yield put(cmUsersActions.cmUsersRequestSuccess(parsedData))
      : yield put(cmUsersActions.cmUsersRequestFailure("No data found"));
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(cmUsersActions.cmUsersRequestFailure(error));
  }
}
