import { ehrFileUploadTypes } from "../types";

export const ehrFileUploadActions = {
  ehrFileUploadRequest,
  ehrFileUploadRequestSuccess,
  ehrFileUploadRequestFailure,
  ehrFileUploadRequestReset,
};
function ehrFileUploadRequest(request, token) {
  return {
    type: ehrFileUploadTypes.EHRFILEUPLOAD_REQUEST,
    request,
    token,
  };
}
function ehrFileUploadRequestSuccess(message) {
  return {
    type: ehrFileUploadTypes.EHRFILEUPLOAD_REQUEST_SUCCESS,
    message,
  };
}
function ehrFileUploadRequestFailure(error) {
  return {
    type: ehrFileUploadTypes.EHRFILEUPLOAD_REQUEST_FAILURE,
    error,
  };
}
function ehrFileUploadRequestReset() {
  return {
    type: ehrFileUploadTypes.EHRFILEUPLOAD_REQUEST_RESET,
  };
}
