import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ButtonRow } from "../../../../../components";
import {
  InputField,
  AutoComplete,
} from "../../../../cm/EditPatient/components";
import styles from "./AddTask.module.scss";
import { addTaskActionsCm } from "../../actions/addTask.action";
import { isEmpty } from "../../../../../util";
import { lookupGroupsSubgroupsActions } from "../../../../cmer/TaskDetails/actions/addTask.action";

export const AddTask = ({
  show,
  setShow,
  editValue,
  isEditing,
  autoCompleteList,
  patientDetails,
  selectedItem,
}) => {
  console.log(editValue, "editValue");
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.login.userData);
  const lookupGroupsSubgroups = useSelector(
    (state) => state.lookupGroupsSubgroups?.getAll
  );
  const addTask = useSelector((state) => state.addTaskReducerCM);
  console.log(addTask, "addTask");

  const lookupLooding = useSelector(
    (state) => state.lookupGroupsSubgroups?.loading
  );

  const [groups, setGroup] = useState("");
  console.log(groups, "groups");
  const [selectedGroups, setSelectedGroups] = useState("");
  const [filteredSubGroups, setFilteredSubGroups] = useState([]);

  const [note, setNote] = useState("");
  const [dates, setDates] = useState("");

  const [errorDate, setErrorDate] = useState(false);
  const [errorNote, setErrorNote] = useState(false);
  const [errorGroup, setErrorGroup] = useState(false);
  const [errorSubgroup, setErrorSubgroup] = useState(false);

  useEffect(() => {
    if (userData.token !== undefined) {
      dispatch(
        lookupGroupsSubgroupsActions.lookupGroupsSubgroupsRequest(
          userData.token
        )
      );
    }
  }, []);

  useEffect(() => {
    if (isEditing)
      if (editValue !== undefined && editValue !== null && editValue !== "") {
        setDates(new Date(editValue?.TaskDate).toLocaleDateString("en-CA"));
        setNote(editValue?.TaskDetail);
        //  setSubGroups(groups.subGroup);

        let editGroup = lookupGroupsSubgroups?.groups?.find(
          (item) => editValue.Groupid === item?.Groupid
        );
        let editSubGroup = lookupGroupsSubgroups?.subgroups?.find(
          (item) => editValue.Subgroupid === item?.Subgroupid
        );
        setGroup(editGroup);
        // setAllSubGroups(editGroup);
        setSelectedGroups(editSubGroup);
        // setSelectedGroups({
        //   id: editValue?.Groupid,
        //   name: editValue?.SubGroupName,
        //   subid: editValue?.Subgroupid,
        // });
      }
    // eslint-disable-next-line
  }, [editValue]);
  useEffect(() => {
    if (addTask?.message?.includes("Task created successfully")) {
      dispatch(addTaskActionsCm.addTaskCmRequestReset());
    }
  }, [addTask, dispatch, setShow]);
  //get subgroup
  useEffect(() => {
    if (groups) {
      const filtered = lookupGroupsSubgroups?.subgroups.filter(
        (subGroup) => subGroup.Groupid === groups.Groupid
      );
      setFilteredSubGroups(filtered);
    }
  }, [groups, lookupGroupsSubgroups]);

  const formKeys = {
    group: null,
    subGroup: null,
    date: "",
    note: "",
  };
  const [formData, setFormData] = useState(formKeys);
  const saveTask = () => {
    if (!validate()) {
      if (userData.token !== undefined) {
        let request;
        if (isEditing) {
          console.log(patientDetails);
          request = {
            date: dates,
            note: note,
            groupId: `${selectedGroups.Groupid}`,
            subgroupId: `${selectedGroups.Subgroupid}`,
            patientId: `${patientDetails.PatientId}`,
            admissionId: `${patientDetails?.parentAdmissionId}`,
            TaskID: `${editValue?.TaskID}`,

            Flag: "Update",
          };

          dispatch(addTaskActionsCm.addTaskCmRequest(request, userData.token));
        } else {
          request = {
            date: dates,
            note: note,
            groupId: `${selectedGroups.Groupid}`,
            subgroupId: `${selectedGroups.Subgroupid}`,
            patientId: `${patientDetails.PatientId}`,
            admissionId: `${patientDetails?.parentAdmissionId}`,
          };
          dispatch(addTaskActionsCm.addTaskCmRequest(request, userData.token));
        }
      }
    }
  };

  const validate = () => {
    let cancel = false;
    if (dates === "") {
      cancel = true;
      setErrorDate(true);
    } else {
      setErrorDate(false);
    }

    if (note === "") {
      cancel = true;
      setErrorNote(true);
    } else {
      setErrorNote(false);
    }

    if (isEmpty(groups)) {
      cancel = true;
      setErrorGroup(true);
    } else {
      setErrorGroup(false);
    }

    // if (
    //   selectedGroups.subid == undefined ||
    //   selectedGroups.id == 0 ||
    //   selectedGroups.id == 0
    // ) {
    //   cancel = true;
    //   setErrorSubgroup(true);
    // } else {
    //   setErrorSubgroup(false);
    // }

    return cancel;
  };

  useEffect(() => {
    if (addTask.message !== undefined) {
      console.log(addTask);
      if (addTask.message === "created") {
        setDates("");
        setNote("");
        setGroup("");
        setSelectedGroups("");

        setTimeout(() => {
          dispatch(addTaskActionsCm.addTaskCmRequestReset());
        }, 800);
      }
    }

    // eslint-disable-next-line
  }, [addTask.message, addTask.error]);

  return (
    <div className={styles.container}>
      <div className={styles.add_call_container}>
        <div>
          <AutoComplete
            isLoading={lookupLooding}
            getOptionLabel={(option) => option.Groupname}
            getOptionValue={(option) => option.Groupid}
            value={groups}
            onChange={(val) => {
              setGroup(val);
              setFormData({
                ...formData,
                group: val,
                subGroup: null,
              });
              // setAllSubGroups(val);
            }}
            options={lookupGroupsSubgroups?.groups || []}
            label={"Group"}
          />
          {errorGroup && <p className={styles.error}>Required*</p>}
        </div>
        <div>
          <AutoComplete
            isLoading={lookupLooding}
            getOptionLabel={(option) => option.SubGroupname}
            getOptionValue={(option) => option.Subgroupid}
            onChange={(val) => {
              setFormData({
                ...formData,
                subGroup: val,
              });
              setSelectedGroups(val);
            }}
            options={filteredSubGroups || []}
            value={selectedGroups}
            label={"Sub Group"}
          />

          {errorSubgroup && <p className={styles.error}>Required*</p>}
        </div>
        <div>
          <InputField
            type="date"
            label={"Date"}
            defaultValue={
              isEditing &&
              new Date(editValue?.TaskDate).toLocaleDateString("en-CA")
            }
            min={new Date().toLocaleDateString("en-CA")}
            max="9999-12-31"
            onChange={(evt) => {
              setFormData({
                ...formData,
                date: new Date(evt.target.value).toLocaleDateString("en-CA"),
              });
              setDates(new Date(evt.target.value).toLocaleDateString("en-CA"));
            }}
          />
          {errorDate && <p className={styles.error}>Required*</p>}
        </div>
        <div>
          <div className={styles.info_row}>
            <p className={styles.label}>{"Note:"}</p>
            <textarea
              value={note}
              placeholder="Enter note"
              className={styles.value}
              onChange={(evt) => {
                setFormData({
                  ...formData,
                  note: evt.target.value,
                });
                setNote(evt.target.value);
              }}
            />
          </div>
          {errorNote && <p className={styles.error}>Required*</p>}
        </div>
      </div>
      <ButtonRow
        isEditing={isEditing}
        loading={addTask.loading}
        onSubmit={saveTask}
        onCancel={() => {
          setShow(false);
          setFormData(formKeys);
        }}
      />
    </div>
  );
};
