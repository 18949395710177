import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { getInsuranceActions } from "../actions";
import { getInsuranceTypes } from "../types";

export function* watchDMInsuranceDetails() {
  yield takeLatest(getInsuranceTypes.GET_INSURANCE_REQUEST, getInsurance);
}

function* getInsurance(action) {
  try {
    let data = yield call(Api, {
      method: "POST",
      endpoint: endpoint.getDMInsuranceDetails,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedData = JSON.parse(data);

    yield put(getInsuranceActions.insuranceRequestSuccess(parsedData[0]));
  } catch (error) {
    yield put(getInsuranceActions.insuranceRequestFailure(error));
  }
}
