import { put, takeLatest, call } from "@redux-saga/core/effects";
import { getDiagnosisActions } from "../actions";
import { diagnosisType } from "../types";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { Api2 } from "../../../../api/Api2";

export function* watchDiagnosis() {
  yield takeLatest(diagnosisType.GET_DIAGNOSIS_REQUEST, getAllDiagnosis);
}

function* getAllDiagnosis(action) {
  try {
    let diagnosisList = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.awvGetAllergeyDiagnosisLookup}?search=${action.request.searchStr}`,
      jwt: "Bearer " + action.token,
    });
    console.log(diagnosisList, "diagnosisList");
    yield put(getDiagnosisActions.getDiagnosisRequestSuccess(diagnosisList));
  } catch (error) {
    yield put(getDiagnosisActions.getDiagnosisRequestFailure(error));
  }
}
