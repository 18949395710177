import { permissionTypes } from "../types";

export const permissionActions = {
  permissionGetAllRequest,
  permissionGetAllRequestSuccess,
  permissionGetAllRequestFailure,
  permissionSetParent,
  permissionResetParent,
};

function permissionGetAllRequest(token) {
  return { type: permissionTypes.PERMISSION_GETALL_REQUEST, token };
}
function permissionGetAllRequestSuccess(getAll) {
  return { type: permissionTypes.PERMISSION_GETALL_REQUEST_SUCCESS, getAll };
}
function permissionGetAllRequestFailure(error) {
  return { type: permissionTypes.PERMISSION_GETALL_REQUEST_FAILURE, error };
}
function permissionSetParent(parentMenuId) {
  return { type: permissionTypes.PERMISSION_SETPARENT, parentMenuId };
}
function permissionResetParent() {
  return { type: permissionTypes.PERMISSION_RESETPARENT };
}
