import { put, takeLatest, call } from "@redux-saga/core/effects";
import { chargesSaveActions } from "../actions";
import { chargesSaveType } from "../types";
import { endpoint } from "../../../../../api/Url";
import { Api } from "../../../../../api";
import { loginActions } from "../../../../Login/actions/login.action";

export function* watchChargesSave() {
  yield takeLatest(chargesSaveType.CHARGES_SAVE_REQUEST, saveCharges);
}

function* saveCharges(action) {
  try {
    // //   api call
    const message = yield call(Api, {
      endpoint: `${endpoint.saveCharges}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    const parsedMessage = JSON.parse(message);
    yield put(chargesSaveActions.chargesSaveRequestSuccess(parsedMessage[0]));
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(chargesSaveActions.chargesSaveRequestFailure(error));
  }
}
