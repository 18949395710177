import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { getInsurancePlanActions } from "../actions";
import { getInsurancePlanTypes } from "../types";

export function* watchDMInsurancePlanDetails() {
  yield takeLatest(
    getInsurancePlanTypes.GET_INSURANCE_PLAN_REQUEST,
    getInsurancePlan
  );
}

function* getInsurancePlan(action) {
  try {
    let data = yield call(Api, {
      method: "POST",
      endpoint: endpoint.getDMInsurancePlanDetails,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedData = JSON.parse(data);

    yield put(
      getInsurancePlanActions.insurancePlanRequestSuccess(parsedData[0])
    );
  } catch (error) {
    yield put(getInsurancePlanActions.insurancePlanRequestFailure(error));
  }
}
