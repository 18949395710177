import { relationshipTypes } from "../types";

export const relationshipActions = {
  relationshipRequest,
  relationshipRequestSuccess,
  relationshipRequestFailure,
};

function relationshipRequest(request, token) {
  return { type: relationshipTypes.RELATIONSHIP_REQUEST, request, token };
}

function relationshipRequestSuccess(getAll) {
  return { type: relationshipTypes.RELATIONSHIP_REQUEST_SUCCESS, getAll };
}

function relationshipRequestFailure(error) {
  return { type: relationshipTypes.RELATIONSHIP_REQUEST_FAILURE, error };
}
