export const strings = {
  //for table headers
  TH_S_NO: "S.No.",
  TH_USER: "User",
  TH_DATE: "Date & Time",
  TH_TIME: "Time",
  TH_DNC: "DNC",
  TH_CLOSE_THIS_CASE: "Case Close",
  TH_CALL_COMPLETE: "Call Complete",
  TH_DISPOSITION: "Disposition",
  TH_ACTION: "Action",
  TH_COMMENT: "Comment",
  TH_CONSENT: "Consent",
  BTN_NAME: "Add Call History",
};
