import { call, put, takeLatest } from "@redux-saga/core/effects";
import { medicationActions } from "../actions/medication.action";
import { medicationType } from "../types/medication.type";
import { loginActions } from "../../../Login/actions/login.action";
import { endpoint } from "../../../../api/Url";
import { Api2 } from "../../../../api/Api2";

export function* watchMedication() {
  yield takeLatest(medicationType.MEDICATION_REQUEST, getMedication);
}

function* getMedication(action) {
  try {
    const data = yield call(Api2, {
      endpoint: `${endpoint.awvGetmedicationList}?patientId=${action.request.patientId}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });

    yield put(medicationActions.medicationRequestSuccess(data?.medication));
    // } else {
    //   yield put(medicationActions.medicationRequestFailure("No data found"));
    // }
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(medicationActions.medicationRequestFailure(error));
  }
}
