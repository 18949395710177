import { updateCMPatientDetailsType } from "../../types/patientDetailstype/updateCMPatientDetails";

export const updateCMPatientDetailsActions = {
  updateCMPatientDetailsRequest,
  updateCMPatientDetailsSuccess,
  updateCMPatientDetailsFailure,
  updateCMPatientDetailsReset,
};
function updateCMPatientDetailsRequest(query, token) {
  return {
    type: updateCMPatientDetailsType.UPDATE_CM_PATIENT_DETAILS_REQUEST,
    query,
    token,
  };
}
function updateCMPatientDetailsSuccess(getAll) {
  return {
    type: updateCMPatientDetailsType.UPDATE_CM_PATIENT_DETAILS_SUCCESS,
    getAll,
  };
}
function updateCMPatientDetailsFailure(error) {
  return {
    type: updateCMPatientDetailsType.UPDATE_CM_PATIENT_DETAILS_FAILURE,
    error,
  };
}
function updateCMPatientDetailsReset(error) {
  return {
    type: updateCMPatientDetailsType.UPDATE_CM_PATIENT_DETAILS_RESET,
    error,
  };
}
