import { call, put, takeLatest } from "redux-saga/effects";
import { Api2 } from "../../../../api/Api2";
import { endpoint } from "../../../../api/Url";
import { hospitalistPatientListSuccess } from "../slice/getHospitalPatientList.slice";
import {
  addHospitalPatientFailure,
  addHospitalPatientSuccess,
} from "../slice/addHospitalPaitent.slice";

export function* watchHospitalist() {
  yield takeLatest(
    "hospitalistPatientList/hospitalistPatientListRequest",
    getHospitalistPatient
  );
  yield takeLatest(
    "addHospitalPatient/addHospitalPatientRequest",
    addHospitalistPatient
  );
}
function* getHospitalistPatient(action) {
  try {
    const response = yield call(Api2, {
      endpoint: `${endpoint.referralConsultant}?pageNumber=${action.payload.pageNumber}&pageSize=${action.payload.pageSize}`,
      method: "GET",
      jwt: `Bearer ${action.payload.token}`,
    });
    console.log(response);
    yield put(hospitalistPatientListSuccess(response));
  } catch (error) {
    // yield put()
  }
}
function* addHospitalistPatient(action) {
  console.log(action?.payload);
  let { token, ...body } = action?.payload;
  try {
    const response = yield call(Api2, {
      endpoint: `${endpoint.createHospitalistFollowUpPatient}`,
      method: "POST",
      jwt: `Bearer ${action.payload.token}`,
      body: body,
    });

    console.log(response?.result);
    yield put(
      addHospitalPatientSuccess({
        message: response?.result?.message,
        patient: response?.result?.patient || [],
      })
    );
  } catch (error) {
    yield put(addHospitalPatientFailure({ error: error.message }));
  }
}
