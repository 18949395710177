import { put, takeLatest, call } from "@redux-saga/core/effects";
import { clinicalInfoUpdateActions } from "../actions";
import { clinicalInfoUpdateTypes } from "../types";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";

export function* watchClinicalInfoUpdate() {
  yield takeLatest(
    clinicalInfoUpdateTypes.CLINICAL_INFO_UPDATE_REQUEST,
    getAllClinicalInfoUpdate
  );
}

function* getAllClinicalInfoUpdate(action) {
  try {
    let message = yield call(Api, {
      method: "POST",
      endpoint: `${endpoint.patientClinicalInformationUpdate}`,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    const parsedMessage = JSON.parse(message);

    yield put(
      clinicalInfoUpdateActions.clinicalInfoUpdateRequestSuccess(
        parsedMessage[0]
      )
    );
  } catch (error) {
    yield put(
      clinicalInfoUpdateActions.clinicalInfoUpdateRequestFailure(error)
    );
  }
}
