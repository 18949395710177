import { conversationListTypes } from "../types";

export const conversationListActions = {
  conversationListRequest,
  conversationListRequestSuccess,
  conversationListRequestFailure,
};

function conversationListRequest(request, token) {
  return {
    type: conversationListTypes.CONVERSATION_LIST_REQUEST,
    request,
    token,
  };
}
function conversationListRequestSuccess(convList) {
  return {
    type: conversationListTypes.CONVERSATION_LIST_REQUEST_SUCCESS,
    convList,
  };
}
function conversationListRequestFailure(error) {
  return {
    type: conversationListTypes.CONVERSATION_LIST_REQUEST_FAILURE,
    error,
  };
}
