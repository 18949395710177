import { icdTypes } from "../types";

export const icdActions = {
    icdRequest,
    icdRequestSuccess,
    icdRequestFailure,
   };
   function icdRequest(request, token) {
     return {
       type: icdTypes.ICD_REQUEST,
       request,
       token,
     };
   }
   function icdRequestSuccess(getAll) {
     return {
       type: icdTypes.ICD_REQUEST_SUCCESS,
       getAll,
     };
   }
   function icdRequestFailure(error) {
     return {
       type: icdTypes.ICD_REQUEST_FAILURE,
       error,
     };
   }
   