import { hospitalizationTypes } from "../types";

const initialGetState = {
  loading: false,
  getAll: [],
  error: "",
  dropdownSourcelist: [],
};
export const hospitalizationGetReducer = (state = initialGetState, action) => {
  switch (action.type) {
    case hospitalizationTypes.GETALL_HOSPITALIZATION_REQUEST:
      return {
        ...initialGetState,
        loading: true,
      };
    case hospitalizationTypes.GETALL_HOSPITALIZATION_REQUEST_SUCCESS:
      return {
        ...initialGetState,
        getAll: action.getAll,
        dropdownSourcelist: action.dropdownSourcelist,
      };
    case hospitalizationTypes.GETALL_HOSPITALIZATION_REQUEST_FAILURE:
      return {
        ...initialGetState,
        error: action.error,
      };
    default:
      return state;
  }
};

const initialOperationState = {
  loading: false,
  message: "",
  error: "",
};
export const hospitalizationOperationReducer = (
  state = initialOperationState,
  action
) => {
  switch (action.type) {
    case hospitalizationTypes.OPERARTION_HOSPITALIZATION_REQUEST:
      return {
        ...initialOperationState,
        loading: true,
      };
    case hospitalizationTypes.OPERARTION_HOSPITALIZATION_REQUEST_SUCCESS:
      return {
        ...initialOperationState,
        message: action.message,
      };
    case hospitalizationTypes.OPERARTION_HOSPITALIZATION_REQUEST_FAILURE:
      return {
        ...initialOperationState,
        error: action.error,
      };
    case hospitalizationTypes.OPERARTION_HOSPITALIZATION_REQUEST_RESET:
      return {
        ...initialOperationState,
      };
    default:
      return state;
  }
};

const initialDeleteState = {
  loading: false,
  message: "",
  error: "",
};
export const hospitalizationDeleteReducer = (
  state = initialDeleteState,
  action
) => {
  switch (action.type) {
    case hospitalizationTypes.DELETE_HOSPITALIZATION_REQUEST:
      return {
        ...initialDeleteState,
        loading: true,
      };
    case hospitalizationTypes.DELETE_HOSPITALIZATION_REQUEST_SUCCESS:
      return {
        ...initialDeleteState,
        message: action.message,
      };
    case hospitalizationTypes.DELETE_HOSPITALIZATION_REQUEST_FAILURE:
      return {
        ...initialDeleteState,
        error: action.error,
      };
    case hospitalizationTypes.DELETE_HOSPITALIZATION_REQUEST_RESET:
      return {
        ...initialDeleteState,
      };
    default:
      return state;
  }
};
