import { dosageNRouteType } from "../types/dosageNRoute.type";

export const dosageNRouteActions = {
  dosageNRouteRequest,
  dosageNRouteRequestSuccess,
  dosageNRouteRequestFailure,
};

function dosageNRouteRequest(request, token) {
  return { type: dosageNRouteType.DOSAGENROUTE_REQUEST, request, token };
}

function dosageNRouteRequestSuccess(getDosageNRoute) {
  return {
    type: dosageNRouteType.DOSAGENROUTE_REQUEST_SUCCESS,
    getDosageNRoute,
  };
}

function dosageNRouteRequestFailure(error) {
  return { type: dosageNRouteType.DOSAGENROUTE_REQUEST_FAILURE, error };
}
