import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  error: false,
  // timeZone: moment.tz.guess(), // Initialize with guessed time zone
  timeZone: "America/Chicago", // Initialize with guessed time zone
};

export const timeZoneSlice = createSlice({
  name: "timeZone",
  initialState: initialState,
  reducers: {
    timeZoneSet: (state, action) => {
      console.log(action,'action');
      state.error = false;
      state.timeZone = action.payload.zone;
      moment.tz.setDefault(state.timeZone); // Update moment's default timezone dynamically
    },

    timeZoneReSet: (state) => {
      state.timeZone = initialState.timeZone;
      state.error = false;
      moment.tz.setDefault(state.timeZone); // Reset moment’s default timezone
    },
  },
});

export const { timeZoneSet, timeZoneReSet } = timeZoneSlice.actions;
export default timeZoneSlice.reducer;
