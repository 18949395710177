import { dosageNRouteType } from "../types/dosageNRoute.type";

const initialState = {
  loading: false,
  getDosageNRoute: [],
};

export const dosageNRouteReducer = (state = initialState, action) => {
  switch (action.type) {
    case dosageNRouteType.DOSAGENROUTE_REQUEST:
      return { loading: true };
    case dosageNRouteType.DOSAGENROUTE_REQUEST_SUCCESS:
      return { getDosageNRoute: action.getDosageNRoute };
    case dosageNRouteType.DOSAGENROUTE_REQUEST_FAILURE:
      return { error: action.error };
    default:
      return state;
  }
};
