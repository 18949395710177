import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { socialDeterministOperationActions } from "../actions";
import { socialDeterministOperationTypes } from "../types";

export function* watchDMSocialDeterministOperation() {
  yield takeLatest(
    socialDeterministOperationTypes.SOCIALDETERMINIST_OPERATION_REQUEST,
    SocialDeterministOperation
  );
}

function* SocialDeterministOperation(action) {
  try {
    let data = yield call(Api, {
      method: "POST",
      endpoint: endpoint.operationDMSocialDeterminants,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedData = JSON.parse(data);

    if (parsedData.length > 0) {
      yield put(
        socialDeterministOperationActions.socialDeterministOperationRequestSuccess(
          parsedData[0]
        )
      );
    } else {
      yield put(
        socialDeterministOperationActions.socialDeterministOperationRequestSuccess(
          {
            Result: "Error",
          }
        )
      );
    }
  } catch (error) {
    yield put(
      socialDeterministOperationActions.socialDeterministOperationRequestFailure(
        error
      )
    );
  }
}
