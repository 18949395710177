import { medicationSaveType } from "../types/medicationSave.type";

const initialState = {
  saving: false,
};

export const medicationSaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case medicationSaveType.MEDICATION_SAVE_REQUEST:
      return { ...initialState, saving: true };
    case medicationSaveType.MEDICATION_SAVE_REQUEST_SUCCESS:
      return { ...initialState, message: action.message };
    case medicationSaveType.MEDICATION_SAVE_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    case medicationSaveType.MEDICATION_SAVE_REQUEST_RESET:
      return initialState;
    default:
      return state;
  }
};
