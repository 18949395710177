import React from "react";
import * as MdIcons from "react-icons/md";
import styles from "./ActionBtn.module.scss";
export const ActionBtn = ({ onClick, isEdit, loading }) => {
  return (
    <button className={styles.btn_dlt} onClick={onClick}>
      {/* <MdIcons.MdDelete className={styles.dlt_icon} /> */}
      {!isEdit && <MdIcons.MdDelete className={styles.dlt_icon} />}
      {isEdit && <MdIcons.MdModeEdit className={styles.dlt_icon} />}
    </button>
  );
};
