import { getInsuranceTypes } from "../types";

const initiaLState = {
  loading: false,
  get: [],
};

export const getInsuranceDMReducer = (state = initiaLState, action) => {
  switch (action.type) {
    case getInsuranceTypes.GET_INSURANCE_REQUEST:
      return {
        ...initiaLState,
        loading: true,
      };
    case getInsuranceTypes.GET_INSURANCE_REQUEST_SUCCESS:
      return {
        ...initiaLState,
        get: action.get,
      };
    case getInsuranceTypes.GET_INSURANCE_REQUEST_FAILURE:
      return {
        ...initiaLState,
        error: action.error,
      };
    default:
      return state;
  }
};
