import { mergeDuplicatePatientsTypes } from "../types";

function mergeDuplicatePatientsRequest(AdmissionIds, token) {
  return {
    type: mergeDuplicatePatientsTypes.MERGE_DUPLICATE_PATIENTS_REQUEST,
    AdmissionIds,
    token,
  };
}

function mergeDuplicatePatientsSuccess(message) {
  return {
    type: mergeDuplicatePatientsTypes.MERGE_DUPLICATE_PATIENTS_SUCCESS,
    message,
  };
}

function mergeDuplicatePatientsFailure(error) {
  return {
    type: mergeDuplicatePatientsTypes.MERGE_DUPLICATE_PATIENTS_FAILURE,
    error,
  };
}
function mergeDuplicatePatientsReset() {
  return {
    type: mergeDuplicatePatientsTypes.MERGE_DUPLICATE_PATIENTS_RESET
  };
}

export const mergeDuplicatePatientsActions = {
  mergeDuplicatePatientsRequest,
  mergeDuplicatePatientsSuccess,
  mergeDuplicatePatientsFailure,
  mergeDuplicatePatientsReset
};
