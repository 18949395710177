import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { recommendationsActions } from "../actions";
import { recommendationsTypes } from "../types";
export function* watchRecommendations() {
  yield takeLatest(
    recommendationsTypes.RECOMMENDATIONS_GET_REQUEST,
    getRecommendations
  );
  yield takeLatest(
    recommendationsTypes.RECOMMENDATIONS_OPERATION_REQUEST,
    operationRecommendations
  );
}

function* getRecommendations(action) {
  try {
    let data = yield call(Api, {
      endpoint: `${endpoint.getRecommendationsDetails}?patientId=${action.request.patientId}`,
      method: "POST",
      jwt: "Bearer " + action.token,
    });
    let parsedData = JSON.parse(data);

    yield put(
      recommendationsActions.recommendationsGetRequestSuccess(parsedData)
    );
  } catch (error) {
    yield put(recommendationsActions.recommendationsGetRequestFailure(error));
  }
}
function* operationRecommendations(action) {
  try {
    let message = yield call(Api, {
      endpoint: endpoint.saveRecommendationsDetails,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedMessage = JSON.parse(message);

    yield put(
      recommendationsActions.recommendationsOpRequestSuccess(parsedMessage[0])
    );
  } catch (error) {
    yield put(recommendationsActions.recommendationsOpRequestFailure(error));
  }
}
