import { msoTypes } from "../types";

export const msoActions = {
 msoRequest,
 msoRequestSuccess,
 msoRequestFailure,
};
function msoRequest(request, token) {
  return {
    type: msoTypes.MSO_REQUEST,
    request,
    token,
  };
}
function msoRequestSuccess(getAll) {
  return {
    type: msoTypes.MSO_REQUEST_SUCCESS,
    getAll,
  };
}
function msoRequestFailure(error) {
  return {
    type: msoTypes.MSO_REQUEST_FAILURE,
    error,
  };
}
