import { chargesInputType } from "../types";

const initialState = {
  loading: false,
  inputs: [],
};

export const chargesInputReducer = (state = initialState, action) => {
  switch (action.type) {
    case chargesInputType.CHARGES_INPUT_REQUEST:
      return { ...initialState, loading: true };
    case chargesInputType.CHARGES_INPUT_REQUEST_SUCCESS:
      return {
        ...initialState,
        inputs: action.inputs,
      };
    case chargesInputType.CHARGES_INPUT_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};
