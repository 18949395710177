import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
import { archiveActions } from "../actions";

import { archiveType } from "../types";
export function* watchArchiveMail() {
  yield takeLatest(archiveType.ARCHIVE_MESSAGE_REQUEST, archiveMessage);
}

function* archiveMessage(action) {
  try {
    let archive = yield call(Api, {
      method: "POST",
      endpoint: endpoint.archiveMail,
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    const parsedArchive = JSON.parse(archive);

    yield put(archiveActions.archiveRequestSuccess(parsedArchive[0]));

    // if (parsedArchive.length > 0 && parsedArchive[0].Result === "Success") {
    //   yield put(sentMsgsActions.sentMsgsRequest(action.token));
    // }
  } catch (error) {
    yield put(archiveActions.archiveRequestFailure(error));
  }
}
