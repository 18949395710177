import { put, takeLatest } from "@redux-saga/core/effects";
import { deleteMedicationTypes } from "../types/deleteMedication.type";
import { deleteMedicationActions } from "../actions/deleteMedication.action";

export function* watchDeleteMedication() {
  yield takeLatest(
    deleteMedicationTypes.DELETE_MEDICATION_REQUEST,
    deleteMedication
  );
}

function* deleteMedication() {
  try {
    let deleteData = "Success";

    yield put(
      deleteMedicationActions.deleteMedicationRequestSuccess(deleteData)
    );
  } catch (error) {
    yield put(deleteMedicationActions.deleteMedicationRequestFailure(error));
  }
}
