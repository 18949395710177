import { getDischargePatientDetailsType } from "../types/getDischargePatientDetails.type";

export const getDischargePatientDetailsActions = {
  getDischargePatientDetailsRequest,
  getDischargePatientDetailsSuccess,
  getDischargePatientDetailsFailure,
};
function getDischargePatientDetailsRequest(request, token) {
  return {
    type: getDischargePatientDetailsType.GET_DISCHARGE_PATIENT_DETAILS_PHARMA_REQUEST,
    request,
    token,
  };
}
function getDischargePatientDetailsSuccess(
  patientDetails,
  medicationDetails,
  callHistory
) {
  return {
    type: getDischargePatientDetailsType.GET_DISCHARGE_PATIENT_DETAILS_PHARMA_SUCCESS,
    patientDetails,
    medicationDetails,
    callHistory,
  };
}
function getDischargePatientDetailsFailure(error) {
  return {
    type: getDischargePatientDetailsType.GET_DISCHARGE_PATIENT_DETAILS_PHARMA_FAILURE,
    error,
  };
}
