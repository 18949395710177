//import dependencies
import { put, takeLatest, call } from "@redux-saga/core/effects";

//api
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
import { loginActions } from "../../Login/actions/login.action";
import { filtersListActions } from "../actions/filtersList.action";
import { filtersListType } from "../types/filtersList.type";

export function* watchFiltersList() {
  yield takeLatest(filtersListType.FILTERSLIST_REQUEST, fetchFiltersListData);
}

function* fetchFiltersListData(action) {
  try {
    //api call
    const data = yield call(Api, {
      endpoint: endpoint.patientFiltersList,
      method: "POST",
      jwt: "Bearer " + action.token,
    });

    const parsedData = JSON.parse(data);
    if (
      parsedData.patientfilters.length === 0 &&
      parsedData.flagList.length === 0
    ) {
      yield put(filtersListActions.filtersListRequestFailure(parsedData));
    } else {
      yield put(filtersListActions.filtersListRequestSuccess(parsedData));
    }
  } catch (error) {
    yield put(filtersListActions.filtersListRequestFailure(error));

    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
  }
}
