import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { icdSaveActions } from "../actions";
import { icdSaveTypes } from "../types";

export function* watchDMIcdOperation() {
  yield takeLatest(icdSaveTypes.ICD_SAVE_REQUEST, icdSave);
}

function* icdSave(action) {
  try {
    let data = yield call(Api, {
      method: "POST",
      endpoint: endpoint.saveDMIcdData,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedData = JSON.parse(data);

    if (parsedData.length > 0)
      yield put(icdSaveActions.icdSaveRequestSuccess(parsedData[0]));
    else {
      yield put(icdSaveActions.icdSaveRequestSuccess({ Result: "Error" }));
    }
  } catch (error) {
    yield put(icdSaveActions.icdSaveRequestFailure(error));
  }
}
