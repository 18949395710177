import { forgetPasswordTypes } from "../types";

export const forgetPasswordActions = {
  forgetPasswordOtpRequest,
  forgetPasswordOtpRequestSuccess,
  forgetPasswordOtpRequestFailure,
  forgetPasswordOtpRequestReset,

  forgetPasswordConfirmOtpRequest,
  forgetPasswordConfirmOtpRequestSuccess,
  forgetPasswordConfirmOtpRequestFailure,
  forgetPasswordConfirmOtpRequestReset,

  forgetPasswordResetRequest,
  forgetPasswordResetRequestSuccess,
  forgetPasswordResetRequestFailure,
  forgetPasswordResetRequestReset,
};
//SEND OTP
function forgetPasswordOtpRequest(request, token, message) {
  return {
    type: forgetPasswordTypes.FORGET_PASSWORD_OTP_REQUEST,
    request,
    token,
    message,
  };
}
function forgetPasswordOtpRequestSuccess(message) {
  return {
    type: forgetPasswordTypes.FORGET_PASSWORD_OTP_REQUEST_SUCCESS,
    message,
  };
}
function forgetPasswordOtpRequestFailure(error) {
  return {
    type: forgetPasswordTypes.FORGET_PASSWORD_OTP_REQUEST_FAILURE,
    error,
  };
}
function forgetPasswordOtpRequestReset() {
  return {
    type: forgetPasswordTypes.FORGET_PASSWORD_OTP_REQUEST_RESET,
  };
}

//CONFIRM OTP
function forgetPasswordConfirmOtpRequest(request, token) {
  return {
    type: forgetPasswordTypes.FORGET_PASSWORD_CONFIRM_OTP_REQUEST,
    request,
    token,
  };
}
function forgetPasswordConfirmOtpRequestSuccess(message, userID) {
  return {
    type: forgetPasswordTypes.FORGET_PASSWORD_CONFIRM_OTP_REQUEST_SUCCESS,
    message,
    userID,
  };
}
function forgetPasswordConfirmOtpRequestFailure(error) {
  return {
    type: forgetPasswordTypes.FORGET_PASSWORD_CONFIRM_OTP_REQUEST_FAILURE,
    error,
  };
}
function forgetPasswordConfirmOtpRequestReset() {
  return {
    type: forgetPasswordTypes.FORGET_PASSWORD_CONFIRM_OTP_REQUEST_RESET,
  };
}
//RESET PASSWORD
function forgetPasswordResetRequest(request, token) {
  return {
    type: forgetPasswordTypes.FORGET_PASSWORD_RESET_REQUEST,
    request,
    token,
  };
}
function forgetPasswordResetRequestSuccess(message) {
  return {
    type: forgetPasswordTypes.FORGET_PASSWORD_RESET_REQUEST_SUCCESS,
    message,
  };
}
function forgetPasswordResetRequestFailure(error) {
  return {
    type: forgetPasswordTypes.FORGET_PASSWORD_RESET_REQUEST_FAILURE,
    error,
  };
}
function forgetPasswordResetRequestReset() {
  return {
    type: forgetPasswordTypes.FORGET_PASSWORD_RESET_REQUEST_RESET,
  };
}
