import { getallCptType } from "../types/getCpt.type";

const initialState = {
  loading: false,
  getAll: [],
  error: "",
};

export const getallcptReducer = (state = initialState, action) => {
  switch (action.type) {
    case getallCptType.GETALL_CPT_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case getallCptType.GETALL_CPT_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
      };
    case getallCptType.GETALL_CPT_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    default:
      return state;
  }
};
