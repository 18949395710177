import { getBillingIcdType } from "../types";

export const getBillingIcdActions = {
  getBillingIcdRequest,
  getBillingIcdRequestSuccess,
  getBillingIcdRequestFailure,
};
function getBillingIcdRequest(request, token) {
  return {
    type: getBillingIcdType.GET_BILLING_Icd_REQUEST,
    request,
    token,
  };
}
function getBillingIcdRequestSuccess(getAll) {
  return {
    type: getBillingIcdType.GET_BILLING_Icd_REQUEST_SUCCESS,
    getAll,
  };
}
function getBillingIcdRequestFailure(error) {
  return {
    type: getBillingIcdType.GET_BILLING_Icd_REQUEST_FAILURE,
    error,
  };
}
