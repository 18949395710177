import { put, takeLatest, call } from "@redux-saga/core/effects";
import { notificationsListType } from "../types/notificationsList.type";
import { notificationsListActions } from "../actions/notificationsList.action";
import { endpoint } from "../../../../api/Url";
import { Api } from "../../../../api";

export function* watchGetNotificationsList() {
  yield takeLatest(
    notificationsListType.NOTIFICATIONS_LIST_REQUEST,
    getNotificationsList
  );
}

function* getNotificationsList(action) {
  try {
    //   api call

    const message = yield call(Api, {
      endpoint: `${endpoint.getNotificationList}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    const parsedMessage = JSON.parse(message);

    yield put(notificationsListActions.notificationsListSuccess(parsedMessage));
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(notificationsListActions.notificationsListFailure(error));
  }
}
