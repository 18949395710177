import { notificationsListType } from "../types/notificationsList.type";

export const notificationsListActions = {
  notificationsListRequest,
  notificationsListSuccess,
  notificationsListFailure,
};
function notificationsListRequest(request, token) {
  return {
    type: notificationsListType.NOTIFICATIONS_LIST_REQUEST,
    request,
    token,
  };
}
function notificationsListSuccess(notificationList) {
  return {
    type: notificationsListType.NOTIFICATIONS_LIST_REQUEST_SUCCESS,
    notificationList,
  };
}
function notificationsListFailure(error) {
  return {
    type: notificationsListType.NOTIFICATIONS_LIST_REQUEST_FAILURE,
    error,
  };
}
