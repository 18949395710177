import { getAllDrugLookUpTransitionType } from "../../types";

export const getAllDrugLookUpTCActions = {
  getAllDrugLookUpRequest,
  getAllDrugLookUpSuccess,
  getAllDrugLookUpFailure,
};
function getAllDrugLookUpRequest(query, token) {
  return {
    type: getAllDrugLookUpTransitionType.GETALL_DRUG_LOOKUP_TC_REQUEST,
    query,
    token,
  };
}
function getAllDrugLookUpSuccess(getAll) {
  return {
    type: getAllDrugLookUpTransitionType.GETALL_DRUG_LOOKUP_TC_SUCCESS,
    getAll,
  };
}
function getAllDrugLookUpFailure(error) {
  return {
    type: getAllDrugLookUpTransitionType.GETALL_DRUG_LOOKUP_TC_FAILURE,
    error,
  };
}
