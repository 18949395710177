import { relationshipLookupCmerType } from "../types/relationshipLookupCmer.type";

export const relationshipListLookupCmerActions = {
  relationshipListLookupCmerRequest,
  relationshipListLookupCmerSuccess,
  relationshipListLookupCmerFailure,
};
function relationshipListLookupCmerRequest(token) {
  return {
    type: relationshipLookupCmerType.RELATIONSHIP_LOOKUP_CMER_REQUEST,
    token,
  };
}
function relationshipListLookupCmerSuccess(getAll) {
  return {
    type: relationshipLookupCmerType.RELATIONSHIP_LOOKUP_CMER_SUCCESS,
    getAll,
  };
}
function relationshipListLookupCmerFailure(error) {
  return {
    type: relationshipLookupCmerType.RELATIONSHIP_LOOKUP_CMER_FAILURE,
    error,
  };
}
