import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { loginActions } from "../../../../Login/actions/login.action";

import { drgActions } from "../actions";
import { drgTypes } from "../types";

export function* watchDRGs() {
  yield takeLatest(drgTypes.GET_DRG_REQUEST, getDRGs);
}

function* getDRGs(action) {
  try {
    const drgList = yield call(Api, {
      endpoint: `${endpoint.getDRGInfo}?SearchStr=${action.request.searchStr}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      //   body: action.request,
    });

    const parsedDrgList = JSON.parse(drgList);

    yield put(drgActions.getDRGRequestSuccess(parsedDrgList));
  } catch (error) {
    yield put(drgActions.getDRGRequestFailure(error));

    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
  }
}
