import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tab } from "../Tab";
import styles from "./Menu.module.scss";

class Menu extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.active[0].label,
    };
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
  };
  componentDidUpdate(prevProps) {
    if (prevProps.active !== this.props.active) {
      this.onClickTabItem(this.props.active[0].label);
    }
  }

  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab },
    } = this;

    return (
      <div className={styles.tab_content}>
        {/* <div className="tabs"> */}
        {/* <ol className={styles.tab_list}>
          {children.map((child) => {
            const { label } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={(tab) => {
                  onClickTabItem(tab);
                  this.props.getTab(tab);
                }}
              />
            );
          })}
        </ol> */}
        {/* <div className={styles.tab_content}> */}
        {children.map((child) => {
          if (child.props.label !== activeTab) return undefined;
          return child.props.children;
        })}
        {/* </div> */}
      </div>
    );
  }
}

export { Menu };
