import { dispositionOperationTypes } from "../types";

export const dispositionOperationActions = {
  dispositionOperationRequest,
  dispositionOperationRequestReset,
  dispositionOperationRequestSuccess,
  dispositionOperationRequestFailure,
};
function dispositionOperationRequest(request, token) {
  return {
    type: dispositionOperationTypes.DISPOSITION_OPERATION_REQUEST,
    request,
    token,
  };
}
function dispositionOperationRequestReset() {
  return {
    type: dispositionOperationTypes.DISPOSITION_OPERATION_REQUEST_RESET,
  };
}
function dispositionOperationRequestSuccess(message) {
  return {
    type: dispositionOperationTypes.DISPOSITION_OPERATION_REQUEST_SUCCESS,
    message,
  };
}
function dispositionOperationRequestFailure(error) {
  return {
    type: dispositionOperationTypes.DISPOSITION_OPERATION_REQUEST_FAILURE,
    error,
  };
}
