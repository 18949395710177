import { endpoint } from "../../../../api/Url";
import { put, call, debounce } from "@redux-saga/core/effects";
import {
  advanceCarePlanLookupCmerTypes,
  diagnosisLookupCmerTypes,
  getClinicalInfoCmTypes,
  pcpLookupCmerTypes,
  updateClinicalInfoCmTypes,
} from "../types";
import { Api2 } from "../../../../api/Api2";
import {
  advanceCarePlanLookupCmerActions,
  diagnosisLookupCmerActions,
  getClinicalInformationCmActions,
  pcpLookupCmerActions,
  updateClinicalInformationCmActions,
} from "../actions";
import { alertActions } from "../../../../components/AlertNew/store/AlertNew.action";

export function* watchClinicalInformationCM() {
  yield debounce(
    300,
    advanceCarePlanLookupCmerTypes.ADVANCECAREPLAN_LOOKUP_CMER_REQUEST,
    advanceCarePlanLookUpCmer
  );
  yield debounce(
    300,
    diagnosisLookupCmerTypes.DIAGNOSIS_LOOKUP_CMER_REQUEST,
    diagnosisLookupCmer
  );
  yield debounce(
    300,
    pcpLookupCmerTypes.PCP_LOOKUP_CMER_REQUEST,
    pcpLookupCmer
  );
  yield debounce(
    300,
    getClinicalInfoCmTypes.GET_CLINICAL_INFO_CM_REQUEST,
    getPatientsClinicalInformationCm
  );
  yield debounce(
    300,
    updateClinicalInfoCmTypes.UPDATE_CLINICAL_INFO_CM_REQUEST,
    updatePatientsClinicalInformationCm
  );
}

function* advanceCarePlanLookUpCmer(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.advanceCarePlanLookupCmer}`,
      jwt: "Bearer " + action.token,
    });

    yield put(
      advanceCarePlanLookupCmerActions.advanceCarePlanLookupSuccess(
        response?.data
      )
    );
  } catch (error) {
    yield put(
      advanceCarePlanLookupCmerActions.advanceCarePlanLookupFailure(error)
    );
    yield put(alertActions?.alertErrorRequest(error?.message));
  }
}
function* diagnosisLookupCmer(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.diagnosisLookupCmer}?search=${action?.request?.searchStr}`,
      jwt: "Bearer " + action.token,
    });

    yield put(diagnosisLookupCmerActions.diagnosisLookupSuccess(response));
  } catch (error) {
    yield put(
      diagnosisLookupCmerActions.diagnosisLookupFailure(error?.message)
    );
    yield put(alertActions?.alertErrorRequest(error?.message));
  }
}

function* pcpLookupCmer(action) {
  try {
    console.log(action, "action");
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.pcpLookupCmer}?search=${action?.request?.search}`,
      jwt: "Bearer " + action.token,
    });

    yield put(pcpLookupCmerActions.pcpLookupSuccess(response));
  } catch (error) {
    yield put(pcpLookupCmerActions.pcpLookupFailure(error?.message));
    yield put(alertActions?.alertErrorRequest(error?.message));
  }
}
function* getPatientsClinicalInformationCm(action) {
  try {
    console.log(action, "action");
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.patientClinicalInfoOperationsCM}?admissionId=${action?.request?.admissionId}&patientId=${action?.request?.patientId}`,
      jwt: "Bearer " + action.token,
    });
    let { Consultant, DcDiagnosis, socialInfo } = response;
    console.log(Consultant, DcDiagnosis, socialInfo, "imhere");
    yield put(
      getClinicalInformationCmActions.getClinicalInfoCmSuccess(
        Consultant,
        DcDiagnosis,
        socialInfo
      )
    );
  } catch (error) {
    yield put(
      getClinicalInformationCmActions.getClinicalInfoCmFailure(error?.message)
    );
    yield put(alertActions?.alertErrorRequest(error?.message));
  }
}
function* updatePatientsClinicalInformationCm(action) {
  try {
    console.log(action, "action");
    let response = yield call(Api2, {
      method: "POST",
      endpoint: `${endpoint.patientClinicalInfoOperationsCM}?admissionId=${action?.request?.admissionId}&patientId=${action?.request?.patientId}`,
      jwt: "Bearer " + action.token,
      body: action.body,
    });
    let { message } = response;
    console.log(message, "imhere");
    yield put(
      updateClinicalInformationCmActions.updateClinicalInfoCmSuccess(message)
    );
    yield put(alertActions.alertSuccessRequest(message));
  } catch (error) {
    yield put(
      updateClinicalInformationCmActions.updateClinicalInfoCmFailure(
        error?.message
      )
    );
    yield put(alertActions?.alertErrorRequest(error?.message));
  }
}
