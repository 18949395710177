import { icdSaveTypes } from "../types";

export const icdSaveActions = {
    icdSaveRequest,
    icdSaveRequestReset,
    icdSaveRequestSuccess,
    icdSaveRequestFailure,
  };
  function icdSaveRequest(request, token) {
    return {
      type: icdSaveTypes.ICD_SAVE_REQUEST,
      request,
      token,
    };
  }
  function icdSaveRequestReset() {
    return {
      type: icdSaveTypes.ICD_SAVE_REQUEST_RESET,
    };
  }
  function icdSaveRequestSuccess(message) {
    return {
      type: icdSaveTypes.ICD_SAVE_REQUEST_SUCCESS,
      message,
    };
  }
  function icdSaveRequestFailure(error) {
    return {
      type: icdSaveTypes.ICD_SAVE_REQUEST_FAILURE,
      error,
    };
  }
  