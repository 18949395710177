import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { LuRefreshCw } from "react-icons/lu";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { pageActions } from "../../../../store/actions";
import CustomToolBar from "./CustomToolBar";
import AppointmentModal from "./AppointmentModel";
import { Navigate } from "react-big-calendar";
import CustomWeekView from "./CustomWeekView";
import WeekView from "./WeekView";
import CustomFilters from "./CustomFilters";
import { format } from "date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  createScheduleAppoinmentsTCActions,
  getAllProviderSchedulesTCActions,
} from "../../actions";
import { useSelector } from "react-redux";
import { getAllFacilityLocationsTCActions } from "../../actions/Schedules/getAllFacilityLocations.action";
import { getAllProvidersTCActions } from "../../actions/Schedules/getAllProviders.action";
import { getAllRegionsTCActions } from "../../actions/Schedules/getAllRegions.action";
import { getScheduleAppoinmentsTCActions } from "../../actions/Schedules/getScheduleAppoinments.actions";
import { useLocation, useNavigate } from "react-router-dom";
import { alertActions } from "../../../../components/AlertNew/store/AlertNew.action";
import "./index.scss";
import dayjs from "dayjs";
import Calendar from "./Calendar";
import {
  Button,
  FormControlLabel,
  Snackbar,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useReactToPrint } from "react-to-print";
import PrintSample from "../../../Hospitalist/Print";
import { colors } from "../../../../themes/colors";
import { dateObject } from "../../../../util/dates";
import {
  timeZoneReSet,
  timeZoneSet,
} from "../../../../store/slice/TimeZone.slice";
import { refreshAppointmentRequest } from "../../reducers/Appointment/refreshAppointments.reducer";

//! customization of 2 weeks view start

CustomWeekView.range = (date, { localizer }) => {
  console.log(localizer, "localizer");
  const start = date;
  const end = localizer.add(start, 13, "day");

  let current = start;
  const range = [];

  while (localizer.lte(current, end, "day")) {
    // Check if the current day is not Saturday or Sunday
    // if (current.getDay() !== 0 && current.getDay() !== 6) {
    range.push(current);
    // }
    current = localizer.add(current, 1, "day");
  }

  return range;
};

CustomWeekView.navigate = (date, action, { localizer }) => {
  console.log(localizer, "localizer", date, action);

  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -14, "day");

    case Navigate.NEXT:
      return localizer.add(date, 14, "day");
    // case Navigate.TODAY:
    //   return localizer.add(date, 14, "day");

    default:
      return date;
  }
};

CustomWeekView.title = (date, { localizer }) => {
  const [start, ...rest] = CustomWeekView.range(date, { localizer });
  return localizer.format({ start, end: rest.pop() }, "dayRangeHeaderFormat");
}; //! customization of fortnight view end

//! customization of  weeks view start
WeekView.range = (date, { localizer }) => {
  const start = date;
  const end = localizer.add(start, 6, "day");

  let current = start;
  const range = [];

  while (localizer.lte(current, end, "day")) {
    // Check if the current day is not Saturday or Sunday
    // if (current.getDay() !== 0 && current.getDay() !== 6) {
    range.push(current);
    // }
    current = localizer.add(current, 1, "day");
  }

  return range;
};

WeekView.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -7, "day");

    case Navigate.NEXT:
      return localizer.add(date, 7, "day");
    // case Navigate.TODAY:
    //   return localizer.add(date, 14, "day");

    default:
      return date;
  }
};

WeekView.title = (date, { localizer }) => {
  const [start, ...rest] = WeekView.range(date, { localizer });
  return localizer.format({ start, end: rest.pop() }, "dayRangeHeaderFormat");
};
//! customization of  weeks view end

const AppointmentCalendar = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const timeZoneCurrent = useSelector((state) => state.timeZoneCurrent);
  const { state } = useLocation();
  const patientDetails = state?.patientDetails; //!details of patient
  const userData = useSelector((state) => state.login.userData);
  const localizer = momentLocalizer(moment); // or globalizeLocalizer
  // facility
  const facilityData = useSelector((state) => state.getAllFacilityLocation);
  //  provider or physician
  const providerData = useSelector((state) => state.getAllProviders);
  // region
  const regionData = useSelector((state) => state.getAllRegions);
  // provider or physician appoinments
  const getScheduleAppoinments = useSelector(
    (state) => state.getScheduleAppoinments
  );

  // provider or physician shifts
  const providerSchedulesData = useSelector(
    (state) => state.getAllProviderSchedules
  );
  const createScheduleAppoinments = useSelector(
    (state) => state.createScheduleAppoinments
  );
  const refreshAppointment = useSelector((state) => state?.refreshAppointment);
  const { views, ...otherProps } = useMemo(
    () => ({
      views: {
        // month: true,
        // week: true,
        // localizer: momentLocalizer(moment), // or globalizeLocalizer
        week: WeekView,

        day: true,
        fortnight: CustomWeekView,
        agenda: true,
      },
    }),
    []
  );

  //  !states start
  // const [date, setDate] = useState(new Date());
  const [date, setDate] = useState(
    moment().tz(timeZoneCurrent?.timeZone).toDate()
  );
  // const [currentView, setCurrentView] = useState("month"); // State to store the current view
  const [currentView, setCurrentView] = useState("week"); // State to store the current view
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [globalArr, setGlobalArr] = useState([]);

  // const [tempArr, setTempArr] = useState([]);

  const [isProviderSearched, setsProviderSearched] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    // from: dayjs(),

    // to: dayjs().add(6, "day"),
    from: moment().tz(timeZoneCurrent?.timeZone),
    to: moment().tz(timeZoneCurrent?.timeZone).add(6, "day"),

    pcp: null,
    // pcp: { PcpId: 0, PcpName: "IMC" },
    facility: "",
    region: "",
  });

  const [providerSchedules, setProviderSchedules] = useState([]); //! for all physician Schedule data
  const [providerAppoinments, setProviderAppoinments] = useState(); //!for a physician appoinments

  const [scheduleId, setScheduleId] = useState("");
  const [isAppointmentInPast, setIsAppointmentInPast] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState({
    reason: null,
    startTime: "",
    endTime: "",
    date: "",
    scheduleId: "",
    provider: null,
    startT: "",
    endT: "",
    childrens: [],
  });

  //  !states end
  const handleClose = useCallback(() => {
    setAppointmentDetails({
      reason: null,
      startTime: "",
      endTime: "",
      date: "",
      scheduleId: "",
      provider: null,
      childrens: [],
      // ...appointmentDetails,
      // reason: " ",
      // startTime: "",
      // endTime: "",
    });
    setErrors({});
    setAppointmentErrors({});
    // setAppointmentId(null);
    setShowAppointmentModal(false);
  }, [appointmentDetails]);

  // const CustomShowMoreLink = () => {
  //   return {
  //     style: {
  //       fontSize: 12,
  //     },
  //   };
  // };

  providerSchedules.forEach((schedule) => {
    schedule.start = moment(schedule.start).toDate();
    schedule.end = moment(schedule.end).toDate();
    schedule.allDay = false;
  });
  const handleReset = () => {
    try {
      let query = {
        from: dayjs().format("YYYY-MM-DD"),
        to:
          currentView === "fortnight"
            ? dayjs().add(13, "day").format("YYYY-MM-DD")
            : dayjs().add(6, "day").format("YYYY-MM-DD"),
        providerId: "",
        facilityId: "",
        regionId: "",
      };
      // setSearchQuery(query); //!this is for imc
      // setSearchQuery({ ...query, pcp: { PcpId: 0, PcpName: "IMC" } });

      // setCurrentView(Views.WEEK);
      if (!query.pcp || query.pcp === "" || query.pcp === "IMC") {
        setsProviderSearched(false);
      } else {
        setsProviderSearched(true);
      }

      setSearchQuery(query);

      // setSearchQuery({ ...query, pcp: { PcpId: 0, PcpName: "IMC" } });

      dispatch(
        getAllProviderSchedulesTCActions.getallProviderSchedulesRequest(
          query,
          userData.token
        )
      );
    } catch (error) {
      alertActions.alertErrorRequest("Something Went Wrong");
    }
  };

  const handleSearch = useCallback(() => {
    if (userData.token !== undefined) {
      let query = {
        // from: format(new Date(searchQuery?.from), "yyyy-MM-dd"),
        // to: format(new Date(searchQuery?.to), "yyyy-MM-dd"),
        from: dayjs(searchQuery?.from).format("YYYY-MM-DD"),
        to: dayjs(searchQuery?.to).format("YYYY-MM-DD"),
        // to: format(dayjs(searchQuery?.to), "yyyy-MM-dd"),
        // physicianId: searchQuery?.physician?.PhysicianID || "",
        pcpId: searchQuery?.pcp?.PcpId || "",
        facilityId: searchQuery?.facility?.AuthFacilityID || "",
        regionId: searchQuery?.region?.RegionId || "",
      };

      try {
        if (
          searchQuery?.pcp == null ||
          searchQuery?.pcp === "" ||
          searchQuery?.pcp?.PcpId === 0
        ) {
          setsProviderSearched(false);
        } else {
          setsProviderSearched(true);
        }
        dispatch(
          getAllProviderSchedulesTCActions.getallProviderSchedulesRequest(
            query,
            userData.token
          )
        );
      } catch (error) {
        alertActions.alertErrorRequest("Something Went Wrong");
      }
      // setCurrentView(Views.MONTH);
      // if (currentView === "day") {
      //   setCurrentView(Views.WEEK);
      // }
    }
  }, [dispatch, searchQuery, userData.token]);

  const handleViewChange = useCallback(
    (view) => {
      setLoading(true);
      // setDate(new Date());//! correct
      setDate(moment().tz(timeZoneCurrent?.timeZone).toDate());
      if (view !== "day") {
        let query = {
          // from: format(new Date(searchQuery?.from), "yyyy-MM-dd"),
          // to: format(new Date(searchQuery?.to), "yyyy-MM-dd"),
          from: dayjs().format("YYYY-MM-DD"),
          to:
            currentView === "fortnight"
              ? dayjs().add(6, "day").format("YYYY-MM-DD")
              : dayjs().add(13, "day").format("YYYY-MM-DD"),
          // from: dayjs(searchQuery?.from).format("YYYY-MM-DD"),
          // to: dayjs(searchQuery?.to).format("YYYY-MM-DD"),
          // physicianId: searchQuery?.physician?.PhysicianID || "",
          pcpId: searchQuery?.pcp?.PcpId || "",
          facilityId: searchQuery?.facility?.AuthFacilityID || "",
          regionId: searchQuery?.region?.RegionId || "",
        };
        setScheduleId("");
        dispatch(
          getAllProviderSchedulesTCActions.getallProviderSchedulesRequest(
            query,
            userData.token
          )
        );
        setAppointmentDetails({
          reason: null,
          startTime: "",
          endTime: "",
          date: "",
          scheduleId: "",
          provider: null,
          childrens: [],
        });
        setProviderAppoinments([]);
      }
      setCurrentView(view);
    },
    // [dispatch, searchQuery?.facility?.AuthFacilityID, searchQuery?.from, searchQuery?.physician?.PhysicianID, searchQuery?.region?.RegionId, searchQuery?.to, userData.token]
    [dispatch, searchQuery, userData.token]
  );
  useEffect(() => {
    if (currentView === "day") {
      const currentDate = new Date();
      const currentDateOnly = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );

      const bool = date.getTime() < currentDateOnly.getTime();

      setIsAppointmentInPast(bool);
    } else if (currentView === "fortnight") {
      setSearchQuery({
        ...searchQuery,
        from: dayjs(),
        to: dayjs().add(13, "day"),
      });
    } else if (currentView === "week") {
      setSearchQuery({
        ...searchQuery,
        from: dayjs(),
        to: dayjs().add(6, "day"),
      });
    }
  }, [currentView, date]);
  useEffect(() => {
    console.log(patientDetails, "patientDetails");
    if (patientDetails === undefined) {
      navigation("/transitioncare");
    } else {
      setLoading(true);
      let tempQuery = {
        ...searchQuery,
        pcp: providerData?.getAll?.find((data) =>
          data?.PcpName.replaceAll(",", "").includes(patientDetails?.PCP)
        ),
        facilityId: "",
        regionId: "",
      };
      let query = {
        // from: format(new Date(searchQuery?.from), "yyyy-MM-dd"),
        // to: format(new Date(searchQuery?.to), "yyyy-MM-dd"),
        //! from: dayjs(tempQuery?.from).format("YYYY-MM-DD"),
        //! to: dayjs(tempQuery?.to).format("YYYY-MM-DD"),
        from: moment
          .tz(tempQuery?.from, timeZoneCurrent?.timeZone)
          .format("YYYY-MM-DD"),
        to: moment
          .tz(tempQuery?.to, timeZoneCurrent?.timeZone)
          .format("YYYY-MM-DD"),

        // to: format(dayjs(tempQuery?.to), "yyyy-MM-dd"),
        // physicianId: tempQuery?.physician?.PhysicianID || "",
        pcpId: tempQuery?.pcp?.PcpId || "",
        facilityId: tempQuery?.facility?.AuthFacilityID || "",
        regionId: tempQuery?.region?.RegionId || "",
      };

      try {
        if (tempQuery?.pcp == null || tempQuery?.pcp === "") {
          setsProviderSearched(false);
        } else {
          setsProviderSearched(true);
        }
        dispatch(
          getAllProviderSchedulesTCActions.getallProviderSchedulesRequest(
            query,
            userData.token
          )
        );
      } catch (error) {
        alertActions.alertErrorRequest("Something Went Wrong");
      }
      setSearchQuery(tempQuery);
      setLoading(false);
      // setLoading(getScheduleAppoinments?.loading);
    }
  }, [patientDetails, providerData]);
  console.log(isProviderSearched, "isProviderSearched");
  const eventStyleGetter = (event) => {
    if (currentView !== "day") {
      // let isWithinRange = checkIsWithinRange(event?.Date);
      return {
        style: {
          display: "flex",
          justifyContent: "center",
          fontSize: 12,
          fontWeight: 500,
          // cursor: isWithinRange ? "" : "not-allowed",
          cursor: Boolean(event?.isOpen) ? "" : "not-allowed",
          // borderLeft: "6px solid rgba(0, 0, 0, 0.5)",
          // backgroundColor: Boolean(event?.isOpen) ? "#00B677" : "#E55C64",
          // backgroundColor: Boolean(event?.isOpen) ? "#00B677" : "#FA7570",
          background: Boolean(event?.isOpen)
            ? "rgba(0, 182, 119, 0.9)"
            : "rgba(250, 117, 112, 0.9)",
        },
      };
    }

    // return {
    //   style: {
    //     fontSize: 12,
    //     // cursor:'no-drop',
    //     backgroundColor: "#E55C64",
    //   },
    // };
  };

  const onCalenderNavigate = useCallback(
    (newDate) => {
      setDate(newDate);

      if (currentView === "day" && userData.token !== undefined) {
        let query = {
          ScheduleId: scheduleId,
        };
        setScheduleId(query?.ScheduleId);

        dispatch(
          getScheduleAppoinmentsTCActions?.getScheduleAppoinmentsRequest(
            query,
            userData?.token
          )
        );
      }
    },
    [currentView, dispatch, scheduleId, userData.token]
  );

  const [appointmentErrors, setAppointmentErrors] = useState(null);
  const patientAppointmentSchema = Yup.object().shape({
    provider: Yup.object().required("PCP Required"),
    reason: Yup.string().required("Reason is required"),
  });
  const onEventClick = useCallback(
    (newDate) => {
      // const centralStartTime = moment
      //   .tz(newDate.start, "America/Chicago")
      //   .format("YYYY-MM-DDTHH:mm:ss");
      // const centralStartTimeOBj = moment
      //   .tz(newDate.start, "America/Chicago")
      //   .toDate();

      // const centralEndTime = moment
      //   .tz(newDate.end, "America/Chicago")
      //   .format("YYYY-MM-DDTHH:mm:ss");
      const centralStartTime = moment

        .tz(newDate.start, timeZoneCurrent?.timeZone)
        .format("YYYY-MM-DDTHH:mm:ss");
      const centralStartTimeOBj = moment
        .tz(newDate.start, timeZoneCurrent?.timeZone)
        .toDate();

      const centralEndTime = moment
        .tz(newDate.end, timeZoneCurrent?.timeZone)
        .format("YYYY-MM-DDTHH:mm:ss");
      // const appointmentData = moment

      //   .tz(newDate.date, timeZoneCurrent?.timeZone)
      //   .format("YYYY-MM-DDTHH:mm:ss");
      setAppointmentDetails({
        ...appointmentDetails,
        // provider: isProviderSearched ? newDate?.title : "",
        date: newDate?.Date,
        // date: appointmentData,
        provider: Array.isArray(newDate?.childrens)
          ? newDate?.childrens?.find((providerObject) => providerObject?.isOpen)
          : newDate,

        scheduleId: isProviderSearched ? newDate : "",
        childrens: isProviderSearched ? [newDate] : newDate?.childrens,
        //! startTime: newDate?.start, //?defaulttime feature correct
        //! endTime: newDate?.end,//?defaulttime feature correct
        startTime: centralStartTime, // Update start time in Central Time
        endTime: centralEndTime, // Update end time in Central Time
        centralStartTimeOBj: centralStartTimeOBj, // Update end time in Central Time

        startT: moment
          .tz(newDate.start, timeZoneCurrent?.timeZone)
          .format("hh:mm a"),
        endT: moment
          .tz(newDate.end, timeZoneCurrent?.timeZone)
          .format("hh:mm a"),
        // startT: moment.tz(newDate.start, "America/Chicago").format("hh:mm a"),
        // endT: moment.tz(newDate.end, "America/Chicago").format("hh:mm a"),
      });

      setLoading(false);
      setShowAppointmentModal(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appointmentDetails, isProviderSearched]
  );
  console.log(timeZoneCurrent?.timeZone, "timeZoneCurrent?.timeZone");
  const formatUTCEvents = (utcEvents) => {
    return utcEvents.map((event) => ({
      ...event,
      // Convert UTC to Chicago time for display
      // start: moment.utc(event.start).tz("America/Chicago").toDate(),
      start: dateObject(event.start, timeZoneCurrent?.timeZone),
      end: dateObject(event.end, timeZoneCurrent?.timeZone),
      // end: moment.utc(event.end).tz("America/Chicago").toDate(),
    }));
  };

  const handleEventClick = (value) => {
    const callEventClicked = debounce((value) => {
      onEventClick(value);
      // onScheduleNavigate(value)
    }, 500);
    if (value?.isOpen) {
      setLoading(true);
      callEventClicked(value);
    } else {
      dispatch(
        alertActions.alertErrorRequest("0 Appointments Slots available.")
      );
    }
  };

  const slotPropGetter = useCallback(
    (date) => {
      const currentDate = new Date(date);
      const currentDateStart = new Date(currentDate);
      currentDateStart.setSeconds(0); // Reset seconds to 0
      currentDateStart.setMilliseconds(0); // Reset milliseconds to 0

      // Create currentDateEnd by adding 30 minutes
      const currentDateEnd = new Date(currentDateStart);
      currentDateEnd.setMinutes(currentDateEnd.getMinutes() + 30);

      // Check if there's a schedule object available
      const schedule = getScheduleAppoinments?.schedule;

      if (!schedule) {
        return { className: "slotDefault" }; // No schedule available
      }

      const scheduleStart = new Date(schedule.ScheduleStart);
      const scheduleEnd = new Date(schedule.ScheduleEnd);

      // Check if the given date is between adjusted start time and end time
      const isWithinSchedule =
        currentDateStart >= scheduleStart && currentDateEnd <= scheduleEnd;

      const backgroundColor =
        // currentView === "day" && isWithinSchedule ? "#b3e5fc" : "";
        // currentView === "day" && isWithinSchedule ? "#d5f9dd" : "";
        currentView === "day" && isWithinSchedule ? "#E1FFE9" : "";
      // currentView === "day" && isWithinSchedule ? "#cbf7e8" : "";

      return {
        className: "slotDefault",
        ...(backgroundColor && {
          style: {
            // cursor,
            backgroundColor,
            // color: backgroundColor === "#56E499" ? "black" : "black",
            color: "black",
          },
          title: "This is a tooltip.",
        }),
      };
    },
    [currentView, getScheduleAppoinments?.schedule]
  );
  // const handleSelectSlot = useCallback(
  //   (prop) => {
  //     if (getScheduleAppoinments) {
  //       const schedule = getScheduleAppoinments?.schedule;

  //       setShowAppointmentModal(true);
  //       setAppointmentDetails({
  //         ...appointmentDetails,
  //         startTime: prop?.start,
  //         endTime: prop?.end,
  //         reason: "",
  //       });
  //     }
  //   },
  //   [appointmentDetails, currentView, getScheduleAppoinments]
  // );

  //!  for appoiment schedule

  useEffect(() => {
    if (appointmentDetails?.provider !== "") {
      setErrors(null);
    }
  }, [appointmentDetails]);
  console.log(appointmentDetails, "appointmentDetails");
  const addAppointment = async () => {
    try {
      await patientAppointmentSchema.validate(appointmentDetails, {
        abortEarly: false,
      });
      setAppointmentErrors([]);

      let query = {
        // scheduleId: `${scheduleId}` || "",
        scheduleId: `${appointmentDetails?.provider?.ScheduleId}`,
        startTime: moment
          .tz(appointmentDetails?.startTime, timeZoneCurrent?.timeZone)
          .utc()
          .format(),
        endTime: moment
          .tz(appointmentDetails?.endTime, timeZoneCurrent?.timeZone)
          .utc()
          .format(),
        // startTime:
        //   `${moment
        //     .tz(
        //       `${appointmentDetails?.date} ${appointmentDetails?.startT}`,
        //       "MM/DD/YYYY hh:mm a",
        //       // "America/Chicago"
        //       timeZoneCurrent?.timeZone
        //     )
        //     .add(1, "hour") // Subtract 1 hour
        //     .utc()
        //     .format()}` || "",
        // endTime:
        //   `${moment
        //     .tz(
        //       `${appointmentDetails?.date} ${appointmentDetails?.endT}`,
        //       "MM/DD/YYYY hh:mm a",
        //       // "America/Chicago"
        //       timeZoneCurrent?.timeZone
        //     )
        //     .add(1, "hour") // Subtract 1 hour
        //     .utc()
        //     .format()}` || "",

        // startTime: new Date(appointmentDetails?.startTime) || "",
        // endTime: new Date(appointmentDetails?.endTime) || "",
        reason: appointmentDetails?.reason || " ",
        patientId: `${patientDetails?.PatientId}` || "",
        isFollowup: false,
      };
      if (appointmentDetails?.provider === "") {
        setErrors({ pcp: "PCP Required*" });
        return;
      }

      if (userData.token !== undefined) {
        dispatch(
          createScheduleAppoinmentsTCActions?.createScheduleAppoinmentsRequest(
            query,
            userData.token
          )
        );
        // setShowAppointmentModal(false);
        // navigation("/transitioncare");
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setAppointmentErrors(validationErrors);
    }
    // let query = {
    //   // scheduleId: `${scheduleId}` || "",
    //   scheduleId: `${appointmentDetails?.provider?.ScheduleId}`,
    //   startTime: new Date(appointmentDetails?.startTime) || "",
    //   endTime: new Date(appointmentDetails?.endTime) || "",
    //   reason: appointmentDetails?.reason || " ",
    //   patientId: `${patientDetails?.PatientId}` || "",
    //   isFollowup: false,
    // };
    // if (appointmentDetails?.provider === "") {
    //   setErrors({ pcp: "PCP Required*" });
    //   return;
    // }

    // if (userData.token !== undefined) {
    //   dispatch(
    //     createScheduleAppoinmentsTCActions?.createScheduleAppoinmentsRequest(
    //       query,
    //       userData.token
    //     )
    //   );
    //   // setShowAppointmentModal(false);
    //   // navigation("/transitioncare");
    // }
  };
  const componentRef = useRef();
  const [discardPrintDialog, setDiscardPrintDialog] = useState(false);

  const handleResetAppointmentDetails = () => {
    setShowAppointmentModal(false);

    setAppointmentDetails({
      ...appointmentDetails,
      reason: null,
      startTime: "",
      endTime: "",
      startT: "",
      endT: "",

      provider: null,
    });
    dispatch(
      createScheduleAppoinmentsTCActions.createScheduleAppoinmentsReset()
    );

    setTimeout(() => {
      let query = {
        // physicianId: searchQuery?.physician?.physicianId || null,
        pcpId: searchQuery?.pcp?.PcpId || null,
        facilityId: searchQuery?.facility?.facilityId || null,
        regionId: searchQuery?.region?.regionId || null,
        from: format(new Date(searchQuery?.from), "yyyy-MM-dd"),
        to: format(new Date(searchQuery?.to), "yyyy-MM-dd"),
      };
      dispatch(
        getAllProviderSchedulesTCActions.getallProviderSchedulesRequest(
          query,
          userData.token
        )
      );
      if (userData?.RoleID === 70) {
        navigation("/hospital");
      }
      // navigation("/transitioncare");
    }, 1500);
  };
  useEffect(() => {
    if (discardPrintDialog) {
      handleResetAppointmentDetails();
      setDiscardPrintDialog(false);
      navigation("/hospital");
    }
  }, [discardPrintDialog]);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onPrintError: (error) => console.log(error),
    documentTitle: `Appointment for ${
      (patientDetails?.lastName, patientDetails?.firstName)
    }`,
    onAfterPrint: (value) => {
      if (createScheduleAppoinments?.message === "Appointment Scheduled") {
        setDiscardPrintDialog(true);
      }
    },
  });
  useEffect(() => {
    if (createScheduleAppoinments?.message === "Appointment Scheduled") {
      handlePrint();
      // setShowAppointmentModal(false);

      // setAppointmentDetails({
      //   ...appointmentDetails,
      //   reason: null,
      //   startTime: "",
      //   endTime: "",
      //   provider: null,
      // });
      // dispatch(
      //   createScheduleAppoinmentsTCActions.createScheduleAppoinmentsReset()
      // );

      // setTimeout(() => {
      //   let query = {
      //     // physicianId: searchQuery?.physician?.physicianId || null,
      //     pcpId: searchQuery?.pcp?.PcpId || null,
      //     facilityId: searchQuery?.facility?.facilityId || null,
      //     regionId: searchQuery?.region?.regionId || null,
      //     from: format(new Date(searchQuery?.from), "yyyy-MM-dd"),
      //     to: format(new Date(searchQuery?.to), "yyyy-MM-dd"),
      //   };
      //   dispatch(
      //     getAllProviderSchedulesTCActions.getallProviderSchedulesRequest(
      //       query,
      //       userData.token
      //     )
      //   );
      //   if (userData?.RoleID === 70) {
      //     navigation("/hospital");
      //   }
      //   // navigation("/transitioncare");
      // }, 1500);
    }
  }, [createScheduleAppoinments?.message]);
  //! useEffects
  useEffect(() => {
    dispatch(pageActions.pageRequest("Schedule"));

    if (userData.token !== undefined) {
      let query = {
        // physicianId: searchQuery?.physician?.physicianId || null,
        // pcpId: searchQuery?.pcp?.PcpId || null,
        pcpId:
          providerData?.getAll?.find((data) =>
            data?.PcpName.replaceAll(",", "").includes(patientDetails?.PCP)
          ) || null,

        facilityId: searchQuery?.facility?.facilityId || null,
        regionId: searchQuery?.region?.regionId || null,
        from: format(new Date(searchQuery?.from), "yyyy-MM-dd"),
        to: format(new Date(searchQuery?.to), "yyyy-MM-dd"),
      };
      // dispatch(
      //   getAllProviderSchedulesTCActions.getallProviderSchedulesRequest(
      //     query,
      //     userData.token
      //   )
      // );
      dispatch(
        getAllFacilityLocationsTCActions.getAllFacilityLocationRequest(
          query,
          userData.token
        )
      );
      dispatch(getAllProvidersTCActions.getAllProvidersRequest(userData.token));
      dispatch(
        getAllRegionsTCActions.getallRegionsRequest(query, userData.token)
      );
      // let action = {
      //   token: userData.token,
      // };
      // dispatch(refreshAppointmentRequest(action));
    }
  }, [userData.token]);

  useEffect(() => {
    if (providerSchedulesData && providerSchedulesData.getAll) {
      const formattedData = providerSchedulesData.getAll.map((event) => ({
        ...event,
        start: new Date(event.start),

        end: new Date(event.end),
        allDay: searchQuery.pcp === null ? true : false,
        title: event.title
          .split(" ")
          .map((word) => {
            return word?.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          })
          .join(" "),
      }));

      // Define the start and end times

      // Create an array to hold the hour intervals and initialize counts
      // Create a map to hold events grouped by date
      const eventsByDate = new Map();

      // Group events by date
      providerSchedulesData.getAll.forEach((userData) => {
        const startDate = new Date(userData.start).toLocaleDateString();
        if (!eventsByDate.has(startDate)) {
          eventsByDate.set(startDate, []);
        }
        eventsByDate.get(startDate).push(userData);
      });

      // Loop through each hour interval
      for (let index = 7; index < 19; index++) {
        const currentHour = index;
        const nextHour = index + 1;

        // Filter events within the current hour interval
        const eventsWithinHour = [];
        eventsByDate.forEach((events) => {
          events.forEach((userData) => {
            const startHour = new Date(userData.start).getHours();
            const endHour = new Date(userData.end).getHours();

            if (startHour >= currentHour && endHour <= nextHour) {
              eventsWithinHour.push(userData);
            }
          });
        });

        const groupedEvents = Object.values(
          eventsWithinHour.reduce((acc, obj) => {
            const date = obj.Date;
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(obj);
            return acc;
          }, {})
        );

        setGlobalArr((preValue) => [...preValue, groupedEvents]);
      }

      setProviderSchedules(formattedData);
    } else {
      setProviderSchedules([]);
    }
    setLoading(providerSchedulesData?.loading);
  }, [providerSchedulesData]);

  useEffect(() => {
    if (getScheduleAppoinments?.getAll) {
      let action = {
        token: userData.token,
      };
      // dispatch(refreshAppointmentRequest(action));

      const processedAppointments = getScheduleAppoinments?.getAll?.map(
        (event) => ({
          ...event,
          start: new Date(event.start),
          end: new Date(event.end),
          title: event.title
            .split(" ")
            .map((word) => {
              return (
                word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase()
              );
            })
            .join(" "),
        })
      );
      setProviderAppoinments(processedAppointments);
    } else {
      setProviderAppoinments([]);
    }
    setLoading(getScheduleAppoinments?.loading);
  }, [getScheduleAppoinments?.getAll, getScheduleAppoinments?.loading]);

  useEffect(() => {
    if (userData.token !== undefined && currentView === "day") {
      let query = {
        ScheduleId: scheduleId,
      };

      dispatch(
        getScheduleAppoinmentsTCActions?.getScheduleAppoinmentsRequest(
          query,
          userData?.token
        )
      );
      if (createScheduleAppoinments?.message === "Appointment Scheduled") {
        setShowAppointmentModal(false);
        navigation("/transitioncare");

        setAppointmentDetails({
          ...appointmentDetails,
          reason: null,
          startTime: "",
          endTime: "",
          provider: null,
          startT: "",
          endT: "",
        });
        dispatch(
          createScheduleAppoinmentsTCActions.createScheduleAppoinmentsReset()
        );
      }
    }
  }, [
    createScheduleAppoinments,
    currentView,
    dispatch,
    scheduleId,
    userData.token,
  ]);

  const getScrollToTime = () => {
    if (currentView === "day") {
      return new Date(getScheduleAppoinments?.schedule?.ScheduleStart);
    } else if (currentView === "fortnight" || currentView === "week") {
      const sortedSchedules = providerSchedules?.sort(
        (itm1, itm2) => itm1?.start - itm2?.start
      );
      if (sortedSchedules && sortedSchedules.length > 0) {
        return new Date(sortedSchedules[0]?.start);
      }
    }
    return null;
  };

  let temp = [];
  let remainingEvents = [...providerSchedules];
  providerSchedules.forEach((test) => {
    let existingIndex = temp.findIndex(
      (event) =>
        new Date(event.start).getTime() === new Date(test.start).getTime() &&
        new Date(event.end).getTime() === new Date(test.end).getTime()
    );

    if (existingIndex !== -1) {
      // Event with the same start and end times already exists in temp
      if (!temp[existingIndex].childrens) {
        temp[existingIndex].childrens = [];
      }

      // Check if the event is not already in the childrens array
      if (
        !temp[existingIndex].childrens.some(
          (child) => child.ScheduleId === test.ScheduleId
        )
      ) {
        temp[existingIndex].childrens.push(test);
      }
    } else {
      // No event with the same start and end times exists in temp
      let arr = remainingEvents.filter(
        (val) =>
          new Date(val.start).getTime() === new Date(test.start).getTime() &&
          new Date(val.end).getTime() === new Date(test.end).getTime()
      );

      if (arr.length !== 0) {
        let tesArr = {
          ...test,
          hoverTitle: `${
            arr.filter((ar) => Boolean(ar.isOpen)).length
          } Slots Available for booking`,
          start: test.start,
          end: test.end,
          childrens: arr,
          count: arr.length,
          booked: arr.filter((ar) => !Boolean(ar.isOpen)).length,
          isOpen: arr.some((ar) => ar.isOpen),
          open: arr.filter((ar) => Boolean(ar.isOpen)).length,
        };
        temp.push(tesArr);

        remainingEvents = remainingEvents.filter(
          (val) => !arr.some((ar) => ar.ScheduleId === val.ScheduleId)
        );
      }
    }
  });

  // const handleRefresh = (e) => {
  //   let action = {
  //     token: userData.token,
  //   };
  //   dispatch(refreshAppointmentRequest(action));
  // };
  // useEffect(() => {
  //   if (refreshAppointment?.message) {
  //     let query = {
  //       // physicianId: searchQuery?.physician?.physicianId || null,
  //       // pcpId: searchQuery?.pcp?.PcpId || null,
  //       pcpId: searchQuery?.pcp?.PcpId || null,

  //       facilityId: searchQuery?.facility?.facilityId || null,
  //       regionId: searchQuery?.region?.regionId || null,
  //       from: format(new Date(searchQuery?.from), "yyyy-MM-dd"),
  //       to: format(new Date(searchQuery?.to), "yyyy-MM-dd"),
  //     };
  //     dispatch(
  //       getAllProviderSchedulesTCActions.getallProviderSchedulesRequest(
  //         query,
  //         userData.token
  //       )
  //     );
  //   }
  // }, [refreshAppointment, timeZoneCurrent?.timeZone]);
  const [isProviderSchedulesEmpty, setIsProviderSchedulesEmpty] =
    useState(false);

  useEffect(() => {
    console.log(
      providerSchedules,
      providerSchedules?.length,
      "providerSchedules"
    );
    if (!providerSchedulesData?.loading) {
      if (providerSchedules?.length === 0) {
        setIsProviderSchedulesEmpty(true);
      } else {
        setIsProviderSchedulesEmpty(false);
      }
    }
  }, [providerSchedules]);

  const handleCloseIsEmptyPopUp = () => {
    setIsProviderSchedulesEmpty(false);
  };

  // const backendEvents = [
  //   {
  //     id: 1,
  //     title: "Test",
  //     start: "2024-10-22T20:00:00Z", // UTC time from backend
  //     end: "2024-10-22T21:00:00Z", // UTC time from backend
  //     isOpen: 1,
  //   },
  // ];

  const [isCSTTimeZone, setISCSTTimeZone] = useState(true);
  const handleTimeZoneChange = (event, isChecked) => {
    setISCSTTimeZone(isChecked);
    const request = { zone: isChecked ? "America/Chicago" : null };
    dispatch(isChecked ? timeZoneSet(request) : timeZoneReSet());
  };

  // const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  //   width: 62,
  //   height: 34,
  //   padding: 7,
  //   '& .MuiSwitch-switchBase': {
  //     margin: 1,
  //     padding: 0,
  //     transform: 'translateX(6px)',
  //     '&.Mui-checked': {
  //       color: '#fff',
  //       transform: 'translateX(22px)',
  //       '& .MuiSwitch-thumb:before': {
  //         backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
  //           '#fff',
  //         )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
  //       },
  //       '& + .MuiSwitch-track': {
  //         opacity: 1,
  //         backgroundColor: '#aab4be',
  //         ...theme.applyStyles('dark', {
  //           backgroundColor: '#8796A5',
  //         }),
  //       },
  //     },
  //   },
  //   '& .MuiSwitch-thumb': {
  //     backgroundColor: '#001e3c',
  //     width: 32,
  //     height: 32,
  //     '&::before': {
  //       content: "''",
  //       position: 'absolute',
  //       width: '100%',
  //       height: '100%',
  //       left: 0,
  //       top: 0,
  //       backgroundRepeat: 'no-repeat',
  //       backgroundPosition: 'center',
  //       backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
  //         '#fff',
  //       )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
  //     },
  //     ...theme.applyStyles('dark', {
  //       backgroundColor: '#003892',
  //     }),
  //   },
  //   '& .MuiSwitch-track': {
  //     opacity: 1,
  //     backgroundColor: '#aab4be',
  //     borderRadius: 20 / 2,
  //     ...theme.applyStyles('dark', {
  //       backgroundColor: '#8796A5',
  //     }),
  //   },
  // }));

  const IOSSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&::after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));
  const [currentTime, setCurrentTime] = useState(
    moment().tz(moment.tz.guess()).format("hh:mm A")
  );
  const [centralTime, setCentralTime] = useState(
    moment().tz(timeZoneCurrent?.timeZone).format("hh:mm A")
  );

  const timeZoneAbbreviationMap = {
    CST: "Central Standard Time",
    CDT: "Central Daylight Time",
    EST: "Eastern Standard Time",
    EDT: "Eastern Daylight Time",
    MST: "Mountain Standard Time",
    MDT: "Mountain Daylight Time",
    PST: "Pacific Standard Time",
    PDT: "Pacific Daylight Time",
    // Add more abbreviations as needed
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment().tz(moment.tz.guess()).format("hh:mm A"));
      setCentralTime(moment().tz(timeZoneCurrent?.timeZone).format("hh:mm A"));
    }, 60000); // Update every 60 seconds (60000 ms)

    const request = { zone: "America/Chicago" };
    timeZoneSet(request);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  const getFormattedTimeZone = (zone) => {
    const currentTime = moment.tz(zone);
    console.log(currentTime.format("zz"));
    return `${currentTime.format("zz")}`;
  };
  // Assuming timeZoneCurrent?.timeZone contains something like "America/Chicago"
  let value = getFormattedTimeZone(timeZoneCurrent?.timeZone);
  console.log(value, "value");
  value = timeZoneAbbreviationMap[value];

  return (
    <Box sx={{ px: 4 }}>
      {/* for loading start*/}
      <Box sx={{ width: 500, fontSize: 12 }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={isProviderSchedulesEmpty && !loading}
          onClose={handleCloseIsEmptyPopUp}
          autoHideDuration={5000}
          style={{
            fontSize: 10,
            fontFamily: "serif",
          }}
          message={
            <i>
              {`There are no slots available for `}
              <b>{`${searchQuery?.pcp?.PcpName} `}</b>
              {`Please reset for IMC Schedules`}
            </i>
          }
          action={
            <>
              <Button
                // color="secondary"
                sx={{
                  // color: colors?.color_primary,
                  color: "#007BFF",

                  textTransform: "capitalize",
                  textDecoration: "underline",
                  ":hover": {
                    textDecoration: "underline",
                  },
                }}
                size="small"
                onClick={handleCloseIsEmptyPopUp}
              >
                Close
              </Button>
              <Button
                // color="secondary"
                sx={{
                  color: colors?.color_primary,
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  // textDecoration: "underline",
                  ":hover": {
                    // textDecoration: "underline",
                  },
                }}
                size="small"
                onClick={handleReset}
              >
                Reset
              </Button>
            </>
          }
        />
      </Box>
      <Box sx={{ display: "none" }}>
        {" "}
        <PrintSample
          ref={componentRef}
          firstName={state?.patientDetails?.FirstName}
          lastName={state?.patientDetails?.LastName}
          {...state?.patientDetails}
          {...searchQuery}
          {...appointmentDetails}
        />
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || refreshAppointment?.loading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>{" "}
      {/* for loading endthis */}
      <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
        <AppointmentModal
          open={showAppointmentModal}
          handleClose={handleClose}
          setAppointmentDetails={setAppointmentDetails}
          appointmentDetails={appointmentDetails}
          // providersData={providersData}
          addAppointment={addAppointment}
          patientDetails={patientDetails}
          errors={errors}
          appointmentErrors={appointmentErrors}
        />
        <Grid
          container
          item
          spacing={1} //sx={{backgroundColor:'#00B677'}}
        >
          {/* <Grid item xs={12} justifyContent={"flex-end"}>
            <Typography sx={{ fontSize: 12, fontStyle: "oblique" }}>
              Last Synced - 7/24/24, 7:42:36 AM
            </Typography>
          </Grid> */}
          <Grid item xs={12} justifyContent={"space-between"} container>
            <Tooltip title="go back">
              {/* Back */}
              <KeyboardBackspaceIcon
                onClick={() => navigation(-1)}
                sx={{
                  // color: colors?.color_primary_txt,
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              />
            </Tooltip>
            <Tooltip
              title={`${
                refreshAppointment?.timeStamp
                  ? `Last Synced: ${refreshAppointment?.timeStamp}`
                  : "Not Synced Yet"
              }`}
            >
              <Button
                startIcon={<LuRefreshCw />}
                sx={{
                  // width: "fit-content",
                  // backgroundColor: colors?.color_active,
                  // "&:hover": {
                  //   backgroundColor: colors?.color_active,
                  // },
                  // color: colors?.color_primary_txt,
                  // "& :hover": {
                  //   color: colors?.color_primary_txt,
                  // },
                  position: "sticky",
                  top: 10,
                  right: 9,

                  textTransform: "capitalize",
                }}
                // onClick={handleRefresh}
              >
                Refresh
              </Button>
            </Tooltip>
          </Grid>

          {/* container of filters */}
          <Grid item container>
            <Grid item xs={12}>
              <CustomFilters
                facilityLocationsData={facilityData?.getAll}
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
                providersData={providerData?.getAll}
                regionsData={regionData?.getAll}
                handleSearch={handleSearch}
                handleReset={handleReset}
              />
            </Grid>
          </Grid>

          <Grid item container>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid
            item
            // container
            justifyContent={"space-between"}
            sx={{
              p: 0,
              m: 0,
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{
                fontSize: 12,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid item>
                <Typography variant="subtitle2">
                  Local Time{" "}
                  {/* {new Date().toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })} */}
                  {/* {moment().tz(moment.tz.guess()).format("hh:mm A")} */}
                  {currentTime}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item>
                <Tooltip title={`All appointments are in ${value}`}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: colors.color_risk_level,
                    }}
                  >
                    {/* {timeZoneCurrent?.timeZone} -{" "} */}
                    {value} -{" "}
                    {/* {moment.tz(timeZoneCurrent?.timeZone).format("hh:mm A")} */}
                    {centralTime}
                  </Typography>
                </Tooltip>{" "}
              </Grid>
              {/* <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={isCSTTimeZone}
                    onChange={handleTimeZoneChange}
                  />
                }
                label={
                  <Grid item>
                    <Typography variant="subtitle2">
                      {timeZoneCurrent?.timeZone} -{" "}
                      {moment.tz(timeZoneCurrent?.timeZone).format("hh:mm A")}
                    </Typography>
                  </Grid>
                }
              /> */}
              {/* <Switch
                checked={isCSTTimeZone}
                onChange={handleTimeZoneChange}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  "& .MuiSwitch-thumb": {
                    position: "relative",
                    "&:before": {
                      content: '"CT"',
                      padding: 2,
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "#fff",
                      fontSize: "15px",
                    },
                  },

                  "& .MuiSwitch-track": {
                    borderRadius: 20, // Make the track more rounded
                    position: "relative",

                    "&::before": {
                      content: '""',
                      position: "absolute",
                      padding: 2,
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "#fff",
                      fontSize: "12px",
                      opacity: isCSTTimeZone ? 0 : 1, // Show "System" only when off
                      transition: "opacity 0.3s",
                    },
                  },
                }}
              /> */}
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12}>
              <Calendar
                views={views}
                view={currentView}
                onView={handleViewChange} // Listen to view changes
                date={date}
                step={15}
                timeslots={2}
                showMultiDayTimes
                loading={loading}
                // min={moment
                //   .tz(
                //     "2024-01-24 07:00",
                //     "YYYY-MM-DD HH:mm",
                //     timeZoneCurrent?.timeZone || "America/Chicago"
                //   )
                //   .toDate()}
                // max={moment
                //   .tz(
                //     "2024-01-24 20:00",
                //     "YYYY-MM-DD HH:mm",
                //     timeZoneCurrent?.timeZone || "America/Chicago"
                //   )
                //   .toDate()}
                min={dateObject("2024-01-24 07:00", timeZoneCurrent?.timeZone)}
                max={dateObject("2024-01-24 20:00", timeZoneCurrent?.timeZone)}
                // min={new Date(2017, 10, 0, 7, 0, 0)}
                // max={new Date(2017, 10, 0, 19, 30, 0)}
                // min={moment("2024-10-22T12:00:00Z").toDate()} // Convert moment to Date
                // max={moment("2024-10-22T01:00:00Z").toDate()} // Fix the format and convert to Date
                showMoreButton={true}
                selectable={currentView === "day" ? "ignoreEvents" : false}
                popup
                startAccessor="start"
                endAccessor="end"
                localizer={localizer}
                onNavigate={onCalenderNavigate}
                onSelectEvent={handleEventClick}
                components={{
                  toolbar: (props) => (
                    <CustomToolBar
                      currentView={currentView}
                      setCurrentView={setCurrentView}
                      // physicianName={physicianName}
                      getScheduleAppoinments={getScheduleAppoinments}
                      appointmentDetails={appointmentDetails}
                      {...props}
                    />
                  ),
                  event: ({ event }) => {
                    return !isProviderSearched ? (
                      <div
                        style={{
                          backgroundColor: "rgb(0,0,0,0.3)",
                          width: "fit-content",
                          borderRadius: "50%",
                          padding: "0.3vmax",
                          margin: "0.1vmax",
                          color: "#ffffff",
                        }}
                      >
                        {/* {event.booked}/{event.count} */}
                        {event.open}/{event.count}
                      </div>
                    ) : (
                      <div>{event.title}</div>
                    );
                  },

                  // },
                  // event: CustomEvent,
                }}
                tooltipAccessor={isProviderSearched ? "title" : "hoverTitle"}
                eventStyleGetter={eventStyleGetter}
                slotPropGetter={slotPropGetter}
                eventPropGetter={eventStyleGetter}
                onDrillDown={() => {}}
                // onSelectSlot={handleSelectSlot}
                scrollToTime={getScrollToTime()}
                // allDayMaxRows={1}
                // events={[
                //   {
                //     id: 1,
                //     title: "Test",
                //     // start: moment("2024-10-22T15:00:00Z").toDate(),
                //     // end: moment("2024-10-22T16:00:00Z").toDate(),
                //     start: moment("2024-10-22T15:00:00Z").toDate(),
                //     end: moment("2024-10-22T16:00:00Z").toDate(),
                //     isOpen: 1,
                //   },
                // ]}
                // events={formatUTCEvents(backendEvents)}
                events={
                  isProviderSearched
                    ? formatUTCEvents(providerSchedules)
                    : formatUTCEvents(temp)
                }
                // events={temp}
                // events={
                //   currentView !== "day"
                //     ? providerSchedules
                //     : tempproviderAppoinments
                // }

                formats={{
                  dayHeaderFormat: (date) => moment(date).format("dddd @ DD"),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Box>
  );
};

export default AppointmentCalendar;
