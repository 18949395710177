import { printTypes } from "../types/print.type";

const initialState = {
  loading: false,

  patient: [],
  familyHistory: [],
  socialInfo: [],
  allergies: [],
  currentMedication: [],
  screenList: [],
  biometricList: [],
  screenOtherComment: "",

  error: "",
};

export const printReducer = (state = initialState, action) => {
  switch (action.type) {
    case printTypes.PRINT_REQUEST:
      return { ...initialState, loading: true };
    case printTypes.PRINT_REQUEST_SUCCESS:
      return {
        ...initialState,
        patient: action.patient,
        familyHistory: action.familyHistory,
        socialInfo: action.socialInfo,
        allergies: action.allergies,
        currentMedication: action.currentMedication,
        screenList: action.screenList,
        biometricList: action.biometricList,
        screenOtherComment: action.screenOtherComment,
      };
    case printTypes.PRINT_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};
