export const comprehensiveTypes = {
  GET_ASSESSMENT_REQUEST: "GET_COMPREHENSIVE_ASSESSMENT_REQUEST",
  GET_ASSESSMENT_REQUEST_SUCCESS:
    "GET_COMPREHENSIVE_ASSESSMENT_REQUEST_SUCCESS",
  GET_ASSESSMENT_REQUEST_FAILURE:
    "GET_COMPREHENSIVE_ASSESSMENT_REQUEST_FAILURE",

  GET_CHANGES_ASSESSMENT_REQUEST:
    "GET_COMPREHENSIVE_CHANGES_ASSESSMENT_REQUEST",
  GET_CHANGES_ASSESSMENT_REQUEST_SUCCESS:
    "GET_COMPREHENSIVE_CHANGES_ASSESSMENT_REQUEST_SUCCESS",
  GET_CHANGES_ASSESSMENT_REQUEST_FAILURE:
    "GET_COMPREHENSIVE_CHANGES_ASSESSMENT_REQUEST_FAILURE",

  UPDATE_ASSESSMENT_REQUEST: "UPDATE_COMPREHENSIVE_ASSESSMENT_REQUEST",
  UPDATE_ASSESSMENT_REQUEST_SUCCESS:
    "UPDAT_COMPREHENSIVE_ASSESSMENT_REQUEST_SUCCESS",
  UPDATE_ASSESSMENT_REQUEST_FAILURE:
    "UPDATE_COMPREHENSIVE_ASSESSMENT_REQUEST_FAILURE",
  UPDATE_ASSESSMENT_REQUEST_RESET:
    "UPDATE_COMPREHENSIVE_ASSESSMENT_REQUEST_RESET",
};
