import { updatePatientBillingType } from "../types/updatePatientBilling.type";

export const updatePatientBillingActions = {
  updatePatientBillingRequest,
  updatePatientBillingSuccess,
  updatePatientBillingFailure,
  updatePatientBillingReset,
};
function updatePatientBillingRequest(request, token) {
  return {
    type: updatePatientBillingType.UPDATE_PATIENT_BILLING_REQUEST,
    request,
    token,
  };
}
function updatePatientBillingSuccess(message) {
  return {
    type: updatePatientBillingType.UPDATE_PATIENT_BILLING_SUCCESS,
    message,
  };
}
function updatePatientBillingFailure(error) {
  return {
    type: updatePatientBillingType.UPDATE_PATIENT_BILLING_FAILURE,
    error,
  };
}
function updatePatientBillingReset() {
  return {
    type: updatePatientBillingType.UPDATE_PATIENT_BILLING_RESET,
  };
}
