import { put, takeLatest, call } from "@redux-saga/core/effects";
import { callHistoryActions } from "../actions/callHistory.action";
import { callHistoryType } from "../types/callHistory.type";
import { endpoint } from "../../../../../api/Url"; 
import { Api } from "../../../../../api"; 
import { loginActions} from "../../../../Login/actions/login.action";

export function* watchCallHistory() {
  yield takeLatest(callHistoryType.CALL_HISTORY_REQUEST, getCallHistory);
}

function* getCallHistory(action) {
  try {
    //   api call
    const data = yield call(Api, {
      endpoint:`${endpoint.getCallHistory}?AdmissionID=${action.request.patientAdmissionId}`,
      method: "POST",
      jwt: "Bearer " + action.token,
    });
 
  const parsedData = JSON.parse(data);
      parsedData.CallHistoryDetailList!==undefined && parsedData.CallHistoryDetailList.length>0
      ? yield put(callHistoryActions.callHistoryRequestSuccess(parsedData.CallHistoryDetailList))
      :  yield put(
        callHistoryActions.callHistoryRequestFailure("No data found")
      );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(callHistoryActions.callHistoryRequestFailure(error));
  }
}
