import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { deleteAppointmentActions } from "../actions/deleteAppointment.action";
import { deleteAppointmentTypes } from "../types/deleteAppointment.type";

export function* watchDeleteAppointment() {
  yield takeLatest(
    deleteAppointmentTypes.DELETE_APPOINTMENT_REQUEST,
    deleteAppointment
  );
}
function* deleteAppointment(action) {
  try {
    const data = yield call(Api, {
      endpoint: `${endpoint.deleteAppointment}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    const parsedData = JSON.parse(data);

    yield put(
      deleteAppointmentActions.deleteAppointmentRequestSuccess(parsedData[0])
    );
  } catch (error) {
    yield put(deleteAppointmentActions.deleteAppointmentRequestFailure(error));
  }
}
