import { getFileDataType } from "../types/getFileData.type";

const initialState = {
  loading: false,
  data: [],
  totalrecords: 0,
};

export const getFileDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case getFileDataType.GET_FILE_DATA_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case getFileDataType.GET_FILE_DATA_SUCCESS:
      return {
        ...initialState,
        data: action.data,
        totalrecords: action.totalrecords,
        loading: false,
      };
    case getFileDataType.GET_FILE_DATA_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case getFileDataType.GET_FILE_DATA_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
