import { sendMessageTypes } from "../types";

const initialState = {
  sending: false,
  message: "",
  error: "",
};

export const sendMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case sendMessageTypes.SEND_MESSAGE_REQUEST:
      return { ...initialState, sending: true };
    case sendMessageTypes.SEND_MESSAGE_REQUEST_SUCCESS:
      return { ...initialState, message: action.message };
    case sendMessageTypes.SEND_MESSAGE_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    case sendMessageTypes.SEND_MESSAGE_REQUEST_RESET:
      return { ...initialState };
    default:
      return state;
  }
};
