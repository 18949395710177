import { put, takeEvery, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { insurancePlanCompneyActions } from "../actions";
import { insurancePlanCompneyTypes } from "../types";

export function* watchDMInsurancePlanCompney() {
  yield takeEvery(
    insurancePlanCompneyTypes.INSURANCE_PLAN_COMPNEY_REQUEST,
    getAllInsurancePlanCompney
  );
}

function* getAllInsurancePlanCompney(action) {
  try {
    let data = yield call(Api, {
      method: "POST",
      endpoint: endpoint.getAllDMInsurancePlanCompneyData,
      jwt: "Bearer " + action.token,
      // body: action.request,
    });
    let parsedData = JSON.parse(data);

    yield put(
      insurancePlanCompneyActions.insurancePlanCompneyRequestSuccess(
        parsedData.InsuranceList
      )
    );
  } catch (error) {
    yield put(
      insurancePlanCompneyActions.insurancePlanCompneyRequestFailure(error)
    );
  }
}
