import { frequencyType } from "../types/frequency.type";

export const frequencyActions = {
  frequencyRequest,
  frequencyRequestSuccess,
  frequencyRequestFailure,
};

function frequencyRequest(request, token) {
  return { type: frequencyType.FREQUENCY_REQUEST, request, token };
}

function frequencyRequestSuccess(getFrequency) {
  return { type: frequencyType.FREQUENCY_REQUEST_SUCCESS, getFrequency };
}

function frequencyRequestFailure(error) {
  return { type: frequencyType.FREQUENCY_REQUEST_FAILURE, error };
}
