import { sendMessageTypes } from "../types";

export const sendMessageActions = {
  sendMessageRequest,
  sendMessageRequestSuccess,
  sendMessageRequestFailure,
  sendMessageRequestReset,
};

function sendMessageRequest(request, token) {
  return {
    type: sendMessageTypes.SEND_MESSAGE_REQUEST,
    request,
    token,
  };
}
function sendMessageRequestSuccess(message) {
  return {
    type: sendMessageTypes.SEND_MESSAGE_REQUEST_SUCCESS,
    message: message,
  };
}
function sendMessageRequestFailure(error) {
  return {
    type: sendMessageTypes.SEND_MESSAGE_REQUEST_FAILURE,
    error: error,
  };
}
function sendMessageRequestReset() {
  return {
    type: sendMessageTypes.SEND_MESSAGE_REQUEST_RESET,
  };
}
