import { getallCptType } from "../types/getCpt.type";

export const getAllCptActions = {
  getAllCptRequest,
  getAllCptSuccess,
  getAllCptFailure,
};
function getAllCptRequest(query, token) {
  return {
    type: getallCptType.GETALL_CPT_REQUEST,
    query,
    token,
  };
}
function getAllCptSuccess(getAll) {
  return {
    type: getallCptType.GETALL_CPT_SUCCESS,
    getAll,
  };
}
function getAllCptFailure(error) {
  return {
    type: getallCptType.GETALL_CPT_FAILURE,
    error,
  };
}
