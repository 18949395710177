import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { getPCPActions } from "../actions";
import { getPCPTypes } from "../types";

export function* watchDMPCPDetails() {
  yield takeLatest(getPCPTypes.GET_PCP_REQUEST, getPCP);
}

function* getPCP(action) {
  try {
    let data = yield call(Api, {
      method: "POST",
      endpoint: endpoint.getDMPCPDetails,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedData = JSON.parse(data);

    yield put(getPCPActions.pcpRequestSuccess(parsedData[0]));
  } catch (error) {
    yield put(getPCPActions.pcpRequestFailure(error));
  }
}
