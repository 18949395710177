import { diagnosisType } from "../types";

const initialState = {
  loading: false,
};

export const getDiagnosisReducer = (state = initialState, action) => {
  switch (action.type) {
    case diagnosisType.GET_DIAGNOSIS_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case diagnosisType.GET_DIAGNOSIS_REQUEST_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
      };
    case diagnosisType.GET_DIAGNOSIS_REQUEST_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    default:
      return state;
  }
};
