import { deletePatientBillingType } from "../types";

export const deletePatientBillingActions = {
  deleteBillingPatientRequest,
  deleteBillingPatientSuccess,
  deleteBillingPatientFailure,
  deleteBillingPatientReset,
};
function deleteBillingPatientRequest(request, token) {
  return {
    type: deletePatientBillingType.DELETE_PATIENT_BILLING_REQUEST,
    request,
    token,
  };
}
function deleteBillingPatientSuccess(message) {
  return {
    type: deletePatientBillingType.DELETE_PATIENT_BILLING_SUCCESS,
    message,
  };
}
function deleteBillingPatientFailure(error) {
  return {
    type: deletePatientBillingType.DELETE_PATIENT_BILLING_FAILURE,
    error,
  };
}
function deleteBillingPatientReset() {
  return {
    type: deletePatientBillingType.DELETE_PATIENT_BILLING_RESET,
  };
}
