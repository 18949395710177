import moment from "moment";
import React, { useMemo } from "react";
import TimeGrid from "react-big-calendar/lib/TimeGrid";

const WeekView = ({
  date,
  localizer,
  max = localizer.endOf(new Date(), "day"),
  min = localizer.startOf(new Date(), "day"),
  scrollToTime = localizer.startOf(new Date(), "day"),
  ...props
}) => {
  const currRange = useMemo(
    () => WeekView.range(date, { localizer }),
    [date, localizer]
  );

  return (
    <>
      <TimeGrid
        date={date}
        eventOffset={1}
        localizer={localizer}
        max={max}
        min={min}
        range={currRange}
        scrollToTime={scrollToTime}
        {...props}
      />
    </>
  );
};

export default WeekView;
