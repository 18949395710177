import { lookupNationTypes } from "../types/nationLookup.type";

export const lookupNationActions = {
  lookupNationRequest,
  lookupNationRequestSuccess,
  lookupNationRequestFailure,
};

//lookup nation
function lookupNationRequest(token) {
  return {
    type: lookupNationTypes.LOOKUP_NATION_REQUEST,
    token,
  };
}
function lookupNationRequestSuccess(getAll) {
  return {
    type: lookupNationTypes.LOOKUP_NATION_REQUEST_SUCCESS,
    getAll,
  };
}
function lookupNationRequestFailure(error) {
  return {
    type: lookupNationTypes.LOOKUP_NATION_REQUEST_FAILURE,
    error,
  };
}
