import { cronicCareManagerTypes } from "../types/croniccaremanager.type";

export const cronicCareManagerActions = {
  lookupNotesRequest,
  lookupNotesRequestSuccess,
  lookupNotesRequestFailure,
  lookupInventionRequest,
  lookupInventionRequestSuccess,
  lookupInventionRequestFailure,
  saveCCMRequest,
  saveCCMRequestSuccess,
  saveCCMRequestFailure,
  saveCCMRequestReset,
  getallCCMRequest,
  getallCCMRequestSuccess,
  getallCCMRequestFailure,
};

//lookup notes
function lookupNotesRequest(token) {
  return {
    type: cronicCareManagerTypes.LOOKUP_NOTE_REQUEST,
    token,
  };
}
function lookupNotesRequestSuccess(getAll) {
  return {
    type: cronicCareManagerTypes.LOOKUP_NOTE_REQUEST_SUCCESS,
    getAll,
  };
}
function lookupNotesRequestFailure(error) {
  return {
    type: cronicCareManagerTypes.LOOKUP_NOTE_REQUEST_FAILURE,
    error,
  };
}

//lookup invention
function lookupInventionRequest(AdmissionID, carePlanId, token) {
  return {
    type: cronicCareManagerTypes.LOOKUP_INVENTION_REQUEST,
    AdmissionID,
    carePlanId,
    token,
  };
}
function lookupInventionRequestSuccess(getAll) {
  return {
    type: cronicCareManagerTypes.LOOKUP_INVENTION_REQUEST_SUCCESS,
    getAll,
  };
}
function lookupInventionRequestFailure(error) {
  return {
    type: cronicCareManagerTypes.LOOKUP_INVENTION_REQUEST_FAILURE,
    error,
  };
}

//save CCM
function saveCCMRequest(params, request, token) {
  return {
    type: cronicCareManagerTypes.SAVE_CCM_TASK_REQUEST,
    params,
    request,
    token,
  };
}
function saveCCMRequestSuccess(message) {
  return {
    type: cronicCareManagerTypes.SAVE_CCM_TASK_REQUEST_SUCCESS,
    message,
  };
}
function saveCCMRequestFailure(error) {
  return {
    type: cronicCareManagerTypes.SAVE_CCM_TASK_REQUEST_FAILURE,
    error,
  };
}
function saveCCMRequestReset() {
  return {
    type: cronicCareManagerTypes.SAVE_CCM_TASK_REQUEST_RESET,
  };
}

//Getall ccm
function getallCCMRequest(request, token) {
  return {
    type: cronicCareManagerTypes.GETALL_CCM_TASK_REQUEST,
    request,
    token,
  };
}
function getallCCMRequestSuccess(getAll) {
  return {
    type: cronicCareManagerTypes.GETALL_CCM_TASK_REQUEST_SUCCESS,
    getAll,
  };
}
function getallCCMRequestFailure(error) {
  return {
    type: cronicCareManagerTypes.GETALL_CCM_TASK_REQUEST_FAILURE,
    error,
  };
}
