import { pcpGroupTypes } from "../types/pcpgroup.type";

export const pcpGroupActions = {
  pcpgroupRequest,
  pcpgroupRequestSuccess,
  pcpgroupRequestFailure,
  pcpgroupSaveRequest,
  pcpgroupSaveRequestSuccess,
  pcpgroupSaveRequestFailure,
  pcpgroupEditRequest,
  pcpgroupEditRequestSuccess,
  pcpgroupEditRequestFailure,
  pcpgroupUpdateStatusRequest,
  pcpgroupUpdateStatusRequestSuccess,
  pcpgroupUpdateStatusRequestFailure,
  pcpgroupRequestReset,
};

//getAll PCP GROUP
function pcpgroupRequest(query, token) {
  return {
    type: pcpGroupTypes.PCP_GROUP_REQUEST,
    query,
    token,
  };
}

function pcpgroupRequestSuccess(getAll) {
  return {
    type: pcpGroupTypes.PCP_GROUP_REQUEST_SUCCESS,
    getAll,
  };
}

function pcpgroupRequestFailure(error) {
  return {
    type: pcpGroupTypes.PCP_GROUP_REQUEST_FAILURE,
    error,
  };
}

//Save PCP GROUP
function pcpgroupSaveRequest(request, token) {
  return {
    type: pcpGroupTypes.PCP_GROUP_SAVE_REQUEST,
    request,
    token,
  };
}

function pcpgroupSaveRequestSuccess(message) {
  return {
    type: pcpGroupTypes.PCP_GROUP_SAVE_REQUEST_SUCCESS,
    message,
  };
}

function pcpgroupSaveRequestFailure(error) {
  return {
    type: pcpGroupTypes.PCP_GROUP_SAVE_REQUEST_FAILURE,
    error,
  };
}

//update PCP group
function pcpgroupEditRequest(query, payload, token) {
  return {
    type: pcpGroupTypes.PCP_GROUP_EDIT_REQUEST,
    query,
    payload,
    token,
  };
}

function pcpgroupEditRequestSuccess(message) {
  return {
    type: pcpGroupTypes.PCP_GROUP_EDIT_REQUEST_SUCCESS,

    message,
  };
}

function pcpgroupEditRequestFailure(error) {
  return {
    type: pcpGroupTypes.PCP_GROUP_EDIT_REQUEST_FAILURE,
    error,
  };
}

//IsActive PCP GROUP
function pcpgroupUpdateStatusRequest(query, payload, token) {
  return {
    type: pcpGroupTypes.PCP_GROUP_UPDATE_STATUS_REQUEST,
    query,
    payload,
    token,
  };
}

function pcpgroupUpdateStatusRequestSuccess(id, message) {
  return {
    type: pcpGroupTypes.PCP_GROUP_UPDATE_STATUS_REQUEST_SUCCESS,
    id,
    message,
  };
}

function pcpgroupUpdateStatusRequestFailure(error) {
  return {
    type: pcpGroupTypes.PCP_GROUP_UPDATE_STATUS_REQUEST_FAILURE,
    error,
  };
}

//reset
function pcpgroupRequestReset() {
  return {
    type: pcpGroupTypes.PCP_GROUP_REQUEST_RESET,
  };
}
