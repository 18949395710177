import { takeLatest, call, put } from "redux-saga/effects";
import { Api2 } from "../../../../api/Api2";
import { endpoint } from "../../../../api/Url";
import {
  getInitialAssessmentFailure,
  getInitialAssessmentSuccess,
} from "../Reducer/initialassessment.slice";
import {
  updateInitialAssessmentSuccess,
  updateInitialAssessmentFailure,
} from "../Reducer/initialassessment.slice";
import { alertActions } from "../../../../components/AlertNew/store/AlertNew.action";

// Watcher saga
export function* watchNursingInitialAssessment() {
  yield takeLatest(
    "getInitialAssessment/getInitialAssessmentRequest",
    getInitialAssessments
  );
  yield takeLatest(
    "updateInitialAssessment/updateInitialAssessmentRequest",
    updateInitialAssessments
  );
}

// Worker saga for fetching initial assessments
function* getInitialAssessments(action) {
  try {
    const response = yield call(Api2, {
      endpoint: `${endpoint.nursingInitialAssessment}?patientId=${action.payload.patientId}&admissionId=${action.payload.admissionId}`, // Ensure the endpoint is correct
      method: "GET",
      jwt: `Bearer ${action.payload.token}`,
    });
    console.log(response, "responseresponse");
    yield put(getInitialAssessmentSuccess(response));
  } catch (error) {
    yield put(getInitialAssessmentFailure(error));
  }
}

// Worker saga for updating initial assessments
function* updateInitialAssessments(action) {
  console.log(action, "actionaction");
  try {
    const response = yield call(Api2, {
      endpoint: `${endpoint.nursingInitialAssessment}`,
      method: "POST",
      jwt: `Bearer ${action.payload.query.token}`,

      body: action.payload.body,
    });

    yield put(updateInitialAssessmentSuccess(response));
    yield put(alertActions.alertSuccessRequest(response?.message));
  } catch (error) {
    yield put(updateInitialAssessmentFailure(error));
    yield put(alertActions.alertErrorRequest(error.message));
  }
}
