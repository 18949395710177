import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { biometricMeasurementActions } from "../actions";
import { biometricMeasureTypes } from "../types";
import { Api2 } from "../../../../api/Api2";
export function* watchBiometricMeasure() {
  yield takeLatest(biometricMeasureTypes.BIOMETRIC_GET_REQUEST, getBiometric);
  yield takeLatest(
    biometricMeasureTypes.BIOMETRIC_OPERATION_REQUEST,
    operationBiometric
  );
}

function* getBiometric(action) {
  try {
    let data = yield call(Api2, {
      endpoint: `${endpoint.awvGetBioMetricList}?patientId=${action.request.patientId}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });

    yield put(
      biometricMeasurementActions.biometricGetRequestSuccess(data?.biometric)
    );
  } catch (error) {
    yield put(biometricMeasurementActions.biometricGetRequestFailure(error));
  }
}
function* operationBiometric(action) {
  try {
    let message = yield call(Api, {
      endpoint: endpoint.saveBiometricMeasurementsDetails,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedMessage = JSON.parse(message);

    yield put(
      biometricMeasurementActions.biometricOpRequestSuccess(parsedMessage[0])
    );
  } catch (error) {
    yield put(biometricMeasurementActions.biometricOpRequestFailure(error));
  }
}
