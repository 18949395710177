import { mergeDuplicatePatientsTypes } from "../types";

const initialState = {
  loading: false,
  message: "",
  error:""
};

export const mergeDuplicatePatientReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case mergeDuplicatePatientsTypes.MERGE_DUPLICATE_PATIENTS_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case mergeDuplicatePatientsTypes.MERGE_DUPLICATE_PATIENTS_SUCCESS:
      return {
        ...initialState,
        message: action.message,
      };
    case mergeDuplicatePatientsTypes.MERGE_DUPLICATE_PATIENTS_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case mergeDuplicatePatientsTypes.MERGE_DUPLICATE_PATIENTS_RESET:
      return {
        ...initialState
      };
   
    default:
      return state;
  }
};
