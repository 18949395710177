export const pcpGroupTypes = {
  //get PCP GROUP

  PCP_GROUP_REQUEST: "GETALL_DM_PCP_GROUP_REQUEST",
  PCP_GROUP_REQUEST_SUCCESS: "GETALL_DM_PCP_GROUP_REQUEST_SUCCESS",
  PCP_GROUP_REQUEST_FAILURE: "GETALL_DM_PCP_GROUP_REQUEST_FAILURE",

  //SAVE PCP GROUP

  PCP_GROUP_SAVE_REQUEST: "DM_PCP_GROUP_SAVE_REQUEST",
  PCP_GROUP_SAVE_REQUEST_SUCCESS: "DM_PCP_GROUP_SAVE_REQUEST_SUCCESS",
  PCP_GROUP_SAVE_REQUEST_FAILURE: "DM_PCP_GROUP_SAVE_REQUEST_FAILURE",

  //EDIT  PCP GROUP

  PCP_GROUP_EDIT_REQUEST: "DM_PCP_GROUP_REQUEST_EDIT_REQUEST",
  PCP_GROUP_EDIT_REQUEST_SUCCESS: "DM_PCP_GROUP_EDIT_REQUEST_SUCCESS",
  PCP_GROUP_EDIT_REQUEST_FAILURE: "DM_PCP_GROUP_EDIT_REQUEST_FAILURE",

  //UPDATE PCP GROUP

  PCP_GROUP_UPDATE_STATUS_REQUEST: "DM_PCP_GROUP_UPDATE_STATUS_REQUEST",
  PCP_GROUP_UPDATE_STATUS_REQUEST_SUCCESS:
    "DM_PCP_GROUP_UPDATE_STATUS_REQUEST_SUCCESS",
  PCP_GROUP_UPDATE_STATUS_REQUEST_FAILURE:
    "DM_PCP_GROUP_STATUS_REQUEST_FAILURE",

  //RESET

  PCP_GROUP_REQUEST_RESET: "DM_PCP_GROUP_REQUEST_RESET",
};
