import React from "react";
import styles from "./Input.module.scss";
import * as AiIcons from "react-icons/ai";
const Input = (props) => {
  return (
    <div className={styles.container}>
      <input className={styles.input} {...props} required />
      {/* <AiIcons.AiFillEye /> */}
      {props.hasPassword && !props.showPassword && (
        <AiIcons.AiFillEye
          className={styles.eyeClose}
          onClick={props.onEyeClick}
        />
      )}
      {props.hasPassword && props.showPassword && (
        <AiIcons.AiFillEyeInvisible
          className={styles.eyeOpen}
          onClick={props.onEyeClick}
        />
      )}
    </div>
  );
};

export { Input };
