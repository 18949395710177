import { getAllRegionsType } from "../../types";

const initialState = {
  loading: false,
  getAll: [],
};

export const getAllRegionsTCReducer = (state = initialState, action) => {
  switch (action.type) {
    case getAllRegionsType.GETALL_REGION_TC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case getAllRegionsType.GETALL_REGION_TC_SUCCESS:
      return {
        ...state,
        getAll: action.getAll,
        loading: false,
      };
    case getAllRegionsType.GETALL_REGION_TC_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    default:
      return state;
  }
};
