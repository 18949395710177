import { inboxMailboxTypes } from "../types/inboxMailbox.type";

export const inboxMailboxActions = {
  inboxRequest,
  inboxRequestSuccess,
  inboxRequestFailure,
};

function inboxRequest(request, token) {
  return { type: inboxMailboxTypes.INBOX_MAILBOX_REQUEST, request, token };
}
function inboxRequestSuccess(getAll) {
  return { type: inboxMailboxTypes.INBOX_MAILBOX_REQUEST_SUCCESS, getAll };
}
function inboxRequestFailure(error) {
  return { type: inboxMailboxTypes.INBOX_MAILBOX_REQUEST_FAILURE, error };
}
