import { getAllLookupPatientStatusType } from "../types";

const initiaState = {
  loading: false,
  getAll: [],
  error: "",
};
export const getAllLookupPatientStatusTCReducer = (
  state = initiaState,
  action
) => {
  switch (action.type) {
    case getAllLookupPatientStatusType.GETALL_LOOKUP_PATIENT_STATUS_TC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case getAllLookupPatientStatusType.GETALL_LOOKUP_PATIENT_STATUS_TC_SUCCESS:
      return {
        ...initiaState,
        getAll: action.getAll,
      };
    case getAllLookupPatientStatusType.GETALL_LOOKUP_PATIENT_STATUS_TC_FAILURE:
      return {
        ...initiaState,
        error: action.error,
      };
    case getAllLookupPatientStatusType.GETALL_LOOKUP_PATIENT_STATUS_TC_RESET:
      return {
        ...initiaState,
      };
    default:
      return state;
  }
};
