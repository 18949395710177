import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
import { replyMailActions } from "../actions";
import { replyMailTypes } from "../types/replyMail.type";
export function* watchReplyPatientChatMail() {
  yield takeLatest(
    replyMailTypes.PATIENTCHAT_REPLY_MAIL_REQUEST,
    replyPatientChatMail
  );
}

function* replyPatientChatMail(action) {
  try {
    let message = yield call(Api, {
      method: "POST",
      endpoint: endpoint.replyPatientChatMail,
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    let parsedMessage = JSON.parse(message);

    yield put(
      replyMailActions.replyPatientChatMailRequestSuccess(parsedMessage[0])
    );
  } catch (error) {
    yield put(replyMailActions.replyPatientChatMailRequestFailure(error));
  }
}
