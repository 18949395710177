import { createSlice } from "@reduxjs/toolkit";

// Initial state for updating initial assessment
const initialState = {
  loading: false,
  patientDetails: null,
  message: "",
  error: "",
};

// Slice for updating initial assessment
const addHospitalPatientSlice = createSlice({
  name: "addHospitalPatient",
  initialState: initialState,
  reducers: {
    addHospitalPatientRequest: (state) => {
      state.loading = true;
    },
    addHospitalPatientSuccess: (state, action) => {
      state.loading = false;
      state.message = action?.payload?.message;
      state.patientDetails = action?.payload?.patient[0] || null;
      state.error = "";
      console.log(state.message, action, "thisispayload");
    },
    addHospitalPatientFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
      state.message = "";
    },
    addHospitalPatientReset: (state) => {
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        patientDetails: null,
      };
    },
  },
});

export const {
  addHospitalPatientRequest,
  addHospitalPatientSuccess,
  addHospitalPatientFailure,
  addHospitalPatientReset,
} = addHospitalPatientSlice.actions;

export const addHospitalPatientReducer = addHospitalPatientSlice.reducer;
