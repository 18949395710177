import { getAllLookupPatientStatusType } from "../types";

export const getAllLookupPatientStatusTCActions = {
  getAllLookupPatientStatusTCRequest,
  getAllLookupPatientStatusTCSuccess,
  getAllLookupPatientStatusTCFailure,
  getAllLookupPatientStatusTCReset,
};
function getAllLookupPatientStatusTCRequest(query, token) {
  return {
    type: getAllLookupPatientStatusType.GETALL_LOOKUP_PATIENT_STATUS_TC_REQUEST,
    query,
    token,
  };
}
function getAllLookupPatientStatusTCSuccess(getAll, stay) {
  return {
    type: getAllLookupPatientStatusType.GETALL_LOOKUP_PATIENT_STATUS_TC_SUCCESS,
    getAll,
    stay,
  };
}
function getAllLookupPatientStatusTCFailure(error) {
  return {
    type: getAllLookupPatientStatusType.GETALL_LOOKUP_PATIENT_STATUS_TC_FAILURE,
    error,
  };
}
function getAllLookupPatientStatusTCReset() {
  return {
    type: getAllLookupPatientStatusType.GETALL_LOOKUP_PATIENT_STATUS_TC_RESET,
  };
}
