//import dependencies
import { put, takeLatest, call } from "@redux-saga/core/effects";

//api
import { Api2 } from "../../../api/Api2";
import { endpoint } from "../../../api/Url";
import { loginActions } from "../../Login/actions/login.action";
import {
  powerbiActions,
  powerbiDashboardByIdActions,
  powerbiDashboardTabsActions,
} from "../actions/powerbiDashboard.action";
import {
  powerbiDashboardByIdType,
  powerbiDashboardTabsType,
  powerbiDashboardType,
} from "../types/powerbiDashboard.type";

export function* watchPowerBiReport() {
  yield takeLatest(powerbiDashboardType.POWERBI_REQUEST, fetchReportData);
  yield takeLatest(
    powerbiDashboardTabsType.POWERBI_DASHBOARD_TABS_REQUEST,
    fetchReportTabs
  );
  yield takeLatest(
    powerbiDashboardByIdType.POWERBI_REPORT_BY_ID_REQUEST,
    fetchReportById
  );
}

function* fetchReportData(action) {
  try {
    //   api call
    const data = yield call(Api2, {
      // endpoint: `${endpoint.powerbiReport}?RoleId=${action.roleId}`,
      endpoint: `${endpoint.qualityReport}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });

    const parsedData = data;

    if (
      parsedData.report === undefined &&
      parsedData.report.accessToken === undefined
    ) {
      yield put(powerbiActions.powerbiRequestFailure(parsedData.report));
    } else {
      yield put(powerbiActions.powerbiRequestSuccess(parsedData.report));
    }
  } catch (error) {
    yield put(powerbiActions.powerbiRequestFailure(error));

    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
  }
}
function* fetchReportTabs(action) {
  try {
    //   api call
    const data = yield call(Api2, {
      // endpoint: `${endpoint.powerbiReport}?RoleId=${action.roleId}`,
      endpoint: `${endpoint.qualityReportTabs}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });
    console.log(data, "data");
    yield put(
      powerbiDashboardTabsActions.powerbiDashboardTabsSuccess(
        data?.powerbireports
      )
    );
  } catch (error) {
    yield put(powerbiDashboardTabsActions.powerbiDashboardTabsFaliure(error));

    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
  }
}
function* fetchReportById(action) {
  try {
    //   api call
    const data = yield call(Api2, {
      // endpoint: `${endpoint.powerbiReport}?RoleId=${action.roleId}`,
      endpoint: `${endpoint.qualityReportById}?&rowId=${action.request?.rowId}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });
    const parsedData = data;

    if (data?.report?.status === 500) {
      yield put(
        powerbiDashboardByIdActions.powerbiDashboardByIdFailure(
          data.report.error
        )
      );
      yield put;
    } else {
      yield put(
        powerbiDashboardByIdActions.powerbiDashboardByIdSuccess(
          parsedData?.report
        )
      );
    }
  } catch (error) {
    yield put(powerbiDashboardByIdActions.powerbiDashboardByIdFailure(error));

    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
  }
}
