import { put, takeLatest, call, debounce } from "@redux-saga/core/effects";

import { Api2 } from "../../../../api/Api2";
import { endpoint } from "../../../../api/Url";
import {
  getBillingCptModifierActions,
  getProviderCptActions,
  getallBillingActions,
  getallModifierActions,
  updateCptModifierActions,
  updateIcdModifierActions,
  getallIcdActions,
  getBillingIcdActions,
  updatePatientBillingActions,
  updatePatientAssignSeenActions,
  patientBillingSkeletonActions,
  addPatientBillingActions,
  getRvuReportActions,
  deletePatientBillingActions,
  addCptCommentActions,
  patientLookupBillingActions,
  preventDuplicatePatientAddBillingActions,
} from "../actions";
import {
  providerCptType,
  getallBillingType,
  getallModifierType,
  updateBillingCptModifiersType,
  updateBillingIcdModifiersType,
  getBillingCptType,
  getBillingIcdType,
  getallIcdType,
  updatePatientBillingType,
  updatePatientAssignSeenType,
  addPatientBillingType,
  getRvuReportType,
  deletePatientBillingType,
  addCPTCommentType,
  patientLookUpBillingType,
  preventDuplicatePatientAddBillingType,
} from "../types";
import { alertActions } from "../../../../components/AlertNew/store/AlertNew.action";
import { getAllCptActions } from "../actions/getCpt.action";
import { getallCptType } from "../types/getCpt.type";

export function* watchPatientBilling() {
  //lookup
  yield takeLatest(providerCptType.GET_PROVIDER_CPT_REQUEST, getAllProviderCpt);
  yield takeLatest(getallModifierType.GETALL_MODIFIER_REQUEST, getAllModifier);
  //billing
  // yield takeLatest(getallBillingType.GETALL_BILLING_REQUEST, getallBilling);
  yield debounce(500, getallBillingType.GETALL_BILLING_REQUEST, getallBilling);

  yield takeLatest(
    getBillingCptType.GET_BILLING_CPT_REQUEST,
    getBillingCptModofier
  );
  yield takeLatest(getBillingIcdType.GET_BILLING_Icd_REQUEST, getBillingIcd);
  yield debounce(1000, getallIcdType.GETALL_ICD_REQUEST, getallIcd);

  yield takeLatest(
    updateBillingCptModifiersType.UPDATE_CPT_MODIFIERS_REQUEST,
    updateCptModifier
  );

  yield takeLatest(
    updateBillingIcdModifiersType.UPDATE_ICD_MODIFIERS_REQUEST,
    updateBillingIcdModifier
  );
  yield takeLatest(
    updatePatientAssignSeenType.UPDATE_PATIENT_ASSIGN_SEEN_REQUEST,
    updatePatientAssignSeen
  );

  // rvu report
  // yield takeLatest(getRvuReportType.GET_RVU_REPORT_REQUEST, getallRvuReport);
  yield debounce(500, getRvuReportType.GET_RVU_REPORT_REQUEST, getallRvuReport);
  yield debounce(
    500,
    patientLookUpBillingType.PATIENT_LOOKUP_BILLING_REQUEST,
    patientLookUpBilling
  );
  yield debounce(
    500,
    preventDuplicatePatientAddBillingType.PREVENT_DUPLICATE_PATIENT_ADD_BILLING_REQUEST,
    preventDuplicatePatientAddBilling
  );

  //! for mobile only
  yield takeLatest(
    updatePatientBillingType.UPDATE_PATIENT_BILLING_REQUEST,
    updatePatientBilling
  );
  // yield takeLatest(
  //   addPatientBillingType.ADD_PATIENT_BILLING_REQUEST,
  //   addPatientBilling
  // );
  yield debounce(
    700,
    addPatientBillingType.ADD_PATIENT_BILLING_REQUEST,
    addPatientBilling
  );

  yield debounce(500, getallCptType.GETALL_CPT_REQUEST, getallCpt);
  yield debounce(
    500,
    deletePatientBillingType.DELETE_PATIENT_BILLING_REQUEST,
    deleteCptModifier
  );
  yield debounce(
    500,
    addCPTCommentType.ADD_CPT_COMMENT_REQUEST,
    addCptComments
  );
}

// Get lookup Provider cpt
function* getAllProviderCpt(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.getProviderCpt}`,
      jwt: "Bearer " + action.token,
    });
    yield put(getProviderCptActions.getProviderCptRequestSuccess(response));
  } catch (error) {
    yield put(getProviderCptActions.getProviderCptRequestFailure(error));
  }
}

//Getall modifier
function* getAllModifier(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.getBillingModifier}?isCpt=${action.request.isCpt}&isIcd=${action.request.isIcd}`,
      jwt: "Bearer " + action.token,
    });
    yield put(getallModifierActions.getallModifierRequestSuccess(response));
  } catch (error) {
    yield put(getallModifierActions.getallModifierRequestFailure(error));
  }
}

//get all billing
function* getallBilling(action) {
  yield put(
    patientBillingSkeletonActions.patientBillingSkeletonRequest("loading")
  );
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.getallBilling}?dos=${action.request.dos}`,
      jwt: "Bearer " + action.token,
    });

    yield put(
      getallBillingActions.getallBillingRequestSuccess(
        response.billingData,
        response.phyData
      )
    );
  } catch (error) {
    yield put(getallBillingActions.getallBillingRequestFailure(error));
  }
  yield put(patientBillingSkeletonActions.patientBillingSkeletonReset());
}

//update cpt modifier
function* updateCptModifier(action) {
  try {
    let response = yield call(Api2, {
      method: "POST",
      endpoint: `${endpoint.updateCptModifier}`,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    yield put(
      updateCptModifierActions.updateCptModifierSuccess(response.message)
    );
    yield put(alertActions.alertSuccessRequest(response.message));
  } catch (error) {
    yield put(updateCptModifierActions.updateCptModifierFailure(error));
    yield put(alertActions.alertErrorRequest(error.message));
  }
}
//delete cpt modifier
function* deleteCptModifier(action) {
  try {
    let response = yield call(Api2, {
      method: "POST",
      endpoint: `${endpoint.updateCptModifier}`,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    yield put(
      deletePatientBillingActions.deleteBillingPatientSuccess(response.message)
    );
    yield put(alertActions.alertSuccessRequest(response.message));
  } catch (error) {
    yield put(deletePatientBillingActions.deleteBillingPatientFailure(error));
    yield put(alertActions.alertErrorRequest(error.message));
  }
}

//update icd modifier
function* updateBillingIcdModifier(action) {
  try {
    let response = yield call(Api2, {
      method: "POST",
      endpoint: `${endpoint.updateIcdModifier}`,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    yield put(
      updateIcdModifierActions.updateIcdModifierSuccess(response.message)
    );
    yield put(alertActions.alertSuccessRequest(response.message));
  } catch (error) {
    yield put(updateIcdModifierActions.updateIcdModifierFailure(error));
    yield put(alertActions.alertErrorRequest(error.message));
  }
}

//get all billing
function* getBillingCptModofier(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.getBillingCptModifier}?assignmentId=${action.request.assignmentId}`,
      jwt: "Bearer " + action.token,
    });

    yield put(
      getBillingCptModifierActions.getBillingCptRequestSuccess(response)
    );
  } catch (error) {
    yield put(
      getBillingCptModifierActions.getBillingCptRequestFailure(error.message)
    );
  }
}

function* getBillingIcd(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.getBillingIcd}?assignmentId=${action.request.assignmentId}`,
      jwt: "Bearer " + action.token,
    });
    yield put(getBillingIcdActions.getBillingIcdRequestSuccess(response));
  } catch (error) {
    yield put(getBillingIcdActions.getBillingIcdRequestFailure(error));
  }
}
// lookup icd
function* getallIcd(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.getallIcd}?search=${action.request.search}`,
      jwt: "Bearer " + action.token,
    });
    yield put(getallIcdActions.getallIcdSuccess(response));
  } catch (error) {
    yield put(getallIcdActions.getallIcdFailure(error));
  }
}

// Update Patient Billing
function* updatePatientAssignSeen(action) {
  try {
    let response = yield call(Api2, {
      method: "POST",
      endpoint: `${endpoint.updatePatientAssignmentSeen}`,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    yield put(
      updatePatientAssignSeenActions.updatePatientAssignSeenSuccess(
        response.message
      )
    );
    yield put(alertActions.alertSuccessRequest(response.message));
  } catch (error) {
    yield put(
      updatePatientAssignSeenActions.updatePatientAssignSeenFailure(error)
    );
    yield put(alertActions.alertErrorRequest(error.message));
  }
}

// Update Patient Billing
function* updatePatientBilling(action) {
  try {
    let response = yield call(Api2, {
      method: "POST",
      endpoint: `${endpoint.updatePatientBilling}`,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    yield put(
      updatePatientBillingActions.updatePatientBillingSuccess(response.message)
    );
    yield put(alertActions.alertSuccessRequest(response.message));
  } catch (error) {
    yield put(updatePatientBillingActions.updatePatientBillingFailure(error));
    yield put(alertActions.alertErrorRequest(error));
  }
}
// add Patient Billing
function* addPatientBilling(action) {
  try {
    let response = yield call(Api2, {
      method: "POST",
      endpoint: `${endpoint.addPatientBilling}`,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    yield put(
      addPatientBillingActions.addBillingPatientSuccess(response.message)
    );
  } catch (error) {
    if (error?.message === "Failed to fetch") {
      yield put(alertActions.alertErrorRequest("Something went wrong!"));
    } else {
      yield put(alertActions.alertErrorRequest(error?.message));
    }
    yield put(
      addPatientBillingActions.addBillingPatientFailure(error?.message)
    );
  }
}
// get rvu report
function* getallRvuReport(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.getRvuReport}?fromDate=${action.query.fromDate}&toDate=${action.query.toDate}&pageSize=${action.query.pageSize}&pageNumber=${action.query.pageNumber}&all=${action.query.all}`,
      jwt: "Bearer " + action.token,
    });
    yield put(
      getRvuReportActions.getRvuReportSuccess(
        response.data,
        response.totalPages,
        response.totalRows,
        response.totalRVU
      )
    );
  } catch (error) {
    yield put(getRvuReportActions.getRvuReportFailure(error));
  }
}

// getAll cpt lookup
function* getallCpt(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.getallCpt}?search=${action.query.search}`,
      jwt: "Bearer " + action.token,
    });
    yield put(getAllCptActions.getAllCptSuccess(response));
  } catch (error) {
    yield put(getAllCptActions.getAllCptFailure(error));
  }
}

// add cpt comments
function* addCptComments(action) {
  try {
    let response = yield call(Api2, {
      method: "POST",
      endpoint: `${endpoint.addCptComments}`,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    yield put(addCptCommentActions.addBillingPatientSuccess(response));
    yield put(alertActions.alertSuccessRequest(response?.message));
  } catch (error) {
    yield put(addCptCommentActions.addBillingPatientFailure(error));
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}

function* patientLookUpBilling(action) {
  console.log(action);
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.patientSuggestions}?query=${action?.query}`,
      jwt: "Bearer " + action.token,
    });
    yield put(
      patientLookupBillingActions.patientLookupBillingSuccess(response?.data)
    );
  } catch (error) {
    yield put(patientLookupBillingActions.patientLookupBillingFailure(error));
    // yield put(alertActions.alertErrorRequest(error?.message));
  }
}
function* preventDuplicatePatientAddBilling(action) {
  console.log(action);
  try {
    let response = yield call(Api2, {
      method: "POST",
      endpoint: `${endpoint.preventDuplicatePatientAddBilling}`,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    yield put(
      preventDuplicatePatientAddBillingActions.preventDuplicatePatientAddBillingSuccess(
        response?.data && response?.data[0],
        response?.message
      )
    );
  } catch (error) {
    yield put(
      preventDuplicatePatientAddBillingActions.preventDuplicatePatientAddBillingFailure(
        error
      )
    );
    yield put(alertActions.alertErrorRequest(error?.message));
  }
}
