export const insuranceCompanyListLookupCmerType = {
  INSURANCE_COMPANY_LIST_LOOKUP_CMER_REQUEST:
    "INSURANCE_COMPANY_LIST_LOOKUP_CMER_REQUEST",
  INSURANCE_COMPANY_LIST_LOOKUP_CMER_SUCCESS:
    "INSURANCE_COMPANY_LIST_LOOKUP_CMER_SUCCESS",
  INSURANCE_COMPANY_LIST_LOOKUP_CMER_FAILURE:
    "INSURANCE_COMPANY_LIST_LOOKUP_CMER_FAILURE",
};
export const insurancePlanLookupCmerType = {
  INSURANCE_PLAN_LOOKUP_CMER_REQUEST: "INSURANCE_PLAN_LOOKUP_CMER_REQUEST",
  INSURANCE_PLAN_LOOKUP_CMER_SUCCESS: "INSURANCE_PLAN_LOOKUP_CMER_SUCCESS",
  INSURANCE_PLAN_LOOKUP_CMER_FAILURE: "INSURANCE_PLAN_LOOKUP_CMER_FAILURE",
};
