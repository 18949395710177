import { redirectType } from "../types/redirect.type";

const initialState = {
  loading: false,
  contactNo: "",
  error: "",
};

export const redirectReducer = (state = initialState, action) => {
  switch (action.type) {
    case redirectType.REDIRECT_REQUEST:
      return { ...initialState, loading: true };
    case redirectType.REDIRECT_REQUEST_SUCCESS:
      return { ...initialState, contactNo: action.contactNo };
    case redirectType.REDIRECT_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    case redirectType.REDIRECT_REQUEST_RESET:
      return { ...initialState };
    default:
      return state;
  }
};
