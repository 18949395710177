export const familyHistoryTypes = {
  GETALL_FAMILY_HISTORY_REQUEST: "GETALL_FAMILY_HISTORY_REQUEST",
  GETALL_FAMILY_HISTORY_REQUEST_SUCCESS:
    "GETALL_FAMILY_HISTORY_REQUEST_SUCCESS",
  GETALL_FAMILY_HISTORY_REQUEST_FAILURE:
    "GETALL_FAMILY_HISTORY_REQUEST_FAILURE",

  OPERARTION_FAMILY_HISTORY_REQUEST: "OPERARTION_FAMILY_HISTORY_REQUEST",
  OPERARTION_FAMILY_HISTORY_REQUEST_SUCCESS:
    "OPERARTION_FAMILY_HISTORY_REQUEST_SUCCESS",
  OPERARTION_FAMILY_HISTORY_REQUEST_FAILURE:
    "OPERARTION_FAMILY_HISTORY_REQUEST_FAILURE",
  OPERARTION_FAMILY_HISTORY_REQUEST_RESET:
    "OPERARTION_FAMILY_HISTORY_REQUEST_RESET",

  DELETE_FAMILY_REQUEST: "DELETE_FAMILY_REQUEST",
  DELETE_FAMILY_REQUEST_SUCCESS: "DELETE_FAMILY_REQUEST_SUCCESS",
  DELETE_FAMILY_REQUEST_FAILURE: "DELETE_FAMILY_REQUEST_FAILURE",
  DELETE_FAMILY_REQUEST_RESET: "DELETE_FAMILY_REQUEST_RESET",
};
