import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import React from "react";
import { colors } from "../../../../themes/colors";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

import { matchSorter } from "match-sorter";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { Divider } from "@mui/material";
const CustomFilters = ({
  setShowCalender,
  providersData,
  facilityLocationsData,
  regionsData,
  setSearchQuery,
  searchQuery,
  handleSearch,
  setCurrentView,
  handleReset,
}) => {
  const isOusideRange = () => {
    return true;
  };
  return (
    <Grid
      container
      xs={12}
      alignItems={"center"}
      alignContent={"center"}
      justifyContent={"center"}
    >
      <Grid
        item
        container
        alignItems={"center"}
        alignContent={"center"}
        spacing={1}
        xs={12}
      >
        <Grid item container xs={2}>
          <Grid item container alignItems={"baseline"} alignContent={"center"}>
            <DatePicker
              sx={{ width: "100%" }}
              label="From Date"
              // defaultValue={dayjs()}

              disablePast
              maxDate={dayjs().add(13, "day")}
              value={dayjs(searchQuery?.from)}
              // defaultValue={new Date().toISOString().split("T")[0]}
              onChange={(newValue) => {
                setSearchQuery({
                  ...searchQuery,
                  // from: newValue.toISOString(),
                  from: dayjs(newValue.$d).toISOString(),
                });
              }}
              slotProps={{ textField: { size: "small" } }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </Grid>
        </Grid>
        <Grid item container xs={2}>
          <Grid
            item
            // xs={12}
            container
            alignItems={"baseline"}
            alignContent={"center"}
          >
            <DatePicker
              sx={{ width: "100%" }}
              label="To Date"
              value={dayjs(searchQuery?.to)}
              disablePast
              maxDate={dayjs().add(13, "day")}
              onChange={(newValue) => {
                setSearchQuery({
                  ...searchQuery,
                  to: dayjs(newValue.$d).toISOString(),
                });
              }}
              slotProps={{ textField: { size: "small" } }}
            />
          </Grid>
        </Grid>
        <Grid item container xs={2.5}>
          <Grid
            item
            container
            alignItems={"center"}
            alignContent={"center"}
            size="small"
          >
            <Autocomplete
              id="provider"
              size="small"
              value={searchQuery?.pcp || null}
              options={providersData || []}
              // getOptionLabel={(option) => option.FullName}
              // filterOptions={(options, { inputValue }) => {

              //   return matchSorter(options, inputValue, {
              //     keys: ["PcpName"],
              //   });
              // }}
              filterOptions={(options, { inputValue }) => {
                // Use matchSorter to filter the options
                let filtered = matchSorter(options, inputValue, {
                  keys: ["PcpName"],
                });

                // Move "IMC" to the top if it exists in the filtered results
                const imcIndex = filtered.findIndex(
                  (option) => option.PcpName === "IMC"
                );

                if (imcIndex > -1) {
                  const [imcOption] = filtered.splice(imcIndex, 1); // Remove IMC from its current position
                  filtered.unshift(imcOption); // Add it to the top
                }

                return filtered;
              }}
              renderOption={(props, option, { inputValue }) => {
                const matches = match(option.PcpName, inputValue, {
                  insideWords: true,
                });
                const parts = parse(option.PcpName, matches);
                return (
                  // <li {...props}>
                  <>
                    <li
                      {...props}
                      style={
                        option.PcpName === "IMC"
                          ? { fontWeight: 800, backgroundColor: "" }
                          : {}
                      }
                    >
                      <div>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                              fontSize: 14,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                    {/* {option.PcpName === "IMC" && (
                      <Divider
                        style={{
                          backgroundColor: "#000",
                        }}
                      />
                    )} */}
                  </>
                );
              }}
              getOptionLabel={(option) =>
                option?.PcpName?.split(" ")
                  .map(
                    (word) =>
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(" ")
              }
              sx={{ width: "100%" }}
              onChange={(event, newValue) => {
                setSearchQuery({
                  ...searchQuery,
                  // physician: newValue,
                  pcp: newValue,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Provider"
                  // label="PCP"
                  gutterBottom
                  size="small"
                  sx={{
                    input: {
                      alignItems: "center",
                      alignContent: "center",
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>{" "}
        <Grid item container xs={2.5}>
          <Grid
            item
            container
            alignItems={"center"}
            alignContent={"center"}
            size="small"
          >
            <Autocomplete
              id="facilityLocation"
              size="small"
              value={searchQuery?.facility || null}
              options={facilityLocationsData || []}
              getOptionLabel={(option) => option.Facility}
              filterOptions={(options, { inputValue }) => {
                return matchSorter(options, inputValue, {
                  keys: ["Facility"],
                });
              }}
              renderOption={(props, option, { inputValue }) => {
                const matches = match(option.Facility, inputValue, {
                  insideWords: true,
                });
                const parts = parse(option.Facility, matches);
                return (
                  <li {...props}>
                    <div>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                            fontSize: 14,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </div>
                  </li>
                );
              }}
              sx={{ width: "100%" }}
              onChange={(event, newValue) => {
                setSearchQuery({
                  ...searchQuery,
                  facility: newValue,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Facility"
                  gutterBottom
                  size="small"
                  sx={{
                    input: {
                      alignItems: "center",
                      alignContent: "center",
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>{" "}
        {/* <Grid item container xs={2}>
          <Grid
            item
            // xs={12}
            sx={{ width: "100%" }}
            container
            alignItems={"center"}
            alignContent={"center"}
          >
            <Autocomplete
              id="region"
              size="small"
              value={searchQuery?.region || null}
              options={regionsData || []}
              getOptionLabel={(option) => option.region}
              filterOptions={(options, { inputValue }) => {
                return matchSorter(options, inputValue, {
                  keys: ["region"],
                });
              }}
              renderOption={(props, option, { inputValue }) => {
                const matches = match(option.region, inputValue, {
                  insideWords: true,
                });
                const parts = parse(option.region, matches);
                return (
                  <li {...props}>
                    <div>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                            fontSize: 14,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </div>
                  </li>
                );
              }}
              sx={{ width: "100%" }}
              onChange={(event, newValue) => {
                setSearchQuery({
                  ...searchQuery,
                  region: newValue,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Region"
                  gutterBottom
                  size="small"
                  sx={{
                    input: {
                      alignItems: "center",
                      alignContent: "center",
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid> */}
        <Grid item container xs={1.5}>
          <Grid
            item
            // xs={12}
            container
            alignItems={"center"}
            sx={{ width: "100%" }}
            alignContent={"center"}
          >
            <Button
              sx={{
                width: "100%",
                backgroundColor: colors?.color_primary_txt,
                "&:hover": {
                  backgroundColor: colors?.color_primary_txt,
                },
                color: colors?.color_active,
                "& :hover": {
                  color: colors?.color_active,
                },
                textTransform: "none",
              }}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>
        </Grid>{" "}
        <Grid item container xs={1.5}>
          <Grid
            item
            // xs={12}
            container
            alignItems={"center"}
            sx={{ width: "100%" }}
            alignContent={"center"}
          >
            <Button
              sx={{
                width: "100%",
                backgroundColor: colors?.color_active,
                "&:hover": {
                  backgroundColor: colors?.color_active,
                },
                color: colors?.color_primary_txt,
                "& :hover": {
                  color: colors?.color_primary_txt,
                },
                textTransform: "none",
              }}
              onClick={handleReset}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomFilters;
// 5 weeks
