import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { bundleDispositionActions } from "../actions";
import { bundleDisposition } from "../types";
export function* watchBundleDisposition() {
  yield takeLatest(
    bundleDisposition.BUNDLE_DISPOSITION_REQUEST,
    getAllBundleDisposition
  );
}

function* getAllBundleDisposition(action) {
  try {
    const bundleDispositions = yield call(Api, {
      method: "POST",
      endpoint: endpoint.getBundleDisposition,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    const parsedBundleDisposition = JSON.parse(bundleDispositions);

    yield put(
      bundleDispositionActions.bundleDispositionRequestSuccess(
        parsedBundleDisposition.DispositionModel
      )
    );
  } catch (error) {
    yield put(bundleDispositionActions.bundleDispositionRequestFailure(error));
  }
}
