export const cronicCareManagerTypes = {
  //Lookup note
  LOOKUP_NOTE_REQUEST: "LOOKUP_NOTE_REQUEST",
  LOOKUP_NOTE_REQUEST_SUCCESS: "LOOKUP_NOTE_REQUEST_SUCCESS",
  LOOKUP_NOTE_REQUEST_FAILURE: "LOOKUP_NOTE_REQUEST_FAILURE",

  //Lopkup invention
  LOOKUP_INVENTION_REQUEST: "LOOKUP_INVENTION_REQUEST",
  LOOKUP_INVENTION_REQUEST_SUCCESS: "LOOKUP_INVENTION_REQUEST_SUCCESS",
  LOOKUP_INVENTION_REQUEST_FAILURE: "LOOKUP_INVENTION_REQUEST_FAILURE",

  // Save CCM Task
  SAVE_CCM_TASK_REQUEST: "SAVE_CCM_TASK_REQUEST",
  SAVE_CCM_TASK_REQUEST_SUCCESS: "SAVE_CCM_TASK_REQUEST_SUCCESS",
  SAVE_CCM_TASK_REQUEST_FAILURE: "SAVE_CCM_TASK_REQUEST_FAILURE",
  SAVE_CCM_TASK_REQUEST_RESET: "SAVE_CCM_TASK_REQUEST_RESET",

  //Get CCM Task
  GETALL_CCM_TASK_REQUEST: "GETALL_CCM_TASK_REQUEST",
  GETALL_CCM_TASK_REQUEST_SUCCESS: "GETALL_CCM_TASK_REQUEST_SUCCESS",
  GETALL_CCM_TASK_REQUEST_FAILURE: "GETALL_CCM_TASK_REQUEST_FAILURE",
  GETALL_CCM_TASK_REQUEST_RESET: "GETALL_CCM_TASK_REQUEST_RESET",
};
