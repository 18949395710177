import { addUpdateUtilizationCMTypes } from "../types";

export const addUpdateUtilizationActions = {
  addUpdateUtilizationRequest,
  addUpdateUtilizationRequestSuccess,
  addUpdateUtilizationRequestFailure,
  addUpdateUtilizationRequestReset,
};
function addUpdateUtilizationRequest(request, token) {
  return {
    type: addUpdateUtilizationCMTypes.ADDUPDATE_UTILIZATION_CM_REQUEST,
    request,
    token,
  };
}
function addUpdateUtilizationRequestSuccess(message) {
  return {
    type: addUpdateUtilizationCMTypes.ADDUPDATE_UTILIZATION_CM_SUCCESS,
    message,
  };
}
function addUpdateUtilizationRequestFailure(error) {
  return {
    type: addUpdateUtilizationCMTypes.ADDUPDATE_UTILIZATION_CM_FAILURE,
    error,
  };
}
function addUpdateUtilizationRequestReset() {
  return {
    type: addUpdateUtilizationCMTypes.ADDUPDATE_UTILIZATION_CM_RESET,
  };
}
