import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { labTestsActions } from "../actions";
import { labTestsTypes } from "../types";
export function* watchLabTests() {
  yield takeLatest(labTestsTypes.LABTESTS_GET_REQUEST, getLabTests);
  yield takeLatest(labTestsTypes.LABTESTS_OPERATION_REQUEST, operationLabTests);
}

function* getLabTests(action) {
  try {
    let data = yield call(Api, {
      endpoint: `${endpoint.getLabTestsDetails}?patientId=${action.request.patientId}`,
      method: "POST",
      jwt: "Bearer " + action.token,
    });
    let parsedData = JSON.parse(data);

    yield put(labTestsActions.labTestsGetRequestSuccess(parsedData));
  } catch (error) {
    yield put(labTestsActions.labTestsGetRequestFailure(error));
  }
}
function* operationLabTests(action) {
  try {
    let message = yield call(Api, {
      endpoint: endpoint.saveLabTestsDetails,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedMessage = JSON.parse(message);
    console.log("====================================");
    console.log(parsedMessage);
    console.log("====================================");

    yield put(labTestsActions.labTestsOpRequestSuccess(parsedMessage[0]));
  } catch (error) {
    yield put(labTestsActions.labTestsOpRequestFailure(error));
  }
}
