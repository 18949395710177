import { addTaskTypeCm } from "../types/addTask.type";

/*Add task */
const initialState = {
  loading: false,
};

export const addTaskReducerCmReducer = (state = initialState, action) => {
  switch (action.type) {
    case addTaskTypeCm.ADD_TASK_CM_REQUEST:
      return { ...initialState, loading: true };
    case addTaskTypeCm.ADD_TASK_CM_REQUEST_SUCCESS:
      console.log(action, "action");
      return {
        ...initialState,
        message: action.message,
      };
    case addTaskTypeCm.ADD_TASK_CM_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    case addTaskTypeCm.ADD_TASK_CM_REQUEST_RESET:
      return { ...initialState };
    default:
      return state;
  }
};
