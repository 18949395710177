import { getDischargePatientDetailsType } from "../types/getDischargePatientDetails.type";

const initialState = {
  loading: false,
  patientDetails: [],
  medicationDetails: [],
  callHistory: [],
};

export const getDischargePatientDetailsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getDischargePatientDetailsType.GET_DISCHARGE_PATIENT_DETAILS_PHARMA_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case getDischargePatientDetailsType.GET_DISCHARGE_PATIENT_DETAILS_PHARMA_SUCCESS:
      return {
        ...initialState,
        patientDetails: action.patientDetails,
        medicationDetails: action.medicationDetails,
        callHistory: action.callHistory,
        loading: false,
      };
    case getDischargePatientDetailsType.GET_DISCHARGE_PATIENT_DETAILS_PHARMA_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    default:
      return state;
  }
};
