import { patientType } from "../types/patient.type";
export const patientActions = {
  //page request
  patientRequest,
  patientRequestSuccess,
  patientRequestFailure,

  // //type request

  // patientTypeRequest,
  // patientTypeRequestSuccess,
  // patientTypeRequestFailure,

  // // flag request
  // patientFlagRequest,
  // patientFlagRequestSuccess,
  // patientFlagRequestFailure,
};

//For patients by page request
function patientRequest(request, token) {
  return { type: patientType.PATIENT_PAGE_REQUEST, request, token };
}
function patientRequestSuccess(totalCount, patients) {
  return {
    type: patientType.PATIENT_PAGE_REQUEST_SUCCESS,
    totalCount,
    patients,
  };
}
function patientRequestFailure(error) {
  return { type: patientType.PATIENT_PAGE_REQUEST_FAILURE, error };
}

//For patients by type request
function patientTypeRequest(request, token) {
  return { type: patientType.PATIENT_TYPE_REQUEST, request, token };
}
function patientTypeRequestSuccess(totalCount, patients) {
  return {
    type: patientType.PATIENT_TYPE_REQUEST_SUCCESS,
    totalCount,
    patients,
  };
}

// function patientTypeRequestFailure(error) {
//   return { type: patientType.PATIENT_TYPE_REQUEST_FAILURE, error };
// }

// //For patients flag request

// function patientFlagRequest(request, token) {
//   return { type: patientType.PATIENT_FLAG_REQUEST, request, token };
// }
// function patientFlagRequestSuccess(totalCount, patients) {
//   return {
//     type: patientType.PATIENT_FLAG_REQUEST_SUCCESS,
//     totalCount,
//     patients,
//   };
// }
// function patientFlagRequestFailure(error) {
//   return { type: patientType.PATIENT_FLAG_REQUEST_FAILURE, error };
// }
