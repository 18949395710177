import { updatePatientCallStatusTransitionType } from "../types";

const initialState = {
  loading: false,
  message: "",
};

export const updatePatientCallStatusTCReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case updatePatientCallStatusTransitionType.UPDATE_PATIENT_CALL_STATUS_TC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case updatePatientCallStatusTransitionType.UPDATE_PATIENT_CALL_STATUS_TC_SUCCESS:
      return {
        ...state,
        message: action.message,

        loading: false,
      };
    case updatePatientCallStatusTransitionType.UPDATE_PATIENT_CALL_STATUS_TC_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case updatePatientCallStatusTransitionType.UPDATE_PATIENT_CALL_STATUS_TC_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
