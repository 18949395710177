import { messageListTypes } from "../types";

const initialState = {
  loading: false,
};

export const messageListReducer = (state = initialState, action) => {
  switch (action.type) {
    case messageListTypes.MESSAGE_LIST_REQUEST:
      return { ...initialState, loading: true };
    case messageListTypes.MESSAGE_LIST_REQUEST_SUCCESS:
      return { ...initialState, getList: action.getList };
    case messageListTypes.MESSAGE_LIST_REQUEST_FAILURE:
      return { ...initialState, error: action.error };

    default:
      return state;
  }
};
