import { callAttemptedPharmaType } from "../types";

export const callAttemptedPatientDischargePharmaActions = {
  callAttemptedPatientDischargeRequest,
  callAttemptedPatientDischargeSuccess,
  callAttemptedPatientDischargeFailure,
  callAttemptedPatientDischargeReset,
};
function callAttemptedPatientDischargeRequest(request, token) {
  return {
    type: callAttemptedPharmaType.CALL_ATTENMPTED_PHARMA_REQUEST,
    request,
    token,
  };
}
function callAttemptedPatientDischargeSuccess(message) {
  return {
    type: callAttemptedPharmaType.CALL_ATTENMPTED_PHARMA_SUCCESS,
    message,
  };
}
function callAttemptedPatientDischargeFailure(error) {
  return {
    type: callAttemptedPharmaType.CALL_ATTENMPTED_PHARMA_FAILURE,
    error,
  };
}
function callAttemptedPatientDischargeReset() {
  return {
    type: callAttemptedPharmaType.CALL_ATTENMPTED_PHARMA_RESET,
  };
}
