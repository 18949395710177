export const startIdleTimer = (callback) => {
  let timeout = 1000 * 60 * 25; //millisec* sec*min
  let idleTimer;
  function handleUserActivity() {
    clearTimeout(idleTimer);
    idleTimer = setTimeout(() => {
      callback();
    }, timeout);
  }

  window.addEventListener("mousemove", handleUserActivity);
  window.addEventListener("keydown", handleUserActivity);

  handleUserActivity();

  return () => {
    clearTimeout(idleTimer);
    window.removeEventListener("mousemove", handleUserActivity);
    window.removeEventListener("keydown", handleUserActivity);
  };
};
