import { takeLatest, put, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { pcpsDetailsActions } from "../actions";
import { getPcpsDetailsTypes } from "../types";
export function* watchPcpsDetails() {
  yield takeLatest(getPcpsDetailsTypes.GET_PCPS_DETAILS_REQUEST, getAllPcps);
}

function* getAllPcps(action) {
  try {
    let pcps = yield call(Api, {
      method: "POST",
      endpoint: `${endpoint.getPcpsDetails}?SearchStr=${action.request.searchStr}`,
      jwt: "Bearer " + action.token,
    });

    const parsedPcps = JSON.parse(pcps);
    yield put(pcpsDetailsActions.getPcpsDetailsRequestSuccess(parsedPcps));
  } catch (error) {
    yield put(pcpsDetailsActions.getPcpsDetailsRequestFailure(error));
  }
}
