import { call, put, takeLatest } from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
import { patientChatMailboxActions } from "../actions";
import { patientChatMailboxTypes } from "../types/patientChatMailbox.type";

export function* watchPatientChatMailbox() {
  yield takeLatest(
    patientChatMailboxTypes.PATIENT_CHAT_MAILBOX_REQUEST,
    getPatientChatMails
  );
}

function* getPatientChatMails(action) {
  try {
    let inboxMails = yield call(Api, {
      method: "POST",
      endpoint: endpoint.patientChatMailbox,
      jwt: "Bearer " + action.token,
    });
    let parsedInboxMails = JSON.parse(inboxMails);

    yield put(
      patientChatMailboxActions.patientChatRequestSuccess(parsedInboxMails)
    );
  } catch (error) {
    yield put(patientChatMailboxActions.patientChatRequestFailure(error));
  }
}
