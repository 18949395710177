import { icdTypes } from "../types";

const initiaLState = {
    loading: false,
  };
  
export const icdDMReducer = (state = initiaLState, action) => {
    switch (action.type) {
      case icdTypes.ICD_REQUEST:
        return {
          ...initiaLState,
          loading: true,
        };
      case icdTypes.ICD_REQUEST_SUCCESS:
        return {
          ...initiaLState,
          getAll: action.getAll,
        };
      case icdTypes.ICD_REQUEST_FAILURE:
        return {
          ...initiaLState,
          error: action.error,
        };
      default:
        return state;
    }
  };
