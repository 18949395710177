import { hospitalizationTypes } from "../types";

export const hospitalizationActions = {
  //for get
  hospitalizationGetAllRequest,
  hospitalizationGetAllRequestSuccess,
  hospitalizationGetAllRequestFailure,

  //for update delete insert
  hospitalizationOperationRequest,
  hospitalizationOperationRequestSuccess,
  hospitalizationOperationRequestFailure,
  hospitalizationOperationRequestReset,

  //for- delete -
  hospitalizationDeleteRequest,
  hospitalizationDeleteRequestSuccess,
  hospitalizationDeleteRequestFailure,
  hospitalizationDeleteRequestReset,
};
function hospitalizationGetAllRequest(request, token) {
  return {
    type: hospitalizationTypes.GETALL_HOSPITALIZATION_REQUEST,
    request,
    token,
  };
}
function hospitalizationGetAllRequestSuccess(getAll, dropdownSourcelist) {
  return {
    type: hospitalizationTypes.GETALL_HOSPITALIZATION_REQUEST_SUCCESS,
    getAll,
    dropdownSourcelist,
  };
}
function hospitalizationGetAllRequestFailure(error) {
  return {
    type: hospitalizationTypes.GETALL_HOSPITALIZATION_REQUEST_FAILURE,
    error,
  };
}

function hospitalizationOperationRequest(request, token) {
  return {
    type: hospitalizationTypes.OPERARTION_HOSPITALIZATION_REQUEST,
    request,
    token,
  };
}
function hospitalizationOperationRequestSuccess(message) {
  return {
    type: hospitalizationTypes.OPERARTION_HOSPITALIZATION_REQUEST_SUCCESS,
    message,
  };
}
function hospitalizationOperationRequestFailure(error) {
  return {
    type: hospitalizationTypes.OPERARTION_HOSPITALIZATION_REQUEST_FAILURE,
    error,
  };
}
function hospitalizationOperationRequestReset() {
  return {
    type: hospitalizationTypes.OPERARTION_HOSPITALIZATION_REQUEST_RESET,
  };
}

function hospitalizationDeleteRequest(request, token) {
  return {
    type: hospitalizationTypes.DELETE_HOSPITALIZATION_REQUEST,
    request,
    token,
  };
}
function hospitalizationDeleteRequestSuccess(message) {
  return {
    type: hospitalizationTypes.DELETE_HOSPITALIZATION_REQUEST_SUCCESS,
    message,
  };
}
function hospitalizationDeleteRequestFailure(error) {
  return {
    type: hospitalizationTypes.DELETE_HOSPITALIZATION_REQUEST_FAILURE,
    error,
  };
}
function hospitalizationDeleteRequestReset() {
  return {
    type: hospitalizationTypes.DELETE_HOSPITALIZATION_REQUEST_RESET,
  };
}
