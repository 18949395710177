// !Facility look correct ,dispositon ,correct diagonisis correct

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./AddEditUtilization.module.scss";
import { strings } from "./strings";
import { ButtonRow, MultiAutoComplete } from "../../../../../components";
import { isEmpty, isEmptyOrNull } from "../../../../../util";
import { useLocation, useParams } from "react-router-dom";
import {
  addUpdateUtilizationActions,
  facilityLookupCMActions,
} from "../../actions";

import {
  AutoComplete,
  InputField,
} from "../../../../cm/EditPatient/components";
import { diagnosisLookupCmerActions } from "../../../../cmer/ClinicalInformation/actions/diagnosisLookup.action";
import { pcpLookupCmerActions } from "../../../ClinicalInformationCM/actions/pcpLookup.action";
import { callDispositionLookupCmerActions } from "../../../CallHistoryCmRole/actions/callDispositionLookup.action";
export const AddEditUtilization = ({
  isEditing,
  setShow,
  show,
  selectedItem,
}) => {
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.login.userData);
  const addUpdateUtilization = useSelector(
    (state) => state.addUpdateUtilization
  );
  //   const patientDetails = useSelector((state) => state.basicInfo);
  const patientDetails = useLocation().state;
  const pcpsDetails = useSelector((state) => state.getPcpsDetails);
  const pcpLookupCmer = useSelector((state) => state.pcpLookupCmer);
  const facilityLookupCM = useSelector((state) => state.facilityLookupCM);
  const addUpdateUtilizationCM = useSelector(
    (state) => state.addUpdateUtilizationCM
  );

  const diagnosisDetails = useSelector((state) => state.getDiagnosis);
  const [diagnosisValue, setDiagnosisValue] = useState(null);
  const callDisposition = useSelector((state) => state.callDisposition);
  const callDispositionLookupCmer = useSelector(
    (state) => state.callDispositionLookupCmer
  );
  const diagnosisLookupCmer = useSelector((state) => state.diagnosisLookupCmer);
  const [facility, setFacility] = useState("");
  const [dOA, setDOA] = useState("");
  const [dOD, setDOD] = useState("");
  const [mrn, setMRN] = useState("");
  const [diagnosis, setDiagnosis] = useState("");
  const [pcp, setPCP] = useState(null);
  const [dispositionId, setDispositionId] = useState({
    DispositionName: "Select",
    DispositionID: "SgBtADIAZABvAGsARgBIADQAKwBNAD0A",
  });
  const [dispositionList, setDispositionList] = useState("");
  const [errorDisposition, setErrorDisposition] = useState(false);
  const [errorFacility, setErrorFacility] = useState(false);
  const [errorPCP, setErrorPCP] = useState(false);
  const [errorDOA, setErrorDOA] = useState(false);
  const [errorDOD, setErrorDOD] = useState(false);
  const [errorMrn, setErrorMrn] = useState(false);
  const [errorDiagnosis, setErrorDiagnosis] = useState(false);
  useEffect(() => {
    if (isEditing && selectedItem !== undefined && selectedItem !== "") {
      setMRN(selectedItem.MRN);

      setDOD(
        selectedItem.DCDate === ""
          ? ""
          : new Date(selectedItem.DCDate).toLocaleDateString("en-CA")
      );
      setDOA(
        selectedItem.AdmissionDate === ""
          ? ""
          : new Date(selectedItem.AdmissionDate).toLocaleDateString("en-CA")
      );

      setFacility({
        FacilityName: selectedItem.Facility,
        FacilityID: selectedItem.FacilityID,
      });

      selectedItem?.PcpID &&
        setPCP({ PCP: selectedItem?.PCPName, PCPID: selectedItem?.PcpID });

      let diagnosisId =
        selectedItem?.Diagnosis?.length > 1 &&
        selectedItem?.Diagnosis?.split(";");
      let diagnosValue = selectedItem?.DiagnosisDetail?.split(";");

      // diagnosisId &&
      //   setDiagnosisValue(
      //     diagnosisId?.map((value, index) => {
      //       return {
      //         ICDCodeId: value,
      //         CodeDescription: diagnosValue[index],
      //       };
      //     })
      //   );

      if (diagnosisId && diagnosisId.length > 0) {
        setDiagnosisValue(
          diagnosisId.map((value, index) => {
            return {
              ICDCodeId: value,
              CodeDescription: diagnosValue[index],
            };
          })
        );
      } else {
        setDiagnosisValue(null);
      }
      // setDiagnosisValue(
      //   selectedItem.DiagnosisDetail?.split(",").map((val, index) => {
      //     return {
      //       ICDCodeId: temp[index],

      //       CodeDescription: val,
      //     };
      //   })
      // );
      // setDiagnosisValue([
      //   {
      //     ICDCodeId: 38455,
      //     Code: "H26.041",
      //     CodeDescription:
      //       "H26.041: Anterior subcapsular polar infantile and juvenile cataract, right eye",
      //   },
      //   {
      //     ICDCodeId: 38456,
      //     Code: "H26.042",
      //     CodeDescription:
      //       "H26.042: Anterior subcapsular polar infantile and juvenile cataract, left eye",
      //   },
      // ]);
      // selectedDiagnosis();
      // let tmep = ids?.split(",").map((val, index) => {
      //   return {
      //     ICDCodeId: val,
      //     CodeDescription: val[index],
      //   };
      // });
      // selectedDiagnosis(tmep);
      // selectedDiagnosis(selectedItem.DiagnosisDetail);
      // selectedDiagnosis([
      //   {
      //     ICDCodeId: 38455,
      //     Code: "H26.041",
      //     CodeDescription:
      //       "H26.041: Anterior subcapsular polar infantile and juvenile cataract, right eye",
      //   },
      //   {
      //     ICDCodeId: 38456,
      //     Code: "H26.042",
      //     CodeDescription:
      //       "H26.042: Anterior subcapsular polar infantile and juvenile cataract, left eye",
      //   },
      // ]);
      setDispositionId({
        // DispositionName:
        DispositionDesc:
          selectedItem.Disposition === null
            ? "Select"
            : selectedItem.Disposition,
        DispositionID: selectedItem.DispositionID,
      });
    }
  }, [selectedItem]);

  /**
   *
   * @param { setDOD(new Date(selectedItem.DCDate).toLocaleDateString("en-CA")));
      setDOA(new Date(selectedItem.AdmissionDate).toLocaleDateString("en-CA")));} val
   */
  useEffect(() => {
    if (userData.token !== undefined) {
      let request = {};

      dispatch(
        callDispositionLookupCmerActions.callDispositionLookupCmerRequest(
          request,
          userData.token
        )
      );
    }
    // eslint-disable-next-line
  }, [userData.token]);

  const getPcp = (val) => {
    if (val.length > 2) {
      if (userData.token !== undefined) {
        let request = {
          search: val,
        };

        dispatch(
          pcpLookupCmerActions.pcpLookupRequest(request, userData.token)
        );
      }
    }
  };
  const getFacility = (val) => {
    if (val.length > 2) {
      if (userData.token !== undefined) {
        let request = {
          SearchStr: val,
        };
        dispatch(
          facilityLookupCMActions.facilityLookupCMRequest(
            request,
            userData.token
          )
        );
      }
    }
  };
  const getDiagnosis = (val) => {
    if (val.length > 2) {
      if (userData.token !== undefined) {
        let request = {
          searchStr: val,
        };

        dispatch(
          diagnosisLookupCmerActions.diagnosisLookupRequest(
            request,
            userData.token
          )
        );
        // dispatch(
        //   getDiagnosisActions.getDiagnosisRequest(request, userData.token)
        // );
      }
    }
  };

  //multi diagnosis ids selected
  const diagnosisMap = new Map();
  // const selectedDiagnosis = (selectedDiagnosis) => {
  //   // for (let i in selectedDiagnosis) {
  //   //   diagnosisMap.set(
  //   //     selectedDiagnosis[i].ICDCodeId,
  //   //     selectedDiagnosis[i].Descriptions
  //   //     // selectedDiagnosis[i].ICDCodeId,
  //   //     // selectedDiagnosis[i].Descriptions
  //   //   );
  //   // }
  //   // let tempDiagnosis = "";
  //   // for (const [key] of diagnosisMap.entries()) {
  //   //   tempDiagnosis = key + ";" + tempDiagnosis;
  //   // }
  //   // setDiagnosis(tempDiagnosis);
  // };

  const saveUpdateUtilization = () => {
    if (!validate()) {
      if (
        userData.token !== undefined &&
        patientDetails.patientDetails1.PatientId !== undefined &&
        patientDetails.patientDetails1.PatientId !== ""
      ) {
        let request;

        if (isEditing) {
          const result = diagnosisValue
            ?.map((item) => item?.ICDCodeId) // Extract ICDCodeId from each item
            .filter((id) => id != null) // Filter out any undefined or null values
            .join(","); // Join the IDs with commas

          request = {
            // PatientID: selectedItem.PatientID,
            // AdmissionID: selectedItem.parentAdmissionId,
            PatientID: patientDetails.patientDetails1.PatientId,
            AdmissionID: patientDetails.patientDetails1.parentAdmissionId,
            AdmissionDate: dOA,
            DcDate: dOD,
            // FacilityID: facility.FacilityID,
            FacilityID: facility.FacilityID,
            Pcpid: pcp?.PCPID,
            Mrn: mrn,
            Flag: "Update",
            // Diagnosis: removeSemi(diagnosis),
            Diagnosis: diagnosisValue
              ?.map((item) => item?.ICDCodeId) // Extract ICDCodeId from each item
              .filter((id) => id != null) // Filter out any undefined or null values
              .join(","),
            DispositionID: dispositionId.DispositionID,
            userID: userData?.UserID,
          };
        } else {
          request = {
            PatientID: patientDetails.patientDetails1.PatientId,
            // AdmissionID: selectedItem.parentAdmissionId,
            AdmissionID: patientDetails.patientDetails1.parentAdmissionId,
            AdmissionDate: dOA,
            DcDate: dOD,
            FacilityID: facility.FacilityID,
            // FacilityID: facility.AuthFacilityID,
            Pcpid: pcp?.PCPID,
            Mrn: mrn,
            Flag: "Insert",
            // Diagnosis: removeSemi(diagnosis),
            Diagnosis: diagnosisValue
              ?.map((item) => item?.ICDCodeId) // Extract ICDCodeId from each item
              .filter((id) => id != null) // Filter out any undefined or null values
              .join(","),

            DispositionID: dispositionId.DispositionID,
            userID: userData?.UserID,
          };
        }
        dispatch(
          addUpdateUtilizationActions.addUpdateUtilizationRequest(
            request,
            userData.token
          )
        );
      }
    }
  };

  const validate = () => {
    let cancel = false;
    if (isEmpty(facility)) {
      setErrorFacility(true);
      cancel = true;
    } else {
      setErrorFacility(false);
    }

    if (isEmptyOrNull(pcp)) {
      setErrorPCP(true);
      cancel = true;
    } else {
      setErrorPCP(false);
    }
    if (isEmpty(dOA)) {
      setErrorDOA(true);
      cancel = true;
    } else {
      setErrorDOA(false);
    }
    if (isEmpty(dOD)) {
      setErrorDOD(true);
      cancel = true;
    } else {
      setErrorDOD(false);
    }
    if (
      isEmpty(
        diagnosisValue
          ?.map((item) => item?.ICDCodeId) // Extract ICDCodeId from each item
          .filter((id) => id != null) // Filter out any undefined or null values
          .join(",")
      )
    ) {
      setErrorDiagnosis(true);
      cancel = true;
    } else {
      setErrorDiagnosis(false);
    }
    if (isEmpty(mrn)) {
      setErrorMrn(true);
      cancel = true;
    } else {
      setErrorMrn(false);
    }

    return cancel;
  };
  const removeSemi = (str) => {
    if (str != null && str.length > 0 && str.charAt(str.length - 1) === ",") {
      str = str.substring(0, str.length - 1);
    }
    return str;
  };

  useEffect(() => {
    if (addUpdateUtilization.message.Result !== undefined) {
      if (addUpdateUtilization.message.Result === "Success") {
        if (
          patientDetails.details.PatientID !== undefined &&
          patientDetails.details.PatientID !== ""
        ) {
          const request = {
            patientID: patientDetails.details.PatientID,
            page: 1,
          };

          if (typeof userData.token !== "undefined")
            // dispatch(
            //   visitDetailsActions.visitDetailsRequest(request, userData.token)
            // );

            setTimeout(() => {
              setShow(false);
              setFacility("");
              setPCP(null);
              setDOA("");
              setDOD("");
              setDiagnosis(null);
              setMRN("");
              dispatch(
                addUpdateUtilizationActions.addUpdateUtilizationRequestReset()
              );
            }, 1000);
        }
      }
    }
  }, [addUpdateUtilization.message, show, addUpdateUtilization.error]);

  useEffect(() => {
    setDispositionList([
      {
        DispositionName: "Select",
        DispositionID: 0,
      },
      ...callDisposition.getAll,
    ]);
  }, [callDisposition.getAll]);

  return (
    <>
      <div className={styles.add_utilization}>
        <div className={styles.main}>
          <div className={styles.col1}>
            <>
              <AutoComplete
                onInputChange={getFacility}
                value={facility}
                onChange={(val) => setFacility(val)}
                getOptionLabel={(option) => option.FacilityName}
                // getOptionLabel={(option) => option.FacilityName}
                getOptionValue={(option) => option.FacilityID}
                // getOptionValue={(option) => option.FacilityID}
                // isLoading={facilityDetails.loading}
                isLoading={facilityLookupCM.loading}
                // options={facilityDetails.getAll}
                // options={FacilityData}
                options={facilityLookupCM?.getAll}
                label={strings.FACILITY}
              />
              {errorFacility && <p className={styles.error}>{"Required*"}</p>}
            </>
            <>
              <InputField
                type="date"
                label={strings.DOA}
                value={dOA}
                onChange={(evt) => setDOA(evt.target.value)}
                max="9999-12-31"
              />
              {errorDOA && <p className={styles.error}>{"Required*"}</p>}
            </>

            <>
              <AutoComplete
                error={errorDisposition}
                isLoading={callDisposition.loading}
                label="Disposition"
                // options={dispositionList}
                options={callDispositionLookupCmer?.getAll}
                value={dispositionId}
                getOptionLabel={(option) => option.DispositionDesc}
                // getOptionLabel={(option) => option.DispositionName}
                getOptionValue={(option) => option.DispositionID}
                onChange={(selected) => setDispositionId(selected)}
              />
            </>
            <>
              <InputField
                label={strings.MRN}
                value={mrn}
                onChange={(evt) => setMRN(evt.target.value)}
              />
              {errorMrn && <p className={styles.error}>{"Required*"}</p>}
            </>
          </div>

          <div className={styles.col1}>
            <>
              <AutoComplete
                isLoading={pcpsDetails.loading}
                onInputChange={getPcp}
                getOptionLabel={(option) => option?.PCP}
                getOptionValue={(option) => option?.PCPID}
                value={pcp}
                onChange={(val) => setPCP(val)}
                // options={pcpsDetails.getAll}
                options={pcpLookupCmer?.getAll}
                label={strings.PCP}
              />
              {errorPCP && <p className={styles.error}>{"Required*"}</p>}
            </>

            <>
              <InputField
                label="DOD"
                type="date"
                value={dOD}
                max="9999-12-31"
                min={dOA}
                onChange={(evt) => setDOD(evt.target.value)}
              />
              {errorDOD && <p className={styles.error}>{"Required*"}</p>}
            </>
            <>
              <MultiAutoComplete
                onInputChange={getDiagnosis}
                isMulti
                label={strings.DIAGNOSIS}
                // options={diagnosisDetails.getAll}
                options={diagnosisLookupCmer.getAll}
                value={diagnosisValue}
                // value={"12"?.split(",").map((val, index) => {
                //   return {
                //     ICDCodeId: val,
                //     CodeDescription: "test",
                //   };
                // })}
                getOptionLabel={(option) => option.CodeDescription}
                // getOptionLabel={(option) => option.Descriptions}
                getOptionValue={(option) => option.ICDCodeId}
                onChange={(selected) => {
                  // selectedDiagnosis(selected);
                  setDiagnosisValue(selected);
                }}
                isLoading={diagnosisLookupCmer?.loading}
              />
              {errorDiagnosis && <p className={styles.error}>{"Required*"}</p>}
            </>
            <></>
          </div>
        </div>

        <ButtonRow
          isEditing={isEditing}
          loading={addUpdateUtilizationCM.loading}
          onSubmit={saveUpdateUtilization}
          onCancel={() => {
            setShow(false);
          }}
        />
        {/* {addUpdateUtilizationCM.message.Result !== undefined && (
          <Alert
            isSuccess={addUpdateUtilization.message.Result === "Success"}
            successMessage={
              isEditing ? "Successfully updated!" : "Successfully added!"
            }
            failureMessage={
              addUpdateUtilization.message.Result === "Error"
                ? isEditing
                  ? "Failed to update!"
                  : "Failed to add!"
                : addUpdateUtilization.message.Result
            }
          />
        )} */}
      </div>
    </>
  );
};
