import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { insurancePlanOperationActions } from "../actions";
import { insurancePlanOperationTypes } from "../types";

export function* watchDMInsurancePlanOperation() {
  yield takeLatest(
    insurancePlanOperationTypes.INSURANCE_PLAN_OPERATION_REQUEST,
    insurancePlanOperation
  );
}

function* insurancePlanOperation(action) {
  try {
    let data = yield call(Api, {
      method: "POST",
      endpoint: endpoint.operationDMInsurancePlan,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedData = JSON.parse(data);

    yield put(
      insurancePlanOperationActions.insurancePlanOperationRequestSuccess(
        parsedData[0]
      )
    );
  } catch (error) {
    yield put(
      insurancePlanOperationActions.insurancePlanOperationRequestFailure(error)
    );
  }
}
