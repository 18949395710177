import React, { useState, useEffect } from "react";
import { PatientDetailsRoutes } from "../../../routes/PatientDetailsRoutes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MessageForm } from "../../cm/EditPatient/components";
import { PatientInfo } from "../components/PatientInfo";
import { Menu } from "../../maw/components/Menu";
import { pageActions, permissionActions } from "../../../store/actions";
import { basicInfoActions } from "../../cm/EditPatient/BasicInfo/actions";

export const PatientNav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { patientId } = useParams();
  const { patientDetails1 } = useLocation().state;

  console.log("🚀 ~ PatientNav ~ patientId:", patientId);
  console.log("🚀 ~ PatientNav ~ patientDetails1:", patientDetails1);
  const userData = useSelector((state) => state.login.userData);
  const [selectedMenu, setMenu] = useState(null);
  const patientDetails = useSelector((state) => state.basicInfo);

  console.log("🚀 ~ PatientNav ~ patientDetails:", patientDetails);
  const permission = useSelector((state) => state.permission);
  console.log("permission:", permission);

  useEffect(() => {
    if (userData.token !== undefined)
      dispatch(permissionActions.permissionGetAllRequest(userData.token));
  }, []);

  // useEffect(() => {
  //   const request = {
  //     AdmissionID: patientId,
  //   };

  //   if (typeof userData.token !== "undefined")
  //     dispatch(basicInfoActions.basicInfoRequest(request, userData.token));
  //   // eslint-disable-next-line
  // }, [patientId]);

  useEffect(() => {
    if (selectedMenu !== null)
      dispatch(pageActions.pageRequest(selectedMenu.menu));
    // eslint-disable-next-line
  }, [selectedMenu]);
  const [showChatForm, setShowChatForm] = useState(false);
  //set default menu
  useEffect(() => {
    if (permission.getAll.length > 0) {
      //get the first sequence 1 menu
      const menu = permission.getAll
        .filter((itm) => itm.menuType === "dropdown")
        .sort((itm1, itm2) => itm1.sequenceNo - itm2.sequenceNo)[0];

      getComponent(menu);
    }
  }, [permission.getAll]);

  const getComponent = (menu) => {
    const component = PatientDetailsRoutes.filter(
      (item) => item.menuCode === menu.menuCode
    );

    dispatch(permissionActions.permissionSetParent(menu.id));

    const obj = { ...menu, component: component[0].element };
    setMenu(obj);
  };
  useEffect(() => {
    if (permission.parentMenuId === 11) {
      getComponent(
        permission.getAll.filter(
          (itm) =>
            itm.menuType === "dropdown" && itm.id === permission.parentMenuId
        )[0]
      );
    }
  }, [permission.parentMenuId]);
  useEffect(() => {
    if (patientDetails1.LastName == undefined) {
      navigate("/cmer");
    }
  }, [patientDetails1]);

  return (
    <>
      {patientDetails1.LastName !== undefined && userData !== undefined && (
        <>
          <main>
            {/* {userData.RoleID === 2 && (
              <MessageForm
                pcpId={patientDetails1.details.PCPID}
                patientId={patientDetails.details.PatientID}
                patientName={patientDetails.details.PatientName}
                show={showChatForm}
                getShow={(val) => setShowChatForm(val)}
              />
            )} */}
            <PatientInfo
              pName={`${patientDetails1.LastName},${patientDetails1.FirstName}`}
              pDob={patientDetails1.DOB}
              onChat={() => setShowChatForm(!showChatForm)}
              hideChat={true}
              //hideVC={userData.RoleID !== 2}
              menuOptions={permission.getAll
                .filter((item) => item.menuType === "dropdown")
                .sort((itm1, itm2) => itm1.sequenceNo - itm2.sequenceNo)}
              onChange={(val) => getComponent(val)}
              value={selectedMenu}
              getOptionLabel={(itm) => itm.menu}
              getOptionValue={(itm) => itm.id}
              isLoading={permission.loading}
            />
          </main>

          <main>
            {selectedMenu !== null && selectedMenu.component !== null && (
              <div>{selectedMenu.component}</div>
            )}
          </main>
        </>
      )}
      {/* {patientDetails.details !== undefined && userData !== undefined && (
        <>
          <main>
            {userData.RoleID === 2 && (
              <MessageForm
                pcpId={patientDetails.details.PCPID}
                patientId={patientDetails.details.PatientID}
                patientName={patientDetails.details.PatientName}
                show={showChatForm}
                getShow={(val) => setShowChatForm(val)}
              />
            )}
            <PatientInfo
              pName={patientDetails.details.PatientName || ""}
              pDob={patientDetails.details.DOB || ""}
              onChat={() => setShowChatForm(!showChatForm)}
              hideChat={userData.RoleID !== 2}
              //hideVC={userData.RoleID !== 2}
              menuOptions={permission.getAll
                .filter((item) => item.menuType === "dropdown")
                .sort((itm1, itm2) => itm1.sequenceNo - itm2.sequenceNo)}
              onChange={(val) => getComponent(val)}
              value={selectedMenu}
              getOptionLabel={(itm) => itm.menu}
              getOptionValue={(itm) => itm.id}
              isLoading={permission.loading}
            />
          </main>

          <main>
          
            {selectedMenu !== null && selectedMenu.component !== null && (
              <div>{selectedMenu.component}</div>
            )}
          </main>
        </>
      )} */}
    </>
  );
};
