import { addUpdateUtilizationTypes } from "../types";

const initialState = {
  loading: false,
  message: {},
};

export const addUpdateUtilizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case addUpdateUtilizationTypes.ADDUPDATE_UTILIZATION_REQUEST:
      return {
        ...initialState,
        loading: true,
      };

    case addUpdateUtilizationTypes.ADDUPDATE_UTILIZATION_REQUEST_SUCCESS:
      return {
        ...initialState,
        message: action.message,
      };
    case addUpdateUtilizationTypes.ADDUPDATE_UTILIZATION_REQUEST_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case addUpdateUtilizationTypes.ADDUPDATE_UTILIZATION_REQUEST_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
