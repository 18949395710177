import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { colors } from "../../../themes/colors";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import DoNotDisturbOnTotalSilenceIcon from "@mui/icons-material/DoNotDisturbOnTotalSilence";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../../core/Spinner";
import {
  getActivePatientAppointmentsTCActions,
  getActivePatientMedicationActions,
} from "../actions";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { alertActions } from "../../../components/AlertNew/store/AlertNew.action";

const PatientCallStatus = ({
  patientDetails,
  patientDetailsUpdate,
  setPatientDetailsUpdate,
  handleSubmit,
  loadingFlag,
  onChangeDc,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const copyToClipboard = (text, e) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        dispatch(alertActions.alertSuccessRequest("AthenaId Copied"));
      })
      .catch((err) => {});
  };
  const patientCallStatus = useSelector(
    (state) => state.updatePatientCallStatus
  );
  const getActivePatientTC = useSelector((state) => state.getActivePatientTC);

  const [isNoteUpdated, setIsNoteUpdated] = useState(false);
  // const hasName = getActivePatientTC?.stay?.find(
  //   (val) => val?.name === "Discharge"
  // )?.name; // Define hasName variable

  useEffect(() => {
    if (patientCallStatus.message === "Notes submitted successfully!") {
      setIsNoteUpdated(false);
    }
  }, [patientCallStatus]);

  return (
    <Box sx={{ pl: 3 }}>
      <Grid item pb={2}>
        {/* <Link> */}
        {/* Back */}
        <KeyboardBackspaceIcon
          onClick={() => {
            navigate(-1);
            dispatch(
              getActivePatientMedicationActions.getActivePatientMedicationReset()
            );
            dispatch(
              getActivePatientAppointmentsTCActions.getActivePatientAppointmentsReset()
            );
          }}
          sx={{
            // color: colors?.color_primary_txt,
            "&:hover": {
              cursor: "pointer",
            },
            color: colors?.color_primary_txt,
            textTransform: "capitalize",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            justifySelf: "start",
            width: "min-content",
            textDecoration: "underline",
            paddingLeft: 0,
            margin: 0,
          }}
        />
        {/* </Link> */}
        {/* <Button
              sx={{
                color: colors?.color_primary_txt,
                "&:hover": {
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                },

                "&:active": {
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                },
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                justifySelf: "start",
                width: "min-content",
                textDecoration: "underline",
                paddingLeft: 1,
                margin: 0,
              }}
            >
              {/* <KeyboardBackspaceIcon /> */}
        {/* <Link> */}
        {/* Back */}
        {/* </Link> */}
        {/* </Button> */}
      </Grid>
      <Grid
        container
        sx={{
          p: 1,
          backgroundColor: colors?.color_background,
          borderRadius: 2,
        }}
      >
        <Grid item container spacing={0}>
          <Grid item container xs={6} lg={6} xl={8} sx={{ p: 1 }}>
            <Grid item xs={6}>
              <Typography
                component="span"
                sx={{ fontWeight: "bold", fontSize: 14 }}
              >
                Athena PatientID
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <Tooltip title="Click to copy AthenId">
                <Typography
                  variant="subtitle1"
                  component="span"
                  sx={{ fontSize: 14, userSelect: "text", fontWeight: "bold" }}
                  onClick={(event) =>
                    copyToClipboard(patientDetails?.ATHENAPatientId, event)
                  }
                >
                  {`#${patientDetails?.ATHENAPatientId}`}
                </Typography>{" "}
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Typography
                component="span"
                sx={{ fontWeight: "bold", fontSize: 14 }}
              >
                Patient Name
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                component="span"
                sx={{ fontSize: 14, userSelect: "text" }}
              >
                {`${
                  patientDetails?.LastName?.charAt(0).toUpperCase() +
                  patientDetails?.LastName?.slice(1).toLowerCase()
                }, ${
                  patientDetails?.FirstName?.charAt(0).toUpperCase() +
                  patientDetails?.FirstName?.slice(1).toLowerCase()
                }`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                component="span"
                sx={{ fontWeight: "bold", fontSize: 14 }}
              >
                DOB
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                component="span"
                sx={{ fontSize: 14, userSelect: "text" }}
              >
                {`${patientDetails?.DOB}`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                component="span"
                sx={{ fontWeight: "bold", fontSize: 14 }}
              >
                Facility
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                component="span"
                sx={{ fontSize: 14, userSelect: "text" }}
              >
                {patientDetails?.Facility}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                component="span"
                sx={{ fontWeight: "bold", fontSize: 14 }}
              >
                PCP
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                component="span"
                sx={{ fontSize: 14, userSelect: "text" }}
              >
                {patientDetails?.PCP ? patientDetails?.PCP : "--"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                component="span"
                sx={{ fontWeight: "bold", fontSize: 14 }}
              >
                Admit Date
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                component="span"
                sx={{ fontSize: 14, userSelect: "text" }}
              >
                {patientDetails?.AdmitDateNew}
              </Typography>
            </Grid>
            {
              <Grid item xs={6}>
                <Typography
                  component="span"
                  sx={{ fontWeight: "bold", fontSize: 14, userSelect: "text" }}
                >
                  D/C Date
                </Typography>
              </Grid>
            }
            {
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs} locale="">
                  <DatePicker
                    sx={{ width: "70%", m: 0, p: 0 }}
                    minDate={dayjs(patientDetails?.AdmitDate)}
                    fullWidth
                    defaultValue={
                      patientDetails?.DCDateNew
                        ? dayjs(patientDetails?.DCDateNew?.split("T")[0])
                        : null
                    }
                    onChange={(e) => {
                      onChangeDc(e);
                    }}
                    // slotProps={{ textField: { size: "small" } }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
            }
            <Grid item xs={6}>
              <Typography
                component="span"
                sx={{ fontWeight: "bold", fontSize: 14 }}
              >
                ACO
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                component="span"
                sx={{ fontSize: 14, userSelect: "text" }}
              >
                {patientDetails?.ValueBase || "--"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={6}
            lg={6}
            xl={4}
            sx={{ width: "fit-content" }}
            justifyContent={"flex-end"}
            justifyItems={"flex-end"}
            justifySelf={"end"}
          >
            <Grid item container spacing={1}>
              <Grid item xs={4} alignItems={"center"} alignContent={"center"}>
                <Button
                  id="Call_Completed"
                  sx={{
                    backgroundColor: "#00B677",
                    color: colors.color_background,
                    textTransform: "capitalize",
                    ":hover": {
                      backgroundColor: "#00B677",
                      color: colors.color_background,
                    },
                  }}
                  startIcon={
                    patientCallStatus.loading && loadingFlag?.Call_Completed ? (
                      <CircularProgress
                        color="inherit"
                        size={15}
                        thickness={6}
                      />
                    ) : (
                      <PermPhoneMsgIcon />
                    )
                  }
                  fullWidth
                  size="small"
                  value="Call_Completed"
                  onClick={() => handleSubmit("Call_Completed")}
                >
                  Call Completed
                </Button>
              </Grid>
              <Grid item xs={4} alignItems={"center"} alignContent={"center"}>
                <Button
                  id="Do_not_Call"
                  sx={{
                    backgroundColor: "red",
                    color: colors.color_background,
                    textTransform: "capitalize",

                    ":hover": {
                      backgroundColor: "red",
                      color: colors.color_background,
                    },
                  }}
                  startIcon={
                    patientCallStatus.loading && loadingFlag?.Do_not_Call ? (
                      <CircularProgress
                        color="inherit"
                        size={15}
                        thickness={6}
                      />
                    ) : (
                      <DoNotDisturbOnTotalSilenceIcon />
                    )
                  }
                  fullWidth
                  size="small"
                  value="Do_not_Call"
                  onClick={() => handleSubmit("Do_not_Call")} // Pass "Reminder" as an argument
                >
                  Do not Call
                </Button>
              </Grid>
              <Grid item xs={4} alignItems={"center"} alignContent={"center"}>
                <Button
                  id="Reminder"
                  sx={{
                    backgroundColor: "#0077B6",
                    color: colors.color_background,
                    textTransform: "capitalize",
                    ":hover": {
                      backgroundColor: "#0077B6",
                      color: colors.color_background,
                    },
                  }}
                  startIcon={
                    patientCallStatus.loading && loadingFlag?.Reminder ? (
                      <CircularProgress
                        color="inherit"
                        size={15}
                        thickness={6}
                      />
                    ) : (
                      <NotificationsActiveIcon />
                    )
                  }
                  fullWidth
                  size="small"
                  value="Reminder"
                  onClick={() => handleSubmit("Reminder")} // Pass "Reminder" as an argument
                >
                  Reminder
                </Button>
              </Grid>
            </Grid>

            <Grid item container spacing={1}>
              <Grid item xs={12} alignItems={"start"} alignContent={"start"}>
                <TextField
                  id="outlined-multiline-static"
                  label=""
                  multiline
                  rows={2}
                  placeholder="Enter Patient Note"
                  fullWidth
                  value={patientDetailsUpdate?.note}
                  onChange={(event) => {
                    setIsNoteUpdated(true);

                    setPatientDetailsUpdate({
                      ...patientDetailsUpdate,
                      note: event.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                container
                alignItems={"end"}
                alignContent={"end"}
                justifyContent={"end"}
              >
                <Grid
                  // xs={3}
                  item
                  justifySelf={"flex-end"}
                  sx={{
                    ":hover": {
                      cursor:
                        patientDetailsUpdate?.note ===
                          patientDetails?.CaseManagerNotes ||
                        patientDetailsUpdate?.note === ""
                          ? "not-allowed"
                          : "pointer",
                    },
                  }}
                  dis
                >
                  <Button
                    variant="outlined"
                    // disabled={
                    //   // patientDetailsUpdate?.note === null ||
                    //   patientDetailsUpdate?.note ===
                    //     patientDetails?.CaseManagerNotes ||
                    //   patientDetailsUpdate?.note === "" ||
                    //   !isNoteUpdated
                    //     ? true
                    //     : false
                    // }
                    sx={{
                      background: colors.color_primary_txt,
                      color: colors.color_primary,
                      textTransform: "capitalize",
                      cursor: "cell", // Change cursor style to "pointer"
                      "&:hover": {
                        background: colors.color_primary_txt,
                        color: colors.color_primary,
                      },
                      ":disabled": {
                        background: colors.color_primary_txt,
                        color: colors.color_primary,
                        opacity: 0.5,
                      },
                    }}
                    onClick={() => handleSubmit("caseManagerNotes")} // Pass "Reminder" as an argument
                  >
                    {patientCallStatus.loading &&
                    loadingFlag?.caseManagerNotes ? (
                      <>
                        <Spinner width={18} height={18} />
                        <span style={{ marginLeft: "6px" }}>{"Updating"}</span>
                      </>
                    ) : (
                      <span style={{ marginLeft: "6px", color: "white" }}>
                        Submit
                      </span>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientCallStatus;
