import { getInsurancePlanTypes } from "../types";

export const getInsurancePlanActions = {
  insurancePlanRequest,
  insurancePlanRequestSuccess,
  insurancePlanRequestFailure,
};
function insurancePlanRequest(request, token) {
  return {
    type: getInsurancePlanTypes.GET_INSURANCE_PLAN_REQUEST,
    request,
    token,
  };
}
function insurancePlanRequestSuccess(get) {
  return {
    type: getInsurancePlanTypes.GET_INSURANCE_PLAN_REQUEST_SUCCESS,
    get,
  };
}
function insurancePlanRequestFailure(error) {
  return {
    type: getInsurancePlanTypes.GET_INSURANCE_PLAN_REQUEST_FAILURE,
    error,
  };
}
