import { GridFilterInputValue, getGridStringOperators } from "@mui/x-data-grid";
import dayjs from "dayjs";
export const isEmpty = (val) => val === "";
export const isEmptyOrNull = (val) => val === "" || val === null;
export const isContactEmptyOrInvalid = (val) => val === "" || val.length !== 10;
export const isContactInvalid = (val) => val.length !== 10;
export const isZipCodeInvalid = (val) => val.length !== 5;
export const isOTPInvalid = (val) => val.length !== 6;

export const isPasswordInvalid = (val) => val.length < 6;

export const isEmailInvalid = (text) => {
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/; // eslint-disable-line
  if (reg.test(text) === false) {
    return true;
  } else {
    return false;
  }
};

// export const isDigitInvalid = (val) => isNaN(val)
export const isPriceEmptyOrInvalid = (val) =>
  val === "" || val === 0 || val === "0";

export const dateDiff = (sdate, edate) => {
  const date1 = new Date(sdate);
  const date2 = new Date(edate);

  const diffTime = date2 - date1;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

// to get first chars of first and last name
export const getEachWordFirstChar = (fullName) => {
  const mataches = fullName.match(/\b(\w)/g);
  const acronym = mataches.join("");
  return acronym.slice(0, 2);
};

export const removeSemi = (str) => {
  if (str != null && str.length > 0 && str.charAt(str.length - 1) === ";") {
    str = str.substring(0, str.length - 1);
  }
  return str;
};

export function calculateBmi(height_feet, height_inch, weight) {
  if (height_feet != "" && height_inch != "" && weight != "") {
    var Result;
    height_feet = parseFloat(height_feet) * 12 + parseFloat(height_inch);
    if (height_feet > 0)
      Result =
        (parseFloat(weight) * 703) /
        (parseFloat(height_feet) * parseFloat(height_feet));
    else Result = "";
    return Result.toFixed(2);
  }
}

export const customStringFilters = [
  ...getGridStringOperators(),
  {
    label: "not contains",

    value: "noContain",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      const filterRegex = new RegExp(filterItem.value, "i");
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : params.value;
        return !filterRegex.test(rowValue?.toString() || "");
      };
    },
    InputComponent: GridFilterInputValue,
  },
];
// export const programNameConvert = (str) => {
//   if (/^[A-Z]+$/.test(str)) {
//     return str.split("").join(".") + ". Program";
//   }

//   // Handle cases with initial uppercase sequence followed by other characters
//   let match = str.match(/^([A-Z]+)(.*)$/);
//   if (match) {
//     let acronym = match[1];
//     let rest = match[2];

//     if (acronym.length > 1) {
//       // Format the acronym with dots, including the last letter
//       let formattedAcronym = acronym.split("").join(".");

//       // Format the rest of the string
//       let formattedRest = rest.replace(/([A-Z])/g, " $1").trim();

//       return `${formattedAcronym}. ${formattedRest} Program`.trim();
//     }
//   }

//   // For other cases, split at capital letters and join with spaces
//   let formatted = str.replace(/([A-Z])/g, " $1").trim();
//   return formatted + " Program";
// };

export const programNameConvert = (str) => {
  const keywords = [
    "Screen",
    "Obesity",
    "Cardio",
    "Smear",
    "Council",
    "Immediate",
    "Cancer",
    "Rectal",
    "Child",
    "Retina",
  ];

  for (const keyword of keywords) {
    if (str.includes(keyword)) {
      return `${str.replace(keyword, "")} ${keyword} Program`.trim();
    }
  }

  return `${str} Program`;
  // if (/^[A-Z]+$/.test(str)) {
  //   return str.split("").join("") + " Program";
  // }

  // // Handle cases with initial uppercase sequence followed by other characters
  // let match = str.match(/^([A-Z]+)(.*)$/);
  // if (match) {
  //   let acronym = match[1].slice(0, -1);
  //   let rest = ` ${match[1].slice(-1)}${match[2]}`;

  //   if (acronym.length > 1) {
  //     // Format the acronym with dots
  //     let formattedAcronym = acronym.split("").join(".");

  //     // Format the rest of the string, but don't add space before the first character
  //     let formattedRest =
  //       rest.charAt(0) +
  //       rest
  //         .slice(1)
  //         .replace(/([A-Z])/g, " $1")
  //         .trim();

  //     return `${formattedAcronym}. ${formattedRest} Program`.trim();
  //   }
  // }

  // // For other cases, split at capital letters and join with spaces
  // let formatted = str.replace(/([A-Z])/g, " $1").trim();
  // return formatted + " Program";
};

export const dateGetter = (timeStamp) => {
  // Check if the timestamp is valid before formatting
  const date = dayjs(timeStamp);
  // Return formatted date if valid, otherwise return null
  return date.isValid() && timeStamp ? date.format("MM/DD/YYYY") : null;
};

export const dateTimeGetter = (timeStamp) => {
  // Create a Day.js object from the timestamp

  const date = dayjs(timeStamp);

  // Check if the date is valid and return formatted date if valid, otherwise return null
  return date.isValid() ? date.format("MM/DD/YYYY, hh:mm A [EST]") : null;
};

export const stringCapatilizeConverter = (textValue) => {
  console.log(textValue, "textValue");
  let converterdStringArray = textValue
    ?.split(" ")
    ?.map(
      (text) =>
        `${text?.slice(0, 1)?.toUpperCase() + text?.slice(1)?.toLowerCase()}`
    );

  return converterdStringArray?.join(" ");
};
