import { ehrFileUploadTypes } from "../types";

const initialState = {
  uploading: false,
  message: "",
  error: "",
};
export const ehrFileUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case ehrFileUploadTypes.EHRFILEUPLOAD_REQUEST:
      return {
        ...initialState,
        uploading: true,
      };
    case ehrFileUploadTypes.EHRFILEUPLOAD_REQUEST_SUCCESS:
      return {
        ...initialState,
        message: action.message,
      };
    case ehrFileUploadTypes.EHRFILEUPLOAD_REQUEST_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case ehrFileUploadTypes.EHRFILEUPLOAD_REQUEST_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
