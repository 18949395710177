import { messageListTypes } from "../types";

export const messageListActions = {
  messageListRequest,
  messageListRequestSuccess,
  messageListRequestFailure,
};

function messageListRequest(request, token) {
  return { type: messageListTypes.MESSAGE_LIST_REQUEST, request, token };
}
function messageListRequestSuccess(getList) {
  return { type: messageListTypes.MESSAGE_LIST_REQUEST_SUCCESS, getList };
}
function messageListRequestFailure(error) {
  return { type: messageListTypes.MESSAGE_LIST_REQUEST_FAILURE, error };
}
