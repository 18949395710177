export const hospitalizationTypes = {
  GETALL_HOSPITALIZATION_REQUEST: "GETALL_HOSPITALIZATION_REQUEST",
  GETALL_HOSPITALIZATION_REQUEST_SUCCESS:
    "GETALL_HOSPITALIZATION_REQUEST_SUCCESS",
  GETALL_HOSPITALIZATION_REQUEST_FAILURE:
    "GETALL_HOSPITALIZATION_REQUEST_FAILURE",

  OPERARTION_HOSPITALIZATION_REQUEST: "OPERARTION_HOSPITALIZATION_REQUEST",
  OPERARTION_HOSPITALIZATION_REQUEST_SUCCESS:
    "OPERARTION_HOSPITALIZATION_REQUEST_SUCCESS",
  OPERARTION_HOSPITALIZATION_REQUEST_FAILURE:
    "OPERARTION_HOSPITALIZATION_REQUEST_FAILURE",
  OPERARTION_HOSPITALIZATION_REQUEST_RESET:
    "OPERARTION_HOSPITALIZATION_REQUEST_RESET",

  DELETE_HOSPITALIZATION_REQUEST: "DELETE_HOSPITALIZATION_REQUEST",
  DELETE_HOSPITALIZATION_REQUEST_SUCCESS:
    "DELETE_HOSPITALIZATION_REQUEST_SUCCESS",
  DELETE_HOSPITALIZATION_REQUEST_FAILURE:
    "DELETE_HOSPITALIZATION_REQUEST_FAILURE",
  DELETE_HOSPITALIZATION_REQUEST_RESET: "DELETE_HOSPITALIZATION_REQUEST_RESET",
};
