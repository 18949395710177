import React from "react";
import { Col } from "react-bootstrap";
import styles from "./TextCard.module.scss";
import { TextAvatar } from "../../../../../components/TextAvatar";

const TextCard = ({ text, date, name, onClick, isActive, group, subGroup }) => {
  // var item = [
  //   {
  //     patientName: "The Darshil",
  //   },
  // ];
  // to get first chars of first and last name
  // const getEachWordFirstChar = (fullName) => {
  //   const mataches = fullName.match(/\b(\w)/g);
  //   const acronym = mataches.join("");
  //   return acronym.slice(0, 2);
  // };
  return (
    <Col
      className={isActive ? styles.item_active : styles.item}
      xs={{ span: 11 }}
      sm={{ span: 6 }}
      md={{ span: 5 }}
      lg={{ span: 4 }}
      xl={{ span: 3 }}
      onClick={onClick}
    >
      <div className={styles.container}>
        <div className={styles.row}>
          <TextAvatar name={name} />
          <p className={styles.name}>{name}</p>
          <p className={styles.date}>{date}</p>
        </div>
        <div className={styles.row}>
          <p className={styles.txt}>{"Group: " + group}</p>
        </div>
        <div className={styles.row}>
          <p className={styles.txt}>{"Sub Group: " + subGroup}</p>
        </div>
        <div className={styles.row}>
          <p className={styles.txt}>{text}</p>
        </div>
        {/* <div className={styles.row}>
          <div className={styles.icon}>
            <MdIcons.MdEdit className={styles.btn_icon} />
          </div>
          <div className={styles.icon}>
            <MdIcons.MdDelete className={styles.btn_icon} />
          </div>
        </div> */}
      </div>
    </Col>
  );
};

export { TextCard };
