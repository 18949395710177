import { put, takeLatest, call } from "@redux-saga/core/effects";

import { cmerTasksDetailsActions } from "../actions/tasksDetails.action";
import { cmerTasksDetailsType } from "../types/tasksDetails.type";
import { endpoint } from "../../../../api/Url";
import { loginActions } from "../../../Login/actions/login.action";
import { Api2 } from "../../../../api/Api2";

export function* watchCmerTasksDetails() {
  yield takeLatest(
    cmerTasksDetailsType.CALL_TASKS_DETAILS_REQUEST,
    getTasksDetails
  );
}

function* getTasksDetails(action) {
  try {
    //   api call
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.cmer}/tasks?patientId=${action.request.patientId}&from=${action.request.from}&to=${action.request.to}&encounterId=${action.request.encounterId}`,
      jwt: "Bearer " + action.token,
    });
    yield put(
      cmerTasksDetailsActions.tasksDetailsRequestSuccess(response.data)
    );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(cmerTasksDetailsActions.tasksDetailsRequestFailure(error));
  }
}
