import { put, takeLatest, call } from "@redux-saga/core/effects";
import { contactActions } from "../actions/contact.action";
import { contactTypes } from "../types/contact.type";

import { endpoint } from "../../../api/Url";
import { Api } from "../../../api";
import { loginActions } from "../../Login/actions/login.action";

export function* watchContact() {
  yield takeLatest(contactTypes.CONTACT_REQUEST, getAllContact);
}

function* getAllContact(action) {
  try {
    const contacts = yield call(Api, {
      endpoint: endpoint.getAllContacts,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    const parsedContacts = JSON.parse(contacts);

    yield put(contactActions.contactRequestSuccess(parsedContacts));
  } catch (error) {
    yield put(contactActions.contactRequestFailure(error));

    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
  }
}
