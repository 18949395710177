import { inboxMailboxTypes } from "../../types/inboxMailbox.type";
const initialState = {
  loading: false,
  getAll: [],
  error: "",
};

export const inboxMailboxReducer = (state = initialState, action) => {
  switch (action.type) {
    case inboxMailboxTypes.INBOX_MAILBOX_REQUEST:
      return { ...initialState, loading: true };
    case inboxMailboxTypes.INBOX_MAILBOX_REQUEST_SUCCESS:
      console.log(action, "imhere");
      return { ...initialState, getAll: action?.getAll?.data };
    case inboxMailboxTypes.INBOX_MAILBOX_REQUEST_FAILURE:
      return { ...initialState, error: action.error };

    default:
      return state;
  }
};
