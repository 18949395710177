import { updateCMPatientDetailsType } from "../../types/patientDetailstype/updateCMPatientDetails";

const initialState = {
  loading: false,
  getAll: [],
  message: "",
};

export const updateCMPatientDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case updateCMPatientDetailsType.UPDATE_CM_PATIENT_DETAILS_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case updateCMPatientDetailsType.UPDATE_CM_PATIENT_DETAILS_SUCCESS:
      return {
        ...initialState,
        // getAll: action.getAll,
        getAll: action.message,
        loading: false,
      };
    case updateCMPatientDetailsType.UPDATE_CM_PATIENT_DETAILS_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case updateCMPatientDetailsType.UPDATE_CM_PATIENT_DETAILS_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
