import { put, takeLatest, call } from "@redux-saga/core/effects";
import { medicationSaveActions } from "../actions/medicationSave.action";
import { medicationSaveType } from "../types/medicationSave.type";
import { loginActions } from "../../../Login/actions/login.action";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";

export function* watchMedicationSave() {
  yield takeLatest(
    medicationSaveType.MEDICATION_SAVE_REQUEST,
    getMedicationSave
  );
}

function* getMedicationSave(action) {
  try {
    const message = yield call(Api, {
      endpoint: `${endpoint.saveMedication}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    const parsedMessage = JSON.parse(message);

    yield put(
      medicationSaveActions.medicationSaveRequestSuccess(
        parsedMessage[0].Result
      )
    );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(medicationSaveActions.medicationSaveRequestFailure(error));
  }
}
