import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { insuranceOperationActions } from "../actions";
import { insuranceOperationTypes } from "../types";

export function* watchDMInsuranceOperation() {
  yield takeLatest(
    insuranceOperationTypes.INSURANCE_OPERATION_REQUEST,
    insuranceOperation
  );
}

function* insuranceOperation(action) {
  try {
    let data = yield call(Api, {
      method: "POST",
      endpoint: endpoint.operationDMInsurance,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedData = JSON.parse(data);

    if(parsedData.length > 0){
    yield put(insuranceOperationActions.insuranceOperationRequestSuccess(parsedData[0]));
    }else {
      yield put(insuranceOperationActions.insuranceOperationRequestSuccess({Result:"Error"}));
    }
  } catch (error) {
    yield put(
      insuranceOperationActions.insuranceOperationRequestFailure(error)
    );
  }
}
