import { permissionTypes } from "../types";

const initialState = {
  loading: false,
  getAll: [],
  parentMenuId: -1,
  error: "",
};

export const permissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case permissionTypes.PERMISSION_GETALL_REQUEST:
      return { ...initialState, loading: true };
    case permissionTypes.PERMISSION_GETALL_REQUEST_SUCCESS:
      return { ...initialState, getAll: action.getAll };
    case permissionTypes.PERMISSION_GETALL_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    case permissionTypes.PERMISSION_SETPARENT:
      return { ...state, parentMenuId: action.parentMenuId };
    case permissionTypes.PERMISSION_RESETPARENT:
      return { ...state, parentMenuId: -1 };
    default:
      return state;
  }
};
