import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: false,
};

export const errorBoundrySlice = createSlice({
  name: "errorBoundry",
  initialState: initialState,
  reducers: {
    errorBoundrySet: (state) => {
      state.error = true;
    },

    errorBoundryReSet: (state) => {
      state.error = false;
    },
  },
});

export const { errorBoundrySet, errorBoundryReSet } = errorBoundrySlice.actions;
export default errorBoundrySlice.reducer;
