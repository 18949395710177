import { call, put, takeLatest } from "redux-saga/effects";
import { endpoint } from "../../../../../api/Url";
import { Api2 } from "../../../../../api/Api2";
import { loginActions } from "../../../../Login/actions/login.action";
import { carePlanTypes } from "../types/careplan.type";
import { carePlanActions } from "../actions/careplan.action";

export function* watchCarePlan() {
  yield takeLatest(carePlanTypes.GETALL_CARE_PLAN_REQUEST, getCareplan);
  yield takeLatest(carePlanTypes.ADD_CARE_PLAN_REQUEST, addCareplan);
  yield takeLatest(carePlanTypes.EDIT_CARE_PLAN_REQUEST, editCareplan);

  //cm lookup
  yield takeLatest(carePlanTypes.LOOKUP_CM_DIAGNOSIS, lookupDiagnosis);
  yield takeLatest(carePlanTypes.LOOKUP_CM_GOAL, lookupGoal);
  yield takeLatest(carePlanTypes.LOOKUP_CM_FREQUENCY, lookupFrequency);
  yield takeLatest(
    carePlanTypes.LOOKUP_CM_PATIENT_RESPONSIBILITY,
    lookupResponsibility
  );
  yield takeLatest(carePlanTypes.LOOKUP_CM_INTERVENTION, lookupInvention);
}

// Get care plan
function* getCareplan(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.ccm}/${action.request}/${endpoint.careplans}`,
      jwt: "Bearer " + action.token,
    });

    yield put(carePlanActions.getallCarePlanRequestSuccess(response.data));
    // response.data !== undefined && response.data.length > 0
    //   ? yield put(carePlanActions.getallCarePlanRequestSuccess(response.data))
    //   : yield put(
    //       carePlanActions.getallCarePlanRequestFailure("No data found")
    //     );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(carePlanActions.getallCarePlanRequestFailure(error));
  }
}

//Add care plan
function* addCareplan(action) {
  try {
    const response = yield call(Api2, {
      endpoint: `${endpoint.ccm}/${action.params.PatientId}/${endpoint.careplans}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    yield put(carePlanActions.addCarePlanRequestSuccess(response.message));
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(carePlanActions.addCarePlanRequestFailure(error));
  }
}
//Edit care plan
function* editCareplan(action) {
  try {
    const message = yield call(Api2, {
      //   endpoint: endpoint.saveCMDCallHistory,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    yield put(carePlanActions.editCarePlanRequestSuccess(message));
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(carePlanActions.editCarePlanRequestFailure(error));
  }
}

// Get cm diagnosis
function* lookupDiagnosis(action) {
  try {
    let data = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.ccm}/${action.request}/${endpoint.diagnosis}`,
      jwt: "Bearer " + action.token,
    });

    data.data !== undefined && data.data.length > 0
      ? yield put(carePlanActions.lookupCMDiagnosisRequestSuccess(data.data))
      : yield put(
          carePlanActions.lookupCMDiagnosisRequestFailure("No data found")
        );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(carePlanActions.lookupCMDiagnosisRequestFailure(error));
  }
}
// Get cm goal
function* lookupGoal(action) {
  try {
    let data = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.ccm}/${action.request}/${endpoint.goal}`,
      jwt: "Bearer " + action.token,
    });

    data.data !== undefined && data.data.length > 0
      ? yield put(carePlanActions.lookupCMGoalRequestSuccess(data.data))
      : yield put(carePlanActions.lookupCMGoalRequestFailure("No data found"));
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(carePlanActions.lookupCMGoalRequestFailure(error));
  }
}
// Get cm frequency
function* lookupFrequency(action) {
  try {
    let data = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.ccm}/${endpoint.frequency}`,
      jwt: "Bearer " + action.token,
    });

    data.data !== undefined && data.data.length > 0
      ? yield put(carePlanActions.lookupCMFrequencyRequestSuccess(data.data))
      : yield put(
          carePlanActions.lookupCMFrequencyRequestFailure("No data found")
        );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(carePlanActions.lookupCMFrequencyRequestFailure(error));
  }
}
// Get cm responsibility
function* lookupResponsibility(action) {
  try {
    let data = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.ccm}/${action.request}/${endpoint.patient_responsibility}`,
      jwt: "Bearer " + action.token,
    });

    data.data !== undefined && data.data.length > 0
      ? yield put(
          carePlanActions.lookupCMPatientResponsibilityRequestSuccess(data.data)
        )
      : yield put(
          carePlanActions.lookupCMPatientResponsibilityRequestFailure(
            "No data found"
          )
        );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(
      carePlanActions.lookupCMPatientResponsibilityRequestFailure(error)
    );
  }
}

// Get cm invention
function* lookupInvention(action) {
  try {
    let data = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.ccm}/${action.request}/${endpoint.intervention}`,
      jwt: "Bearer " + action.token,
    });

    data.data !== undefined && data.data.length > 0
      ? yield put(carePlanActions.lookupCMInterventionRequestSuccess(data.data))
      : yield put(
          carePlanActions.lookupCMInterventionRequestFailure("No data found")
        );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(carePlanActions.lookupCMInterventionRequestFailure(error));
  }
}
