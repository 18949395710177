import { put, takeLatest, call } from "@redux-saga/core/effects";
import { chargesActions } from "../actions/charges.action";
import { chargesType } from "../types/charges.type";
import { endpoint } from "../../../../../api/Url";
import { Api } from "../../../../../api";
import { loginActions } from "../../../../Login/actions/login.action";

export function* watchCharges() {
  yield takeLatest(chargesType.CHARGES_REQUEST, getAllCharges);
}

function* getAllCharges(action) {
  try {
    // //   api call
    const data = yield call(Api, {
      endpoint: `${endpoint.getCharges}?AdmissionID=${
        action.request.patientAdmissionId
      }&&Id=${"SgBtADIAZABvAGsARgBIADQAKwBNAD0A"}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    const parsedData = JSON.parse(data);

    parsedData.BundleDispositionDetail !== undefined &&
    parsedData.BundleDispositionDetail.length > 0
      ? yield put(
          chargesActions.chargesRequestSuccess(
            parsedData.BundleDispositionDetail
          )
        )
      : yield put(chargesActions.chargesRequestFailure("No data found"));
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(chargesActions.chargesRequestFailure(error));
  }
}
