import { insurancePlanOperationTypes } from "../types";

export const insurancePlanOperationActions = {
  insurancePlanOperationRequest,
  insurancePlanOperationRequestReset,
  insurancePlanOperationRequestSuccess,
  insurancePlanOperationRequestFailure,
};
function insurancePlanOperationRequest(request, token) {
  return {
    type: insurancePlanOperationTypes.INSURANCE_PLAN_OPERATION_REQUEST,
    request,
    token,
  };
}
function insurancePlanOperationRequestReset() {
  return {
    type: insurancePlanOperationTypes.INSURANCE_PLAN_OPERATION_REQUEST_RESET,
  };
}
function insurancePlanOperationRequestSuccess(message) {
  return {
    type: insurancePlanOperationTypes.INSURANCE_PLAN_OPERATION_REQUEST_SUCCESS,
    message,
  };
}
function insurancePlanOperationRequestFailure(error) {
  return {
    type: insurancePlanOperationTypes.INSURANCE_PLAN_OPERATION_REQUEST_FAILURE,
    error,
  };
}
