import { patientMessageTypes } from "./patientMessage.type";

export const patientMessageActions = {
  patientMessageSendRequest,
  patientMessageSendRequestSuccess,
  patientMessageSendRequestFailure,
  patientMessageSendRequestReset,
};
function patientMessageSendRequest(request, token) {
  return {
    type: patientMessageTypes.PATIENT_MESSAGE_SEND_REQUEST,
    request,
    token,
  };
}
function patientMessageSendRequestSuccess(message) {
  return {
    type: patientMessageTypes.PATIENT_MESSAGE_SEND_REQUEST_SUCCESS,
    message,
  };
}
function patientMessageSendRequestFailure(error) {
  return {
    type: patientMessageTypes.PATIENT_MESSAGE_SEND_REQUEST_FAILURE,
    error,
  };
}
function patientMessageSendRequestReset() {
  return {
    type: patientMessageTypes.PATIENT_MESSAGE_SEND_REQUEST_RESET,
  };
}
