import { diagnosisDetailsType } from "../types";

export const diagnosisDetailsActions = {
  diagnosisDetailsRequest,
  diagnosisDetailsRequestSuccess,
  diagnosisDetailsRequestFailure,
};
function diagnosisDetailsRequest(request, token) {
  return {
    type: diagnosisDetailsType.DIAGNOSIS_DETAILS_REQUEST,
    request,
    token,
  };
}
function diagnosisDetailsRequestSuccess(getAll) {
  return {
    type: diagnosisDetailsType.DIAGNOSIS_DETAILS_REQUEST_SUCCESS,
    getAll,
  };
}
function diagnosisDetailsRequestFailure(error) {
  return {
    type: diagnosisDetailsType.DIAGNOSIS_DETAILS_REQUEST_FAILURE,
    error,
  };
}
