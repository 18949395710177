import { patientDetailsUpdateType } from "../types";

const initialState = {
  updating: false,
};
export const tcPatientDetailsUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case patientDetailsUpdateType.UPDATE_PATIENT_DETAILS_REQUEST:
      return {
        ...initialState,
        updating: true,
      };
    case patientDetailsUpdateType.UPDATE_PATIENT_DETAILS_REQUEST_SUCCESS:
      return {
        ...initialState,
        message: action.message,
      };
    case patientDetailsUpdateType.UPDATE_PATIENT_DETAILS_REQUEST_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case patientDetailsUpdateType.UPDATE_PATIENT_DETAILS_REQUEST_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
