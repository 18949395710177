import { deleteMedicationTypes } from "../types/deleteMedication.type";

export const deleteMedicationActions = {
  deleteMedicationRequest,
  deleteMedicationRequestSuccess,
  deleteMedicationRequestFailure,
  deleteMedicationRequestReset,
};

function deleteMedicationRequest(request, token) {
  return {
    type: deleteMedicationTypes.DELETE_MEDICATION_REQUEST,
    request,
    token,
  };
}

function deleteMedicationRequestSuccess(message) {
  return {
    type: deleteMedicationTypes.DELETE_MEDICATION_REQUEST_SUCCESS,
    message,
  };
}

function deleteMedicationRequestFailure(error) {
  return {
    type: deleteMedicationTypes.DELETE_MEDICATION_REQUEST,
    error,
  };
}

function deleteMedicationRequestReset() {
  return {
    type: deleteMedicationTypes.DELETE_MEDICATION_REQUEST_RESET,
  };
}
