import { createSlice } from "@reduxjs/toolkit";

// Initial state for initial assessment
const initialAssessmentState = {
  loading: false,
  data: [], // Renamed getAll to data for clarity
  error: "",
};

// Slice for getting initial assessment
const getInitialAssessmentSlice = createSlice({
  name: "getInitialAssessment",
  initialState: initialAssessmentState,
  reducers: {
    getInitialAssessmentRequest: (state) => {
      state.loading = true;
    },
    getInitialAssessmentSuccess: (state, action) => {
      console.log(action,'action')
      state.loading = false;
      state.error = "";
      state.data = action.payload.data;
    },
    getInitialAssessmentFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.error; // Adjusted to match action structure
    },
    getInitialAssessmentReset: () => initialAssessmentState, // Simplified reset
  },
});

// Export actions and reducer for getting initial assessment
export const {
  getInitialAssessmentRequest,
  getInitialAssessmentSuccess,
  getInitialAssessmentFailure,
  getInitialAssessmentReset,
} = getInitialAssessmentSlice.actions;

export const getInitialAssessmentReducer = getInitialAssessmentSlice.reducer;

// Initial state for updating initial assessment
const initialUpdateAssessmentState = {
  loading: false,
  message: "",
  error: "",
};

// Slice for updating initial assessment
const updateInitialAssessmentSlice = createSlice({
  name: "updateInitialAssessment",
  initialState: initialUpdateAssessmentState,
  reducers: {
    updateInitialAssessmentRequest: (state) => {
      state.loading = true;
    },
    updateInitialAssessmentSuccess: (state, action) => {
      state.loading = false;
      state.error = "";
      state.message = action.payload.message; // Adjusted to match action structure
    },
    updateInitialAssessmentFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.error; // Adjusted to match action structure
    },
    updateInitialAssessmentReset: () => initialUpdateAssessmentState, // Simplified reset
  },
});

// Export actions and reducer for updating initial assessment
export const {
  updateInitialAssessmentRequest,
  updateInitialAssessmentSuccess,
  updateInitialAssessmentFailure,
  updateInitialAssessmentReset,
} = updateInitialAssessmentSlice.actions;

export const updateInitialAssessmentReducer =
  updateInitialAssessmentSlice.reducer;
