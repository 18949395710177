import { put, takeLatest } from "@redux-saga/core/effects";
import { call } from "redux-saga/effects";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { loginActions } from "../../../Login/actions/login.action";
import { setAppointmentActions } from "../actions/setAppointment.action";
import { setAppointmentType } from "../types/setAppointment.type";

export function* watchSetAppointment() {
  yield takeLatest(
    setAppointmentType.SET_APPOINTMENT_REQUEST,
    getSetAppointment
  );
}

function* getSetAppointment(action) {
  try {
    const data = yield call(Api, {
      endpoint: `${endpoint.saveAppointment}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    const parsedData = JSON.parse(data);

    yield put(
      setAppointmentActions.setAppointmentRequestSuccess(parsedData[0])
    );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(setAppointmentActions.setAppointmentRequestFailure(error));
  }
}
