import {
  powerbiDashboardById,
  powerbiDashboardByIdType,
  powerbiDashboardTabsType,
  powerbiDashboardType,
} from "../types/powerbiDashboard.type";

export const powerbiActions = {
  powerbiRequest,
  powerbiRequestReset,
  powerbiRequestSuccess,
  powerbiRequestFailure,
};

function powerbiRequest(request, token, roleId) {
  return {
    type: powerbiDashboardType.POWERBI_REQUEST,
    request,
    token,
    roleId,
  };
}
function powerbiRequestReset() {
  return {
    type: powerbiDashboardType.POWERBI_REQUEST_RESET,
  };
}
function powerbiRequestSuccess(reportData) {
  return {
    type: powerbiDashboardType.POWERBI_REQUEST_SUCCESS,
    reportData,
  };
}
function powerbiRequestFailure(error) {
  return {
    type: powerbiDashboardType.POWERBI_REQUEST_FAILURE,
    error: error,
  };
}

export const powerbiDashboardTabsActions = {
  powerbiDashboardTabsRequest,
  powerbiDashboardTabsSuccess,
  powerbiDashboardTabsFaliure,
  powerbiDashboardTabsReset,
};
function powerbiDashboardTabsRequest(token) {
  return {
    type: powerbiDashboardTabsType.POWERBI_DASHBOARD_TABS_REQUEST,

    token,
  };
}
function powerbiDashboardTabsSuccess(tabs) {
  return {
    type: powerbiDashboardTabsType.POWERBI_DASHBOARD_TABS_SUCCESS,
    tabs,
  };
}
function powerbiDashboardTabsFaliure(error) {
  return {
    type: powerbiDashboardTabsType.POWERBI_DASHBOARD_TABS_FAILURE,
    error: error,
  };
}
function powerbiDashboardTabsReset() {
  return {
    type: powerbiDashboardTabsType.POWERBI_DASHBOARD_TABS_RESET,
  };
}

export const powerbiDashboardByIdActions = {
  powerbiDashboardByIdRequest,
  powerbiDashboardByIdSuccess,
  powerbiDashboardByIdFailure,
  powerbiDashboardByIdReset,
};
function powerbiDashboardByIdRequest(request, token) {
  return {
    type: powerbiDashboardByIdType.POWERBI_REPORT_BY_ID_REQUEST,
    request,
    token,
  };
}
function powerbiDashboardByIdReset() {
  return {
    type: powerbiDashboardByIdType.POWERBI_REPORT_BY_ID_RESET,
  };
}
function powerbiDashboardByIdSuccess(reportData) {
  return {
    type: powerbiDashboardByIdType.POWERBI_REPORT_BY_ID_SUCCESS,
    reportData,
  };
}
function powerbiDashboardByIdFailure(error) {
  return {
    type: powerbiDashboardByIdType.POWERBI_REPORT_BY_ID_FAILURE,
    error: error,
  };
}
