import { put, takeLatest, call } from "@redux-saga/core/effects";
import { cmUsersActions } from "../actions/cmUsers.action";
import { cmUsersType } from "../types/cmUsers.type";
import { endpoint } from "../../../../api/Url";
import { Api } from "../../../../api";
import { loginActions } from "../../../Login/actions/login.action";

export function* watchCMUsers() {
  yield takeLatest(cmUsersType.CM_USERS_REQUEST, getCaseManagers);
}

function* getCaseManagers(action) {
  try {
    //   api call
    const data = yield call(Api, {
      endpoint: `${endpoint.getCMUsers}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    const parsedData = JSON.parse(data);

    parsedData !== null && parsedData.length > 0
      ? yield put(cmUsersActions.cmUsersRequestSuccess(parsedData))
      : yield put(cmUsersActions.cmUsersRequestFailure("No data found"));
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(cmUsersActions.cmUsersRequestFailure(error));
  }
}
