import { pcpGroupTypes } from "../types/pcpgroup.type";

const initialState = {
  loading: false,
  getAll: [],
  error: "",
  message: "",
};

export const pcpGroupDMReducer = (state = initialState, action) => {
  switch (action.type) {
    case pcpGroupTypes.PCP_GROUP_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case pcpGroupTypes.PCP_GROUP_REQUEST_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
      };
    case pcpGroupTypes.PCP_GROUP_REQUEST_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    //edit pcpgroup
    case pcpGroupTypes.PCP_GROUP_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case pcpGroupTypes.PCP_GROUP_EDIT_REQUEST_SUCCESS:
      // let index = state.getAll.findIndex(
      //   (item) => (item.pcpgroupId = action.id)
      // );
      // const newArr = [...state.getAll];
      // const updatePCPGroup = { ...newArr[index], ...action.data };
      // newArr[index] = updatePCPGroup;
      return {
        ...state,
        loading: false,
        message: action.message,
        // getAll: newArr,
      };
    case pcpGroupTypes.PCP_GROUP_EDIT_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    //create pcpgroup
    case pcpGroupTypes.PCP_GROUP_SAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case pcpGroupTypes.PCP_GROUP_SAVE_REQUEST_SUCCESS:
      return {
        ...state,
        Loading: false,
        message: action.message,
      };
    case pcpGroupTypes.PCP_GROUP_SAVE_REQUEST_FAILURE:
      return {
        ...state,
        Loading: false,
        error: action.error,
      };
    case pcpGroupTypes.PCP_GROUP_REQUEST_RESET:
      return { ...state, loading: false, message: "", error: "" };

    //update status
    case pcpGroupTypes.PCP_GROUP_UPDATE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case pcpGroupTypes.PCP_GROUP_UPDATE_STATUS_REQUEST_SUCCESS:
      let index1 = state.getAll.findIndex(
        (item) => item.pcpgroupId === action.id
      );
      const newArr1 = [...state.getAll];
      const pcpgroup = {
        ...newArr1[index1],
        active: !newArr1[index1].active,
      };
      newArr1[index1] = pcpgroup;
      return {
        ...state,
        loading: false,
        message: action.message,
        getAll: newArr1,
      };
    case pcpGroupTypes.PCP_GROUP_UPDATE_STATUS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
