import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api2 } from "../../../../../api/Api2";
import { endpoint } from "../../../../../api/Url";
import { lookupNationActions } from "../actions/nationLookup.action";
import { lookupNationTypes } from "../types/nationLookup.type";

export function* watchLookupNation() {
  yield takeLatest(lookupNationTypes.LOOKUP_NATION_REQUEST, getAllNation);
}

// Getall nation
function* getAllNation(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.lookupNation}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });
    if (response.data !== undefined && response.data.length > 0) {
      yield put(lookupNationActions.lookupNationRequestSuccess(response.data));
    } else {
      yield put(
        lookupNationActions.lookupNationRequestFailure("No data found")
      );
    }
  } catch (error) {
    yield put(lookupNationActions.lookupNationRequestFailure(error));
  }
}
