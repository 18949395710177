import { icdSaveTypes } from "../types";

const initiaLState = {
    loading: false,
  };

  export const icdSaveDMReducer = (state = initiaLState, action) => {
    switch (action.type) {
      case icdSaveTypes.ICD_SAVE_REQUEST:
        return {
          ...initiaLState,
          loading: true,
        };
      case icdSaveTypes.ICD_SAVE_REQUEST_RESET:
        return {
          ...initiaLState,
        };
      case icdSaveTypes.ICD_SAVE_REQUEST_SUCCESS:
        return {
          ...initiaLState,
          message: action.message,
        };
      case icdSaveTypes.ICD_SAVE_REQUEST_FAILURE:
        return {
          ...initiaLState,
          error: action.error,
        };
      default:
        return state;
    }
  };
  