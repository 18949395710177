import { screenPreventTypes } from "../types";

export const screenPreventExamActions = {
  screenPreventGetRequest,
  screenPreventGetRequestSuccess,
  screenPreventGetRequestFailure,

  screenPreventOpRequest,
  screenPreventOpRequestSuccess,
  screenPreventOpRequestFailure,
  screenPreventOpRequestReset,
};
function screenPreventGetRequest(request, token) {
  return {
    type: screenPreventTypes.SCREENPREVENT_GET_REQUEST,
    request,
    token,
  };
}
function screenPreventGetRequestSuccess(get) {
  return {
    type: screenPreventTypes.SCREENPREVENT_GET_REQUEST_SUCCESS,
    get,
  };
}
function screenPreventGetRequestFailure(error) {
  return {
    type: screenPreventTypes.SCREENPREVENT_GET_REQUEST_FAILURE,
    error,
  };
}

function screenPreventOpRequest(request, token) {
  return {
    type: screenPreventTypes.SCREENPREVENT_OPERATION_REQUEST,
    request,
    token,
  };
}
function screenPreventOpRequestSuccess(message) {
  return {
    type: screenPreventTypes.SCREENPREVENT_OPERATION_REQUEST_SUCCESS,
    message,
  };
}
function screenPreventOpRequestFailure(error) {
  return {
    type: screenPreventTypes.SCREENPREVENT_OPERATION_REQUEST_FAILURE,
    error,
  };
}
function screenPreventOpRequestReset() {
  return {
    type: screenPreventTypes.SCREENPREVENT_OPERATION_REQUEST_RESET,
  };
}
