import { call, put, takeLatest } from "redux-saga/effects";
import { hedisProcedureActions } from "../actions/hedis.action";
import { loginActions } from "../../../Login/actions/login.action";
import { hedisProcedureTypes } from "../types/hedis.type";
import { Api2 } from "../../../../api/Api2";
import { endpoint } from "../../../../api/Url";

export function* watchHedisProcedure() {
  yield takeLatest(
    hedisProcedureTypes.GETALL_PROCEDURELIST_REQUEST,
    getAllHedisProcedure
  );
  yield takeLatest(
    hedisProcedureTypes.UPDATE_PROCEDURELIST_REQUEST,
    updateHedisProcedure
  );
}

function* getAllHedisProcedure(action) {
  try {
    let data = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.hedis}/${action.request.AdmissionID}/${endpoint.procedures}`,
      jwt: "Bearer " + action.token,
    });

    data.procedures !== undefined && data.procedures.length > 0
      ? yield put(
          hedisProcedureActions.getAllProcedureListSuccess(data.procedures)
        )
      : yield put(
          hedisProcedureActions.getAllProcedureListFailure("No data found")
        );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(hedisProcedureActions.getAllProcedureListFailure(error));
  }
}

//Hedis procedure
function* updateHedisProcedure(action) {
  try {
    const message = yield call(Api2, {
      //   endpoint: endpoint.saveCMDCallHistory,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    yield put(hedisProcedureActions.updateProcedureListSuccess(message));
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(hedisProcedureActions.updateProcedureListFailure(error));
  }
}
