import { getAssessmentTypes } from "../types";

export const getAssessmentActions = {
  getAssessmentRequest,
  getAssessmentRequestSuccess,
  getAssessmentRequestFailure,
};

function getAssessmentRequest(request, token) {
  return { type: getAssessmentTypes.GET_ASSESSMENT_REQUEST, request, token };
}
function getAssessmentRequestSuccess(getAssessment) {
  return {
    type: getAssessmentTypes.GET_ASSESSMENT_REQUEST_SUCCESS,
    getAssessment,
  };
}
function getAssessmentRequestFailure(error) {
  return { type: getAssessmentTypes.GET_ASSESSMENT_REQUEST_FAILURE, error };
}
