import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { Api2 } from "../../../api/Api2";
import { endpoint } from "../../../api/Url";
import { getReportsDataActions } from "../actions/getReportsData.action";
import { getReportsDataTypes } from "../types/getReportsData.type";
import { loginActions } from "../../Login/actions/login.action";

export function* watchGetReportsData() {
  yield takeLatest(getReportsDataTypes.GET_REPORTSDATA_REQUEST, getReportsData);
}
function* getReportsData(action) {
  try {
    const data = yield call(Api2, {
      endpoint: `${endpoint.getNewReports}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: { procedure: action.request.ProcedureName },
    });
    const parsedData = data;

    yield put(
      getReportsDataActions.getReportsDataRequestSuccess(parsedData.procedures)
    );
  } catch (error) {
    console.log(error);
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(getReportsDataActions.getReportsDataRequestFailure(error));
  }
}
