import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../api";
import { endpoint } from "../../api/Url";
import { loginActions } from "../../pages/Login/actions/login.action";
import { pcpActions } from "../actions";
import { pcpTypes } from "../types";
import { Api2 } from "../../api/Api2";

export function* watchPCPs() {
  yield takeLatest(pcpTypes.GET_PCP_REQUEST, getPCPs);
}

function* getPCPs(action) {
  console.log(action, "action");
  try {
    const response = yield call(Api2, {
      endpoint: `${endpoint.pcpLookupCmer}?search=${action.query.search}`,
      method: "GET",
      jwt: "Bearer " + action.token,
      //   body: action.request,
    });

    yield put(pcpActions.getPCPRequestSuccess(response?.data));
  } catch (error) {
    yield put(pcpActions.getPCPRequestFailure(error));

    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
  }
}
