import { createSlice } from "@reduxjs/toolkit";

/**Initial state
 * it can be empty */
const initialState = {
  loading: false,
  getAll: [],
  error: "",
};
//creating a slice for test
export const receptionPatientSlice = createSlice({
  name: "receptionPatient",
  initialState: initialState,
  reducers: {
    receptionPatientRequest: (state, action) => {
      state.loading = true;
    },
    receptionPatientSuccess: (state, action) => {
      state.loading = false;
      state.error = "";
      state.getAll = action.payload; // getting message from payload passed to receptionPatientSuccess action
    },
    receptionPatientFailure: (state, action) => {
      state.loading = false;
      state.getAll = [];
      state.error = action.payload.error; // getting error from payload passed to receptionPatientFailure action
    },
    receptionPatientResetRequest: (state, action) => {
      state.loading = false;
      state.error = "";
      state.getAll = [];
    },
  },
});
//getting all actions
export const {
  receptionPatientRequest,
  receptionPatientFailure,
  receptionPatientSuccess,
  receptionPatientResetRequest,
} = receptionPatientSlice.actions;
//exporting slice as a reducer
export default receptionPatientSlice.reducer;
