import { ReferralPlansType } from "../types/ReferralPlans.type";

const initialState = {
  loading: false,
  referralPlans: [],
};

export const referralPlansReducer = (state = initialState, action) => {
  switch (action.type) {
    case ReferralPlansType.REFERRAL_PLANS_REQUEST:
      return { ...initialState, loading: true, referralPlans: [] };
    case ReferralPlansType.REFERRAL_PLANS_REQUEST_SUCCESS:
      return {
        // ...initialState,
        referralPlans: action.referralPlans,
      };
    case ReferralPlansType.REFERRAL_PLANS_REQUEST_FAILURE:
      return { error: action.error };
    //  return { ...initialState,error: action.error };
    default:
      return state;
  }
};
