import { put, takeLatest, call } from "@redux-saga/core/effects";
import { appointmentDetailsActions } from "../actions/appointmentDetails.action";
import { appointmentDetailsType } from "../types/appointmentDetails.type";
import { endpoint } from "../../../../api/Url";
import { Api } from "../../../../api";
import { loginActions } from "../../../Login/actions/login.action";

export function* watchAppointmentDetails() {
  yield takeLatest(
    appointmentDetailsType.APPOINTMENT_DETAILS_REQUEST,
    getAppointmentDetails
  );
}

function* getAppointmentDetails(action) {
  try {
    const data = yield call(Api, {
      endpoint: `${endpoint.getSetAppointmentList}?AdmissionID=${action.request.AdmissionID}`,
      method: "POST",
      jwt: "Bearer " + action.token,
    });
    const parsedData = JSON.parse(data);

    parsedData.SetAppointmentList !== undefined &&
    parsedData.SetAppointmentList.length > 0
      ? yield put(
          appointmentDetailsActions.appointmentDetailsRequestSuccess(
            parsedData.SetAppointmentList
          )
        )
      : yield put(
          appointmentDetailsActions.appointmentDetailsRequestFailure(
            "No data found"
          )
        );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(
      appointmentDetailsActions.appointmentDetailsRequestFailure(error)
    );
  }
}
