import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { sentMailboxTypes } from "../../types";
import { sentMailboxActions } from "../../actions";
import { Api2 } from "../../../../api/Api2";

export function* watchSentMailbox() {
  yield takeLatest(sentMailboxTypes.SENT_MAILBOX_REQUEST, getSentMails);
}

function* getSentMails(action) {
  console.log(action, "action");
  try {
    let sentMails = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.sentMailbox}/${action?.request?.UserRoleId}`,
      jwt: "Bearer " + action.token,
    });
    yield put(sentMailboxActions.sentMailboxRequestSuccess(sentMails));
  } catch (error) {
    yield put(sentMailboxActions.sentMailboxRequestFailure(error));
  }
}
