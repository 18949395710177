import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styles from "./SkeletonLoading.module.scss";
const SkeletonLoading = () => {
  return (
    <div className={styles.container}>
      <h1>
        <Skeleton count={1} />
      </h1>

      <h6 className={styles.paragraph_skull}>
        <Skeleton count={4} />
      </h6>

      <h2>
        <Skeleton count={1} />
      </h2>
      <h6 className={styles.paragraph_skull}>
        <Skeleton count={4} />
      </h6>
      <h2>
        <Skeleton count={1} />
      </h2>
    </div>
  );
};

export { SkeletonLoading };
