import React, { useState } from "react";
import * as FiIcons from "react-icons/fi";
import styles from "./Search.module.scss";

const Search = (props) => {
  const { getToggleValue, isCoder = false, ...otherprops } = props;
  const [searchBy, setSearchBy] = useState("pcp");

  const handleToggle = () => {
    setSearchBy(searchBy === "pcp" ? "patient" : "pcp");
    // getToggleValue = searchBy === "pcp" ? "patient" : "pcp";
  };

  return (
    <div className={styles.container}>
      <FiIcons.FiSearch className={styles.icon} />
      <input className={styles.input} {...otherprops} />
      {isCoder && (
        <div className={styles.toggle} onClick={handleToggle}>
          {searchBy === "pcp" ? "PCP" : "Patient"}
        </div>
      )}
    </div>
  );
};

export { Search };
