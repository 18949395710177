import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { pcpOperationActions } from "../actions";
import { pcpOperationTypes } from "../types";

export function* watchDMPCPOperation() {
  yield takeLatest(pcpOperationTypes.PCP_OPERATION_REQUEST, pcpOperation);
}

function* pcpOperation(action) {
  try {
    let data = yield call(Api, {
      method: "POST",
      endpoint: endpoint.operationDMPCP,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedData = JSON.parse(data);

    if (parsedData.length > 0) {
      yield put(pcpOperationActions.pcpOperationRequestSuccess(parsedData[0]));
    } else {
      yield put(
        pcpOperationActions.pcpOperationRequestSuccess({ Result: "Error" })
      );
    }
  } catch (error) {
    yield put(pcpOperationActions.pcpOperationRequestFailure(error));
  }
}
