import { getallCmerPatientType } from "../types";

const initialState = {
  loading: false,
  getAll: []
};

export const getallCmerPatientReducer = (state = initialState, action) => {
  switch (action.type) {
    case getallCmerPatientType.GETALL_CMER_PATIENT_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case getallCmerPatientType.GETALL_CMER_PATIENT_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
        loading: false,
      };
    case getallCmerPatientType.GETALL_CMER_PATIENT_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    default:
      return state;
  }
};
