export const recommendationsTypes = {
  RECOMMENDATIONS_GET_REQUEST: "RECOMMENDATIONS_GET_REQUEST",
  RECOMMENDATIONS_GET_REQUEST_SUCCESS: "RECOMMENDATIONS_GET_REQUEST_SUCCESS",
  RECOMMENDATIONS_GET_REQUEST_FAILURE: "RECOMMENDATIONS_GET_REQUEST_FAILURE",

  RECOMMENDATIONS_OPERATION_REQUEST: "RECOMMENDATIONS_OPERATION_REQUEST",
  RECOMMENDATIONS_OPERATION_REQUEST_SUCCESS:
    "RECOMMENDATIONS_OPERATION_REQUEST_SUCCESS",
  RECOMMENDATIONS_OPERATION_REQUEST_FAILURE:
    "RECOMMENDATIONS_OPERATION_REQUEST_FAILURE",
  RECOMMENDATIONS_OPERATION_REQUEST_RESET:
    "RECOMMENDATIONS_OPERATION_REQUEST_RESET",
};
