import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { saveDynamicGaitActions } from "../actions";
import { saveDynamicGaitTypes } from "../types";

export function* watchSaveDynamicGait() {
  yield takeLatest(
    saveDynamicGaitTypes.SAVE_DYNAMIC_GAIT_REQUEST,
    saveDynamicGait
  );
}

function* saveDynamicGait(action) {
  try {
    const data = yield call(Api, {
      endpoint: `${endpoint.saveDynamicGait}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    const parsedData = JSON.parse(data);

    yield put(
      saveDynamicGaitActions.saveDynamicGaitRequestSuccess(parsedData[0])
    );
  } catch (error) {
    yield put(saveDynamicGaitActions.saveDynamicGaitRequestFailure(error));
  }
}
