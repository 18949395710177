import { call, put, takeLatest } from "redux-saga/effects";
import { endpoint } from "../../../../api/Url";
import { userPermissionActions } from "../actions/userpermission.actions";
import { Api2 } from "../../../../api/Api2";
import { userPermissionTypes } from "../types/userpermission.type";

export function* watchDMUserPermission() {
  yield takeLatest(
    userPermissionTypes.USER_PERMISSION_SAVE_REQUEST,
    saveUserPermission
  );
  yield takeLatest(
    userPermissionTypes.USER_PERMISSION_GET_REQUEST,
    getUserPermission
  );
}

//save userpermission
function* saveUserPermission(action) {
  try {
    console.log("calling saga action", action);
    let response = yield call(Api2, {
      endpoint: `${endpoint.userpermission}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    console.log("response", response);
    yield put(
      userPermissionActions.userpermissionSaveRequestSuccess(response.message)
    );
  } catch (error) {
    console.log("error", error);
    yield put(userPermissionActions.userpermissionSaveRequestFailure(error));
  }
}

function* getUserPermission(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.userpermission}?roleId=${action.request.roleId}&userId=${action.request.userId}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });
    console.log("====================================");
    console.log(response);
    console.log("====================================");

    yield put(
      userPermissionActions.userpermissionGetRequestSuccess(response.data)
    );
  } catch (error) {
    yield put(userPermissionActions.userpermissionGetRequestFailure(error));
  }
}
