export const patientType = {
  PATIENT_PAGE_REQUEST: "PATIENT_SEARCH_REQUEST",
  PATIENT_PAGE_REQUEST_SUCCESS: "PATIENT_SEARCH_REQUEST_SUCCESS",
  PATIENT_PAGE_REQUEST_FAILURE: "PATIENT_SEARCH_REQUEST_FAILURE",

  PATIENT_TYPE_REQUEST: "PATIENT_TYPE_REQUEST",
  PATIENT_TYPE_REQUEST_SUCCESS: "PATIENT_TYPE_REQUEST_SUCCESS",
  PATIENT_TYPE_REQUEST_FAILURE: "PATIENT_TYPE_REQUEST_FAILURE",

  PATIENT_FLAG_REQUEST: "PATIENT_FLAG_REQUEST",
  PATIENT_FLAG_REQUEST_SUCCESS: "PATIENT_FLAG_REQUEST_SUCCESS",
  PATIENT_FLAG_REQUEST_FAILURE: "PATIENT_FLAG_REQUEST_FAILURE",
};
