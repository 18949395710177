import { call, debounce, put, takeLatest } from "redux-saga/effects";

import {
  getallCmerPatientActions,
  getCMPatientDetailsActions,
  insuranceCompanyListLookupCmerActions,
  relationshipListLookupCmerActions,
} from "../actions";
import {
  getallCmerPatientType,
  getCMPatientDetailsType,
  insuranceCompanyListLookupCmerType,
  relationshipLookupCmerType,
} from "../types";
import { endpoint } from "../../../../api/Url";
import { Api2 } from "../../../../api/Api2";
import { alertActions } from "../../../../components/AlertNew/store/AlertNew.action";
import { updateCMPatientDetailsType } from "../types/patientDetailstype/updateCMPatientDetails";
import { insurancePlanLookupCmerActions } from "../../../cmer/actions";
import { insurancePlanLookupCmerType } from "../../../cmer/types";

export function* watchCMPatient() {
  yield takeLatest(
    getCMPatientDetailsType.GET_CM_PATIENT_DETAILS_REQUEST,
    handleGetCMPatientDetails
  );

  // above one is updated

  yield debounce(
    500,
    getallCmerPatientType.GETALL_CMER_PATIENT_REQUEST,
    handleGetallCmerPatient
  );

  yield debounce(
    500,
    relationshipLookupCmerType.RELATIONSHIP_LOOKUP_CMER_REQUEST,
    getRelationShipListLookup
  );
  yield debounce(
    500,
    insuranceCompanyListLookupCmerType.INSURANCE_COMPANY_LIST_LOOKUP_CMER_REQUEST,
    getinsuranceListLookup
  );
  yield debounce(
    500,
    insurancePlanLookupCmerType.INSURANCE_PLAN_LOOKUP_CMER_REQUEST,
    getinsurancePlanLookup
  );
  yield takeLatest(
    updateCMPatientDetailsType.UPDATE_CM_PATIENT_DETAILS_REQUEST,
    updateCMPatientDetails
  );
}
function* handleGetCMPatientDetails(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.patientDetailsCM}?admissionId=${action.query.admissionId}&patientId=${action.query.patientId}&userId=${action.query.userId}&roleId=${action.query.roleId}`,
      jwt: "Bearer " + action.token,
    });
    yield put(
      getCMPatientDetailsActions.getCMPatientDetailsSuccess(response.data)
    );
  } catch (error) {
    yield put(getCMPatientDetailsActions.getCMPatientDetailsFailure(error));
    yield put(alertActions?.alertErrorRequest(error?.message));
  }
}
function* updateCMPatientDetails(action) {
  try {
    let response = yield call(Api2, {
      method: "POST",
      endpoint: `${endpoint.patientDetailsCM}`,
      jwt: "Bearer " + action.token,
      body: action.query,
    });

    yield put(getCMPatientDetailsActions.getCMPatientDetailsSuccess(response));
  } catch (error) {
    yield put(getCMPatientDetailsActions.getCMPatientDetailsFailure(error));
    yield put(alertActions?.alertErrorRequest(error?.message));
  }
}
function* handleGetallCmerPatient(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.cmer}/patients`,
      jwt: "Bearer " + action.token,
    });

    yield put(getallCmerPatientActions.getallCmerPatientSuccess(response.data));
  } catch (error) {
    yield put(
      getallCmerPatientActions.getallCmerPatientFailure(error?.message)
    );
  }
}

function* getRelationShipListLookup(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.relationshipListLookupCmer}`,
      jwt: "Bearer " + action.token,
    });

    yield put(
      relationshipListLookupCmerActions.relationshipListLookupCmerSuccess(
        response.data
      )
    );
  } catch (error) {
    yield put(
      relationshipListLookupCmerActions.relationshipListLookupCmerFailure(error)
    );
    yield put(alertActions?.alertErrorRequest(error?.message));
  }
}
function* getinsuranceListLookup(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.insuranceListLookupCmer}`,
      jwt: "Bearer " + action.token,
    });

    yield put(
      insuranceCompanyListLookupCmerActions.insuranceListLookupCmerSuccess(
        response.data
      )
    );
  } catch (error) {
    yield put(
      insuranceCompanyListLookupCmerActions.insuranceListLookupCmerFailure(
        error
      )
    );
    yield put(alertActions?.alertErrorRequest(error?.message));
  }
}
function* getinsurancePlanLookup(action) {
  console.log(action);
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.insurancePlanLookup}?companyId=${action.request.companyId}`,
      jwt: "Bearer " + action.token,
    });
    console.log(response);
    yield put(
      insurancePlanLookupCmerActions.insurancePlanLookupCmerSuccess(response)
    );
  } catch (error) {
    yield put(
      insurancePlanLookupCmerActions.insurancePlanLookupCmerFailure(error)
    );
    yield put(alertActions?.alertErrorRequest(error?.message));
  }
}
