import { getCMPatientDetailsType } from "../../types";

const initialState = {
  loading: false,
  getAll: [],
};

export const getCMPatientDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case getCMPatientDetailsType.GET_CM_PATIENT_DETAILS_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case getCMPatientDetailsType.GET_CM_PATIENT_DETAILS_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
        loading: false,
      };
    case getCMPatientDetailsType.GET_CM_PATIENT_DETAILS_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    default:
      return state;
  }
};
