import { chargesInputType } from "../types";
export const chargesInputActions = {
  chargesInputRequest,
  chargesInputRequestReset,
  chargesInputRequestSuccess,
  chargesInputRequestFailure,
};

function chargesInputRequest(request, token) {
  return { type: chargesInputType.CHARGES_INPUT_REQUEST, request, token };
}
function chargesInputRequestReset() {
  return { type: chargesInputType.CHARGES_INPUT_REQUEST_RESET };
}
function chargesInputRequestSuccess(inputs) {
  return {
    type: chargesInputType.CHARGES_INPUT_REQUEST_SUCCESS,
    inputs,
  };
}
function chargesInputRequestFailure(error) {
  return { type: chargesInputType.CHARGES_INPUT_REQUEST_FAILURE, error };
}
