import { addTaskType } from "../types/addTask.type";

const initialState = {
  loading: false,
};

export const addTaskReducer = (state = initialState, action) => {
  switch (action.type) {
    case addTaskType.ADD_TASK_REQUEST:
      return { ...initialState, loading: true };
    case addTaskType.ADD_TASK_REQUEST_SUCCESS:
      return {
        ...initialState,
        message: action.message,
      };
    case addTaskType.ADD_TASK_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    case addTaskType.ADD_TASK_REQUEST_RESET:
      return { ...initialState };
    //  return { ...initialState,error: action.error };
    default:
      return state;
  }
};
