import {userRoleTypes} from "../types";

export const userRoleActions = {
    userRoleRequest,
    userRoleRequestSuccess,
    userRoleRequestFailure,
  };
  function userRoleRequest(request, token) {
    return {
      type: userRoleTypes.USER_ROLE_REQUEST,
      request,
      token,
    };
  }
  function userRoleRequestSuccess(getAll) {
    return {
      type: userRoleTypes.USER_ROLE_REQUEST_SUCCESS,
      getAll,
    };
  }
  function userRoleRequestFailure(error) {
    return {
      type: userRoleTypes.USER_ROLE_REQUEST_FAILURE,
      error,
    };
  }
  