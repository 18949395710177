import { put, call, takeLatest } from "redux-saga/effects";
import { endpoint } from "../../../../../api/Url";
import { pcpOfficeActions } from "../actions/pcpoffice.action";
import { Api2 } from "../../../../../api/Api2";
import { pcpOfficeTypes } from "../types/pcpoffice.type";

export function* watchDMPCPOffice() {
  yield takeLatest(pcpOfficeTypes.PCP_OFFICE_REQUEST, getAllPCPOffice);
  yield takeLatest(pcpOfficeTypes.PCP_OFFICE_SAVE_REQUEST, createPCPOffice);
  yield takeLatest(pcpOfficeTypes.PCP_OFFICE_EDIT_REQUEST, editPCPOffice);
  yield takeLatest(
    pcpOfficeTypes.PCP_OFFICE_UPDATE_STATUS_REQUEST,
    updatePCPOfficeStatus
  );
}

//getAll pcpoffice

function* getAllPCPOffice(action) {
  try {
    let res = yield call(Api2, {
      endpoint: `${endpoint.pcpoffice}?nationId=${action.query.nationId}&regionId=${action.query.regionId}&ipaId=${action.query.ipaId}&pcpGroupId=${action.query.pcpGroupId}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });
    if (res.data !== undefined && res.data.length > 0) {
      yield put(pcpOfficeActions.pcpofficeRequestSuccess(res.data));
    } else {
      yield put(pcpOfficeActions.pcpofficeEditRequestFailure("No data found"));
    }
  } catch (error) {
    console.log(error, "error");
    yield put(pcpOfficeActions.pcpofficeEditRequestFailure(error));
  }
}

//createpcpoffice

function* createPCPOffice(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.pcpoffice}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    yield put(pcpOfficeActions.pcpofficeSaveRequestSuccess(response.message));
  } catch (error) {
    yield put(pcpOfficeActions.pcpofficeSaveRequestFailure(error));
  }
}

//update /edit office
function* editPCPOffice(action) {
  try {
    console.log("action", action);
    let response = yield call(Api2, {
      endpoint: `${endpoint.pcpoffice}/${action.query}`,
      method: "PATCH",
      jwt: "Bearer " + action.token,
      body: action.payload,
    });

    yield put(pcpOfficeActions.pcpofficeEditRequestSuccess(response.message));
  } catch (error) {
    yield put(pcpOfficeActions.pcpofficeSaveRequestFailure(error));
  }
}

//update office

function* updatePCPOfficeStatus(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.pcpoffice}/${action.query.pcpofficeId}`,
      method: "PUT",
      jwt: "Bearer " + action.token,
      body: action.payload,
    });
    yield put(
      pcpOfficeActions.pcpofficeUpdateStatusRequestSuccess(
        action.query,
        action.payload,
        response.message
      )
    );
  } catch (error) {
    console.log(error, "error");
    yield put(pcpOfficeActions.pcpofficeUpdateStatusRequestFailure(error));
  }
}
