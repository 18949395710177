import { bundleSaveTypes } from "../types";

export const bundleSaveActions = {
    bundleSaveRequest,
    bundleSaveRequestReset,
    bundleSaveRequestSuccess,
    bundleSaveRequestFailure,
  };
  function bundleSaveRequest(request, token) {
    return {
      type: bundleSaveTypes.BUNDLE_SAVE_REQUEST,
      request,
      token,
    };
  }
  function bundleSaveRequestReset() {
    return {
      type: bundleSaveTypes.BUNDLE_SAVE_REQUEST_RESET,
    };
  }
  function bundleSaveRequestSuccess(message) {
    return {
      type: bundleSaveTypes.BUNDLE_SAVE_REQUEST_SUCCESS,
      message,
    };
  }
  function bundleSaveRequestFailure(error) {
    return {
      type: bundleSaveTypes.BUNDLE_SAVE_REQUEST_FAILURE,
      error,
    };
  }
  