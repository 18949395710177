import { preventDuplicatePatientAddBillingType } from "../types";

export const preventDuplicatePatientAddBillingActions = {
  preventDuplicatePatientAddBillingRequest,
  preventDuplicatePatientAddBillingSuccess,
  preventDuplicatePatientAddBillingFailure,
  preventDuplicatePatientAddBillingReset,
};
function preventDuplicatePatientAddBillingRequest(request, token) {
  return {
    type: preventDuplicatePatientAddBillingType.PREVENT_DUPLICATE_PATIENT_ADD_BILLING_REQUEST,
    request,
    token,
  };
}
function preventDuplicatePatientAddBillingSuccess(data, message) {
 
  return {
    type: preventDuplicatePatientAddBillingType.PREVENT_DUPLICATE_PATIENT_ADD_BILLING_SUCCESS,
    message,
    data,
  };
}
function preventDuplicatePatientAddBillingFailure(error) {
  return {
    type: preventDuplicatePatientAddBillingType.PREVENT_DUPLICATE_PATIENT_ADD_BILLING_FAILURE,
    error,
  };
}
function preventDuplicatePatientAddBillingReset() {
  return {
    type: preventDuplicatePatientAddBillingType.PREVENT_DUPLICATE_PATIENT_ADD_BILLING_RESET,
  };
}
