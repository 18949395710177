import { call, put, takeLatest } from "@redux-saga/core/effects";
import { conversationListActions } from "../actions";
import { conversationListTypes } from "../types";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";

export function* watchPatientConversationList() {
  yield takeLatest(
    conversationListTypes.CONVERSATION_LIST_REQUEST,
    getConversationList
  );
}

function* getConversationList(action) {
  try {
    let conversationsList = yield call(Api, {
      endpoint: endpoint.getNurseChatHistory,
      method: "POST",
      jwt: "bearer " + action.token,
    });

    let parsedConversationList = JSON.parse(conversationsList);

    yield put(
      conversationListActions.conversationListRequestSuccess(
        parsedConversationList
      )
    );
  } catch (error) {
    yield put(conversationListActions.conversationListRequestFailure(error));
  }
}
