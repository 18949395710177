import { insurancePlanOperationTypes } from "../types";

const initiaLState = {
  loading: false,
};

export const insurancePlanOperationDMReducer = (
  state = initiaLState,
  action
) => {
  switch (action.type) {
    case insurancePlanOperationTypes.INSURANCE_PLAN_OPERATION_REQUEST:
      return {
        ...initiaLState,
        loading: true,
      };
    case insurancePlanOperationTypes.INSURANCE_PLAN_OPERATION_REQUEST_RESET:
      return {
        ...initiaLState,
      };
    case insurancePlanOperationTypes.INSURANCE_PLAN_OPERATION_REQUEST_SUCCESS:
      return {
        ...initiaLState,
        message: action.message,
      };
    case insurancePlanOperationTypes.INSURANCE_PLAN_OPERATION_REQUEST_FAILURE:
      return {
        ...initiaLState,
        error: action.error,
      };
    default:
      return state;
  }
};
