import { put, call, takeLatest } from "@redux-saga/core/effects";
import { printTypes } from "../types/print.type";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { printActions } from "../actions";
export function* watchPrint() {
  yield takeLatest(printTypes.PRINT_REQUEST, getPrintData);
}

function* getPrintData(action) {
  try {
    let reportData = yield call(Api, {
      method: "POST",
      endpoint: `${endpoint.getPatientDetailsForPrint}?patientID=${action.request.patientID}&AdmissionID=${action.request.admissionID}`,
      jwt: "Bearer " + action.token,
    });

    const parsedReportData = JSON.parse(reportData);

    yield put(
      printActions.printRequestSuccess(
        parsedReportData.patient,
        parsedReportData.familyHistory,
        parsedReportData.socialInfo,
        parsedReportData.allergies,
        parsedReportData.currentMedication,
        parsedReportData.screenList,
        parsedReportData.biometricList,
        parsedReportData.scrrenotherCommentText
      )
    );
  } catch (error) {
    yield put(printActions.printRequestFailure(error));
  }
}
