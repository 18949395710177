import React, { useState, useEffect } from "react";
import styles from "./Diagnosis.module.scss";
import { strings } from "./strings";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  advanceCarePlanLookupCmerActions,
  diagnosisLookupCmerActions,
  getClinicalInformationCmActions,
  // getClinicalInformationCmerActions,
  pcpLookupCmerActions,
  updateClinicalInformationCmActions,
} from "./actions";
import { Progress } from "../../../components";

import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Radio from "@mui/material/Radio";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { colors } from "../../../themes/colors";
import Icons from "../../../components/NavBar2/Icons";
import ConfirmDialog from "../../../components/Dialog";
import RadioComp from "./Components/RadioGroup";
import { Spinner } from "../../../core";
import { alertActions } from "../../../components/AlertNew/store/AlertNew.action";

const ClinicalInformationCM = () => {
  const dispatch = useDispatch();
  const { patientId } = useParams();
  const { state } = useLocation();
  const userData = useSelector((state) => state.login.userData);
  const [isFormEdited, setIsFormEdited] = useState(false);
  const pcpLookupCmer = useSelector((state) => state.pcpLookupCmer);
  const getClinicalInfoCm = useSelector((state) => state.getClinicalInfoCm);
  const updateClinicalInfoCm = useSelector(
    (state) => state.updateClinicalInfoCm
  );

  const advanceCarePlanLookupCmer = useSelector(
    (state) => state.advanceCarePlanLookupCmer
  );
  const diagnosisLookupCmer = useSelector((state) => state.diagnosisLookupCmer);

  const [diagnosisId, setDiagnosisId] = useState("");
  const [diagnosisList, setDiagnosisList] = useState([]);

  const [errorDiagnosis, setErrorDiagnosis] = useState(false);
  const [errorDiagnosislist, setErrorDiagnosislist] = useState(false);

  // !
  useEffect(() => {
    if (userData.token !== undefined) {
      let request = {
        AdmissionID: patientId,
      };
      dispatch(
        advanceCarePlanLookupCmerActions.advanceCarePlanLookupRequest(
          request,
          userData.token
        )
      );
    }
    setFormData({ ...formKeys });
    // eslint-disable-next-line
  }, []);

  const validate = () => {
    let isError = false;
    let error = {};
    if (formData?.diagnosis?.length === 0) {
      error.diagnosis = "Diagnosis is Required";
      isError = true;
    } else {
      delete error.diagnosis;
    }
    if (formData?.pcp?.length === 0) {
      error.pcp = "Pcp is Required";
      isError = true;
    } else {
      delete error.pcp;
    }
    if (formData?.smoking?.smokingStatus) {
      if (
        formData?.smoking?.smokingBehavior === "" ||
        formData?.smoking?.smokingDurationValue === "" ||
        formData?.smoking?.smokingDurationUnit === "" ||
        formData?.smoking?.packsPerDurationValue === "" ||
        formData?.smoking?.packsPerDurationUnit === ""
      ) {
        error.smoking = "Required";
        isError = true;
      }
    } else {
      delete error.smoking;
    }
    if (formData?.alcohol?.alcoholicStatus) {
      if (formData?.alcohol?.alcoholicBehavior === "") {
        error.alcohol = "Required";
        isError = true;
      }
    } else {
      delete error.alcohol;
    }
    if (formData?.drugs?.drugsStatus) {
      if (
        formData?.drugs?.drugsBehavior === "" ||
        formData?.drugs?.drugDurationValue === "" ||
        formData?.drugs?.drugDurationUnit === "" ||
        formData?.drugs?.drugName === ""
      ) {
        error.drugs = "Required";
        isError = true;
      }
    } else {
      delete error.drugs;
    }
    if (formData?.pcp?.length === 0) {
      error.pcp = "Pcp is Required";
      isError = true;
    } else {
      delete error.pcp;
    }
    setErrors(error);
    return isError;
  };
  const formKeys = {
    diagnosis: [],
    pcp: [],
    hospice: false,
    acp: null,
    smoking: {
      smokingStatus: false,
      smokingBehavior: "",
      smokingDurationUnit: "",
      smokingDurationValue: "",
      packsPerDurationUnit: "",
      packsPerDurationValue: "",
    },
    alcohol: {
      alcoholicStatus: false,
      alcoholicBehavior: "",
    },
    drugs: {
      drugsStatus: false,
      drugsBehavior: "",
      drugDurationUnit: "",
      drugDurationValue: "",
      drugName: "",
    },
  };
  const [formData, setFormData] = useState();
  const [errors, setErrors] = useState(null);
  //!

  const handleSaveClinicalInfo = async () => {
    // if (validate()) return;
    const { smoking, alcohol, pcp, drugs, hospice, diagnosis, acp } = formData;
    console.log(formData);
    const body = {
      PatientDCDx: diagnosis
        ? diagnosis.map((item) => item?.ICDCodeId).join(",")
        : "",
      Consultants: pcp ? pcp.map((item) => item?.PCPID).join(",") : "",
      Smoking: smoking?.smokingStatus || false,
      SmokingActivePast: smoking?.smokingStatus
        ? smoking?.smokingBehavior === "Active"
          ? true
          : false
        : false,
      HowLongSmoking:
        (smoking?.smokingStatus && `${smoking?.smokingDurationValue}`) || "0",
      DayMonthYearSmoking:
        (smoking?.smokingStatus && `${smoking?.smokingDurationUnit}`) || "0",
      Packs:
        (smoking?.smokingStatus && `${smoking?.packsPerDurationValue}`) || "0",
      DayMonthYearPacks:
        (smoking?.smokingStatus && smoking?.packsPerDurationUnit) || "0",
      Alochalic: alcohol?.alcoholicStatus || false,
      DialyOccasionally:
        alcohol?.alcoholicStatus && alcohol?.alcoholicBehavior === "Daily"
          ? true
          : false,
      Drugs: drugs?.drugsStatus || false,
      DrugsActivePast:
        drugs?.drugsStatus && drugs?.drugsBehavior === "Active" ? true : false,
      DrugsMeds: (drugs?.drugsStatus && drugs?.drugName) || "0",
      HowLongDrugs:
        (drugs?.drugsStatus && `${drugs?.drugDurationValue}`) || "0",
      DayMonthYearDrugs: (drugs?.drugsStatus && drugs?.drugDurationUnit) || "0",
      IsHospice: hospice,
      ACPlanId: acp?.ACPlanId ? `${acp?.ACPlanId}` : 0,
      EduQualification: "0",
      CurrentEmployment: "0",
      CreatedBy: userData?.UserID,
    };

    let request = {
      admissionId: state?.patientDetails1?.parentAdmissionId,
      patientId: state?.patientDetails1?.PatientId,
    };
    if (!updateClinicalInfoCm?.loading && state) {
      dispatch(
        updateClinicalInformationCmActions?.updateClinicalInfoCmRequest(
          request,
          body,
          userData?.token
        )
      );
    }
  };

  useEffect(() => {
    if (updateClinicalInfoCm?.message !== "") {
      dispatch(updateClinicalInformationCmActions?.updateClinicalInfoCmReset());
      getPaitentClinicalInfo();
    }
  }, [updateClinicalInfoCm?.message]);

  //Getall pcp
  const getPcp = (e, val) => {
    if (val.length > 2) {
      if (userData.token !== undefined) {
        let request = {
          search: val,
        };
        dispatch(
          pcpLookupCmerActions.pcpLookupRequest(request, userData.token)
        );
      }
    }
  };
  const defaultFormData = () => {
    const selectedPcp = getClinicalInfoCm?.Consultant;
    let { socialInfo } = getClinicalInfoCm;
    setFormData((prev) => ({
      ...prev,
      diagnosis: getClinicalInfoCm?.DcDiagnosis,
      pcp: selectedPcp,
      hospice: socialInfo[0]?.IsHospice ? true : false,
      acp: advanceCarePlanLookupCmer?.getAll?.find(
        (item) => item.ACPlanId === socialInfo[0]?.ACPlanId
      ),
      smoking: {
        smokingStatus: getClinicalInfoCm?.socialInfo[0]?.Smoking,
        smokingBehavior:
          socialInfo && socialInfo.length > 0
            ? socialInfo[0]?.SmokingActivePast === true
              ? "Active"
              : "Past"
            : "",
        smokingDurationUnit:
          socialInfo[0]?.DayMonthYearSmoking === "0"
            ? ""
            : socialInfo[0]?.DayMonthYearSmoking,
        smokingDurationValue: socialInfo[0]?.HowLongSmoking || "",
        packsPerDurationUnit:
          socialInfo[0]?.DayMonthYearPacks === "0"
            ? ""
            : socialInfo[0]?.DayMonthYearPacks,
        packsPerDurationValue: socialInfo[0]?.Packs,
      },
      alcohol: {
        alcoholicStatus: socialInfo[0]?.Alochalic,
        alcoholicBehavior: socialInfo[0]?.DialyOccasionally
          ? "Daily"
          : "Occasionally",
      },
      drugs: {
        drugsStatus: socialInfo[0]?.Drugs,
        drugsBehavior: socialInfo
          ? socialInfo[0]?.DrugsActivePast === true
            ? "Active"
            : "Past"
          : "",
        drugDurationUnit: socialInfo ? socialInfo[0]?.DayMonthYearDrugs : "",
        drugDurationValue: socialInfo[0]?.HowLongDrugs || "",
        drugName: socialInfo[0]?.DrugsMeds || "",
      },
    }));
  };
  useEffect(() => {
    defaultFormData();
  }, [getClinicalInfoCm?.getAll]);
  //Getall diagnosis
  const getDiagnosis = (event, val) => {
    if (val.length > 2) {
      if (userData.token !== undefined) {
        let request = {
          searchStr: val,
        };

        dispatch(
          diagnosisLookupCmerActions.diagnosisLookupRequest(
            request,
            userData.token
          )
        );
      }
    }
  };
  //Getall diagnosis

  //multi pcp ids selected
  //multi diagnosis ids selected
  const diagnosisMap = new Map();
  const selectedDiagnosis = (selectedDiagnosis) => {
    for (let i in selectedDiagnosis) {
      diagnosisMap.set(
        selectedDiagnosis[i].ICDCodeId,
        selectedDiagnosis[i].Descriptions
      );
    }
    let tempDiagnosis = "";
    for (const [key] of diagnosisMap.entries()) {
      tempDiagnosis = key + ";" + tempDiagnosis;
    }
    setDiagnosisId(tempDiagnosis);
  };

  const getPaitentClinicalInfo = () => {
    let query = {
      admissionId: state?.patientDetails1?.parentAdmissionId,
      patientId: state?.patientDetails1?.PatientId,
    };
    dispatch(
      getClinicalInformationCmActions.getClinicalInfoCmRequest(
        query,
        userData?.token
      )
    );
    setErrors([]);
  };
  useEffect(() => {
    getPaitentClinicalInfo();
  }, []);

  useEffect(() => {
    selectedDiagnosis(diagnosisList);
  }, [diagnosisList]);

  const [isDeleteDiagnosis, setIsDeleteDiagnosis] = useState({
    open: false,
    details: null,
  });

  const deleteDiagnosis = (row) => {
    setIsDeleteDiagnosis({
      open: true,
      details: row,
    });
  };
  const handleDialogClose = () => {
    setFormData({
      ...formData,
      diagnosis: formData?.diagnosis?.filter(
        (val) => val?.ICDCodeId !== isDeleteDiagnosis?.details?.ICDCodeId
      ),
    });

    setIsDeleteDiagnosis({
      open: false,
      details: null,
    });
  };
  const handleDiscard = () => {
    setIsDeleteDiagnosis(false);
  };
  const handleReset = () => {
    defaultFormData();
  };
  const [selectedValue, setSelectedValue] = useState(null);

  return (
    <>
      {isDeleteDiagnosis?.details && (
        <ConfirmDialog
          open={isDeleteDiagnosis?.open}
          handleDialogClose={handleDialogClose}
          handleDiscard={handleDiscard}
        />
      )}
      {getClinicalInfoCm.loading ? (
        <Progress />
      ) : (
        <>
          <Grid container>
            <Grid container item alignItems={"start"} spacing={2}>
              <Grid container item>
                <Grid item xs={2} alignItems={"center"}>
                  {" "}
                  <Typography>Diagnosis</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Grid item>
                    <Autocomplete
                      sx={{
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: "none" },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                          maxWidth: 300,
                        },

                        background: colors.color_background,
                      }}
                      blurOnSelect
                      size="small"
                      onInputChange={getDiagnosis}
                      label={strings.DIAGNOSIS}
                      value={null}
                      options={diagnosisLookupCmer.getAll}
                      isMulti={false}
                      getOptionLabel={(option) => `${option.CodeDescription}`}
                      getOptionValue={(option) => option.ICDCodeId}
                      onChange={(event, selected) => {
                        if (
                          selected !== null &&
                          !formData?.diagnosis?.some(
                            (val) => val?.ICDCodeId === selected?.ICDCodeId
                          )
                        ) {
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            diagnosis: [...prevFormData?.diagnosis, selected],
                          }));
                        } else {
                          let error = { ...errors };
                          error.diagnosis = "Diagnosis Already Exists";
                          dispatch(
                            alertActions?.alertErrorRequest(error?.diagnosis)
                          );
                        }
                      }}
                      loading={diagnosisLookupCmer.loading}
                      id="combo-box-demo"
                      renderInput={(params) => (
                        <TextField {...params} label="" size="small" error />
                      )}
                    />{" "}
                    <p className={styles.error}>{errors?.diagnosis}</p>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ width: "100%" }}
                        size="small"
                        aria-label="simple table"
                      >
                        <TableHead
                          sx={{ backgroundColor: colors?.color_primary_txt }}
                        >
                          <TableRow>
                            <TableCell sx={{ color: colors?.color_background }}>
                              Diagnosis
                            </TableCell>
                            <TableCell
                              sx={{ color: colors?.color_background }}
                              align="right"
                            >
                              Delete
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {formData?.diagnosis?.length > 0 ? (
                            formData?.diagnosis?.map((row) => (
                              <TableRow
                                key={row.name}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.CodeDescription}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="right"
                                >
                                  <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                      deleteDiagnosis(row);
                                    }}
                                    size={"small"}
                                  >
                                    <Icons name="MdDelete" />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ color: colors?.color_light_txt }}
                              >
                                {"No Diagnosis Selected"}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <p className={styles.error}>
                      {errorDiagnosislist ? "Already exist!" : ""}
                    </p>
                    <p className={styles.error}>
                      {errorDiagnosis ? "No selected diagnosis" : ""}
                    </p>{" "}
                  </Grid>
                </Grid>{" "}
              </Grid>
              <Grid container item>
                <Grid item xs={2} alignItems={"center"}>
                  <Typography>Consultant</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Autocomplete
                    size="small"
                    sx={{
                      boxShadow: "none",
                      ".MuiOutlinedInput-notchedOutline": { border: "none" },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        maxWidth: 300,
                      },

                      background: colors.color_background,
                    }}
                    onInputChange={getPcp}
                    options={pcpLookupCmer?.getAll}
                    value={formData?.pcp || []}
                    multiple
                    ChipProps={{
                      sx: {
                        backgroundColor: colors?.color_primary,
                        color: colors?.color_primary_txt,
                      },
                    }} // Added curly braces around sx
                    getOptionLabel={(option) => option.PCP}
                    getOptionValue={(option) => option.PCPID}
                    onChange={(event, selected) => {
                      setFormData({
                        ...formData,
                        pcp: selected,
                      });
                    }}
                    loading={pcpLookupCmer?.loading}
                    id="pcp"
                    renderInput={(params) => <TextField {...params} label="" />}
                  />
                  <p className={styles.error}>{errors?.pcp}</p>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider
                  sx={{ backgroundColor: colors?.color_light_txt }}
                  col
                />
              </Grid>
              <Grid container item xs={12}>
                <Typography
                  variant="subtitle1"
                  component="span"
                  sx={{ fontWeight: "bold" }}
                >
                  Social History
                </Typography>
              </Grid>
              <Grid container item>
                <Grid item xs={2}>
                  {" "}
                  <Typography>Smoking</Typography>
                </Grid>

                <Grid item xs={10} container>
                  <Grid item xs={2}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      onChange={(e, v) => {
                        const newValue = v === "Yes" ? true : false;

                        setFormData({
                          ...formData,
                          smoking: newValue
                            ? { ...formData.smoking, smokingStatus: newValue }
                            : { ...formKeys?.smoking, smokingStatus: newValue },
                        });
                      }}
                      value={formData?.smoking?.smokingStatus ? "Yes" : "No"}
                    >
                      <FormControlLabel
                        value={"Yes"}
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={"No"}
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </Grid>
                  {formData?.smoking?.smokingStatus && (
                    <>
                      <Grid item xs={3}>
                        <RadioComp
                          value={formData?.smoking?.smokingBehavior}
                          onRadioChange={(e, v) => {
                            setFormData({
                              ...formData,
                              smoking: {
                                ...formData["smoking"],
                                smokingBehavior: v,
                              },
                            });
                          }}
                          options={[
                            {
                              value: "Active",
                              label: "Active",
                            },
                            {
                              value: "Past",
                              label: "Past",
                            },
                          ]}
                        />
                      </Grid>

                      <Grid
                        item
                        container
                        xs={7}
                        spacing={1}
                        alignItems={"start"}
                      >
                        <Grid item xs={7} alignItems={"center"}>
                          <Typography>
                            How Long you have been smoking?
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            fullWidth
                            label=""
                            value={
                              formData?.smoking?.smokingDurationValue || "0"
                            }
                            onChange={(event) => {
                              let newVal = event.target.value;
                              setFormData({
                                ...formData,
                                smoking: {
                                  ...formData["smoking"],
                                  smokingDurationValue: newVal,
                                },
                              });
                            }}
                            sx={{
                              boxShadow: "none",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "none",
                                },

                              background: colors.color_background,
                            }}
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                              formData?.smoking?.smokingDurationUnit || "Month"
                            }
                            onChange={(event) => {
                              let newVal = event.target.value;
                              setFormData({
                                ...formData,
                                smoking: {
                                  ...formData["smoking"],
                                  smokingDurationUnit: newVal,
                                },
                              });
                            }}
                            sx={{
                              width: "fit-content",
                              boxShadow: "none",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "none",
                                },

                              background: colors.color_background,
                            }}
                            size="small"
                          >
                            <MenuItem value={"Year"}>Year(s)</MenuItem>
                            <MenuItem value={"Month"}>Month(s)</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={7} alignItems={"center"}>
                          <Typography>How many Packs?</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            fullWidth
                            label=""
                            value={
                              formData?.smoking?.packsPerDurationValue || "0"
                            }
                            onChange={(event) => {
                              let newVal = event.target.value;
                              setFormData({
                                ...formData,
                                smoking: {
                                  ...formData["smoking"],
                                  packsPerDurationValue: newVal,
                                },
                              });
                            }}
                            sx={{
                              boxShadow: "none",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "none",
                                },

                              background: colors.color_background,
                            }}
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                              formData?.smoking?.packsPerDurationUnit || "Month"
                            }
                            onChange={(event) => {
                              let newVal = event.target.value;
                              setFormData({
                                ...formData,
                                smoking: {
                                  ...formData["smoking"],
                                  packsPerDurationUnit: newVal,
                                },
                              });
                            }}
                            sx={{
                              width: "fit-content",
                              boxShadow: "none",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "none",
                                },

                              background: colors.color_background,
                            }}
                            size="small"
                          >
                            <MenuItem value={"Year"}>Year(s)</MenuItem>
                            <MenuItem value={"Month"}>Month(s)</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </>
                  )}{" "}
                  <p className={styles.error}>{errors?.smoking}</p>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={2}>
                  {" "}
                  <Typography>Alcohol Use</Typography>
                </Grid>

                <Grid item xs={10} container>
                  <Grid item xs={2}>
                    <RadioComp
                      value={formData?.alcohol?.alcoholicStatus ? "Yes" : "No"}
                      onRadioChange={(e, v) => {
                        const newValue = v === "Yes" ? true : false;

                        setFormData({
                          ...formData,
                          alcohol: {
                            ...formData.alcohol,
                            alcoholicStatus: newValue,
                            alcoholicBehavior: "",
                          },
                        });
                      }}
                      options={[
                        {
                          value: "Yes",
                          label: "Yes",
                        },
                        {
                          value: "No",
                          label: "No",
                        },
                      ]}
                    />
                  </Grid>
                  {formData?.alcohol?.alcoholicStatus && (
                    <>
                      <Grid item xs={3}>
                        <RadioComp
                          value={formData?.alcohol?.alcoholicBehavior || "Past"}
                          onRadioChange={(e, v) => {
                            setFormData({
                              ...formData,
                              alcohol: {
                                ...formData.alcohol,
                                alcoholicBehavior: v,
                              },
                            });
                          }}
                          options={[
                            {
                              value: "Daily",
                              label: "Daily",
                            },
                            {
                              value: "Occasionally",
                              label: "Occasionally",
                            },
                          ]}
                        />
                      </Grid>
                    </>
                  )}
                  <p className={styles.error}>{errors?.alcohol}</p>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={2}>
                  {" "}
                  <Typography>Drugs</Typography>
                </Grid>

                <Grid item xs={10} container>
                  <Grid item xs={2}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      onChange={(e, v) => {
                        const newValue = v === "Yes" ? true : false;

                        setFormData({
                          ...formData,
                          drugs: newValue
                            ? { ...formData.drugs, drugsStatus: newValue }
                            : { ...formKeys?.drugs, drugsStatus: newValue },
                        });
                      }}
                      value={formData?.drugs?.drugsStatus ? "Yes" : "No"}
                    >
                      <FormControlLabel
                        value={"Yes"}
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={"No"}
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </Grid>
                  {formData?.drugs?.drugsStatus && (
                    <>
                      <Grid item xs={3}>
                        <RadioComp
                          value={formData?.drugs?.drugsBehavior}
                          onRadioChange={(e, v) => {
                            setFormData({
                              ...formData,
                              drugs: {
                                ...formData?.drugs,
                                drugsBehavior: v,
                              },
                            });
                          }}
                          options={[
                            {
                              value: "Active",
                              label: "Active",
                            },
                            {
                              value: "Past",
                              label: "Past",
                            },
                          ]}
                        />
                      </Grid>

                      <Grid
                        item
                        container
                        xs={7}
                        spacing={1}
                        alignItems={"start"}
                      >
                        <Grid item xs={7} alignItems={"center"}>
                          <Typography>How Long you have been using?</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            fullWidth
                            label=""
                            value={formData?.drugs?.drugDurationValue || "0"}
                            onChange={(event) => {
                              let newVal = event.target.value;
                              setFormData({
                                ...formData,
                                drugs: {
                                  ...formData?.drugs,
                                  drugDurationValue: newVal,
                                },
                              });
                            }}
                            sx={{
                              boxShadow: "none",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "none",
                                },

                              background: colors.color_background,
                            }}
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData?.drugs?.drugDurationUnit || "Month"}
                            onChange={(event) => {
                              let newVal = event.target.value;
                              setFormData({
                                ...formData,
                                drugs: {
                                  ...formData?.drugs,
                                  drugDurationUnit: newVal,
                                },
                              });
                            }}
                            sx={{
                              width: "fit-content",
                              boxShadow: "none",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "none",
                                },

                              background: colors.color_background,
                            }}
                            size="small"
                          >
                            <MenuItem value={"Year"}>Year(s)</MenuItem>
                            <MenuItem value={"Month"}>Month(s)</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={7} alignItems={"center"}>
                          <Typography>What Drugs/Meds</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <TextareaAutosize
                            value={formData?.drugs?.drugName || ""}
                            style={{
                              width: "fit-content",
                              boxShadow: "none",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "none",
                                },

                              background: colors.color_background,
                            }}
                            aria-label="minimum height"
                            minRows={3}
                            placeholder="Drug name"
                            onChange={(event) => {
                              let newVal = event.target.value;
                              setFormData({
                                ...formData,
                                drugs: {
                                  ...formData?.drugs,
                                  drugName: newVal,
                                },
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <p className={styles.error}>{errors?.drugs}</p>
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={2} alignItems={"center"}>
                  {" "}
                  <Typography>Advance Care Planning</Typography>
                </Grid>
                <Grid item xs={10} container spacing={1}>
                  <Grid item xs={4}>
                    <Autocomplete
                      size="small"
                      sx={{
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: "none" },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                          maxWidth: 300,
                        },

                        background: colors.color_background,
                      }}
                      label={strings.DIAGNOSIS}
                      options={advanceCarePlanLookupCmer?.getAll || []}
                      value={formData?.acp || null}
                      isMulti={false}
                      getOptionLabel={(option) => `${option.ACPlanName}`}
                      getOptionValue={(option) => option.ACPlanId}
                      onChange={(event, selected) => {
                        setFormData({
                          ...formData,
                          acp: selected,
                        });
                      }}
                      loading={advanceCarePlanLookupCmer?.loading}
                      id="combo-box-demo"
                      renderInput={(params) => (
                        <TextField {...params} label="" />
                      )}
                    />{" "}
                  </Grid>
                  <Grid item>
                    {" "}
                    <FormControlLabel
                      labelPlacement="end"
                      control={
                        <Checkbox
                          checked={formData?.hospice}
                          onChange={(e, isChecked) => {
                            setFormData({
                              ...formData,
                              hospice: isChecked,
                            });
                          }}
                          name="Hospice"
                        />
                      }
                      label="Hospice"
                    />
                  </Grid>
                </Grid>{" "}
              </Grid>
              <Grid container item>
                <Grid item>
                  <Button
                    variant="outlined"
                    sx={{
                      mr: 1,
                      textTransform: "capitalize",
                      fontWeight: "bold",
                      borderColor: colors.color_light_txt,
                      background: colors.color_light_txt,
                      color: colors.color_primary,
                      "&:hover": {
                        borderColor: colors.color_primary_txt,
                        color: colors.color_primary_txt,
                      },
                    }}
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    // disabled={errors?.length !== 0}
                    variant="outlined"
                    sx={{
                      background: colors.color_accent,
                      color: colors.color_primary,
                      textTransform: "capitalize",
                      fontWeight: "bold",
                      ml: 1,
                      "&:hover": {
                        background: colors.color_primary_txt,
                        color: colors.color_primary,
                      },
                      "&:disabled": {
                        background: colors.color_primary_txt,
                        color: colors.color_primary,
                        opacity: 0.7,
                        cursor: "no-drop",
                      },
                    }}
                    onClick={handleSaveClinicalInfo}
                    // onClick={handleSubmit}
                  >
                    {updateClinicalInfoCm.loading ? (
                      <>
                        <Spinner width={18} height={18} />
                        <span style={{ marginLeft: "6px" }}>
                          {state ? "Updating" : "Adding"}
                        </span>
                      </>
                    ) : (
                      <span style={{ marginLeft: "6px" }}>
                        {state ? "Update" : "Add"}
                      </span>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default ClinicalInformationCM;
