import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { getMsoActions } from "../actions";
import { getMsoTypes } from "../types";

export function* watchDMMsoDetails() {
    yield takeLatest(getMsoTypes.GET_MSO_REQUEST, getMso);
  }
  
  function* getMso(action) {
    try {
      let data = yield call(Api, {
        method: "POST",
        endpoint: endpoint.getDMMsoDetails,
        jwt: "Bearer " + action.token,
        body: action.request,
      });
      let parsedData = JSON.parse(data);
  
      yield put(getMsoActions.msoRequestSuccess(parsedData[0]));
    } catch (error) {
      yield put(getMsoActions.msoRequestFailure(error));
    }
  }
  