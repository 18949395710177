import { ipaTypes } from "../types/ipa.type";

export const ipaActions = {
  ipaRequest,
  ipaRequestSuccess,
  ipaRequestFailure,
  ipaSaveRequest,
  ipaSaveRequestSuccess,
  ipaSaveRequestFailure,
  ipaEditRequest,
  ipaEditRequestSuccess,
  ipaEditRequestFailure,
  ipaUpdateStatusRequest,
  ipaUpdateStatusRequestSuccess,
  ipaUpdateStatusRequestFailure,
  ipaRequestReset,
};

//Getall IPA
function ipaRequest(query, token) {
  return {
    type: ipaTypes.IPA_REQUEST,
    query,
    token,
  };
}
function ipaRequestSuccess(getAll) {
  return {
    type: ipaTypes.IPA_REQUEST_SUCCESS,
    getAll,
  };
}
function ipaRequestFailure(error) {
  return {
    type: ipaTypes.IPA_REQUEST_FAILURE,
    error,
  };
}

//Save IPA
function ipaSaveRequest(request, token) {
  return {
    type: ipaTypes.IPA_SAVE_REQUEST,
    request,
    token,
  };
}
function ipaSaveRequestSuccess(message) {
  return {
    type: ipaTypes.IPA_SAVE_REQUEST_SUCCESS,
    message,
  };
}
function ipaSaveRequestFailure(error) {
  return {
    type: ipaTypes.IPA_SAVE_REQUEST_FAILURE,
    error,
  };
}

//update IPA
function ipaEditRequest(query, payload, token) {
  return {
    type: ipaTypes.IPA_EDIT_REQUEST,
    query,
    payload,
    token,
  };
}
function ipaEditRequestSuccess(id, data, message) {
  return {
    type: ipaTypes.IPA_EDIT_REQUEST_SUCCESS,
    id,
    data,
    message,
  };
}
function ipaEditRequestFailure(error) {
  return {
    type: ipaTypes.IPA_EDIT_REQUEST_FAILURE,
    error,
  };
}

//IsActive IPA
function ipaUpdateStatusRequest(query, payload, token) {
  return {
    type: ipaTypes.IPA_UPDATE_STATUS_REQUEST,
    query,
    payload,
    token,
  };
}
function ipaUpdateStatusRequestSuccess(id, message) {
  return {
    type: ipaTypes.IPA_UPDATE_STATUS_REQUEST_SUCCESS,
    id,
    message,
  };
}
function ipaUpdateStatusRequestFailure(error) {
  return {
    type: ipaTypes.IPA_UPDATE_STATUS_REQUEST_FAILURE,
    error,
  };
}
//reset
function ipaRequestReset() {
  return {
    type: ipaTypes.IPA_REQUEST_RESET,
  };
}
