import { immunizationTypes } from "../types";

export const immunizationActions = {
  //for get
  immunizationGetAllRequest,
  immunizationGetAllRequestSuccess,
  immunizationGetAllRequestFailure,

  //for update insert
  immunizationOperationRequest,
  immunizationOperationRequestSuccess,
  immunizationOperationRequestFailure,
  immunizationOperationRequestReset,

  //for- delete -
  immunizationDeleteRequest,
  immunizationDeleteRequestSuccess,
  immunizationDeleteRequestFailure,
  immunizationDeleteRequestReset,
};
function immunizationGetAllRequest(request, token) {
  return {
    type: immunizationTypes.GETALL_IMMUNIZATION_REQUEST,
    request,
    token,
  };
}
function immunizationGetAllRequestSuccess(
  getAll,
  dropDownImmunization,
  dropdownSourcelist
) {
  return {
    type: immunizationTypes.GETALL_IMMUNIZATION_REQUEST_SUCCESS,
    getAll,
    dropDownImmunization,
    dropdownSourcelist,
  };
}
function immunizationGetAllRequestFailure(error) {
  return {
    type: immunizationTypes.GETALL_IMMUNIZATION_REQUEST_FAILURE,
    error,
  };
}

function immunizationOperationRequest(request, token) {
  return {
    type: immunizationTypes.OPERARTION_IMMUNIZATION_REQUEST,
    request,
    token,
  };
}
function immunizationOperationRequestSuccess(message) {
  return {
    type: immunizationTypes.OPERARTION_IMMUNIZATION_REQUEST_SUCCESS,
    message,
  };
}
function immunizationOperationRequestFailure(error) {
  return {
    type: immunizationTypes.OPERARTION_IMMUNIZATION_REQUEST_FAILURE,
    error,
  };
}
function immunizationOperationRequestReset() {
  return {
    type: immunizationTypes.OPERARTION_IMMUNIZATION_REQUEST_RESET,
  };
}

function immunizationDeleteRequest(request, token) {
  return {
    type: immunizationTypes.DELETE_IMMUNIZATION_REQUEST,
    request,
    token,
  };
}
function immunizationDeleteRequestSuccess(message) {
  return {
    type: immunizationTypes.DELETE_IMMUNIZATION_REQUEST_SUCCESS,
    message,
  };
}
function immunizationDeleteRequestFailure(error) {
  return {
    type: immunizationTypes.DELETE_IMMUNIZATION_REQUEST_FAILURE,
    error,
  };
}
function immunizationDeleteRequestReset() {
  return {
    type: immunizationTypes.DELETE_IMMUNIZATION_REQUEST_RESET,
  };
}
