import { medicationSaveType } from "../types/medicationSave.type";

export const medicationSaveActions = {
  medicationSaveRequest,
  medicationSaveRequestSuccess,
  medicationSaveRequestReset,
  medicationSaveRequestFailure,
};

function medicationSaveRequest(request, token) {
  return { type: medicationSaveType.MEDICATION_SAVE_REQUEST, request, token };
}

function medicationSaveRequestSuccess(message) {
  return { type: medicationSaveType.MEDICATION_SAVE_REQUEST_SUCCESS, message };
}

function medicationSaveRequestReset() {
  return { type: medicationSaveType.MEDICATION_SAVE_REQUEST_RESET };
}

function medicationSaveRequestFailure(error) {
  return { type: medicationSaveType.MEDICATION_SAVE_REQUEST_FAILURE, error };
}
