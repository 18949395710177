import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { strings } from "./strings";
import styles from "./callHistoryCmer.module.scss";
import {
  callActionTakenLookupCmerActions,
  callDispositionLookupCmerActions,
  callHistoryOperationsCmActions,
} from "./actions";
import { useSelector, useDispatch } from "react-redux";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

import { AddCall } from "./components";
import { ActionBtn, BtnDeleteIcon } from "../../../components";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { AddButton } from "../../cm/EditPatient/components/AddButton";
import ConfirmDialog from "../../../components/Dialog";
import { Backdrop, CircularProgress } from "@mui/material";

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}
const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};
renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.string.isRequired,
};

function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={params.value || ""}
      width={params.colDef.computedWidth}
    />
  );
}
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton className={styles.toolbar} />
      <GridToolbarFilterButton className={styles.toolbar} />
      <GridToolbarDensitySelector className={styles.toolbar} />
      <GridToolbarExport
        className={styles.toolbar}
        csvOptions={{
          fields: [
            "UserName",
            "Action",
            "Disposition",
            "CloseCase",
            "CallComplete",
            "Comment",
            "UpdatedDate",
          ],
        }}
      />
    </GridToolbarContainer>
  );
}
const CallHistoryCmRole = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const userData = useSelector((state) => state.login.userData);
  const callHistory = useSelector((state) => state.callHistory);

  const callHistoryDetailsCm = useSelector(
    (state) => state.callHistoryDetailsCm
  );
  const callActionTaken = useSelector((state) => state.callActionTaken);
  const callDisposition = useSelector((state) => state.callDisposition);
  const callHistoryOperationsCm = useSelector(
    (state) => state.callHistoryOperationsCm
  );
  const callHistoryDetailsCmerReducer = useSelector(
    (state) => state.callHistoryDetailsCmer
  );
  const [loading, setLoading] = useState(false);
  const formDataKeys = {
    actions: null,
    disposition: null,
    callComments: "",
    callComplete: false,
    closeCase: false,
    DNC: false,
    historyId: "",
  };
  const [formData, setFormData] = useState({
    ...formDataKeys,
  });
  const [formDataErrors, setFormDataErrors] = useState(null);

  useEffect(() => {
    if (callHistoryOperationsCm?.message !== "") {
      dispatch(callHistoryOperationsCmActions?.callHistoryOperationsCmReset());
      setLoading(false);
      setShow(false);
      getCallHistoryDetails();
      setTimeout(() => {
        setFormData(formDataKeys);
        setFormDataErrors(null);
      }, 1500);
    }
  }, [callHistoryOperationsCm]);
  const [dispositionId, setDispositionId] = useState("");

  const [actionsTaken, setActionsTaken] = useState("");
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [callHistoryId, setCallHistoryId] = useState("");
  const [isEditing, setEditing] = useState(false);

  const columns = [
    {
      field: "edit",
      headerName: "Edit",

      flex: 0.05,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <>
            <ActionBtn isEdit onClick={() => onEditCallHistory(params?.row)} />
          </>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 0.08,
      sortable: false,
      filterable: false,

      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setDeleteCallHistoryDetails({
            isOpen: true,
            details: params?.row,
          });
          // return alert(JSON.stringify(params.id));
        };
        return <BtnDeleteIcon onClick={onClick} />;
      },
    },
    {
      field: "UserName",
      headerName: strings.TH_USER,
      flex: 0.1,
      renderCell: renderCellExpand,
      headerClassName: styles.header,
      cellClassName: styles.cell,
    },
    {
      field: "UpdatedDate",
      headerName: strings.TH_DATE,
      flex: 0.15,
      type: "date",
    },
    // { field: "time", headerName: strings.TH_TIME, width: 160 },
    {
      field: "DNC",
      headerName: strings.TH_DNC,
      headerClassName: styles.header,
      flex: 0.08,
    },
    //Consent
    {
      field: "CloseCase",
      headerName: strings.TH_CLOSE_THIS_CASE,
      headerClassName: styles.header,
      // description: "This column has a value getter and is not sortable.",
      // sortable: false,
      flex: 0.1,
    },
    {
      field: "CallComplete",
      headerName: strings.TH_CALL_COMPLETE,
      flex: 0.1,
    },
    // { field: "Consent", headerName: strings.TH_CONSENT, width: 160 },
    {
      field: "Disposition",
      headerName: strings.TH_DISPOSITION,
      headerClassName: styles.header,
      flex: 0.2,
      renderCell: renderCellExpand,
    },
    {
      field: "Action",
      headerName: strings.TH_ACTION,
      headerClassName: styles.header,
      flex: 0.2,
      renderCell: renderCellExpand,
    },
    {
      field: "Comment",
      headerName: strings.TH_COMMENT,
      renderCell: renderCellExpand,
      flex: 0.15,
    },
  ];

  //filter patient by the flags
  const selectedActions = new Map();

  const getSelectedActions = (selected) => {
    for (let i in selected) {
      selectedActions.set(selected[i].ActionID, selected[i].ActionID);
    }
    let tempFlag = "";
    for (const [key] of selectedActions.entries()) {
      tempFlag = key + "," + tempFlag;
    }

    setActionsTaken(tempFlag);
    return tempFlag;
  };

  const handleRowSelection = (id) => {
    setCallHistoryId(id[0]);

    if (callHistory.callHistory !== undefined && id.length > 0) {
      setSelectedItem(
        callHistory.callHistory.filter((item) =>
          item.HistoryID.includes(id[0])
        )[0]
      );
    }
  };
  const callActionTakenLookupCmer = useSelector(
    (state) => state.callActionTakenLookupCmer
  );
  const callDispositionLookupCmer = useSelector(
    (state) => state.callDispositionLookupCmer
  );
  const onEditCallHistory = (item) => {
    const actions = item?.Action.split(",").map((val) => val.trim());

    const filteredItems = callActionTakenLookupCmer?.getAll?.filter((item) =>
      actions.includes(item?.Action?.trim())
    );
    // if (
    //   callHistoryId !== "" &&
    //   callHistoryId !== undefined &&
    //   selectedItem !== ""
    // ) {
    setFormData({
      actions: filteredItems,
      disposition: callDispositionLookupCmer?.getAll?.find(
        (val) => val?.DispositionDesc.trim() === item?.Disposition?.trim()
      ),
      callComments: item?.Comment,
      callComplete: item?.CallComplete === "No" ? false : true,
      closeCase: item?.CloseCase === "No" ? false : true,
      DNC: item?.DNC === "No" ? false : true,
      historyId: item?.HistoryID,
    });
    setLoading(true);
    setTimeout(() => {
      setEditing(true);
      setShow(true);
    }, 1500);
  };
  const getCallHistoryDetails = () => {
    let request = {
      patientID: state?.patientId,
      parentAdmissionId: state?.patientDetails1?.parentAdmissionId,
    };
    dispatch(
      callHistoryOperationsCmActions?.callHistoryGetAllDetailsCmRequest(
        request,
        userData?.token
      )
    );
  };
  useEffect(() => {
    if (userData.token !== undefined) {
      let request = {
        encounterId: state?.encounterId,
        patientID: state?.patientID,
      };
      // dispatch(
      //   callHistoryOperationsCmerActions?.callHistoryGetAllDetailsCmerRequest(
      //     request,
      //     userData?.token
      //   )
      // );

      getCallHistoryDetails();
      dispatch(
        callActionTakenLookupCmerActions.callActionTakenLookupCmerRequest(
          request,
          userData.token
        )
      );
      dispatch(
        callDispositionLookupCmerActions.callDispositionLookupCmerRequest(
          request,
          userData.token
        )
      );
    }
    // eslint-disable-next-line
  }, [userData.token]);

  useEffect(() => {
    if (
      selectedItem !== null &&
      callDisposition.getAll.length > 0 &&
      callActionTaken?.actions.length > 0
    ) {
      let actionArr = selectedItem.Action.split(", ");
      let ActArr = [];
      for (let i in actionArr) {
        ActArr.push(
          callActionTaken?.actions.filter((item) =>
            item.ActionName.includes(actionArr[i])
          )[0]
        );
      }

      if (selectedItem.Action !== undefined && ActArr.length > 0) {
        getSelectedActions(ActArr);
      }
      setDispositionId(
        callDisposition.getAll.filter((item) =>
          item.DispositionName.includes(selectedItem.Disposition)
        )[0]
      );
    }
    // eslint-disable-next-line
  }, [selectedItem, callDisposition.getAll, callActionTaken.actions]);

  // const removeComma = (str) => {
  //   if (str != null && str.length > 0 && str.charAt(str.length - 1) === ",") {
  //     str = str.substring(0, str.length - 1);
  //   }
  //   return str;
  // };

  const [deleteCallHistoryDetails, setDeleteCallHistoryDetails] = useState({
    isOpen: false,
    details: null,
  });
  let handleDiscard = () => {
    setDeleteCallHistoryDetails({
      isDeleteTask: false,
      details: null,
    });
  };
  let handleDialogClose = () => {
    let request = {
      historyId: deleteCallHistoryDetails?.details?.HistoryID,
      flag: "Delete",
    };

    dispatch(
      callHistoryOperationsCmActions?.callHistoryOperationsCmRequest(
        request,
        userData?.token
      )
    );
    setLoading(true);
    setDeleteCallHistoryDetails({
      isDeleteTask: false,
      details: null,
    });
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>{" "}
      <div className={styles.container}>
        {deleteCallHistoryDetails?.isOpen && (
          <ConfirmDialog
            open={deleteCallHistoryDetails?.isOpen}
            handleDialogClose={handleDialogClose}
            handleDiscard={handleDiscard}
          />
        )}
        {!show && (
          <div className={styles.btn_actions}>
            <AddButton
              isActive={show}
              onClick={() => {
                setShow(!show);
                setEditing(false);
              }}
              btnEventName={strings.BTN_NAME}
            />
          </div>
        )}
        {show && (
          <AddCall
            show={show}
            isEditing={isEditing}
            setShow={(val) => setShow(val)}
            selectedItem={selectedItem}
            formData={formData}
            setFormData={setFormData}
            formDataErrors={formDataErrors}
            setFormDataErrors={setFormDataErrors}
            formDataKeys={formDataKeys}
            setLoading={setLoading}
          />
        )}
        {!show && (
          <DataGrid
            autoHeight
            className={styles.table}
            rows={callHistoryDetailsCm?.getAll}
            columns={columns}
            loading={callHistoryDetailsCm?.loading}
            onSelectionModelChange={handleRowSelection}
            components={{ Toolbar: CustomToolbar }}
            getRowClassName={styles.table_rows}
            getRowId={(row) => row.HistoryID}
            pageSize={10}
            rowsPerPageOptions={[10]}
            componentsProps={{
              pagination: {
                component: "div",
                style: {
                  flex: 1,
                },
              },
            }}
          />
        )}
      </div>
    </>
  );
};

export default CallHistoryCmRole;
