import { insuranceOperationTypes } from "../types";

export const insuranceOperationActions = {
  insuranceOperationRequest,
  insuranceOperationRequestReset,
  insuranceOperationRequestSuccess,
  insuranceOperationRequestFailure,
};
function insuranceOperationRequest(request, token) {
  return {
    type: insuranceOperationTypes.INSURANCE_OPERATION_REQUEST,
    request,
    token,
  };
}
function insuranceOperationRequestReset() {
  return {
    type: insuranceOperationTypes.INSURANCE_OPERATION_REQUEST_RESET,
  };
}
function insuranceOperationRequestSuccess(message) {
  return {
    type: insuranceOperationTypes.INSURANCE_OPERATION_REQUEST_SUCCESS,
    message,
  };
}
function insuranceOperationRequestFailure(error) {
  return {
    type: insuranceOperationTypes.INSURANCE_OPERATION_REQUEST_FAILURE,
    error,
  };
}
