import { socialDeterministOperationTypes } from "../types";

export const socialDeterministOperationActions = {
  socialDeterministOperationRequest,
  socialDeterministOperationRequestReset,
  socialDeterministOperationRequestSuccess,
  socialDeterministOperationRequestFailure,
};
function socialDeterministOperationRequest(request, token) {
  return {
    type: socialDeterministOperationTypes.SOCIALDETERMINIST_OPERATION_REQUEST,
    request,
    token,
  };
}
function socialDeterministOperationRequestReset() {
  return {
    type: socialDeterministOperationTypes.SOCIALDETERMINIST_OPERATION_REQUEST_RESET,
  };
}
function socialDeterministOperationRequestSuccess(message) {
  return {
    type: socialDeterministOperationTypes.SOCIALDETERMINIST_OPERATION_REQUEST_SUCCESS,
    message,
  };
}
function socialDeterministOperationRequestFailure(error) {
  return {
    type: socialDeterministOperationTypes.SOCIALDETERMINIST_OPERATION_REQUEST_FAILURE,
    error,
  };
}
