import React from "react";
import styles from "./InputTypeField.module.scss";

const InputTypeField = (propsall) => {
  const {
    minWidth = 0,
    height = 0,
    paddingTop = 0,
    marginLeft = 0,
    flex = 0.5,

    ...props
  } = propsall;
  return (
    <div className={styles.info_row}>
      <p className={[styles.label, { flex: flex }]}>{props.label}:</p>
      <textarea
        className={styles.value}
        style={{
          minWidth: minWidth,
          height: height,
          paddingTop: paddingTop,
          marginLeft: marginLeft,
        }}
        {...props}
      />
    </div>
  );
};

export { InputTypeField };
