import { put, takeLatest, call } from "@redux-saga/core/effects";
import { pushNotificationActions } from "../actions/pushNotification.action";
import { pushNotificationType } from "../types/pushNotification.type";
import { endpoint } from "../../api/Url";
import { Api } from "../../api";

export function* watchPushNotificationDeviceSave() {
  yield takeLatest(
    pushNotificationType.PUSH_NOTIFICATION_REQUEST,
    savePushNotificationDevice
  );
}

function* savePushNotificationDevice(action) {
  try {
    //   api call

    const message = yield call(Api, {
      endpoint: `${endpoint.saveNotificationDevice}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    const parsedMessage = JSON.parse(message);

    yield put(
      pushNotificationActions.pushNotificationSuccess(parsedMessage[0])
    );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(pushNotificationActions.pushNotificationFailure(error));
  }
}
