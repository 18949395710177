import { takeLatest, put, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { addUpdateUtilizationTypes } from "../types";
import { addUpdateUtilizationActions } from "../actions";

export function* watchAddUpdateUtilization() {
  yield takeLatest(
    addUpdateUtilizationTypes.ADDUPDATE_UTILIZATION_REQUEST,
    addUpdateUtilization
  );
}

function* addUpdateUtilization(action) {
  try {
    let message = yield call(Api, {
      method: "POST",
      endpoint: endpoint.saveUtilizationHistory,
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    const parsedMessage = JSON.parse(message);
    yield put(
      addUpdateUtilizationActions.addUpdateUtilizationRequestSuccess(
        parsedMessage[0]
      )
    );
  } catch (error) {
    console.log(error);
    yield put(
      addUpdateUtilizationActions.addUpdateUtilizationRequestFailure(error)
    );
  }
}
