import { preventDuplicatePatientAddBillingType } from "../types";

const initialState = {
  loading: false,
  data: "",
  message: "",
  error: "",
};

export const preventDuplicatePatientAddBillingReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case preventDuplicatePatientAddBillingType.PREVENT_DUPLICATE_PATIENT_ADD_BILLING_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case preventDuplicatePatientAddBillingType.PREVENT_DUPLICATE_PATIENT_ADD_BILLING_SUCCESS:
      return {
        ...initialState,
        data: action.data,
        message: action.message,
      };
    case preventDuplicatePatientAddBillingType.PREVENT_DUPLICATE_PATIENT_ADD_BILLING_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case preventDuplicatePatientAddBillingType.PREVENT_DUPLICATE_PATIENT_ADD_BILLING_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
