import { visitDetailsCMType } from "../types";

export const visitDetailsCMActions = {
  visitDetailsCMRequest,
  visitDetailsCMSuccess,
  visitDetailsCMFailure,
};

function visitDetailsCMRequest(request, token) {
  return { type: visitDetailsCMType.VISIT_DETAILS_CM_REQUEST, request, token };
}
function visitDetailsCMSuccess(visits) {
  return {
    type: visitDetailsCMType.VISIT_DETAILS_CM_SUCCESS,
    visits,
  };
}
function visitDetailsCMFailure(error) {
  return { type: visitDetailsCMType.VISIT_DETAILS_CM_FAILURE, error };
}
