import {
  getClinicalInfoCmerTypes,
  updateClinicalInfoCmerTypes,
} from "../types";

export const getClinicalInformationCmerActions = {
  getClinicalInfoCmerRequest,
  getClinicalInfoCmerSuccess,
  getClinicalInfoCmerFailure,
};

function getClinicalInfoCmerRequest(request, token) {
  return {
    type: getClinicalInfoCmerTypes.GET_CLINICAL_INFO_CMER_REQUEST,
    request,
    token,
  };
}

function getClinicalInfoCmerSuccess(Consultant, DcDiagnosis, socialInfo) {
  return {
    type: getClinicalInfoCmerTypes.GET_CLINICAL_INFO_CMER_SUCCESS,
    Consultant,
    DcDiagnosis,
    socialInfo,
  };
}
function getClinicalInfoCmerFailure(error) {
  return {
    type: getClinicalInfoCmerTypes.GET_CLINICAL_INFO_CMER_FAILURE,
  };
}






export const updateClinicalInformationCmerActions = {
  updateClinicalInfoCmerRequest,
  updateClinicalInfoCmerSuccess,
  updateClinicalInfoCmerFailure,
  updateClinicalInfoCmerReset,
};
function updateClinicalInfoCmerRequest(request, body, token) {
  return {
    type: updateClinicalInfoCmerTypes.UPDATE_CLINICAL_INFO_CMER_REQUEST,
    request,
    body,
    token,
  };
}

function updateClinicalInfoCmerSuccess(message) {
  return {
    type: updateClinicalInfoCmerTypes.UPDATE_CLINICAL_INFO_CMER_SUCCESS,
    message,
  };
}
function updateClinicalInfoCmerFailure() {
  return {
    type: updateClinicalInfoCmerTypes.UPDATE_CLINICAL_INFO_CMER_FAILURE,
  };
}
function updateClinicalInfoCmerReset(error) {
  return {
    type: updateClinicalInfoCmerTypes.UPDATE_CLINICAL_INFO_CMER_RESET,
  };
}
