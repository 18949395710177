import { nationTypes } from "../types/nation.type";

const initiaLState = {
  loading: false,
  getAll: [],
  error: "",
  message: "",
};

export const nationDMReducer = (state = initiaLState, action) => {
  switch (action.type) {
    case nationTypes.NATION_REQUEST:
      return {
        ...initiaLState,
        loading: true,
      };
    case nationTypes.NATION_REQUEST_SUCCESS:
      return {
        ...initiaLState,
        getAll: action.getAll,
      };
    case nationTypes.NATION_REQUEST_FAILURE:
      return {
        ...initiaLState,
        error: action.error,
      };
    //create
    case nationTypes.NATION_SAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case nationTypes.NATION_SAVE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        getAll: [...state.getAll, action.value],
      };
    case nationTypes.NATION_SAVE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    //edit nation
    case nationTypes.NATION_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case nationTypes.NATION_EDIT_REQUEST_SUCCESS:
      let index = state.getAll.findIndex(
        (item) => item.NationId === action.value.NationId
      );
      const newArr = [...state.getAll]; // create new array to store list
      newArr[index] = action.value;

      return {
        ...state,
        loading: false,
        message: action.message,
        getAll: newArr,
      };
    case nationTypes.NATION_EDIT_REQUEST_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    //Update status
    case nationTypes.NATION_UPDATE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case nationTypes.NATION_UPDATE_STATUS_REQUEST_SUCCESS:
      let index1 = state.getAll.findIndex(
        (item) => item.NationId === action.id
      );
      const newArr1 = [...state.getAll]; // create new array to store list
      const nation = { ...newArr1[index1], active: !newArr1[index1].active }; // Here is the issue
      newArr1[index1] = nation;

      return {
        ...state,
        loading: false,
        message: action.message,
        getAll: newArr1,
      };
    case nationTypes.NATION_UPDATE_STATUS_REQUEST_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case nationTypes.NATION_REQUEST_RESET:
      return { ...state, loading: false, message: "", error: "" };
    default:
      return state;
  }
};
