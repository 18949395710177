import { updateInfoTypes } from "../types";

const initialState = {
  updating: false,
};

export const updateInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case updateInfoTypes.UPDATE_INFO_REQUEST:
      return {
        ...initialState,
        updating: true,
      };
    case updateInfoTypes.UPDATE_INFO_REQUEST_SUCCESS:
      return {
        ...initialState,
        message: action.message,
      };
    case updateInfoTypes.UPDATE_INFO_REQUEST_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case updateInfoTypes.UPDATE_INFO_REQUEST_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
