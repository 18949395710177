import { put, takeLatest, call } from "@redux-saga/core/effects";
import { endpoint } from "../../../../../api/Url";
import { Api } from "../../../../../api";
import { bundleDispositionTypes } from "../types";
import { bundleDispositionActions } from "../actions";

export function* watchDMBundleDisposition() {
  yield takeLatest(
    bundleDispositionTypes.BUNDLE_DISPOSITION_REQUEST,
    getBundleDisposition
  );
}

function* getBundleDisposition(action) {
  try {
    let data = yield call(Api, {
      endpoint: endpoint.getDMBundleDispositionData,
      method: "POST",
      jwt: "Bearer " + action.token,
    });

    let parsedData = JSON.parse(data);

    if (
      parsedData.DispositionList !== undefined &&
      parsedData.DispositionList.length > 0
    ) {
      yield put(
        bundleDispositionActions.bundleDispositionRequestSuccess(
          parsedData.DispositionList
        )
      );
    } else {
      yield put(
        bundleDispositionActions.bundleDispositionRequestFailure(
          "No data found"
        )
      );
    }
  } catch (error) {
    yield put(bundleDispositionActions.bundleDispositionRequestFailure(error));
  }
}
