import { put, takeLatest, call } from "@redux-saga/core/effects";
import { comprehensiveActions } from "../actions/comprehensive.action";
import { comprehensiveTypes } from "../types/comprehensive.type";
import { Api2 } from "../../../../api/Api2";
import { endpoint } from "../../../../api/Url";

export function* watchComprehensiveAssessment() {
  yield takeLatest(
    comprehensiveTypes.GET_ASSESSMENT_REQUEST,
    getComprehensiveAssessment
  );
  yield takeLatest(
    comprehensiveTypes.GET_CHANGES_ASSESSMENT_REQUEST,
    getChangesComprehensiveAssessment
  );
  yield takeLatest(
    comprehensiveTypes.UPDATE_ASSESSMENT_REQUEST,
    updateComprehensiveAssessment
  );
}

function* getComprehensiveAssessment(action) {
  try {
    const data = yield call(Api2, {
      endpoint: `${endpoint.awv}/${action.request.patientId}/${endpoint.comprehensiveSystemAssessmentsPatient}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });

    yield put(
      comprehensiveActions.getAssessmentRequestSuccess(data.Assessmemts)
    );
  } catch (error) {
    yield put(comprehensiveActions.getAssessmentRequestFailure(error));
  }
}

// get chenage comprehensive
function* getChangesComprehensiveAssessment(action) {
  try {
    const data = yield call(Api2, {
      endpoint: `${endpoint.awv}/${action.request.patientId}/${endpoint.comprehensiveSystemAssessmentsSystem}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });

    yield put(comprehensiveActions.getChangesAssessmentRequestSuccess(data));
  } catch (error) {
    yield put(comprehensiveActions.getChangesAssessmentRequestFailure(error));
  }
}
// update change comprehensive
function* updateComprehensiveAssessment(action) {
  try {
    const data = yield call(Api2, {
      endpoint: `${endpoint.awv}/${action.request.patientId}/${endpoint.comprehensiveSystemAssessmentsSystem}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request.data,
    });
    console.log(data);
    yield put(comprehensiveActions.updateAssessmentRequestSuccess(data));
  } catch (error) {
    yield put(comprehensiveActions.updateAssessmentRequestFailure(error));
  }
}
