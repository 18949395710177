import { diagnosisUpdateType } from "../types";

export const updateDiagnosisActions = {
  updateDiagnosisRequest,
  updateDiagnosisRequestReset,
  updateDiagnosisRequestSuccess,
  updateDiagnosisRequestFailure,
};
function updateDiagnosisRequest(request, token) {
  return {
    type: diagnosisUpdateType.UPDATE_DIAGNOSIS_REQUEST,
    request,
    token,
  };
}
function updateDiagnosisRequestReset() {
  return {
    type: diagnosisUpdateType.UPDATE_DIAGNOSIS_REQUEST_RESET,
  };
}
function updateDiagnosisRequestSuccess(message) {
  return {
    type: diagnosisUpdateType.UPDATE_DIAGNOSIS_REQUEST_SUCCESS,
    message,
  };
}
function updateDiagnosisRequestFailure(error) {
  return {
    type: diagnosisUpdateType.UPDATE_DIAGNOSIS_REQUEST_FAILURE,
    error,
  };
}
