import { deleteAppointmentTypes } from "../types/deleteAppointment.type";

const initialState = {
  deleting: false,
};

export const deleteAppointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case deleteAppointmentTypes.DELETE_APPOINTMENT_REQUEST:
      return { ...initialState, deleting: true };
    case deleteAppointmentTypes.DELETE_APPOINTMENT_REQUEST_SUCCESS:
      return { ...initialState, message: action.message };
    case deleteAppointmentTypes.DELETE_APPOINTMENT_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    case deleteAppointmentTypes.DELETE_APPOINTMENT_REQUEST_RESET:
      return initialState;
    default:
      return state;
  }
};
