import React from "react";
import { Button, ButtonCancel } from "../../core";
import styles from "./ButtonRow.module.scss";
export const ButtonRow = ({ isEditing, loading, onSubmit, onCancel }) => {
  return (
    <div className={styles.container}>
      <ButtonCancel label="Cancel" onClick={onCancel} />
      <Button
        label={isEditing ? "Update" : "Save"}
        loading={loading}
        onClick={onSubmit}
      />
    </div>
  );
};
