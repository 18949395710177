import { put, takeLatest, call } from "@redux-saga/core/effects";
import { patientDetailsActions } from "../actions";
import { patientDetailsTypes } from "../types";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";

export function* watchCMDPatientDetails() {
  yield takeLatest(
    patientDetailsTypes.PATIENT_DETAILS_REQUEST,
    getPatientDetails
  );
}

function* getPatientDetails(action) {
  try {
    let details = yield call(Api, {
      method: "POST",
      endpoint: `${endpoint.getCMDPatientDetails}?AdmissionID=${action.request.AdmissionID}`,
      jwt: "Bearer " + action.token,
    });
    const parsedDetails = JSON.parse(details);
    if (
      parsedDetails.CaseManagerDirectorPatientDetail !== undefined &&
      parsedDetails.CaseManagerDirectorPatientDetail.length > 0
    ) {
      yield put(
        patientDetailsActions.patientDetailsRequestSuccess(
          parsedDetails.CaseManagerDirectorPatientDetail[0]
        )
      );
    } else {
      yield put(
        patientDetailsActions.patientDetailsRequestFailure("No Data found")
      );
    }
  } catch (error) {
    yield put(patientDetailsActions.patientDetailsRequestFailure(error));
  }
}
