import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
import { userReportsListActions } from "../actions/getUserReportsList.action";
import { userReportsListTypes } from "../types/getUserReportsList.type";

export function* watchUserReportsList() {
  yield takeLatest(
    userReportsListTypes.USER_REPORTSLIST_REQUEST,
    getUserReportsList
  );
}

function* getUserReportsList(action) {
  try {
    const data = yield call(Api, {
      endpoint: `${endpoint.getUserReportsList}`,
      method: "POST",
      jwt: "Bearer " + action.token,
    });
    const parsedData = JSON.parse(data);

    yield put(userReportsListActions.userReportsListRequestSuccess(parsedData));
  } catch (error) {
    yield put(userReportsListActions.userReportsListRequestFailure(error));
  }
}
