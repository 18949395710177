export const surgicalHistoryTypes = {
  GETALL_SURGICAL_HISTORY_REQUEST: "GETALL_SURGICAL_HISTORY_REQUEST",
  GETALL_SURGICAL_HISTORY_REQUEST_SUCCESS:
    "GETALL_SURGICAL_HISTORY_REQUEST_SUCCESS",
  GETALL_SURGICAL_HISTORY_REQUEST_FAILURE:
    "GETALL_SURGICAL_HISTORY_REQUEST_FAILURE",

  OPERARTION_SURGICAL_HISTORY_REQUEST: "OPERARTION_SURGICAL_HISTORY_REQUEST",
  OPERARTION_SURGICAL_HISTORY_REQUEST_SUCCESS:
    "OPERARTION_SURGICAL_HISTORY_REQUEST_SUCCESS",
  OPERARTION_SURGICAL_HISTORY_REQUEST_FAILURE:
    "OPERARTION_SURGICAL_HISTORY_REQUEST_FAILURE",
  OPERARTION_SURGICAL_HISTORY_REQUEST_RESET:
    "OPERARTION_SURGICAL_HISTORY_REQUEST_RESET",

  DELETE_SURGICAL_REQUEST: "DELETE_SURGICAL_REQUEST",
  DELETE_SURGICAL_REQUEST_SUCCESS: "DELETE_SURGICAL_REQUEST_SUCCESS",
  DELETE_SURGICAL_REQUEST_FAILURE: "DELETE_SURGICAL_REQUEST_FAILURE",
  DELETE_SURGICAL_REQUEST_RESET: "DELETE_SURGICAL_REQUEST_RESET",
};
