import { put, call, takeLatest } from "@redux-saga/core/effects";
import { sendMessageActions } from "../actions";
import { sendMessageTypes } from "../types";
import { endpoint } from "../../../api/Url";
import { Api } from "../../../api";
export function* watchPatientSendMessage() {
  yield takeLatest(sendMessageTypes.SEND_MESSAGE_REQUEST, getSendMessage);
}

function* getSendMessage(action) {
  try {
    let message = yield call(Api, {
      endpoint: endpoint.savePatientNurseChatDetails,
      method: "POST",
      jwt: "bearer " + action.token,
      body: action.request,
    });
    const parsedMessage = JSON.parse(message);

    yield put(
      sendMessageActions.sendMessageRequestSuccess(parsedMessage[0].Result)
    );
  } catch (error) {
    yield put(sendMessageActions.sendMessageRequestFailure("Failed to sent!"));
  }
}
