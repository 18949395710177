import { tasksDetailsType } from "../types/tasksDetails.type";

const initialState = {
  loading: false,
  tasksDetails: [],
};

export const tasksDetailsTCReducer = (state = initialState, action) => {
  switch (action.type) {
    case tasksDetailsType.CALL_TASKS_DETAILS_REQUEST:
      return { ...initialState, loading: true };
    case tasksDetailsType.CALL_TASKS_DETAILS_REQUEST_SUCCESS:
      return {
        ...initialState,
        tasksDetails: action.tasksDetails,
      };
    case tasksDetailsType.CALL_TASKS_DETAILS_REQUEST_FAILURE:
      return { ...initialState, error: action.error };

    default:
      return state;
  }
};
