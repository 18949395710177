import { getAllFacilityType } from "../types";

const initiaState = {
  loading: false,
  getAll: [],
  error: "",
};

export const getAllFacilityTypeReducer = (state = initiaState, action) => {
  switch (action.type) {
    case getAllFacilityType.GETALL_FACILITY_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case getAllFacilityType.GETALL_FACILITY_TYPE_SUCCESS:
      return {
        ...initiaState,
        getAll: action.getAll,
      };
    case getAllFacilityType.GETALL_FACILITY_TYPE_FAILURE:
      return {
        ...initiaState,
        error: action.error,
      };
    case getAllFacilityType.GETALL_FACILITY_TYPE_RESET:
      return {
        ...initiaState,
      };
    default:
      return state;
  }
};
