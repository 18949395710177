import { replyMailTypes } from "../types/replyMail.type";

export const replyMailActions = {
  replyMailRequest,
  replyMailRequestReset,
  replyMailRequestSuccess,
  replyMailRequestFailure,

  replyPatientChatMailRequest,
  replyPatientChatMailRequestReset,
  replyPatientChatMailRequestSuccess,
  replyPatientChatMailRequestFailure,
};
function replyMailRequest(request, token) {
  return { type: replyMailTypes.REPLY_MAIL_REQUEST, request, token };
}
function replyMailRequestReset() {
  return { type: replyMailTypes.REPLY_MAIL_REQUEST_RESET };
}
function replyMailRequestSuccess(message) {
  return { type: replyMailTypes.REPLY_MAIL_REQUEST_SUCCESS, message };
}
function replyMailRequestFailure(error) {
  return { type: replyMailTypes.REPLY_MAIL_REQUEST_FAILURE, error };
}

function replyPatientChatMailRequest(request, token) {
  return {
    type: replyMailTypes.PATIENTCHAT_REPLY_MAIL_REQUEST,
    request,
    token,
  };
}
function replyPatientChatMailRequestReset() {
  return { type: replyMailTypes.PATIENTCHAT_REPLY_MAIL_REQUEST_RESET };
}
function replyPatientChatMailRequestSuccess(message) {
  return {
    type: replyMailTypes.PATIENTCHAT_REPLY_MAIL_REQUEST_SUCCESS,
    message,
  };
}
function replyPatientChatMailRequestFailure(error) {
  return { type: replyMailTypes.PATIENTCHAT_REPLY_MAIL_REQUEST_FAILURE, error };
}
