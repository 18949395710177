import { getScheduleAppoinmentsType } from "../../types";

export const getScheduleAppoinmentsTCActions = {
  getScheduleAppoinmentsRequest,
  getScheduleAppoinmentsSuccess,
  getScheduleAppoinmentsFailure,
};
function getScheduleAppoinmentsRequest(query, token) {
  return {
    type: getScheduleAppoinmentsType.GET_SCHEDULE_APPOINMENTS_TC_REQUEST,
    query,
    token,
  };
}
function getScheduleAppoinmentsSuccess(getAll, schedule) {
  return {
    type: getScheduleAppoinmentsType.GET_SCHEDULE_APPOINMENTS_TC_SUCCESS,
    getAll,
    schedule,
  };
}
function getScheduleAppoinmentsFailure(error) {
  return {
    type: getScheduleAppoinmentsType.GET_SCHEDULE_APPOINMENTS_TC_FAILURE,
    error,
  };
}
