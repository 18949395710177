import { msoTypes } from "../types";

const initiaLState = {
  loading: false,
};

export const msoDMReducer = (state = initiaLState, action) => {
  switch (action.type) {
    case msoTypes.MSO_REQUEST:
      return {
        ...initiaLState,
        loading: true,
      };
    case msoTypes.MSO_REQUEST_SUCCESS:
      return {
        ...initiaLState,
        getAll: action.getAll,
      };
    case msoTypes.MSO_REQUEST_FAILURE:
      return {
        ...initiaLState,
        error: action.error,
      };
    default:
      return state;
  }
};
