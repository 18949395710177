import { callActionTakenLookupCmerTypes } from "../types";

export const callActionTakenLookupCmerActions = {
  callActionTakenLookupCmerRequest,
  callActionTakenLookupCmerSuccess,
  callActionTakenLookupCmerFailure,
};
function callActionTakenLookupCmerRequest(request, token) {
  return {
    type: callActionTakenLookupCmerTypes.CALL_ACTION_TAKEN_LOOKUP_CMER_REQUEST,
    request,
    token,
  };
}
function callActionTakenLookupCmerSuccess(getAll) {
  console?.log(getAll, "action");
  return {
    type: callActionTakenLookupCmerTypes.CALL_ACTION_TAKEN_LOOKUP_CMER_REQUEST_SUCCESS,
    getAll,
  };
}
function callActionTakenLookupCmerFailure(error) {
  return {
    type: callActionTakenLookupCmerTypes.CALL_ACTION_TAKEN_LOOKUP_CMER_REQUEST_FAILURE,
    error,
  };
}
