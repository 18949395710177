export const lookupTypes = {
  //LOOKUP region
  LOOKUP_REGION_REQUEST: "LOOKUP_REGION_REQUEST",
  LOOKUP_REGION_REQUEST_SUCCESS: "LOOKUP_REGION_REQUEST_SUCCESS",
  LOOKUP_REGION_REQUEST_FAILURE: "LOOKUP_REGION_REQUEST_FAILURE",

  //LOOKUP IPA
  LOOKUP_IPA_REQUEST: "LOOKUP_IPA_REQUEST",
  LOOKUP_IPA_REQUEST_SUCCESS: "LOOKUP_IPA_REQUEST_SUCCESS",
  LOOKUP_IPA_REQUEST_FAILURE: "LOOKUP_IPA_REQUEST_FAILURE",

  //LOOKUP PCPGROUP

  LOOKUP_PCP_GROUP_REQUEST: "LOOKUP_PCP_GROUP_REQUEST",
  LOOKUP_PCP_GROUP_REQUEST_SUCCESS: "LOOKUP_PCP_GROUP_REQUEST_SUCCESS",
  LOOKUP_PCP_GROUP_REQUEST_FAILURE: "LOOKUP_PCP_GROUP_REQUEST_FAILURE",

  //lookup pcpoffice

  LOOKUP_PCP_OFFICE_REQUEST: "LOOKUP_PCP_OFFICE_REQUEST",
  LOOKUP_PCP_OFFICE_SUCCESS: "LOOKUP_PCP_OFFICE_REQUEST_SUCCESS",
  LOOKUP_PCP_OFFICE_REQUEST_FAILURE: "LOOKUP_PCP_OFFICE_REQUEST_FAILURE",

  //lookup Hierarchylevel
  LOOKUP_HIERARCHY_LEVEL_REQUEST: "LOOKUP_HIERARCHY_LEVEL_REQUEST",
  LOOKUP_HIERARCHY_LEVEL_SUCCESS: "LOOKUP_HIERARCHY_LEVEL_REQUEST_SUCCESS",
  LOOKUP_HIERARCHY_LEVEL_REQUEST_FAILURE:
    "LOOKUP_HIERARCHY_LEVEL_REQUEST_FAILURE",

  //lookup menus
  LOOKUP_MENU_REQUEST: "LOOKUP_MENU_REQUEST",
  LOOKUP_MENU_SUCCESS: "LOOKUP_MENU_SUCCESS",
  LOOKUP_MENU_FAILURE: "LOOKUP_MENU_FAILURE",

  //lookup user
  LOOKUP_USER_REQUEST: "LOOKUP_USER_REQUEST",
  LOOKUP_USER_REQUEST_SUCCESS: "LOOKUP_USER_REQUEST_SUCCESS",
  LOOKUP_USER_REQUEST_FAILURE: "LOOKUP_USER_REQUEST_FAILURE",

  //lookup Role
  LOOKUP_ROLE_REQUEST: "LOOKUP_ROLE_REQUEST",
  LOOKUP_ROLE_REQUEST_SUCCESS: "LOOKUP_ROLE_REQUEST_SUCCESS",
  LOOKUP_ROLE_REQUEST_FAILURE: "LOOKUP_ROLE_REQUEST_FAILURE",
};
