import { pageType } from "../types/page.type";

export const pageReducer = (state = {}, action) => {
  switch (action.type) {
    case pageType.PAGE_TYPE_REQUEST:
      return { loading: true };
    case pageType.PAGE_TYPE_REQUEST_SUCCESS:
      return { page: action.page };
    case pageType.PAGE_TYPE_REQUEST_FAILURE:
      return { error: action.error };
    default:
      return state;
  }
};
