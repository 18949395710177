export const ipaTypes = {
  //get IPA
  IPA_REQUEST: "GETALL_DM_IPA_REQUEST",
  IPA_REQUEST_SUCCESS: "GETALL_DM_IPA_REQUEST_SUCCESS",
  IPA_REQUEST_FAILURE: "GETALL_DM_IPA_REQUEST_FAILURE",

  //save IPA
  IPA_SAVE_REQUEST: "DM_IPA_SAVE_REQUEST",
  IPA_SAVE_REQUEST_SUCCESS: "DM_IPA_SAVE_REQUEST_SUCCESS",
  IPA_SAVE_REQUEST_FAILURE: "DM_IPA_SAVE_REQUEST_FAILURE",

  //edit IPA
  IPA_EDIT_REQUEST: "DM_IPA_EDIT_REQUEST",
  IPA_EDIT_REQUEST_SUCCESS: "DM_IPA_EDIT_REQUEST_SUCCESS",
  IPA_EDIT_REQUEST_FAILURE: "DM_IPA_EDIT_REQUEST_FAILURE",

  //update IPA
  IPA_UPDATE_STATUS_REQUEST: "DM_IPA_UPDATE_STATUS_REQUEST",
  IPA_UPDATE_STATUS_REQUEST_SUCCESS: "DM_IPA_UPDATE_STATUS_REQUEST_SUCCESS",
  IPA_UPDATE_STATUS_REQUEST_FAILURE: "DM_IPA_UPDATE_STATUS_REQUEST_FAILURE",

  //reset
  IPA_REQUEST_RESET: "DM_IPA_REQUEST_RESET",
};
