import { getDispositionTypes } from "../types";

export const getDispositionActions = {
  dispositionRequest,
  dispositionRequestSuccess,
  dispositionRequestFailure,
};
function dispositionRequest(request, token) {
  return {
    type: getDispositionTypes.GET_DISPOSITION_REQUEST,
    request,
    token,
  };
}
function dispositionRequestSuccess(get) {
  return {
    type: getDispositionTypes.GET_DISPOSITION_REQUEST_SUCCESS,
    get,
  };
}
function dispositionRequestFailure(error) {
  return {
    type: getDispositionTypes.GET_DISPOSITION_REQUEST_FAILURE,
    error,
  };
}
