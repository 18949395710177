import { advanceCarePlanTypes } from "../types";

export const advanceCarePlanActions = {
  advanceCarePlanRequest,
  advanceCarePlanRequestSuccess,
  advanceCarePlanRequestFailure,
};
function advanceCarePlanRequest(request, token) {
  return {
    type: advanceCarePlanTypes.GET_ADVANCECAREPLAN_REQUEST,
    request,
    token,
  };
}
function advanceCarePlanRequestSuccess(getAll) {
  return {
    type: advanceCarePlanTypes.GET_ADVANCECAREPLAN_REQUEST_SUCCESS,
    getAll,
  };
}
function advanceCarePlanRequestFailure(error) {
  return {
    type: advanceCarePlanTypes.GET_ADVANCECAREPLAN_REQUEST_FAILURE,
    error,
  };
}
