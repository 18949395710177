import { medicationType } from "../types/medication.type";

const initialState = {
  loading: false,
  medication: [],
};

export const medicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case medicationType.MEDICATION_REQUEST:
      return { ...initialState, loading: true };
    case medicationType.MEDICATION_REQUEST_SUCCESS:
      return { ...initialState, medication: action.medication };
    case medicationType.MEDICATION_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};
