import { nationTypes } from "../types/nation.type";

export const nationActions = {
  nationRequest,
  nationRequestSuccess,
  nationRequestFailure,
  nationSaveRequest,
  nationSaveRequestSuccess,
  nationSaveRequestFailure,
  nationEditRequest,
  nationEditRequestSuccess,
  nationEditRequestFailure,
  nationUpdateStatusRequest,
  nationUpdateStatusRequestSuccess,
  nationUpdateStatusRequestFailure,
  nationRequestReset,
};

//Getall nation
function nationRequest(token) {
  return {
    type: nationTypes.NATION_REQUEST,
    token,
  };
}
function nationRequestSuccess(getAll) {
  return {
    type: nationTypes.NATION_REQUEST_SUCCESS,
    getAll,
  };
}
function nationRequestFailure(error) {
  return {
    type: nationTypes.NATION_REQUEST_FAILURE,
    error,
  };
}

//Save nation
function nationSaveRequest(request, token) {
  return {
    type: nationTypes.NATION_SAVE_REQUEST,
    request,
    token,
  };
}
function nationSaveRequestSuccess(message, value) {
  return {
    type: nationTypes.NATION_SAVE_REQUEST_SUCCESS,
    message,
    value,
  };
}
function nationSaveRequestFailure(error) {
  return {
    type: nationTypes.NATION_SAVE_REQUEST_FAILURE,
    error,
  };
}

//update nation
function nationEditRequest(query, payload, token) {
  return {
    type: nationTypes.NATION_EDIT_REQUEST,
    query,
    payload,
    token,
  };
}
function nationEditRequestSuccess(message, value) {
  return {
    type: nationTypes.NATION_EDIT_REQUEST_SUCCESS,
    message,
    value,
  };
}
function nationEditRequestFailure(error) {
  return {
    type: nationTypes.NATION_EDIT_REQUEST_FAILURE,
    error,
  };
}

//IsActive nation
function nationUpdateStatusRequest(query, payload, token) {
  return {
    type: nationTypes.NATION_UPDATE_STATUS_REQUEST,
    query,
    payload,
    token,
  };
}
function nationUpdateStatusRequestSuccess(id, message) {
  return {
    type: nationTypes.NATION_UPDATE_STATUS_REQUEST_SUCCESS,
    id,
    message,
  };
}
function nationUpdateStatusRequestFailure(error) {
  return {
    type: nationTypes.NATION_UPDATE_STATUS_REQUEST_FAILURE,
    error,
  };
}
//reset
function nationRequestReset() {
  return {
    type: nationTypes.NATION_REQUEST_RESET,
  };
}
