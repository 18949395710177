import {
  addTaskTypeCmer,
  lookupGroupsSubgroupsType,
} from "../types/addTask.type";

/*Add task */
const initialGroupSubgroupState = {
  loading: false,
  getAll: [],

  // groups, subgroups
  // getAll: {
  //   groups: [],
  //   subgroups: [],
  // },
  // groups: [],
  // subgroups: [],
};

export const lookupGroupsSubgroupsReducer = (
  state = initialGroupSubgroupState,
  action
) => {
  switch (action.type) {
    case lookupGroupsSubgroupsType.LOOKUP_GROUP_SUBGROUP_REQUEST:
      return { ...initialState, loading: true };
    case lookupGroupsSubgroupsType.LOOKUP_GROUP_SUBGROUP_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
      };
    case lookupGroupsSubgroupsType.LOOKUP_GROUP_SUBGROUP_FAILURE:
      return { ...initialState, error: action.error };
    case lookupGroupsSubgroupsType.LOOKUP_GROUP_SUBGROUP_RESET:
      return { ...initialState };
    default:
      return state;
  }
};

/*Add task */
const initialState = {
  loading: false,
};

export const addTaskReducerCmer = (state = initialState, action) => {
  switch (action.type) {
    case addTaskTypeCmer.ADD_TASK_CMER_REQUEST:
      return { ...initialState, loading: true };
    case addTaskTypeCmer.ADD_TASK_CMER_REQUEST_SUCCESS:
      return {
        ...initialState,
        message: action.message,
      };
    case addTaskTypeCmer.ADD_TASK_CMER_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    case addTaskTypeCmer.ADD_TASK_CMER_REQUEST_RESET:
      return { ...initialState };
    default:
      return state;
  }
};
