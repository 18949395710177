import { dashboardReportTypes } from "../types";

export const dashboardReportActions = {
  dashboardReportRequest,
  dashboardReportRequestSuccess,
  dashboardReportRequestFailure,
};
function dashboardReportRequest(request, token) {
  return {
    type: dashboardReportTypes.DASHBOARD_REPORT_REQUEST,
    request,
    token,
  };
}
function dashboardReportRequestSuccess(getAll) {
  return {
    type: dashboardReportTypes.DASHBOARD_REPORT_REQUEST_SUCCESS,
    getAll,
  };
}
function dashboardReportRequestFailure(error) {
  return {
    type: dashboardReportTypes.DASHBOARD_REPORT_REQUEST_FAILURE,
    error,
  };
}
