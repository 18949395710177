import { patientDetailsType } from "../types";

export const getPatientDetailsActions = {
  getPatientDetailsRequest,
  getPatientDetailsRequestSuccess,
  getPatientDetailsRequestFailure,
};
function getPatientDetailsRequest(request, token) {
  return {
    type: patientDetailsType.GET_PATIENT_DETAILS_REQUEST,
    request,
    token,
  };
}
function getPatientDetailsRequestSuccess(details) {
  return {
    type: patientDetailsType.GET_PATIENT_DETAILS_REQUEST_SUCCESS,
    details,
  };
}
function getPatientDetailsRequestFailure(error) {
  return {
    type: patientDetailsType.GET_PATIENT_DETAILS_REQUEST_FAILURE,
    error,
  };
}
