import { put, call, debounce } from "@redux-saga/core/effects";
import { visitDetailsCMActions } from "../actions/visitDetails.action";
import { visitDetailsCMType } from "../types/visitDetails.type";
import { loginActions } from "../../../Login/actions/login.action";
import { Api2 } from "../../../../api/Api2";
import { endpoint } from "../../../../api/Url";
export function* watchHospitalAdmissionPatientVisitsCM() {
  yield debounce(500, visitDetailsCMType.VISIT_DETAILS_CM_REQUEST, getCMVisits);
}

function* getCMVisits(action) {
  try {
    //   api call
    const response = yield call(Api2, {
      endpoint: `${endpoint.hospitalAdmissionVisitDetailsCM}?PatientID=${action.request.patientID}&Pageindex=${action.request.page}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });

    yield put(visitDetailsCMActions.visitDetailsCMSuccess(response));
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(visitDetailsCMActions.visitDetailsCMFailure(error));
  }
}
