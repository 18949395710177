import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../../themes/colors";
import { DataGrid } from "@mui/x-data-grid";
// import AppointmentModal from "./AppointmentModel";
import AddIcon from "@mui/icons-material/Add";

import { useLocation, useNavigate } from "react-router-dom";
import { getActivePatientAppointmentsTCActions } from "../actions/Appointment/getActivePatientAppointments.action";
import moment from "moment";

const PatientAppointments = ({ patientId, patientDetails, role }) => {
  const dispatch = useDispatch();
  const url = useLocation();
  console.log(url);
  const userData = useSelector((state) => state.login.userData);
  const getActivePatientAppoinments = useSelector(
    (state) => state.getActivePatientAppoinments
  );
  const [rows, setRows] = useState([]);

  useEffect(() => {
    let query = {
      patientId: patientId,
    };
    dispatch(
      getActivePatientAppointmentsTCActions.getActivePatientAppointmentsRequest(
        query,
        userData.token
      )
    );
  }, [patientId]);

  useEffect(() => {
    setRows(getActivePatientAppoinments?.getAll);
  }, [getActivePatientAppoinments]);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const timeZoneCurrent = useSelector((state) => state.timeZoneCurrent);

  const columns = useMemo(
    () => [
      {
        // field: "PhysicianName",
        field: "PcpName",
        // headerName: "PCP Name",
        headerName: "Provider Name",
        width: 400,
        flex: 0.08,
      },
      { field: "Date", headerName: "Date", width: 200, flex: 0.08 },
      {
        field: "StartTime",
        headerName: "Start Time",
        width: 200,
        flex: 0.08,
        renderCell: (params) => {
          const utcDate = moment
            .tz(params?.value, timeZoneCurrent?.timeZone)
            .format("hh:mm A");
          // const utcDate = new Date(params?.value);
          // const localTimeString = utcDate.toLocaleString("en-US", {
          //   hour: "numeric",
          //   minute: "numeric",

          //   hour12: true, // Set to true to display in AM/PM format
          //   timeZone: "America/New_York", // Set the desired timezone
          // });

          return `${utcDate}`;
        },
      },
      {
        field: "EndTime",
        headerName: "End Time",
        width: 200,
        flex: 0.08,
        renderCell: (params) => {
          // const utcDate = new Date(params?.value);
          const utcDate = moment
            .tz(params?.value, timeZoneCurrent?.timeZone)
            .format("hh:mm A");

          // const localTimeString = utcDate.toLocaleString("en-US", {
          //   hour: "numeric",
          //   minute: "numeric",

          //   hour12: true, // Set to true to display in AM/PM format
          //   timeZone: "America/New_York", // Set the desired timezone
          // });

          return `${utcDate}`;
          // return `${localTimeString}`;
        },
      },
      {
        field: "Reason",
        headerName: "Reason",
        width: 200,
        flex: 0.08,
        renderCell: (params) => {
          const Reason = params?.row?.Reason || "";
          return `${Reason.charAt(0).toUpperCase()}${Reason.slice(
            1
          ).toLowerCase()}`;
        },
      },
    ],
    []
  );

  const navigate = useNavigate();
  return (
    <Box sx={{ pl: 3, py: 1 }}>
      {/* <AppointmentModal handleClose={handleClose} open={showAppointmentModal} /> */}
      <Grid
        container
        sx={{
          p: 1,
          backgroundColor: colors?.color_background,
          borderRadius: 2,
        }}
      >
        <Grid
          container
          item
          // sx={{ pb: 0.5, px: 0.5, backgroundColor: colors?.color_background }}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid
            item
            sx={{ pb: 0.5, pl: 0.5, backgroundColor: colors?.color_background }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              {role === "nursing" ? "Follow Ups" : "Appointments"}
            </Typography>
          </Grid>
          <Grid
            item
            sx={{ pb: 0.5, backgroundColor: colors?.color_background }}
          >
            <Tooltip title="Click to add appoinment">
              <Button
                variant="contained"
                sx={{
                  background: colors.color_primary_txt,
                  "&:hover": {
                    background: colors.color_primary_txt,
                  },
                  mb: 0.5,
                }}
                onClick={() => {
                  setShowAppointmentModal(true);
                  // navigate(`/transitioncare/${patientId}/appointment`);
                  const state = {
                    patientDetails,
                  };
                  if (role === "nursing") {
                    navigate(`/facility/${patientId}/schedule`, {
                      state,
                    });
                  } else {
                    navigate(`/transitioncare/${patientId}/schedule`, {
                      state,
                    });
                  }
                }}
              >
                <AddIcon
                  sx={{ color: colors.color_primary, fontSize: "20px" }}
                />{" "}
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container>
          <DataGrid
            sx={{
              backgroundColor: "#FFFFFF",
              height: "35vh",
              overflow: "scroll",
              "&::-webkit-scrollbar": {
                width: "5px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555",
              },
            }}
            rows={rows || []}
            columns={columns || []}
            loading={getActivePatientAppoinments?.loading}
            getRowId={(row) => row?.PatientAppointmentId}
            hideFooter
            density="compact"
            disableSelectionOnClick // Disables selection on click
            disableColumnSelector
            disableRowSelectionOnClick // Disables row selection on click
            pageSize={100} // rowsPerPageOptions={[5]}
            pagination
          />
        </Grid>
      </Grid>
      {/* medication details end */}
    </Box>
  );
};

export default memo(PatientAppointments);
