import { relationshipLookupCmerType } from "../types/relationshipLookupCmer.type";

const initialState = {
  loading: false,
  getAll: [],
};

export const relationshiplistLookupCmerReducer = (state = initialState, action) => {
  switch (action.type) {
    case relationshipLookupCmerType.RELATIONSHIP_LOOKUP_CMER_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case relationshipLookupCmerType.RELATIONSHIP_LOOKUP_CMER_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
        loading: false,
      };
    case relationshipLookupCmerType.RELATIONSHIP_LOOKUP_CMER_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    default:
      return state;
  }
};
