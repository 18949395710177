import { userReportsListTypes } from "../types/getUserReportsList.type";

export const userReportsListActions = {
  userReportsListRequest,
  userReportsListRequestSuccess,
  userReportsListRequestFailure,
};

function userReportsListRequest(request, token) {
  return {
    type: userReportsListTypes.USER_REPORTSLIST_REQUEST,
    request,
    token,
  };
}

function userReportsListRequestSuccess(getReports) {
  return {
    type: userReportsListTypes.USER_REPORTSLIST_REQUEST_SUCCESS,
    getReports,
  };
}

function userReportsListRequestFailure(error) {
  return {
    type: userReportsListTypes.USER_REPORTSLIST_REQUEST_SUCCESS,
    error,
  };
}
