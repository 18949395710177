import * as Yup from "yup";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  Button,
  Box,
  Grid,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { pageActions } from "../../../store/actions";
import StringTextField from "../../../components/text-field/stringType/StringTextField";
import DatePickerField from "../../../components/text-field/date/DatePickerField";
import {
  getPatientListForUpdateRequest,
  getPatientListForUpdateSuccess,
} from "./patientUpdate.store/slice/getPatientList.slice";
import {
  patientUpdateRequest,
  patientUpdateReset,
} from "./patientUpdate.store/slice/patientUpdate.slice";
import { Spinner } from "../../../core";
import { colors } from "../../../themes/colors";
import { MdEditNote } from "react-icons/md";
import dayjs from "dayjs";
import moment from "moment";
import { Progress } from "../../../components";
import { alertActions } from "../../../components/AlertNew/store/AlertNew.action";

const PatientUpdate = () => {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.login.userData);
  const patientUpdate = useSelector((state) => state.patientUpdate);
  const getPatientListForUpdate = useSelector(
    (state) => state.getPatientListForUpdate
  );

  const [editingRowId, setEditingRowId] = useState(null);
  const [disabledRowId, setDisabledRowId] = useState(null);
  const [dos, setDos] = useState(
    moment().subtract(1, "days").format("YYYY-MM-DD")
  );
  const yupPatientUpdateSchema = Yup.object().shape({
    zipCode: Yup.string().required("ZIP is required"), // Required ZIP
    dob: Yup.string().required("DOB is required"), // Required DOB
    firstName: Yup.string().required("First Name is required"), // Required First Name
    lastName: Yup.string().required("Last Name is required"), // Required Last Name
  });

  const [editedRow, setEditedRow] = useState({});
  const handleEditClick = (row) => {
    setEditingRowId(row.AssignmentId);
    setEditedRow(row);
  };
  const [errors, setErrors] = useState({});
  const handleSave = async () => {
    try {
      const validatedData = await yupPatientUpdateSchema.validate(editedRow, {
        abortEarly: false,
      });
      console.log(validatedData);
      let request = {
        assignmentId: `${editedRow?.AssignmentId}`,
        patientId: `${editedRow?.patientId}`,
        athenaMRN: editedRow?.athenaMRN ? `${editedRow?.athenaMRN}` : "",
        hcaMRN: editedRow?.hcaMRN,
        firstName: editedRow?.firstName,
        lastName: editedRow?.lastName,

        middleName: editedRow?.middleName,
        dob: editedRow?.dob
          ? `${dayjs(editedRow?.dob).format("YYYY/MM/DD")}`
          : "",
        // dos: editedRow?.DOS,
        dos: dos,
        zipcode: editedRow?.zipCode ? `${editedRow?.zipCode}` : "",
        token: userData.token,
      };
      dispatch(patientUpdateRequest(request));
      setDisabledRowId(editedRow);
      setErrors({});
    } catch (validationErrors) {
      // If validation fails, collect and display errors
      const formattedErrors = {};
      validationErrors.inner.forEach((error) => {
        formattedErrors[error.path] = error.message;
      });
      setErrors(formattedErrors);
    }
  };
  const columns = [
    { field: "patientId", headerName: "PatientId", width: 100 },
    { field: "DOS", headerName: "DOS", width: 100 },
    { field: "lastName", headerName: "Last Name", width: 150 },
    { field: "middleName", headerName: "Middle Name", width: 130 },
    { field: "firstName", headerName: "First Name", width: 150 },
    { field: "dob", headerName: "DOB", width: 190 },
    { field: "zipCode", headerName: "Zip", width: 90 },
    { field: "hcaMRN", headerName: "HCA", width: 140 },
    { field: "athenaMRN", headerName: "Athena", width: 100 },
    { field: "actions", headerName: "Actions", width: 300 },
  ];

  const handleCancel = () => {
    setEditingRowId(null);
    setEditedRow({});
    setErrors({});
  };

  const handleChange = useCallback((field, value) => {
    setEditedRow((prev) => ({ ...prev, [field]: value }));
  }, []);

  const handleDateChange = useCallback((field, value) => {
    setEditedRow((prev) => ({ ...prev, [field]: value?.split("T")[0] }));
  }, []);

  const [pageState, setPageState] = useState({
    page: 1,
    pageSize: 50,
  });

  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 25,
    all: false,
    search: "",
    dos: dos,
  });

  useEffect(() => {
    if (query.search.length === 0) {
      let request = {
        ...query,
        search: query.search.length,
        token: userData.token,
      };
      dispatch(getPatientListForUpdateRequest(request));
    }
  }, [query?.search]);

  useEffect(() => {
    let request = { ...query, token: userData.token };
    dispatch(getPatientListForUpdateRequest(request));
    dispatch(pageActions?.pageRequestSuccess("Patient Update"));
  }, [userData.token]);

  // Assuming getPatientListForUpdate?.getAll is your array of patients
  const updatePatientData = (patientList, editingRowId, newData) => {
    // Find the index of the patient to update
    const indexOfPatient = patientList.findIndex(
      (patient) => patient.AssignmentId === editingRowId
    );

    // If patient is found, update their data
    if (indexOfPatient !== -1) {
      // Create a new array with the updated patient data
      const updatedPatientList = [
        ...patientList.slice(0, indexOfPatient),
        { ...patientList[indexOfPatient], ...newData },
        ...patientList.slice(indexOfPatient + 1),
      ];

      return updatedPatientList;
    }

    // If patient is not found, return the original list
    return patientList;
  };

  // Usage /* your new patient data */;
  useEffect(() => {
    if (patientUpdate?.message?.toLowerCase().includes("successfully")) {
      let data = updatePatientData(
        getPatientListForUpdate?.getAll,
        editingRowId,
        { ...editedRow, dob: `${dayjs(editedRow?.dob).format("MM/DD/YYYY")}` }
      );
      dispatch(getPatientListForUpdateSuccess({ patients: data }));
      dispatch(alertActions.alertSuccessRequest(patientUpdate?.message));
      dispatch(patientUpdateReset());
      setEditingRowId(null);
    }
  }, [patientUpdate?.message]);

  console.log(editedRow, "editedRow");
  return (
    <Box sx={{ padding: 2 }}>
      <Box
        sx={{
          width: "100%",
          overflow: "hidden",
          backgroundColor: colors.color_background,
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            // height: "calc(100vh - 120px)",
            overflow: "auto",
            "& .MuiTableCell-root": {
              whiteSpace: "nowrap",
              padding: "8px 16px",
            },
            "&::-webkit-scrollbar": {
              width: "8px",
              height: "8px",
            },
          }}
        >
          <Table
            stickyHeader
            sx={
              {
                // minHeight: "80vh",
              }
            }
          >
            <TableHead>
              <TableRow>
                {columns?.map((col) => (
                  <TableCell
                    key={col.field}
                    sx={{
                      backgroundColor: colors.color_primary_txt,
                      color: colors.color_primary,
                      minWidth: col.width || "auto",
                      fontWeight: "",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    {col.headerName || ""}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {/* <TableBody
              sx={{
                minHeight: "80vh",
              }}
            >
              {getPatientListForUpdate?.loading ? (
                <>
                  <TableRow>
                    <TableCell colSpan={columns?.length}>
                      <Progress />
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <>
                  {getPatientListForUpdate?.getAll?.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:nth-of-type(odd)": {
                          backgroundColor: colors.color_background,
                        },
                        "&:hover": {
                          backgroundColor: `${colors.color_background}99`,
                        },
                      }}
                    >
                      {columns?.map((col) => (
                        <TableCell
                          key={`${row.id}-${col.field}`}
                          sx={{
                            minWidth: col.width || "auto",
                          }}
                        >
                          {editingRowId === row.AssignmentId ? (
                            <>
                              {col.field === "dob" ? (
                                <DatePickerField
                                  value={
                                    editedRow?.dob
                                      ? dayjs(editedRow?.dob)
                                      : null
                                  }
                                  onChange={(date) => {
                                    const parsedDate = dayjs(date);
                                    handleDateChange(
                                      "dob",
                                      parsedDate.toISOString()
                                    );
                                  }}
                                  isError={errors?.dob}
                                  placeholder="DOB"
                                  variant="outlined"
                                  size="small"
                                />
                              ) : col.field === "actions" ? (
                                <Grid
                                  container
                                  justifyContent="flex-start"
                                  columnSpacing={1}
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <Button
                                      disabled={patientUpdate?.loading}
                                      sx={{
                                        textTransform: "capitalize",
                                        // width: "100%",
                                        background: colors.color_primary_txt,
                                        color: colors.color_primary,
                                        fontSize: 10,
                                        "&:hover": {
                                          background: colors.color_primary_txt,
                                          color: colors.color_primary,
                                        },
                                      }}
                                      variant="contained"
                                      size="small"
                                      onClick={handleSave}
                                      startIcon={
                                        patientUpdate?.loading && (
                                          <Spinner height={12} width={12} />
                                        )
                                      }
                                    >
                                      {patientUpdate?.loading
                                        ? "Updating"
                                        : "Update"}
                                    </Button>
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      variant="outlined"
                                      fullWidth
                                      sx={{
                                        // width: "100%",
                                        backgroundColor: colors.color_primary,
                                        color: colors.color_primary_txt,
                                        borderColor: colors.color_primary_txt,
                                        textTransform: "capitalize",
                                        fontSize: 10,
                                        transition: "0.3s",
                                        "&:hover": {
                                          color: colors.color_primary_txt,
                                          borderColor: colors.color_primary_txt,
                                          opacity: 0.8,
                                        },
                                        "&:disabled": {
                                          color: colors.color_primary_txt,
                                          borderColor: colors.color_primary_txt,
                                          opacity: 0.8,
                                        },
                                      }}
                                      size="small"
                                      onClick={handleCancel}
                                    >
                                      Cancel
                                    </Button>
                                  </Grid>
                                </Grid>
                              ) : (
                                <StringTextField
                                  value={editedRow[col.field] || ""}
                                  onChange={(e) =>
                                    handleChange(col.field, e.target.value)
                                  }
                                  variant="outlined"
                                  placeholder={col.headerName || "Enter value"}
                                  size="small"
                                  isError={errors?.hasOwnProperty(col.field)}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              {col.field !== "actions" ? (
                                row[col.field]
                              ) : (
                                <Grid container>
                                  <Grid item>
                                    <Tooltip title="Edit Patient Details">
                                      <Button
                                        variant="text"
                                        sx={{
                                          textTransform: "capitalize",
                                          color: colors.color_primary_txt,
                                          "&:hover": {
                                            color: colors.color_primary_txt,
                                            borderColor:
                                              colors.color_primary_txt,
                                            backgroundColor: "transparent",
                                            opacity: 0.8,
                                          },
                                        }}
                                        onClick={() => handleEditClick(row)}
                                      >
                                        <MdEditNote size={20} />
                                      </Button>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              )}
                            </>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody> */}

            <TableBody
              sx={{
                minHeight: "80vh",
              }}
            >
              {getPatientListForUpdate?.loading ? (
                <TableRow>
                  <TableCell colSpan={columns?.length}>
                    <Progress />
                  </TableCell>
                </TableRow>
              ) : (
                getPatientListForUpdate?.getAll?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:nth-of-type(odd)": {
                        backgroundColor: colors.color_background,
                      },
                      "&:hover": {
                        backgroundColor: `${colors.color_background}99`,
                      },
                    }}
                  >
                    {columns?.map((col) => (
                      <TableCell
                        key={`${row.id}-${col.field}`}
                        sx={{
                          minWidth: col.width || "auto",
                        }}
                      >
                        {editingRowId === row.AssignmentId
                          ? (() => {
                              switch (col.field) {
                                case "dob":
                                  return (
                                    <DatePickerField
                                      value={
                                        editedRow?.dob
                                          ? dayjs(editedRow?.dob)
                                          : null
                                      }
                                      onChange={(date) => {
                                        const parsedDate = dayjs(date);
                                        handleDateChange(
                                          "dob",
                                          parsedDate.toISOString()
                                        );
                                      }}
                                      isError={errors?.dob}
                                      placeholder="DOB"
                                      variant="outlined"
                                      size="small"
                                    />
                                  );

                                case "actions":
                                  return (
                                    <Grid
                                      container
                                      justifyContent="flex-start"
                                      columnSpacing={1}
                                      alignItems="center"
                                    >
                                      <Grid item>
                                        <Button
                                          disabled={patientUpdate?.loading}
                                          sx={{
                                            textTransform: "capitalize",
                                            background:
                                              colors.color_primary_txt,
                                            color: colors.color_primary,
                                            fontSize: 10,
                                            "&:hover": {
                                              background:
                                                colors.color_primary_txt,
                                              color: colors.color_primary,
                                            },
                                          }}
                                          variant="contained"
                                          size="small"
                                          onClick={handleSave}
                                          startIcon={
                                            patientUpdate?.loading && (
                                              <Spinner height={12} width={12} />
                                            )
                                          }
                                        >
                                          {patientUpdate?.loading
                                            ? "Updating"
                                            : "Update"}
                                        </Button>
                                      </Grid>
                                      <Grid item>
                                        <Button
                                          variant="outlined"
                                          fullWidth
                                          sx={{
                                            backgroundColor:
                                              colors.color_primary,
                                            color: colors.color_primary_txt,
                                            borderColor:
                                              colors.color_primary_txt,
                                            textTransform: "capitalize",
                                            fontSize: 10,
                                            transition: "0.3s",
                                            "&:hover": {
                                              color: colors.color_primary_txt,
                                              borderColor:
                                                colors.color_primary_txt,
                                              opacity: 0.8,
                                            },
                                            "&:disabled": {
                                              color: colors.color_primary_txt,
                                              borderColor:
                                                colors.color_primary_txt,
                                              opacity: 0.8,
                                            },
                                          }}
                                          size="small"
                                          onClick={handleCancel}
                                        >
                                          Cancel
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  );
                                case "DOS":
                                case "patientId":
                                  return (
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontSize: "0.875rem",
                                        color: errors?.hasOwnProperty(col.field)
                                          ? "error.main"
                                          : "text.primary",
                                      }}
                                    >
                                      {editedRow[col.field] || ""}
                                    </Typography>
                                  );

                                default:
                                  return (
                                    <StringTextField
                                      value={editedRow[col.field] || ""}
                                      onChange={(e) =>
                                        handleChange(col.field, e.target.value)
                                      }
                                      variant="outlined"
                                      placeholder={
                                        col.headerName || "Enter value"
                                      }
                                      size="small"
                                      isError={errors?.hasOwnProperty(
                                        col.field
                                      )}
                                    />
                                  );
                              }
                            })()
                          : (() => {
                              switch (col.field) {
                                case "actions":
                                  return (
                                    <Grid container>
                                      <Grid item>
                                        <Tooltip title="Edit Patient Details">
                                          <Button
                                            variant="text"
                                            sx={{
                                              textTransform: "capitalize",
                                              color: colors.color_primary_txt,
                                              "&:hover": {
                                                color: colors.color_primary_txt,
                                                borderColor:
                                                  colors.color_primary_txt,
                                                backgroundColor: "transparent",
                                                opacity: 0.8,
                                              },
                                            }}
                                            onClick={() => handleEditClick(row)}
                                          >
                                            <MdEditNote size={20} />
                                          </Button>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  );
                                default:
                                  return row[col.field];
                              }
                            })()}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default PatientUpdate;
