import { put, takeLatest, call, debounce } from "@redux-saga/core/effects";
import {
  addTaskActions,
  addTaskActionsCmer,
  lookupGroupsSubgroupsActions,
} from "../actions/addTask.action";
import { loginActions } from "../../../Login/actions/login.action";

import {
  addTaskTypeCmer,
  lookupGroupsSubgroupsType,
} from "../types/addTask.type";

// import { Api } from "../../../../api";
import { Api2 } from "../../../../api/Api2";
import { endpoint } from "../../../../api/Url";
import { Api } from "../../../../api";
import { alertActions } from "../../../../components/AlertNew/store/AlertNew.action";

export function* watchCmerAddTask() {
  yield takeLatest(
    lookupGroupsSubgroupsType.LOOKUP_GROUP_SUBGROUP_REQUEST,
    handlelookupGroupSubgroup
  );
  yield debounce(500, addTaskTypeCmer.ADD_TASK_CMER_REQUEST, addTask);
}

/* Lookup */
function* handlelookupGroupSubgroup(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.cmer}/lookup/tasks-groups-subgroups`,
      jwt: "Bearer " + action.token,
    });
    yield put(
      lookupGroupsSubgroupsActions.lookupGroupsSubgroupsRequestSuccess(response)
    );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(
      lookupGroupsSubgroupsActions.lookupGroupsSubgroupsRequestFailure(error)
    );
  }
}

function* addTask(action) {
  try {
    console.log(action);
    const endpointUrl = `${endpoint.addTaskCmer}${
      action?.request?.Flag === "Delete" || action?.request?.Flag === "Update"
        ? `/${action?.request?.TaskID || ""}`
        : ""
    }`;
    console.log(endpointUrl, "endpointUrl");
    const method =
      action?.request?.Flag === "Delete"
        ? "DELETE"
        : action?.request?.Flag === "Update"
        ? "PATCH"
        : "POST";
    const body =
      action?.request?.Flag === "Delete"
        ? undefined
        : {
            patientId: action.request?.patientId,
            date: action.request?.date,
            note: action.request?.note,
            groupId: action.request?.groupId,
            subgroupId: action.request?.subgroupId,
            encounterId: action.request?.encounterId,
          };

    const response = yield call(Api2, {
      endpoint: endpointUrl,
      method: method,
      jwt: "Bearer " + action.token,
      body: body,
    });
    console.log(response, "response");
    yield put(addTaskActionsCmer.addTaskCmerRequestSuccess(response?.message));
    // yield put(alertActions?.alertSuccessRequest(response?.message));
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(addTaskActionsCmer.addTaskCmerRequestFailure(error));
  }
}
