import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { colors } from "../../themes/colors";

const ConfirmDialog = ({
  open,
  handleDialogClose,
  handleDiscard,
  message,
  title,
}) => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          handleDialogClose();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ p: 2, borderRadius: 2 }}
    >
      <DialogTitle id="alert-dialog-title">
        {title || `Delete Confirmation`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message || ` Are you sure you want to delete this?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleDiscard}
          variant="outlined"
          sx={{
            borderColor: colors?.color_primary_txt,
            color: colors?.color_primary_txt,
            ":hover": {
              borderColor: colors?.color_primary_txt,
              color: colors?.color_primary_txt,
            },
          }}
        >
          No
        </Button>
        <Button
          variant="contained"
          onClick={handleDialogClose}
          sx={{
            backgroundColor: colors?.color_primary_txt,
            borderColor: colors?.color_primary_txt,

            ":hover": {
              backgroundColor: colors?.color_primary_txt,
              borderColor: colors?.color_primary_txt,
            },
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
