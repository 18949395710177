import { call, put, takeLatest } from "@redux-saga/core/effects";
import { permissionTypes } from "../types";
import { Api2 } from "../../api/Api2";
import { endpoint } from "../../api/Url";
import { permissionActions } from "../actions";
export function* watchPermissions() {
  yield takeLatest(permissionTypes.PERMISSION_GETALL_REQUEST, getAllPermission);
}
function* getAllPermission(action) {
  try {
    const res = yield call(Api2, {
      endpoint: endpoint.authPermissions,
      method: "GET",
      jwt: "Bearer " + action.token,
    });
    if (res.data !== undefined && res.data.length >= 0) {
      yield put(permissionActions.permissionGetAllRequestSuccess(res.data));
    } else {
      yield put(
        permissionActions.permissionGetAllRequestFailure({
          message: "No Data Found",
        })
      );
    }
  } catch (error) {
    yield put(permissionActions.permissionGetAllRequestFailure(error));
  }
}
