import { lazy } from "react";
import Loadable from "../components/Loadable";
import PatientBasicInfoCM from "../pages/cm_New/patientBasicInfo";
import CallHistoryCmRole from "../pages/cm_New/CallHistoryCmRole";
import CMTasksDetails from "../pages/cm_New/TaskDetails";
import ClinicalInformationCM from "../pages/cm_New/ClinicalInformationCM";
import CMVisitDetails from "../pages/cm_New/HospitalAdmissions";

const BasicInfo = Loadable(lazy(() => import("../pages/cmer/BasicInfo")));
const CallHistory = Loadable(
  lazy(() => import("../pages/cm/EditPatient/CallHistory"))
);
const Charges = Loadable(lazy(() => import("../pages/cm/EditPatient/Charges")));

const TasksDetails = Loadable(lazy(() => import("../pages/cmer/TaskDetails")));
const VisitDetails = Loadable(
  lazy(() => import("../pages/cm/EditPatient/VisitDetails"))
);
const Assessments = Loadable(
  lazy(() => import("../pages/cm/EditPatient/Assessments"))
);
const EpisodicCost = Loadable(
  lazy(() => import("../pages/cm/EditPatient/EpisodicCost"))
);
const Medication = Loadable(
  lazy(() => import("../pages/cm/EditPatient/Medication"))
);
const ClinicalInformationCmer = Loadable(
  lazy(() => import("../pages/cmer/ClinicalInformation"))
);
// const Diagnosis = Loadable(
//   lazy(() => import("../pages/cm/EditPatient/Diagnosis"))
// );

const AdvanceDirective = Loadable(
  lazy(() => import("../pages/maw/AdvanceDirective"))
);

const CarePlan = Loadable(
  lazy(() => import("../pages/cm/EditPatient/CarePlan"))
);
const CCM = Loadable(
  lazy(() => import("../pages/cm/EditPatient/CronicCareManager"))
);

//awv
const Immunization = Loadable(lazy(() => import("../pages/maw/Immunization")));
const SurgicalHistory = Loadable(
  lazy(() => import("../pages/maw/SurgicalHistory"))
);
const Hospitalization = Loadable(
  lazy(() => import("../pages/maw/Hospitalization"))
);
const BiometricMeasurments = Loadable(
  lazy(() => import("../pages/maw/BiometricMeasurments"))
);
const ProblemList = Loadable(lazy(() => import("../pages/maw/ProblemList")));
const ScreeningPreventiveExam = Loadable(
  lazy(() => import("../pages/maw/ScreeningPreventiveExam"))
);
const LabTests = Loadable(lazy(() => import("../pages/maw/LabTests")));
const EducationProvided = Loadable(
  lazy(() => import("../pages/maw/EducationProvided"))
);
const AssessmentsReferrals = Loadable(
  lazy(() => import("../pages/maw/AssessmentsReferrals"))
);
const Recommendations = Loadable(
  lazy(() => import("../pages/maw/Recommendations"))
);
const CallHistoryCmer = Loadable(
  lazy(() => import("../pages/cmer/CallHistoryCmer"))
);

const FamilyHistory = Loadable(
  lazy(() => import("../pages/maw/FamilyHistory"))
);
const PrintReport = Loadable(lazy(() => import("../pages/maw/PrintReport")));
const Allergies = Loadable(lazy(() => import("../pages/maw/Allergies")));
const ComprehensiveSystemAssessment = Loadable(
  lazy(() => import("../pages/maw/ComprehensiveSystemAssessment"))
);
const AWVMenu = Loadable(lazy(() => import("../pages/Dynamic/AWVMenu")));

export const PatientDetailsRoutes = [
  {
    menuCode: "cm-patientdetails",
    // element: <BasicInfo />,
    element: <PatientBasicInfoCM />,
  },
  {
    menuCode: "cm-callhistory",
    element: <CallHistoryCmRole />,
  },
  {
    menuCode: "cm-taskpoc",
    element: <CMTasksDetails />,
  },
  {
    menuCode: "cm-clinicalinformation",
    // element: <Diagnosis />,
    element: <ClinicalInformationCM />,
  },
  {
    menuCode: "cm-hospitaladmissions",
    element: <CMVisitDetails />,
  },
  {
    menuCode: "patientdetails",
    element: <BasicInfo />,
  },
  {
    menuCode: "medication",
    element: <Medication />,
  },
  {
    menuCode: "callhistory",
    element: <CallHistoryCmer />,
  },
  // {
  //   menuCode: "callhistory",
  //   element: <CallHistory />,
  // },
  {
    menuCode: "taskpoc",
    element: <TasksDetails />,
  },
  {
    menuCode: "episodiccost",
    element: <EpisodicCost />,
  },
  {
    menuCode: "hositaladmissions",
    element: <VisitDetails />,
  },
  {
    menuCode: "ccm",
    element: <CCM />,
  },
  {
    menuCode: "awv",
    element: <AWVMenu />,
  },
  {
    menuCode: "clinicalinformation",
    // element: <Diagnosis />,
    element: <ClinicalInformationCmer />,
  },
  {
    menuCode: "demographics",
    element: <BasicInfo />,
  },
  {
    menuCode: "familyhistory",
    element: <FamilyHistory />,
  },
  {
    menuCode: "advancedirective",
    element: <AdvanceDirective />,
  },
  {
    menuCode: "allergies",
    element: <Allergies />,
  },
  {
    menuCode: "surgicalhistory",
    element: <SurgicalHistory />,
  },
  {
    menuCode: "hospitalization",
    element: <Hospitalization />,
  },
  {
    menuCode: "medications",
    element: <Medication />,
  },
  {
    menuCode: "comprehensivesystemassessment",
    element: <ComprehensiveSystemAssessment />,
  },
  {
    menuCode: "biometricmeasurments",
    element: <BiometricMeasurments />,
  },
  {
    menuCode: "funtionalassessments",
    element: <Assessments />,
  },
  {
    menuCode: "screeningassessment",
    element: <Assessments />,
  },
  {
    menuCode: "problemlist",
    element: <ProblemList />,
  },
  {
    menuCode: "educationprovided",
    element: <EducationProvided />,
  },
  {
    menuCode: "print",
    element: <PrintReport />,
  },
  {
    menuCode: "careplan",
    element: <CarePlan />,
  },
  {
    menuCode: "charges",
    element: <Charges />,
  },
  {
    menuCode: "immunization",
    element: <Immunization />,
  },
];
