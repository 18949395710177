import { pcpLookupCmerTypes } from "../types";

export const pcpLookupCmerActions = {
  pcpLookupRequest,
  pcpLookupSuccess,
  pcpLookupFailure,
};
function pcpLookupRequest(request, token) {
  return {
    type: pcpLookupCmerTypes.PCP_LOOKUP_CMER_REQUEST,
    request,
    token,
  };
}
function pcpLookupSuccess(getAll) {
  return {
    type: pcpLookupCmerTypes.PCP_LOOKUP_CMER_SUCCESS,
    getAll,
  };
}
function pcpLookupFailure(error) {
  return {
    type: pcpLookupCmerTypes.PCP_LOOKUP_CMER_FAILURE,
    error,
  };
}
