import { Button, Grid, Tooltip, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../../../themes/colors";
import { ArrowLeftIcon, ArrowRightIcon } from "@mui/x-date-pickers";
import EventNoteIcon from "@mui/icons-material/EventNote";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonIcon from "@mui/icons-material/Person";


const CustomToolBar = ({
  label,
  onNavigate,
  onView,
  currentView,
  setCurrentView,
  physicianName,
  getScheduleAppoinments,
  appointmentDetails,
}) => {
  const navigate = (action) => {
    onNavigate(action);
  };
  const view = (action) => {
    onView(action);
  };

  return (
    <Grid
      container
      item
      sx={{
        p: { xs: 1, xl: 2 },
        // py: 2,
        backgroundColor: colors?.color_background,
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        boxShadow: "0px 0px 2px grey",
      }}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Grid container item spacing={0.5} sx={{ width: "fit-content" }}>
        <Grid item>
          <Button
            size="small"
            variant="outlined"
            className=""
            sx={{
              backgroundColor:
                currentView && currentView === "week"
                  ? colors?.color_primary_txt
                  : "",
              color:
                currentView && currentView === "week"
                  ? colors?.color_primary
                  : colors?.color_primary_txt,
              "&: hover": {
                color:
                  currentView && currentView === "week"
                    ? colors?.color_primary
                    : colors?.color_primary_txt,
                backgroundColor:
                  currentView && currentView === "week"
                    ? colors?.color_primary_txt
                    : "",
                borderColor: colors?.color_primary_txt,
              },
              borderColor: colors?.color_primary_txt,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "fit-content",
              textTransform: "none",

              fontSize: 12,
            }}
            onClick={() => view("week")}
          >
            <CalendarTodayIcon
              style={{
                fontSize: 20,
                marginRight: 1,
              }}
            />
            <span style={{ textAlign: "center" }}>Week</span>
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            variant="outlined"
            className=""
            sx={{
              backgroundColor:
                currentView && currentView === "fortnight"
                  ? colors?.color_primary_txt
                  : "",
              color:
                currentView && currentView === "fortnight"
                  ? colors?.color_primary
                  : colors?.color_primary_txt,
              "&: hover": {
                color:
                  currentView && currentView === "fortnight"
                    ? colors?.color_primary
                    : colors?.color_primary_txt,
                backgroundColor:
                  currentView && currentView === "fortnight"
                    ? colors?.color_primary_txt
                    : "",
                borderColor: colors?.color_primary_txt,
              },
              borderColor: colors?.color_primary_txt,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "fit-content",
              textTransform: "none",

              fontSize: 12,
            }}
            onClick={() => view("fortnight")}
          >
            <EventNoteIcon
              style={{
                fontSize: 20,
                marginRight: 1,
              }}
            />
            <span style={{ textAlign: "center" }}>2 Weeks</span>
          </Button>
        </Grid>
        {/* //!commented code this is for month view */}
        {/* <Grid item>
          <Button
            size="small"
            variant="outlined"
            className=""
            sx={{
              backgroundColor:
                currentView && currentView === "month"
                  ? colors?.color_primary_txt
                  : "",
              color:
                currentView && currentView === "month"
                  ? colors?.color_primary
                  : colors?.color_primary_txt,
              "&: hover": {
                color:
                  currentView && currentView === "month"
                    ? colors?.color_primary
                    : colors?.color_primary_txt,
                backgroundColor:
                  currentView && currentView === "month"
                    ? colors?.color_primary_txt
                    : "",
                borderColor: colors?.color_primary_txt,
              },
              borderColor: colors?.color_primary_txt,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "fit-content",
              textTransform: "none",

              fontSize: 12,
            }}
            onClick={() => view("month")}
          >
            <CalendarTodayIcon
              style={{
                fontSize: 20,
                marginRight: 1,
              }}
            />
            <span style={{ textAlign: "center" }}>Month</span>
          </Button>
        </Grid> */}
      </Grid>
      <Grid container item sx={{ width: "fit-content" }}>
        <Grid item alignItems={"center"}>
          <span>{label}</span>
        </Grid>
      </Grid>
      {currentView !== "day" && (
        <Grid
          container
          item
          spacing={0.5}
          sx={{ width: "fit-content" }}
          justifyContent={"flex-end"}
        >
          <Grid item justifyItems={"center"}>
            <Button
              size="small"
              variant="outlined"
              className=""
              sx={{
                color: colors?.color_primary_txt,
                "&: hover": {
                  color: colors?.color_primary_txt,
                  borderColor: colors?.color_primary_txt,
                },
                borderColor: colors?.color_primary_txt,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "fit-content",
                textTransform: "none",

                fontSize: 12,
              }}
              onClick={() => navigate("PREV")}
            >
              <ArrowLeftIcon
                style={{
                  fontSize: 18,
                  marginRight: 1,
                }}
              />
              <span style={{ textAlign: "center" }}>Prev</span>
            </Button>
          </Grid>

          <Grid item>
            <Button
              size="small"
              variant="outlined"
              className=""
              sx={{
                color: colors?.color_primary_txt,
                "&: hover": {
                  color: colors?.color_primary_txt,
                  borderColor: colors?.color_primary_txt,
                },
                borderColor: colors?.color_primary_txt,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "fit-content",
                textTransform: "none",

                fontSize: 12,
              }}
              onClick={() => navigate("TODAY")}
            >
              <span style={{ textAlign: "center" }}>Today</span>
            </Button>
          </Grid>

          <Grid item>
            <Button
              size="small"
              variant="outlined"
              className=""
              sx={{
                color: colors?.color_primary_txt,
                "&: hover": {
                  color: colors?.color_primary_txt,
                  borderColor: colors?.color_primary_txt,
                },
                borderColor: colors?.color_primary_txt,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "fit-content",
                textTransform: "none",

                fontSize: 12,
              }}
              onClick={() => navigate("NEXT")}
            >
              <span style={{ textAlign: "center" }}>Next</span>
              <ArrowRightIcon
                style={{
                  fontSize: 18,
                  marginLeft: 1,
                }}
              />
            </Button>
          </Grid>
        </Grid>
      )}
      {currentView === "day" && (
        <Grid
          container
          item
          sx={{ width: "fit-content", mr: 1, justifyContent: "center" }}
          spacing={0.5}
        >
          <Grid item>
            {" "}
            <PersonIcon color="primary" style={{ fontSize: 20 }} />
          </Grid>{" "}
          <Grid item>
            <Tooltip title={appointmentDetails?.date}>
              <Typography
                variant="subtitle1"
                component={"span"}
                sx={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                {`${appointmentDetails?.provider}`}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CustomToolBar;
