import { diagnosisType } from "../types";

export const getDiagnosisActions = {
  getDiagnosisRequest,
  getDiagnosisRequestSuccess,
  getDiagnosisRequestFailure,
};
function getDiagnosisRequest(request, token) {
  return {
    type: diagnosisType.GET_DIAGNOSIS_REQUEST,
    request,
    token,
  };
}
function getDiagnosisRequestSuccess(getAll) {
  return {
    type: diagnosisType.GET_DIAGNOSIS_REQUEST_SUCCESS,
    getAll,
  };
}
function getDiagnosisRequestFailure(error) {
  return {
    type: diagnosisType.GET_DIAGNOSIS_REQUEST_FAILURE,
    error,
  };
}
