import { getAllDosageLookUpTransitionType } from "../../types";

export const getAllDosageLookUpTCActions = {
  getAllDosageLookUpRequest,
  getAllDosageLookUpSuccess,
  getAllDosageLookUpFailure,
};
function getAllDosageLookUpRequest(token) {
  return {
    type: getAllDosageLookUpTransitionType.GETALL_DOSAGE_LOOKUP_TC_REQUEST,

    token,
  };
}
function getAllDosageLookUpSuccess(getAll) {
  return {
    type: getAllDosageLookUpTransitionType.GETALL_DOSAGE_LOOKUP_TC_SUCCESS,
    getAll,
  };
}
function getAllDosageLookUpFailure(error) {
  return {
    type: getAllDosageLookUpTransitionType.GETALL_DOSAGE_LOOKUP_TC_FAILURE,
    error,
  };
}
