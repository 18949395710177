import { contactTypes } from "../types/contact.type";

export const contactActions = {
  contactRequest,
  contactRequestSuccess,
  contactRequestFailure,
};
function contactRequest(request, token) {
  return { type: contactTypes.CONTACT_REQUEST, request, token };
}
function contactRequestSuccess(getAll) {
  return { type: contactTypes.CONTACT_REQUEST_SUCCESS, getAll };
}
function contactRequestFailure(error) {
  return { type: contactTypes.CONTACT_REQUEST_FAILURE, error };
}
