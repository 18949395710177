import { patientDetailsUpdateType } from "../types";

export const updatePatientDetailsActions = {
  updatePatientDetailsRequest,
  updatePatientDetailsRequestReset,
  updatePatientDetailsRequestSuccess,
  updatePatientDetailsRequestFailure,
};
function updatePatientDetailsRequest(request, token) {
  return {
    type: patientDetailsUpdateType.UPDATE_PATIENT_DETAILS_REQUEST,
    request,
    token,
  };
}
function updatePatientDetailsRequestReset() {
  return {
    type: patientDetailsUpdateType.UPDATE_PATIENT_DETAILS_REQUEST_RESET,
  };
}
function updatePatientDetailsRequestSuccess(message) {
  return {
    type: patientDetailsUpdateType.UPDATE_PATIENT_DETAILS_REQUEST_SUCCESS,
    message,
  };
}
function updatePatientDetailsRequestFailure(error) {
  return {
    type: patientDetailsUpdateType.UPDATE_PATIENT_DETAILS_REQUEST_FAILURE,
    error,
  };
}
