// import { put, takeLatest, call } from "@redux-saga/core/effects";

// import {
//   dynamicLookupFaliure,
//   dynamicLookupSuccess,
// } from "../reducers/dynamicLookup.slice";
// import { loginActions } from "../../pages/Login/actions/login.action";
// import { endpoint } from "../../api/Url";
// import { Api2 } from "../../api/Api2";

// export function* watchDynamicLookupData() {
//   yield takeLatest("dynamicLookup/dynamicLookupRequest", getDynamicLookupData);
// }
// //For dynamic page request
// function* getDynamicLookupData(action) {
//   try {
//     //   api call
//     const res = yield call(Api2, {
//       endpoint: `${endpoint.dynamicLookup}?table=${action.payload.tableName}`,
//       method: "GET",
//       jwt: "Bearer " + action.payload.token,
//     });

//     yield put(dynamicLookupSuccess(res.data));
//   } catch (error) {
//     yield put(dynamicLookupFaliure(error));

//     if (error.code === 401 && error.code === 403)
//       yield put(loginActions.logoutRequest());
//   }
// }
import { put, takeLatest, call, takeEvery } from "@redux-saga/core/effects";

import {
  dynamicLookupFaliure,
  dynamicLookupSuccess,
} from "../reducers/dynamicLookup.slice";
import { loginActions } from "../../pages/Login/actions/login.action";
import { endpoint } from "../../api/Url";
import { Api2 } from "../../api/Api2";
import {
  lookupDynamicFailure,
  lookupDynamicSuccess,
} from "../reducers/lookupDynamic.slice";

export function* watchDynamicLookupData() {
  yield takeLatest("dynamicLookup/dynamicLookupRequest", getDynamicLookupData);
  yield takeEvery("lookupDynamic/lookupDynamicRequest", getLookupData);
}
//For dynamic page request
function* getDynamicLookupData(action) {
  try {
    //   api call
    const res = yield call(Api2, {
      endpoint: `${endpoint.dynamicLookup}?table=${action.payload.tableName}`,
      method: "GET",
      jwt: "Bearer " + action.payload.token,
    });

    yield put(dynamicLookupSuccess(res.data));
  } catch (error) {
    yield put(dynamicLookupFaliure(error));

    if (error.code === 401 && error.code === 403)
      yield put(loginActions.logoutRequest());
  }
}
function* getLookupData(action) {
  try {
    //   api call
    const res = yield call(Api2, {
      endpoint: `${endpoint.dynamicLookup}?table=${action.payload.tableName}`,
      method: "GET",
      jwt: "Bearer " + action.payload.token,
    });
    const response = {
      key: action.payload.tableName,
      ...res,
    };
    console.log(response);
    yield put(lookupDynamicSuccess(response));
  } catch (error) {
    yield put(lookupDynamicFailure(error));

    if (error.code === 401 && error.code === 403)
      yield put(loginActions.logoutRequest());
  }
}
