import { patientChatMailboxTypes } from "../types/patientChatMailbox.type";
const initialState = {
  loading: false,
};

export const patientChatMailboxReducer = (state = initialState, action) => {
  switch (action.type) {
    case patientChatMailboxTypes.PATIENT_CHAT_MAILBOX_REQUEST:
      return { ...initialState, loading: true };
    case patientChatMailboxTypes.PATIENT_CHAT_MAILBOX_REQUEST_SUCCESS:
      return { ...initialState, getAll: action.getAll };
    case patientChatMailboxTypes.PATIENT_CHAT_MAILBOX_REQUEST_FAILURE:
      return { ...initialState, error: action.error };

    default:
      return state;
  }
};
