import { getAllFacilityType } from "../types";

export const getAllFacilityTypeAction = {
  getAllFacilityTypeRequest,
  getAllFacilityTypeSuccess,
  getAllFacilityTypeFailure,
  getAllFacilityTypeReset,
};

function getAllFacilityTypeRequest(token) {
  return {
    type: getAllFacilityType.GETALL_FACILITY_TYPE_REQUEST,
    token,
  };
}
function getAllFacilityTypeSuccess(getAll) {
  return {
    type: getAllFacilityType.GETALL_FACILITY_TYPE_SUCCESS,
    getAll,
  };
}

function getAllFacilityTypeFailure(error) {
  return {
    type: getAllFacilityType.GETALL_FACILITY_TYPE_FAILURE,
    error,
  };
}
function getAllFacilityTypeReset(error) {
  return {
    type: getAllFacilityType.GETALL_FACILITY_TYPE_RESET,
  };
}
