import { educationProvidedTypes } from "../types";

export const educationProvidedActions = {
  educationProvidedGetRequest,
  educationProvidedGetRequestSuccess,
  educationProvidedGetRequestFailure,

  educationProvidedOpRequest,
  educationProvidedOpRequestSuccess,
  educationProvidedOpRequestFailure,
  educationProvidedOpRequestReset,
};
function educationProvidedGetRequest(request, token) {
  return {
    type: educationProvidedTypes.EDUCATIONPROVIDED_GET_REQUEST,
    request,
    token,
  };
}
function educationProvidedGetRequestSuccess(get) {
  return {
    type: educationProvidedTypes.EDUCATIONPROVIDED_GET_REQUEST_SUCCESS,
    get,
  };
}
function educationProvidedGetRequestFailure(error) {
  return {
    type: educationProvidedTypes.EDUCATIONPROVIDED_GET_REQUEST_FAILURE,
    error,
  };
}

function educationProvidedOpRequest(request, token) {
  return {
    type: educationProvidedTypes.EDUCATIONPROVIDED_OPERATION_REQUEST,
    request,
    token,
  };
}
function educationProvidedOpRequestSuccess(message) {
  return {
    type: educationProvidedTypes.EDUCATIONPROVIDED_OPERATION_REQUEST_SUCCESS,
    message,
  };
}
function educationProvidedOpRequestFailure(error) {
  return {
    type: educationProvidedTypes.EDUCATIONPROVIDED_OPERATION_REQUEST_FAILURE,
    error,
  };
}
function educationProvidedOpRequestReset() {
  return {
    type: educationProvidedTypes.EDUCATIONPROVIDED_OPERATION_REQUEST_RESET,
  };
}
