import { put, takeLatest, call } from "@redux-saga/core/effects";
import { relationshipActions } from "../actions";
import { endpoint } from "../../../../../api/Url";
import { Api } from "../../../../../api";
import { loginActions } from "../../../../Login/actions/login.action";
import { relationshipTypes } from "../types";

export function* watchRelationship() {
  yield takeLatest(relationshipTypes.RELATIONSHIP_REQUEST, getRelationshipList);
}

function* getRelationshipList(action) {
  try {
    const relationshipList = yield call(Api, {
      endpoint: `${endpoint.getRelationship}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      //   body: action.request,
    });

    const parsedRelationshipList = JSON.parse(relationshipList);

    yield put(
      relationshipActions.relationshipRequestSuccess(
        parsedRelationshipList.RelationshipModel
      )
    );
  } catch (error) {
    yield put(relationshipActions.relationshipRequestFailure(error));
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
  }
}
