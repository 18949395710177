import { put, takeLatest, call } from "@redux-saga/core/effects";
import { chargesInputActions } from "../actions";
import { chargesInputType } from "../types";
import { endpoint } from "../../../../../api/Url";
import { Api } from "../../../../../api";
import { loginActions } from "../../../../Login/actions/login.action";

export function* watchChargesInput() {
  yield takeLatest(chargesInputType.CHARGES_INPUT_REQUEST, getChargesInput);
}

function* getChargesInput(action) {
  try {
    // //   api call
    const data = yield call(Api, {
      endpoint: `${endpoint.getShowHideChargesInputBox}?DispositionId=${action.request.DispositionId}`,
      method: "POST",
      jwt: "Bearer " + action.token,
    });
    const parsedData = JSON.parse(data);

    yield put(
      chargesInputActions.chargesInputRequestSuccess(
        parsedData.BundleDispositionDetailModel[0]
      )
    );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(chargesInputActions.chargesRequestFailure(error));
  }
}
