/**
 * @url
 * base url
 */
// baseURL: `https://apihits.wwmcpa.net/`
// baseURL2: `https://wwapi.hitspact.com/api/v1/`,

export const Url = {
  baseURL: `https://apihits.wwmcpa.net/`, //! development
  baseURL2: `https://api2hits.wwmcpa.net/api/v1/`, //!development
  baseGraphql: `https://api2hits.wwmcpa.net/graphql/`, //!development
  // baseURL2: `https://api2hits.wwmcpa.net/api/v1/`, //!live
};

/**
 *
 * @endpoint
 *to get endpoints3
 */
export const endpoint = {
  login: "AuthenticationApi/login",
  powerbiReport: "EmbedInfo/PowerBiReport",
  // qualityReport: "powerbi/quality-report",
  // qualityReportTabs: "powerbi/quality-reports/tabs",
  // qualityReportById: "powerbi/report",
  qualityReport: "powerbi/quality-report",
  qualityReportTabs: "powerbi/multiplepowerbi-report-tabs",
  // qualityReportTabs: "powerbi/quality-reports/tabs",
  // qualityReportById: "powerbi/report",
  qualityReportById: "powerbi/powerbi-report-byid",
  dashboardReport: "Chart/getChartReportsDetail",
  patientFiltersList: "Patient/patientfilters",
  patientList: "Patient/filteredpaientlist",
  patientDetails: "Patient/patientdetails",
  visitDetails: "Patient/VisitDetails",
  saveUtilizationHistory: "Patient/SaveUtilizationHistory",

  getContactRoles: "MailMessage/mailing-roles",
  getAllContacts: "MailMessage/mailing-users",

  archiveMailbox: "MailMessage/archive-mailbox",
  patientChatMailbox: "Patient/getPcpChatHistory",

  // deleteMail: "MailMessage/delete-message",
  replyMail: "MailMessage/reply-mail",

  // mailDetails: "MailMessage/message-detail",
  patientChatDetails: "Patient/getPcpchatDetails",

  replyPatientChatMail: "Patient/SavePatientPcp_ChatDetails",
  // !Mail box gets
  inboxMailbox: "inbox/get-inbox-mail",
  sentMailbox: "inbox/get-sent-mail",
  getArchiveMailbox: "inbox/get-archive-mail",
  mailDetails: "inbox/get-message-detail",
  deleteMail: "inbox/delete-mail",
  // !Mail crud
  // archiveMail: "inbox/compose-mail",
  sendMail: "inbox/compose-mail",
  //cm role api
  getCallHistory: "CaseManager/getCallHistoryDataList",
  getCharges: "CaseManager/getSavedBundleDispositionforAdmission",
  getTasksDetails: "CaseManager/getTaskListDataList",
  saveCallHistory: "cmer/callHistory",
  getCMPatientDetails: "Patient/getCaseManagerPatientDetails",
  getCMPatientDetailsV2: "Patient",
  updatePatientDetails: "Patient/updateCaseManagerPatientDetails",

  // hospital admissionk cm role update
  updateHospitalAdmissionPatientDetails: "casemanager/hospital-admisions",

  getPcpsDetails: "lookup/getPcpListWithDetail",

  saveCharges: "CaseManager/saveCharges",
  getShowHideChargesInputBox: "CaseManager/getShowHideChargesInputBox",
  saveCaseManagerTaskDetail: "CaseManager/saveCaseManagerTaskDetail",
  deleteCharges: "CaseManager/deleteCharges",
  GetTaskPocDataList: "Task/GetTaskPocDataList",
  savePocTaskDetail: "Task/savePocTaskDetail",

  functionalAssessment: "Patient/getPatientFunctionalAssessment",
  getAssessment: "Patient/getFunctionalAssessmentQuestionAnswerDetails",

  //patientchat for cm
  sendPatientChat: "Patient/savePatientChat",
  getNurseChatHistory: "Patient/getNurseChatHistory",
  getNursechatDetails: "Patient/getNursechatDetails",
  savePatientNurseChatDetails: "Patient/savePatientNurse_ChatDetails",

  // saveDynamicGait: "Patient/saveDynamicGaitQuestionsDetails",
  saveDynamicGait: "Patient/saveAssessmentQuestionsDetails",
  saveBergBalance: "Patient/saveBergBalanceQuestionsDetails",
  saveDepressionAssessment: "Patient/saveDepressionAssessmentQuestionsDetails",
  saveNutritionalAssessment: "Patient/saveMNAQuestionsDetails",
  saveADL: "Patient/saveADLQuestionsDetails",
  saveIADL: "Patient/saveIADLQuestionsDetails",
  //lookup
  getPhysician: "Lookup/getPhysicianList",
  getPCP: "Lookup/getPcpList",
  getDRGInfo: "Lookup/getDRGinfoList",
  getDiagnosis: "Lookup/getDiagnosisList",

  getFacility: "Lookup/getFacilityList",
  getRelationship: "Lookup/getRelationshipList",
  getDispositions: "Lookup/getDispositionList",
  getTransitionActions: "Lookup/getTransitionActionList",
  getBundleDisposition: "Lookup/getBundleDisposition",
  getDosage: "Lookup/getDosageAndRouteList",
  getDrug: "Lookup/GetDrugList",
  getFrequency: "Lookup/getFrequencyList",
  // saveMedication: "Transition/saveMedicationList",

  patientClinicalInformation: "Patient/getPatientClinicalInformation",
  patientClinicalInformationUpdate: "Patient/updatePatientClinicalInformation",

  //Reports
  getUserReportsList: "Patient/getUserDefinedReportsList",
  getReportsData: "Patient/getReportsData",

  //tc
  getAllLookupPatientStatus: "tc/lookup/patientstatus", //? for getting role based patient data
  getTCPatientDetails: "Transition/getTransitionPatientDetails",
  updateTCPatientDetails: "Transition/updateTransitionCarePatientDetails",
  updateTCPatientDiagnosis:
    "Transition/updateTransitionCarePatientDiagnosis_Dc_Consultant",
  getTCPatientDiagnosisDetails:
    "Transition/getTransitionPatientDiagnosis_Dc_Consultant",
  getTCTasks: "Task/getTaskList",
  getMedicationsList: "Transition/getMedicationsList",
  saveMedication: "Transition/saveMedication",

  saveAppointment: "Transition/saveAppointment",
  getSetAppointmentList: "Transition/getSetAppointmentList",
  deleteAppointment: "Transition/deleteAppointment",
  saveTCTask: "Task/saveTaskDetail",
  getAllFacilityType: "lookup/getallfacility-type",
  refreshAppointmentData: "tc/patient-subscribe-appointment",

  //TC lookup
  getCMUsers: "Lookup/GetCMUserList",
  GetReferralPlans: "Lookup/GetReferralPlanList",

  //CMD
  getCMDPatientDetails: "CaseManagerDirector/getPatientDetailsForCMD",
  getCMDCallHistory: "CaseManagerDirector/getCallHistoryDataListForCMD",
  saveCMDCallHistory: "CaseManagerDirector/saveCallHistoryDetailForCMD",
  updateCMDPhoneNo: "CaseManagerDirector/updatePhoneNoForCMD",
  getCMDTasksDetails: "Task/getTaskList",
  saveCMDAddTasksDetail: "Task/saveTaskDetail",

  //DM
  getDMFacilityData: "DataManagement/getFacilityDataList",
  operationDMFacility: "DataManagement/saveFacilityData",

  getAllDMInsuranceData: "DataManagement/getInsuranceDataList",
  getDMInsuranceDetails: "DataManagement/getInsuranceDetails",
  operationDMInsurance: "DataManagement/saveInsuranceData",

  getDMMsoData: "DataManagement/getMSODataList",
  getDMMsoDetails: "DataManagement/getMsoDetails",
  operationDMMso: "DataManagement/saveMsoData",

  getAllDMPCPData: "DataManagement/getPcpDataList",
  operationDMPCP: "DataManagement/savePcpData",
  getDMPCPDetails: "DataManagement/getPcpDetails",

  getAllDMDispositionData: "DataManagement/getDispositionDataList",
  operationDMDisposition: "DataManagement/saveDispositionData",
  getDMDispositionDetails: "DataManagement/getDispositionDetails",

  getAllDMInsurancePlanData: "DataManagement/getInsurancePlanDataList",
  getAllDMInsurancePlanCompneyData: "DataManagement/insurancePlan",
  getDMInsurancePlanDetails: "DataManagement/getInsurancePlanDetails",
  operationDMInsurancePlan: "DataManagement/saveInsurancePlanData",
  getDMRoleLookup: "lookup/roles",
  getRoleIdentityLookup: "lookup/role-identities",
  getDMRoleData: "DataManagement/getRoleDataList",
  saveDMRoleData: "DataManagement/saveRoleData",

  getDMBundleDispositionData: "DataManagement/getBundleDispositionDataList",

  getAllDMSocialDeterminantsData:
    "DataManagement/getSocialDeterminantsDataList",
  getDMSocialDeterminantsDetails: "DataManagement/getSocialDeterminantsDetails",
  operationDMSocialDeterminants: "DataManagement/saveSocialDeterminantsData",
  saveDMBundleData: "DataManagement/saveBundleDispositionData",

  getDMICDData: "DataManagement/getICDDataList",
  saveDMIcdData: "DataManagement/saveICDData",

  getUserDMDataList: "DataManagement/getUserDataList",
  getDMRoleIdentityData: "DataManagement/getRoleIdentityDataList",
  operationDMUser: "DataManagement/saveUser",
  // for create new user
  createUsersDmRole: "dm/users",
  //notifications
  saveNotificationDevice: "PushNotification/saveNotificationDevice",
  getNotificationList: "PushNotification/getNotificationList",

  EpisodicPowerBiReport: "EmbedInfo/EpisodicPowerBiReport",

  getAdvanceCarePlan: "lookup/getAdvanceCarePlanList",

  ehrFileUpload: "Upload/ehrFile",

  //forgot password
  createForgotPasswordOtp: "AuthenticationApi/createForgotPasswordOtp",
  verifyForgotPasswordOtp: "AuthenticationApi/verifyForgotPasswordOtp",
  changePasswordForPatientApp: "AuthenticationApi/changePasswordForPatientApp",

  //advance Directive
  getAdvanceDirectiveDetails: "Patient/getAdvanceDirectiveDetails",
  savePatientAdvanceDirectiveDetails:
    "Patient/savePatientAdvanceDirectiveDetails",

  //! CM ROle--> AWV  Start
  awvGetAllergiesDetails: "awv/allergy", //? Allerties Get
  awvGetmedicationList: "awv/medication", //? Allerties Get
  awvGetBioMetricList: "awv/biometric",
  awvGetProblemList: "awv/problemlist",
  awvGetAllergeyDiagnosisLookup: "lookup/icds",
  // getDiagnosis: "Lookup/getDiagnosisList", //!ond one for allergey diagnosis

  // savePatientAllergiesDetails: "Patient/savePatientAllergiesDetails", //! old patient save
  // getAllergiesDetails: "Patient/getAllergiesDetails", //!old one
  //! CM ROle--> AWV  End
  getAllergiesDetails: "Patient/getAllergiesDetails",
  savePatientAllergiesDetails: "Patient/savePatientAllergiesDetails",

  savePatientImmunizationDetails: "Patient/savePatientImmunizationDetails",
  getImmunizationDetails: "Patient/getImmunizationDetails",
  deletePatientImmunization: "Patient/deletePatientImmunizationDetails",

  getSurgicalHistoryDetails: "Patient/getSurgicalHistoryDetails",
  savePatientSurgicalHistoryDetails:
    "Patient/savePatientSurgicalHistoryDetails",
  deletePatientSurgicalHistoryDetails:
    "Patient/deletePatientSurgicalHistoryDetails",

  getHospitalizationDetails: "Patient/getHospitalizationDetails",
  savePatientHospitalizationDetails:
    "Patient/savePatientHospitalizationDetails",
  deletePatientHospitalizationDetails:
    "Patient/deletePatientHospitalizationDetails",

  getBiometricMeasurementsDetails: "Patient/getBiometricMeasurementsDetails",
  saveBiometricMeasurementsDetails: "Patient/saveBiometricMeasurementsDetails",

  getProblemListDetails: "Patient/getProblemListDetails",
  saveProblemListDetails: "Patient/saveProblemListDetails",
  deletePatientProblemListDetails: "Patient/deletePatientProblemListDetails",

  getScreeningOrPreventiveExamsDetails:
    "Patient/getScreeningOrPreventiveExamsDetails",
  saveScreeningorPreventiveExamsDetails:
    "Patient/saveScreeningorPreventiveExamsDetails",

  getLabTestsDetails: "Patient/getLabTestsDetails",
  saveLabTestsDetails: "Patient/saveLabTestsDetails",

  getEducationProvidedDetails: "Patient/getEducationProvidedDetails",
  saveEducationProvidedDetails: "Patient/saveEducationProvidedDetails",

  getSuggestedAssessmentsorReferrals:
    "Patient/getSuggestedAssessmentsorReferrals",
  saveAssesmentsRefferalsDetails: "Patient/saveAssesmentsRefferalsDetails",

  getRecommendationsDetails: "Patient/getRecommendationsDetails",
  saveRecommendationsDetails: "Patient/saveRecommendationsDetails",

  getFamilyHistoryDetails: "Patient/getFamilyHisoryDetails",
  saveFamilyHistoryDetails: "Patient/saveFamilyHistoryDetails",

  getPatientDetailsForPrint: "Patient/getPatientDetailsForPrint",

  /**
   * @link https://acs.apacovid.com
   * api base url
   */

  //Auth
  validatelogin: "auth/login",
  loginOtpVerify: "auth/verify-otp-login",

  //hedis role
  hedis: "hedis",
  procedures: "procedures",

  getNewReports: "hedis/reports",

  // cm role
  ccm: "ccm",
  diagnosis: "lookups/diagnosis",
  goal: "lookups/goals",
  frequency: "lookups/frequency",
  patient_responsibility: "lookups/patient-responsibility-by-id",
  intervention: "lookups/intervention",
  careplans: "careplans",

  lookups: "lookups",
  notes: "notes",
  interventions: "interventions",

  ccmtasks: "ccmtasks",
  // cm role
  callHistoryOperationsCm: "casemanager/call-history",
  savePocTaskDetailCM: "casemanager/tasks",
  patientClinicalInfoOperationsCM: "casemanager/clinical-information",
  patientDetailsCM: "casemanager/patients-details",
  hospitalAdmissionVisitDetailsCM: "casemanager/hospital-admisions",
  //maw
  awv: "awv",
  comprehensiveSystemAssessments: "comprehensive-system-assessments",
  comprehensiveSystemAssessmentsPatient:
    "comprehensive-system-assessments-patient",
  comprehensiveSystemAssessmentsSystem:
    "comprehensive-system-assessments-changes",
  // lookup
  insurancePlanLookup: "lookup/insuranceplan",
  //lookup hierarchy
  dm: "/dm/routes",
  nation: "dm/nations",
  region: "dm/regions",
  ipas: "dm/ipas",
  pcpgroup: "dm/pcpgroups",
  pcpoffice: "dm/pcpoffices",
  pcphierarchy: "dm/pcphierarchy",
  userpermission: "dm/user-permissions",

  lookupNation: "lookup/nations",
  LookupRegion: "lookup/regions",
  lookupIPA: "lookup/ipas",
  lookupPCPGroup: "lookup/pcpgroups",
  lookupPCPOffice: "lookup/pcpoffices",
  lookupHierarchyLevel: "lookup/hierarchylevels",
  lookupMenu: "lookup/menus",
  lookupUser: "lookup/users",
  lookupRole: "lookup/roles",
  // LookupPCPOffice: "lookup/pcpoffice",

  authPermissions: "auth/permissions",

  //charges
  getProviderCpt: "lookup/getProviderCpt",
  getallIcd: "lookup/icds",
  getBillingModifier: "lookup/getBillingModifier",
  updatePatientBilling: "billing/patient-billing",

  getallBilling: "billing/getallBilling",

  updateCptModifier: "billing/patient-billing/cpt",
  updateIcdModifier: "billing/patient-billing/icd",

  getBillingCptModifier: "billing/patient-billing/cpt",
  getBillingIcd: "billing/patient-billing/icd",
  updatePatientAssignmentSeen: "billing/updateAssignmentSeen",
  addPatientBilling: "billing/assignments",
  getRvuReport: "billing/patient-billing/reports",
  getallCpt: "lookup/cpts",
  addCptComments: "billing/addBillingComment",
  patientSuggestions: "billing/patient-suggestions",
  preventDuplicatePatientAddBilling: "billing/check-existing-assignment",

  //adt

  // update patients bpo
  getPatientListForUpdate: "bpo/pulm-patients",
  // cronJobhca: "cronjob/hca",
  bpoPatientAssignment: "bpo/patient-assignments",

  bpoPatientAssignmentHie: "bpo/patient-assignments-hie",

  azureNomcPdf: "bpo/parse-nomc-pdf",
  azureHcaFaceSheets: "bpo/parse-hca-pdf",
  storepatientFacesheet: "bpo/store-azure-facesheet",
  storepatientNomc: "bpo/store-azure-nomc",
  // cronJobHie: "bpa/hca",

  //duplicate patients
  getAllDuplicatePatients: "bpo/duplicate-patients",
  mergeDuplicatePatients: "bpo/merge-duplicate-patients",
  mergePatientFacilityLookup: "lookup/attending-facility-physiciantype",
  //Pharmacist
  DischargePatientPharma: "pharma/patients",

  //transition care
  transitionCare: "tc/patients",
  getAllProviderSchedulesTc: "tc/physicians/schedule",
  getAllPatientAppointment: "tc/patient-appointments",
  getAllFacilityLocation: "lookup/facilities",
  // getAllProviders: "lookup/physicians",
  getAllProviders: "lookup/pcps",
  getAllRegions: "lookup/regions",
  getScheduleAppoinments: "tc/patient-appointments",
  createScheduleAppoinments: "tc/patient-appointments",
  getLookUpPatient: "lookup/patients",
  getActivePatientAppoinments: "tc/patient-getappointments",

  // frequency
  getAllFrequencyLookUp: "lookup/frequency",
  getAllRouteLookup: "lookup/route",
  getAllDosageLookup: "lookup/Dosage",
  getAllDrugLookup: "lookup/getDrugList",
  addActivePatientMedication: "tc/patients/medication",
  deleteActivePatientMedication: "tc/patients/medication",
  getPatientTypeStatus: "tc/patient-tcpreference",
  getPcpLookup: "tc/patient-tcpcp",
  updatePatientsCallStatus: "tc/patients-callstatus",

  /*CMER */
  cmer: "cmer",
  relationshipListLookupCmer: "lookup/relationship-list",
  insuranceListLookupCmer: "lookup/insurance-list",
  // clinical information
  advanceCarePlanLookupCmer: "lookup/advance-careplan",
  pcpLookupCmer: "lookup/search-pcps",
  diagnosisLookupCmer: "lookup/icds",
  getPatientClinicalInformationCmer:
    "cmer/patients-details?encounterId=12793&patientId=77348",
  addTaskCmer: "cmer/tasks", //task
  //   lookupGroupSubgroup: "tasks-groups-subgroups",

  // call history cmer
  actionTakenLookupCmer: "lookup/transition-action",
  dispositionLookupCmer: "lookup/disposition-list",
  callHistoryOperationsCmer: "cmer/call-history",
  patientClinicalInfoCmer: "cmer/clinical-information",

  // hospial admission cm role
  facilityLookupCM: "lookup/getFacilityByName",

  //recetionist
  receptionPatients: "receptionist/patients",
  // dynamicLookup: "lookup/dynamic?table=PactNew_tblLookupCallStatus",
  dynamicLookup: "lookup/dynamic",
  patientDetailsUpdate: "receptionist/insert-callstatus-notes",
  receptionistPatientNotesHistory: "receptionist/patients",

  // nursing
  nursingInitialAssessment: "nurshing/initial-assessment",
  nursingProgressUpdates: "nurshing/progress-updates",

  // Referral Role
  referralConsultant: `refferals/get-patient`,
  createHospitalistFollowUpPatient: `hospitalist/followup-patient`,
};
