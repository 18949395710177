import { allergiesTypes } from "../types";

const initialGetState = {
  loading: false,
  getAll: [],
  error: "",
  message: "",
};
export const allergyGetReducer = (state = initialGetState, action) => {
  switch (action.type) {
    case allergiesTypes.GETALL_ALLERGY_REQUEST:
      return {
        ...initialGetState,
        loading: true,
      };
    case allergiesTypes.GETALL_ALLERGY_REQUEST_SUCCESS:
      return {
        ...initialGetState,
        getAll: action.getAll,
      };
    case allergiesTypes.GETALL_ALLERGY_REQUEST_FAILURE:
      return {
        ...initialGetState,
        error: action.error,
      };
    default:
      return state;
  }
};

const initialOperationState = {
  loading: false,
  message: "",
  error: "",
};
export const allergyOperationReducer = (
  state = initialOperationState,
  action
) => {
  switch (action.type) {
    case allergiesTypes.OPERARTION_ALLERGY_REQUEST:
      return {
        ...initialOperationState,
        loading: true,
      };
    case allergiesTypes.OPERARTION_ALLERGY_REQUEST_SUCCESS:
      return {
        ...initialOperationState,
        message: action.message,
      };
    case allergiesTypes.OPERARTION_ALLERGY_REQUEST_FAILURE:
      return {
        ...initialOperationState,
        error: action.error,
      };
    case allergiesTypes.OPERARTION_ALLERGY_REQUEST_RESET:
      return {
        ...initialOperationState,
      };
    default:
      return state;
  }
};
