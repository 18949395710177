import { getDischargePatientPharmaType } from "../types/getAllDischargePatient.type";

export const getallDischargePatientPharmaActions = {
  getallDischargePatientRequest,
  getallDischargePatientSuccess,
  getallDischargePatientFailure,
};
function getallDischargePatientRequest(query, token) {
  return {
    type: getDischargePatientPharmaType.GETALL_DISCHARGE_PATIENT_PHARMA_REQUEST,
    query,
    token,
  };
}
function getallDischargePatientSuccess(getAll, totalPages, totalRows) {
  return {
    type: getDischargePatientPharmaType.GETALL_DISCHARGE_PATIENT_PHARMA_SUCCESS,
    getAll,
    totalPages,
    totalRows,
  };
}
function getallDischargePatientFailure(error) {
  return {
    type: getDischargePatientPharmaType.GETALL_DISCHARGE_PATIENT_PHARMA_FAILURE,
    error,
  };
}
