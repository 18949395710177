import React from "react";
import { Spinner } from "..";

import styles from "./Button.module.scss";

export const Button = (props) => {
  return (
    <button className={styles.btn_container} onClick={props.onClick}>
      {props.loading && (
        <div className={styles.spinner_container}>
          <Spinner height={20} width={20} />
        </div>
      )}
      {props.label}
    </button>
  );
};
