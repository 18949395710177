import React from "react";
import { Spinner } from "../../core";
import { strings } from "./strings";
import styles from "./Progress.module.scss";
const Progress = () => {
  return (
    <div className={styles.progress_container}>
      <Spinner width={34} height={34} />
      <h5 className={styles.progress_msg}>{strings.PROGRESS_MSG}</h5>
    </div>
  );
};

export { Progress };
