export const allergiesTypes = {
  GETALL_ALLERGY_REQUEST: "GETALL_ALLERGY_REQUEST",
  GETALL_ALLERGY_REQUEST_SUCCESS: "GETALL_ALLERGY_REQUEST_SUCCESS",
  GETALL_ALLERGY_REQUEST_FAILURE: "GETALL_ALLERGY_REQUEST_FAILURE",

  OPERARTION_ALLERGY_REQUEST: "OPERARTION_ALLERGY_REQUEST",
  OPERARTION_ALLERGY_REQUEST_SUCCESS: "OPERARTION_ALLERGY_REQUEST_SUCCESS",
  OPERARTION_ALLERGY_REQUEST_FAILURE: "OPERARTION_ALLERGY_REQUEST_FAILURE",
  OPERARTION_ALLERGY_REQUEST_RESET: "OPERARTION_ALLERGY_REQUEST_RESET",
};
