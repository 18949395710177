import { physicianTypes } from "../types";

export const physicianActions = {
  physicianRequest,
  physicianRequestSuccess,
  physicianRequestFailure,
};

function physicianRequest(request, token) {
  return { type: physicianTypes.PHYSICIAN_REQUEST, request, token };
}

function physicianRequestSuccess(getAll) {
  return { type: physicianTypes.PHYSICIAN_REQUEST_SUCCESS, getAll };
}

function physicianRequestFailure(error) {
  return { type: physicianTypes.PHYSICIAN_REQUEST_FAILURE, error };
}
