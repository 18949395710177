import { contactRoleTypes } from "../types/contactRole.type";

export const contactRoleActions = {
  contactRoleRequest,
  contactRoleRequestSuccess,
  contactRoleRequestFailure,
};
function contactRoleRequest(token) {
  return { type: contactRoleTypes.CONTACT_ROLE_REQUEST, token };
}
function contactRoleRequestSuccess(getRoles) {
  return { type: contactRoleTypes.CONTACT_ROLE_REQUEST_SUCCESS, getRoles };
}
function contactRoleRequestFailure(error) {
  return { type: contactRoleTypes.CONTACT_ROLE_REQUEST_FAILURE, error };
}
