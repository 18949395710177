import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api2 } from "../../api/Api2";
import { endpoint } from "../../api/Url";
import { lookupTypes } from "../types/lookup.type";
import { lookupActions } from "../actions/lookup.action";
export function* watchLookups() {
  yield takeLatest(lookupTypes.LOOKUP_REGION_REQUEST, getAllRegion);
  yield takeLatest(lookupTypes.LOOKUP_IPA_REQUEST, getAllIPA);
  yield takeLatest(lookupTypes.LOOKUP_PCP_GROUP_REQUEST, getAllPCPGroup);
  yield takeLatest(lookupTypes.LOOKUP_PCP_OFFICE_REQUEST, getAllPCPOffice);
  yield takeLatest(
    lookupTypes.LOOKUP_HIERARCHY_LEVEL_REQUEST,
    getAllHierarchylevel
  );
  yield takeLatest(lookupTypes.LOOKUP_MENU_REQUEST, getAllMenuList);
  yield takeLatest(lookupTypes.LOOKUP_USER_REQUEST, getAllUser);
  yield takeLatest(lookupTypes.LOOKUP_ROLE_REQUEST, getAllRole);
}

//getAll Region

function* getAllRegion(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.LookupRegion}?nationId=${action.query.nationId}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });

    if (response.data !== undefined && response.data.length > 0) {
      yield put(lookupActions.lookupRegionRequestSuccess(response.data));
    } else {
      yield put(lookupActions.lookupRegionRequestFailure("No Data Found"));
    }
  } catch (error) {
    yield put(lookupActions.lookupRegionRequestFailure(error));
  }
}

//getAllIPA IPA
function* getAllIPA(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.lookupIPA}?nationId=${action.query.nationId}&regionId=${action.query.regionId}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });

    if (response.data !== undefined && response.data.length > 0) {
      yield put(lookupActions.lookupIPARequestSuccess(response.data));
    } else {
      yield put(lookupActions.lookupIPARequestFailure("No  Data Found"));
    }
  } catch (error) {
    yield put(lookupActions.lookupIPARequestFailure(error));
  }
}
//getAllpcpgroup
function* getAllPCPGroup(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.lookupPCPGroup}?nationId=${action.query.nationId}&regionId=${action.query.regionId}&ipaId=${action.query.ipaId}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });
    if (response.data !== undefined && response.data.length > 0) {
      yield put(lookupActions.lookupPCPGroupSuccess(response.data));
    } else {
      yield put(lookupActions.lookupPCPGroupFailure("No data Found"));
    }
  } catch (error) {
    yield put(lookupActions.lookupPCPGroupFailure(error));
  }
}

//getAllpcpoffice
function* getAllPCPOffice(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.lookupPCPOffice}?nationId=${action.query.nationId}&regionId=${action.query.regionId}&ipaId=${action.query.ipaId}&pcpgroupId=${action.query.pcpGroupId}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });

    if (response.data !== undefined && response.data.length > 0) {
      yield put(lookupActions.lookupPCPOfficeSuccess(response.data));
    } else {
      yield put(lookupActions.lookupPCPOfficeFailure("No data Found"));
    }
  } catch (error) {
    yield put(lookupActions.lookupPCPOfficeFailure(error));
  }
}

//getAllHierarchylevel
function* getAllHierarchylevel(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.lookupHierarchyLevel}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });

    if (response.data !== undefined && response.data.length > 0) {
      yield put(lookupActions.lookupHierarchyLevelSuccess(response.data));
    } else {
      yield put(lookupActions.lookupHierarchyLevelFailure("No data Found"));
    }
  } catch (error) {
    yield put(lookupActions.lookupHierarchyLevelFailure(error));
  }
}

//getAll menulist

function* getAllMenuList(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.lookupMenu}?type=`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });
    if (response.data !== undefined && response.data.length > 0) {
      yield put(lookupActions.lookupMenuSuccess(response.data));
    } else {
      yield put(lookupActions.lookupMenuFailure("No data Found"));
    }
  } catch (error) {
    yield put(lookupActions.lookupMenuFailure(error));
  }
}

function* getAllUser(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.lookupUser}?roleId=${action.query.roleId}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });
    if (response.data !== undefined && response.data.length > 0) {
      yield put(lookupActions.lookupUserRequestSuccess(response.data));
    } else {
      yield put(lookupActions.lookupUserRequestFailure("No data found"));
    }
  } catch (error) {
    yield put(lookupActions.lookupUserRequestFailure(error));
  }
}

function* getAllRole(action) {
  try {
    let response = yield call(Api2, {
      endpoint: `${endpoint.lookupRole}`,
      method: "GET",
      jwt: "Bearer " + action.token,
    });
    if (response.data !== undefined && response.data.length > 0) {
      yield put(lookupActions.lookupRoleRequestSuccess(response.data));
    } else {
      yield put(lookupActions.lookupRoleRequestFailure("No data found"));
    }
  } catch (error) {
    yield put(lookupActions.lookupRoleRequestFailure(error));
  }
}
