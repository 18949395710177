import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";

import { getHieFileDataType } from "../type/getHieFileData.type";
import { getHieFileDataActions } from "../actions/getHieFileData.action";
import { hieFileProcessingType } from "../type/hieFileProcess.type";
import { Url, endpoint } from "../../../../../api/Url";


export function* watchHieFileProcessing() {
  yield takeLatest(
    getHieFileDataType.GET_HIE_FILE_DATA_REQUEST,
    getHieFileData
  );
  yield takeLatest(
    hieFileProcessingType.HIE_FILE_PROCESSING_REQUEST,
    hieFileDataProcess
  );
}

function* getHieFileData(action) {
  let urlEndpoint = `${Url.baseURL2}${endpoint.bpoPatientAssignmentHie}?read=${action.query.read}&write=${action.query.write}`;
  try {
    let response = yield call(axios, {
      method: "POST",
      url: urlEndpoint,
      data: action.request,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + action.token,
      },
      timeout: 1800000, // 30 minutes
    });
    console.log(response);
    yield put(
      getHieFileDataActions.getHieFileDataSuccess(
        response?.data?.data,
        response?.data?.totalrecords
      )
    );
  } catch (error) {
    console.log(error);
    yield put(getHieFileDataActions.getHieFileDataFailure(error));
  }
}


function* hieFileDataProcess(action) {
  console.log(action,"action");
  let urlEndpoint = `${Url.baseURL2}${endpoint.bpoPatientAssignmentHie}?read=${action.query.read}&write=${action.query.write}`;
  try {
    let response = yield call(axios, {
      method: "POST",
      url: urlEndpoint,
      data: action.request,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + action.token,
      },
      timeout: 1800000, // 30 minutes
    });
    console.log(response, "response");

    // yield put(getHieFileDataActions.getHieFileDataSuccess(response, 2));
  } catch (error) {
    console.log(error);
    yield put(getHieFileDataActions.getHieFileDataFailure(error));
  }
}
