import { callHistoryOperationsCmerType } from "../types/callHistory.type";
export const callHistoryOperationsCmerActions = {
  callHistoryOperationsCmerRequest,
  callHistoryOperationsCmerSuccess,
  callHistoryOperationsCmerFailure,
  callHistoryGetAllDetailsCmerRequest,
  callHistoryGetAllDetailsCmerSuccess,
  callHistoryGetAllDetailsCmerFailure,
  callHistoryOperationsCmerReset,
};

function callHistoryGetAllDetailsCmerRequest(request, token) {
  return {
    type: callHistoryOperationsCmerType.CALL_HISTORY_DETAILS_CMER_REQUEST,

    request,
    token,
  };
}
function callHistoryGetAllDetailsCmerSuccess(getAll) {
  return {
    type: callHistoryOperationsCmerType.CALL_HISTORY_DETAILS_CMER_SUCCESS,
    getAll,
  };
}
function callHistoryGetAllDetailsCmerFailure(error) {
  return {
    type: callHistoryOperationsCmerType.CALL_HISTORY_DETAILS_CMER_FAILURE,
    error,
  };
}
function callHistoryOperationsCmerRequest(request, token) {
  return {
    type: callHistoryOperationsCmerType.CALL_HISTORY_OPERATIONS_CMER_REQUEST,

    request,
    token,
  };
}
function callHistoryOperationsCmerSuccess(message) {
  return {
    type: callHistoryOperationsCmerType.CALL_HISTORY_OPERATIONS_CMER_SUCCESS,
    message,
  };
}
function callHistoryOperationsCmerFailure(error) {
  return {
    type: callHistoryOperationsCmerType.CALL_HISTORY_OPERATIONS_CMER_FAILURE,
    error,
  };
}
function callHistoryOperationsCmerReset() {
  return {
    type: callHistoryOperationsCmerType.CALL_HISTORY_OPERATIONS_CMER_RESET,
  };
}
