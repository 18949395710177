import { assessmentsReferralsTypes } from "../types";

export const assessmentsReferralsActions = {
  assessmentsReferralsGetRequest,
  assessmentsReferralsGetRequestSuccess,
  assessmentsReferralsGetRequestFailure,

  assessmentsReferralsOpRequest,
  assessmentsReferralsOpRequestSuccess,
  assessmentsReferralsOpRequestFailure,
  assessmentsReferralsOpRequestReset,
};
function assessmentsReferralsGetRequest(request, token) {
  return {
    type: assessmentsReferralsTypes.ASSESSMENTSREFERRAL_GET_REQUEST,
    request,
    token,
  };
}
function assessmentsReferralsGetRequestSuccess(get) {
  return {
    type: assessmentsReferralsTypes.ASSESSMENTSREFERRAL_GET_REQUEST_SUCCESS,
    get,
  };
}
function assessmentsReferralsGetRequestFailure(error) {
  return {
    type: assessmentsReferralsTypes.ASSESSMENTSREFERRAL_GET_REQUEST_FAILURE,
    error,
  };
}

function assessmentsReferralsOpRequest(request, token) {
  return {
    type: assessmentsReferralsTypes.ASSESSMENTSREFERRAL_OPERATION_REQUEST,
    request,
    token,
  };
}
function assessmentsReferralsOpRequestSuccess(message) {
  return {
    type: assessmentsReferralsTypes.ASSESSMENTSREFERRAL_OPERATION_REQUEST_SUCCESS,
    message,
  };
}
function assessmentsReferralsOpRequestFailure(error) {
  return {
    type: assessmentsReferralsTypes.ASSESSMENTSREFERRAL_OPERATION_REQUEST_FAILURE,
    error,
  };
}
function assessmentsReferralsOpRequestReset() {
  return {
    type: assessmentsReferralsTypes.ASSESSMENTSREFERRAL_OPERATION_REQUEST_RESET,
  };
}
