import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { strings } from "./strings";
import styles from "./VisitDetails.module.scss";
import { visitDetailsCMActions } from "./actions/visitDetails.action";
import { useSelector, useDispatch } from "react-redux";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { AddEditUtilization } from "./components";
import { addUpdateUtilizationActions } from "./actions";
import { AddButton } from "../../cm/EditPatient/components/AddButton";
import { ActionBtn } from "../../../components";
import { Tooltip } from "@mui/material";

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}
const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};
renderCellExpand.propTypes = {
  colDef: PropTypes.object.isRequired,

  value: PropTypes.string.isRequired,
};

function renderCellExpand(params) {
  return (
    <Tooltip title={params.value || ""}>
      <div>{params.value}</div> {/* Render the cell value */}
    </Tooltip>
  );
}

const CMVisitDetails = () => {
  const userData = useSelector((state) => state.login.userData);
  const visitDetails = useSelector((state) => state.visitDetailsCM);
  const addUpdateUtilizationCM = useSelector(
    (state) => state.addUpdateUtilizationCM
  );
  // const patientDetails = useSelector((state) => state.basicInfo);
  const patientDetails = useLocation().state.patientDetails1;
  const [selectedItem, setSelectedItem] = useState(null);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [isEditing, setEditing] = useState(false);
  console.log(patientDetails.PatientId, "qwerty");
  useEffect(() => {
    if (
      addUpdateUtilizationCM?.message !== "" &&
      patientDetails.PatientId !== undefined &&
      patientDetails.PatientId !== "" &&
      userData.token !== undefined
    ) {
      const request = {
        patientID: patientDetails.PatientId,
        page: 1,
      };

      dispatch(
        visitDetailsCMActions.visitDetailsCMRequest(request, userData.token)
      );
      dispatch(addUpdateUtilizationActions.addUpdateUtilizationRequestReset());
    }
  }, [addUpdateUtilizationCM]);
  // const { patientId } = useParams();
  // const { patientId  = useParams();
  const columns = [
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      filterable: false,
      width: 80,
      flex: 0.1,

      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          if (visitDetails.visits !== undefined && params.id !== "") {
            let item = visitDetails.visits.filter((item) =>
              item.id.toString().includes(params.id.toString())
            )[0];
            setSelectedItem(item);
            setEditing(true);
            setShow(true);
          }
        };

        return (
          <>
            <ActionBtn isEdit onClick={onClick} />
          </>
        );
      },
    },
    // { field: "id", headerName: "Sr.No.", width: 60 },
    // {
    //   field: "LastAssignedProvider",
    //   headerName: strings.TH_ASSIGNED_PCP,
    //   width: 180,
    //   headerClassName: styles.header,
    //   cellClassName: styles.cell,
    // },
    {
      field: "Facility",
      headerName: strings.TH_FACILITY,
      width: 220,
      renderCell: renderCellExpand,
      flex: 0.3,
    },
    {
      field: "AdmissionDate",
      headerName: strings.TH_ADMISSION_DATE,
      headerClassName: styles.header,
      width: 150,
      flex: 0.2,
    },
    {
      field: "DCDate",
      headerName: strings.TH_DISCHARGE_DATE,
      headerClassName: styles.header,
      // description: "This column has a value getter and is not sortable.",
      // sortable: false,
      width: 150,
      flex: 0.2,
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
    {
      field: "MRN",
      headerName: strings.TH_MRN,
      headerClassName: styles.header,
      width: 120,
      flex: 0.2,
    },
    {
      field: "PCPName",
      headerName: strings.TH_PCP_NAME,
      headerClassName: styles.header,
      width: 130,
      renderCell: renderCellExpand,
      flex: 0.3,
    },
    {
      field: "Disposition",
      headerName: strings.TH_DISPOSITION,
      headerClassName: styles.header,
      width: 220,
      renderCell: renderCellExpand,
      flex: 0.4,
    },
    {
      field: "DiagnosisDetail",
      // field: "Diagnosis",
      headerName: strings.TH_DIAGNOSIS,

      renderCell: renderCellExpand,
      flex: 0.2,

      width: 400,
    },
  ];
  useEffect(() => {
    if (
      patientDetails.PatientId !== undefined &&
      patientDetails.PatientId !== ""
    ) {
      const request = {
        patientID: patientDetails.PatientId,
        page: 1,
      };

      if (userData.token !== "undefined")
        dispatch(
          visitDetailsCMActions.visitDetailsCMRequest(request, userData.token)
        );
    }
    // eslint-disable-next-line
  }, [userData.token]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton className={styles.toolbar} />
        <GridToolbarFilterButton className={styles.toolbar} />
        <GridToolbarDensitySelector className={styles.toolbar} />
        <GridToolbarExport className={styles.toolbar} />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    if (!show) {
      setEditing(false);
      dispatch(addUpdateUtilizationActions.addUpdateUtilizationRequestReset());
    }
  }, [show]);
  //cols filts density exp
  return (
    <>
      <div className={styles.container}>
        {!show && (
          <div className={styles.btn_actions}>
            <AddButton
              isActive={show}
              onClick={() => {
                setShow(!show);
              }}
              btnEventName={strings.BTN_ADD}
            />
          </div>
        )}
        {show ? (
          <>
            <AddEditUtilization
              isEditing={isEditing}
              show={show}
              setShow={(val) => setShow(val)}
              selectedItem={selectedItem}
            />
          </>
        ) : (
          <DataGrid
            loading={visitDetails.loading}
            autoHeight
            className={styles.table}
            rows={visitDetails.visits || []}
            columns={columns}
            components={{ Toolbar: CustomToolbar }}
            getRowClassName={styles.table_rows}
            pageSize={10}
            rowsPerPageOptions={[10]}
            componentsProps={{
              pagination: {
                component: "div",
                style: {
                  flex: 1,
                },
              },
            }}
          />
        )}
      </div>
    </>
  );
};

export default CMVisitDetails;
