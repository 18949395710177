import { routeTypes } from "../types/route.type";

export const routeActions = {
  routeRequest,
  routeRequestSuccess,
  routeRequestFailure,
  routeSaveRequest,
  routeSaveRequestSuccess,
  routeSaveRequestFailure,
  // routeSaveRequestReset,
  routeUpdateRequest,
  routeUpdateRequestSuccess,
  routeUpdateRequestFailure,
  // routeUpdateRequestReset,
  routeIsActiveRequest,
  routeIsActiveRequestSuccess,
  routeIsActiveRequestFailure,
  routeRequestReset,
};

//Getall route
function routeRequest(token) {
  return {
    type: routeTypes.ROUTE_REQUEST,
    token,
  };
}
function routeRequestSuccess(getAll) {
  return {
    type: routeTypes.ROUTE_REQUEST_SUCCESS,
    getAll,
  };
}
function routeRequestFailure(error) {
  return {
    type: routeTypes.ROUTE_REQUEST_FAILURE,
    error,
  };
}

//Save route
function routeSaveRequest(request, token) {
  return {
    type: routeTypes.ROUTE_SAVE_REQUEST,
    request,
    token,
  };
}
function routeSaveRequestSuccess(message) {
  return {
    type: routeTypes.ROUTE_SAVE_REQUEST_SUCCESS,
    message,
  };
}
function routeSaveRequestFailure(error) {
  return {
    type: routeTypes.ROUTE_SAVE_REQUEST_FAILURE,
    error,
  };
}

// function routeSaveRequestReset() {
//   return {
//     type: routeTypes.ROUTE_SAVE_REQUEST_RESET,
//   };
// }

//update route
function routeUpdateRequest(query, payload, token) {
  return {
    type: routeTypes.ROUTE_UPDATE_REQUEST,
    query,
    payload,
    token,
  };
}
function routeUpdateRequestSuccess(id, data, message) {
  return {
    type: routeTypes.ROUTE_UPDATE_REQUEST_SUCCESS,
    id,
    data,
    message,
  };
}
function routeUpdateRequestFailure(error) {
  return {
    type: routeTypes.ROUTE_UPDATE_REQUEST_FAILURE,
    error,
  };
}

// function routeUpdateRequestReset() {
//   return {
//     type: routeTypes.ROUTE_UPDATE_REQUEST_RESET,
//   };
// }

//IsActive route
function routeIsActiveRequest(query, payload, token) {
  return {
    type: routeTypes.ROUTE_ISACTIVE_REQUEST,
    query,
    payload,
    token,
  };
}
function routeIsActiveRequestSuccess(id, message) {
  return {
    type: routeTypes.ROUTE_ISACTIVE_REQUEST_SUCCESS,
    id,
    message,
  };
}
function routeIsActiveRequestFailure(error) {
  return {
    type: routeTypes.ROUTE_ISACTIVE_REQUEST_FAILURE,
    error,
  };
}

// function routeIsActiveRequestReset() {
//   return {
//     type: routeTypes.ROUTE_ISACTIVE_REQUEST_RESET,
//   };
// }

//reset
function routeRequestReset() {
  return {
    type: routeTypes.ROUTE_REQUEST_RESET,
  };
}
