import React from "react";

import styles from "./Error.module.scss";
import { errorStrings } from "./error.strings";
export default function Error() {
  return (
    <div className={styles.container}>
      <h1 className={styles.error_code}>{errorStrings.ERROR_CODE}</h1>
      <h4 className={styles.error_type}>{errorStrings.ERROR_TYPE}</h4>
      <p className={styles.error_message}>{errorStrings.ERROR_MESSAGE}</p>
    </div>
    // <div className="fluid  d-flex-none justify-content-center align-item-center">
    //   <p className="h1 btn-danger align-self-center text-align-center">
    //     Error 404! Page not found
    //   </p>
    // </div>
  );
}
