import { put, takeLatest, call } from "@redux-saga/core/effects";
import { endpoint } from "../../../../../api/Url";
import { deleteChargesActions } from "../actions/deleteCharges.action";
import { deleteChargesTypes } from "../types/chargesDelete.type";
import { Api } from "../../../../../api";

export function* watchDeleteCharges() {
  yield takeLatest(deleteChargesTypes.DELETE_CHARGES_REQUEST, deleteCharges);
}

function* deleteCharges(action) {
  try {
    const data = yield call(Api, {
      endpoint: `${endpoint.deleteCharges}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    let deleteData = JSON.parse(data);

    yield put(deleteChargesActions.deleteChargesRequestSuccess(deleteData[0]));
  } catch (error) {
    yield put(deleteChargesActions.deleteChargesRequestFailure(error));
  }
}
