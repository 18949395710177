import { userPermissionTypes } from "../types/userpermission.type";

const initialState = {
  loading: false,
  loadingGet: false,
  get: null,
  loaded: false,
  error: "",
  message: "",
};

export const userPermissionDMReducer = (state = initialState, action) => {
  switch (action.type) {
    case userPermissionTypes.USER_PERMISSION_SAVE_REQUEST:
      return {
        ...state,
        loading: true,
        loadingGet: false,
        message: "",
        error: "",
      };
    case userPermissionTypes.USER_PERMISSION_SAVE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingGet: false,
        message: action.message,
        error: "",
      };
    case userPermissionTypes.USER_PERMISSION_SAVE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        loadingGet: false,
        error: action.error,
        message: "",
      };
    case userPermissionTypes.USER_PERMISSION_REQUEST_RESET:
      return {
        ...state,
        loading: false,
        loadingGet: false,
        message: "",
        error: "",
      };
    case userPermissionTypes.USER_PERMISSION_GET_REQUEST:
      return {
        ...state,
        loadingGet: true,
        loading: false,
        message: "",
        error: "",
      };
    case userPermissionTypes.USER_PERMISSION_GET_REQUEST_SUCCESS:
      return {
        ...state,
        loadingGet: false,
        loading: false,
        get: action.get,
        loaded: true,
        message: "",
        error: "",
      };
    case userPermissionTypes.USER_PERMISSION_GET_REQUEST_FAILURE:
      return {
        ...state,
        loadingGet: false,
        loading: false,
        error: action.error,
        message: "",
      };

    default:
      return state;
  }
};
