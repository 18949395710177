import { printTypes } from "../types/print.type";

export const printActions = {
  printRequest,
  printRequestSuccess,
  printRequestFailure,
};

function printRequest(request, token) {
  return { type: printTypes.PRINT_REQUEST, request, token };
}
function printRequestSuccess(
  patient,
  familyHistory,
  socialInfo,
  allergies,
  currentMedication,
  screenList,
  biometricList,
  screenOtherComment
) {
  return {
    type: printTypes.PRINT_REQUEST_SUCCESS,
    patient,
    familyHistory,
    socialInfo,
    allergies,
    currentMedication,
    screenList,
    biometricList,
    screenOtherComment,
  };
}
function printRequestFailure(error) {
  return { type: printTypes.PRINT_REQUEST_FAILURE, error };
}
