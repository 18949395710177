import { patientDetailsTypes } from "../types";

export const patientDetailsActions = {
  patientDetailsRequest,
  patientDetailsRequestSuccess,
  patientDetailsRequestFailure,
};
function patientDetailsRequest(request, token) {
  return {
    type: patientDetailsTypes.PATIENT_DETAILS_REQUEST,
    request,
    token,
  };
}
function patientDetailsRequestSuccess(details) {
  return {
    type: patientDetailsTypes.PATIENT_DETAILS_REQUEST_SUCCESS,
    details,
  };
}
function patientDetailsRequestFailure(error) {
  return {
    type: patientDetailsTypes.PATIENT_DETAILS_REQUEST_FAILURE,
    error,
  };
}
