import { deleteMedicationTransitionType } from "../../types/medication/deleteMedication.type";

const initialState = {
  loading: false,
  message: "",
  error: "",
};
export const deleteMedicationTCReducer = (state = initialState, action) => {
  switch (action.type) {
    case deleteMedicationTransitionType.DELETE_MEDICATION_TC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case deleteMedicationTransitionType.DELETE_MEDICATION_TC_SUCCESS:
      return {
        ...state,
        message: action.message,
        loading: false,
      };
    case deleteMedicationTransitionType.DELETE_MEDICATION_TC_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case deleteMedicationTransitionType.DELETE_MEDICATION_TC_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
