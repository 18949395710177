import { getDischargePatientPharmaType } from "../types/getAllDischargePatient.type";

const initialState = {
  loading: false,
  getAll: [],
  totalPages: 0,
};

export const getallDischargePatientReducer = (state = initialState, action) => {
  switch (action.type) {
    case getDischargePatientPharmaType.GETALL_DISCHARGE_PATIENT_PHARMA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case getDischargePatientPharmaType.GETALL_DISCHARGE_PATIENT_PHARMA_SUCCESS:
      return {
        ...state,
        // getAll: [...state.getAll, ...action.getAll],
        getAll: action.getAll,
        totalPages: action.totalPages,
        loading: false,
      };
    case getDischargePatientPharmaType.GETALL_DISCHARGE_PATIENT_PHARMA_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};

// import { getDischargePatientPharmaType } from "../types/getAllDischargePatient.type";

// const initialState = {
//   loading: false,
//   getAll: [],
//   totalPages: 100,
//   totalRows: 1000,
// };

// export const getallDischargePatientReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case getDischargePatientPharmaType.GETALL_DISCHARGE_PATIENT_PHARMA_REQUEST:
//       return {
//         ...state,
//         loading: true,
//       };
//     case getDischargePatientPharmaType.GETALL_DISCHARGE_PATIENT_PHARMA_SUCCESS:
//       return {
//         ...state,

//         getAll: [...state.getAll, ...action.getAll],
//         totalPages: action.totalPages,
//         totalRows: action.totalRows,
//         loading: false,
//       };
//     case getDischargePatientPharmaType.GETALL_DISCHARGE_PATIENT_PHARMA_FAILURE:
//       return {
//         ...state,

//         error: action.error,
//       };

//     default:
//       return state;
//   }
// };
