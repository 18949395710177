import { rememberType } from "../types/remember.type";

export const rememberActions = {
  rememberRequest,
  rememberRequestReset,
};
function rememberRequest(loginId, password) {
  return { type: rememberType.REMEMBER_REQUEST, loginId, password };
}

function rememberRequestReset() {
  return { type: rememberType.REMEMBER_REQUEST_RESET };
}
