import { chargesSaveType } from "../types";
export const chargesSaveActions = {
  chargesSaveRequest,
  chargesSaveRequestReset,
  chargesSaveRequestSuccess,
  chargesSaveRequestFailure,
};

function chargesSaveRequest(request, token) {
  return { type: chargesSaveType.CHARGES_SAVE_REQUEST, request, token };
}
function chargesSaveRequestReset() {
  return { type: chargesSaveType.CHARGES_SAVE_REQUEST_RESET };
}
function chargesSaveRequestSuccess(message) {
  return {
    type: chargesSaveType.CHARGES_SAVE_REQUEST_SUCCESS,
    message,
  };
}
function chargesSaveRequestFailure(error) {
  return { type: chargesSaveType.CHARGES_SAVE_REQUEST_FAILURE, error };
}
