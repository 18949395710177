import { cmUsersType } from "../types/cmUsers.type";
export const cmUsersActions = {
  cmUsersRequest,
  cmUsersRequestSuccess,
  cmUsersRequestFailure,
};

function cmUsersRequest(request, token) {
  return { type: cmUsersType.CM_USERS_REQUEST, request, token };
}
function cmUsersRequestSuccess(cmUsers) {
  return {
    type: cmUsersType.CM_USERS_REQUEST_SUCCESS,
    cmUsers,
  };
}
function cmUsersRequestFailure(error) {
  return { type: cmUsersType.CM_USERS_REQUEST_FAILURE, error };
}
