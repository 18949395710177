import { callHistorySaveType } from "../types";
export const callHistorySaveActions = {
  callHistorySaveRequest,
  callHistorySaveRequestSuccess,
  callHistorySaveRequestFailure,
  callHistorySaveRequestReset,
};

function callHistorySaveRequest(request, token) {
  return {
    type: callHistorySaveType.CALL_HISTORY_SAVE_REQUEST,
    request,
    token,
  };
}
function callHistorySaveRequestReset() {
  return {
    type: callHistorySaveType.CALL_HISTORY_SAVE_REQUEST_RESET,
  };
}
function callHistorySaveRequestSuccess(message) {
  return {
    type: callHistorySaveType.CALL_HISTORY_SAVE_REQUEST_SUCCESS,
    message,
  };
}
function callHistorySaveRequestFailure(error) {
  return { type: callHistorySaveType.CALL_HISTORY_SAVE_REQUEST_FAILURE, error };
}
