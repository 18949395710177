import { advanceDirectiveTypes } from "../types";

export const advanceDirectiveActions = {
  advanceDirectiveRequest,
  advanceDirectiveRequestSuccess,
  advanceDirectiveRequestFailure,

  advanceDirectiveOperationRequest,
  advanceDirectiveOperationRequestSuccess,
  advanceDirectiveOperationRequestFailure,
  advanceDirectiveOperationRequestReset,
};
//for get
function advanceDirectiveRequest(request, token) {
  return {
    type: advanceDirectiveTypes.ADVANCE_DIRECTIVE_REQUEST,
    request,
    token,
  };
}
function advanceDirectiveRequestSuccess(get, dnsStatusList) {
  return {
    type: advanceDirectiveTypes.ADVANCE_DIRECTIVE_REQUEST_SUCCESS,
    get,
    dnsStatusList,
  };
}
function advanceDirectiveRequestFailure(error) {
  return {
    type: advanceDirectiveTypes.ADVANCE_DIRECTIVE_REQUEST_FAILURE,
    error,
  };
}

// for update
function advanceDirectiveOperationRequest(request, token) {
  return {
    type: advanceDirectiveTypes.ADVANCE_DIRECTIVE_OPERATION_REQUEST,
    request,
    token,
  };
}
function advanceDirectiveOperationRequestSuccess(message) {
  return {
    type: advanceDirectiveTypes.ADVANCE_DIRECTIVE_OPERATION_REQUEST_SUCCESS,
    message,
  };
}
function advanceDirectiveOperationRequestFailure(error) {
  return {
    type: advanceDirectiveTypes.ADVANCE_DIRECTIVE_OPERATION_REQUEST_FAILURE,

    error,
  };
}
function advanceDirectiveOperationRequestReset() {
  return {
    type: advanceDirectiveTypes.ADVANCE_DIRECTIVE_OPERATION_REQUEST_RESET,
  };
}
