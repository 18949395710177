import { bundleDispositionTypes } from "../types/bundleDisposition.type";

export const bundleDispositionActions = {
    bundleDispositionRequest,
    bundleDispositionRequestSuccess,
    bundleDispositionRequestFailure,
   };
   function bundleDispositionRequest(request, token) {
     return {
       type: bundleDispositionTypes.BUNDLE_DISPOSITION_REQUEST,
       request,
       token,
     };
   }
   function bundleDispositionRequestSuccess(getAll) {
     return {
       type: bundleDispositionTypes.BUNDLE_DISPOSITION_REQUEST_SUCCESS,
       getAll,
     };
   }
   function bundleDispositionRequestFailure(error) {
     return {
       type: bundleDispositionTypes.BUNDLE_DISPOSITION_REQUEST_FAILURE,
       error,
     };
   }
   