import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { colors } from "../../themes/colors";
import SearchIcon from "@mui/icons-material/Search";

const TextFieldSearch = ({
  onChange = () => {}, // No-op by default
  searchValue = "",
  searchReference = null,
  searchPlaceholder = "Search",
  searchStyle = {},
}) => {
  return (
    <TextField
      placeholder={searchPlaceholder}
      fullWidth
      onChange={onChange}
      value={searchValue}
      inputRef={searchReference}
      sx={{
        "@media (max-width: 820px)": {
          minWidth: "100%",
        },
        "@media (min-width: 1200px)": {
          minWidth: 500,
        },
        fontSize: 14,
        "& .MuiInputBase-root": {
          borderRadius: "5px !important",
          backgroundColor: colors.color_background,
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "0 !important",
          border: "1px solid transparent",
          transition: "border 0.3s, box-shadow 0.3s",
          "& fieldset": {
            border: "none",
          },
          "&.Mui-focused": {
            border: `1px solid ${colors.color_light_txt}`,
            color: "inherit",
          },
        },
        "& .MuiInputBase-root, & .MuiOutlinedInput-root": {
          overflow: "visible !important",
        },
        "& .MuiInputBase-input": {
          caretColor: colors.color_primary_txt,
          "&::selection": {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          },
        },
        ...searchStyle,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon htmlColor={colors.color_primary_txt} />
          </InputAdornment>
        ),
        style: {
          borderRadius: 20,
          marginRight: "1vmax",
        },
      }}
      autoComplete="off"
      autoCorrect="off"
      autoFocus
      size="small"
    />
  );
};

export default TextFieldSearch;
