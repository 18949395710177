import { createScheduleAppoinmentsType } from "../../types";

export const createScheduleAppoinmentsTCActions = {
  createScheduleAppoinmentsRequest,
  createScheduleAppoinmentsSuccess,
  createScheduleAppoinmentsFailure,
  createScheduleAppoinmentsReset,
};
function createScheduleAppoinmentsRequest(body, token) {
  return {
    type: createScheduleAppoinmentsType.CREATE_SCHEDULE_APPOINMENTS_TC_REQUEST,
    body,
    token,
  };
}
function createScheduleAppoinmentsSuccess(message) {
  return {
    type: createScheduleAppoinmentsType.CREATE_SCHEDULE_APPOINMENTS_TC_SUCCESS,
    message,
  };
}
function createScheduleAppoinmentsFailure(error) {
  return {
    type: createScheduleAppoinmentsType.CREATE_SCHEDULE_APPOINMENTS_TC_FAILURE,
    error,
  };
}
function createScheduleAppoinmentsReset() {
  return {
    type: createScheduleAppoinmentsType.CREATE_SCHEDULE_APPOINMENTS_TC_RESET,
  };
}
