import { pcpOperationTypes } from "../types";

export const pcpOperationActions = {
  pcpOperationRequest,
  pcpOperationRequestReset,
  pcpOperationRequestSuccess,
  pcpOperationRequestFailure,
};
function pcpOperationRequest(request, token) {
  return {
    type: pcpOperationTypes.PCP_OPERATION_REQUEST,
    request,
    token,
  };
}
function pcpOperationRequestReset() {
  return {
    type: pcpOperationTypes.PCP_OPERATION_REQUEST_RESET,
  };
}
function pcpOperationRequestSuccess(message) {
  return {
    type: pcpOperationTypes.PCP_OPERATION_REQUEST_SUCCESS,
    message,
  };
}
function pcpOperationRequestFailure(error) {
  return {
    type: pcpOperationTypes.PCP_OPERATION_REQUEST_FAILURE,
    error,
  };
}
