import { id } from "date-fns/locale";
import { routeTypes } from "../types/route.type";

const initiaLState = {
  loading: false,
  getAll: [],
  error: "",
  message: "",
};

export const routeGetDMReducer = (state = initiaLState, action) => {
  switch (action.type) {
    case routeTypes.ROUTE_REQUEST:
      return {
        ...initiaLState,
        loading: true,
      };
    case routeTypes.ROUTE_REQUEST_SUCCESS:
      return {
        ...initiaLState,
        getAll: action.getAll,
      };
    case routeTypes.ROUTE_REQUEST_FAILURE:
      return {
        ...initiaLState,
        error: action.error,
      };

    //Create
    case routeTypes.ROUTE_SAVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case routeTypes.ROUTE_SAVE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        getAll: [...state.getAll],
      };
    case routeTypes.ROUTE_SAVE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    //update
    case routeTypes.ROUTE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case routeTypes.ROUTE_UPDATE_REQUEST_SUCCESS:
      let index = state.getAll.findIndex((item) => item.RouteId === action.id);
      const newArr = [...state.getAll]; // create new array to store list
      const updatedRoute = { ...newArr[index], ...action.data }; // Here is the issue
      newArr[index] = updatedRoute;
      return {
        ...state,
        loading: false,
        message: action.message,
        getAll: newArr,
      };
    case routeTypes.ROUTE_UPDATE_REQUEST_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    //Update status
    case routeTypes.ROUTE_ISACTIVE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case routeTypes.ROUTE_ISACTIVE_REQUEST_SUCCESS:
      let index1 = state.getAll.findIndex((item) => item.RouteId === action.id);
      const newArr1 = [...state.getAll]; // create new array to store list
      const route = { ...newArr1[index1], active: !newArr1[index1].active }; // Here is the issue
      newArr1[index1] = route;

      return {
        ...state,
        loading: false,
        message: action.message,
        getAll: newArr1,
      };
    case routeTypes.ROUTE_ISACTIVE_REQUEST_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    //Reset
    case routeTypes.ROUTE_REQUEST_RESET:
      return { ...state, loading: false, message: "", error: "" };

    default:
      return state;
  }
};
// const initialSaveState = {
//   loading: false,
//   message: "",
//   error: "",
// };

// export const routeSaveDMReducer = (state = initialSaveState, action) => {
//   switch (action.type) {
//     case routeTypes.ROUTE_SAVE_REQUEST:
//       return {
//         ...initialSaveState,
//         loading: true,
//       };
//     case routeTypes.ROUTE_SAVE_REQUEST_SUCCESS:
//       return {
//         ...initialSaveState,
//         message: action.message,
//       };
//     case routeTypes.ROUTE_SAVE_REQUEST_FAILURE:
//       return {
//         ...initialSaveState,
//         error: action.error,
//       };
//     case routeTypes.ROUTE_SAVE_REQUEST_RESET:
//       return { ...initialSaveState };
//     default:
//       return state;
//   }
// };

//update route
// const initialUpdateState = {
//   loading: false,
//   message: "",
//   error: "",
// };

// export const routeUpdateDMReducer = (state = initialUpdateState, action) => {
//   switch (action.type) {
//     case routeTypes.ROUTE_UPDATE_REQUEST:
//       return {
//         ...initialUpdateState,
//         loading: true,
//       };
//     case routeTypes.ROUTE_UPDATE_REQUEST_SUCCESS:
//       return {
//         ...initialUpdateState,
//         message: action.message,
//       };
//     case routeTypes.ROUTE_UPDATE_REQUEST_FAILURE:
//       return {
//         ...initialUpdateState,
//         error: action.error,
//       };
//     case routeTypes.ROUTE_UPDATE_REQUEST_RESET:
//       return { ...initialUpdateState };
//     default:
//       return state;
//   }
// };

//isActive route
// const initialIsActiveState = {
//   loading: false,
//   message: "",
//   error: "",
// };

// export const routeIsActiveDMReducer = (
//   state = initialIsActiveState,
//   action
// ) => {
//   switch (action.type) {
//     case routeTypes.ROUTE_ISACTIVE_REQUEST:
//       return {
//         ...initialIsActiveState,
//         loading: true,
//       };
//     case routeTypes.ROUTE_ISACTIVE_REQUEST_SUCCESS:
//       return {
//         ...initialIsActiveState,
//         message: action.message,
//       };
//     case routeTypes.ROUTE_ISACTIVE_REQUEST_FAILURE:
//       return {
//         ...initialIsActiveState,
//         error: action.error,
//       };
//     case routeTypes.ROUTE_ISACTIVE_REQUEST_RESET:
//       return { ...initialIsActiveState };
//     default:
//       return state;
//   }
// };
