import { pushNotificationType } from "../types/pushNotification.type";

export const pushNotificationActions = {
  pushNotificationRequest,
  pushNotificationSuccess,
  pushNotificationFailure,
};
function pushNotificationRequest(request, token) {
  return {
    type: pushNotificationType.PUSH_NOTIFICATION_REQUEST,
    request,
    token,
  };
}
function pushNotificationSuccess(message) {
  return {
    type: pushNotificationType.PUSH_NOTIFICATION_REQUEST_SUCCESS,
    message,
  };
}
function pushNotificationFailure(error) {
  return {
    type: pushNotificationType.PUSH_NOTIFICATION_REQUEST_FAILURE,
    error,
  };
}
