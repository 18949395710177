import * as AllIconsMd from "react-icons/md";
import * as AllIconsFa from "react-icons/fa";
import * as AllIconsAi from "react-icons/ai";
import * as AllIconsHi from "react-icons/hi";
import * as AllIconsVsc from "react-icons/vsc";

import * as AllIconsIo from "react-icons/io";

const Icons = ({ name, ...props }) => {
  const prefix = name?.slice(0, 2);

  switch (prefix) {
    case "Fa":
      const FaIcon = AllIconsFa[name];
      return FaIcon ? <FaIcon {...props} /> : null;
    case "Md":
      const MdIcon = AllIconsMd[name];
      return MdIcon ? <MdIcon {...props} /> : null;
    case "Ai":
      const AiIcon = AllIconsAi[name];
      return AiIcon ? <AiIcon {...props} /> : null;
    case "Hi":
      const HiIcon = AllIconsHi[name];
      return HiIcon ? <HiIcon {...props} /> : null;
    case "Vs":
      const VscIcon = AllIconsVsc[name];
      return VscIcon ? <VscIcon {...props} /> : null;
    case "Io":
      const IoIcon = AllIconsIo[name];
      return IoIcon ? <IoIcon {...props} /> : null;
    default:
      return null;
  }
};

export default Icons;
