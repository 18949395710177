import { roleSaveTypes } from "../types";

export const roleSaveActions = {
    roleSaveRequest,
    roleSaveRequestReset,
    roleSaveRequestSuccess,
    roleSaveRequestFailure,
  };
  function roleSaveRequest(request, token) {
    return {
      type: roleSaveTypes.ROLE_SAVE_REQUEST,
      request,
      token,
    };
  }
  function roleSaveRequestReset() {
    return {
      type: roleSaveTypes.ROLE_SAVE_REQUEST_RESET,
    };
  }
  function roleSaveRequestSuccess(message) {
    return {
      type: roleSaveTypes.ROLE_SAVE_REQUEST_SUCCESS,
      message,
    };
  }
  function roleSaveRequestFailure(error) {
    return {
      type: roleSaveTypes.ROLE_SAVE_REQUEST_FAILURE,
      error,
    };
  }
  