import { put, takeLatest, call } from "@redux-saga/core/effects";
import { dosageNRouteActions } from "../actions/dosageNRoute.action";
import { dosageNRouteType } from "../types/dosageNRoute.type";
import { loginActions } from "../../../Login/actions/login.action";
import { endpoint } from "../../../../api/Url";
import { Api } from "../../../../api";

export function* watchDosageNRoute() {
  yield takeLatest(dosageNRouteType.DOSAGENROUTE_REQUEST, getDosageNRoute);
}

function* getDosageNRoute(action) {
  try {
    const data = yield call(Api, {
      endpoint: `${endpoint.getDosage}?DrugID=${action.request.DrugId}`,
      method: "POST",
      jwt: "Bearer " + action.token,
    });
    const parsedDosageList = JSON.parse(data);

    yield put(dosageNRouteActions.dosageNRouteRequestSuccess(parsedDosageList));
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(dosageNRouteActions.dosageNRouteRequestFailure(error));
  }
}
