import { getMsoTypes } from "../types";

const initiaLState = {
    loading: false,
    get: [],
  };
  
  export const getMsoDMReducer = (state = initiaLState, action) => {
    switch (action.type) {
      case getMsoTypes.GET_MSO_REQUEST:
        return {
          ...initiaLState,
          loading: true,
        };
      case getMsoTypes.GET_MSO_REQUEST_SUCCESS:
        return {
          ...initiaLState,
          get: action.get,
        };
      case getMsoTypes.GET_MSO_REQUEST_FAILURE:
        return {
          ...initiaLState,
          error: action.error,
        };
      default:
        return state;
    }
  };
  
