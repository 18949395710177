import { put, takeLatest, call } from "@redux-saga/core/effects";
import { advanceCarePlanActions } from "../actions";
import { advanceCarePlanTypes } from "../types";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { Api2 } from "../../../../../api/Api2";

export function* watchAdvanceCarePlan() {
  yield takeLatest(
    advanceCarePlanTypes.GET_ADVANCECAREPLAN_REQUEST,
    getAllAdvanceCarePlan
  );
}

function* getAllAdvanceCarePlan(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.advanceCarePlanLookupCmer}`,
      jwt: "Bearer " + action.token,
    });

    yield put(
      advanceCarePlanActions.advanceCarePlanRequestSuccess(response?.data)
    );
  } catch (error) {
    yield put(advanceCarePlanActions.advanceCarePlanRequestFailure(error));
  }
}
