import { getAllLookUpPatientsType } from "../../types";

export const getAllLookUpPatientsTCActions = {
  getAllLookUpPatientsRequest,
  getAllLookUpPatientsSuccess,
  getAllLookUpPatientsFailure,
};
function getAllLookUpPatientsRequest(query, token) {
  return {
    type: getAllLookUpPatientsType.GETALL_LOOKUP_PATIENTS_TC_REQUEST,
    query,
    token,
  };
}
function getAllLookUpPatientsSuccess(getAll) {
  return {
    type: getAllLookUpPatientsType.GETALL_LOOKUP_PATIENTS_TC_SUCCESS,
    getAll,
  };
}
function getAllLookUpPatientsFailure(error) {
  return {
    type: getAllLookUpPatientsType.GETALL_LOOKUP_PATIENT_TC_FAILURE,
    error,
  };
}
