import { put, takeLatest, call } from "@redux-saga/core/effects";
import { loginActions } from "../../../Login/actions/login.action";
import { frequencyActions } from "../actions/frequency.action";
import { frequencyType } from "../types/frequency.type";
import { endpoint } from "../../../../api/Url";
import { Api } from "../../../../api";

export function* watchFrequency() {
  yield takeLatest(frequencyType.FREQUENCY_REQUEST, getFrequency);
}

function* getFrequency(action) {
  try {
    const data = yield call(Api, {
      endpoint: `${endpoint.getFrequency}`,
      method: "POST",
      jwt: "Bearer " + action.token,
    });
    const parsedFrequencyList = JSON.parse(data);

    yield put(frequencyActions.frequencyRequestSuccess(parsedFrequencyList));
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(frequencyActions.frequencyRequestFailure(error));
  }
}
