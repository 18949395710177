import { getActivePatientTransitionType } from "../types";

const initialState = {
  loading: false,
  patientDetails: [],
};

export const getActivePatientDetailsTCReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getActivePatientTransitionType.GETALL_ACTIVE_PATIENT_TC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case getActivePatientTransitionType.GETALL_ACTIVE_PATIENT_TC_SUCCESS:
      return {
        ...state,
        patientDetails: action.patientDetails,
        loading: false,
      };
    case getActivePatientTransitionType.GETALL_ACTIVE_PATIENT_TC_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    default:
      return state;
  }
};
