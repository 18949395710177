import { put, takeLatest, call } from "@redux-saga/core/effects";
import { updateDiagnosisActions } from "../actions";
import { diagnosisUpdateType } from "../types";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";

export function* watchUpdateDiagnosis() {
  yield takeLatest(
    diagnosisUpdateType.UPDATE_DIAGNOSIS_REQUEST,
    getUpdateDiagnosis
  );
}

function* getUpdateDiagnosis(action) {
  try {
    let message = yield call(Api, {
      endpoint: endpoint.updateTCPatientDiagnosis,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    const parsedMessage = JSON.parse(message);

    yield put(
      updateDiagnosisActions.updateDiagnosisRequestSuccess(parsedMessage[0])
    );
  } catch (error) {
    yield put(updateDiagnosisActions.updateDiagnosisRequestFailure(error));
  }
}
