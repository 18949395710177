export const nationTypes = {
  //get NATION
  NATION_REQUEST: "GETALL_DM_NATION_REQUEST",
  NATION_REQUEST_SUCCESS: "GETALL_DM_NATION_REQUEST_SUCCESS",
  NATION_REQUEST_FAILURE: "GETALL_DM_NATION_REQUEST_FAILURE",

  //save NATION
  NATION_SAVE_REQUEST: "DM_NATION_SAVE_REQUEST",
  NATION_SAVE_REQUEST_SUCCESS: "DM_NATION_SAVE_REQUEST_SUCCESS",
  NATION_SAVE_REQUEST_FAILURE: "DM_NATION_SAVE_REQUEST_FAILURE",

  //edit nation
  NATION_EDIT_REQUEST: "DM_NATION_EDIT_REQUEST",
  NATION_EDIT_REQUEST_SUCCESS: "DM_NATION_EDIT_REQUEST_SUCCESS",
  NATION_EDIT_REQUEST_FAILURE: "DM_NATION_EDIT_REQUEST_FAILURE",

  //update nation
  NATION_UPDATE_STATUS_REQUEST: "DM_NATION_UPDATE_STATUS_REQUEST",
  NATION_UPDATE_STATUS_REQUEST_SUCCESS:
    "DM_NATION_UPDATE_STATUS_REQUEST_SUCCESS",
  NATION_UPDATE_STATUS_REQUEST_FAILURE:
    "DM_NATION_UPDATE_STATUS_REQUEST_FAILURE",

  //reset
  NATION_REQUEST_RESET: "DM_NATION_REQUEST_RESET",
};
