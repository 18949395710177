import { put, call, takeLatest } from "@redux-saga/core/effects";
import { Api } from "../../../../api";
import { endpoint } from "../../../../api/Url";
import { immunizationActions } from "../actions";
import { immunizationTypes } from "../types";
export function* watchImmunization() {
  yield takeLatest(
    immunizationTypes.GETALL_IMMUNIZATION_REQUEST,
    getAllImmunization
  );
  yield takeLatest(
    immunizationTypes.OPERARTION_IMMUNIZATION_REQUEST,
    operationImmunization
  );
  yield takeLatest(
    immunizationTypes.DELETE_IMMUNIZATION_REQUEST,
    deleteImmunization
  );
}
function* getAllImmunization(action) {
  try {
    let data = yield call(Api, {
      endpoint: `${endpoint.getImmunizationDetails}?patientId=${action.request.patientId}`,
      method: "POST",
      jwt: "Bearer " + action.token,
    });
    let parsedImmunization = JSON.parse(data);

    yield put(
      immunizationActions.immunizationGetAllRequestSuccess(
        parsedImmunization._listImmunizationDetails,
        parsedImmunization._dropDownImmunization,
        parsedImmunization._dropdownSourcelist
      )
    );
  } catch (error) {
    yield put(immunizationActions.immunizationGetAllRequestFailure(error));
  }
}
function* operationImmunization(action) {
  try {
    let message = yield call(Api, {
      endpoint: `${endpoint.savePatientImmunizationDetails}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedMessage = JSON.parse(message);

    yield put(
      immunizationActions.immunizationOperationRequestSuccess(parsedMessage[0])
    );
  } catch (error) {
    yield put(immunizationActions.immunizationOperationRequestFailure(error));
  }
}
function* deleteImmunization(action) {
  try {
    let message = yield call(Api, {
      endpoint: `${endpoint.deletePatientImmunization}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedMessage = JSON.parse(message);

    yield put(
      immunizationActions.immunizationDeleteRequestSuccess(parsedMessage[0])
    );
  } catch (error) {
    yield put(immunizationActions.immunizationDeleteRequestFailure(error));
  }
}
