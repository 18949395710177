import { getallIcdType } from "../types";

export const getallIcdActions = {
  getallIcdRequest,
  getallIcdSuccess,
  getallIcdFailure,
  getallIcdReset,
};
function getallIcdRequest(request, token) {
  return {
    type: getallIcdType.GETALL_ICD_REQUEST,
    request,
    token,
  };
}
function getallIcdSuccess(getAll) {
  return {
    type: getallIcdType.GETALL_ICD_SUCCESS,
    getAll,
  };
}
function getallIcdFailure(error) {
  return {
    type: getallIcdType.GETALL_ICD_FAILURE,
    error,
  };
}
function getallIcdReset() {
  return {
    type: getallIcdType.GETALL_ICD_RESET,
  };
}
