import { callDispositionTypes } from "../types";

export const callDispositionActions = {
  callDispositionRequest,
  callDispositionRequestSuccess,
  callDispositionRequestFailure,
};
function callDispositionRequest(request, token) {
  return {
    type: callDispositionTypes.CALL_DISPOSITION_REQUEST,
    request,
    token,
  };
}
function callDispositionRequestSuccess(getAll) {
  return {
    type: callDispositionTypes.CALL_DISPOSITION_REQUEST_SUCCESS,
    getAll,
  };
}
function callDispositionRequestFailure(error) {
  return {
    type: callDispositionTypes.CALL_DISPOSITION_REQUEST_FAILURE,
    error,
  };
}
