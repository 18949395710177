import { getAllProvidersType } from "../../types";

const initialState = {
  loading: false,
  getAll: [],
};

export const getAllProvidersTCReducer = (state = initialState, action) => {
  switch (action.type) {
    // case getAllProvidersType.GETALL_PROVIDERS_TC_REQUEST:
    //   return {
    //     ...initialState,
    //     loading: true,
    //   };
    case getAllProvidersType.GETALL_PROVIDERS_TC_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
        loading: false,
      };
    case getAllProvidersType.GETALL_PROVIDERS_TC_FAILURE:
      return {
        ...initialState,
        error: action.error,
        loading: false,
      };

    default:
      return state;
  }
};
