import { patientMessageTypes } from "./patientMessage.type";

const initialState = {
  loading: false,
  message: "",
  error: "",
};
export const patientMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case patientMessageTypes.PATIENT_MESSAGE_SEND_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case patientMessageTypes.PATIENT_MESSAGE_SEND_REQUEST_SUCCESS:
      return {
        ...initialState,
        message: action.message,
      };
    case patientMessageTypes.PATIENT_MESSAGE_SEND_REQUEST_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case patientMessageTypes.PATIENT_MESSAGE_SEND_REQUEST_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
