import React, { forwardRef } from "react";
import dayjs from "dayjs";
import { stringCapatilizeConverter } from "../../../util";

const PrintSample = forwardRef((props, ref) => {
  const ticketStyle = {
    width: "100%",
    padding: "20px",
    backgroundColor: "#f8f9fa",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    fontFamily: "Arial, sans-serif",
    color: "#333",
    border: "1px solid #ddd",
    position: "relative",
    overflow: "hidden",
  };

  const headerStyle = {
    backgroundColor: "#007bff",
    color: "white",
    padding: "10px",
    borderRadius: "5px 5px 0 0",
    marginBottom: "15px",
    textAlign: "center",
  };

  const detailsStyle = {
    backgroundColor: "white",
    padding: "15px",
    borderRadius: "0 0 5px 5px",
    border: "1px solid #eee",
  };

  const rowStyle = {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    fontSize: "14px",
  };

  const labelStyle = {
    fontWeight: "bold",
    color: "#555",
  };
  return (
    <div style={ticketStyle} ref={ref}>
      <img
        src="logo.png" // Replace with your hospital logo path
        alt="Hospital Logo"
        height={60}
        // width={60}
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          objectFit: "contain",
        }}
        // style={logoStyle}
      />
      <div style={headerStyle}>
        <h2 style={{ marginTop: 0, fontSize: "20px" }}>
          Appointment Confirmation
        </h2>
      </div>
      <div style={detailsStyle}>
        {" "}
        <div style={rowStyle}>
          <span style={labelStyle}>Patient Name:</span>
          <span>{`${props?.lastName}, ${props?.firstName}`}</span>
        </div>
        <div style={rowStyle}>
          <span style={labelStyle}>Phone No.:</span>
          <span>
            {props?.phone ? (
              <a
                href={`tel:${props.phone}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {`(${props.phone.slice(0, 3)}) ${props.phone.slice(
                  3,
                  6
                )}-${props.phone.slice(6)}`}
              </a>
            ) : (
              <span style={{ color: "gray" }}>(555) 555-1234</span> // Placeholder when no phone number is available
            )}
          </span>
        </div>
        <div style={rowStyle}>
          <span style={labelStyle}>Provider Name:</span>
          <span>{stringCapatilizeConverter(props?.provider?.Pcp)}</span>
        </div>
        <br />
        <div style={rowStyle}>
          <span style={labelStyle}>Date:</span>
          <span>{dayjs(props?.date).format("MM/DD/YYYY")}</span>
        </div>
        <div style={rowStyle}>
          <span style={labelStyle}>Time:</span>
          <span>{`${dayjs(props?.startTime).format("h:mm A")} - ${dayjs(
            props?.endTime
          ).format("h:mm A")}`}</span>
          {/* // <span>{`${props?.startT} - ${props?.endT}`}</span> */}
        </div>
        <br />
        <div style={rowStyle}>
          <span style={labelStyle}>Speciality:</span>
          {/* <span>{props?.speciality?.DepartmentName}</span> */}
          <span>
            {props?.provider?.DepartmentName ||
              props?.speciality?.DepartmentName}
          </span>
        </div>
        <div style={rowStyle}>
          <span style={labelStyle}>Location:</span>
          {/* <span>{props?.speciality?.facility}</span> */}
          <span>
            {props?.provider?.Facility || props?.speciality?.facility}
          </span>
        </div>
        <div style={rowStyle}>
          <span style={labelStyle}>Reason:</span>
          <span>{props?.reason}</span>
        </div>
      </div>
      <div
        style={{
          marginTop: "15px",
          textAlign: "center",
          fontSize: "12px",
          color: "#777",
        }}
      >
        Please arrive 15 minutes early. Contact us at (850) 863-8100 for any
        queries.
      </div>
    </div>
  );
});

export default PrintSample;
