//imports: dependencies
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/es/storage/session";

//reducers
import { alertReducer } from "../components/AlertNew/store/AlertNew.reducer";
import { errorReducer } from "./reducers/error.reducer";
import { loginReducer } from "../pages/Login/reducers/login.reducer";
import { rememberReducer } from "../pages/Login/reducers/remember.reducer";
import { patientReducer } from "../pages/Patients/reducers/patient.reducer";
import { pageReducer } from "./reducers/page.reducer";
import {
  powerbiDashboardByIDReducer,
  powerbiDashboardTabsReducer,
  powerbiReducer,
} from "../pages/Dashboard/reducers/powerbiDashboard.reducer";
import { powerbiEpisodicReducer } from "../pages/cm/EditPatient/EpisodicCost/reducers/powerbiEpisodic.reducer";
import { lookupRegionReducer } from "./reducers/region.reducer";
import { lookupIPAReducer } from "./reducers/ipa.reducer";
import { lookupPCPGroupReducer } from "./reducers/pcpgroup.reducer";
import { lookupPCPOfficeReducer } from "./reducers/pcpoffice.reducer";
import { lookupUserReducer } from "./reducers/user.reducer";
import { lookupRoleReducer } from "./reducers/role.reducer";
import refreshAppointmentReducer from "../pages/transitionCare/reducers/Appointment/refreshAppointments.reducer";

import {
  callHistoryDetailsCmReducer,
  callHistoryOperationsCmReducer,
} from "../pages/cm_New/CallHistoryCmRole/reducers/callHistory.reducer";
import {
  basicInfoReducer,
  physicianReducer,
  facilityReducer,
  relationshipReducer,
  updateInfoReducer,
  getPcpsDetailsReducer,
} from "../pages/cm/EditPatient/BasicInfo/reducers";
//inbox
import {
  sentReducer,
  replyMailReducer,
  mailDetailsReducer,
  archiveReducer,
  deleteMailReducer,
  inboxMailboxReducer,
  archiveMailboxReducer,
  sentMailboxReducer,
  contactReducer,
  contactRoleReducer,
  patientChatMailboxReducer,
  patientChatDetailsReducer,
  replyPatientChatReducer,
} from "../pages/Inbox/reducers";

import { filtersListReducer } from "../pages/Patients/reducers/filtersList.reducer";
// import { patientDetailsReducer } from "../pages/Patient/BasicInfo/reducers/patientDetails.reducer";
import { visitDetailsReducer } from "../pages/Patient/VisitDetails/reducers/visitDetails.reducer";
import { redirectReducer } from "../pages/Login/reducers/redirect.reducer";

import { chargesReducer } from "../pages/cm/EditPatient/Charges/reducers/charges.reducer";
import {
  tasksDetailsReducer,
  addTaskReducer,
} from "../pages/cm/EditPatient/TasksDetails/reducers";
//for cm role

//callhistory
import {
  callActionTakenReducer,
  callHistoryReducer,
  callDispositionReducer,
  callHistorySaveReducer,
} from "../pages/cm/EditPatient/CallHistory/reducers";
import {
  bundleDispositionReducer,
  chargesInputReducer,
  chargesSaveReducer,
  drgReducer,
  deleteChargesReducer,
} from "../pages/cm/EditPatient/Charges/reducers";

import {
  clinicalInfoUpdateReducer,
  getClinicalInfoReducer,
  advanceCarePlanReducer,
} from "../pages/cm/EditPatient/Diagnosis/reducers";

//Lookup for cm role
import {
  getCarePlanReducer,
  carePlanReducer,
  lookupCMDiagnosisReducer,
  lookupCMGoalReducer,
  lookupCMPatientResonsibilityReducer,
  lookupCMInterventionReducer,
  lookupCMFrequencyReducer,
} from "../pages/cm/EditPatient/CarePlan/reducers/careplan.reducer";

import {
  lookupNotesReducer,
  lookupInventionReducer,
  getCCMReducer,
  saveCCMReducer,
} from "../pages/cm/EditPatient/CronicCareManager/reducers/cronicCareManager.reducer";

//tc role
import { medicationReducer } from "../pages/tc/Medication/reducers/medication.reducer";
import { medicationSaveReducer } from "../pages/tc/Medication/reducers/medicationSave.reducer";
import { setAppointmentReducer } from "../pages/tc/Appointments/reducers/setAppointment.reducer";
import { dosageNRouteReducer } from "../pages/tc/Medication/reducers/dosageNRoute.reducer";
import { drugReducer } from "../pages/tc/Medication/reducers/drug.reducer";
import { frequencyReducer } from "../pages/tc/Medication/reducers/frequency.reducer";
import { deleteMedicationReducer } from "../pages/tc/Medication/reducers/deleteMedication.reducer";
import { deleteAppointmentReducer } from "../pages/tc/Appointments/reducers/deleteAppointment.reducer";
import { addUpdateUtilizationReducer } from "../pages/cm/EditPatient/VisitDetails/reducers/addUpdateUtliization.reducer";
//for tc role
import { appointmentDetailsReducer } from "../pages/tc/Appointments/reducers/appointmentDetails.reducer";
import { pcpReducer } from "./reducers/pcp.reducer";
import { hierarchyLevelReducer } from "./reducers/hierarchylevel.reducer";
import { lookupMenusReducer } from "./reducers/menu.reducer";
import {
  tcPatientDetailsReducer,
  tcPatientDetailsUpdateReducer,
} from "../pages/tc/PatientDetails/reducers";
import {
  diagnosisDetailsReducer,
  getDiagnosisReducer,
  updateDiagnosisReducer,
} from "../pages/tc/Diagnosis/reducers";

import {
  referralPlansReducer,
  addTaskTCReducer,
  cmUsersReducer,
  tasksDetailsTCReducer,
} from "../pages/tc/TasksDetails/reducers";

//for cmd role
import {
  patientDetailsCMDReducer,
  updatePhoneNoCMDReducer,
} from "../pages/cmd/PatientDetails/reducers";
import {
  callHistoryCMDReducer,
  callHistorySaveCMDReducer,
} from "../pages/cmd/CallHistory/reducers";
import { tasksDetailsCMDReducer } from "../pages/cmd/TaskDetails/reducers/cmdTasksDetails.reducer";
import { cmdAddTaskDetailsReducer } from "../pages/cmd/TaskDetails/reducers/cmdAddTasksDetails.reducer";
import { cmdCmUsersReducer } from "../pages/cmd/TaskDetails/reducers/cmdCmUsers.reducer";
//dm role
import {
  facilityDMReducer,
  facilityOperationDMReducer,
} from "../pages/dm/Lookup/Facility/reducers";
import {
  getInsuranceDMReducer,
  insuranceDMReducer,
  insuranceOperationDMReducer,
} from "../pages/dm/Lookup/Insurance/reducers";

import {
  getInsurancePlanDMReducer,
  insurancePlanCompneyDMReducer,
  insurancePlanDMReducer,
  insurancePlanOperationDMReducer,
} from "../pages/dm/Lookup/InsurancePlan/reducers";
import {
  getPCPDMReducer,
  pcpDMReducer,
  pcpOperationDMReducer,
} from "../pages/dm/Lookup/PCP/reducers";
import { msoDMReducer } from "../pages/dm/Lookup/MSO/reducers";
import {
  getMsoDMReducer,
  msoOperationDMReducer,
} from "../pages/dm/Lookup/MSO/reducers";
import {
  roleDMReducer,
  roleSaveDMReducer,
} from "../pages/dm/Lookup/Role/reducers";
import {
  dispositionDMReducer,
  dispositionOperationDMReducer,
  getDispositionDMReducer,
} from "../pages/dm/Lookup/Disposition/reducers";
import {
  bundleDispositionDMReducer,
  bundleDispositionSaveDMReducer,
} from "../pages/dm/Lookup/BundleDisposition/reducers";

import {
  getSocialDeterministDMReducer,
  socialDeterministDMReducer,
  socialDeterministOperationDMReducer,
} from "../pages/dm/Lookup/SocialDeterminist/reducers";

import {
  icdDMReducer,
  icdSaveDMReducer,
} from "../pages/dm/Lookup/ICD/reducers";

import {
  routeGetDMReducer,
  // routeSaveDMReducer,
  // routeUpdateDMReducer,
  // routeIsActiveDMReducer,
} from "../pages/dm/Lookup/Route/reducers/route.reducer";
//dm pcp hierarchy
import { nationDMReducer } from "../pages/dm/Lookup/Nation/reducers/nation.reducer";
import { regionDMReducer } from "../pages/dm/Lookup/Region/reducers/region.reducer";
import { ipaDMReducer } from "../pages/dm/Lookup/IPA/reducers/ipa.reducer";
import { pcpGroupDMReducer } from "../pages/dm/Lookup/PCPGroup/reducers/pcpgroup.reducer";
import { pcpOfficeDMReducer } from "../pages/dm/Lookup/PCPOffice/reducers/pcpoffice.reducer";
import { pcpHierarchyDMReducer } from "../pages/dm/Lookup/PCPHierarchy/reducers/pcphierarchy.reducer";

//lookup
import { lookupNationReducer } from "../pages/dm/Lookup/Region/reducers/lookupNation.reducer";
// dm User Manager
import {
  userReducer,
  userRoleDMReducer,
  userOperationDMReducer,
  roleLookupDMReducer,
} from "../pages/dm/Users/reducers";

import { userPermissionDMReducer } from "../pages/dm/Userpermission/reducers/userpermission.reducer";
//notifications
import { devicePushTokenReducer } from "../components/RightNavbar/Submenus/reducers/pushDeviceToken.reducer";
import { notificationListReducer } from "../components/RightNavbar/Submenus/reducers/notificationsList.reducer";
import { pushNotificationReducer } from "./reducers/pushNotification.reducer";

//Screening Assessments
import {
  AssessmentReducer,
  getAssessmentReducer,
  saveDynamicGaitReducer,
} from "../pages/cm/EditPatient/Assessments/reducers";

//Reports

import { userReportsListReducer } from "../pages/Reports/reducers/getUserReportsList.reducer";
import { getReportsDataReducer } from "../pages/Reports/reducers/geReportsData.reducer";
import { patientMessageReducer } from "../pages/cm/EditPatient/patientMessage.reducer";

import {
  messageListReducer,
  conversationListReducer,
  sendMessageReducer,
} from "../pages/PatientChat/reducers";

import {
  forgetPasswordConfirmOtpReducer,
  forgetPasswordResetReducer,
  forgetPasswordOtpReducer,
} from "../pages/ForgetPassword/reducers";
import { ehrFileUploadReducer } from "../pages/cmd/EHRFiles/reducers";
import {
  advanceDirectiveOperationReducer,
  advanceDirectiveReducer,
} from "../pages/maw/AdvanceDirective/reducers";
import {
  allergyGetReducer,
  allergyOperationReducer,
} from "../pages/maw/Allergies/reducers";
import {
  immunizationDeleteReducer,
  immunizationGetReducer,
  immunizationOperationReducer,
} from "../pages/maw/Immunization/reducers/immunization.reducer";
import {
  surgicalHistoryDeleteReducer,
  surgicalHistoryGetReducer,
  surgicalHistoryOperationReducer,
} from "../pages/maw/SurgicalHistory/reducers";
import {
  hospitalizationDeleteReducer,
  hospitalizationGetReducer,
  hospitalizationOperationReducer,
} from "../pages/maw/Hospitalization/reducers";
import {
  biometricMeasureGetReducer,
  biometricMeasureOperationReducer,
} from "../pages/maw/BiometricMeasurments/reducers";
import {
  problemListDeleteReducer,
  problemListGetReducer,
  problemListOperationReducer,
} from "../pages/maw/ProblemList/reducers";
import {
  screenPreventGetReducer,
  screenPreventOperationReducer,
} from "../pages/maw/ScreeningPreventiveExam/reducers/screenPrevent.reducer";
import {
  labTestsGetReducer,
  labTestsOperationReducer,
} from "../pages/maw/LabTests/reducers";
import {
  educationProvidedGetReducer,
  educationProvidedOperationReducer,
} from "../pages/maw/EducationProvided/reducers";
import {
  assessmentsReferralsGetReducer,
  assessmentsReferralsOperationReducer,
} from "../pages/maw/AssessmentsReferrals/reducers";
import {
  recommendationsGetReducer,
  recommendationsOperationReducer,
} from "../pages/maw/Recommendations/reducers";
import {
  familyHistoryGetReducer,
  familyHistoryOperationReducer,
} from "../pages/maw/FamilyHistory/reducers";
import { printReducer } from "../pages/maw/PrintReport/reducers/print.reducer";
import { dashboardReportReducer } from "../pages/Dashboardv2/reducers";
import { hedisProcedureReducer } from "../pages/hedis/HedisProcedure/reducers/hedis.reducer";
import {
  getChangesComprehensiveReducer,
  getComprehensiveSystemAssessmentReducer,
  updateComprehensiveSystemAssessmentReducer,
} from "../pages/maw/ComprehensiveSystemAssessment/reducers/comprehensive.reducer";
import { permissionReducer } from "./reducers/permission.reducer";

//attending
import { getProviderCptReducer } from "../pages/attending/Billing/reducers/providerCpt.reducer";
import { getallBillingReducer } from "../pages/attending/Billing/reducers/getallBilling.reducer";
import { getallModifierReducer } from "../pages/attending/Billing/reducers/getallModifier.reducer";
import { updateCptReducer } from "../pages/attending/Billing/reducers/updateCptModifier.reducer";
import { updateIcdReducer } from "../pages/attending/Billing/reducers/updateIcdModifier.reducer";
import { getallIcdReducer } from "../pages/attending/Billing/reducers/getallIcd.reducer";

import {
  getBillingCptModifierReducer,
  getBillingIcdReducer,
  updatePatientBillingReducer,
  updatePatientAssignmentSeenReducer,
  patientBillingSkeletonReducer,
  addPatienBillingReducer,
  getRvuReportReducer,
  getallcptReducer,
  addCptCommentReducer,
  patientLookupBillingReducer,
  preventDuplicatePatientAddBillingReducer,
} from "../pages/attending/Billing/reducers";

// For admission discharge transfer
import {
  fileProcessDataReducer,
  getFileDataReducer,
} from "../pages/adt/FileProcessing/store/reducers";
import { getDischargePatientDetailsReducer } from "../pages/Pharmacist/pharma/reducers/getDischargePatientDetails.reducer";
import { getallDischargePatientReducer } from "../pages/Pharmacist/pharma/reducers/getAllDischargePatient.reducer";
import {
  callAttemptedDischargePharmaReducer,
  callCompletedDischargePharmaReducer,
} from "../pages/Pharmacist/pharma/reducers";

import { getActivePatientTCReducer } from "../pages/transitionCare/reducers/getAllActivePatient.reducer";
import {
  createScheduleAppoinmentsTCReducer,
  getActivePatientMedicationReducer,
  getAllProviderSchedulesTCReducer,
  getAllProvidersTCReducer,
  getAllRegionsTCReducer,
  getScheduleAppoinmentsTCReducer,
  getAllPatientAppointmentTCReducer,
  getAllLookUpPatientsTCReducer,
  getActivePatientDetailsTCReducer,
  getActivePatientAppoinmentsTCReducer,
  getAllDosageLookUpTCReducer,
  getAllDrugLookUpTCReducer,
  getAllFrequencyLookUpTCReducer,
  getAllRouteLookUpTCReducer,
  addActivePatientMediationTCReducer,
  updatePatientCallStatusTCReducer,
  deleteMedicationTCReducer,
  getAllFacilityLocationTCReducer,
  getAllLookupPatientStatusTCReducer,
  // getPcpLookUpTCReducer,
  getAllFacilityTypeReducer,
} from "../pages/transitionCare/reducers";
import {
  getHieFileDataReducer,
  hieFileProcessDataReducer,
} from "../pages/adt/HieFileProcessing/store/reducers";
import {
  getAllDuplicatePatientsReducer,
  mergePatientFacilityLookupReducer,
} from "../pages/adt/DuplicatePatientMerging/reducers";
import { mergeDuplicatePatientReducer } from "../pages/adt/DuplicatePatientMerging/reducers/mergeDuplicatePatient.reducer";

/*cmer */
//Patient
import {
  getallCmerPatientReducer,
  getCmerPatientDetailsReducer,
  insuranceCompanylistLookupCmerReducer,
  insurancePlanLookupCmerReducer,
  relationshiplistLookupCmerReducer,
} from "../pages/cmer/reducers";

//task/poc
import {
  lookupGroupsSubgroupsReducer,
  getCmerTasksDetailsReducer,
  addTaskReducerCmer,
} from "../pages/cmer/TaskDetails/reducers";
import { callActionTakenLookupCmerReducer } from "../pages/cmer/CallHistoryCmer/reducers/callActionTakenLookUpCmer.reducer";
import { callDispositionLookupCmerReducer } from "../pages/cmer/CallHistoryCmer/reducers/callDispositionLookup.reducer";
import {
  callHistoryDetailsCmerReducer,
  callHistoryOperationsCmerReducer,
} from "../pages/cmer/CallHistoryCmer/reducers/callHistory.reducer";
import {
  advanceCarePlanLookupCmerReducer,
  diagnosisLookupCmerReducer,
  getClinicalInfoCmerReducer,
  pcpLookupCmerReducer,
  updateClinicalInfoCmerReducer,
} from "../pages/cmer/ClinicalInformation/reducers";
import { roleIdentityLookupDMReducer } from "../pages/dm/Users/reducers/roleIdentityLookup.reducer";
import receptionPatientReducer from "../pages/receptionist/slices/patient.slice";
import dyncamicLookupSlice from "./reducers/dynamicLookup.slice";
import patientDetailsUpdateReceptionSlice from "../pages/receptionist/slices/patientDetailsUpdateReception.slice";
import receptionPatientNotesHistoryReducer from "../pages/receptionist/slices/patientNotesHistory.slice";
import { addTaskReducerCmReducer } from "../pages/cm_New/TaskDetails/reducers/addTask.reducer";
import { getAlltasksDetailsCMReducer } from "../pages/cm_New/TaskDetails/reducers/tasksDetails.reducer";
import {
  getClinicalInfoCmReducer,
  updateClinicalInfoCmReducer,
} from "../pages/cm_New/ClinicalInformationCM/reducers/getUpdatePatientClinicalDetails.reducer";
import { getCMPatientDetailsReducer } from "../pages/cm_New/store/reducers/patientDetailsReducer/getCMPatientDetails.reducer";
import { updateCMPatientDetailsReducer } from "../pages/cm_New/store/reducers/patientDetailsReducer/updateCMPatientDetails.reducer";
import {
  addUpdateUtilizationCMReducer,
  facilityLookupCMReducer,
  visitDetailsCMReducer,
} from "../pages/cm_New/HospitalAdmissions/reducers";
//facesheet
import {
  processFacesheetDataReducer,
  getFacesheetDataReducer,
} from "../pages/adt/HcaFaceSheets/store/reducers";
import {
  getInitialAssessmentReducer,
  updateInitialAssessmentReducer,
} from "../pages/nursing/store/Reducer/initialassessment.slice";
import {
  getNursingProgressReducer,
  updateNursingProgressReducer,
} from "../pages/nursing/store/Reducer/progress.slice";
import lookupDynamicSlice from "./reducers/lookupDynamic.slice";
import errorBoundryReducer from "./slice/ErrorBoundry.slice";
import referralConsultantReducer from "../pages/referral/Referralstore/ReferralSlice";
import { hospitalistPatientListReducer } from "../pages/Hospitalist/store/slice/getHospitalPatientList.slice";
import { addHospitalPatientReducer } from "../pages/Hospitalist/store/slice/addHospitalPaitent.slice";
import patientUpdateReducer from "../pages/adt/patient-update/patientUpdate.store/slice/patientUpdate.slice";
import getPatientListForUpdateReducer from "../pages/adt/patient-update/patientUpdate.store/slice/getPatientList.slice";
import timeZoneReducer from "./slice/TimeZone.slice";

//persist the loginReducer
const loginPersistConfig = {
  key: "login",
  // replace storage with session storage
  storage: sessionStorage,
  whitelist: ["loggedIn", "loggingIn", "userData"],
};
const powerbiPersistConfig = {
  key: "powerbiDashboard",
  storage: sessionStorage,
  whitelist: ["reportData", "error"],
};
const rememberPersistConfig = {
  key: "rememberMe",
  storage: sessionStorage,
  whitelist: ["remember", "loginId", "password"],
};
const filterListPersistConfig = {
  key: "filterListPersist",
  storage: sessionStorage,
  whitelist: ["filtersDataList"],
};

const devicePushTokenConfig = {
  key: "pushDeviceToken",
  storage: sessionStorage,
  whitelist: ["pushDeviceToken"],
};
export const rootReducer = combineReducers({
  alert: alertReducer,
  error: errorReducer,
  login: persistReducer(loginPersistConfig, loginReducer),
  remember: persistReducer(rememberPersistConfig, rememberReducer),
  powerBiReport: persistReducer(powerbiPersistConfig, powerbiReducer),
  dashboardReport: dashboardReportReducer,
  patients: patientReducer,
  pages: pageReducer,
  powerBiEpisodicReport: powerbiEpisodicReducer,

  archive: archiveReducer,
  sent: sentReducer,
  sentMsgs: sentMailboxReducer,
  filtersList: persistReducer(filterListPersistConfig, filtersListReducer),
  // patientDetails: patientDetailsReducer,
  visitDetails: visitDetailsReducer,
  contact: contactReducer,
  redirect: redirectReducer,
  contactRole: contactRoleReducer,

  charges: chargesReducer,
  inboxMailbox: inboxMailboxReducer,
  archiveMailbox: archiveMailboxReducer,
  deleteMail: deleteMailReducer,
  replyMail: replyMailReducer,
  mailDetails: mailDetailsReducer,

  // admin
  powerbiDashboardByID: powerbiDashboardByIDReducer,

  powerbiDashboardTabs: powerbiDashboardTabsReducer,

  //patient chat in inbox (pcp only)
  patientChatMailbox: patientChatMailboxReducer,
  patientChatDetails: patientChatDetailsReducer,
  replyPatientChat: replyPatientChatReducer,
  //cm
  basicInfo: basicInfoReducer,
  physician: physicianReducer,
  facility: facilityReducer,
  relationship: relationshipReducer,
  tasksDetails: tasksDetailsReducer,
  addUpdateUtilization: addUpdateUtilizationReducer,
  callActionTaken: callActionTakenReducer,
  callDisposition: callDispositionReducer,
  callHistory: callHistoryReducer,
  callHistorySave: callHistorySaveReducer,
  updatePatientInfo: updateInfoReducer,
  bundleDisposition: bundleDispositionReducer,
  drgs: drgReducer,
  clinicalInfo: getClinicalInfoReducer,
  clinicalInfoUpdate: clinicalInfoUpdateReducer,
  addTask: addTaskReducer,
  deleteCharges: deleteChargesReducer,
  getPcpsDetails: getPcpsDetailsReducer,

  patientMessage: patientMessageReducer,
  getCarePlan: getCarePlanReducer,
  carePlanReducer: carePlanReducer,
  //lookup cm
  lookupCMDiagnosis: lookupCMDiagnosisReducer,
  lookupCMGoal: lookupCMGoalReducer,
  lookupCMPatientResonsibility: lookupCMPatientResonsibilityReducer,
  lookupCMIntervention: lookupCMInterventionReducer,
  lookupCMFrequency: lookupCMFrequencyReducer,
  lookupNotes: lookupNotesReducer,
  lookupInvention: lookupInventionReducer,
  saveCCM: saveCCMReducer,
  getCCM: getCCMReducer,
  //tc
  medication: medicationReducer,
  medicationSave: medicationSaveReducer,
  deleteMedication: deleteMedicationReducer,

  deleteAppointment: deleteAppointmentReducer,
  setAppointment: setAppointmentReducer,
  dosageNRoute: dosageNRouteReducer,
  drug: drugReducer,
  frequency: frequencyReducer,
  chargesInput: chargesInputReducer,
  chargesSave: chargesSaveReducer,
  appointments: appointmentDetailsReducer,
  pcps: pcpReducer,

  tcPatientDetails: tcPatientDetailsReducer,
  tcPatientDetailsUpdate: tcPatientDetailsUpdateReducer,
  tcPatientDiagnosisUpdate: updateDiagnosisReducer,
  tcPatientDiagnosisDetails: diagnosisDetailsReducer,

  //tc role task details
  referralPlans: referralPlansReducer,
  addTaskTC: addTaskTCReducer,
  cmUsers: cmUsersReducer,
  tasksDetailsTC: tasksDetailsTCReducer,

  getDiagnosis: getDiagnosisReducer,

  //cmd role
  cmdPatientDetails: patientDetailsCMDReducer,
  cmdCallHistory: callHistoryCMDReducer,
  cmdCallHistorySave: callHistorySaveCMDReducer,
  cmdUpdatePhoneNo: updatePhoneNoCMDReducer,
  cmdTasksDetails: tasksDetailsCMDReducer,
  cmdAddTasksDetails: cmdAddTaskDetailsReducer,
  cmdCmUsers: cmdCmUsersReducer,

  //dm role
  dmFacility: facilityDMReducer,
  dmFacilityOperation: facilityOperationDMReducer,

  dmInsurance: insuranceDMReducer,
  dmInsuranceDetails: getInsuranceDMReducer,
  dmInsuranceOperation: insuranceOperationDMReducer,

  dmInsurancePlan: insurancePlanDMReducer,
  dmInsurancePlanCompney: insurancePlanCompneyDMReducer,
  dmInsurancePlanDetails: getInsurancePlanDMReducer,
  dmInsurancePlanOperation: insurancePlanOperationDMReducer,

  dmMSO: msoDMReducer,
  dmMsoDetails: getMsoDMReducer,
  dmMsoOperation: msoOperationDMReducer,

  dmPCP: pcpDMReducer,
  dmPCPDetails: getPCPDMReducer,
  dmPCPOperation: pcpOperationDMReducer,

  dmDisposition: dispositionDMReducer,
  dmDispositionDetails: getDispositionDMReducer,
  dmDispositionOperation: dispositionOperationDMReducer,

  dmRole: roleDMReducer,
  dmRoleLookup: roleLookupDMReducer,
  roleIdentityLookupDM: roleIdentityLookupDMReducer,
  dmRoleSave: roleSaveDMReducer,

  dmBundleDisposition: bundleDispositionDMReducer,
  dmBundleDispositionSave: bundleDispositionSaveDMReducer,

  dmICD: icdDMReducer,
  dmIcdSave: icdSaveDMReducer,

  dmSocialDeterminist: socialDeterministDMReducer,
  dmSocialDeterministDetails: getSocialDeterministDMReducer,
  dmSocialDeterministOperation: socialDeterministOperationDMReducer,

  routeGet: routeGetDMReducer,
  // routeSaveDM: routeSaveDMReducer,
  // routeUpdateDM: routeUpdateDMReducer,
  // routeIsActiveDM: routeIsActiveDMReducer,
  nationDM: nationDMReducer,
  regionDM: regionDMReducer,
  ipaDM: ipaDMReducer,
  pcpGroupDM: pcpGroupDMReducer,
  pcpOfficeDM: pcpOfficeDMReducer,
  pcpHierarchyDM: pcpHierarchyDMReducer,
  dmHierarchyLevel: hierarchyLevelReducer,
  dmlookupMenu: lookupMenusReducer,
  dmUserPermission: userPermissionDMReducer,

  //pcpgroupDM: pcpDMReducer,
  lookupNation: lookupNationReducer,
  lookupRegion: lookupRegionReducer,
  lookupIPA: lookupIPAReducer,
  lookupPCPGroup: lookupPCPGroupReducer,
  lookupPCPOffice: lookupPCPOfficeReducer,
  lookupUser: lookupUserReducer,
  lookupRole: lookupRoleReducer,

  //User manager
  dmUser: userReducer,
  dmUserRoleIdentity: userRoleDMReducer,
  dmUserOperation: userOperationDMReducer,

  //notifications
  pushNotification: pushNotificationReducer,
  devicePushToken: persistReducer(
    devicePushTokenConfig,
    devicePushTokenReducer
  ),
  notificationList: notificationListReducer,

  advanceCarePlan: advanceCarePlanReducer,

  //Screening Assessments
  cmAssessment: AssessmentReducer,
  cmGetAssessment: getAssessmentReducer,
  cmSaveDynamicGait: saveDynamicGaitReducer,
  getUserReportsList: userReportsListReducer,
  getReportsData: getReportsDataReducer,

  //Patient Pacts
  messageList: messageListReducer,
  conversationList: conversationListReducer,
  sendMessage: sendMessageReducer,

  //forget password
  forgetPasswordOtp: forgetPasswordOtpReducer,
  forgetPasswordConfirmOtp: forgetPasswordConfirmOtpReducer,
  forgetPasswordReset: forgetPasswordResetReducer,

  ehrFileUpload: ehrFileUploadReducer,
  advanceDirective: advanceDirectiveReducer,
  advanceDirectiveOperation: advanceDirectiveOperationReducer,

  allergyGet: allergyGetReducer,
  allergyOperation: allergyOperationReducer,

  immunizationGet: immunizationGetReducer,
  immunizationOperation: immunizationOperationReducer,
  immunizationDelete: immunizationDeleteReducer,

  surgicalHistoryDelete: surgicalHistoryDeleteReducer,
  surgicalHistoryGet: surgicalHistoryGetReducer,
  surgicalHistoryOperation: surgicalHistoryOperationReducer,
  hospitalizationGet: hospitalizationGetReducer,
  hospitalizationOperation: hospitalizationOperationReducer,
  hospitalizationDelete: hospitalizationDeleteReducer,

  biometricMeasureOperation: biometricMeasureOperationReducer,
  biometricMeasureGet: biometricMeasureGetReducer,

  problemListGet: problemListGetReducer,
  problemListOperation: problemListOperationReducer,
  problemListDelete: problemListDeleteReducer,

  screenPreventGet: screenPreventGetReducer,
  screenPreventOperation: screenPreventOperationReducer,

  labTestsGet: labTestsGetReducer,
  labTestsOperation: labTestsOperationReducer,

  educationProvidedGet: educationProvidedGetReducer,
  educationProvidedOperation: educationProvidedOperationReducer,

  assessmentsReferralsGet: assessmentsReferralsGetReducer,
  assessmentsReferralsOperation: assessmentsReferralsOperationReducer,

  recommendationsGet: recommendationsGetReducer,
  recommendationsOperation: recommendationsOperationReducer,

  familyHistoryGet: familyHistoryGetReducer,
  familyHistoryOperation: familyHistoryOperationReducer,
  print: printReducer,

  //hedis
  hedisProcedure: hedisProcedureReducer,

  //maw
  getComprehensiveSystemAssessment: getComprehensiveSystemAssessmentReducer,
  updateComprehensiveSystemAssessment:
    updateComprehensiveSystemAssessmentReducer,
  getChangesComprehensive: getChangesComprehensiveReducer,

  //permissions
  permission: permissionReducer,

  //attending role
  getProviderCpt: getProviderCptReducer,
  getallBilling: getallBillingReducer,
  getallModifier: getallModifierReducer,
  updateCptModifier: updateCptReducer,
  updateIcdModifier: updateIcdReducer,
  getallIcd: getallIcdReducer,
  getBillingCptModifier: getBillingCptModifierReducer,
  getBillingIcd: getBillingIcdReducer,
  updatePatientBilling: updatePatientBillingReducer,
  updatePatientAssignmentSeen: updatePatientAssignmentSeenReducer,
  patientBillingSkeleton: patientBillingSkeletonReducer,
  addPatienBilling: addPatienBillingReducer,
  getRvuReport: getRvuReportReducer,
  getallcpt: getallcptReducer,
  addCptComment: addCptCommentReducer,
  patientLookupBilling: patientLookupBillingReducer,
  preventDuplicatePatientAddBilling: preventDuplicatePatientAddBillingReducer,

  //adt
  getFileAdtProccessing: getFileDataReducer, //get file
  adtFileProcessing: fileProcessDataReducer, //file process
  getHieFileData: getHieFileDataReducer, //!hie File procee
  hieFileProcessData: hieFileProcessDataReducer, //!hie File procee

  // duplicate Patients mergen
  getAllDuplicatePatients: getAllDuplicatePatientsReducer,
  mergeDuplicatePatient: mergeDuplicatePatientReducer,
  mergePatientFacilityLookup: mergePatientFacilityLookupReducer,

  // Patient -update
  patientUpdate: patientUpdateReducer,
  getPatientListForUpdate: getPatientListForUpdateReducer,
  //pharmacist
  getallDischargePatient: getallDischargePatientReducer,
  dischargePatientDetails: getDischargePatientDetailsReducer,
  callAttemptedDischargePharma: callAttemptedDischargePharmaReducer,
  callCompletedDischargePharma: callCompletedDischargePharmaReducer,

  // tansition care
  getAllLookupPatientStatusTC: getAllLookupPatientStatusTCReducer, //? for getting role based patient data
  getActivePatientTC: getActivePatientTCReducer, //? for getting patient data
  getActivePatientMedication: getActivePatientMedicationReducer, //? to get patient medication details
  getAllProviderSchedules: getAllProviderSchedulesTCReducer, //? get get the schedules of providers
  getAllPatientAppointment: getAllPatientAppointmentTCReducer, //? to get all  appointment history of patient
  getAllFacilityLocation: getAllFacilityLocationTCReducer, //? lookup for geting facility
  getAllProviders: getAllProvidersTCReducer, //? looku[ for pcp
  getAllRegions: getAllRegionsTCReducer, //? lookup for regions
  getScheduleAppoinments: getScheduleAppoinmentsTCReducer, //? to  get all the appointments for a pcp
  createScheduleAppoinments: createScheduleAppoinmentsTCReducer, //? to add appointments for a pcp
  getAllPatientLookup: getAllLookUpPatientsTCReducer,
  getActivePatientDetails: getActivePatientDetailsTCReducer,
  // !for transcation caree active patient appoinment history
  getActivePatientAppoinments: getActivePatientAppoinmentsTCReducer,
  refreshAppointment: refreshAppointmentReducer,
  timeZoneCurrent: timeZoneReducer,

  getAllDosageLookUp: getAllDosageLookUpTCReducer,
  getAllDrugLookUp: getAllDrugLookUpTCReducer,
  getAllFrequencyLookUp: getAllFrequencyLookUpTCReducer,
  getAllRouteLookUp: getAllRouteLookUpTCReducer,
  getAllFacilityType: getAllFacilityTypeReducer,
  //!pending
  addActivePatientMediation: addActivePatientMediationTCReducer,
  deleteMedicationTC: deleteMedicationTCReducer,
  updatePatientCallStatus: updatePatientCallStatusTCReducer,

  /*cmer */
  getallCmerPatient: getallCmerPatientReducer,
  getCmerPatientDetails: getCmerPatientDetailsReducer,

  //Task/Poc
  lookupGroupsSubgroups: lookupGroupsSubgroupsReducer,
  getTasksDetails: getCmerTasksDetailsReducer,
  addTaskReducerCmer: addTaskReducerCmer,

  // call histor
  callActionTakenLookupCmer: callActionTakenLookupCmerReducer,
  callDispositionLookupCmer: callDispositionLookupCmerReducer,
  callHistoryOperationsCmer: callHistoryOperationsCmerReducer,
  callHistoryDetailsCmer: callHistoryDetailsCmerReducer,

  // clinicalinformation
  advanceCarePlanLookupCmer: advanceCarePlanLookupCmerReducer,
  diagnosisLookupCmer: diagnosisLookupCmerReducer,
  pcpLookupCmer: pcpLookupCmerReducer,
  getClinicalInfoCmer: getClinicalInfoCmerReducer,
  updateClinicalInfoCmer: updateClinicalInfoCmerReducer,
  relationshiplistLookupCmer: relationshiplistLookupCmerReducer,
  insuranceCompanylistLookupCmer: insuranceCompanylistLookupCmerReducer,
  insurancePlanLookupCmer: insurancePlanLookupCmerReducer,
  receptionPatient: receptionPatientReducer,
  dynamicLookupData: dyncamicLookupSlice,
  dynamicLookupDatas: lookupDynamicSlice,

  patientDetailsUpdateReception: patientDetailsUpdateReceptionSlice,
  receptionPatientNotesHistory: receptionPatientNotesHistoryReducer,

  //facesheet
  processFacesheetData: processFacesheetDataReducer,
  getFacesheetData: getFacesheetDataReducer,
  // Cm ROle --call history
  callHistoryDetailsCm: callHistoryDetailsCmReducer,
  callHistoryOperationsCm: callHistoryOperationsCmReducer,
  // Cm ROle --task poc
  addTaskReducerCM: addTaskReducerCmReducer,
  getAlltasksDetailsCM: getAlltasksDetailsCMReducer,
  getClinicalInfoCm: getClinicalInfoCmReducer,
  updateClinicalInfoCm: updateClinicalInfoCmReducer,

  // CM role-patietndetaisl
  getCMPatientDetails: getCMPatientDetailsReducer,
  updateCMPatientDetails: updateCMPatientDetailsReducer,

  visitDetailsCM: visitDetailsCMReducer,
  facilityLookupCM: facilityLookupCMReducer,
  addUpdateUtilizationCM: addUpdateUtilizationCMReducer,
  // Role Nurisng /Facility
  getInitialAssessment: getInitialAssessmentReducer,
  updateInitialAssessment: updateInitialAssessmentReducer,
  getNursingProgress: getNursingProgressReducer,
  updateNursingProgress: updateNursingProgressReducer,
  errorBoundryState: errorBoundryReducer,

  // Referral Role
  referralConsultant: referralConsultantReducer,

  // Hospitalist
  hospitalistPatientList: hospitalistPatientListReducer,
  addHospitalPatient: addHospitalPatientReducer,
});
