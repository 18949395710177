import { facesheetTypes } from "../types/facesheet.type";

const initialState = {
  loading: false,
  data: null,
};

export const getFacesheetDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case facesheetTypes.GET_FACESHEET_DATA_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case facesheetTypes.GET_FACESHEET_DATA_SUCCESS:
      return {
        ...initialState,
        data: action.data,
        loading: false,
      };
    case facesheetTypes.GET_FACESHEET_DATA_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case facesheetTypes.GET_FACESHEET_DATA_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
