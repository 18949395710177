import React from "react";
import * as MdIcons from "react-icons/md";
import styles from "./BtnDelete.module.scss";
export const BtnDelete = ({ onClick, loading }) => {
  return (
    <button className={styles.btn_dlt} onClick={onClick} title="Delete">
      {/* <MdIcons.MdDelete className={styles.dlt_icon} /> */}
      {!loading && <MdIcons.MdDelete className={styles.dlt_icon} />}
      {loading && <MdIcons.MdDeleteSweep className={styles.dlt_icon} />}
    </button>
  );
};
