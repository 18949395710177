import { insurancePlanCompneyTypes } from "../types";

export const insurancePlanCompneyActions = {
  insurancePlanCompneyRequest,
  insurancePlanCompneyRequestSuccess,
  insurancePlanCompneyRequestFailure,
};
function insurancePlanCompneyRequest(request, token) {
  return {
    type: insurancePlanCompneyTypes.INSURANCE_PLAN_COMPNEY_REQUEST,
    request,
    token,
  };
}
function insurancePlanCompneyRequestSuccess(getAll) {
  return {
    type: insurancePlanCompneyTypes.INSURANCE_PLAN_COMPNEY_REQUEST_SUCCESS,
    getAll,
  };
}
function insurancePlanCompneyRequestFailure(error) {
  return {
    type: insurancePlanCompneyTypes.INSURANCE_PLAN_COMPNEY_REQUEST_FAILURE,
    error,
  };
}
