import { userOperationTypes } from "../types";

export const userOperationActions = {
    userOperationRequest,
    userOperationRequestReset,
    userOperationRequestSuccess,
    userOperationRequestFailure,
  };
  function userOperationRequest(request, token) {
    return {
      type: userOperationTypes.USER_OPERATION_REQUEST,
      request,
      token,
    };
  }
  function userOperationRequestReset() {
    return {
      type: userOperationTypes.USER_OPERATION_REQUEST_RESET,
    };
  }
  function userOperationRequestSuccess(message) {
    return {
      type: userOperationTypes.USER_OPERATION_REQUEST_SUCCESS,
      message,
    };
  }
  function userOperationRequestFailure(error) {
    return {
      type: userOperationTypes.USER_OPERATION_REQUEST_FAILURE,
      error,
    };
  }
  