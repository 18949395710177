import { callCompletedPharmaType } from "../types";

export const callCompletedPatientDischargePharmaActions = {
  callCompletedPatientDischargeRequest,
  callCompletedPatientDischargeSuccess,
  callCompletedPatientDischargeFailure,
  callCompletedPatientDischargeReset,
};
function callCompletedPatientDischargeRequest(query, request, token) {
  return {
    type: callCompletedPharmaType.CALL_COMPLETED_PHARMA_REQUEST,
    query,
    request,
    token,
  };
}
function callCompletedPatientDischargeSuccess(message) {
  return {
    type: callCompletedPharmaType.CALL_COMPLETED_PHARMA_SUCCESS,
    message,
  };
}
function callCompletedPatientDischargeFailure(error) {
  return {
    type: callCompletedPharmaType.CALL_COMPLETED_PHARMA_FAILURE,
    error,
  };
}
function callCompletedPatientDischargeReset() {
  return {
    type: callCompletedPharmaType.CALL_COMPLETED_PHARMA_RESET,
  };
}
