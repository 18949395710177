import { allergiesTypes } from "../types";

export const allergyActions = {
  //for get
  allergyGetAllRequest,
  allergyGetAllRequestSuccess,
  allergyGetAllRequestFailure,

  //for update delete insert
  allergyOperationRequest,
  allergyOperationRequestSuccess,
  allergyOperationRequestFailure,
  allergyOperationRequestReset,
};
function allergyGetAllRequest(request, token) {
  return {
    type: allergiesTypes.GETALL_ALLERGY_REQUEST,
    request,
    token,
  };
}
function allergyGetAllRequestSuccess(getAll, sourceList, serverityList) {
  return {
    type: allergiesTypes.GETALL_ALLERGY_REQUEST_SUCCESS,
    getAll,
    // sourceList,
    // serverityList,
  };
}
function allergyGetAllRequestFailure(error) {
  return {
    type: allergiesTypes.GETALL_ALLERGY_REQUEST_FAILURE,
    error,
  };
}

function allergyOperationRequest(request, token) {
  return {
    type: allergiesTypes.OPERARTION_ALLERGY_REQUEST,
    request,
    token,
  };
}
function allergyOperationRequestSuccess(message) {
  return {
    type: allergiesTypes.OPERARTION_ALLERGY_REQUEST_SUCCESS,
    message,
  };
}
function allergyOperationRequestFailure(error) {
  return {
    type: allergiesTypes.OPERARTION_ALLERGY_REQUEST_FAILURE,
    error,
  };
}
function allergyOperationRequestReset() {
  return {
    type: allergiesTypes.OPERARTION_ALLERGY_REQUEST_RESET,
  };
}
