import { takeLatest, put, call, debounce } from "@redux-saga/core/effects";
import { endpoint } from "../../../../api/Url";
import { facilityLookupCMType } from "../types";
import {
  addUpdateUtilizationActions,
  facilityLookupCMActions,
} from "../actions";
import { Api2 } from "../../../../api/Api2";

export function* watchFacilityLookupCM() {
  yield debounce(
    500,
    facilityLookupCMType.FACILITY_LOOKUP_CM_REQUEST,
    facilityLookupCM
  );
}

function* facilityLookupCM(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.facilityLookupCM}?SearchStr=${action?.request?.SearchStr}`,
      jwt: "Bearer " + action.token,
    });
    yield put(facilityLookupCMActions.facilityLookupCMSuccess(response?.data));
  } catch (error) {
    yield put(facilityLookupCMActions.facilityLookupCMFailure(error));
  }
}
