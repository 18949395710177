import { patientLookUpBillingType } from "../types";

export const patientLookupBillingActions = {
  patientLookupBillingRequest,
  patientLookupBillingSuccess,
  patientLookupBillingFailure,
  patientLookupBillingReset,
};
function patientLookupBillingRequest(query, token) {
  return {
    type: patientLookUpBillingType.PATIENT_LOOKUP_BILLING_REQUEST,
    query,
    token,
  };
}
function patientLookupBillingSuccess(getAll) {
  return {
    type: patientLookUpBillingType.PATIENT_LOOKUP_BILLING_SUCCESS,
    getAll,
  };
}
function patientLookupBillingFailure(error) {
  return {
    type: patientLookUpBillingType.PATIENT_LOOKUP_BILLING_FAILURE,
    error,
  };
}
function patientLookupBillingReset() {
  return {
    type: patientLookUpBillingType.PATIENT_LOOKUP_BILLING_RESET,
  };
}
