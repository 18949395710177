import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: "",
  error: "",
};

export const patientDetailsUpdateReceptionSlice = createSlice({
  name: "patientDetailsUpdateReception",
  initialState: initialState,
  reducers: {
    patientDetailsUpdateReceptionRequest: (state, action) => {
      state.loading = true;
    },
    patientDetailsUpdateReceptionSuccess: (state, action) => {
      console.log(action.payload, "payload");
      state.loading = false;
      state.error = "";
      state.message = action.payload?.message;
    },
    patientDetailsUpdateReceptionFaliure: (state, action) => {
      state.loading = false;
      state.message = "";
      state.error = action.payload.error;
    },
    patientDetailsUpdateReceptionReset: (state, action) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  patientDetailsUpdateReceptionRequest,
  patientDetailsUpdateReceptionSuccess,
  patientDetailsUpdateReceptionFaliure,
  patientDetailsUpdateReceptionReset,
} = patientDetailsUpdateReceptionSlice.actions;
export default patientDetailsUpdateReceptionSlice.reducer;
