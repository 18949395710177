import { physicianTypes } from "../types";

const initialState = {
  loading: false,
  getAll: [],
};

export const physicianReducer = (state = initialState, action) => {
  switch (action.type) {
    case physicianTypes.PHYSICIAN_REQUEST:
      return { ...initialState, loading: true };
    case physicianTypes.PHYSICIAN_REQUEST_SUCCESS:
      return { ...initialState, getAll: action.getAll };
    case physicianTypes.PHYSICIAN_REQUEST_FAILURE:
      return { ...initialState, error: action.error };
    default:
      return state;
  }
};
