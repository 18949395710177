import { clinicalInfoUpdateTypes } from "../types";

const initialState = {
  updating: false,
};

export const clinicalInfoUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case clinicalInfoUpdateTypes.CLINICAL_INFO_UPDATE_REQUEST:
      return {
        ...initialState,
        updating: true,
      };
    case clinicalInfoUpdateTypes.CLINICAL_INFO_UPDATE_REQUEST_SUCCESS:
      return {
        ...initialState,
        message: action.message,
      };
    case clinicalInfoUpdateTypes.CLINICAL_INFO_UPDATE_REQUEST_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case clinicalInfoUpdateTypes.CLINICAL_INFO_UPDATE_REQUEST_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
