import { mergePatientFacilityLookupTypes } from "../types";

const initialState = {
  loading: false,
  getAll: [],
  error: "",
};

export const mergePatientFacilityLookupReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case mergePatientFacilityLookupTypes.MERGE_PATIENT_FACILITY_LOOKUP_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case mergePatientFacilityLookupTypes.MERGE_PATIENT_FACILITY_LOOKUP_SUCCESS: {
      return {
        ...initialState,
        getAll: action.getAll,
      };
    }
    case mergePatientFacilityLookupTypes.MERGE_PATIENT_FACILITY_LOOKUP_FAILURE: {
      return {
        ...initialState,
        error: action.error,
      };
    }
    case mergePatientFacilityLookupTypes.MERGE_PATIENT_FACILITY_LOOKUP_RESET: {
      return initialState;
    }
    default:
      return state;
  }
};
