import { patientDetailsType } from "../types";

const initialState = {
  loading: false,
};
export const tcPatientDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case patientDetailsType.GET_PATIENT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case patientDetailsType.GET_PATIENT_DETAILS_REQUEST_SUCCESS:
      return {
        ...initialState,
        details: action.details,
      };
    case patientDetailsType.GET_PATIENT_DETAILS_REQUEST_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    default:
      return state;
  }
};
