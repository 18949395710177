import { patientBillingSkeletonTypes } from "../types";

export const patientBillingSkeletonActions = {
  patientBillingSkeletonRequest,
  patientBillingSkeletonReset,
};

function patientBillingSkeletonRequest(message) {
  return {
    type: patientBillingSkeletonTypes.PATIENT_BILLING_SKELETON_REQUEST,
    message,
  };
}

function patientBillingSkeletonReset() {
  return { type: patientBillingSkeletonTypes.PATIENT_BILLING_SKELETON_RESET };
}
