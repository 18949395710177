export const getClinicalInfoCmerTypes = {
  GET_CLINICAL_INFO_CMER_REQUEST: "GET_CLINICAL_INFO_CMER_REQUEST",
  GET_CLINICAL_INFO_CMER_SUCCESS: "GET_CLINICAL_INFO_CMER_SUCCESS",
  GET_CLINICAL_INFO_CMER_FAILURE: "GET_CLINICAL_INFO_CMER_FAILURE",
};

export const updateClinicalInfoCmerTypes = {
  UPDATE_CLINICAL_INFO_CMER_REQUEST: "UPDATE_CLINICAL_INFO_CMER_REQUEST",
  UPDATE_CLINICAL_INFO_CMER_RESET: "UPDATE_CLINICAL_INFO_CMER_RESET",
  UPDATE_CLINICAL_INFO_CMER_SUCCESS: "UPDATE_CLINICAL_INFO_CMER_SUCCESS",
  UPDATE_CLINICAL_INFO_CMER_FAILURE: "UPDATE_CLINICAL_INFO_CMER_FAILURE",
};
