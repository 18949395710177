import { getAllPatientAppoinmentType } from "../../types";

const initialState = {
  loading: false,
  getAll: [],

};

export const getAllPatientAppointmentTCReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getAllPatientAppoinmentType.GETALL_PATIENT_APPOINMENT_TC_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case getAllPatientAppoinmentType.GETALL_PATIENT_APPOINMENT_TC_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
        schedule: action.schedules,
      };
    case getAllPatientAppoinmentType.GETALL_PATIENT_APPOINMENT_TC_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };

    default:
      return state;
  }
};
