import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  Grid,
  Checkbox,
  Link,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ReplayIcon from "@mui/icons-material/Replay";
import BugReportIcon from "@mui/icons-material/BugReport";
import { colors } from "../themes/colors";
import { useDispatch, useSelector } from "react-redux";
import { useErrorBoundary } from "react-error-boundary";
import { errorBoundryReSet } from "../store/slice/ErrorBoundry.slice";
import { loginActions } from "../pages/Login/actions/login.action";
import { CiLogout } from "react-icons/ci";

const ErrorBoundary = ({ error, resetErrorBoundary }) => {
  const [reportChecked, setReportChecked] = useState(true);
  const dispatch = useDispatch();
  const handleReportError = () => {
    // Implement error reporting logic here
    console.log("Error reported:", error);
  };

  return (
    <Box
      sx={{
        position: "relative",
        minHeight: "100vh",
        background: "linear-gradient(to bottom, #f8f8f8, #e0e0e0, #c0c0c0)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button
        variant="text"
        onClick={() => {
          resetErrorBoundary();
          dispatch(loginActions.logoutRequest());
        }}
        sx={{
          color: colors.color_risk_level,
          textTransform: "capitalize",
          position: "absolute",
          top: 4,
          right: 10,
          ":hover": {
            textTransform: "capitalize",
            color: colors.color_risk_level,
            opacity: 0.9,
            backgroundColor: "transparent",
          },
        }}
        startIcon={<CiLogout />}
      >
        Logout
      </Button>
      <Container
        maxWidth="sm"
        sx={{
          textAlign: "center", // Center align text
        }}
      >
        <ErrorOutlineIcon
          sx={{
            fontSize: 60,
            mb: 2,
            color: "linear-gradient(to right, #b0b0b0, #808080, #404040)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
          }}
        />
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            background: "linear-gradient(to bottom, #b0b0b0, #808080, #404040)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            color: "transparent",
          }}
        >
          Oops! Something went wrong
        </Typography>
        {/* <Typography variant="body1" paragraph color="error.dark">
          {error.message || "An unexpected error occurred"}
        </Typography> */}
        <Box
          sx={{
            mt: 4,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              resetErrorBoundary();
              dispatch(errorBoundryReSet());
            }}
            sx={{
              textTransform: "capitalize",
              backgroundColor: colors.color_active,
              color: colors.color_primary_txt,
              ":hover": {
                textTransform: "capitalize",

                backgroundColor: colors.color_active,
                color: colors.color_primary_txt,
                opacity: 0.9,
              },
            }}
            startIcon={<ReplayIcon />}
          >
            Reload
          </Button>
        </Box>
      </Container>

      <Box sx={{ position: "fixed", bottom: 16, right: 16 }}>
        <Grid
          container
          direction="column"
          spacing={1}
          alignItems="flex-end"
          sx={{ fontSize: 12 }}
        >
          <Grid item>
            <Button
              variant="contained"
              onClick={handleReportError}
              startIcon={<BugReportIcon />}
              disabled={!reportChecked}
              sx={{
                textTransform: "none",
                color: colors.color_primary,
                backgroundColor: colors.color_primary_txt,
                ":hover": {
                  color: colors.color_primary,
                  backgroundColor: colors.color_primary_txt,
                  opacity: 0.7,
                },
              }}
              size="small"
            >
              Report Error
            </Button>
          </Grid>
          <Grid item container alignItems="center" spacing={1}>
            <Grid item>
              <Checkbox
                checked={reportChecked}
                onChange={(e) => setReportChecked(e.target.checked)}
                sx={{
                  transform: "scale(0.75)",
                  padding: 0,
                }}
                size="small"
              />
            </Grid>
            <Grid item>
              <Typography variant="caption" sx={{ fontSize: 12 }}>
                I agree to report this error
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ position: "fixed", bottom: 16, left: 16 }}>
        <Typography variant="caption" sx={{ fontSize: 12 }}>
          Contact:{" "}
          <Link href="mailto:developer@white-wilson.com">
            developer@white-wilson.com
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default ErrorBoundary;
