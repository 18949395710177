import { call, put, takeLatest } from "redux-saga/effects";
import { Url, endpoint } from "../../../../../api/Url";
import axios from "axios";
import { alertActions } from "../../../../../components/AlertNew/store/AlertNew.action";
import { facesheetActions } from "../actions/facesheet.action";
import { facesheetTypes } from "../types/facesheet.type";
import { Api2 } from "../../../../../api/Api2";

export function* watchFacesheet() {
  yield takeLatest(facesheetTypes.GET_FACESHEET_DATA_REQUEST, getFacesheetData);
  yield takeLatest(
    facesheetTypes.PROCESS_FACESHEET_DATA_REQUEST,
    processFacesheetData
  );
}

function* getFacesheetData(action) {
  let urlEndpoint = `${Url.baseURL2}${endpoint.azureHcaFaceSheets}?read=${action.query.read}&write=${action.query.write}`;
  try {
    let response = yield call(axios, {
      method: "POST",
      url: urlEndpoint,
      data: action.request,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + action.token,
      },
      timeout: 1800000, // 30 minutes
    });
    if (
      response?.data?.message !== undefined &&
      response?.data?.message.length > 0
    ) {
      yield put(
        facesheetActions.getFacesheetDataSuccess(response?.data?.message[0])
      );
    } else {
      yield put(
        facesheetActions.getFacesheetDataFailure("Failed to read the facesheet")
      );
    }
  } catch (error) {
    console.log(error);
    yield put(facesheetActions.getFacesheetDataFailure(error));
  }
}

function* processFacesheetData(action) {
  try {
    const response = yield call(Api2, {
      method: "POST",
      endpoint: `${endpoint.storepatientFacesheet}`,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    if (response.message.result) {
      yield put(
        facesheetActions.processFacesheetDataSuccess(
          response.message.result,
          response.message.message
        )
      );
      yield put(alertActions.alertSuccessRequest(response.message.message));
    } else {
      yield put(alertActions.alertErrorRequest(response.message.message));
    }
  } catch (error) {
    console.log(error);
    yield put(alertActions.alertErrorRequest(error));
    yield put(facesheetActions.processFacesheetDataFailure(error));
  }
}
