import { facesheetTypes } from "../types/facesheet.type";

const initialState = {
  loading: false,
  result: false,
  message: "",
  error: "",
};

export const processFacesheetDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case facesheetTypes.PROCESS_FACESHEET_DATA_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case facesheetTypes.PROCESS_FACESHEET_DATA_SUCCESS:
      return {
        ...initialState,
        result: action.result,
        message: action.message,
      };
    case facesheetTypes.PROCESS_FACESHEET_DATA_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case facesheetTypes.PROCESS_FACESHEET_DATA_RESET:
      return { ...initialState };

    default:
      return state;
  }
};
