import {
  powerbiDashboardByIdType,
  powerbiDashboardTabsType,
  powerbiDashboardType,
} from "../types/powerbiDashboard.type";
const initalState = {
  reportLoading: false,
};

export const powerbiReducer = (state = initalState, action) => {
  switch (action.type) {
    case powerbiDashboardType.POWERBI_REQUEST:
      return { reportLoading: true };
    case powerbiDashboardType.POWERBI_REQUEST_SUCCESS:
      return { reportData: action.reportData };
    case powerbiDashboardType.POWERBI_REQUEST_FAILURE:
      return { error: action.error };
    case powerbiDashboardType.POWERBI_REQUEST_RESET:
      return initalState;
    default:
      return state;
  }
};

const initialTabsState = {
  loading: false,
  getAll: [],
  error: "",
};

export const powerbiDashboardTabsReducer = (
  state = initialTabsState,
  action
) => {
  switch (action.type) {
    case powerbiDashboardTabsType.POWERBI_DASHBOARD_TABS_REQUEST:
      return { ...state, loading: true };

    case powerbiDashboardTabsType.POWERBI_DASHBOARD_TABS_SUCCESS:
      return { ...state, loading: false, getAll: action?.tabs };

    case powerbiDashboardTabsType.POWERBI_DASHBOARD_TABS_FAILURE:
      return { ...state, loading: false, error: action.error };

    case powerbiDashboardTabsType.POWERBI_DASHBOARD_TABS_RESET:
      return initialTabsState;
    default: {
      return state;
    }
  }
};
const initalStateByID = {
  reportLoading: false,
};

export const powerbiDashboardByIDReducer = (
  state = initalStateByID,
  action
) => {
  switch (((state = initalStateByID), action.type)) {
    case powerbiDashboardByIdType.POWERBI_REPORT_BY_ID_REQUEST:
      return { reportLoading: true };
    case powerbiDashboardByIdType.POWERBI_REPORT_BY_ID_SUCCESS:
      return { ...state, reportLoading: false, reportData: action.reportData };
    case powerbiDashboardByIdType.POWERBI_REPORT_BY_ID_FAILURE:
      return { ...state, error: action.error };
    case powerbiDashboardByIdType.POWERBI_REPORT_BY_ID_RESET:
      return initalState;
    default:
      return state;
  }
};
