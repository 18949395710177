import { call, put, takeLatest } from "redux-saga/effects";

import { endpoint } from "../../../api/Url";
import { Api2 } from "../../../api/Api2";
import {
  getallCmerPatientActions,
  getCmerPatientDetailsActions,
  insuranceCompanyListLookupCmerActions,
  relationshipListLookupCmerActions,
} from "../actions";
import {
  getallCmerPatientType,
  getCmerPatientDetailsType,
  insuranceCompanyListLookupCmerType,
  relationshipLookupCmerType,
} from "../types";
import { alertActions } from "../../../components/AlertNew/store/AlertNew.action";

export function* watchCmerPatient() {
  yield takeLatest(
    getallCmerPatientType.GETALL_CMER_PATIENT_REQUEST,
    handleGetallCmerPatient
  );
  yield takeLatest(
    getCmerPatientDetailsType.GET_CMER_PATIENT_DETAILS_REQUEST,
    handleGetCmerPatientDetails
  );
  yield takeLatest(
    relationshipLookupCmerType.RELATIONSHIP_LOOKUP_CMER_REQUEST,
    getRelationShipListLookup
  );
  yield takeLatest(
    insuranceCompanyListLookupCmerType.INSURANCE_COMPANY_LIST_LOOKUP_CMER_REQUEST,
    getinsuranceListLookup
  );
}

function* handleGetallCmerPatient(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.cmer}/patients`,
      jwt: "Bearer " + action.token,
    });

    yield put(getallCmerPatientActions.getallCmerPatientSuccess(response.data));
  } catch (error) {
    yield put(
      getallCmerPatientActions.getallCmerPatientFailure(error?.message)
    );
  }
}
function* handleGetCmerPatientDetails(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.cmer}/patients-details?patientId=${action.query.patientId}&encounterId=${action.query.encounterId}`,
      jwt: "Bearer " + action.token,
    });

    yield put(
      getCmerPatientDetailsActions.getCmerPatientDetailsSuccess(response.data)
    );
  } catch (error) {
    yield put(getCmerPatientDetailsActions.getCmerPatientDetailsFailure(error));
    yield put(alertActions?.alertErrorRequest(error?.message));
  }
}
function* getRelationShipListLookup(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.relationshipListLookupCmer}`,
      jwt: "Bearer " + action.token,
    });

    yield put(
      relationshipListLookupCmerActions.relationshipListLookupCmerSuccess(
        response.data
      )
    );
  } catch (error) {
    yield put(
      relationshipListLookupCmerActions.relationshipListLookupCmerFailure(error)
    );
    yield put(alertActions?.alertErrorRequest(error?.message));
  }
}
function* getinsuranceListLookup(action) {
  try {
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.insuranceListLookupCmer}`,
      jwt: "Bearer " + action.token,
    });

    yield put(
      insuranceCompanyListLookupCmerActions.insuranceListLookupCmerSuccess(
        response.data
      )
    );
  } catch (error) {
    yield put(
      insuranceCompanyListLookupCmerActions.insuranceListLookupCmerFailure(
        error
      )
    );
    yield put(alertActions?.alertErrorRequest(error?.message));
  }
}
