import { drugType } from "../types/drug.type";

const initialState = {
  loading: false,
  getDrug: [],
};

export const drugReducer = (state = initialState, action) => {
  switch (action.type) {
    case drugType.DRUG_REQUEST:
      return { loading: true };
    case drugType.DRUG_REQUEST_SUCCESS:
      return { getDrug: action.getDrug };
    case drugType.DRUG_REQUEST_FAILURE:
      return { error: action.error };
    default:
      return state;
  }
};
