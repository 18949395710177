import { callHistoryOperationsCmType } from "../types/callHistory.type";

const initialStateGetDetails = {
  loading: false,
  getAll: [],
  error: "",
};

export const callHistoryDetailsCmReducer = (
  state = initialStateGetDetails,
  action
) => {
  switch (action.type) {
    case callHistoryOperationsCmType.CALL_HISTORY_DETAILS_CM_REQUEST:
      return { ...initialStateGetDetails, loading: true };
    case callHistoryOperationsCmType.CALL_HISTORY_DETAILS_CM_SUCCESS:
      return {
        ...initialStateGetDetails,
        getAll: action.getAll,
      };
    case callHistoryOperationsCmType.CALL_HISTORY_DETAILS_CM_FAILURE:
      return { ...initialStateGetDetails, error: action.error };
    default:
      return state;
  }
};

// crud operations
const initialStateOperation = {
  loading: false,
  message: "",
  error: "",
};

export const callHistoryOperationsCmReducer = (
  state = initialStateOperation,
  action
) => {
  switch (action.type) {
    case callHistoryOperationsCmType.CALL_HISTORY_OPERATIONS_CM_REQUEST:
      return { ...initialStateOperation, loading: true };
    case callHistoryOperationsCmType.CALL_HISTORY_OPERATIONS_CM_SUCCESS:
      console.log(action, "actionCRUD");
      return {
        ...initialStateOperation,
        message: action.message,
      };
    case callHistoryOperationsCmType.CALL_HISTORY_OPERATIONS_CM_FAILURE:
      return { ...initialStateOperation, error: action.error };
    case callHistoryOperationsCmType.CALL_HISTORY_OPERATIONS_CM_RESET:
      return { ...initialStateOperation };
    default:
      return state;
  }
};
