import { put, takeLatest, call } from "@redux-saga/core/effects";

import { tasksDetailsCMType } from "../types/tasksDetails.type";
import { endpoint } from "../../../../api/Url";
import { loginActions } from "../../../Login/actions/login.action";
import { Api2 } from "../../../../api/Api2";
import { TasksDetailsCMActions } from "../actions/tasksDetails.action";

export function* watchCmTasksDetails() {
  yield takeLatest(
    tasksDetailsCMType.CALL_TASKS_DETAILS_CM_REQUEST,
    getAllTasksDetails
  );
}

function* getAllTasksDetails(action) {
  try {
    //   api call
    let response = yield call(Api2, {
      method: "GET",
      endpoint: `${endpoint.savePocTaskDetailCM}?patientId=${action.request.patientId}&from=${action.request.from}&to=${action.request.to}&admissionId=${action.request.admissionId}`,
      jwt: "Bearer " + action.token,
    });
    yield put(TasksDetailsCMActions.tasksDetailsCMSuccess(response.data));
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(TasksDetailsCMActions.tasksDetailsCMFailure(error));
  }
}
