import { put, takeLatest, call } from "@redux-saga/core/effects";
import { tasksDetailsActions } from "../actions";
import { tasksDetailsType } from "../types";
import { endpoint } from "../../../../api/Url";
import { Api } from "../../../../api";
import { loginActions } from "../../../Login/actions/login.action";

export function* watchCMDTasksDetails() {
  yield takeLatest(
    tasksDetailsType.CALL_TASKS_DETAILS_REQUEST,
    getTasksDetails
  );
}

function* getTasksDetails(action) {
  try {
    //   api call
    const data = yield call(Api, {
      endpoint: `${endpoint.getCMDTasksDetails}`,
      method: "POST",
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    const parsedData = JSON.parse(data);

    parsedData.TaskListModel !== undefined &&
    parsedData.TaskListModel.length > 0
      ? yield put(
          tasksDetailsActions.tasksDetailsRequestSuccess(
            parsedData.TaskListModel
          )
        )
      : yield put(
          tasksDetailsActions.tasksDetailsRequestFailure("No data found")
        );
  } catch (error) {
    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
    yield put(tasksDetailsActions.tasksDetailsRequestFailure(error));
  }
}
