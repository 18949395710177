import { getAllProviderScheduleType } from "../../types";

export const getAllProviderSchedulesTCActions = {
  getallProviderSchedulesRequest,
  getallProviderSchedulesSuccess,
  getallProviderSchedulesFailure,
};
function getallProviderSchedulesRequest(query, token) {
  return {
    type: getAllProviderScheduleType.GETALL_PROVIDER_SCHEDULE_TC_REQUEST,
    query,
    token,
  };
}
function getallProviderSchedulesSuccess(getAll) {
  return {
    type: getAllProviderScheduleType.GETALL_PROVIDER_SCHEDULE_TC_SUCCESS,
    getAll,
  };
}
function getallProviderSchedulesFailure(error) {
  return {
    type: getAllProviderScheduleType.GETALL_PROVIDER_SCHEDULE_TC_FAILURE,
    error,
  };
}
