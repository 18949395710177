import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../api";
import { endpoint } from "../../../api/Url";
import { mailDetailsActions } from "../actions";
import { mailDetailsTypes } from "../types/mailDetails.type";
export function* watchPatientChatDetails() {
  yield takeLatest(
    mailDetailsTypes.PATIENTCHAT_DETAILS_REQUEST,
    patientChatDetails
  );
}

function* patientChatDetails(action) {
  try {
    console.log(action.request);
    let message = yield call(Api, {
      method: "POST",
      endpoint: endpoint.patientChatDetails,
      jwt: "Bearer " + action.token,
      body: action.request,
    });

    let parsedMessage = JSON.parse(message);

    yield put(
      mailDetailsActions.patientChatDetailsRequestSuccess(parsedMessage)
    );
  } catch (error) {
    yield put(mailDetailsActions.patientChatDetailsRequestFailure(error));
  }
}
