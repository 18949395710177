import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab, Breadcrumbs, Typography, Popper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import InitialAssessment from "./InitialAssessment/InitialAssessment";
import { lookupDynamicRequest } from "../../store/reducers/lookupDynamic.slice";
import NursingProgress from "./Progress/Progress";
import { colors } from "../../themes/colors";
import { IoMdHome } from "react-icons/io";
import { pageActions } from "../../store/actions/page.action";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getNursingProgressRequest,
  updateNursingProgressRequest,
  updateNursingProgressReset,
} from "./store/Reducer/progress.slice";
import { dateGetter } from "../../util";
import ProgressHeader from "./Progress/ProgressHeader";
import dayjs from "dayjs";
import PatientAppointments from "../transitionCare/components/PatientAppointments";

const Nursing = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.login.userData);
  const { state } = useLocation();

  const [tabIndex, setTabIndex] = useState(0);
  const { patientId } = useParams();
  const admissionId = state?.patientDetails1?.parentAdmissionId;
  console.log(state);
  useEffect(() => {
    if (userData.token) {
      const queryParams = { token: userData.token };
      const tables = [
        "Provider",
        "PactNew_Mobility",
        "PactNew_Balance",
        "PactNew_Strength",
        "PactNew_GoalsObjectives",
      ];

      tables.forEach((tableName) => {
        dispatch(lookupDynamicRequest({ ...queryParams, tableName }));
      });
      dispatch(pageActions.pageRequest("Patients Details"));
    }
  }, [dispatch, userData.token]); // Added dispatch to dependencies

  useEffect(() => {
    if (state.tab === 3) {
      setTabIndex(2);
    }
  }, []);
  //Nursing logics
  const progressValues = {
    dischargePlanningConsiderations: "",
    mostRecentCarePlanDate: null,
    nextCarePlanDate: null,
    anticipatedDate: null,
    nursingNotes: "",
    cognitiveStatus: "",
    ptProgress: "",
    actualLOS: "",
    expectedLOS: "",
    otProgress: "",
    stDiet: "",
    progressingAsExpected: "",
  };

  const getNursingProgress = useSelector((state) => state.getNursingProgress);
  const updateNursingProgress = useSelector(
    (state) => state.updateNursingProgress
  );
  const [progressId, setProgressId] = useState(null);

  const [progressInitialValues, setProgressInitialValues] = useState({
    ...progressValues,
  });
  useEffect(() => {
    getNursingData();
  }, [patientId]);

  const getNursingData = (progressId = null) => {
    if (userData.token) {
      const request = {
        patientId: patientId,
        admissionId: admissionId,
        progressId: progressId,

        token: userData.token,
      };
      dispatch(getNursingProgressRequest(request));
    }
  }; //? get patient data when user login
  const handleDiscard = () => {
    defaultProgressValues();
    setIsEditable(false);
  };
  const defaultProgressValues = () => {
    const patientDetails = getNursingProgress?.data?.latestUpdate;
    // const patientHistory = getNursingProgress?.data?.history;
    setProgressId(patientDetails?.id);

    // setProgressId(patientHistory[0]?.id);
    setProgressInitialValues({
      dischargePlanningConsiderations:
        patientDetails?.dischargePlanningConsiderations ?? null,

      mostRecentCarePlanDate: dateGetter(
        patientDetails?.mostRecentCarePlanDate
      ),
      nextCarePlanDate: dateGetter(patientDetails?.nextCarePlanDate) ?? null,
      anticipatedDate: dateGetter(patientDetails?.anticipatedDCDate) ?? null,

      nursingNotes: patientDetails?.nurshingNotes ?? null,
      cognitiveStatus: patientDetails?.cognitiveStatus ?? null,
      ptProgress: patientDetails?.ptProgress ?? null,
      actualLOS: patientDetails?.actualLos ?? null,
      expectedLOS: patientDetails?.expectedLos ?? null,
      otProgress: patientDetails?.otProgress ?? null,
      stDiet: patientDetails?.stDiet ?? null,
      progressingAsExpected: patientDetails?.progressingAsExpected ?? false,
    });
    console.log(patientDetails, "patientDetails");
    if (patientDetails === undefined) {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }
  };
  useEffect(() => {
    defaultProgressValues();
  }, [getNursingProgress?.data]);
  useEffect(() => {
    if (
      updateNursingProgress.message.includes("updated") ||
      updateNursingProgress.message.includes("inserted")
    ) {
      dispatch(updateNursingProgressReset());
      setProgressId(null);
      setIsEditable(false);
      getNursingData();
    }
  }, [updateNursingProgress]);
  const performUpdate = () => {
    if (userData?.token !== undefined) {
      let body = {
        patientId: patientId,
        admissionId: admissionId,
        dischargePlanningConsiderations:
          progressInitialValues.dischargePlanningConsiderations ?? "",
        mostRecentCarePlanDate: progressInitialValues?.mostRecentCarePlanDate
          ? dayjs(progressInitialValues?.mostRecentCarePlanDate)?.format(
              "YYYY-MM-DD"
            )
          : "",
        actualLos: progressInitialValues?.actualLOS ?? "",
        expectedLos: progressInitialValues?.expectedLOS ?? "",
        ptProgress: progressInitialValues?.ptProgress ?? "",
        otProgress: progressInitialValues?.otProgress ?? "",
        stDiet: progressInitialValues?.stDiet ?? "",
        nurshingNotes: progressInitialValues?.nursingNotes ?? "",
        cognitiveStatus: progressInitialValues?.cognitiveStatus ?? "",
        progressingAsExpected:
          progressInitialValues?.progressingAsExpected ?? false,
        anticipatedDCDate: progressInitialValues?.anticipatedDate
          ? dayjs(progressInitialValues?.anticipatedDate)?.format("YYYY-MM-DD")
          : "",
        nextCarePlanDate: progressInitialValues?.nextCarePlanDate
          ? dayjs(progressInitialValues?.nextCarePlanDate)?.format("YYYY-MM-DD")
          : "",
        createdBy: userData.userID,
        modifiedBy: userData.userID,
      };

      dispatch(
        updateNursingProgressRequest({
          body,
          token: userData.token,
        })
      );
    }
  };
  useEffect(() => {
    if (tabIndex === 0) {
      setProgressInitialValues(progressValues);
    }
    if (tabIndex === 1) {
      getNursingData();
    }
  }, [tabIndex]);

  const handleProgressDateChange = (name) => (event) => {
    setProgressId(event.target.value);
    getNursingData(event.target.value);
  };

  const handleCopyProgressUpdate = (event) => {
    setPopperPosition(event.target);
    setShowPopper(true);

    hidePopper();
  };
  const [popperPosition, setPopperPosition] = useState(null);
  const [showPopper, setShowPopper] = useState(false);
  // useEffect(() => {
  //   console.log(progressId, "progressId");
  //   getNursingData();
  // }, [progressId]);

  const hidePopper = () => {
    setTimeout(() => {
      setShowPopper(false);
      setPopperPosition(null);
    }, 1500);
  };

  const navigate = useNavigate();
  const [isEditable, setIsEditable] = useState(false);
  const handleEdit = () => {
    setIsEditable(true);
  };
  const handleCancelEdit = () => {
    setIsEditable(false);
  };
  return (
    <>
      <Popper open={showPopper} anchorEl={popperPosition}>
        <Box
          sx={{
            border: 0.5,
            p: 1,
            bgcolor: "rgba(0, 0, 0, 0.8)",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Adjust values for the desired shadow effect
            minWidth: 150,
            borderRadius: 2,
            color: "#ffffff",
            mt: 3,
          }}
        >
          <Typography variant="subtitle2">Progress Updates Copied</Typography>
        </Box>
      </Popper>
      <Box
        sx={{
          position: "sticky",
          backgroundColor: colors.color_primary,
          borderBottom: `1px solid ${colors.color_light_txt}`,
          mx: 3,
          zIndex: 1, // Ensure the sticky header stays above other content
        }}
      >
        <Breadcrumbs maxItems={2} aria-label="breadcrumb">
          <Typography
            underline="hover"
            color="inherit"
            onClick={() => navigate("/facility")}
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <IoMdHome sx={{ mr: 1 }} fontSize={20} />
          </Typography>
          <Typography color="text.primary">Facility</Typography>
        </Breadcrumbs>
        <Tabs
          value={tabIndex}
          onChange={(event, newValue) => setTabIndex(newValue)}
          aria-label="Nursing tabs"
          sx={{
            ".MuiTab-root": { pl: 0 },
          }}
        >
          <Tab
            label="Initial Assessment"
            sx={{
              textTransform: "capitalize",
              fontSize: 16,
              fontWeight: "bold",
            }}
          />
          <Tab
            label="Progress Update"
            sx={{
              textTransform: "capitalize",
              fontSize: 16,
              fontWeight: "bold",
            }}
          />
          {state.patientDetails1?.DCDate === null && (
            <Tab
              label="Follow Ups"
              sx={{
                textTransform: "capitalize",
                fontSize: 16,
                fontWeight: "bold",
              }}
            />
          )}
        </Tabs>
      </Box>
      <Box>
        {tabIndex === 0 && <InitialAssessment />}
        {tabIndex === 1 && (
          <Box sx={{ p: 2 }}>
            <ProgressHeader
              handleProgressDateChange={handleProgressDateChange}
              data={getNursingProgress?.data?.history}
              value={progressId}
              handleCopyProgressUpdate={handleCopyProgressUpdate}
              handleEdit={handleEdit}
              handleCancelEdit={handleCancelEdit}
              isEditable={isEditable}
            />
            <NursingProgress
              initialValues={progressInitialValues}
              updateProgressValues={setProgressInitialValues}
              patientId={patientId}
              admissionId={admissionId}
              updateData={performUpdate}
              buttonLabel={"Add"}
              buttonLoadingLabel={"Adding"}
              loading={updateNursingProgress.loading}
              handleDiscard={handleDiscard}
              disabled={!isEditable}
            />
          </Box>
        )}

        {tabIndex === 2 && (
          <PatientAppointments
            patientId={patientId}
            patientDetails={state.patientDetails1}
            role={"nursing"}
          />
        )}
      </Box>
    </>
  );
};

export default Nursing;
