import {
  addTaskTypeCmer,
  lookupGroupsSubgroupsType,
} from "../types/addTask.type";

/*Lookup group & subgroup */
export const lookupGroupsSubgroupsActions = {
  lookupGroupsSubgroupsRequest,
  lookupGroupsSubgroupsRequestSuccess,
  lookupGroupsSubgroupsRequestFailure,
  lookupGroupsSubgroupsRequestReset,
};

function lookupGroupsSubgroupsRequest(token) {
  return {
    type: lookupGroupsSubgroupsType.LOOKUP_GROUP_SUBGROUP_REQUEST,
    token,
  };
}
function lookupGroupsSubgroupsRequestSuccess(getAll) {
  return {
    type: lookupGroupsSubgroupsType.LOOKUP_GROUP_SUBGROUP_SUCCESS,
    getAll,
  };
}
function lookupGroupsSubgroupsRequestFailure(error) {
  return {
    type: lookupGroupsSubgroupsType.LOOKUP_GROUP_SUBGROUP_FAILURE,
    error,
  };
}

function lookupGroupsSubgroupsRequestReset() {
  return { type: lookupGroupsSubgroupsType.LOOKUP_GROUP_SUBGROUP_RESET };
}

/** Add task */
export const addTaskActionsCmer = {
  addTaskCmerRequest,
  addTaskCmerRequestSuccess,
  addTaskCmerRequestFailure,
  addTaskCmerRequestReset,
};

function addTaskCmerRequest(request, token) {
  return { type: addTaskTypeCmer.ADD_TASK_CMER_REQUEST, request, token };
}
function addTaskCmerRequestSuccess(message) {
  return {
    type: addTaskTypeCmer.ADD_TASK_CMER_REQUEST_SUCCESS,
    message,
  };
}
function addTaskCmerRequestFailure(error) {
  return { type: addTaskTypeCmer.ADD_TASK_CMER_REQUEST_FAILURE, error };
}

function addTaskCmerRequestReset() {
  return { type: addTaskTypeCmer.ADD_TASK_CMER_REQUEST_RESET };
}
