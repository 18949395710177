import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { colors } from "../../../themes/colors";
import { AutoComplete } from "../../../components/Forms/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { facilityLookupCMActions } from "../../cm_New/HospitalAdmissions/actions/lookupFacility.action";
import { debounce } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getInitialAssessmentRequest,
  updateInitialAssessmentRequest,
  updateInitialAssessmentReset,
} from "../store/Reducer/initialassessment.slice";
import { Progress } from "../../../components";
import { Spinner } from "../../../core";
import { IoArrowBack } from "react-icons/io5";

const InitialAssessment = () => {
  const navigate = useNavigate();
  const { patientId } = useParams();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.login.userData);
  const dynamicLookupDatas = useSelector(
    (state) => state.dynamicLookupDatas?.lookupsData
  );
  const getInitialAssessment = useSelector(
    (state) => state.getInitialAssessment
  );
  const updateInitialAssessment = useSelector(
    (state) => state.updateInitialAssessment
  );
  const [isDisbled, setIsDisabled] = useState(false);
  const facilityLookupCM = useSelector((state) => state.facilityLookupCM);
  const [searchStr, setSearchStr] = useState("");
  const [assessmentInitialValues, setAssessmentInitialValues] = useState({
    patientInformation: {
      name: "",
      facility: null,
    },
    admissionSection: {
      admissionGoals: [],
      notes: "",
    },
    baselinePriorToAdmission: {
      physicalFunctions: {
        mobility: "",
        balance: "",
        strength: "",
      },
      cognitiveMentalStatus: "",
      nutritionalStatus: "",
    },
  });

  useEffect(() => {
    if (userData.token) {
      const request = {
        patientId: patientId,
        admissionId: state?.patientDetails1?.parentAdmissionId,
        token: userData.token,
      };
      dispatch(getInitialAssessmentRequest(request));
    }
  }, [userData.token, patientId, dispatch]);
  useEffect(() => {
    // Ensure getInitialAssessment.data is an array and check its length
    if (
      Array.isArray(getInitialAssessment.data) &&
      getInitialAssessment.data.length !== 0
    ) {
      setIsDisabled(true);
    }
  }, [getInitialAssessment]);
  useEffect(() => {
    if (
      updateInitialAssessment.message.includes("updated") ||
      updateInitialAssessment.message.includes("inserted")
    ) {
      const request = {
        patientId: patientId,
        admissionId: state.patientDetails1.parentAdmissionId,

        token: userData.token,
      };
      dispatch(getInitialAssessmentRequest(request));
      dispatch(updateInitialAssessmentReset());
    }
  }, [updateInitialAssessment.message]);

  const defaultValues = () => {
    const patientDetails = getInitialAssessment.data[0];
    setAssessmentInitialValues({
      patientInformation: {
        name: `${state.patientDetails1.LastName} ${state.patientDetails1.FirstName}`,
        // facility: {
        //   FacilityID: patientDetails?.facilityId,
        //   FacilityName: patientDetails?.Facility,
        // },
        facility: `${state.patientDetails1.Facility}`,
      },
      admissionSection: {
        admissionGoals:
          patientDetails?.admissionGoalsObjId?.split(",").map(Number) ?? [],
        notes: patientDetails?.notes ?? null,
      },
      baselinePriorToAdmission: {
        physicalFunctions: {
          mobility: dynamicLookupDatas?.PactNew_Mobility?.data?.find(
            (item) => item.id === patientDetails?.strengthId
          ),
          balance: dynamicLookupDatas?.PactNew_Balance?.data?.find(
            (item) => item.id === patientDetails?.balanceId
          ),
          strength: dynamicLookupDatas?.PactNew_Strength?.data?.find(
            (item) => item.id === patientDetails?.strengthId
          ),
        },
        cognitiveMentalStatus: patientDetails?.cogMentalStatus ?? "",
        nutritionalStatus: patientDetails?.nutritionalStatus ?? "",
      },
    });
  };

  useEffect(() => {
    defaultValues();
  }, [userData, dynamicLookupDatas]);

  const performUpdate = () => {
    if (userData.token) {
      const body = {
        patientId: Number(patientId),
        admissionId: state.patientDetails1.parentAdmissionId,
        // facilityId:
        //   assessmentInitialValues.patientInformation.facility?.FacilityID ??
        //   null,
        admissionGoalsObjId:
          assessmentInitialValues.admissionSection.admissionGoals.join(",") ??
          null,
        notes: assessmentInitialValues.admissionSection.notes ?? "",
        mobilityId:
          assessmentInitialValues.baselinePriorToAdmission.physicalFunctions
            .mobility?.id ?? null,
        balanceId:
          assessmentInitialValues.baselinePriorToAdmission.physicalFunctions
            .balance?.id ?? null,
        strengthId:
          assessmentInitialValues.baselinePriorToAdmission.physicalFunctions
            .strength?.id ?? null,
        cogMentalStatus:
          assessmentInitialValues.baselinePriorToAdmission
            .cognitiveMentalStatus ?? null,
        nutritionalStatus:
          assessmentInitialValues.baselinePriorToAdmission.nutritionalStatus ??
          null,
        createdBy: `${userData.UserID}`,
        // modifiedBy: `${userData.UserID}`,
      };
      const query = { token: userData.token };
      dispatch(updateInitialAssessmentRequest({ body, query }));
    }
  };

  // const searchFacility = useCallback(
  //   debounce((searchStr) => {
  //     if (userData.token) {
  //       const query = { SearchStr: searchStr };
  //       dispatch(
  //         facilityLookupCMActions.facilityLookupCMRequest(query, userData.token)
  //       );
  //     }
  //   }, 300),
  //   [dispatch, userData.token]
  // );

  // useEffect(() => {
  //   searchFacility(searchStr);
  // }, [searchStr, searchFacility]);

  return (
    <>
      {getInitialAssessment?.loading ? (
        <Progress />
      ) : (
        <Box sx={{ p: 1, overflow: "hidden", zIndex: 1 }}>
          {/* Patient Information Section */}
          <Card
            sx={{
              width: "100%",
              backgroundColor: "#EFF6FF",
              m: 2,
              p: 2,
              overflow: "visible",
              position: "relative",
            }}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ fontWeight: "bold" }}
            >
              Patient Information
            </Typography>
            <Grid container spacing={2}>
              <Grid
                item
                container
                xs={4}
                sm={4}
                justifyContent={"space-between"}
              >
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  sx={{
                    display: "flex",
                    maxWidth: "16vw",
                    textTransform: "capitalize",
                    color: colors.color_secondary_txt,
                    padding: "0",
                    marginTop: "0.4rem",
                    fontSize: "1rem",
                  }}
                >
                  Patient Name:
                </Typography>

                {/* <TextField
                  size="small"
                  value={assessmentInitialValues.patientInformation.name}
                  disabled
                  sx={{
                    color: colors.color_primary_txt,
                    backgroundColor: colors.color_primary,
                    width: "20vw",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 0,
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                  }}
                /> */}
              </Grid>
              <Grid item xs={8}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  sx={{
                    display: "flex",
                    maxWidth: "16vw",
                    textTransform: "capitalize",
                    color: colors.color_secondary_txt,
                    padding: "0",
                    marginTop: "0.4rem",
                    fontSize: "1rem",
                  }}
                >
                  {assessmentInitialValues.patientInformation.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid
                item
                container
                xs={4}
                sm={4}
                justifyContent={"space-between"}
              >
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  sx={{
                    display: "flex",
                    maxWidth: "16vw",
                    textTransform: "capitalize",
                    color: colors.color_secondary_txt,
                    padding: "0",
                    marginTop: "0.4rem",
                    fontSize: "1rem",
                  }}
                >
                  Facility Name:
                </Typography>

                {/* <TextField
                  size="small"
                  value={assessmentInitialValues.patientInformation.name}
                  disabled
                  sx={{
                    color: colors.color_primary_txt,
                    backgroundColor: colors.color_primary,
                    width: "20vw",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 0,
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                  }}
                /> */}
              </Grid>
              <Grid
                item
                container
                xs={8}
                sm={8}
                justifyContent={"space-between"}
              >
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  sx={{
                    display: "flex",
                    // maxWidth: "16vw",
                    textTransform: "capitalize",
                    color: colors.color_secondary_txt,
                    padding: "0",
                    marginTop: "0.4rem",
                    fontSize: "1rem",
                  }}
                >
                  {assessmentInitialValues.patientInformation.facility}
                </Typography>
              </Grid>
              {/* <Grid
                item
                container
                xs={12}
                sm={6}
                justifyContent={"space-between"}
              >
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  sx={{
                    display: "flex",
                    maxWidth: "16vw",
                    textTransform: "capitalize",
                    color: colors.color_secondary_txt,
                    padding: "0",
                    marginTop: "0.4rem",
                    fontSize: "1rem",
                  }}
                >
                  Facility Name
                </Typography>
                <TextField
                  size="small"
                  value={assessmentInitialValues.patientInformation.facility}
                  disabled
                  sx={{
                    color: colors.color_primary_txt,
                    backgroundColor: colors.color_primary,
                    width: "20vw",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 0,
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </Grid> */}
            </Grid>
          </Card>

          {/* Admission Goal and Objective Section */}
          <Card
            sx={{
              width: "100%",
              backgroundColor: "#EFFDF4",
              m: 2,
              p: 2,
              position: "relative",
            }}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ fontWeight: "bold" }}
            >
              Admission Goal and Objective
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormGroup>
                  {dynamicLookupDatas.PactNew_GoalsObjectives?.data?.map(
                    (item) => (
                      <FormControlLabel
                        key={item.id}
                        control={
                          <Checkbox
                            disabled={isDisbled}
                            checked={assessmentInitialValues.admissionSection.admissionGoals.includes(
                              item.id
                            )}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              const goalId = item.id;

                              setAssessmentInitialValues((prevValues) => ({
                                ...prevValues,
                                admissionSection: {
                                  ...prevValues.admissionSection,
                                  admissionGoals: checked
                                    ? [
                                        ...prevValues.admissionSection
                                          .admissionGoals,
                                        goalId,
                                      ]
                                    : prevValues.admissionSection.admissionGoals.filter(
                                        (id) => id !== goalId
                                      ),
                                },
                              }));
                            }}
                          />
                        }
                        label={item.name}
                      />
                    )
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Notes:
                </Typography>
                <textarea
                  rows={6}
                  style={{
                    width: "100%",
                    minHeight: "100px",
                    resize: "both",
                    overflow: "auto",
                    border: "1px solid #ced4da",
                    borderRadius: "4px",
                    padding: "8px",
                  }}
                  disabled={isDisbled}
                  value={assessmentInitialValues.admissionSection.notes}
                  onChange={(event) =>
                    setAssessmentInitialValues((prevValues) => ({
                      ...prevValues,
                      admissionSection: {
                        ...prevValues.admissionSection,
                        notes: event.target.value,
                      },
                    }))
                  }
                />
              </Grid>
            </Grid>
          </Card>

          {/* Baseline Prior to Hospital Admission Section */}
          <Card
            sx={{
              width: "100%",
              backgroundColor: "#FAF5FF",
              m: 2,
              p: 2,
              position: "relative",
            }}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ fontWeight: "bold" }}
            >
              Baseline Prior to Hospital Admission
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  Physical Functions
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <AutoComplete
                      isDisabled={isDisbled}
                      label="Mobility"
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      options={dynamicLookupDatas?.PactNew_Mobility?.data}
                      value={
                        assessmentInitialValues.baselinePriorToAdmission
                          .physicalFunctions.mobility
                      }
                      onChange={(value) =>
                        setAssessmentInitialValues((prevValues) => ({
                          ...prevValues,
                          baselinePriorToAdmission: {
                            ...prevValues.baselinePriorToAdmission,
                            physicalFunctions: {
                              ...prevValues.baselinePriorToAdmission
                                .physicalFunctions,
                              mobility: value,
                            },
                          },
                        }))
                      }
                      sx={{ zIndex: 1300 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <AutoComplete
                      label="Balance"
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      options={dynamicLookupDatas?.PactNew_Balance?.data}
                      value={
                        assessmentInitialValues.baselinePriorToAdmission
                          .physicalFunctions.balance
                      }
                      isDisabled={isDisbled}
                      onChange={(value) =>
                        setAssessmentInitialValues((prevValues) => ({
                          ...prevValues,
                          baselinePriorToAdmission: {
                            ...prevValues.baselinePriorToAdmission,
                            physicalFunctions: {
                              ...prevValues.baselinePriorToAdmission
                                .physicalFunctions,
                              balance: value,
                            },
                          },
                        }))
                      }
                      sx={{ zIndex: 1300 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <AutoComplete
                      isDisabled={isDisbled}
                      label="Strength"
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option?.id}
                      options={dynamicLookupDatas?.PactNew_Strength?.data}
                      value={
                        assessmentInitialValues.baselinePriorToAdmission
                          .physicalFunctions.strength
                      }
                      onChange={(value) =>
                        setAssessmentInitialValues((prevValues) => ({
                          ...prevValues,
                          baselinePriorToAdmission: {
                            ...prevValues.baselinePriorToAdmission,
                            physicalFunctions: {
                              ...prevValues.baselinePriorToAdmission
                                .physicalFunctions,
                              strength: value,
                            },
                          },
                        }))
                      }
                      sx={{ zIndex: 1300 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  Cognitive and Mental Status
                </Typography>
                <textarea
                  rows={6}
                  style={{
                    width: "100%",
                    minHeight: "100px",
                    resize: "both",
                    overflow: "auto",
                    border: "1px solid #ced4da",
                    borderRadius: "4px",
                    padding: "8px",
                  }}
                  disabled={isDisbled}
                  value={
                    assessmentInitialValues.baselinePriorToAdmission
                      .cognitiveMentalStatus
                  }
                  onChange={(event) =>
                    setAssessmentInitialValues((prevValues) => ({
                      ...prevValues,
                      baselinePriorToAdmission: {
                        ...prevValues.baselinePriorToAdmission,
                        cognitiveMentalStatus: event.target.value,
                      },
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  Nutritional Status
                </Typography>
                <textarea
                  rows={6}
                  style={{
                    width: "100%",
                    minHeight: "100px",
                    resize: "both",
                    overflow: "auto",
                    border: "1px solid #ced4da",
                    borderRadius: "4px",
                    padding: "8px",
                  }}
                  disabled={isDisbled}
                  value={
                    assessmentInitialValues.baselinePriorToAdmission
                      .nutritionalStatus
                  }
                  onChange={(event) =>
                    setAssessmentInitialValues((prevValues) => ({
                      ...prevValues,
                      baselinePriorToAdmission: {
                        ...prevValues.baselinePriorToAdmission,
                        nutritionalStatus: event.target.value,
                      },
                    }))
                  }
                />
              </Grid>
            </Grid>
          </Card>

          <Grid container justifyContent={"space-between"}>
            <Grid item xs={3}>
              <Button
                onClick={() => navigate("/facility")}
                variant="text"
                sx={{
                  ":hover": {
                    backgroundColor: "transparent",
                  },
                  textTransform: "capitalize",
                }}
              >
                <IoArrowBack style={{ marginRight: 5 }} />
                Back To Home
              </Button>
            </Grid>{" "}
            {!isDisbled && (
              <Grid item xs={3}>
                <Button
                  onClick={performUpdate}
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: colors.color_primary_txt,
                    color: colors.color_active,
                    "&:hover": {
                      color: colors.color_active,
                      borderColor: colors.color_primary_txt,
                      backgroundColor: colors.color_primary_txt,
                    },
                    fontSize: 16,
                    width: "100%",
                  }}
                >
                  {updateInitialAssessment.loading ? (
                    <>
                      <Spinner width={18} height={18} />
                      <span style={{ marginLeft: "6px" }}>Adding</span>
                    </>
                  ) : (
                    <span style={{ marginLeft: "6px" }}>Add</span>
                  )}
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default InitialAssessment;
