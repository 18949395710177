import { updatePatientAssignSeenType } from "../types";

const initialState = {
  loading: false,
  message: "",
  error: "",
};

export const updatePatientAssignmentSeenReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case updatePatientAssignSeenType.UPDATE_PATIENT_ASSIGN_SEEN_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case updatePatientAssignSeenType.UPDATE_PATIENT_ASSIGN_SEEN_SUCCESS:
      return {
        ...initialState,
        message: action.message,
      };
    case updatePatientAssignSeenType.UPDATE_PATIENT_ASSIGN_SEEN_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case updatePatientAssignSeenType.UPDATE_PATIENT_ASSIGN_SEEN_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
