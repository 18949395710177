import { put, takeLatest, call } from "@redux-saga/core/effects";
import { Api } from "../../../../../api";
import { endpoint } from "../../../../../api/Url";
import { msoOperationActions } from "../actions";
import { msoOperationTypes } from "../types";

export function* watchDMMsoOperation() {
  yield takeLatest(msoOperationTypes.MSO_OPERATION_REQUEST, msoOperation);
}

function* msoOperation(action) {
  try {
    let data = yield call(Api, {
      method: "POST",
      endpoint: endpoint.operationDMMso,
      jwt: "Bearer " + action.token,
      body: action.request,
    });
    let parsedData = JSON.parse(data);
    if (parsedData.length > 0)
      yield put(msoOperationActions.msoOperationRequestSuccess(parsedData[0]));
    else
      yield put(
        msoOperationActions.msoOperationRequestSuccess({
          Result: "Error",
        })
      );
  } catch (error) {
    yield put(msoOperationActions.msoOperationRequestFailure(error));
  }
}
