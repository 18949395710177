import { pcpTypes } from "../types";

export const pcpActions = {
  pcpRequest,
  pcpRequestSuccess,
  pcpRequestFailure,
};
function pcpRequest(request, token) {
  return {
    type: pcpTypes.PCP_REQUEST,
    request,
    token,
  };
}
function pcpRequestSuccess(getAll, specialities) {
  return {
    type: pcpTypes.PCP_REQUEST_SUCCESS,
    getAll,
    specialities,
  };
}
function pcpRequestFailure(error) {
  return {
    type: pcpTypes.PCP_REQUEST_FAILURE,
    error,
  };
}
