import { deleteMailTypes } from "../types/deleteMail.type";

export const deleteMailActions = {
  deleteMailRequest,
  deleteMailRequestSuccess,
  deleteMailRequestFailure,
};

function deleteMailRequest(request, token) {
  return { type: deleteMailTypes.DELETE_MAIL_REQUEST, request, token };
}

function deleteMailRequestSuccess(message) {
  return { type: deleteMailTypes.DELETE_MAIL_REQUEST_SUCCESS, message };
}

function deleteMailRequestFailure(error) {
  return { type: deleteMailTypes.DELETE_MAIL_REQUEST_FAILURE, error };
}
