import { recommendationsTypes } from "../types";

export const recommendationsActions = {
  recommendationsGetRequest,
  recommendationsGetRequestSuccess,
  recommendationsGetRequestFailure,

  recommendationsOpRequest,
  recommendationsOpRequestSuccess,
  recommendationsOpRequestFailure,
  recommendationsOpRequestReset,
};
function recommendationsGetRequest(request, token) {
  return {
    type: recommendationsTypes.RECOMMENDATIONS_GET_REQUEST,
    request,
    token,
  };
}
function recommendationsGetRequestSuccess(get) {
  return {
    type: recommendationsTypes.RECOMMENDATIONS_GET_REQUEST_SUCCESS,
    get,
  };
}
function recommendationsGetRequestFailure(error) {
  return {
    type: recommendationsTypes.RECOMMENDATIONS_GET_REQUEST_FAILURE,
    error,
  };
}

function recommendationsOpRequest(request, token) {
  return {
    type: recommendationsTypes.RECOMMENDATIONS_OPERATION_REQUEST,
    request,
    token,
  };
}
function recommendationsOpRequestSuccess(message) {
  return {
    type: recommendationsTypes.RECOMMENDATIONS_OPERATION_REQUEST_SUCCESS,
    message,
  };
}
function recommendationsOpRequestFailure(error) {
  return {
    type: recommendationsTypes.RECOMMENDATIONS_OPERATION_REQUEST_FAILURE,
    error,
  };
}
function recommendationsOpRequestReset() {
  return {
    type: recommendationsTypes.RECOMMENDATIONS_OPERATION_REQUEST_RESET,
  };
}
