import { put, takeEvery, takeLatest, call } from "@redux-saga/core/effects";
import { basicInfoActions } from "../actions/bascInfo.action";
import { endpoint } from "../../../../../api/Url";
import { Api2 } from "../../../../../api/Api2";
import { loginActions } from "../../../../Login/actions/login.action";
import { basicInfoTypes } from "../types";

export function* watchBasicInfo() {
  yield takeLatest(basicInfoTypes.BASIC_INFO_REQUEST, getBasicInfo);
}

function* getBasicInfo(action) {
  try {
    const basicInfo = yield call(Api2, {
      endpoint: `${endpoint.getCMPatientDetailsV2}/${action.request.AdmissionID}`,
      method: "GET",
      jwt: "Bearer " + action.token,
      //   body: action.request,
    });
    const parsedBasicInfo = JSON.parse(basicInfo);
    yield put(
      basicInfoActions.basicInfoRequestSuccess(
        parsedBasicInfo.CaseManagerPatientDetail[0],
        parsedBasicInfo.CmUserlist,
        parsedBasicInfo.LookupDisposition
      )
    );
  } catch (error) {
    yield put(basicInfoActions.basicInfoRequestFailure(error));

    if (error.code === 401 || error.code === 403)
      yield put(loginActions.logoutRequest());
  }
}
