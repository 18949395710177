import { getPcpsDetailsTypes } from "../types/pcpsDetails.type";

export const pcpsDetailsActions = {
  getPcpsDetailsRequest,
  getPcpsDetailsRequestFailure,
  getPcpsDetailsRequestSuccess,
};
function getPcpsDetailsRequest(request, token) {
  return {
    type: getPcpsDetailsTypes.GET_PCPS_DETAILS_REQUEST,
    request,
    token,
  };
}
function getPcpsDetailsRequestSuccess(getAll) {
  return {
    type: getPcpsDetailsTypes.GET_PCPS_DETAILS_REQUEST_SUCCESS,
    getAll,
  };
}
function getPcpsDetailsRequestFailure(error) {
  return {
    type: getPcpsDetailsTypes.GET_PCPS_DETAILS_REQUEST_FAILURE,
    error,
  };
}
