import { labTestsTypes } from "../types";

export const labTestsActions = {
  labTestsGetRequest,
  labTestsGetRequestSuccess,
  labTestsGetRequestFailure,

  labTestsOpRequest,
  labTestsOpRequestSuccess,
  labTestsOpRequestFailure,
  labTestsOpRequestReset,
};
function labTestsGetRequest(request, token) {
  return {
    type: labTestsTypes.LABTESTS_GET_REQUEST,
    request,
    token,
  };
}
function labTestsGetRequestSuccess(get) {
  return {
    type: labTestsTypes.LABTESTS_GET_REQUEST_SUCCESS,
    get,
  };
}
function labTestsGetRequestFailure(error) {
  return {
    type: labTestsTypes.LABTESTS_GET_REQUEST_FAILURE,
    error,
  };
}

function labTestsOpRequest(request, token) {
  return {
    type: labTestsTypes.LABTESTS_OPERATION_REQUEST,
    request,
    token,
  };
}
function labTestsOpRequestSuccess(message) {
  return {
    type: labTestsTypes.LABTESTS_OPERATION_REQUEST_SUCCESS,
    message,
  };
}
function labTestsOpRequestFailure(error) {
  return {
    type: labTestsTypes.LABTESTS_OPERATION_REQUEST_FAILURE,
    error,
  };
}
function labTestsOpRequestReset() {
  return {
    type: labTestsTypes.LABTESTS_OPERATION_REQUEST_RESET,
  };
}
