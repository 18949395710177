import React, { useState } from "react";

//CONTEXT
import { useContext } from "react";
import NavContext from "../../Context/NavContext";

//REACT ROUTER
import { NavLink } from "react-router-dom";

// import * as FaIcons from "react-icons/fa";
import {
  AiOutlineMail,
  AiOutlineFileSync,
  AiOutlineWindows,
  AiOutlineThunderbolt,
  AiOutlineTrophy,
  AiOutlineStar,
} from "react-icons/ai";
import { BsRecord } from "react-icons/bs";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { FiUsers, FiUpload } from "react-icons/fi";
import { MdLock } from "react-icons/md";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { FaEllipsisH } from "react-icons/fa";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  MdOutlineDashboard,
  MdOutlineSick,
  MdOutlineLogout,
} from "react-icons/md";
import {
  VscTable,
  VscTypeHierarchySub,
  VscTypeHierarchySuper,
} from "react-icons/vsc";
import { MdOutlineVideoCall } from "react-icons/md";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { FaShieldAlt } from "react-icons/fa";
import { TbHierarchy2 } from "react-icons/fa";
// import logo from "../../assets/images/logo.png";

//STYLES

import styles from "./Navbar.module.scss";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { FaBuilding } from "react-icons/fa";

const NavUrl = ({ url, icon, description }) => {
  const { nav, setNav } = useContext(NavContext);
  const checkWindowSize = () => {
    if (window.innerWidth < 1024) setNav(!nav);
  };

  return (
    <li className={styles.li_navlink}>
      <NavLink
        to={`${url}`}
        className={({ isActive }) => (isActive ? styles.active : undefined)}
        onClick={() => checkWindowSize()}
      >
        {icon}
        <span className={styles.description}>{description}</span>
      </NavLink>
    </li>
  );
};

const dmmenus = [
  {
    id: 1,
    url: "insurance",
    description: "Insurance",
    hasChildren: true,
    icon: <FaShieldAlt />,
    children: [
      {
        id: 1,
        url: "lookup/insurance",
        description: "Insurance",
        hasChildren: false,
      },
      {
        id: 2,
        url: "lookup/insuranceplan",
        description: "Insurance Plan",
        hasChildren: false,
      },
    ],
  },
  {
    id: 2,
    url: "facility",
    description: "Facility",
    hasChildren: true,
    icon: <FaBuilding />,
    children: [
      {
        id: 1,
        url: "lookup/mso",
        description: "MSO",
        hasChildren: false,
      },
      {
        id: 2,
        url: "lookup/facility",
        description: "Facility",
        hasChildren: false,
      },
      {
        id: 3,
        url: "lookup/role",
        description: "Role",
        hasChildren: false,
      },
      {
        id: 4,
        url: "lookup/route",
        description: "Route",
        hasChildren: false,
      },
    ],
  },
  {
    id: 3,
    url: "lookup/pcphierarchy",
    description: "PCP Hierarchy",
    icon: <VscTypeHierarchySub />,
    hasChildren: true,
    children: [
      {
        id: 1,
        url: "lookup/nation",
        description: "Nation",
        hasChildren: false,
      },
      {
        id: 2,
        url: "lookup/region",
        description: "Region",
        hasChildren: false,
      },
      {
        id: 3,
        url: "lookup/ipa",
        description: "IPA",
        hasChildren: false,
      },
      {
        id: 4,
        url: "lookup/pcpgroup",
        description: "PCP Group",
        hasChildren: false,
      },
      {
        id: 5,
        url: "lookup/pcpoffice",
        description: "PCP Office ",
        hasChildren: false,
      },
      {
        id: 6,
        url: "lookup/pcp",
        description: "PCP",
        hasChildren: false,
      },
      {
        id: 7,
        url: "lookup/pcphierarchy",
        description: "PCP Hierarchy ",
        hasChildren: false,
      },
    ],
  },
  {
    id: 4,
    url: "others",
    description: "Others",
    icon: <MoreHorizIcon />,
    hasChildren: true,
    children: [
      {
        id: 1,
        url: "lookup/disposition",
        description: "Disposition",
        hasChildren: false,
      },
      {
        id: 2,
        url: "lookup/bundledisposition",
        description: "Bundle Disposition",
        hasChildren: false,
      },
      {
        id: 3,
        url: "lookup/icd",
        description: "ICD",
        hasChildren: false,
      },
      {
        id: 4,
        url: "lookup/socialdeterminants",
        description: "Social Determinants",
        hasChildren: false,
      },
      {
        id: 5,
        url: "lookup/user-screen-permission",
        description: "Use Screen Permission",
        hasChildren: false,
      },
    ],
  },
];

const Navbar = () => {
  const { nav, setNav } = useContext(NavContext);
  const userData = useSelector((state) => state.login.userData);

  const [selected, setSelected] = useState({
    parentId: -1,
    childId: -1,
  });
  console.log("userData", userData);
  const NavChild = ({ item }) => {
    return (
      <NavLink
        to={`${item.url}`}
        // className={({ isActive }) => (isActive ? styles.active : undefined)}
        // onClick={() => checkWindowSize()}
        className={styles.child}
      >
        <span className={styles.childdescription}>
          <BsRecord size={".5rem"} className={styles.childico} />
          {item.description}
        </span>
      </NavLink>
    );
  };

  const NavUrlsV2 = ({ menus }) => {
    const { nav, setNav } = useContext(NavContext);
    const checkWindowSize = () => {
      if (window.innerWidth < 1024) setNav(!nav);
    };

    return (
      <>
        {menus.map((item) => (
          <li className={styles.li_navlink} key={item.id}>
            <Button
              sx={{
                color: "white",
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
              variant="text"
              onClick={() => {
                checkWindowSize();
                setSelected({
                  parentId: selected.parentId === item.id ? -1 : item.id,
                  childId: -1,
                });
              }}
            >
              <div sx={{ display: "flex", flex: 0.5 }}>
                {item.icon}
                <span
                  className={styles.description}
                  style={{
                    marginLeft: ".4rem",
                    color: "white",
                  }}
                >
                  {item.description}
                </span>
              </div>
              <div sx={{ display: "flex", flex: 0.5 }}>
                {selected.parentId === item.id ? (
                  <IoIosArrowDown
                    style={{ marginLeft: ".9rem" }}
                    size={"1rem"}
                  />
                ) : (
                  <IoIosArrowForward
                    style={{ marginLeft: ".16rem" }}
                    size={"1rem"}
                  />
                )}
              </div>
            </Button>
            {item.hasChildren &&
              selected.parentId === item.id &&
              item.children.map((child) => (
                <NavChild item={child} key={child.id} sx={{ size: "12em" }} />
              ))}
          </li>
        ))}
      </>
    );
  };

  return (
    <div
      className={`${styles.navbar_container} ${
        nav ? styles.navbar_mobile_active : undefined
      }`}
    >
      <nav className={nav ? undefined : styles.nav_small}>
        {/* LOGO */}
        <div className={styles.logo}>
          {/* <VscDashboard className={styles.logo_icon} /> */}
          <h3 className={styles.pact}>PACT</h3>
          {/* <img alt="pact" className="img-fluid" src={logo} /> */}

          {/* <FaIcons.FaTimes
            className={styles.mobile_cancel_icon}
            onClick={() => {
              setNav(!nav);
            }}
          /> */}
        </div>

        {/* MENU */}
        <ul className={styles.menu_container}>
          {/* FIRST CATEGORY */}
          {/* <span className={styles.categories}>{nav ? "Pages" : <></>}</span> */}
          {userData !== undefined &&
            userData.RoleID !== 9 &&
            userData.RoleID !== 58 && (
              <>
                {userData.RoleID !== 56 &&
                  userData.RoleID !== 57 &&
                  userData.RoleID !== 5 && (
                    <NavUrl
                      url="dashboard"
                      icon={<MdOutlineDashboard />}
                      description="Dashboard"
                    />
                  )}

                <NavUrl
                  url={"patients"}
                  icon={<MdOutlineSick />}
                  description="Patients"
                />

                <NavUrl
                  url="inbox"
                  icon={<AiOutlineMail />}
                  description="Messages"
                />
                {userData.RoleID !== 56 && userData.RoleID !== 57 && (
                  <NavUrl
                    url="report"
                    icon={<AiOutlineFileSync />}
                    description="Reports"
                  />
                )}
              </>
            )}
          {userData !== undefined && userData.RoleID === 1 && (
            <NavUrl
              url="patientchats"
              icon={<HiOutlineChatAlt2 />}
              description="PatientChats"
            />
          )}

          {userData !== undefined && userData.RoleID === 9 && (
            <>
              {nav ? (
                <>
                  <span className={styles.categories}>Lookup</span>
                  <NavUrlsV2 menus={dmmenus} />
                  <span className={styles.categories}>Pages</span>
                  <NavUrl url="users" icon={<FiUsers />} description="Users" />
                  <NavUrl
                    url="userpermission"
                    icon={<MdLock />}
                    description="Screen Permission"
                  />
                </>
              ) : (
                <div className={styles.iconhier}>
                  <NavUrlsV2 menus={dmmenus} />

                  <NavUrl url="users" icon={<FiUsers />} description="Users" />
                  <NavUrl
                    url="userpermission"
                    icon={<MdLock />}
                    description="Screen Permission"
                  />
                </div>
              )}
            </>
          )}

          {
            //dm role
            userData !== undefined && userData.RoleID === 58 && (
              <>
                <NavUrl
                  url="vc-pcp"
                  icon={<MdOutlineVideoCall />}
                  description="VC-PCP"
                />
              </>
            )
          }
          {userData !== undefined && userData.RoleID === 3 && (
            <>
              <NavUrl
                url="ehrfiles"
                icon={<FiUpload />}
                description="EHRFiles"
              />
            </>
          )}
        </ul>

        <div
          className={styles.btn_logout}
          onClick={() => {
            setNav(!nav);
          }}
        >
          <label>{!nav ? "Open" : "Close"} </label>
          <HiOutlineArrowNarrowRight />
        </div>
      </nav>

      <div
        className={nav ? styles.mobile_nav_background_active : undefined}
        onClick={() => {
          setNav(!nav);
        }}
      ></div>
    </div>
  );
};

export default Navbar;
