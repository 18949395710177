import { getActivePatientAppointmentsType } from "../../types";

const initialState = {
  loading: false,
  getAll: [],
};

export const getActivePatientAppoinmentsTCReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getActivePatientAppointmentsType.GET_ACTIVE_PATIENT_APPOINTMENTS_REQUEST:
      return {
        ...initialState,
        loading: true,
      };
    case getActivePatientAppointmentsType.GET_ACTIVE_PATIENT_APPOINTMENTS_SUCCESS:
      return {
        ...initialState,
        getAll: action.getAll,
      };
    case getActivePatientAppointmentsType.GET_ACTIVE_PATIENT_APPOINTMENTS_FAILURE:
      return {
        ...initialState,
        error: action.error,
      };
    case getActivePatientAppointmentsType.GET_ACTIVE_PATIENT_APPOINTMENTS_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
